import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Dropdown,
  Indicator,
  Navbar,
  Card,
  Form,
  Input,
} from "react-daisyui";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import { observer } from "mobx-react";
import { RxDoubleArrowLeft, RxDoubleArrowRight, RxEyeClosed, RxEyeOpen, RxGear, RxTextAlignLeft } from "react-icons/rx";
import { useAuthStore } from "../Auth/store/Auth";
import { MdAccountCircle, MdLock, MdLogout, MdOutlineVpnKey } from "react-icons/md";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useProfileStore } from "../Profile/store/Profilestore";


const Header = observer(() => {
  const navigate = useNavigate()
  const [currentshowpassword, setcurrentshowpassword] = useState(false);
  const [newshowpassword, setnewshowpassword] = useState(false);
  const [confirmnewshowpassword, setconfirmnewshowpassword] = useState(false);
  const { getToken, getprofileone, firstnameapi, user, adminAuthApproved, logout, passwordChange, getcurrentpassword, getnewpassword, getconfirmnewpassword, setpasswordpopup, passwordpopup, passwordLoading, errPassword, notificationcount, admin_name, UserPermissions } = useAuthStore();
  const [menuCollapse, setMenuCollapse] = useState(true);

  const { toggleSidebar, collapseSidebar } = useProSidebar();
  const parm = useLocation();
  console.log("useLocation", parm);
  useEffect(() => {
    if (adminAuthApproved) {
      getToken(localStorage.getItem("Admintoken"));
    }
    UserPermissions();

  }, [adminAuthApproved]);

  // useEffect(() => {
  //   if (adminAuthApproved) {
  //      getprofileone();
  //   }
  // }, [adminAuthApproved]);

  // useEffect(() => {
  //   if (adminAuthApproved) {
  //     getNotificationCount()
  //   }

  // })
console.log("window.innerWidth ",window.innerWidth );

  return (
    <div className="mdcal w-full border-b border-[#efefef] h-[70px] bg-[#F6FAFD] sticky top-0 right-0 z-50">
      <Navbar>
        <div className="flex-1">
          {/* <button onClick={() => {collapseSidebar(); setMenuCollapse(!menuCollapse)}} 
          className="
          md:flex 
          hidden 
          btn 
          h-[45px]
          w-[45px]
          min-h-min
          text-lg
          p-0
          items-center 
          justify-center
          font-bold
          rounded-full 
          bg-color_theme 
           border-[5px]
          border-[#fff] 
          hover:text-[#fff] 
          text-[#000] 
          absolute 
          -left-5 
          top-2 
          z-[60]
          ">
        {menuCollapse? <RxDoubleArrowLeft/>: <RxDoubleArrowRight/>} 
          </button> */}
          <RxTextAlignLeft onClick={() => toggleSidebar()} className="text-3xl md:hidden" />
        </div>
        <div className=" w-full">

          {/* <Button color="ghost" className="avatar" shape="circle">
              <div className="w-10 rounded-full">
                <img
                  alt="..."
                  src="https://api.lorem.space/image/face?hash=33791"
                />
              </div>
            </Button> */}
          <div className=" w-8/12">
            <span className="ghost p-5 ml-[24px] mt-[25px] text-[24px] font-bold cursor-pointer">  {parm.pathname == "/" && "Dashboard"}</span>

          </div>
          <div
            className={`${window.innerWidth <= 820 ? "w-full" : "w-4/12"
              } md:flex hidden justify-center items-center text-center gap-10 bg-[#EEF6FBE5] p-3`}
          >
            <Link
              to={"/productcatalog/addproductcatalog"}
              className="ml-9 bg-[#ff6600] space-x-[9px] p-3 px-5 rounded-full text-white text-[14px] cursor-pointer"
            >
              <span>Add New</span> <span className="text">+</span>
            </Link>
            <Dropdown vertical="end">
              <span className="ghost text-[13px] font-semibold cursor-pointer">
                Welcome, {admin_name === undefined ? "Admin" : admin_name}
              </span>
              <Dropdown.Menu className="w-52 menu-compact">
                <Link to="/profile">
                  <Dropdown.Item>
                    <MdAccountCircle />
                    Profile
                  </Dropdown.Item>
                </Link>
                <label onClick={() => setpasswordpopup()} htmlFor="my-modal-3">
                  <Dropdown.Item>
                    <MdOutlineVpnKey />
                    Change Password
                  </Dropdown.Item>
                </label>
                <Dropdown.Item onClick={() => { logout(); navigate("/") }}>
                  <MdLogout />
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>






        </div>
      </Navbar>
      <input readOnly checked={passwordpopup} type="checkbox" id="my-modal-3" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box relative">
          <form autoComplete="off" onSubmit={(e) => { passwordChange(); e.preventDefault() }}>
            <label onClick={() => setpasswordpopup()} htmlFor="my-modal-3" className="btn btn-sm btn-circle absolute right-2 top-2">
              <button type="reset">✕</button>
            </label>
            <h3 className="text-lg font-bold">Change Your Password</h3>
            <div className="pt-4">

              <div className="form-control">
                <label className="label">
                  <span className="label-text font-semibold">Your Current Password</span>
                </label>
                <label className="input-group">
                  <span className=" bg-[#000]">
                    <MdOutlineVpnKey className=" text-color_theme" />
                  </span>
                  <input
                    autoComplete="password"
                    onChange={(e) => getcurrentpassword(e.target.value)}
                    required
                    name="password"
                    type={currentshowpassword ? "text" : "password"}
                    placeholder="Current Password"
                    className="input w-full border-[#000] focus:outline-none border-r-0"
                  />
                  <span className=" bg-transparent cursor-pointer border-[#000] border border-l-0" onClick={() => setcurrentshowpassword(!currentshowpassword)}>
                    {currentshowpassword
                      ?
                      <RxEyeOpen className=" text-[#000]" />
                      :
                      <RxEyeClosed className=" text-[#000]" />
                    }
                  </span>
                </label>
              </div>
              <div className="form-control mt-2">
                <label className="label">
                  <span className="label-text font-semibold">Your New Password</span>
                </label>
                <label className="input-group">
                  <span className=" bg-[#000]">
                    <MdOutlineVpnKey className=" text-color_theme" />
                  </span>
                  <input
                    autoComplete="newpassword"
                    onChange={(e) => getnewpassword(e.target.value)}
                    required
                    name="newpassword"
                    type={newshowpassword ? "text" : "password"}
                    placeholder="New Password"
                    className="input w-full border-[#000] focus:outline-none border-r-0"
                  />
                  <span className=" bg-transparent cursor-pointer border-[#000] border border-l-0" onClick={() => setnewshowpassword(!newshowpassword)}>
                    {newshowpassword
                      ?
                      <RxEyeOpen className=" text-[#000]" />
                      :
                      <RxEyeClosed className=" text-[#000]" />
                    }
                  </span>
                </label>
              </div>
              <div className="form-control mt-2">
                <label className="label">
                  <span className="label-text font-semibold">Confirm New Password</span>
                </label>
                <label className="input-group">
                  <span className=" bg-[#000]">
                    <MdOutlineVpnKey className=" text-color_theme" />
                  </span>
                  <input
                    autoComplete="confirmpassword"
                    onChange={(e) => getconfirmnewpassword(e.target.value)}
                    required
                    name="confirmpassword"
                    type={confirmnewshowpassword ? "text" : "password"}
                    placeholder="Confirm New Password"
                    className="input w-full border-[#000] focus:outline-none border-r-0"
                  />
                  <span className=" bg-transparent cursor-pointer border-[#000] border border-l-0" onClick={() => setconfirmnewshowpassword(!confirmnewshowpassword)}>
                    {confirmnewshowpassword
                      ?
                      <RxEyeOpen className=" text-[#000]" />
                      :
                      <RxEyeClosed className=" text-[#000]" />
                    }
                  </span>
                </label>
              </div>
              <label className="label">
                <span className=" text-xs text-[#f33]">{errPassword}</span>
              </label>

              {!passwordLoading
                ?
                <button type="submit" className="mt-5 btn w-full bg-color_theme hover:bg-[#000] text-[#000] hover:text-color_theme font-semibold border-none">Update Password</button>
                :
                <button type="button" className="mt-5 btn w-full bg-[#000] hover:bg-[#000] text-color_theme hover:text-color_theme font-semibold border-none flex justify-center items-center">
                  <svg
                    className="animate-spin mr-1 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </button>
              }


            </div>
          </form>
        </div>
      </div>
    </div>
  );
});

export default Header;