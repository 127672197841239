import { observer } from 'mobx-react';
import {useHomecourtStore,HomecourtStore,HomecourtStoreContext} from '../HomecourtManagement/store/HomecourtStore';
import { useUserStore } from './store/UserStore';


export const CityDropdown = ({city,functionprop,cityget}) => {
    
    return(
        <HomecourtStoreContext.Provider value={new HomecourtStore({
            loadeditcitybol:false,
            editloading:false,
            slectcity:true
        })}>
            <Screen city={city} functionprop={functionprop} cityget={cityget}/>
        </HomecourtStoreContext.Provider> 
    )
}

const Screen = observer(({city,functionprop,cityget}) => {
    const { 
        citydrop,
        
    } = useHomecourtStore();

    // const {
    //     getNewcity
    //   } = useUserStore();
    return(
        <div>
            <label htmlFor="City" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">City<span className="text-[#f00]">&nbsp;*</span></label>
            <select
            value={city}
            id="City"
            required
            onChange={(e) => {functionprop(e.target.value);cityget(e.target.value)}} 
            name="City"
            className={`select input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                <option value="" disabled className="disabled-value hidden">Select City</option>
                {citydrop.map((data,index)=> (
                <option value={data?._id} key={index}>{data?.city_name } </option>
            ))}

            </select>
        </div>
    )
})