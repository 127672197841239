import React, { useEffect, useState } from "react";
import { MdOutlineRefresh, MdOutlineSearch } from "react-icons/md";
import { observer } from "mobx-react";
import { Input } from "react-daisyui";
import { useDivisionStore } from "../../DivisionManagement/store/DivisionStore";
import { useAuthStore } from "../../Auth/store/Auth";
import { useTieStore } from "../store/store";

const Filter = observer((prop) => {
  const { Leaguesdropdown, getToken, getLeaguesdropdown } = useDivisionStore();
  const { Dname,settled_tie,getDname ,getTieList, getleague, getFilterTieList,SetSettled,Settled_tie,getSettledTieBreaker } = useTieStore();
  const { adminAuthApproved } = useAuthStore();
  const [issettled, setissettled] = useState(true);
  // useEffect(()=>{
  //   if(  prop.prop === "settled" ){
  //     getSettledTieBreaker();

  //   }
  
  // })
  useEffect(() => {
    if (adminAuthApproved) {
      getToken(localStorage.getItem("Admintoken"));
    }
  }, [adminAuthApproved]);

  useEffect(() => {
    getLeaguesdropdown();
  }, []);
  const Reset = () => {
    Settled_tie("")
    getDname("");
    getleague("");
    SetSettled("");
    // setissettled(false);
  };
  return (
    <>
      <div className="card bg-base-100 shadow-md p-6 mt-3 mb-10">
        <form
          onSubmit={(e) => {
            getFilterTieList();
            e.preventDefault();
          }}
        >
          <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
            <div>
              <label
                htmlFor="League Name"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                League Name
              </label>
              <select
                defaultValue=""
                id="LeagueName"
                name="LeagueName"
                onChange={(e) => getleague(e.target.value)}
                className={`select capitalize focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                {/* <option value="" disabled className="hidden">Select city</option> */}
                <option value="" className="">
                  All
                </option>
                {Leaguesdropdown.map((data, index) => (
                  <option value={data?._id} key={index}>
                    {data?.name}
                  </option>
                ))}
              </select>
            </div>
            {/* <div>
                        <label htmlFor="SeasonName" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Season Name</label>
                        <select
                                name="Leaguetype"
                                defaultValue=""
                                id="Leaguetype"
                                // onChange={(e) => addleaguetyp(e.target.value)}
                                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                            >
                                <option value="">All</option>
                                <option value="single">Singles</option>
                                <option value="double">Doubles</option>
                                <option value="mixed">Mixed</option>
                            </select>
                    </div> */}
            {/* <div>
                        <label htmlFor="Fees" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Coupon Code</label>
                        <Input 
                        name="Coupon Code"
                        placeholder="Coupon Code" 
                        // onChange={(e) => getcode(e.target.value)}
                        id="Coupon Code" 
                        type="text" 
                        autoComplete="off" 
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div> */}
            <div>
              <label
                htmlFor="DivisionName"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Division Code
              </label>
              <Input
                name="DivisionName"
                placeholder="Division Code"
                 value={Dname}
                onChange={(e) => getDname(e.target.value)}
                id="DivisionName"
                type="number"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            <div>
              <label
                htmlFor="LeagueCategory"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
              Settled
              </label>
              <select
                name="Settled"
                id="LeagueCategory"
                defaultValue=""
                onChange={(e) => SetSettled(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="">All</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>
          </div>
          <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
            <button
              onClick={() => {
                Reset();
                getTieList();
              }}
              type="reset"
              className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
            >
              <MdOutlineRefresh className="mr-1" />
              Reset
            </button>
            <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
              <MdOutlineSearch className="mr-1" />
              Search
            </button>
          </div>
        </form>
      </div>
    </>
  );
});
export default Filter;
