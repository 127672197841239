/* eslint-disable use-isnan */
import { observer } from "mobx-react-lite";
import React from "react";
import Select from "react-select";
import { useEffect } from "react";
import {
  MdFlag,
  MdRemoveRedEye,
  MdAdd,
  MdSportsTennis,
  MdLocationCity,
  MdCancel,
} from "react-icons/md";
import { RxPencil1 } from "react-icons/rx";
import { Link, useParams } from "react-router-dom";
import Filter from "./DivisonFilter/Filter";
import {
  useDivisionStore,
  DivisionStore,
  DivisionStoreContext,
} from "./store/DivisionStore";
import TieBreaker from "./TieBreaker";
import Moment from "react-moment";
import moment from "moment";
import { useState } from "react";
import Popup from "reactjs-popup";
import { toast } from "react-toastify";
import { Input } from "react-daisyui";
import { useAuthStore } from "../Auth/store/Auth";
const MatchList = () => {
  const store = new DivisionStore({
    isloading: true,
    playerlist: false,
    Divison: true,
  });
  return (
    <DivisionStoreContext.Provider value={store}>
      <Screen />
    </DivisionStoreContext.Provider>
  );
};

export default MatchList;
const Screen = observer(() => {
  // const history = useHistory();
  const goBack = () => {
    window.history.back(); // Go back to the previous pagehistory.goBack(); // Go back to the previous page
  };
  const {
    mysetone,
    mysettwo,
    mysetthree,
    oppsetwo,
    oppsetone,
    oppsetthree,
    newpop,
    setmyone,
    setoppone,
    setopptwo,
    setoppthree,
    setmythree,
    setmytwo,
    setoppwithdrawvalue,
    setmywithdrawvalue,
    setnewpopup,
    teamOne,
    teamTwo,
    getbuttondisable,
    buttondisable,
    setteamOne,
    setteamTwo,
    setmyforfietvalue,
    setoppforfietvalue,
    PostMatchscore,
    setwithdrawvalue,
    setforfietvalue,
    setradiovalue,
    getmatchid,
    SetScorehomecourt,
    setopenwithdrawpopup,
    setopenVoidpopup,
    voidpopup,
    setopenforfietpopup,
    openforfietpopup,
    openwithdrawpopup,
    loadMatch,
    nolistmatch,
    getMatchList,
    setScoreObject,
    ScoreObject,
    Matchlist,
    setopensubmit,
    opensubmit,
    division_ranks,
    VoidScore,
    setmatchid,
    matchid,
    homecourtdrop,
    getUserhomecourtdropdown,
    getmatchdate,
    getmatchtime,
    matchdate,
    SetStateCheck,
    setThirdScoreCheck,
    ThirdScoreCheck,
    voidloder,
    StateCheck,
    ForfitCheck,
    ForfitCheck1,
    WithdrawCheck,
    WithdrawCheck1,
    myforfietvalue,
    oppforfietvalue,
    getToken
  } = useDivisionStore();
  const { adminAuthApproved } = useAuthStore();
  const { id, dcode, leaguename, check, levelid, levelname, leagueid } =
    useParams();

useEffect(() => {
  if (adminAuthApproved) {
    getToken(localStorage.getItem("Admintoken"));
  }

}, [adminAuthApproved]);
  const [ishome, setIsHome] = useState("");
  const [openforfiet, setopenforfiet] = useState("");
  const [opentable, opentables] = useState("");
  const [openwithdraw, setopenwithdraw] = useState("");

  useEffect(() => {
    getMatchList(id);
    getUserhomecourtdropdown();
  }, []);
  function jumpToNextInput(event, nextInputId) {
    var input = event.target;
    var maxLength = parseInt(input.getAttribute('maxlength'));

    if (input.value.length >= maxLength) {
      document.getElementById(nextInputId).focus();
    }
  }
  const validateNo = (e) => {
    const inputValue = parseInt(e.target.value);
    if (inputValue > 7) {
      e.target.value = "";
    } else {
      e.target.value = e.target.value.replace(/\D/g, "").slice(0, 1);
    }
  };

  if((mysetone > oppsetone ? true : false) &&
(mysettwo > oppsetwo ? true : false) || (oppsetone > mysetone ? true : false) &&
(oppsetwo > mysettwo ? true : false) ){
  setThirdScoreCheck(true);
}
else{
  setThirdScoreCheck(false);

}

if((mysettwo && oppsetwo) == "" || (mysetone && oppsetone) == "" || (isNaN(mysetone) || isNaN(oppsetone) || isNaN(mysettwo) || isNaN(oppsetwo) ) ){
  setmythree("")
  setoppthree("")
}
  return (
    <>
      <div className="flex mb-5 justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold">
            Match List Of {leaguename}- {parseFloat(levelname).toFixed(1)}
          </p>
          <p className="font-bold text-sm my-3">Division {dcode}</p>
          <Link
            to={`/playoffmatches/${leagueid}/${levelid}/${leaguename}`}
            className="font-bold rounded-md p-2 bg-color_theme text-[#000] text-sm mt-3"
          >
            View Playoff
          </Link>
        </div>
        <div>
          <Link
            onClick={goBack}
            // to={check ? "../tiebreaker" : "../divisionmanagement"}
            className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
          >
            Back
          </Link>
        </div>
      </div>
      {/* <Filter /> */}
   
        <div className="xl:px-35 mt-[-19px] lg:px-25 md:px-30 flex flex-col  lg:flex-row  justify-center">
          <div className="lg:w-12/12  w-full py-11 flex flex-col">
            <p className=" text-center  text-[black] text-xl md:text-xl capitalize">
              Matches
              <br />
            </p>
            <div
              id="style-1"
              className="  flex-grow flex-shrink-0 border-[1px] border-color_theme   my-5 max-h-[450px] overflow-auto"
            >
              <div id="partner " className="h-full">
                <table className="table table-zebra w-full h-full " id="partnertable">
                  <thead>
                    <tr className=" sticky top-0 z-20">
                      <th className="px-3 text-left ">#</th>
                      <th className="md:py-5 text-left px-3 ">Team 1</th>
                      <th className="text-left px-3">Team 2</th>
                      <th className="text-left px-3">Date to Play</th>
                      <th className="text-left px-3">Home Court</th>
                      <th>Result</th>
                      <th>Action</th>
                      {/* <th>Ranking</th> */}
                    </tr>
                  </thead>
                  <tbody className="text-xs text-[black] lg:text-sm">
                    {loadMatch && (
                      <>
                        <tr>
                          <td colSpan={7} className="animate-pulse py-6"></td>
                        </tr>
                        <tr>
                          <td colSpan={7} className="animate-pulse py-6"></td>
                        </tr>
                        <tr>
                          <td colSpan={7} className="animate-pulse py-6"></td>
                        </tr>
                        <tr>
                          <td colSpan={7} className="animate-pulse py-6"></td>
                        </tr>
                        <tr>
                          <td colSpan={7} className="animate-pulse py-6"></td>
                        </tr>
                        <tr>
                          <td colSpan={7} className="animate-pulse py-6"></td>
                        </tr>
                      </>
                    )}

                    {Matchlist.map((data, index) => (
                      <tr
                        colSpan={6}
                        className={`  ${
                          data?.status === "void"
                            ? "avoid_class"
                            : "bg-[#282B2D]"
                        }  text-[black] table-row-cut    text-[13px]`}
                      >
                        <td className="px-3 ">{index + 1}</td>
                        <td>
                          {data.team_first_players?.length !== undefined
                            ? data?.team_first_players.map((name, index) => (
                                <div key={index}>
                                  <p className="capitalize whitespace-nowrap">
                                    {name?.firstName + " " + name?.lastName}
                                  </p>
                                </div>
                              ))
                            : "-"}
                        </td>
                        <td>
                          {data.team_second_players?.length !== undefined
                            ? data?.team_second_players.map((name, index) => (
                                <div key={index}>
                                  <p className="capitalize whitespace-nowrap">
                                    {name.firstName + " " + name.lastName}
                                  </p>
                                </div>
                              ))
                            : "-"}
                        </td>

                        <td className="px-1 whitespace-nowrap">
                          {data?.propose_dates.length != 0 &&
                          data?.schedule_date === null ? (
                            <>
                              {data?.propose_dates.map((purpose, index) => (
                                <span key={index} title="Proposed Dates">
                                  {(index ? `/` : "") + " "}{" "}
                                  {
                                    <Moment
                                      format={
                                        purpose?.date?.includes("05")
                                          ? "MMM DD"
                                          : "MMM. DD"
                                      }
                                    >
                                      {purpose?.date}
                                    </Moment>
                                  }
                                </span>
                              ))}{" "}
                            </>
                          ) : (
                            <>
                              {" "}
                              {data?.schedule_date === null &&
                              data?.propose_dates.length === 0 ? (
                                <span
                                  className="flex items-center"
                                  title="Proposed Dates"
                                >
                                  <Moment
                                    format={
                                      data?.schedule_from_date?.includes("05")
                                        ? "MMM DD"
                                        : "MMM. DD"
                                    }
                                  >
                                    {data?.schedule_from_date}
                                  </Moment>
                                  <p className="px-1"> - </p>
                                  <Moment
                                    format={
                                      data?.schedule_to_date?.includes("05")
                                        ? "MMM DD"
                                        : "MMM. DD"
                                    }
                                  >
                                    {data?.schedule_to_date}
                                  </Moment>
                                </span>
                              ) : (
                                <>
                                  <span className="flex" title="Schedule Dates">
                                    {" "}
                                    <Moment
                                      format={
                                        data?.schedule_date?.includes("5")
                                          ? "MMM DD"
                                          : "MMM. DD"
                                      }
                                    >
                                      {data?.schedule_date}
                                    </Moment>
                                    <p className="px-1"> @ </p>
                                    {moment(
                                      new Date(data?.schedule_time)
                                    ).format("hh:mm A")}
                                  </span>
                                </>
                              )}
                            </>
                          )}
                        </td>
                        <td className=" px-3 whitespace-nowrap ">
                          {data?.home_court?.displayName}
                        </td>
                        <td className="py-4 justify-center px-3">
                          <p className="text-[black] whitespace-nowrap text md:text-sm text-xs">
                            {data?.result === "score" ? (
                              <>
                                <span className="border 2xl:mx-2 mx-[1px] lg:p-1">
                                  {data?.team_first_score?.set_one} -
                                  {data?.team_second_score?.set_one}
                                </span>
                                <span className="border 2xl:mx-2 mx-[1px] lg:p-1">
                                  {data?.team_first_score?.set_two}-
                                  {data?.team_second_score?.set_two}
                                </span>
                                {data?.team_first_score?.set_three ||
                                data?.team_second_score?.set_three != null ? (
                                  <span className="border 2xl:mx-2 mx-[1px] lg:p-1">
                                    {data?.team_first_score?.set_three}-
                                    {data?.team_second_score?.set_three}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              <span className="md:text-sm capitalize">
                                {data?.result === "Forfieted" ? (
                                  <span>Forfeited By {data?.forfiet_by}</span>
                                ) : data?.result === "withdrew" ? (
                                  <span>Withdrew By {data?.Withdraw_by}</span>
                                ) : (
                                  "TBA"
                                )}
                              </span>
                            )}
                          </p>
                        </td>
                        {!data?.isEnd ? (
                          <td>
                            {" "}
                            <div className="flex ">
                              {data.status !== "void" ? (
                                <button
                                  title="Score Status"
                                  htmlFor="scoreStatus"
                                  className={`btn capitalize mr-1 bg-color_theme hover:bg-color_theme text-[#000] border-hidden  btn-xs`}
                                  onClick={(e) => {
                                    setnewpopup(true);
                                    if (data?.result === "Forfieted") {
                                      setforfietvalue();
                                    }
                                    if (data?.result === "withdrew") {
                                      setwithdrawvalue();
                                    }
                                    if (data?.result === "score") {
                                      setradiovalue();
                                    }

                                    getmatchid(data?._id);
                                    setIsHome(data?.home_court);
                                    setmyforfietvalue(
                                      data?.team_first_players?.team
                                    );
                                    setmywithdrawvalue(
                                      data?.team_first_players?.team
                                    );
                                    setteamOne(data?.team_first_score?.team);
                                    setteamTwo(data?.team_second_score?.team);
                                    SetScorehomecourt(data?.home_court);
                                    setScoreObject(data);
                                    SetStateCheck(data.result);
                                    setmyone(data?.team_first_score?.set_one);
                                    setoppone(data?.team_second_score?.set_one);
                                    setmytwo(data?.team_first_score?.set_two);
                                    setopptwo(data?.team_second_score?.set_two);
                                    setmythree(
                                      data?.team_first_score?.set_three
                                    );
                                    setoppthree(
                                      data?.team_second_score?.set_three
                                    );
                                    opentables(
                                      data?.result === "score" ? true : false
                                    );
                                    setopenforfiet(
                                      data?.result === "Forfieted"
                                        ? true
                                        : false
                                    );
                                    setopenwithdraw(
                                      data?.result === "withdrew" ? true : false
                                    );
                                  }}
                                >
                                  Edit Score
                                </button>
                              ) : null}

                              {data.status === "void" ? (
                                <label
                                  // htmlFor="voidstatus"
                                  className={`btn capitalize bg-[white] hover:bg-[white] text-[#000] border-hidden  btn-xs`}
                                >
                                  cancelled
                                </label>
                              ) : data?.result === "" ? (
                                <label
                                  htmlFor="voidstatus"
                                  className={`btn capitalize bg-[red] hover:bg-[red] text-[#fff] border-hidden  btn-xs`}
                                  onClick={(e) => {
                                    setmatchid(data?._id);
                                    setopenVoidpopup(true);
                                  }}
                                >
                                  Void
                                </label>
                              ) : null}
                            </div>
                          </td>
                        ) : (
                          <td>
                            <label
                              className={`btn capitalize bg-[red] hover:bg-[red] text-[#fff] border-hidden  btn-xs`}
                            >
                              Closed
                            </label>
                          </td>
                        )}

                        {/* <td className="py-4 justify-center px-3">
                          <p className="text-[black] whitespace-nowrap text md:text-sm text-xs">
                            {data?.result === "score" ? (
                              <>
                                <input
                                  autoFocus={EditScore === true ? true : false}
                                  disabled={EditScore !== true ? true : false}
                                  className="Score_Input border 2xl:mx-2 mx-[1px] lg:p-1"
                                  value={`${data?.team_first_score?.set_one}-${data?.team_second_score?.set_one}`}
                                />

                                <input
                                  autoFocus={EditScore === true ? true : false}
                                  disabled={EditScore !== true ? true : false}
                                  className="Score_Input border 2xl:mx-2 mx-[1px] lg:p-1"
                                  value={`${data?.team_first_score?.set_two}-${data?.team_second_score?.set_two}`}
                                />

                                {data?.team_first_score?.set_three ||
                                data?.team_second_score?.set_three != null ? (
                                  <>
                                    <input
                                      disabled={
                                        EditScore !== true ? true : false
                                      }
                                      autoFocus={
                                        EditScore === true ? true : false
                                      }
                                      className="Score_Input border 2xl:mx-2 mx-[1px] lg:p-1"
                                      value={`${data?.team_first_score?.set_three}-${data?.team_second_score?.set_three}`}
                                    />
                                  
                                      <label
                                        title="Score Status"
                                        typeof="submit"
                                        htmlFor="scoreStatus"
                                        className={`btn ml-3 capitalize ${
                                          data?.isActive
                                            ? "bg-color_theme hover:bg-color_theme"
                                            : "bg-warning hover:bg-warning"
                                        } text-[#000] border-hidden  btn-xs`}
                                        onClick={(e) => {
                                          SetEditScore();
                                        }}
                                      >
                                        Edit Score
                                      
                                      </label>
                               
                                  </>
                                ) : EditScore !== true ? (
                                  <label
                                    title="Score Status"
                                    htmlFor="scoreStatus"
                                    className={`btn ml-3 capitalize ${
                                      data?.isActive
                                        ? "bg-color_theme hover:bg-color_theme"
                                        : "bg-warning hover:bg-warning"
                                    } text-[#000] border-hidden  btn-xs`}
                                    onClick={(e) => {
                                      setopenscore(true);
                                      opentables(false);
                                    }}
                                  >
                                    Edit Score
                                 
                                  </label>
                                ) : (
                                  <label
                                    title="Score Status"
                                    htmlFor="scoreStatus"
                                    className={`btn ml-3 capitalize ${
                                      data?.isActive
                                        ? "bg-color_theme hover:bg-color_theme"
                                        : "bg-warning hover:bg-warning"
                                    } text-[#000] border-hidden  btn-xs`}
                                    onClick={(e) => {
                                      SetEditScore();
                                    }}
                                  >
                                    Update
                                 
                                  </label>
                                )}
                              </>
                            ) : (
                              <span className="md:text-sm capitalize">
                                {data?.result === "Forfieted" ? (
                                  <span>Forfeited By {data?.forfiet_by}</span>
                                ) : (
                                  <span>Withdrew By {data?.Withdraw_by}</span>
                                )}
                              </span>
                            )}
                          </p>
                        </td> */}
                      </tr>
                    ))}
                    

                         {  nolistmatch &&  (
                <tr>
                  <td colSpan={7} className="my-10 text-center">
                    <MdFlag className="  text-8xl mx-auto" />
                    <p className="text-[black]  font-semibold text-lg"> Schedule Has Not Been Released Yet.</p>
                    
                  </td>
                </tr>
              )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="lg:w-1/3 w-full  py-11 flex flex-col pl-[0.25rem]">
            <p className=" text-center text-[black] text-xl md:text-xl capitalize">
              Division Standings
              <br />
            </p>
            <div
              id="style-1"
              className="  flex-grow flex-shrink-0 border-[1px] border-color_theme    bg-opacity-40  my-5 max-h-[450px] overflow-auto"
            >
              <div id="partner">
                <table className="table table-zebra w-full  " id="partnertable">
                  <thead>
                    <tr className="  sticky top-0 z-20">
                      {leaguename.includes("Singles") ? (
                        <th className="md:py-5 text-left px-3 ">Player</th>
                      ) : (
                        <th className="md:py-5 text-left px-3 ">Team</th>
                      )}
                      <th className="px-3">W</th>
                      <th className="px-3">L</th>
                      <th className="px-3">Points</th>
                      {/* <th>Ranking</th> */}
                    </tr>
                  </thead>

                  <tbody className="text-xs bg-[#282B2D] text-[black] lg:text-sm">
                    {division_ranks.map((res, index) => (
                      <tr
                        key={index}
                        className="hover:bg-[#39fd13] hover:bg-opacity-5"
                      >
                        <td className="text-xs px-3 py-[.70rem] ">
                          {/* <span
                            title="View Profile"
                            className="capitalize cursor-pointer  hover:text-color_theme"
                          >
                            {res?.firstName ??"N/A"} {res?.lastName}
                            
                          </span> */}

                          {res?.names?.length === 0 ? (
                            <span>-</span>
                          ) : (
                            res?.names?.map((name, index) => (
                              <div key={index}>
                                <p className="capitalize whitespace-nowrap">
                                  {name?.firstName + " " + name?.lastName}
                                </p>
                              </div>
                            ))
                          )}
                        </td>
                        <td className="py-[.70rem] text-base text-center">
                          <p>{res?.win ?? 0}</p>
                        </td>
                        <td className=" py-[.70rem] text-base text-center">
                          <p>{res?.loss ?? 0}</p>
                        </td>
                        <td className=" py-[.70rem] text-base text-center">
                          <p>{res?.total ?? 0}</p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div></div>
          <Popup
            open={newpop}
            contentStyle={{ width: "50%", border: "none", background: "none" }}
            closeOnDocumentClick={false}
            className=""
            onClose={() => {
              setnewpopup(false);
            }}
          >
            <div className="  md:w-full lg:w-full 2xl:w-full bg-[#31343B] w-[90%] move overflow-auto max-h-[600px] rounded  relative">
              <div className="float-right   ">
                <button
                  className=" text-[#ffffff] text-2xl absolute right-[50px] font-poppins  md:leading-[2] top-[21px]"
                  onClick={() => {
                    setnewpopup(false);
                    // popfun();
                  }}
                >
                  <MdCancel />
                </button>
              </div>
              <p className=" text-center text-[#fff] font-poppins  mb-5 text-3xl md:leading-[0] md:text-2xl mt-11">
                Add Match Result
                <br />
              </p>
              <div className="px-3 flex justify-center ">
                <Select
                  id="location"
                  options={homecourtdrop}
                  placeholder="Home Court"
                  onChange={(e) => {
                    setIsHome(e);
                    SetScorehomecourt(e);
                  }}
                  getOptionLabel={(homecourtdrop) => homecourtdrop.displayName}
                  getOptionValue={(homecourtdrop) => homecourtdrop._id}
                  value={ishome}
                  // defaultInputValue={ishome}
                  isSearchable={true}
                  // isClearable
                  isMulti={false}
                  className=" bg-[#f1f1f1] text-[#837575] item w-8/12  rounded-lg focus:outline-none"
                  // menuIsOpen={}
                  onMenuOpen={() => {
                    setIsHome(null);
                  }}
                />
              </div>
              {StateCheck === "" ? (
                <div className="mt-3 flex justify-center items-center px-[30%] ">
                  <Input
                    name="matchdate"
                    placeholder=""
                    // disabled={selectyear === null ? true : false}
                    onChange={(e) => {
                      const selectedDateTime = new Date(e.target.value);
                      const hours = selectedDateTime.getHours();
                      const minutes = selectedDateTime.getMinutes();
                      const amPm = hours >= 12 ? "PM" : "AM";
                      const hours12 = hours % 12 || 12; // Convert to 12-hour format
                      const timeString = `${hours12}:${minutes
                        .toString()
                        .padStart(2, "0")} ${amPm}`;
                      getmatchdate(e.target.value);
                      getmatchtime(timeString);
                      getmatchdate(e.target.value);
                    }}
                    id="matchdate"
                    type="datetime-local"
                    autoComplete="off"
                    className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
                  />
                </div>
              ) : null}

              <div className="flex my-10 mx-6 justify-around ">
                <div className="flex items-center">
                  <input
                    className=" md:h-[20px]   md:w-[20px] cursor-pointer"
                    //   id={index}
                    onClick={(e) => {
                      opentables(true);
                      setopenforfiet(false);
                      setopenwithdraw(false);
                    }}
                    onChange={(e) => {
                      setradiovalue(e.target.value);
                    }}
                    type="radio"
                    name="myCheckbox"
                    value="score"
                    checked={opentable}
                  />
                  <p className="md:ml-3 2xl:text-[28px] md:text-[20px] text-[#fff] font-light font-poppins ">
                    Score
                  </p>
                </div>
                <div className="flex md:ml-20 items-center">
                  <input
                    className=" md:h-[20px]   md:w-[20px] cursor-pointer"
                    //   id={index}
                    onClick={(e) => {
                      setopenforfiet(true);
                      opentables(false);
                      setopenwithdraw(false);
                      setforfietvalue(e.target.value);
                    }}
                    checked={openforfiet}
                    type="radio"
                    name="myCheckbox"
                    value="Forfieted"
                  />
                  <p className="md:ml-3 2xl:text-[28px] md:text-[20px] text-[#fff] font-light font-poppins ">
                    Forfiet
                  </p>
                </div>
                <div className="flex md:ml-20 items-center">
                  <input
                    className=" md:h-[20px]  md:w-[20px] cursor-pointer"
                    //   id={index}
                    onClick={(e) => {
                      setopenwithdraw(true);
                      opentables(false);
                      setopenforfiet(false);
                      setwithdrawvalue(e.target.value);
                    }}
                    checked={openwithdraw}
                    type="radio"
                    name="myCheckbox"
                    value="withdrew"
                  />
                  <p className="md:ml-3 2xl:text-[28px] md:text-[20px] text-[#fff] font-light font-poppins ">
                    Withdraw
                  </p>
                </div>
              </div>
              {opentable ? (
                <>
                  <form
                    onSubmit={(e) => {
                      // PostMatchscore();
                      e.preventDefault();
                    }}
                  >
                    <div className="">
                      <ul className=" ">
                        <li>
                          <div className="flex justify-end space-x-10 2xl:space-x-2 my-10 mr-[2.55rem] mx-3 text-[#fff] 2xl:text-3xl text-xl ">
                            <p>Team 1</p>

                            <p>Team 2</p>
                          </div>
                        </li>

                        <li className="flex px-10 justify-between md:mb-5 border-[#7E7777B5]  border-b-2">
                          <div className="2xl:text-[29.42px] md:text-[20px] text-color_theme font-extralight ">
                            Round One
                          </div>

                          <div className="flex justify-end space-x-14 2xl:space-x-20 mx-[10px] mb-3">
                            <input
                              name="setNo"
                              required
                              // autoComplete="setNo"
                              className=" scoreinput w-1/4 h-9 border-2 number font-bold border-[#fff] text-center  p-2 px-3 relative"
                              type="number"
                              min="1"
                              max="99"
                              id="input1"
                              maxLength="1"
                              // defaultValue={ScoreObject?.team_first_score?.set_one}
                              // value={ScoreObject?.team_first_score?.set_one }
                              value={mysetone}
                              onInput={(e) => {
                                validateNo(e);
                               jumpToNextInput(e, "input2" )                            

                              }}
                              onChange={(e) => {
                                setmyone(e.target.value);
                              }}
                              //  value={}
                            />

                            <input
                              name="setNo"
                              required
                              // autoComplete="setNo"
                              className="scoreinput w-1/4 h-9 border-2 font-bold border-[#fff] text-center  p-2 px-3 relative"
                              type="number"
                              min="1"
                              max="99"
                              id="input2"
                              maxLength="1"
                              // defaultValue={ScoreObject?.team_second_score?.set_one}
                              // value={ScoreObject?.team_second_score?.set_one }
                              value={oppsetone}
                              onInput={(e) => {
                                validateNo(e);
                               jumpToNextInput(e, "input3" )                            
                              }}
                              onChange={(e) => {
                                setoppone(e.target.value);
                              }}
                              // onChange={(e) => {
                              //   setoppone(e.target.value);
                              // }}
                            />
                          </div>
                        </li>
                        <li className="flex px-10 justify-between md:mb-5 border-[#7E7777B5]  border-b-2">
                          <div className="2xl:text-[29.42px] md:text-[20px] text-color_theme font-extralight ">
                            Round Two
                          </div>

                          <div className="flex justify-end space-x-14 2xl:space-x-20 mx-[10px] mb-3">
                            <input
                              name="setNo"
                              required
                              // autoComplete="setNo"
                              id="input3"
                              maxLength="1"
                              className="scoreinput w-1/4 h-9 number border-2 font-bold border-[#fff] text-center  p-2 px-3 relative"
                              type="number"
                              min="1"
                              max="99"
                              // defaultValue={ScoreObject?.team_first_score?.set_two}
                              // value=  {ScoreObject?.team_first_score?.set_two}
                              value={mysettwo}
                              onInput={(e) => {
                                validateNo(e);
                               jumpToNextInput(e, "input4" )                            
                              }}
                              onChange={(e) => {
                                setmytwo(e.target.value);
                              }}
                            />

                            <input
                              name="setNo"
                              // autoComplete="setNo"
                              required
                              id="input4"
                              maxLength="1"
                              className="scoreinput w-1/4 h-9 border-2 number border-[#fff] text-center font-bold  p-2 px-3 relative"
                              type="number"
                              min="1"
                              max="99"
                              // defaultValue={ScoreObject?.team_second_score?.set_two}
                              // value={ScoreObject?.team_second_score?.set_two}
                              value={oppsetwo}
                              onInput={(e) => {
                                validateNo(e);
                               jumpToNextInput(e, "input5" )                            
                              }}
                              onChange={(e) => {
                                setopptwo(e.target.value);
                              }}
                            />
                          </div>
                        </li>
                        <li className="flex px-10 justify-between md:mb-5 border-[#7E7777B5]  border-b-2">
                          <div className="2xl:text-[29.42px] md:text-[20px] text-color_theme font-extralight ">
                            Round Three
                          </div>

                          <div className="flex justify-end space-x-14 2xl:space-x-20 mx-[10px] mb-3">
                            <input
                              name="setNo"
                              // autoComplete="setNo"
                              id="input5"
                              readOnly={ThirdScoreCheck || (mysettwo && oppsetwo) == "" || (mysetone && oppsetone) == "" || (isNaN(mysetone) || isNaN(oppsetone) || isNaN(mysettwo) || isNaN(oppsetwo) ) ? true : false }

                              className={`scoreinput w-1/4 h-9 number border-2 font-bold border-[#fff] text-center  p-2 px-3 relative ${ThirdScoreCheck || (mysettwo && oppsetwo) == "" || (mysetone && oppsetone) == "" || (isNaN(mysetone) || isNaN(oppsetone) || isNaN(mysettwo) || isNaN(oppsetwo) )  ? "bg-[gray] cursor-not-allowed" : ""} `}
                              autoComplete="off"
                              maxLength="1"
                              type="number"
                              min="1"
                              max="99"
                              // defaultValue={ScoreObject?.team_first_score?.set_three}
                              // value={ScoreObject?.team_first_score?.set_three}
                              value={mysetthree}
                              onInput={(e) => {
                                validateNo(e);
                               jumpToNextInput(e, "input6" )                            
                              }}
                              onChange={(e) => {
                                setmythree(e.target.value);
                              }}
                            />

                            <input
                              name="setNo"
                              // autoComplete="setNo"
                              id="input6"
                              readOnly={ThirdScoreCheck || (mysettwo && oppsetwo) == "" || (mysetone && oppsetone) == "" || (isNaN(mysetone) || isNaN(oppsetone) || isNaN(mysettwo) || isNaN(oppsetwo) ) ? true : false }

                              className={`scoreinput w-1/4 h-9 number border-2 font-bold border-[#fff] text-center  p-2 px-3 relative ${ThirdScoreCheck || (mysettwo && oppsetwo) == "" || (mysetone && oppsetone) == "" || (isNaN(mysetone) || isNaN(oppsetone) || isNaN(mysettwo) || isNaN(oppsetwo) )  ? "bg-[gray] cursor-not-allowed" : ""} `}

                       
                              type="number"
                              min="1"
                              max="99"
                              // defaultValue={ScoreObject?.team_second_score?.set_three}
                              // value={ScoreObject?.team_second_score?.set_three}
                              value={oppsetthree}
                              onInput={(e) => {
                                validateNo(e);
                              }}
                              onChange={(e) => {
                                setoppthree(e.target.value);
                              }}
                            />
                          </div>
                        </li>
                      </ul>

                      {isNaN(mysetone) ||
                      isNaN(oppsetone) ||
                      isNaN(mysettwo) ||
                      isNaN(oppsetwo) ||
                      isNaN(mysetthree) ||
                      isNaN(oppsetthree) === false ? (
                        <div className="flex my-5 justify-center">
                        
                          {(mysetone > oppsetone ? true : false) &&
                          (mysettwo > oppsetwo ? true : false) &&
                          (mysetthree > oppsetthree ? true : false) &&
                          !isNaN(mysetone) &&
                          !isNaN(oppsetone) &&
                          !isNaN(mysettwo) &&
                          !isNaN(oppsetwo)  ? (
                            <>
                              {(mysetone === 6 ||
                                mysetone === 7 ||
                                oppsetone === 6 ||
                                oppsetone === 7) &&
                              (mysettwo === 6 ||
                                mysettwo === 7 ||
                                oppsetwo === 6 ||
                                oppsetwo === 7) &&
                              (mysetthree === 6 ||
                                isNaN(mysetthree) ||
                                mysetthree === 7 ||
                                isNaN(mysetthree) ||
                                oppsetthree === 6 ||
                                isNaN(mysetthree) ||
                                oppsetthree === 7 ||
                                isNaN(mysetthree)) &&
                              ((mysetone === 6 && oppsetone <= 4) ||
                                (mysetone === 7 && oppsetone >= 5) ||
                                oppsetone === "" ||
                                (oppsetone === 6 && mysetone <= 4) ||
                                (oppsetone === 7 && mysetone >= 5) ||
                                mysetone === "") &&
                              ((mysettwo === 6 && oppsetwo <= 4) ||
                                (mysettwo === 7 && oppsetwo >= 5) ||
                                oppsetwo === "" ||
                                (oppsetwo === 6 && mysettwo <= 4) ||
                                (oppsetwo === 7 && mysettwo >= 5) ||
                                mysettwo === "") &&
                              ((mysetthree === 6 && oppsetthree <= 4) ||
                                (mysetthree === 7 && oppsetthree >= 5) ||
                                oppsetthree == "" ||
                                isNaN(oppsetthree) ||
                                (oppsetthree === 6 && mysetthree <= 4) ||
                                (oppsetthree === 7 && mysetthree >= 5) ||
                                mysetthree == "" ||
                                isNaN(mysetthree)) ? (
                                <button
                                  className="font-medium bg-color_theme md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                                  //  type="submit"
                                  onClick={() => {
                                    // setnewpopup(false);
                                    setopensubmit(true);
                                  }}
                                >
                                  Submit
                                </button>
                              ) : (
                                <button
                                  className="font-medium bg-color_theme md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                                  //  type="submit"
                                  onClick={() => {
                                    // setnewpopup(false);
                                    toast.error(`Please Check Your Score.`);
                                  }}
                                >
                                  Submit
                                </button>
                              )}
                            </>
                          ) : (mysetone < oppsetone ? true : false) &&
                            (mysettwo < oppsetwo ? true : false) &&
                            !isNaN(mysetone) &&
                            !isNaN(oppsetone) &&
                            !isNaN(mysettwo) &&
                            !isNaN(oppsetwo)? (
                            (mysetone === 6 ||
                              mysetone === 7 ||
                              oppsetone === 6 ||
                              oppsetone === 7) &&
                            (mysettwo === 6 ||
                              mysettwo === 7 ||
                              oppsetwo === 6 ||
                              oppsetwo === 7) &&
                            (mysetthree === 6 ||
                              isNaN(mysetthree) ||
                              mysetthree === 7 ||
                              isNaN(mysetthree) ||
                              oppsetthree === 6 ||
                              isNaN(mysetthree) ||
                              oppsetthree === 7 ||
                              isNaN(mysetthree)) &&
                            ((mysetone === 6 && oppsetone <= 4) ||
                              (mysetone === 7 && oppsetone >= 5) ||
                              oppsetone === "" ||
                              (oppsetone === 6 && mysetone <= 4) ||
                              (oppsetone === 7 && mysetone >= 5) ||
                              mysetone === "") &&
                            ((mysettwo === 6 && oppsetwo <= 4) ||
                              (mysettwo === 7 && oppsetwo >= 5) ||
                              oppsetwo === "" ||
                              (oppsetwo === 6 && mysettwo <= 4) ||
                              (oppsetwo === 7 && mysettwo >= 5) ||
                              mysettwo === "") &&
                            ((mysetthree === 6 && oppsetthree <= 4) ||
                              (mysetthree === 7 && oppsetthree >= 5) ||
                              oppsetthree == "" ||
                              isNaN(oppsetthree) ||
                              (oppsetthree === 6 && mysetthree <= 4) ||
                              (oppsetthree === 7 && mysetthree >= 5) ||
                              mysetthree == "" ||
                              isNaN(mysetthree)) ? (
                              <button
                                className="font-medium bg-color_theme md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                                //  type="submit"
                                onClick={() => {
                                  // setnewpopup(false);
                                  setopensubmit(true);
                                }}
                              >
                                Submit
                              </button>
                            ) : (
                              <button
                                className="font-medium bg-color_theme md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                                //  type="submit"
                                onClick={() => {
                                  // setnewpopup(false);
                                  toast.error(`Please Check Your Score.`);
                                }}
                              >
                                Submit
                              </button>
                            )
                          ) : (isNaN(mysetone) ||
                            isNaN(oppsetone) ||
                            isNaN(mysettwo) ||
                            isNaN(oppsetwo))  ? (
                            <button
                              className="font-medium opacity-50 bg-color_theme md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                              //  type="button"
                              disabled
                            >
                              Submit
                            </button>
                          ) :  mysetthree != oppsetthree &&
                            mysetone != oppsetone &&
                            mysettwo != oppsetwo 
                            ? (
                            (mysetone === 6 ||
                              mysetone === 7 ||
                              oppsetone === 6 ||
                              oppsetone === 7) &&
                            (mysettwo === 6 ||
                              mysettwo === 7 ||
                              oppsetwo === 6 ||
                              oppsetwo === 7) &&
                            (mysetthree === 6 ||
                              isNaN(mysetthree) ||
                              mysetthree === 7 ||
                              isNaN(mysetthree) ||
                              oppsetthree === 6 ||
                              isNaN(mysetthree) ||
                              oppsetthree === 7 ||
                              isNaN(mysetthree)) &&
                            ((mysetone === 6 && oppsetone <= 4) ||
                              (mysetone === 7 && oppsetone >= 5) ||
                              oppsetone === "" ||
                              (oppsetone === 6 && mysetone <= 4) ||
                              (oppsetone === 7 && mysetone >= 5) ||
                              mysetone === "") &&
                            ((mysettwo === 6 && oppsetwo <= 4) ||
                              (mysettwo === 7 && oppsetwo >= 5) ||
                              oppsetwo === "" ||
                              (oppsetwo === 6 && mysettwo <= 4) ||
                              (oppsetwo === 7 && mysettwo >= 5) ||
                              mysettwo === "") &&
                            ((mysetthree === 6 && oppsetthree <= 4) ||
                              (mysetthree === 7 && oppsetthree >= 5) ||
                              oppsetthree == "" ||
                              isNaN(oppsetthree) ||
                              (oppsetthree === 6 && mysetthree <= 4) ||
                              (oppsetthree === 7 && mysetthree >= 5) ||
                              mysetthree == "" ||
                              isNaN(mysetthree)) ? (

                               ( ThirdScoreCheck || (mysettwo && oppsetwo) == "" || (mysetone && oppsetone) == "" || (isNaN(mysetone) || isNaN(oppsetone) || isNaN(mysettwo) || isNaN(oppsetwo) )) == false && (!isNaN(mysetthree) && !isNaN(oppsetthree)) == false  ?
                                <button
                                className="font-medium opacity-50 bg-color_theme md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                                //  type="button"
                                disabled
                              >
                                Submit
                              </button>
                              :

                              <button
                                className="font-medium bg-color_theme md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                                //  type="submit"
                                onClick={() => {
                                  // setnewpopup(false);
                                  setopensubmit(true);
                                }}
                              >
                                Submit
                              </button>
                            ) : (
                              <button
                                className="font-medium bg-color_theme md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                                //  type="submit"
                                onClick={() => {
                                  // setnewpopup(false);
                                  toast.error(`Please Check Your Score.`);
                                }}
                              >
                                Submit
                              </button>
                            )
                          ) : (
                            <button
                              className="font-medium opacity-50 bg-color_theme md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                              //  type="button"
                              disabled
                            >
                              Submit
                            </button>
                          )}

                          {/* {mysetone == "" ||
mysettwo == "" ||
oppsetone == "" ||
oppsetwo == "" ||
(mysetthree && oppsetthree == "") ||
(oppsetthree && mysetthree == "") ? (
  <button
    className="font-medium opacity-50 bg-color_theme md:px-16 px-3 md:w-auto  py-2 rounded-lg "
    //  type="button"
    disabled
  >
    Submit
  </button>
) : ishome === null ? (
  <button
    className="font-medium opacity-50 bg-color_theme md:px-16 px-3 md:w-auto  py-2 rounded-lg "
    //  type="button"
    disabled
  >
    Submit
  </button>
) : (
  <button
    className="font-medium bg-color_theme md:px-16 px-3 md:w-auto  py-2 rounded-lg "
    //  type="submit"
    onClick={() => {
      // setnewpopup(false);
      setopensubmit(true);
    }}
  >
    Submit
  </button>
)} */}
                        </div>
                      ) : (
                        <div className="flex my-5 justify-center">
                          

                          {(mysetone > oppsetone ? true : false) &&
                          (mysettwo > oppsetwo ? true : false) &&
                          !isNaN(mysetone) &&
                          !isNaN(oppsetone) &&
                          !isNaN(mysettwo) &&
                          !isNaN(oppsetwo) &&
                          !isNaN(mysetthree) &&
                          !isNaN(oppsetthree) ? (
                            <>
                              {(mysetone === 6 ||
                                mysetone === 7 ||
                                oppsetone === 6 ||
                                oppsetone === 7) &&
                              (mysettwo === 6 ||
                                mysettwo === 7 ||
                                oppsetwo === 6 ||
                                oppsetwo === 7) &&
                              (mysetthree === 6 ||
                                isNaN(mysetthree) ||
                                mysetthree === 7 ||
                                isNaN(mysetthree) ||
                                oppsetthree === 6 ||
                                isNaN(mysetthree) ||
                                oppsetthree === 7 ||
                                isNaN(mysetthree)) &&
                              ((mysetone === 6 && oppsetone <= 4) ||
                                (mysetone === 7 && oppsetone >= 5) ||
                                oppsetone === "" ||
                                (oppsetone === 6 && mysetone <= 4) ||
                                (oppsetone === 7 && mysetone >= 5) ||
                                mysetone === "") &&
                              ((mysettwo === 6 && oppsetwo <= 4) ||
                                (mysettwo === 7 && oppsetwo >= 5) ||
                                oppsetwo === "" ||
                                (oppsetwo === 6 && mysettwo <= 4) ||
                                (oppsetwo === 7 && mysettwo >= 5) ||
                                mysettwo === "") &&
                              ((mysetthree === 6 && oppsetthree <= 4) ||
                                (mysetthree === 7 && oppsetthree >= 5) ||
                                oppsetthree == "" ||
                                isNaN(oppsetthree) ||
                                (oppsetthree === 6 && mysetthree <= 4) ||
                                (oppsetthree === 7 && mysetthree >= 5) ||
                                mysetthree == "" ||
                                isNaN(mysetthree)) ? (
                                <button
                                  className="font-medium bg-color_theme md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                                  //  type="submit"
                                  onClick={() => {
                                    // setnewpopup(false);
                                    setopensubmit(true);
                                  }}
                                >
                                  Submit
                                </button>
                              ) : (
                                <button
                                  className="font-medium bg-color_theme md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                                  //  type="submit"
                                  onClick={() => {
                                    // setnewpopup(false);
                                    toast.error(`Please Check Your Score.`);
                                  }}
                                >
                                  Submit
                                </button>
                              )}
                            </>
                          ) : (mysetone < oppsetone ? true : false) &&
                            (mysettwo < oppsetwo ? true : false) &&
                            !isNaN(mysetone) &&
                            !isNaN(oppsetone) &&
                            !isNaN(mysettwo) &&
                            !isNaN(oppsetwo) ? (
                            (mysetone === 6 ||
                              mysetone === 7 ||
                              oppsetone === 6 ||
                              oppsetone === 7) &&
                            (mysettwo === 6 ||
                              mysettwo === 7 ||
                              oppsetwo === 6 ||
                              oppsetwo === 7) &&
                            (mysetthree === 6 ||
                              !isNaN(mysetthree) ||
                              mysetthree === 7 ||
                              !isNaN(mysetthree) ||
                              oppsetthree === 6 ||
                              !isNaN(mysetthree) ||
                              oppsetthree === 7 ||
                              !isNaN(mysetthree)) &&
                            ((mysetone === 6 && oppsetone <= 4) ||
                              (mysetone === 7 && oppsetone >= 5) ||
                              oppsetone === "" ||
                              (oppsetone === 6 && mysetone <= 4) ||
                              (oppsetone === 7 && mysetone >= 5) ||
                              mysetone === "") &&
                            ((mysettwo === 6 && oppsetwo <= 4) ||
                              (mysettwo === 7 && oppsetwo >= 5) ||
                              oppsetwo === "" ||
                              (oppsetwo === 6 && mysettwo <= 4) ||
                              (oppsetwo === 7 && mysettwo >= 5) ||
                              mysettwo === "") &&
                            ((mysetthree === 6 && oppsetthree <= 4) ||
                              (mysetthree === 7 && oppsetthree >= 5) ||
                              oppsetthree == "" ||
                              isNaN(oppsetthree) ||
                              (oppsetthree === 6 && mysetthree <= 4) ||
                              (oppsetthree === 7 && mysetthree >= 5) ||
                              mysetthree == "" ||
                              isNaN(mysetthree)) ? (
                              <button
                                className="font-medium bg-color_theme md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                                //  type="submit"
                                onClick={() => {
                                  // setnewpopup(false);
                                  setopensubmit(true);
                                }}
                              >
                                Submit
                              </button>
                            ) : (
                              <button
                                className="font-medium bg-color_theme md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                                //  type="submit"
                                onClick={() => {
                                  // setnewpopup(false);
                                  toast.error(`Please Check Your Score.`);
                                }}
                              >
                                Submit
                              </button>
                            )
                          ) : !isNaN(mysetthree) || !isNaN(oppsetthree) ? (
                            <button
                              className="font-medium opacity-50 bg-color_theme md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                              //  type="button"
                              disabled
                            >
                              Submit
                            </button>
                          ) : mysetthree != oppsetthree &&
                            mysetone != oppsetone &&
                            mysettwo != oppsetwo ? (
                            (mysetone === 6 ||
                              mysetone === 7 ||
                              oppsetone === 6 ||
                              oppsetone === 7) &&
                            (mysettwo === 6 ||
                              mysettwo === 7 ||
                              oppsetwo === 6 ||
                              oppsetwo === 7) &&
                            (mysetthree === 6 ||
                              isNaN(mysetthree) ||
                              mysetthree === 7 ||
                              isNaN(mysetthree) ||
                              oppsetthree === 6 ||
                              isNaN(mysetthree) ||
                              oppsetthree === 7 ||
                              isNaN(mysetthree)) &&
                            ((mysetone === 6 && oppsetone <= 4) ||
                              (mysetone === 7 && oppsetone >= 5) ||
                              oppsetone === "" ||
                              (oppsetone === 6 && mysetone <= 4) ||
                              (oppsetone === 7 && mysetone >= 5) ||
                              mysetone === "") &&
                            ((mysettwo === 6 && oppsetwo <= 4) ||
                              (mysettwo === 7 && oppsetwo >= 5) ||
                              oppsetwo === "" ||
                              (oppsetwo === 6 && mysettwo <= 4) ||
                              (oppsetwo === 7 && mysettwo >= 5) ||
                              mysettwo === "") &&
                            ((mysetthree === 6 && oppsetthree <= 4) ||
                              (mysetthree === 7 && oppsetthree >= 5) ||
                              oppsetthree == "" ||
                              isNaN(oppsetthree) ||
                              (oppsetthree === 6 && mysetthree <= 4) ||
                              (oppsetthree === 7 && mysetthree >= 5) ||
                              mysetthree == "" ||
                              isNaN(mysetthree)) ? (
                              <button
                                className="font-medium bg-color_theme md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                                //  type="submit"
                                onClick={() => {
                                  // setnewpopup(false);
                                  setopensubmit(true);
                                }}
                              >
                                Submit
                              </button>
                            ) : (
                              <button
                                className="font-medium bg-color_theme md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                                //  type="submit"
                                onClick={() => {
                                  // setnewpopup(false);
                                  toast.error(`Please Check Your Score.`);
                                }}
                              >
                                Submit
                              </button>
                            )
                          ) : (
                            <button
                              className="font-medium opacity-50 bg-color_theme md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                              //  type="button"
                              disabled
                            >
                              Submit
                            </button>
                          )}

                          {/* {mysetone == "" ||
mysettwo == "" ||
oppsetone == "" ||
oppsetwo == "" ||
(mysetthree && oppsetthree == "") ||
(oppsetthree && mysetthree == "") ? (
  <button
    className="font-medium opacity-50 bg-color_theme md:px-16 px-3 md:w-auto  py-2 rounded-lg "
    //  type="button"
    disabled
  >
    Submit
  </button>
) : ishome === null ? (
  <button
    className="font-medium opacity-50 bg-color_theme md:px-16 px-3 md:w-auto  py-2 rounded-lg "
    //  type="button"
    disabled
  >
    Submit
  </button>
) : (
  <button
    className="font-medium bg-color_theme md:px-16 px-3 md:w-auto  py-2 rounded-lg "
    //  type="submit"
    onClick={() => {
      // setnewpopup(false);
      setopensubmit(true);
    }}
  >
    Submit
  </button>
)} */}
                        </div>
                      )}
                    </div>

                    <Popup
                      open={opensubmit}
                      contentStyle={{
                        width: "50%",
                        border: "none",
                        background: "none",
                      }}
                      closeOnDocumentClick={false}
                      className=""
                      onClose={() => {
                        setopensubmit(false);
                      }}
                    >
                      <div className=" border-2 border-[white] px-1 w-3/4 m-auto bg-[#31343B] overflow-auto max-h-[600px] rounded  relative">
                        <div className="float-right   ">
                          <button
                            className=" text-[#fff] text-2xl absolute   md:leading-[2] top-[0px] right-[10px]"
                            // type="button"
                            onClick={() => {
                              // setopensubmit(false);
                              // setnewpopup(true)
                              getbuttondisable(false);
                              setopensubmit(false);
                            }}
                          >
                            X
                          </button>
                        </div>
                        <p className=" text-center text-[#fff]  text-3xl md:leading-[0] md:text-2xl mt-16">
                          Are you sure, you want to submit this result?
                          <br />
                        </p>
                        {/* <p className=" text-center text-[#fff] my-5">
                          Once submitted, it cannot be reverted back
                        </p> */}
                        <div className="flex justify-center text-lg  my-10 items-center">
                          <button
                            onClick={() => setopensubmit(false)}
                            className="bg-[red] px-3 mx-5 rounded-md"
                          >
                            No
                          </button>

                          {!buttondisable ? (
                            <button
                              // type="submit"
                              className="bg-color_theme px-3 mx-5 rounded-md"
                              onClick={() => {
                                PostMatchscore(id);
                              }}
                            >
                              Yes
                            </button>
                          ) : (
                            <button
                              // type="submit"
                              disabled
                              className="flex items-center bg-color_theme px-3 mx-5 rounded-md"
                              // onClick={() => {
                              //   PostMatchscore();
                              // }}
                            >
                              Yes..
                              <svg
                                className="animate-spin mr-1 h-5 w-5 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                            </button>
                          )}
                        </div>
                      </div>
                    </Popup>
                  </form>
                </>
              ) : (
                ""
              )}
              {openforfiet ? (
                <>
                  <form
                    onSubmit={(e) => {
                      // PostMatchscore();
                      e.preventDefault();
                    }}
                  >
                    <div>
                      <p className="text-[#fff] 2xl:text-4xl md:text-2xl text-center my-4">
                        Forfieted By
                      </p>
                      <div className="flex justify-around">
                        <div className="flex items-center">
                          <input
                            className=" md:h-[25px]  cursor-pointer  md:w-[25px]"
                            type="radio"
                            name="Checkbox"
                            checked={
                              ScoreObject?.forfiet_by === "Team 1"
                                ? true
                                : undefined
                            }
                            onChange={(e) => {
                              setmyforfietvalue(teamOne, e);
                            }}
                          />

                          <label className=" ml-2 text-[#fff] text-lg">
                            Team 1
                          </label>
                        </div>
                        <div className="flex items-center">
                          <input
                            className=" md:h-[25px]  cursor-pointer  md:w-[25px]"
                            type="radio"
                            name="Checkbox"
                            checked={
                              ScoreObject?.forfiet_by === "Team 2"
                                ? true
                                : undefined
                            }
                            onChange={(e) => {
                              setoppforfietvalue(teamTwo, e);
                            }}
                          />

                          <label className="ml-2 text-[#fff] text-lg">
                            {" "}
                            Team 2
                          </label>
                        </div>
                      </div>

                      <div className="flex my-5 justify-center">
                        {/* {(myforfietvalue == "" && oppforfietvalue == "") ? (
                      <button
                        // onClick={()=> }
                        className="font-medium opacity-50 bg-color_theme md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                        // type="button"
                        disabled
                      >
                        Submit
                      </button>
                    ) 
                     : ( */}
                        <button
                          className="font-bold bg-color_theme md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                          //  type="submit"
                          disabled={
                            (ForfitCheck === undefined || ForfitCheck === "") &&
                            (ForfitCheck1 === undefined ||
                              ForfitCheck1 === "") &&
                            ScoreObject?.forfiet_by === ""
                              ? true
                              : false
                          }
                          onClick={() => {
                            // setnewpopup(false);
                            setopenforfietpopup(true);
                          }}
                        >
                          Submit
                        </button>
                        {/* )} */}
                      </div>
                    </div>
                    <Popup
                      open={openforfietpopup}
                      closeOnDocumentClick={false}
                      contentStyle={{
                        width: "50%",
                        border: "none",
                        background: "none",
                      }}
                      className=""
                      onClose={() => {
                        setopenforfietpopup(false);
                      }}
                    >
                      <div className="border-2 border-[white] px-1 w-3/4 m-auto bg-[#31343B] move overflow-auto max-h-[600px] rounded  relative">
                        <div className="float-right   ">
                          <button
                            className=" text-[#ffffff] text-2xl absolute  md:leading-[2]  top-[0px] right-[10px]"
                            // type="button"
                            onClick={() => {
                              // setopensubmit(false);
                              // setnewpopup(true)
                              setopenforfietpopup(false);
                            }}
                          >
                            X
                          </button>
                        </div>
                        <p className=" text-center text-[#fff]   text-3xl md:leading-[0] md:text-2xl mt-16">
                          Are you sure, you want to submit this result?
                          <br />
                        </p>
                        {/* <p className=" text-center text-[#fff] my-5">
                          Once submitted, it cannot be reverted back
                        </p> */}
                        <div className="flex justify-center text-lg  my-10 items-center">
                          <button
                            onClick={() => setopenforfietpopup(false)}
                            className="bg-[red] px-3 mx-5 rounded-md"
                          >
                            No
                          </button>

                          {!buttondisable ? (
                            <button
                              // type="submit"
                              className="bg-color_theme px-3 mx-5 rounded-md"
                              onClick={() => {
                                PostMatchscore(id);
                              }}
                            >
                              Yes
                            </button>
                          ) : (
                            <button
                              // type="submit"
                              disabled
                              className="flex items-center bg-color_theme px-3 mx-5 rounded-md"
                              // onClick={() => {
                              //   PostMatchscore();
                              // }}
                            >
                              Yes..
                              <svg
                                className="animate-spin mr-1 h-5 w-5 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                            </button>
                          )}
                        </div>
                      </div>
                    </Popup>
                  </form>
                </>
              ) : (
                <></>
              )}
              {openwithdraw ? (
                <form
                  onSubmit={(e) => {
                    // PostMatchscore();
                    e.preventDefault();
                  }}
                >
                  <div>
                    <p className="text-[#fff] 2xl:text-4xl md:text-2xl text-center my-4">
                      Withdrew By
                    </p>
                    <div className="flex justify-around">
                      <div className="flex items-center">
                        <input
                          className=" md:h-[25px]  cursor-pointer  md:w-[25px]"
                          type="radio"
                          name="Checkbox"
                          checked={
                            ScoreObject?.Withdraw_by === "Team 1" ? true : null
                          }
                          onChange={(e) => {
                            setmywithdrawvalue(teamOne, e);
                          }}
                        />

                        <label className=" ml-2 text-[#fff] text-lg">
                          Team 1
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          className=" md:h-[25px]  cursor-pointer  md:w-[25px]"
                          type="radio"
                          name="Checkbox"
                          checked={
                            ScoreObject?.Withdraw_by === "Team 2" ? true : null
                          }
                          onChange={(e) => {
                            setoppwithdrawvalue(teamTwo, e);
                          }}
                        />
                        {/* {leaguetype != "singles" ? ( */}
                        {/* <label className="ml-2 text-[#fff] text-lg">
                        Opponent Team
                      </label>
                    ) : ( */}
                        <label className="ml-2 text-[#fff] text-lg">
                          {" "}
                          Team 2
                        </label>
                        {/* )} */}
                      </div>
                    </div>

                    <div className="flex my-5 justify-center">
                      <button
                        className="font-bold bg-color_theme md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                        // type="submit"
                        disabled={
                          (WithdrawCheck === undefined ||
                            WithdrawCheck === "") &&
                          (WithdrawCheck1 === undefined ||
                            WithdrawCheck1 === "") &&
                          ScoreObject?.Withdraw_by === ""
                            ? true
                            : false
                        }
                        onClick={() => {
                          setopenwithdrawpopup(true);
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                  <Popup
                    open={openwithdrawpopup}
                    contentStyle={{
                      width: "50%",
                      border: "none",
                      background: "none",
                    }}
                    closeOnDocumentClick={false}
                    className=""
                  >
                    <div className=" border-2 border-[white] px-1 w-3/4 m-auto bg-[#31343B] move overflow-auto max-h-[600px] rounded  relative">
                      <div className="float-right   ">
                        <button
                          className=" text-[#ffffff] text-2xl absolute  md:leading-[2] top-[0px] right-[10px]"
                          // type="button"
                          onClick={() => {
                            // setopensubmit(false);
                            // setnewpopup(true)
                            setopenwithdrawpopup(false);
                          }}
                        >
                          X
                        </button>
                      </div>
                      <p className=" text-center text-[#fff]  text-3xl md:leading-[0] md:text-2xl mt-16">
                        Are you sure, you want to submit this result?
                        <br />
                      </p>
                      {/* <p className=" text-center text-[#fff] my-5">
                        Once submitted, it cannot be reverted back
                      </p> */}
                      <div className="flex justify-center text-lg  my-10 items-center">
                        <button
                          onClick={() => setopenwithdrawpopup(false)}
                          className="bg-[red] px-3 mx-5 rounded-md"
                        >
                          No
                        </button>

                        {!buttondisable ? (
                          <button
                            // type="submit"
                            className="bg-color_theme px-3 mx-5 rounded-md"
                            onClick={() => {
                              PostMatchscore(id);
                            }}
                          >
                            Yes
                          </button>
                        ) : (
                          <button
                            // type="submit"
                            disabled
                            className="flex items-center bg-color_theme px-3 mx-5 rounded-md"
                            // onClick={() => {
                            //   PostMatchscore();
                            // }}
                          >
                            Yes..
                            <svg
                              className="animate-spin mr-1 h-5 w-5 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          </button>
                        )}
                      </div>
                    </div>
                  </Popup>
                </form>
              ) : (
                <></>
              )}
            </div>
          </Popup>
        </div>
    

      {/* )} */}
      {/* popup for submit void status */}
      <input type="checkbox" id="voidstatus" className="modal-toggle" />
      {voidpopup && (
        <div className="modal">
          <div className="modal-box relative">
            <label
              className="btn btn-sm btn-circle absolute right-2 top-2"
              onClick={() => setopenVoidpopup(false)}
            >
              ✕
            </label>
            <h3 className="text-lg font-bold text-center">
              Are you sure you want to void this Match ?
            </h3>
            <div className="modal-action justify-center">
              {!voidloder ? (
                <label
                  htmlFor="voidstatus"
                  className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                  onClick={() => {
                    VoidScore(matchid, id);
                  }}
                >
                  Yes
                </label>
              ) : (
                <button
                  // type="submit"
                  disabled
                  className="flex items-center bg-color_theme px-3 mx-5 rounded-md"
                  // onClick={() => {
                  //   PostMatchscore();
                  // }}
                >
                  Yes..
                  <svg
                    className="animate-spin mr-1 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </button>
              )}

              <label
                onClick={() => setopenVoidpopup(false)}
                // htmlFor="voidstatus"
                className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
              >
                No
              </label>
            </div>
          </div>
        </div>
      )}

      <div>
        <TieBreaker />
      </div>
    </>
  );
});
