import React,{useState} from 'react'
import { observer } from 'mobx-react';
import { TransactionStoreContext,TransactionStore, useTransactionStore} from './store/TransactionStore';
import { RxPerson } from 'react-icons/rx';
import { MdAttachMoney, MdRemoveRedEye } from 'react-icons/md';
import Filter from './TransactionFilter/Filter';
import Moment from 'react-moment';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuthStore } from '../Auth/store/Auth';
// import Filter from './TransactionFilter/Filter';


const TransactionManagement = () => {
    const store = new TransactionStore({
      isloading:true,
    //   editloading:false,
    //   slectcity:true
    });
    return(
      <TransactionStoreContext.Provider value={store}>
        <Screen/>
      </TransactionStoreContext.Provider>
    )
  }
  
  export default TransactionManagement

const Screen = observer(() => {
    const {
        transaction,
        loadtransaction,
        nolist,
        getToken
      } = useTransactionStore();
  const { adminAuthApproved } = useAuthStore();

  const BASE_URL = process.env?.REACT_APP_BASE_URL;
  useEffect(() => {
    if (adminAuthApproved) {
      getToken(localStorage.getItem("Admintoken"));
    }
  
  }, [adminAuthApproved]);

      const [currentPage, setCurrentPage] = useState(0);
      const ITEMS_PER_PAGE = 50;
      const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
      };
      const handleClick=()=>{
        const element = document.getElementById("tablescroll");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
      const startIndex = currentPage * ITEMS_PER_PAGE;
      const endIndex = startIndex + ITEMS_PER_PAGE;
      const currentTransactions = transaction.slice(startIndex, endIndex);
  return (
   <>
     <div className='flex justify-between'>
    <div className=" mb-4">
        <p className=" text-2xl font-semibold">Transaction Management</p>
        {/* <p className=" opacity-80 text-sm">Home / User</p> */}
      </div>
      {/* <div>
        <Link to="./adduser" className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"><MdAdd/>Add User</Link>
      </div> */}
     </div>
    <Filter/>
     <div className="card bg-base-100 shadow-md p-6 mt-8">
        <div className="overflow-x-auto w-full">
        <table id="tablescroll" className="table table-zebra w-full">
        
            <thead>           
            <tr>
                <th>#</th>
                <th>League</th>
                <th>User Name</th>
                <th>Transaction Id</th>
                <th>Transaction Date</th>
                <th>Discount Code</th>
                <th>Original Amount</th>
                <th>Discount Amount</th>
                <th>Final Amount</th>
                <th>Subscription</th>
                <th>Payment Method</th>
                <th>Status</th>
                <th>Action</th>

                
              </tr>
            </thead>
            <tbody className="text-sm relative">

            {loadtransaction && 
              <>
              <tr>
                <td colSpan={12} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={12} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={12} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={12} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={12} className="animate-pulse py-6"></td>
              </tr>
              </>
              }
        
            
            {
             ( currentTransactions?.length === 0 ?  transaction:
            currentTransactions)?.map((data,index)=>(
              <tr key={index}>
              <td>
                {currentPage * ITEMS_PER_PAGE + index+1}
                </td>
              
                <td className='capitalize whitespace'>
                {data?.league?.name ?? "N/A"}
                </td>
                {/* <td>
               
                </td> */}
                <td>
                <div className="flex items-center space-x-3">
                    <div>
                    <div className="font-bold capitalize">{(data?.user?.firstName?? "-")+" "+(data?.user?.lastName?? "-") }</div>
                    <div className="text-sm ">{data?.user?.email}</div>
                    </div>
                    </div>
                </td>
                <td>{data?.transaction_id ?? "N/A"}</td>
                <td><Moment format="MM/DD/YYYY">
              {data?.createdAt ?? "N/A"}
             </Moment></td>
             <td>{data?.league_subcription?.discount_code??"N/A"}</td>
             <td className=" capitalize">$ { data?.prevAmount ?? "N/A"}</td>
             <td>$ {data?.league_subcription?.discount_amount?? "0"}</td>
                
                <td className=" capitalize">$ {data?.amount ?? "N/A"}</td>
           
                <td className=" capitalize">{ data?.league_subcription?.paid_status ?? "N/A"}</td>
                <td className=" capitalize">{ data?.payment_method =="discountCode" ? "Promo Code" : data?.payment_method  ?? "N/A"}</td>
                <td className=" capitalize">{data?.payment_status?? "N/A"}</td>
                <td className=" text-base">
                    <span className="flex items-center space-x-4">
                    <a
                   href={ BASE_URL + `/image/${data?.invoice}` }
                   target='_blank'
                   rel='noreferrer'
                    >
                        <MdRemoveRedEye  title="View Bill" className="cursor-pointer" />
                      </a>
                    </span>
                  </td>
                
                </tr>
               
            ))}
            {nolist && 
              <tr>
                <td colSpan={12} className="my-10 text-center">
                  <MdAttachMoney className=" text-[#dedede] text-8xl mx-auto" />
                  <p className=" font-semibold text-lg">No Transaction Found</p>
                  {/* <Link
                    to="./addcity"
                    className="btn btn-sm mt-4 bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                  >
                    <MdAdd />
                    Add City
                  </Link> */}
                </td>
              </tr>}
            </tbody>
           
        </table>
        {!transaction.length == 0 
        && 
        <ReactPaginate
          initialPage={0}
          pageCount={Math.ceil(transaction.length / ITEMS_PER_PAGE)}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          onPageChange={handlePageChange}
          onClick={handleClick}
          containerClassName={'pagination'}
          activeClassName={'active'}
        
          />

        }
     
        {/* status change model start */}
       
          {/* status change model end */}
        </div>
    </div>

     
   </>
  )
})

