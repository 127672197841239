import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineRefresh, MdOutlineSearch } from "react-icons/md";
import { Input } from "react-daisyui"
import { observer } from "mobx-react";
import { useCouponStore } from "../Store/CouponStore";

const Filter = observer(() => {
    
    const {
        getname,
        getcode,
        getdiscount,
        getdiscount_type,
        getstartdate,
        getenddate,
        getstatus,
        addCoupon,
        getCoupon,
        getFilterCoupen,
        code,
        name,
            saved,
          } = useCouponStore();
          const Reset=()=>{
            getname("")
            getcode("")
            getstartdate("")
            getenddate("")
            getstatus("")
        }
    return (
        <>
            <div className="card bg-base-100 shadow-md p-6 mt-3 mb-10">
                <form onSubmit={(e) => {getFilterCoupen(); e.preventDefault() }}>
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
                    <div>
                        <label htmlFor="Fees" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Promo Code Name</label>
                        <Input 
                        name="Coupon Name"
                        placeholder="Promo Code Name" 
                        onChange={(e) => getname(e.target.value)}
                        value={name}
                        id="Coupon Name" 
                        type="text" 
                        autoComplete="off" 
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div>
                    <div>
                        <label htmlFor="Fees" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Promo Code</label>
                        <Input 
                        name="Coupon Code"
                        placeholder="Promo Code" 
                        onChange={(e) => getcode(e.target.value)}
                        value={code}
                        id="Coupon Code" 
                        type="text" 
                        autoComplete="off" 
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div>
                    <div>
                        <label htmlFor="startdate" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Start Date</label>
                        <Input 
                        name="startdate" 
                        placeholder="" 
                        onChange={(e) => getstartdate(e.target.value)}
                        id="startdate" 
                        type="date" 
                        autoComplete="off" 
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div>

                    <div>
                        <label htmlFor="enddate" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">End Date</label>
                        <Input 
                        name="enddate" 
                        placeholder="" 
                        onChange={(e) => getenddate(e.target.value)}
                        id="enddate" 
                        type="date" 
                        autoComplete="off"
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div>
                  
                    </div>
                    <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                        <button onClick={()=>{getCoupon(); Reset()}}   type='reset'className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"><MdOutlineRefresh className="mr-1" />Reset</button>
                        <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"><MdOutlineSearch className="mr-1" />Search</button>
                    </div>
                </form>
            </div>

        </>
    )
})
export default Filter;