import { createContext, useContext } from "react";
import { action, makeAutoObservable, makeObservable, observable, runInAction } from "mobx";
import { postMethodData, getMethodData, putMethod, delMethod, patchMethodData } from "../../ApiMethods/api";
import { toast } from "react-toastify";
const SEO_URL = process.env.REACT_APP_SEO_URL;





export class RewardStore {

    Token = null;
    Reward = [];
    type = "";
    registration = "";
    referral = "";
    Rewardtype = [];
    filterSearch = "";
    PaginationPage = "1";
    TotalPages = "";
    RewardDimiensions = ""
    crop = {};
    errLoadReward = "";
    LoadReward = false;
    delateLoader = false;
    nolist = false;
    Rewardpopup = false;
    deletepopup = false;
    Rewardname = "";
    status = "1";
    statesid = "";
    Rewardid = [];
    seoUrl = "";
    statuspopup = false;
    addRewardname = "";
    imageurl = "";
    logoimage = "";
    Rewardimage = "";
    Base64logoImage = "";
    Base64RewardImage = "";
    logofile = [];
    allReward = [];
    alldeactivate = 'false';
    isRewardUpdated = "false";
    isLogoUpdated = "false"
    checkeddd = '';
    RewardFormDetails = {
        "Ref_amount": "",
        "Reg_amount": ""
    };
    saved = false;

    constructor(isloading = false) {
        makeAutoObservable(this, {
            addRewardname: observable,
            status: observable,
            errorMessages: observable,
            setaddRewardname: action,
            getstatus: action,
            resetErrors: action,
        });

        this.Token = localStorage.getItem("Admintoken");
        if (isloading.isloading) {
            this.getRewardList();
            //   this.getStateList();
            return
        }
        if (isloading.addloading) {
            this.getRewardTypeList();
        }
        if (isloading.Rewardeditloading) {
            this.getRewardform(isloading.id);
            this.getRewardTypeList();

            return
        }
    }
    //All Clear 
    allclear = () => {
        this.filterSearch = ""
        this.getFilterReward();
        //   this.status="";
        //   this.statesid="";
    }
    setimage = (text, type) => {
        this.imageurl = URL.createObjectURL(text[0]);
    }

    setalldeactivate = (text) => {
        console.log(text);
        this.alldeactivate = !this.alldeactivate;
    };

    getid = (text) => {
        this.Rewardid.push(text);
    };



    // status popup
    setstatuspopup = () => {
        this.statuspopup = !this.statuspopup;
    };


    setallReward = (text) => {
        this.allReward = text;
    }

    // get status 
    getstatus = (text) => {
        this.status = text;
    };

    //get city name
    getRewardname = (text) => {
        this.Rewardname = text;
    };

    setaddRewardname = (text) => {
        this.addRewardname = text;
    };
    setCrop = (text) => {
        this.crop = text;
    };

    setRewardDimiensions = (text) => {
        this.RewardDimiensions = text;
    };

    setlogofile = (e) => {
        this.logofile = e;
        console.log(this.logofile);
    };

    setRewardpopup = (e) => {
        this.Rewardpopup = e;
    };
    setdeletepopup = (e) => {
        this.deletepopup = e;
    };
    setdelateLoader = (e) => {
        this.delateLoader = e;
    }
    getToken = (e) => {
        this.Token = e;
    }
    gettype = (e) => {
        this.type = e;
    }
    getseourl = (text) => {
        // console.log(`https://demo.e-kart.com/${text}`);
        this.seoUrl = text;
    };
    getlogoimage = (text) => {
        console.log("checking in the Reward", text);
        this.logoimage = text;
    };
    getReward_image = (text) => {
        console.log(text);
        this.Rewardimage = text;
    };
    SetfilterSearch = (text) => {
        this.filterSearch = text;
    };
    setPaginationPage = (text) => {
        this.PaginationPage = text;
    };
    SetTotalPages = (text) => {
        this.TotalPages = text;
    };
    setchecked = (text) => {
        this.checkeddd = text;
    };
    setisRewardUpdated = (text) => {
        this.isRewardUpdated = text;
    };
    setisLogoUpdated = (text) => {
        this.isLogoUpdated = text;
    };

    isBase64 = (str) => {
        try {
            return btoa(atob(str)) === str;
        } catch (err) {
            return false;
        }
    };
    setreferral = (text) => {
        this.referral = text;
    };
    setregistration = (text) => {
        this.registration = text;
    };

    setRewardFormDetails = (RewardDetail) => {
        console.log("reward", RewardDetail);
        this.RewardFormDetails = {
            ...this.RewardFormDetails,
            Ref_amount: RewardDetail.referral?.amount || "", // Use default value if name is undefined
            Reg_amount: RewardDetail.registration?.amount || "", // Use default value if seo_url is undefined
        };
    };
    // setRewardFormDetails = (text) => {
    //     this.RewardFormDetails = text;
    //     console.log("setRewardFormDetails", this.RewardFormDetails.Rewardname);
    // };
    base64toFile(base64Data, fileName, mimeType) {
        if (base64Data) {
            const byteCharacters = atob(base64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters?.length; offset += 512) {
                const slice = byteCharacters?.slice(offset, offset + 512);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const file = new File(byteArrays, fileName, { type: mimeType });

            return file;
        }

    }

    // get Reward list
    getRewardList = () => {
        runInAction(() => {
            this.RewardLoader = true;
            this.errLoadReward = "";
            this.saved = false;
            this.nolist = false;
            this.Reward = [];
        });
        getMethodData(`/api/v1/admin/reward/get-setting`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.RewardLoader = false;
                        this.errLoadReward = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.RewardLoader = false;
                        this.errLoadReward = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.setRewardFormDetails(resp.data.data);
                        this.referral = resp.data.data.referral?.amount
                        this.registration = resp.data.data.registration?.amount
                        // this.Reward = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadReward = false;
                    this.errLoadReward = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.RewardLoader = false;
                    if (!error.response) {
                        this.errLoadReward = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadReward = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };
    //update method
    updateReward = (type) => {
        runInAction(() => {
            this.EditloadLocation = true;
            this.errLoadLocation = "";
        });

        const formData = new FormData();
        formData.append('amount', type == "referral" ? this.referral : this.registration);
        formData.append('type', type);

        // formData.append('isLocationUpdated', false  );
        // formData.append('isLogoUpdated', true  );


        postMethodData(`/api/v1/admin/reward/save-setting`,
            formData,
            this.Token
        )
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.addloadcity = false;
                        this.EditloadLocation = false;

                        this.erraddcity = resp?.data?.message;
                        toast.error(resp.data.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.addloadcity = false;
                        this.EditloadLocation = false;

                        this.erraddcity = resp?.data?.message;
                        toast.success(resp.data.message);
                        // this.getCity();
                        // this.getLocationList();
                        this.saved = true;
                    })
                    return
                }
                runInAction(() => {
                    this.addloadcity = false;
                    this.erraddcity = resp?.data?.message;
                    toast.error(resp.data.message);
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.EditloadLocation = false;

                    if (!error.response) {
                        this.addloadcity = false;
                        this.erraddcity = error?.message;
                        toast.error(error?.message);
                    }
                    if (error.response && error.response.data && error.response.data.formErrors) {
                        const formErrors = error.response.data.formErrors;
                        Object.keys(formErrors).forEach((key) => {
                            const errorMessage = formErrors[key];
                            toast.error(`${key}: ${errorMessage}`);
                        });
                    }
                    this.addloadcity = false;
                    this.erraddcity = error?.response?.data?.message;
                })
                return;
            })
    };

    // filter League court

    getFilterReward = () => {
        runInAction(() => {
            this.LoadReward = true;
            this.errLoadReward = "";
            this.saved = false;
            this.nolist = false;
            this.getRewardList = [];
        });
        postMethodData(`/api/v1/admin/Reward/search?page=${this.PaginationPage}`, {
            keyword: this.filterSearch
        }, this.Token)
            .then((resp) => {

                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.loadleague = false;
                        this.errloadleague = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {

                    runInAction(() => {
                        this.loadcity = false;
                        this.errloadleague = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.Reward = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.loadcity = false;
                    this.errloadcuty = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.loadleague = false;
                    if (!error.response) {
                        this.errloadleague = error?.message;
                        toast.error(error?.message);
                    }
                    this.errloadleague = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };



    //Active and inactive
    statusChange = () => {
        runInAction(() => {
            this.SectionLoader = true;
            this.Sectionpopup = true;

            this.errLoadSection = "";
        });
        postMethodData(`/api/v1/admin/Reward/update-status`, {

            "record_id": this.Rewardid,
            "status": JSON.stringify(this.status)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.Sectionid = [];
                        this.Rewardid = [];

                        this.SectionLoader = false;
                        this.Rewardpopup = false;
                        this.errLoadSection = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.SectionLoader = false;
                        this.Rewardpopup = false;
                        this.errLoadSection = resp?.data?.message;
                        this.getRewardList();
                        this.Sectionid = [];
                        this.Rewardid = [];
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.SectionLoader = false;
                    this.Rewardpopup = false;

                    this.errLoadSection = resp?.data?.message;
                })
            })
            .catch((error) => {
                this.Rewardid = [];

                runInAction(() => {
                    if (!error.response) {
                        this.SectionLoader = false;
                        this.Rewardpopup = false;
                        this.Sectionid = [];
                        this.errLoadSection = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.SectionLoader = false;
                    this.Rewardpopup = false;
                    this.Sectionid = [];


                    this.errLoadSection = error?.response?.data?.message;
                })
                return;
            })
    };


    //Delete method
    deletefunction = () => {
        runInAction(() => {
            this.setdelateLoader(true);
            this.deletepopup = true;
            this.errLoadReward = "";
        });
        postMethodData(`/api/v1/admin/Reward/delete`, {

            "record_id": this.Rewardid,

        }, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.Rewardid = [];

                    this.LoadReward = false;
                    this.setdelateLoader(true);
                    this.deletepopup = true;
                    this.errLoadReward = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.LoadReward = false;
                    this.Rewardid = [];

                    this.setdelateLoader(false);
                    this.deletepopup = false;
                    this.errLoadReward = resp?.data?.message;
                    this.getRewardList();
                    toast.success(resp.data.message);
                })
                return;
            }
            runInAction(() => {
                this.LoadReward = false;
                this.errLoadReward = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadcity = false;
                    this.Rewardid = [];

                    if (!error.response) {
                        this.LoadReward = false;
                        this.setdelateLoader(false);
                        this.deletepopup = false;
                        this.errLoadReward = error?.message;
                        toast.error(error?.message);
                    }
                    this.LoadReward = false;
                    this.errLoadReward = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            })
    }






}




export const RewardStoreContext = createContext(new RewardStore());
export const useRewardStore = () => useContext(RewardStoreContext);
