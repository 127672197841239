import React from 'react'
import { RxDashboard, RxEyeOpen, RxPencil1, RxPerson } from 'react-icons/rx'
import userimage from "../../assets/images/user.png"
import { observer } from 'mobx-react';
import { HomeStore , HomeStoreContext , useHomeStore } from '../Store/HomeStore';
import { Link,useNavigate } from 'react-router-dom';
const Table = () => {
  const store = new HomeStore({
    isloading: true,
    editloading: false,
  });
  return (
    <HomeStoreContext.Provider value={store}>
      <Screen />
    </HomeStoreContext.Provider>
  );
  }
  export default Table

  const Screen = observer(()=>{
    const {
      users,
      loaduser,
      nolist,
      getNewstatus,
      getstatusid,
      statusChange,
    
    } = useHomeStore();

    const navigate = useNavigate();

  return (
    <div className="card bg-base-100 shadow-md p-6 mt-8">
    <p className=" text-lg font-semibold uppercase mb-4">New User</p>
    <div className="card bg-base-100 shadow-md p-6 mt-8">
        <div className="overflow-x-auto w-full">
        <table className="table table-zebra w-full">
        
            <thead>           
            <tr>
                <th>#</th>
                <th>Name</th>
                {/* <th>Email</th> */}
                <th>Gender</th>
                <th>Mobile</th>
                <th>level</th>
                <th>City</th>
                <th>Court Name</th>
                <th>Last login device</th>
                {/* <th>dob</th> */}            
                {/* <th>Country</th> */}
                
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="text-sm relative">

            {loaduser && 
              <>
              <tr>
                <td colSpan={10} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={10} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={10} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={10} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={10} className="animate-pulse py-6"></td>
              </tr>
              </>
              }
            
            {users.map((data,index)=>(
             
              <tr key={index}>
              <td>

                {index+1}
                </td>
              
                <td>
                <div className="flex items-center space-x-3">
                    {/* <div className="avatar">
                    <div className="mask mask-squircle w-12 h-12">
                        <img src={userimage} alt="Avatar Tailwind CSS Component" />
                    </div>
                    </div> */}
                    <div>
                    <div className="font-bold capitalize text-[#7ac46b] cursor-pointer"
                     onClick={() => {
                      if(data?.firstName!==undefined){
                        navigate(`/playerdetail/${data?._id}/${
                          (data?.firstName??"-")+" "+(data?.lastName??"-")
    
                            }`);
                      }                    
                            }}
                    >
                      {(data?.firstName??"-")+" "+(data?.lastName??"-")}
                      </div>
                    {/* <div className="font-bold">Lname</div> */}
                  <div className="text-sm ">{data?.email ?? "N/A"}</div>
                    </div>
                    </div>
                </td>
                {/* <td>
               
                </td> */}
                <td className=" capitalize">{data?.gender ?? "N/A" }</td>
                <td>{data?.mobile?.replace(/\D/g, '')?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2 - $3') ?? "N/A"}</td>
                <td>
               <div className="text-xs">S-{data?.level?.name?.toFixed(1)?? 0}</div>
               <div className="text-xs">D-{data?.doubles_level?.name?.toFixed(1)?? 0}</div>
               <div className="text-xs whitespace-nowrap">M-{data?.mixed_level?.name?.toFixed(1)?? 0}</div>
                </td>
                <td className=" capitalize">{data?.city?.city_name ?? "N/A" }</td>
                <td className=" capitalize">{data?.homeCourt?.displayName ?? "N/A"}</td>
                <td className=" capitalize">{data?.deviceId ?? "N/A"}</td>
                {/* <td>{data?.dob?.slice(0, 10)}</td> */}
                
                
                
                {/* <td>USA</td> */}
                
                <th>
                    <label
                      title="Change Status"
                      htmlFor="my-modal-5"
                      className={`btn capitalize ${data?.isActive ? "bg-color_theme hover:bg-color_theme" : "bg-warning hover:bg-warning"}  text-[#000] border-hidden  btn-xs`}
                      onClick={(e) => {
                        getstatusid(data?._id);
                        getNewstatus(data?.isActive);
                      }}
                    >
                      {data?.isActive ? "Active" : "Inactive"}
                    </label>
                  </th>
                <td className=" text-base">
                    <div className="flex items-center space-x-4">
                      {/* <label
                        htmlFor="deletecity"
                        className=" text-[#d02c2c] cursor-pointer"
                        title="Delete"
                        // onClick={(e) => {getid(data?._id)}}
                      >
                        <MdOutlineDelete />
                      </label> */}
                      <button title="Edit">
                        <Link to={`/usermanagement/${data?._id}`}>
                            <RxPencil1 />
                        </Link>
                      </button>
                    </div>
                  </td>
                </tr>
               
            ))}
            {nolist && 
              <tr>
                <td colSpan={10} className="my-10 text-center">
                  <RxPerson className=" text-[#dedede] text-8xl mx-auto" />
                  <p className=" font-semibold text-lg">No User Found</p>
                  {/* <Link
                    to="./addcity"
                    className="btn btn-sm mt-4 bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                  >
                    <MdAdd />
                    Add City
                  </Link> */}
                </td>
              </tr>}
            </tbody>
        </table>
        {/* status change model start */}
        <input type="checkbox" id="my-modal-5" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="my-modal-5"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status.
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor="my-modal-5"
                  className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                  onClick={() => statusChange()}
                >
                  Yes
                </label>
                <label htmlFor="my-modal-5" className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]">
                  No
                </label>
              </div>
            </div>
          </div>
          {/* status change model end */}
        </div>
    </div>
    </div>
  )
});

