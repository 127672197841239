import React, { useEffect, useState } from "react";
import {
  PlayerReasonStore,
  usePlayerReasonStore,
  PlayerReasonStoreContext,
} from "./store/PlayerReasonStore";
import Filter from "./PlayerReasonFilter/Filter";
import {  useParams } from "react-router-dom";
// import { RxEyeOpen, RxPencil1 } from "react-icons/rx";
import { observer } from "mobx-react";
import {  MdSportsTennis } from "react-icons/md";
import { RxPencil1 } from "react-icons/rx";
import { format } from 'date-fns';
import ReactPaginate from "react-paginate";
import { useAuthStore } from "../Auth/store/Auth";


// import ReactPaginate from "react-paginate";

const PlayerReason = () => {
  const store = new PlayerReasonStore({
    isloading: true,
    // playerlist: true,
  });
  return (
    <PlayerReasonStoreContext.Provider value={store}>
      <Screen />
    </PlayerReasonStoreContext.Provider>
  );
};
export default PlayerReason
 
const Screen = observer(() => {
  const { getsubject,getDescription,sendemail,emailsent ,subject,description,loadEmail,getToken} = usePlayerReasonStore();
  const { adminAuthApproved } = useAuthStore();
  useEffect(() => {
    if (adminAuthApproved) {
      getToken(localStorage.getItem("Admintoken"));
    }
  
  }, [adminAuthApproved]);

  const { id } = useParams();

  const [allemail, setallemail] = useState([])
  const [popupemail, setPopupemail] = useState(false)

  const {Refund_Deatils,setTransaction_Date, setRemark,setignored_player_id , setrefundpopup,setrefundCheck, setmatename,setPlayer,matename,playername,refundCheck,Remark,Transaction_Date,refundpopup, PlayerList, loadPlayer, nolist, loadrefund,refund } = usePlayerReasonStore();

  const [checkeddd,setchecked] = useState(false)
  const [currentPage, setCurrentPage] = useState(0);
  const ITEMS_PER_PAGE = 50;
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);

  };
  const handleClick=()=>{
    const element = document.getElementById("tablescroll");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }
  const startIndex = currentPage * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentPlayerList = PlayerList.slice(startIndex, endIndex);
  const checkAllCheckboxes=()=> {
    setallemail([]);
    // Get all checkboxes with the same name attribute
    var checkboxes = document.getElementsByName('myCheckbox');
 
    if(checkeddd){
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }
      setallemail([]);
    }else{
      var emails = [];
      var commaSeparatedEmails = [];
      // var emailArray = emailString.split(",").map((email) => email.trim());
      // Loop through each checkbox and check it
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
        var emailValues = checkboxes[i].value.split(',');
        if (emailValues.length > 1) {
          commaSeparatedEmails.push(...emailValues);
        } else {
          var emailValue = emailValues[0].trim();
          if (emailValue !== '' && !emails.includes(emailValue)) {
            emails.push(emailValue);
          }
          
        }
      }
      setallemail((prevEmails) => [...prevEmails, ...emails, ...commaSeparatedEmails]);
    }
   
  }
  const isallcheck =(e)=>{
    var checkboxes = document.getElementsByName('myCheckbox');
    for (var i = 0; i < checkboxes.length; i++) {
      var updatedEmails = Array.from(checkboxes)
      .filter((checkbox) => checkbox.checked)
      .flatMap((checkbox) => checkbox.value.split(',').map((email) => email.trim()));
      setallemail(updatedEmails);
      if(!checkboxes[i].checked){
        setchecked(false)
        return
      }
      else{
        setchecked(true)
      }
      
    }
  }
  useEffect(() => {
    if(emailsent){
      setchecked(false)
      getsubject("")
      getDescription("")
      setallemail([])
    var checkboxes = document.getElementsByName('myCheckbox');
    for (var i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = false;
    }
    }
    if(refund){
      setchecked(false)
      setallemail([])
     
    }
  }, [emailsent,refund])
  
  useEffect(()=>{
if(!loadEmail){
  setPopupemail(false)
}
  },[loadEmail])

 

  return (
    <>
    <div className="flex justify-between mt-[51px] ">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold">Excluded Player Management</p>
        
        </div>
      
      </div>
      <Filter  league={id} reset={setchecked} email={setallemail} />
      
      <div className="flex justify-between items-center py-3">
        <div className="ml-3">
        <label className=" font-extrabold">
        <input className="mr-2" checked={checkeddd} type="checkbox"  name="selectall" onClick={()=>{checkAllCheckboxes();setchecked(!checkeddd)}} />
        Select All
      </label>  
        </div>
        <div>
         {allemail.length !==0?
         <label 
         htmlFor="emailpopup" 
         className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
         onClick={()=>setPopupemail(true)}
          >
           Send Email
         </label >
         :
         <label 
          // htmlFor="emailpopup" 
          className="btn btn-md opacity-50 bg-[#000] text-color_theme text-sm hover:bg-[#000]"
          // onClick={()=>setPopupemail(true)}
           >
            Send Email
          </label >
          }
          
        </div>
      </div>
      <div className="card bg-base-100 shadow-md p-6">
        <div className="overflow-x-auto w-full">
          <table id="tablescroll" className="table table-zebra w-full">
            <thead>
              <tr>
                <th></th>
                <th>#</th>
                <th>League Name</th>
                <th>Level</th>
                <th>Player Info</th>            
                <th>Partner Info</th>
                <th>Homecourt</th>
                <th>Fees Paid</th>
                <th>Reason</th>
                <th>Status</th>
                <th>Action</th>

              
              </tr>
            </thead>
            <tbody className="text-sm relative">
              {loadPlayer && (
                <>
                  <tr>
                    <td colSpan={11} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={11} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={11} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={11} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={11} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={11} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={11} className="animate-pulse py-6"></td>
                  </tr>
                </>
              )}
              {
           (currentPlayerList?.length === 0 ? 
            PlayerList:currentPlayerList)?.map((data, index) => (
              <tr key={index} className="capitalize">
                <td> 
                
              {!data?.is_refunded &&
                 <input 
                 // value={`${data?.players?.mate?.email} ? ${data?.players?.user?.email}`} 
                 value={data?.players?.mate?.email ? (data?.players?.user?.email + "," + data?.players?.mate?.email) : data?.players?.user?.email} 
                 onChange={(e)=>{setchecked(false); isallcheck(e.target.value); }} 
                 type="checkbox" 
                 name="myCheckbox"
                  /> 
                } 
                </td>
                <td>{currentPage * ITEMS_PER_PAGE + index + 1}</td>
                <td>{data?.league.name ?? "N/A"}</td>
                <td>{data?.level?.name.toFixed(1) ?? "N/A"}</td>
                <td>
                  <div className="flex items-center space-x-3">
                    <div>
                      <div className="font-bold capitalize">
                        {(data?.players?.user?.firstName ?? "-") +
                          " " +
                          (data?.players?.user?.lastName ?? "-")}
                      </div>
                      <div className="text-sm ">{data?.players?.user?.email?? "-"}</div>
                      <div className="text-xs ">{data?.players?.user?.mobile?.replace(/\D/g, '')?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2 - $3') ?? "-"}</div>
                    </div>
                  </div>
                </td>

                
               
                <td>
                  <div className="flex items-center space-x-3">
                    <div>
                      <div className="font-bold capitalize">
                        {data?.players?.mate?.firstName === undefined ? (
                          <span className="pl-7">---</span>
                        ) : (
                          data?.players?.mate?.firstName??"-" + " " + data?.players?.mate?.lastName??"-"
                        )}
                      </div>
                      <div className="text-sm  ">{data?.players?.mate?.email??""}</div>
                      <div className="text-xs ">{data?.players?.mate?.mobile?.replace(/\D/g, '')?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2 - $3') ??""}</div>
                    </div>
                  </div>
                </td>
                
                <td>
                  {data?.homecourt?.displayName ?? "N/A"}
                </td>

                <td>
                  $ { data?.players?.fees_amount !== undefined
  ? ( data?.players?.fees_amount % 1 === 0 ?  data?.players?.fees_amount.toFixed(0) :  data?.players?.fees_amount.toFixed(2))
  : "N/A"}
                </td>
                <td>
                    {data?.reason ==="MUCH_DISTANCE"? "Court out of range": "Not enough registration" ?? "N/A"}
                    </td>
                    <td>
                      {data?.is_refunded ==true?
                          <span className="btn capitalize bg-color_theme hover:bg-color_theme cursor-not-allowed text-[#000] border-hidden  btn-xs">Paid</span>
                          :
                          <span className="btn capitalize bg-warning hover:bg-warning  text-[#000] border-hidden  btn-xs">Due</span>
                          }
                          </td>
                    <td className=" text-base">
                    <label 
                    onClick={()=> {setignored_player_id(data?._id);setrefundpopup();setTransaction_Date(data?.refund_date);setRemark(data?.remark);setrefundCheck(data?.is_refunded);setPlayer( (data?.players?.user?.firstName ?? "-") +
                    " " +
                    (data?.players?.user?.lastName ?? "-")); setmatename(data?.players?.mate?.firstName + " " + data?.players?.mate?.lastName) }}
                    htmlFor="refundpopup" 
                     >
              <RxPencil1 />
          </label >
                   
                  </td>
               
              </tr>
            ))
            }
             
              {nolist && (
                <tr>
                  <td colSpan={11} className="my-10 text-center">
                    <MdSportsTennis className=" text-[#dedede] text-8xl mx-auto" />
                    <p className=" font-semibold text-lg">No Player Found</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {!PlayerList?.length == 0 &&
          <ReactPaginate
          initialPage={0}
          pageCount={Math.ceil(PlayerList.length / ITEMS_PER_PAGE)}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          onPageChange={handlePageChange}
          onClick={handleClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
          }
         {/* popup for Email */}
         <input checked={popupemail} type="checkbox" id="emailpopup" className="modal-toggle" />
          <div className="modal">
            <form className="w-full" onSubmit={(e)=>{sendemail(allemail) ; e.preventDefault()}}>
            <div className="modal-box relative mx-auto">
              
              <label
                htmlFor="emailpopup"
                className="btn btn-sm btn-circle absolute right-2 top-2"
                onClick={()=> { getsubject(""); getDescription(""); setPopupemail(false)}}
              >
                ✕
              </label>
              
              <h3 className="text-lg font-bold text-center">
                Send Email
              </h3>
              <div className="py-1">
                  Subject<span className="text-[#f00]">&nbsp;*</span> 
             <input
              required
              value={subject}
              name="subject"
              autoComplete="subject"
              id="subject"
              onChange={(e) => getsubject(e.target.value)}
              // required
              className="  text-[#000] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
              placeholder="Subject"
             ></input>
             </div>

                <div className="py-1">
                  Description<span className="text-[#f00]">&nbsp;*</span> 
             <textarea
             required
              value={description}
              name="description"
              autoComplete="description"
              id="description"
             onChange={(e) => getDescription(e.target.value)}
            
              // required
              className="  text-[#000] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
              placeholder="Description"
             ></textarea>
             </div>
              <div className="modal-action justify-center">
                {!loadEmail?
                 <button
                 type="submit"
                 htmlFor="emailpopup"
                className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
              
              >
                
                Submit
              </button>
              :
              <label 
                      className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                      //  type="button"
                      //  htmlFor="leaguecreated"        
                     >
                     <svg
                         className="animate-spin h-5 w-5 text-white"
                         xmlns="http://www.w3.org/2000/svg"
                         fill="none"
                         viewBox="0 0 24 24"
                         >
                         <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                         <path
                             className="opacity-75"
                             fill="currentColor"
                             d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                         ></path>
                     </svg>
                     Submitting
                     </label>

                }
               
                <label
                  htmlFor="emailpopup"
                  className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                  onClick={()=> { getsubject(""); getDescription("") ; setPopupemail(false)}}
                >
                  Cancel
                </label>
              </div>
            </div>
            </form>
          </div>

           {/* popup for Refund */}
      <input readOnly checked={refundpopup} type="checkbox" id="refundpopup" className="modal-toggle" />

          <div className="modal">
            <div className="modal-box relative">
              <form 
              autoComplete="off"
                  onSubmit={(e) => {
                    Refund_Deatils(); setRemark("");setTransaction_Date(); 
                    e.preventDefault();
                  }}
              
              >
              <label
              onClick={()=>setrefundpopup()}
                htmlFor="refundpopup"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Refund Details For  <span className="text-color_theme">{playername +  (matename !== "undefined undefined"  ?  ` &  ${matename} ` : "" )  }</span>
              </h3>
              <div className="py-1">
              <label htmlFor="Transaction" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Transaction Date<span className="text-[#f00]">&nbsp;*</span></label>
                  <input 
                        name="startdate" 
                        placeholder="" 
                        disabled={refundCheck === true ? true: false}

                        value= {Transaction_Date !== "" && Transaction_Date !== null && Transaction_Date !== undefined  ? format(new Date(Transaction_Date), 'yyyy-MM-dd'): ""}
                        onChange={(e) =>{setTransaction_Date(e.target.value)}}
                        max={new Date().toISOString().split("T")[0]}
                        id="startdate" 
                        type="date" 
                        autoComplete="off" 
                        required
                        className="  text-[#000] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                        />
             </div>

                <div className="py-1">
                <label htmlFor="Remark" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"> Remark<span className="text-[#f00]">&nbsp;*</span></label>
             <textarea
              defaultValue=""
              disabled={refundCheck === true ? true: false}
              name="Remark"
              autoComplete="Remark"
              id="Remark"
              value={Remark !== null ? Remark : setRemark("")}
              onChange={(e) => setRemark(e.target.value)}
              required
              className="  text-[#000] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
              placeholder="Remark"
             ></textarea>
             </div>
             
              <div className="modal-action justify-center">
{refundCheck   ?    <span onClick={()=>{setrefundpopup()}}  className="btn  bg-[#b76a6a] hover:bg-[#b76a6a] text-[#000] text-sm  ml-4">Close</span> :
      !loadrefund ? (   
        <button type="submit"   className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">Submit</button>
    
     ) : (
       <button
         className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
         type="button"
         //  onClick={()=> changeStep("4")}
       >
         <svg
           className="animate-spin mr-1 h-5 w-5 text-white"
           xmlns="http://www.w3.org/2000/svg"
           fill="none"
           viewBox="0 0 24 24"
         >
           <circle
             className="opacity-25"
             cx="12"
             cy="12"
             r="10"
             stroke="currentColor"
             strokeWidth="4"
           ></circle>
           <path
             className="opacity-75"
             fill="currentColor"
             d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
           ></path>
         </svg>
         Submitting..
       </button>
     )  }

        



            {!refundCheck ?     <label
                  htmlFor="refundpopup"
                  onClick={()=>{setrefundpopup()}}
                  className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                 
                >
                  Cancel
                </label>: null}
             
              </div>
              </form>
           
            </div>
          </div>

   
        </div>
      </div>
      
    </>
  );
});