import React, { useState } from "react";
import { RxEyeOpen, RxPencil1 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { MdAdd, MdEmployeeingWatermark, MdEmployeeCity, MdOutlineDelete, MdOutlineGroup, MdRemoveRedEye, MdEdit } from "react-icons/md";
import { observer } from "mobx-react";
import Filter from "./EmployeeFilter/Filter";
import ReactPaginate from 'react-paginate';
import { EmployeeStore, useEmployeeStore, EmployeeStoreContext } from "./store/EmployeeStore";
import { useAuthStore } from "../Auth/store/Auth";
import { useEffect } from "react";
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import { toast, ToastContainer } from "react-toastify";
import Moment from "react-moment";

const EmployeeManagement = () => {
    const store = new EmployeeStore({
        isloading: true,
        editloading: false
    });
    return (
        <EmployeeStoreContext.Provider value={store}>
            <Screen />
        </EmployeeStoreContext.Provider>
    )
}
export default EmployeeManagement;


const Screen = observer(() => {
    const {
        Employee,
        loadcity,
        nolist,
        Citypopup,
        BulkDelete,
        BulkstatusChange,
        EmployeeLoader,
        // setallEmployee,
        // allEmployee,
        setPaginationPage,
        setalldeactivate,
        alldeactivate,
        getEmployeeList,
        getFilterEmployee,
        TotalPages,
        setEmployeepopup,
        Employeepopup,
        deletepopup,
        setdeletepopup,
        setdelateLoader,
        delateLoader,
        statusChange,
        setstatuspopup,
        getid,
        getstatus,
        deletefunction,
        setchecked,
        checkeddd,
        getToken
    } = useEmployeeStore();

    const { adminAuthApproved, admin_permissions } = useAuthStore();

    useEffect(() => {
        if (adminAuthApproved) {
            getToken(localStorage.getItem("Admintoken"));
        }

    }, [adminAuthApproved]);

    const [currentPage, setCurrentPage] = useState(0);
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [allEmployee, setallEmployee] = useState([])
    // const [checkeddd, setchecked] = useState(false)

    const [photoIndex, setPhotoIndex] = useState("");
    const ITEMS_PER_PAGE = 10;
    const handlePageChange = ({ selected }) => {
        setPaginationPage(selected + 1);
        // getEmployeeList();
        setCurrentPage(selected);
    };

    const handleClick = () => {
        const element = document.getElementById("tablescroll");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }
    const startIndex = currentPage * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const Employees = Employee?.slice(startIndex, endIndex);
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const openLightbox = (index) => {
        setLightboxIsOpen(true);
    };

    const closeLightbox = () => {
        setLightboxIsOpen(false);
    };

    const checkAllCheckboxes = () => {
        setallEmployee([]);
        // Get all checkboxes with the same name attribute
        var checkboxes = document.getElementsByName('myCheckbox');

        if (checkeddd) {
            for (var i = 0; i < checkboxes.length; i++) {
                checkboxes[i].checked = false;
            }
            setallEmployee([]);
        } else {
            var emails = [];
            var commaSeparatedEmails = [];
            // var emailArray = emailString.split(",").map((email) => email.trim());
            // Loop through each checkbox and check it
            for (var i = 0; i < checkboxes.length; i++) {
                checkboxes[i].checked = true;
                var emailValues = checkboxes[i].value.split(',');
                if (emailValues.length > 1) {
                    commaSeparatedEmails.push(...emailValues);
                } else {
                    var emailValue = emailValues[0].trim();
                    if (emailValue !== '' && !emails.includes(emailValue)) {
                        emails.push(emailValue);
                    }

                }
            }
            setallEmployee((prevEmails) => [...prevEmails, ...emails, ...commaSeparatedEmails]);
        }

    }
    const isallcheck = (e) => {
        var checkboxes = document.getElementsByName('myCheckbox');
        for (var i = 0; i < checkboxes.length; i++) {
            var updatedEmails = Array.from(checkboxes)
                .filter((checkbox) => checkbox.checked)
                .flatMap((checkbox) => checkbox.value.split(',').map((email) => email.trim()));
            setallEmployee(updatedEmails);
            if (!checkboxes[i].checked) {
                setchecked(false)
                return
            }
            else {
                setchecked(true)
            }

        }
    }

    console.log(Employee);
    return (
        <>
          <div className="flex justify-between mt-[51px] ">
                <div className=" mb-4">
                    <p className=" text-2xl font-semibold">Employee Management</p>
                    <p className=" opacity-80 text-sm">Home / Employee</p>
                </div>
                <Link
                    to="./addemployee"
                    className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                >
                    <MdAdd />
                    Add Employee
                </Link>

            </div>
            {!nolist
                &&
                <Filter />
            }
            {/* 
            <div className="flex justify-between items-center py-2">
                <div className="ml-3">
                    <label className=" font-extrabold">
                        <input className="mr-2" checked={checkeddd} type="checkbox" name="selectall"
                            onClick={() => { checkAllCheckboxes(); setchecked(!checkeddd) }}


                        />
                        Select All
                    </label>
                </div>
                <div className="space-x-3 flex justify-center">
           
                    <label class={`relative ${allEmployee.length == 0 && "opacity-50"}  inline-flex items-center  cursor-pointer`}>
                        {admin_permissions.Employee === 1 ?
                            <input disabled={allEmployee.length == 0 && true} onClick={() => { setalldeactivate(); BulkstatusChange(allEmployee) }} type="checkbox" class="sr-only peer" />
                            :
                            <input disabled onClick={() => {
                                toast.warning("You have no permissions to edit.")

                            }} type="checkbox" class="sr-only peer" />
                        }

                        <div class="w-7 h-3 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:bEmployee-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:bEmployee-gray-300 after:bEmployee after:rounded-full after:h-4 after:w-4 after:transition-all dark:bEmployee-gray-600 peer-checked:bg-blue-600"></div>
                        <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300"> {!alldeactivate ? "All Activate" : "All Deactivate"}
                        </span>
                    </label>

           
                    <label class={`relative ${allEmployee.length == 0 && "opacity-50"}  justify-center text-center   inline-flex   cursor-pointer`}>
                    {admin_permissions.Employee === 1 ?

                        <button disabled={allEmployee.length == 0 && true} className="flex" onClick={() => { BulkDelete(allEmployee) }}  >
                            <MdOutlineDelete className="text-[red]" />
                            <span class="ms-1 text-sm font-medium text-center text-gray-900 dark:text-gray-300">
                                All Delete
                            </span>
                        </button>
                        :
                        <button disabled={allEmployee.length == 0 && true} className="flex" onClick={() => {  toast.warning("You have no permissions to edit.") }}  >
                        <MdOutlineDelete className="text-[red]" />
                        <span class="ms-1 text-sm font-medium text-center text-gray-900 dark:text-gray-300">
                            All Delete
                        </span>
                    </button>
}

                    </label>


                </div>
            </div> */}

            <div className="card bg-base-100 shadow-md p-6 mt-8">
                <div className="overflow-x-auto w-full">
                    <table id="tablescroll" className="table table-zebra w-full">
                        <thead>
                            <tr>
                                {/* <th></th> */}
                                <th>Employee Name</th>
                                <th>Designation</th>
                                <th>Contact</th>
                                <th> status</th>
                                <th>Action</th>
                            </tr>
                        </thead>

                        <tbody className="text-sm relative">
                            {EmployeeLoader &&
                                <>
                                    <tr>
                                        <td colSpan={5} className="animate-pulse py-6"></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} className="animate-pulse py-6"></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} className="animate-pulse py-6"></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} className="animate-pulse py-6"></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} className="animate-pulse py-6"></td>
                                    </tr>
                                </>
                            }
                            {
                                // (Employees.length === 0 ? Employee : Employees)?.map((data, index) => (
                                //     <tr key={index} className="capitalize">
                                //         {/* <td>

                                //             <input
                                //                 value={data?.id}
                                //                 onChange={(e) => { setchecked(false); isallcheck(e.target.value); }}
                                //                 type="checkbox"
                                //                 name="myCheckbox"
                                //             />
                                //         </td> */}
                                //         <td>
                                //             {data?.Employee_id}
                                //         </td>

                                //         <td className="flex flex-col ">
                                //             <div>
                                //                 {data?.buyer_fullname ?? "N/A"}

                                //             </div>
                                //             <div>
                                //                 {data?.buyer_email ?? "N/A"}

                                //             </div>

                                //         </td>
                                //         <td>
                                //             <Moment
                                //                 format={
                                //                     "DD/MM/YYYY"
                                //                 }
                                //             >
                                //                 {data.Employee_date_time}
                                //             </Moment>

                                //         </td>

                                //         <td>
                                //             {data.total_amount}

                                //         </td>



                                //         <td>
                                //             <label
                                //                 title="Change Status"
                                //                 htmlFor="my-modal-5"
                                //                 className={`btn capitalize Active" ${data?.is_verified === 1 ? "bg-[#1dc9b7] hover:bg-[#1dc9b7]" : "bg-error hover:bg-error"}  text-[#000] bEmployee-hidden  btn-xs`}

                                //             // onClick={(e) => {
                                //             //     getid(data?.id);
                                //             //     setProductCatalogpopup(true);
                                //             //     getstatus(data?.is_active === 1 ? 0 : 1);
                                //             // }}
                                //             >
                                //                 {data?.payment_status ?? "n/a"}
                                //             </label>
                                //         </td>

                                //         <th >
                                //             <div className="flex space-x-3">

                                //                 <div className="flex items-center space-x-3">
                                //                     {admin_permissions.Employees === 1 ?
                                //                         <label
                                //                             htmlFor="deletecity"
                                //                             className=" text-[#d02c2c] cursor-pointer"
                                //                             title="Delete"
                                //                             onClick={() => { setdeletepopup(true); getid(data?.id) }}
                                //                         >
                                //                             <MdOutlineDelete />
                                //                         </label>
                                //                         :
                                //                         <label
                                //                             htmlFor="deletecity"
                                //                             className=" text-[#d02c2c] cursor-pointer"
                                //                             title="Delete"
                                //                             onClick={() => { toast.warning("You have no permissions to edit.") }}
                                //                         >
                                //                             <MdOutlineDelete />
                                //                         </label>
                                //                     }
                                //                     <button title="view product">
                                //                         <Link to={`./product/${data?.Employee_id}/${data.total_amount}`}>

                                //                             <MdEdit />

                                //                         </Link>
                                //                     </button>


                                //                 </div>
                                //             </div>

                                //         </th>

                                //     </tr>
                                // ))
                                <tr className="capitalize">
                                    {/* <td>

                                    <input
                                        value={data?.id}
                                        onChange={(e) => { setchecked(false); isallcheck(e.target.value); }}
                                        type="checkbox"
                                        name="myCheckbox"
                                    />
                                </td> */}
                                    <td className="flex flex-col">
                                        <span>
                                        karan
                                        </span>
                                        <span>
                                            Employee id: Ez56151
                                        </span>

                                    </td>

                                    <td >

                                        Frontend Devloper




                                    </td>
                                    <td className="flex flex-col">
                                        <span>
                                        karan@gmail.com
                                        </span>
                                        <span>
                                        730015679
                                        </span>

                                    </td>





                                    <th >
                                        <div className="flex space-x-3">
                                            <label
                                                title="Change Status"
                                                htmlFor="my-modal-5"
                                                // className={`btn capitalize Active" ${data?.is_active === 1 ? "bg-[#5dff5d] hover:bg-[#5dff5d]" : "bg-warning hover:bg-warning"}  text-[#000] border-hidden  btn-xs`}
                                                className={`btn capitalize Active bg-[#5dff5d] hover:bg-[#5dff5d]   text-[#000] border-hidden  btn-xs`}



                                                onClick={(e) => {
                                                    // getid(data?.id);
                                                    setEmployeepopup(true);
                                                    // getstatus(data?.is_active === 1 ? 0 : 1);
                                                }}
                                            >

                                                {/* {data?.is_active === 1 ? "Active" : "Inactive"} */}
                                                Active
                                            </label>

                                        </div>

                                    </th>

                                    <th >
                                        <div className="flex space-x-3">

                                            <div className="flex items-center space-x-3">
                                                {admin_permissions.Employees === 1 ?
                                                    <label
                                                        htmlFor="deletecity"
                                                        className=" text-[#d02c2c] cursor-pointer"
                                                        title="Delete"
                                                        // onClick={() => { setdeletepopup(true); getid(data?.id) }}
                                                        onClick={() => { setdeletepopup(true); }}


                                                    >
                                                        <MdOutlineDelete />
                                                    </label>
                                                    :
                                                    <label
                                                        htmlFor="deletecity"
                                                        className=" text-[#d02c2c] cursor-pointer"
                                                        title="Delete"
                                                        onClick={() => { toast.warning("You have no permissions to edit.") }}
                                                    >
                                                        <MdOutlineDelete />
                                                    </label>
                                                }
                                                <button title="Edit Employee">
                                                    {/* <Link to={`./product/${data?.Employee_id}/${data.total_amount}`}> */}
                                                    <Link>


                                                        <MdEdit />

                                                    </Link>
                                                </button>
                                                <button title="Edit Employee">
                                                    <Link to={`./viewemployee`}>


                                                        <RxEyeOpen />

                                                    </Link>
                                                </button>


                                            </div>
                                        </div>

                                    </th>

                                </tr>
                            }

                            {nolist &&
                                <tr>
                                    <td colSpan={7} className="my-10 text-center">
                                        <MdOutlineGroup className=" text-[#dedede] text-8xl mx-auto" />
                                        <p className=" font-semibold text-lg">No Employee Found</p>
                                    </td>
                                </tr>}
                        </tbody>
                    </table>
                    {/* {!city?.length == 0 && */}

                    <ReactPaginate
                        initialPage={0}
                        pageCount={Math.ceil(TotalPages)}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageChange}
                        onClick={handleClick}
                        containerClassName={'pagination'}
                        activeClassName={'active'}

                    />
                    {/* } */}

                    {/* status change model start */}
                    {/* <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="my-modal-5"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status ?
              </h3>
              <div className="modal-action justify-center">
                      <label
                        htmlFor="my-modal-5"
                        className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                        onClick={() => statusChange()}
                      >
                        Yes
                      </label> 
                <label htmlFor="my-modal-5" className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]">
                  No
                </label>
              </div>
            </div>
          </div> */}
                    {/* status change model end */}
                    {deletepopup &&
                        <>
                            <input type="checkbox" id="deletecity" className="modal-toggle" />
                            <div className="modal">
                                <div className="modal-box relative">
                                    <label

                                        className="btn btn-sm btn-circle absolute right-2 top-2"
                                        onClick={() => { setdelateLoader(false); setdeletepopup(false) }}
                                    >
                                        ✕
                                    </label>
                                    <h3 className="text-lg font-bold text-center">
                                        Are you sure you want to Delete this Employee
                                    </h3>
                                    <div className="modal-action justify-center">
                                        {/* <label
                                            htmlFor="deletecity"
                                            className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                            onClick={() => deletefunction()}
                                        >
                                            Yes
                                        </label> */}
                                        {!delateLoader ?
                                            <label

                                                className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                                onClick={() => deletefunction()}
                                            >
                                                Yes
                                            </label> :
                                            <button
                                                // type="submit"
                                                disabled
                                                className="flex items-center bg-color_theme px-3 mx-5 rounded-md"
                                            // onClick={() => {
                                            //   PostMatchscore();
                                            // }}
                                            >
                                                Yes..
                                                <svg
                                                    className="animate-spin mr-1 h-5 w-5 text-white"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle
                                                        className="opacity-25"
                                                        cx="12"
                                                        cy="12"
                                                        r="10"
                                                        stroke="currentColor"
                                                        strokeWidth="4"
                                                    ></circle>
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    ></path>
                                                </svg>
                                            </button>}
                                        <label
                                            onClick={() => setdeletepopup(false)}
                                            className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </>

                    }
                    {Employeepopup &&
                        <>
                            <input type="checkbox" id="my-modal-5" className="modal-toggle" />
                            <div className="modal">
                                <div className="modal-box relative">
                                    <label

                                        className="btn btn-sm btn-circle absolute right-2 top-2"
                                        onClick={() => setEmployeepopup(false)}
                                    >
                                        ✕
                                    </label>
                                    <h3 className="text-lg font-bold text-center">
                                        Are you sure you want to change Status ?
                                    </h3>
                                    <div className="modal-action justify-center">
                                        {!EmployeeLoader ? <label

                                            className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                            onClick={() => statusChange()}
                                        >
                                            Yes
                                        </label> :
                                            <button
                                                // type="submit"
                                                disabled
                                                className="flex items-center bg-color_theme px-3 mx-5 rounded-md"
                                            // onClick={() => {
                                            //   PostMatchscore();
                                            // }}
                                            >
                                                Yes..
                                                <svg
                                                    className="animate-spin mr-1 h-5 w-5 text-white"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle
                                                        className="opacity-25"
                                                        cx="12"
                                                        cy="12"
                                                        r="10"
                                                        stroke="currentColor"
                                                        strokeWidth="4"
                                                    ></circle>
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    ></path>
                                                </svg>
                                            </button>}

                                        <label
                                            onClick={() => setEmployeepopup(false)}
                                            // htmlFor="voidstatus"
                                            className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                                        >
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>

                        </>

                    }
                </div>
            </div>
        </>
    );
});

