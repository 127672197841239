import React, { useState } from "react";
import { Input } from "react-daisyui";
import {
  MdOutlineCheck,
  MdOutlineClose,
  MdOutlineRefresh,
  MdOutlineSearch,
} from "react-icons/md";
import { observer } from "mobx-react";
// import { TransactionStoreContext,TransactionStore, useTransactionStore} from './store/TransactionStore';
import {
  TransactionStore,
  useTransactionStore,
  TransactionStoreContext,
} from "../store/TransactionStore";

const Filter = observer(() => {
   const { fName,getUsername,getLeaguename,getFiltertransaction,getTransaction,leaguedropdown,getPaymentstatus,allclear,getstartdate,getenddate,getfirstname,getSuscriptionstatus} = useTransactionStore();
   const [startdatestate,setstartdatestate] = useState(null)
  // const Reset=()=>{
  //     getHomename("")
  //     updatedcitynameid("")
  //     getzipcode("")
  //     getstatus("")
  //     setapprovedby("")
  // }

  return (
    <>
      <div className="card bg-base-100 shadow-md p-6 mt-3 mb-10">
        <form
          onSubmit={(e) => {
            getFiltertransaction()
            e.preventDefault();
          }}
        >
          <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
          <div>
              <label
                htmlFor="LeagueName"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                League Name
              </label>
              <select
                defaultValue=""
                id="LeagueName"
                name="LeagueName"
                onChange={(e) => getLeaguename(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                {/* <option value="" disabled className="hidden">Select city</option> */}
                <option value="" className="">
                  All
                </option>
                {leaguedropdown.map((data, index) => (
                  <option value={data?._id} key={index}>
                    {data?.name}
                  </option>
                ))}
              </select>
        
            </div>
            <div>
              <label
                htmlFor="UserName"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                User Name
              </label>
              <Input
                name="UserName"
                 onChange={(e) => getfirstname(e.target.value)}
                placeholder="User Name"
                id="UserName"
                type="text"
                // value={fName}
                // maxLength={5}
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            {/* <div>
              <label
                htmlFor="TransactionId"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Transaction Id
              </label>
              <Input
                name="TransactionId"
                // onChange={(e) => getUserName(e.target.value)}
                placeholder="Transaction Id"
                id="TransactionId"
                type="text"
                // maxLength={5}
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div> */}
            {/* <div>
              <label
                htmlFor="Amount"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Amount
              </label>
              <Input
                name="Amount"
                // onChange={(e) => getUserName(e.target.value)}
                placeholder="Amount"
                id="Amount"
                type="text"
                // maxLength={5}
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div> */}
            {/* <div>
                        <label htmlFor="addedby" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Added By</label>
                        <select 
                        id="addedby"
                        name="addedby"
                        defaultValue=""
                        // onChange={(e) => setaddedby(e.target.value)}
                        className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                            <option value="" className="">All</option>
                            <option value="true">Admin</option>
                            <option value="false">User</option>
                        </select>
                    </div> */}
            {/* <div>
              <label
                htmlFor="transactiondate"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Transaction Date
              </label>
              <input
                name="transactiondate"
                placeholder=""
                // value={edittransactiondates}
                // onChange={(e) => {
                //   addstartdat(e.target.value);
                //   settransactiondatestate(e.target.value);
                // }}
                id="transactiondate"
                // min={new Date().toISOString().split('T')[0]}
                // max="2025-01-01"
                type="date"
                autoComplete="off"
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
              />
            </div> */}
            <div>
                        <label htmlFor="startdate" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Start Date</label>
                        <input 
                        name="startdate" 
                        placeholder="" 
                        onChange={(e) =>{ getstartdate(e.target.value);setstartdatestate(e.target.value)}}
                        id="startdate" 
                        // min={new Date().toISOString().split('T')[0]}
                        max="2025-01-01"
                        type="date" 
                        autoComplete="off" 
                        className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
                        />
                    </div>
                    <div>
                        <label htmlFor="enddate" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">End Date</label>
                
                        <input 
                        name="enddate" 
                        placeholder="" 
                        onChange={(e) => getenddate(e.target.value)}
                        id="enddate" 
                        disabled={startdatestate === null ? true: false}                   
                        min={new Date(startdatestate ? startdatestate : null).toISOString().split('T')[0]}
                        max="2025-01-01"
                        type="date" 
                        autoComplete="off" 
                        className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
                        />
                    </div>
            <div>
              <label
                htmlFor="Status"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Status
              </label>
              <select
                id="Status"
                name="Status"
                defaultValue=""
                 onChange={(e) => getPaymentstatus(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="" className="">
                  All
                </option>
                <option value="succeeded">Succeeded</option>
                <option value="pending">Pending</option>
                <option value="failed">Failed</option>
                {/* <option value="refunded">Refunded</option>
                <option value="cancelled">Cancelled</option> */}

              </select>
            </div>
            <div>
              <label
                htmlFor="Status"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Subscription
              </label>
              <select
                id="Status"
                name="Status"
                defaultValue=""
                 onChange={(e) => getSuscriptionstatus(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="" className="">
                  All
                </option>
                <option value="paid">Paid</option>
                <option value="free">Free</option>
                {/* <option value="refunded">Refunded</option>
                <option value="cancelled">Cancelled</option> */}

              </select>
            </div>
          </div>
          <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
           
            <button type='reset' onClick={()=>{getTransaction();allclear() }} className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"><MdOutlineRefresh className="mr-1"/>Reset</button>
            <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
              <MdOutlineSearch className="mr-1" />
              Search
            </button>
          </div>
        </form>
      </div>
    </>
  );
});

export default Filter;
