import { createContext, useContext } from "react";
import { makeAutoObservable, observable, runInAction } from "mobx";
import {
  postMethodData,
  getMethodData,
  putMethod,
  delMethod,
  patchMethodData,
} from "../../../ApiMethods/api";

import { toast } from "react-toastify";

export class LevelStore {
  Token = null;
  loadlevel = false;
  Levelpopup=false;
  LevelLoader=false;
  Level = [];
  dropdownLevel =[]
  FilterLevel = [];
  loadleague = false;
  nolist=false;

  errloadlevel = "";
  addlevels = "";
  adddescriptions = "";
  editstatus = "";
  addstatusss = "";

  saved = false;

  status = false;

  loadeditlevel= false;

  constructor(isloading = false) {
    makeAutoObservable(this);
    this.Token = localStorage.getItem("Admintoken");
    if (isloading.seasonlist) {
      this.getLevelList();
      this.getDropdownList();
      return;
    }
    if (isloading.isloading) {
      this.getLevelList();

      return;
    }
    if (isloading.editloading) {
      this.editlevellist(isloading.id);
      
      return;
    }
  }

  //   // status popup
  //   setstatuspopup = () => {
  //     this.statuspopup = !this.statuspopup;
  //   };
  //All clear
  allclear = () => {
    this.editleagueCat = "";
    this.editleaguetype = "";
    this.listofseason = "";
    this.editstartdates = "";
    this.editenddates = "";
    this.editstatus = "";
    this.leaguename = "";
    this.editstatus = "";
    this.addlevels = "";
  };
  setlevelpopup = (e) => {
    this.Levelpopup = e;
  };
  getlevelid = (text) => {
    this.levelid = text;
  };

  //edit year
  edityears = (text) => {
    this.edityear = text;
  };

  // get status
  getstatus = (text) => {
    this.status = text;
  };

  //get name
  getname = (text) => {
    this.name = text;
  };

  addstatus = (text) => {
    this.addstatusss = text;
  };

  addlevel = (text) => {
    this.addlevels = text;
  };
  adddescription = (text) => {
    this.adddescriptions = text;
  };
  // get Level list
  getLevelList = () => {
    runInAction(() => {
      this.loadlevel = true;
      this.loadleague = true;
      this.nolist=false;
      this.errloadlevel = "";
      this.saved = false;
    });
    getMethodData("/api/admin/level", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadleague = false;
            this.errloadhomecourt = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadleague = false;
            this.errloadhomecourt = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.Level = resp?.data?.data;
            this.saved = false;
            this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.addleague = false;
          this.loadleague = false;
          this.errloadleague = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadSeason = false;
          if (!error.response) {
            this.errloadlevel = error?.message;
            toast.error(error?.message);
          }
          this.errloadlevel = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };
  //List Dropdown
  getDropdownList = () => {
    runInAction(() => {
      this.loadlevel = true;
      this.errloadlevel = "";
      this.saved = false;
    });
    getMethodData("/api/admin/level", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.errloadhomecourt = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.errloadhomecourt = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.dropdownLevel = resp?.data?.data;
            this.saved = false;
            this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.addleague = false;
          this.errloadleague = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadSeason = false;
          if (!error.response) {
            this.errloadlevel = error?.message;
            toast.error(error?.message);
          }
          this.errloadlevel = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };
  // add level
  addlevellist = () => {
    runInAction(() => {
      this.loadlevel = true;
      this.errloadlevel = "";
    });
    postMethodData(
      "/api/admin/level",
      {
        name: this.addlevels,
        description: this.adddescriptions,
        isActive: this.addstatusss,
      },
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadlevel = false;
            this.errloadlevel = resp?.data?.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadlevel = false;
            this.addloadhomecourt = false;
            this.erraddhomecourt = resp?.data?.message;
            // toast.success(resp.data.message);
            this.getLevelList();

            this.saved = true;
          });
          return;
        }
        runInAction(() => {
          this.loadlevel = false;
          this.errloadlevel = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadlevel = false;
          if (!error.response) {
            this.errloadlevel = error?.message;
            toast.error(error?.message);
          }
          this.errloadlevel = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };

  // filter League court

  getFilterLevelcourt = () => {
    runInAction(() => {
      this.loadlevel = true;
      this.errloadlevel = "";
      this.saved = false;
    });
    getMethodData(
      `/api/admin/level?filters[page]=1&filters[name]=${this.addlevels}`,
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadlevel = false;
            this.errloadlevel = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadlevel = false;
            this.errloadlevel = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.Level = resp?.data?.data;
            this.saved = false;
            this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.errloadleague = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.errloadleague = error?.message;
            toast.error(error?.message);
          }
          this.errloadleague = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };

  // Edit league
  editlevellist = (id) => {
    runInAction(() => {
      this.loadlevel = true;
      this.errloadlevel = "";
    });

    getMethodData(`/api/admin/level/${id}`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadlevel = false;
            this.errloadlevel = resp?.data?.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.addlevels = resp.data.data.name;
            this.adddescriptions=  resp.data.data.description;
            this.addstatusss = resp.data.data.isActive;
          });
          return;
        }
        runInAction(() => {
          this.addlevel = false;
          this.erraddseasoncourt = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.addseasoncourt = false;
          if (!error.response) {
            this.erraddhomecourt = error?.message;
            toast.error(error?.message);
          }
          this.erraddseasoncourt = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };

  //Active and inactive
  statusChange = () => {
    runInAction(() => {
      this.LevelLoader = true;
      this.Levelpopup=true;
      this.errloadleague = "";
    });
    patchMethodData(
      `/api/admin/level/status/${this.levelid}`,
      { isActive: `${!this.status}` },
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.LevelLoader = true;
            this.Levelpopup=true;
            this.errloadlevel = resp?.data?.message;
            toast.error(resp?.data?.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.LevelLoader = false;
            this.Levelpopup=false;
            this.errloadlevel = resp?.data?.message;
            this.getLevelList();
            toast.success(resp?.data?.message);
          });
          return;
        }
        runInAction(() => {
          this.errloadlevel = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.LevelLoader = false;
            this.errloadlevel = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.LevelLoader = false;
          this.errloadlevel = error?.response?.data?.message;
        });
        return;
      });
  };
  //League status
  getallleaguestatus = () => {
    runInAction(() => {
      this.errloadleague = "";
    });
    patchMethodData(
      `/api/admin/league/leagueStatus/${this.leagueid}`,
      { league_status: this.leaguestatus },
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.errloadleague = resp?.data?.message;
            toast.error(resp?.data?.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadleague = false;
            this.errloadleague = resp?.data?.message;
            this.getLeagueList();
            toast.success(resp?.data?.message);
          });
          return;
        }
        runInAction(() => {
          this.loadleague = false;
          this.errloadleague = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.loadleague = false;
            this.errloadleague = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.loadleague = false;
          this.errloadleague = error?.response?.data?.message;
        });
        return;
      });
  };

  //update method
  updateLevel = (id) => {
    runInAction(() => {
      this.loadeditlevel = true;
      this.errloadlevel = "";
      this.saved = false;
    });
    putMethod(
      `/api/admin/level/${id}`,
      {
        name: this.addlevels,
        description: this.adddescriptions,
        isActive: this.addstatusss,
      },
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadeditlevel = false;
            this.addlevel = false;
            this.errloadlevel = resp?.data?.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadeditlevel = false;
            this.loadlevel = false;
            this.errloadleague = resp?.data?.message;
            toast.success(resp.data.message);
            this.saved = true;
          });
          return;
        }
        runInAction(() => {
          this.loadeditlevel = false;
          this.addloadhomecourt = false;
          this.erraddhomecourt = resp?.data?.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.addloadhomecourt = false;
            this.erraddhomecourt = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.loadeditlevel = false;
          this.addloadhomecourt = false;
          this.erraddhomecourt = error?.response?.data?.message;
        });
        return;
      });
  };
}

export const LevelStoreContext = createContext(new LevelStore());
export const useLevelStore = () => useContext(LevelStoreContext);
