import { createContext, useContext } from "react";
import { action, makeAutoObservable, makeObservable, observable, runInAction } from "mobx";
import { postMethodData, getMethodData, putMethod, delMethod, patchMethodData } from "../../ApiMethods/api";
import { toast } from "react-toastify";
const SEO_URL = process.env.REACT_APP_SEO_URL;





export class FAQStore {

    Token = null;
    FAQ = [];
    addloadFAQ = false;
    FAQEditLoader = false;
    prod_id = "";
    rec_id = "";
    FAQ_Object = [];
    answer = "";
    question = "";
    savedfun = false;
    DuplicateProduct = "";
    Productlist = [];
    ProductCatalog = [];
    filterSearch = "";
    PaginationPage = "1";
    TotalPages = "";
    BannerDimiensions = ""
    crop = {};
    errLoadFAQ = "";
    LoadFAQ = false;
    delateLoader = false;
    nolist = false;
    FAQpopup = false;
    FAQAddpopup = false;
    deletepopup = false;
    FAQname = "";
    status = "1";
    statesid = "";
    FAQid = [];
    seoUrl = "";
    statuspopup = false;
    addFAQname = "";
    imageurl = "";
    logoimage = "";
    bannerimage = "";
    Base64logoImage = "";
    Base64BannerImage = "";
    logofile = [];
    allFAQ = [];
    alldeactivate = 'false';
    isBannerUpdated = "false";
    isLogoUpdated = "false"
    checkeddd = '';
    EditFAQFormDetails = {
        "answer": "",
        "question": ""
    };
    saved = false;

    constructor(isloading = false) {
        makeAutoObservable(this, {
            addFAQname: observable,
            status: observable,
            errorMessages: observable,
            setaddFAQname: action,
            getstatus: action,
            resetErrors: action,
        });

        this.Token = localStorage.getItem("Admintoken");
        if (isloading.isloading) {
            this.getFilterFAQ();

            //   this.getStateList();
            return
        }
        if (isloading.Productlist) {
            // this.getProductList(isloading.id);
            this.getProductCatalogList();
            return
        }
        if (isloading.addloading) {
            //   this.getStateList();
        }
        if (isloading.FAQeditloading) {
            this.getFAQform(isloading.id);
            return
        }
    }
    //All Clear 
    allclear = () => {
        this.filterSearch = ""
        this.getFilterFAQ();
        //   this.status="";
        //   this.statesid="";
    }
    setimage = (text, type) => {
        this.imageurl = URL.createObjectURL(text[0]);
    }

    setalldeactivate = (text) => {
        console.log(text);
        this.alldeactivate = !this.alldeactivate;
    };

    getid = (text) => {
        this.FAQid.push(text);
    };


    // status popup
    setstatuspopup = () => {
        this.statuspopup = !this.statuspopup;
    };


    setallFAQ = (text) => {
        this.allFAQ = text;
    }

    // get status 
    getstatus = (text) => {
        this.status = text;
    };

    //get city name
    getFAQname = (text) => {
        this.FAQname = text;
    };
    getprod_id = (text) => {
        this.prod_id = text;
    };
    getrec_id = (text) => {
        this.rec_id = text;
    };
    setaddFAQname = (text) => {
        this.addFAQname = text;
    };
    setCrop = (text) => {
        this.crop = text;
    };

    setBannerDimiensions = (text) => {
        this.BannerDimiensions = text;
    };

    setlogofile = (e) => {
        this.logofile = e;
        console.log(this.logofile);
    };

    setFAQpopup = (e) => {
        this.FAQpopup = e;
    };
    setFAQAddpopup = (e) => {
        this.FAQAddpopup = e;
    };
    setdeletepopup = (e) => {
        this.deletepopup = e;
    };
    setdelateLoader = (e) => {
        this.delateLoader = e;
    }
    getToken = (e) => {
        this.Token = e;
    }
    getseourl = (text) => {
        // console.log(`https://demo.e-kart.com/${text}`);
        this.seoUrl = text;
    };
    getlogoimage = (text) => {
        console.log("checking in the FAQ", text);
        this.logoimage = text;
    };
    getbanner_image = (text) => {
        console.log(text);
        this.bannerimage = text;
    };
    SetfilterSearch = (text) => {
        this.filterSearch = text;
    };
    setPaginationPage = (text) => {
        this.PaginationPage = text;
    };
    SetTotalPages = (text) => {
        this.TotalPages = text;
    };
    setchecked = (text) => {
        this.checkeddd = text;
    };
    setisBannerUpdated = (text) => {
        this.isBannerUpdated = text;
    };
    setisLogoUpdated = (text) => {
        this.isLogoUpdated = text;
    };
    setDuplicateProduct = (text) => {
        console.log(text);
        this.DuplicateProduct = text;
    };
    setquestion = (text) => {
        this.question = text;
    };
    setanswer = (text) => {
        this.answer = text;
    };
    isBase64 = (str) => {
        try {
            return btoa(atob(str)) === str;
        } catch (err) {
            return false;
        }
    };
    setEditFAQFormDetails = (FAQDetail) => {
        this.EditFAQFormDetails = {
            ...this.EditFAQFormDetails,
            answer: FAQDetail.answer || "", // Use default value if name is undefined
            question: FAQDetail.question
        };
    };
    // setFAQFormDetails = (text) => {
    //     this.FAQFormDetails = text;
    //     console.log("setFAQFormDetails", this.FAQFormDetails.FAQname);
    // };
    base64toFile(base64Data, fileName, mimeType) {
        if (base64Data) {
            const byteCharacters = atob(base64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                const slice = byteCharacters.slice(offset, offset + 512);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const file = new File(byteArrays, fileName, { type: mimeType });

            return file;
        }

    }

    // get FAQ list
    getFAQList = () => {
        runInAction(() => {
            this.FAQLoader = true;
            this.errLoadFAQ = "";
            this.saved = false;
            this.nolist = false;
        });
        getMethodData(`/api/v1/admin/FAQs/get-list?page=${this.PaginationPage + 1}`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.FAQLoader = false;
                        this.errLoadFAQ = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.FAQLoader = false;
                        this.errLoadFAQ = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.FAQ = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadFAQ = false;
                    this.errLoadFAQ = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.FAQLoader = false;
                    if (!error.response) {
                        this.errLoadFAQ = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadFAQ = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };
    //Filter city
    // filter League court

    getFilterFAQ = () => {
        runInAction(() => {
            this.FAQLoader = true;
            this.LoadFAQ = true;
            this.errLoadFAQ = "";
            this.saved = false;
            this.nolist = false;
        });
        postMethodData(`/api/v1/admin/FAQs/search?page=${this.PaginationPage + 1}`, {
            keyword: this.filterSearch
        }, this.Token)
            .then((resp) => {

                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.loadleague = false;
                        this.FAQLoader = false;

                        this.errloadleague = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {

                    runInAction(() => {
                        this.loadcity = false;
                        this.FAQLoader = false;
                        this.errloadleague = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.FAQ = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.loadcity = false;
                    this.errloadcuty = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.loadleague = false;
                    this.FAQLoader = false;
                    if (!error.response) {
                        this.errloadleague = error?.message;
                        toast.error(error?.message);
                    }
                    this.errloadleague = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    //Product list 
    // get FAQ list
    getProductList = (id) => {
        runInAction(() => {
            this.FAQLoader = true;
            this.errLoadFAQ = "";
            this.saved = false;
            this.nolist = false;
        });
        postMethodData(`/api/v1/admin/FAQs/get-list-mapping`,

            {
                "FAQ_id": id
            }
            , this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.FAQLoader = false;
                        this.errLoadFAQ = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    console.log(resp?.data?.data.length);
                    runInAction(() => {
                        this.FAQLoader = false;
                        this.errLoadFAQ = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                            console.log("nolist === true");
                        } else {
                            console.log("nolist === false");
                            this.nolist = false;
                        }
                        this.Productlist = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadFAQ = false;
                    this.errLoadFAQ = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.FAQLoader = false;
                    if (!error.response) {
                        this.errLoadFAQ = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadFAQ = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    getProductCatalogList = () => {
        runInAction(() => {
            this.ProductCatalogLoader = true;
            this.errLoadProductCatalog = "";
            this.saved = false;
            this.nolist = false;
            this.ProductCatalog = [];
        });
        getMethodData(`/api/v1/admin/FAQ/get-list`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductCatalogLoader = false;
                        this.errLoadProductCatalog = resp?.data?.message
                    });
                    return;
                }

                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ProductCatalogLoader = false;
                        this.errLoadProductCatalog = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.ProductCatalog = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadProductCatalog = false;
                    this.errLoadProductCatalog = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.ProductCatalogLoader = false;
                    if (!error.response) {
                        this.errLoadProductCatalog = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadProductCatalog = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    // add new FAQ
    addFAQ = () => {
        runInAction(() => {
            this.addloadFAQ = true;
            this.errLoadFAQ = "";
        });
        const formData = new FormData();
        formData.append('question', this.question);
        formData.append('answer', this.answer);
        formData.append('prod_id', this.prod_id);

        postMethodData("/api/v1/admin/FAQ/add", formData, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.addloadFAQ = false;
                    this.errLoadFAQ = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.addloadFAQ = false;
                    this.setFAQAddpopup(false);
                    this.errLoadFAQ = resp?.data?.message;
                    toast.success(resp.data.message);
                    this.getProductCatalogList();
                    this.saved = true;
                })
                return
            }
            runInAction(() => {
                this.addloadFAQ = false;
                this.errLoadFAQ = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadFAQ = false;
                    if (!error.response) {
                        this.errLoadFAQ = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadFAQ = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    getEditFaqForm = (id) => {
        runInAction(() => {
            this.FAQLoader = true;
            this.errLoadFAQ = "";
            this.saved = false;
            this.nolist = false;
        });
        postMethodData(`/api/v1/admin/FAQ/form`,

            {
                "record_id": id
            }
            , this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.FAQLoader = false;
                        this.errLoadFAQ = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    console.log(resp?.data?.data.length);
                    runInAction(() => {
                        this.FAQLoader = false;
                        this.errLoadFAQ = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                            console.log("nolist === true");
                        } else {
                            console.log("nolist === false");
                            this.nolist = false;
                        }
                        this.setEditFAQFormDetails(resp?.data?.data);
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadFAQ = false;
                    this.errLoadFAQ = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.FAQLoader = false;
                    if (!error.response) {
                        this.errLoadFAQ = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadFAQ = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    EditFaq = (id) => {
        runInAction(() => {
            this.FAQEditLoader = true;
            this.errLoadFAQ = "";
            this.saved = false;
            this.nolist = false;
        });
        postMethodData(`/api/v1/admin/FAQ/edit`,
            {
                "record_id": id,
                "prod_id": this.prod_id,
                "question": this.EditFAQFormDetails.question,
                "answer": this.EditFAQFormDetails.answer,
            }
            , this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.FAQEditLoader = false;
                        this.errLoadFAQ = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.FAQEditLoader = false;
                        this.errLoadFAQ = resp?.data?.message;
                        this.getFAQform(this.prod_id)
                        toast.success(resp.data.message);

                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadFAQ = false;
                    this.errLoadFAQ = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.FAQEditLoader = false;
                    if (!error.response) {
                        this.errLoadFAQ = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadFAQ = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };
    //Active and inactive
    statusChange = (id, status, prod_id) => {
        runInAction(() => {
            // this.FAQLoader = true;
            this.errLoadFAQ = "";
        });
        postMethodData(`/api/v1/admin/FAQ/update-status`, {

            "record_id": id,
            "status": JSON.stringify(status)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.FAQid = [];
                        // this.FAQLoader = false;

                        this.errLoadFAQ = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        // this.FAQLoader = false;

                        this.errLoadFAQ = resp?.data?.message;
                        this.getFAQform(prod_id)

                        // this.getFAQform(id);
                        this.FAQid = [];
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    // this.FAQLoader = false;

                    this.errLoadFAQ = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        // this.FAQLoader = false;

                        this.FAQid = [];
                        this.errLoadFAQ = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    // this.FAQLoader = false;
                    this.FAQid = [];


                    this.errLoadFAQ = error?.response?.data?.message;
                })
                return;
            })
    };
    // Bulk Active and inactive
    // BulkstatusChange = (allFAQ) => {
    //     runInAction(() => {
    //         this.FAQLoader = true;
    //         this.FAQpopup = true;

    //         this.errLoadFAQ = "";
    //     });

    //     postMethodData(`/api/v1/admin/FAQ/toggle-bulk-status`, {

    //         "branchId": JSON.stringify(allFAQ),
    //         "status": JSON.stringify(this.alldeactivate ? 1 : 0)

    //     }, this.Token)
    //         .then((resp) => {
    //             if (resp?.data?.status === false) {
    //                 runInAction(() => {
    //                     this.FAQLoader = false;
    //                     this.FAQpopup = false;
    //                     this.errLoadFAQ = resp?.data?.message;
    //                     toast.error(resp?.data?.message);
    //                 });
    //                 return;
    //             }
    //             if (resp?.data?.status === true) {
    //                 runInAction(() => {
    //                     this.FAQLoader = false;
    //                     this.FAQpopup = false;

    //                     this.errLoadFAQ = resp?.data?.message;
    //                     this.getFilterFAQ();
    //                     toast.success(resp?.data?.message);
    //                 })
    //                 return;
    //             }
    //             runInAction(() => {
    //                 this.FAQLoader = false;
    //                 this.FAQpopup = false;

    //                 this.errLoadFAQ = resp?.data?.message;
    //             })
    //         })
    //         .catch((error) => {
    //             runInAction(() => {
    //                 if (!error.response) {
    //                     this.FAQLoader = false;
    //                     this.FAQpopup = false;

    //                     this.errLoadFAQ = error?.message;
    //                     toast.error(error?.message);
    //                 }
    //                 toast.error(error?.response?.data?.message);
    //                 this.FAQLoader = false;
    //                 this.FAQpopup = false;

    //                 this.errLoadFAQ = error?.response?.data?.message;
    //             })
    //             return;
    //         })
    // };

    //Delete method
    deletefunction = (id, prod_id) => {
        runInAction(() => {
            this.setdelateLoader(true);
            this.deletepopup = true;
            this.errLoadFAQ = "";
        });
        postMethodData(`/api/v1/admin/FAQ/delete`, {

            "record_id": id,

        }, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.LoadFAQ = false;
                    this.setdelateLoader(true);
                    this.deletepopup = true;
                    this.errLoadFAQ = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.LoadFAQ = false;
                    this.setdelateLoader(false);
                    this.deletepopup = false;
                    this.errLoadFAQ = resp?.data?.message;
                    this.getFAQform(prod_id)
                    this.FAQid = [];

                    // toast.success(resp.data.message);
                })
                return;
            }
            runInAction(() => {
                this.LoadFAQ = false;
                this.errLoadFAQ = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadcity = false;
                    if (!error.response) {
                        this.LoadFAQ = false;
                        this.setdelateLoader(false);
                        this.deletepopup = false;
                        this.errLoadFAQ = error?.message;
                        toast.error(error?.message);
                    }
                    this.setdelateLoader(false);
                    this.FAQid = [];

                    this.LoadFAQ = false;
                    this.errLoadFAQ = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            })
    }
    // // Bulk Delete 
    // BulkDelete = (allFAQ) => {
    //     runInAction(() => {
    //         this.FAQLoader = true;
    //         this.FAQpopup = true;

    //         this.errLoadFAQ = "";
    //     });

    //     postMethodData(`/api/v1/admin/FAQ/multi-delete`, {

    //         "FAQId": JSON.stringify(allFAQ),
    //         // "status": JSON.stringify(this.alldeactivate ? 1 : 0)

    //     }, this.Token)
    //         .then((resp) => {
    //             if (resp?.data?.status === false) {
    //                 runInAction(() => {
    //                     this.FAQLoader = false;
    //                     this.FAQpopup = false;
    //                     this.errLoadFAQ = resp?.data?.message;
    //                     toast.error(resp?.data?.message);
    //                 });
    //                 return;
    //             }
    //             if (resp?.data?.status === true) {
    //                 runInAction(() => {
    //                     this.FAQLoader = false;
    //                     this.FAQpopup = false;

    //                     this.errLoadFAQ = resp?.data?.message;
    //                     this.getFilterFAQ();
    //                     toast.success(resp?.data?.message);
    //                 })
    //                 return;
    //             }
    //             runInAction(() => {
    //                 this.FAQLoader = false;
    //                 this.FAQpopup = false;

    //                 this.errLoadFAQ = resp?.data?.message;
    //             })
    //         })
    //         .catch((error) => {
    //             runInAction(() => {
    //                 if (!error.response) {
    //                     this.FAQLoader = false;
    //                     this.FAQpopup = false;

    //                     this.errLoadFAQ = error?.message;
    //                     toast.error(error?.message);
    //                 }
    //                 toast.error(error?.response?.data?.message);
    //                 this.FAQLoader = false;
    //                 this.FAQpopup = false;

    //                 this.errLoadFAQ = error?.response?.data?.message;
    //             })
    //             return;
    //         })
    // };


    //get FAQ by id
    getFAQform = (id) => {
        runInAction(() => {
            this.FAQLoader = true;
            this.errLoadFAQ = "";
        });

        getMethodData(`/api/v1/admin/FAQ/view/${id}`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.FAQLoader = false;

                        this.errLoadFAQ = resp?.data?.message;
                        // toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.FAQ_Object = resp.data.data;
                        // this.setFAQFormDetails(resp.data.data.FAQDetail);
                        this.FAQLoader = false;

                        this.errLoadFAQ = resp?.data?.message;
                    })
                    return;
                }
                runInAction(() => {
                    this.FAQLoader = false;


                    this.errLoadFAQ = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.FAQLoader = false;


                        this.errLoadFAQ = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.FAQLoader = false;


                    this.errLoadFAQ = error?.response?.data?.message;
                })
                return;
            })
    };




    //update method
    updateFAQ = (id) => {
        runInAction(() => {
            // this.addloadFAQ = true;
            this.errLoadFAQ = "";
        });
        const formData = new FormData();


        formData.append('record_id', id);
        formData.append('FAQ_name', this.FAQFormDetails.name);
        formData.append('is_active', this.FAQFormDetails.is_active);
        // formData.append('isBannerUpdated', false  );
        // formData.append('isLogoUpdated', true  );


        putMethod(`/api/v1/admin/FAQs/edit`,
            formData,
            this.Token
        )
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.addloadcity = false;
                        this.erraddcity = resp?.data?.message;
                        toast.error(resp.data.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.addloadcity = false;
                        this.erraddcity = resp?.data?.message;
                        toast.success(resp.data.message);
                        // this.getCity();
                        this.getFAQList();
                        this.saved = true;
                    })
                    return
                }
                runInAction(() => {
                    this.addloadcity = false;
                    this.erraddcity = resp?.data?.message;
                    toast.error(resp.data.message);
                });
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.addloadcity = false;
                        this.erraddcity = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.addloadcity = false;
                    this.erraddcity = error?.response?.data?.message;
                })
                return;
            })
    };


    // add new FAQ
    addProductFAQ = (FAQ_id, product_id) => {
        return new Promise((resolve, reject) => {
            runInAction(() => {
                this.FAQLoader = true;
                this.savedfun = false;
                this.Productlist = [];
                this.errLoadFAQ = "";
            });

            const formData = new FormData();

            formData.append('FAQ_id', FAQ_id);
            formData.append('prod_id', product_id);

            postMethodData("/api/v1/admin/FAQs/add-FAQ-product", formData, this.Token)
                .then((resp) => {
                    this.savedfun = true;

                    if (resp?.data?.status === false) {
                        runInAction(() => {
                            this.FAQLoader = false;
                            this.Productlist = [];
                            this.errLoadFAQ = resp?.data?.message;
                            toast.error(resp.data.message);
                            reject(new Error(resp?.data?.message));
                        });
                    } else if (resp?.data?.status === true) {
                        runInAction(() => {
                            this.FAQLoader = false;
                            this.errLoadFAQ = resp?.data?.message;
                            toast.success(resp.data.message);
                            this.getProductList(FAQ_id);
                            this.setDuplicateProduct(true);
                            this.savedfun = true;
                            resolve();
                        });
                    } else {
                        runInAction(() => {
                            this.FAQLoader = false;
                            this.errLoadFAQ = resp?.data?.message;
                            reject(new Error(resp?.data?.message));
                        });
                    }
                })
                .catch((error) => {
                    runInAction(() => {
                        this.FAQLoader = false;
                        this.getProductList(FAQ_id);
                        this.savedfun = true;

                        if (!error.response) {
                            this.errLoadFAQ = error?.message;
                            toast.error(error?.message);
                            this.setDuplicateProduct(false);
                            reject(error);
                        } else {
                            this.errLoadFAQ = error?.response?.data?.message;
                            this.setDuplicateProduct(false);
                            toast.error(error?.response?.data?.message);
                            reject(new Error(error?.response?.data?.message));
                        }
                    });
                });
        });
    };

    deleteProductFAQ = (recordid, FAQid) => {
        runInAction(() => {
            this.setdelateLoader(true);
            this.FAQLoader = true;
            this.Productlist = [];

            this.errLoadFAQ = "";
        });
        postMethodData(`/api/v1/admin/FAQs/delete-FAQ-product`, {

            "record_id": JSON.parse(recordid),

        }, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.LoadFAQ = false;
                    this.setdelateLoader(true);
                    this.FAQLoader = true;
                    this.Productlist = [];
                    this.errLoadFAQ = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.LoadFAQ = false;
                    this.setdelateLoader(false);
                    this.FAQLoader = false;
                    this.errLoadFAQ = resp?.data?.message;
                    this.getProductList(FAQid);

                    this.FAQid = [];

                    // toast.success(resp.data.message);
                })
                return;
            }
            runInAction(() => {
                this.LoadFAQ = false;
                this.errLoadFAQ = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadcity = false;
                    if (!error.response) {
                        this.LoadFAQ = false;
                        this.FAQLoader = false;
                        this.setdelateLoader(false);
                        this.deletepopup = false;
                        this.errLoadFAQ = error?.message;
                        toast.error(error?.message);
                    }
                    this.LoadFAQ = false;
                    this.errLoadFAQ = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            })
    }
}




export const FAQStoreContext = createContext(new FAQStore());
export const useFAQStore = () => useContext(FAQStoreContext);
