import { createContext, useContext } from "react";
import { action, makeAutoObservable, makeObservable, observable, runInAction } from "mobx";
import { postMethodData, getMethodData, putMethod, delMethod, patchMethodData } from "../../ApiMethods/api";
import { toast } from "react-toastify";
const SEO_URL = process.env.REACT_APP_SEO_URL;





export class SectionStore {

    Token = null;
    Section = [];
    savedfun = false;
    Section_status_Loader = false;
    DuplicateProduct = "";
    Productlist = [];
    ProductCatalog = [];
    filterSearch = "";
    PaginationPage = "1";
    TotalPages = "";
    BannerDimiensions = ""
    crop = {};
    errLoadSection = "";
    LoadSection = false;
    delateLoader = false;
    nolist = false;
    Sectionpopup = false;
    deletepopup = false;
    Sectionname = "";
    status = "1";
    statesid = "";
    Sectionid = [];
    seoUrl = "";
    statuspopup = false;
    addSectionname = "";
    imageurl = "";
    logoimage = "";
    bannerimage = "";
    Base64logoImage = "";
    Base64BannerImage = "";
    logofile = [];
    allSection = [];
    alldeactivate = 'false';
    isBannerUpdated = "false";
    isLogoUpdated = "false"
    checkeddd = '';
    SectionFormDetails = {
        "name": "",
        "seo_url": "",
        "logo_image": "",
        "banner_image": "",
        "is_active": ""
    };
    saved = false;

    constructor(isloading = false) {
        makeAutoObservable(this, {
            addSectionname: observable,
            status: observable,
            errorMessages: observable,
            setaddSectionname: action,
            getstatus: action,
            resetErrors: action,
        });

        this.Token = localStorage.getItem("Admintoken");
        if (isloading.isloading) {
            this.getFilterSection();

            //   this.getStateList();
            return
        }
        if (isloading.Productlist) {
            this.getProductList(isloading.id);
            this.getProductCatalogList()
            return
        }
        if (isloading.addloading) {
            //   this.getStateList();
        }
        if (isloading.Sectioneditloading) {
            this.getSectionform(isloading.id);
            return
        }
    }
    //All Clear 
    allclear = () => {
        this.filterSearch = ""
        this.getFilterSection();
        //   this.status="";
        //   this.statesid="";
    }
    setimage = (text, type) => {
        this.imageurl = URL.createObjectURL(text[0]);
    }

    setalldeactivate = (text) => {
        console.log(text);
        this.alldeactivate = !this.alldeactivate;
    };

    getid = (text) => {
        this.Sectionid.push(text);
    };


    // status popup
    setstatuspopup = () => {
        this.statuspopup = !this.statuspopup;
    };


    setallSection = (text) => {
        this.allSection = text;
    }

    // get status 
    getstatus = (text) => {
        this.status = text;
    };

    //get city name
    getSectionname = (text) => {
        this.Sectionname = text;
    };

    setaddSectionname = (text) => {
        this.addSectionname = text;
    };
    setCrop = (text) => {
        this.crop = text;
    };

    setBannerDimiensions = (text) => {
        this.BannerDimiensions = text;
    };

    setlogofile = (e) => {
        this.logofile = e;
        console.log(this.logofile);
    };

    setSectionpopup = (e) => {
        this.Sectionpopup = e;
    };
    setdeletepopup = (e) => {
        this.deletepopup = e;
    };
    setdelateLoader = (e) => {
        this.delateLoader = e;
    }
    getToken = (e) => {
        this.Token = e;
    }
    getseourl = (text) => {
        // console.log(`https://demo.e-kart.com/${text}`);
        this.seoUrl = text;
    };
    getlogoimage = (text) => {
        console.log("checking in the Section", text);
        this.logoimage = text;
    };
    getbanner_image = (text) => {
        console.log(text);
        this.bannerimage = text;
    };
    SetfilterSearch = (text) => {
        this.filterSearch = text;
    };
    setPaginationPage = (text) => {
        this.PaginationPage = text;
    };
    SetTotalPages = (text) => {
        this.TotalPages = text;
    };
    setchecked = (text) => {
        this.checkeddd = text;
    };
    setisBannerUpdated = (text) => {
        this.isBannerUpdated = text;
    };
    setisLogoUpdated = (text) => {
        this.isLogoUpdated = text;
    };
    setDuplicateProduct = (text) => {
        console.log(text);
        this.DuplicateProduct = text;
    };

    isBase64 = (str) => {
        try {
            return btoa(atob(str)) === str;
        } catch (err) {
            return false;
        }
    };
    setSectionFormDetails = (SectionDetail) => {
        this.SectionFormDetails = {
            ...this.SectionFormDetails,
            name: SectionDetail.name || "", // Use default value if name is undefined
            is_active: SectionDetail.is_active
        };
    };
    // setSectionFormDetails = (text) => {
    //     this.SectionFormDetails = text;
    //     console.log("setSectionFormDetails", this.SectionFormDetails.Sectionname);
    // };
    base64toFile(base64Data, fileName, mimeType) {
        if (base64Data) {
            const byteCharacters = atob(base64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                const slice = byteCharacters.slice(offset, offset + 512);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const file = new File(byteArrays, fileName, { type: mimeType });

            return file;
        }

    }

    // get Section list
    getSectionList = () => {
        runInAction(() => {
            this.SectionLoader = true;
            this.errLoadSection = "";
            this.saved = false;
            this.nolist = false;
        });
        getMethodData(`/api/v1/admin/sections/get-list?page=${this.PaginationPage + 1}`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.SectionLoader = false;
                        this.errLoadSection = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.SectionLoader = false;
                        this.errLoadSection = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.Section = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadSection = false;
                    this.errLoadSection = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.SectionLoader = false;
                    if (!error.response) {
                        this.errLoadSection = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadSection = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };
    //Filter city
    // filter League court

    getFilterSection = () => {
        runInAction(() => {
            this.SectionLoader = true;
            this.LoadSection = true;
            this.errLoadSection = "";
            this.saved = false;
            this.nolist = false;
        });
        postMethodData(`/api/v1/admin/sections/search?page=${this.PaginationPage + 1}`, {
            keyword: this.filterSearch
        }, this.Token)
            .then((resp) => {

                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.loadleague = false;
                        this.SectionLoader = false;

                        this.errloadleague = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {

                    runInAction(() => {
                        this.loadcity = false;
                        this.SectionLoader = false;
                        this.errloadleague = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.Section = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.loadcity = false;
                    this.errloadcuty = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.loadleague = false;
                    this.SectionLoader = false;
                    if (!error.response) {
                        this.errloadleague = error?.message;
                        toast.error(error?.message);
                    }
                    this.errloadleague = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    //Product list 
    // get Section list
    getProductList = (id) => {
        runInAction(() => {
            this.SectionLoader = true;
            this.errLoadSection = "";
            this.saved = false;
            this.nolist = false;
        });
        postMethodData(`/api/v1/admin/sections/get-list-mapping`,

            {
                "section_id": id
            }
            , this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.SectionLoader = false;
                        this.errLoadSection = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    console.log(resp?.data?.data.length);
                    runInAction(() => {
                        this.SectionLoader = false;
                        this.errLoadSection = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                            console.log("nolist === true");
                        } else {
                            console.log("nolist === false");
                            this.nolist = false;
                        }
                        this.Productlist = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadSection = false;
                    this.errLoadSection = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.SectionLoader = false;
                    if (!error.response) {
                        this.errLoadSection = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadSection = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    getProductCatalogList = () => {
        runInAction(() => {
            this.ProductCatalogLoader = true;
            this.errLoadProductCatalog = "";
            this.saved = false;
            this.ProductCatalog = [];
        });
        getMethodData(`/api/v1/admin/products/auto-complete`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductCatalogLoader = false;
                        this.errLoadProductCatalog = resp?.data?.message
                    });
                    return;
                }

                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ProductCatalogLoader = false;
                        this.errLoadProductCatalog = resp?.data?.message;

                        this.ProductCatalog = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadProductCatalog = false;
                    this.errLoadProductCatalog = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.ProductCatalogLoader = false;
                    if (!error.response) {
                        this.errLoadProductCatalog = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadProductCatalog = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    // add new Section
    addSection = () => {
        runInAction(() => {
            this.addloadSection = true;
            this.errLoadSection = "";
        });
        const formData = new FormData();

        formData.append('section_name', this.addSectionname);
        formData.append('status', this.status);



        postMethodData("/api/v1/admin/sections/add", formData, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.addloadSection = false;
                    this.errLoadSection = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.addloadSection = false;
                    this.errLoadSection = resp?.data?.message;
                    // toast.success(resp.data.message);
                    this.getFilterSection();
                    this.saved = true;
                })
                return
            }
            runInAction(() => {
                this.addloadSection = false;
                this.errLoadSection = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadSection = false;
                    if (!error.response) {
                        this.errLoadSection = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadSection = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    //Active and inactive
    statusChange = () => {
        runInAction(() => {
            this.Section_status_Loader = true;
            this.Sectionpopup = true;

            this.errLoadSection = "";
        });
        postMethodData(`/api/v1/admin/sections/update-status`, {

            "record_id": this.Sectionid,
            "status": JSON.stringify(this.status)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.Sectionid = [];
                        this.Section_status_Loader = false;
                        this.Sectionpopup = false;
                        this.errLoadSection = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.Section_status_Loader = false;
                        this.Sectionpopup = false;
                        this.errLoadSection = resp?.data?.message;
                        this.getFilterSection();
                        this.Sectionid = [];
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.Section_status_Loader = false;
                    this.Sectionpopup = false;

                    this.errLoadSection = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.Section_status_Loader = false;
                        this.Sectionpopup = false;
                        this.Sectionid = [];
                        this.errLoadSection = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.Section_status_Loader = false;
                    this.Sectionpopup = false;
                    this.Sectionid = [];


                    this.errLoadSection = error?.response?.data?.message;
                })
                return;
            })
    };
    // Bulk Active and inactive
    // BulkstatusChange = (allSection) => {
    //     runInAction(() => {
    //         this.SectionLoader = true;
    //         this.Sectionpopup = true;

    //         this.errLoadSection = "";
    //     });

    //     postMethodData(`/api/v1/admin/Section/toggle-bulk-status`, {

    //         "branchId": JSON.stringify(allSection),
    //         "status": JSON.stringify(this.alldeactivate ? 1 : 0)

    //     }, this.Token)
    //         .then((resp) => {
    //             if (resp?.data?.status === false) {
    //                 runInAction(() => {
    //                     this.SectionLoader = false;
    //                     this.Sectionpopup = false;
    //                     this.errLoadSection = resp?.data?.message;
    //                     toast.error(resp?.data?.message);
    //                 });
    //                 return;
    //             }
    //             if (resp?.data?.status === true) {
    //                 runInAction(() => {
    //                     this.SectionLoader = false;
    //                     this.Sectionpopup = false;

    //                     this.errLoadSection = resp?.data?.message;
    //                     this.getFilterSection();
    //                     toast.success(resp?.data?.message);
    //                 })
    //                 return;
    //             }
    //             runInAction(() => {
    //                 this.SectionLoader = false;
    //                 this.Sectionpopup = false;

    //                 this.errLoadSection = resp?.data?.message;
    //             })
    //         })
    //         .catch((error) => {
    //             runInAction(() => {
    //                 if (!error.response) {
    //                     this.SectionLoader = false;
    //                     this.Sectionpopup = false;

    //                     this.errLoadSection = error?.message;
    //                     toast.error(error?.message);
    //                 }
    //                 toast.error(error?.response?.data?.message);
    //                 this.SectionLoader = false;
    //                 this.Sectionpopup = false;

    //                 this.errLoadSection = error?.response?.data?.message;
    //             })
    //             return;
    //         })
    // };

    //Delete method
    deletefunction = () => {
        runInAction(() => {
            this.setdelateLoader(true);
            this.deletepopup = true;
            this.errLoadSection = "";
        });
        postMethodData(`/api/v1/admin/sections/delete`, {

            "record_id": this.Sectionid,

        }, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.LoadSection = false;
                    this.setdelateLoader(true);
                    this.deletepopup = true;
                    this.errLoadSection = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.LoadSection = false;
                    this.setdelateLoader(false);
                    this.deletepopup = false;
                    this.errLoadSection = resp?.data?.message;
                    this.getFilterSection();
                    this.Sectionid = [];

                    // toast.success(resp.data.message);
                })
                return;
            }
            runInAction(() => {
                this.LoadSection = false;
                this.errLoadSection = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadcity = false;
                    if (!error.response) {
                        this.LoadSection = false;
                        this.setdelateLoader(false);
                        this.deletepopup = false;
                        this.errLoadSection = error?.message;
                        toast.error(error?.message);
                    }
                    this.setdelateLoader(false);
                    this.Sectionid = [];

                    this.LoadSection = false;
                    this.errLoadSection = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            })
    }
    // // Bulk Delete 
    // BulkDelete = (allSection) => {
    //     runInAction(() => {
    //         this.SectionLoader = true;
    //         this.Sectionpopup = true;

    //         this.errLoadSection = "";
    //     });

    //     postMethodData(`/api/v1/admin/Section/multi-delete`, {

    //         "SectionId": JSON.stringify(allSection),
    //         // "status": JSON.stringify(this.alldeactivate ? 1 : 0)

    //     }, this.Token)
    //         .then((resp) => {
    //             if (resp?.data?.status === false) {
    //                 runInAction(() => {
    //                     this.SectionLoader = false;
    //                     this.Sectionpopup = false;
    //                     this.errLoadSection = resp?.data?.message;
    //                     toast.error(resp?.data?.message);
    //                 });
    //                 return;
    //             }
    //             if (resp?.data?.status === true) {
    //                 runInAction(() => {
    //                     this.SectionLoader = false;
    //                     this.Sectionpopup = false;

    //                     this.errLoadSection = resp?.data?.message;
    //                     this.getFilterSection();
    //                     toast.success(resp?.data?.message);
    //                 })
    //                 return;
    //             }
    //             runInAction(() => {
    //                 this.SectionLoader = false;
    //                 this.Sectionpopup = false;

    //                 this.errLoadSection = resp?.data?.message;
    //             })
    //         })
    //         .catch((error) => {
    //             runInAction(() => {
    //                 if (!error.response) {
    //                     this.SectionLoader = false;
    //                     this.Sectionpopup = false;

    //                     this.errLoadSection = error?.message;
    //                     toast.error(error?.message);
    //                 }
    //                 toast.error(error?.response?.data?.message);
    //                 this.SectionLoader = false;
    //                 this.Sectionpopup = false;

    //                 this.errLoadSection = error?.response?.data?.message;
    //             })
    //             return;
    //         })
    // };


    //get Section by id
    getSectionform = (id) => {
        runInAction(() => {
            this.SectionLoader = true;
            this.Sectionpopup = true;

            this.errLoadSection = "";
        });
        postMethodData(`/api/v1/admin/sections/form`, {

            "record_id": id,
            // "status": JSON.stringify(this.status)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.SectionLoader = false;
                        this.Sectionpopup = false;
                        this.errLoadSection = resp?.data?.message;
                        // toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.setSectionFormDetails(resp.data.data.sectionDetail);
                        this.SectionLoader = false;
                        this.Sectionpopup = false;
                        this.errLoadSection = resp?.data?.message;
                    })
                    return;
                }
                runInAction(() => {
                    this.SectionLoader = false;
                    this.Sectionpopup = false;

                    this.errLoadSection = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.SectionLoader = false;
                        this.Sectionpopup = false;

                        this.errLoadSection = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.SectionLoader = false;
                    this.Sectionpopup = false;

                    this.errLoadSection = error?.response?.data?.message;
                })
                return;
            })
    };



    //update method
    updateSection = (id) => {
        runInAction(() => {
            this.load_Edit_Section = true;
            this.errLoadSection = "";
        });
        const formData = new FormData();


        formData.append('record_id', id);
        formData.append('section_name', this.SectionFormDetails.name);
        formData.append('is_active', this.SectionFormDetails.is_active);
        // formData.append('isBannerUpdated', false  );
        // formData.append('isLogoUpdated', true  );


        putMethod(`/api/v1/admin/sections/edit`,
            formData,
            this.Token
        )
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.addloadcity = false;
                        this.load_Edit_Section = false;

                        this.erraddcity = resp?.data?.message;
                        toast.error(resp.data.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.addloadcity = false;
                        this.load_Edit_Section = false;

                        this.erraddcity = resp?.data?.message;
                        toast.success(resp.data.message);
                        // this.getCity();
                        this.getSectionList();
                        this.saved = true;
                    })
                    return
                }
                runInAction(() => {
                    this.addloadcity = false;
                    this.erraddcity = resp?.data?.message;
                    toast.error(resp.data.message);
                });
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.addloadcity = false;
                        this.load_Edit_Section = false;

                        this.erraddcity = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.addloadcity = false;
                    this.erraddcity = error?.response?.data?.message;
                })
                return;
            })
    };


    // add new Section
    addProductSection = (section_id, product_id) => {
        return new Promise((resolve, reject) => {
            runInAction(() => {
                this.SectionLoader = true;
                this.savedfun = false;
                this.Productlist = [];
                this.errLoadSection = "";
            });

            const formData = new FormData();

            formData.append('section_id', section_id);
            formData.append('prod_id', product_id);

            postMethodData("/api/v1/admin/sections/add-section-product", formData, this.Token)
                .then((resp) => {
                    this.savedfun = true;

                    if (resp?.data?.status === false) {
                        runInAction(() => {
                            this.SectionLoader = false;
                            this.Productlist = [];
                            this.errLoadSection = resp?.data?.message;
                            toast.error(resp.data.message);
                            reject(new Error(resp?.data?.message));
                        });
                    } else if (resp?.data?.status === true) {
                        runInAction(() => {
                            this.SectionLoader = false;
                            this.errLoadSection = resp?.data?.message;
                            toast.success(resp.data.message);
                            this.getProductList(section_id);
                            this.setDuplicateProduct(true);
                            this.savedfun = true;
                            resolve();
                        });
                    } else {
                        runInAction(() => {
                            this.SectionLoader = false;
                            this.errLoadSection = resp?.data?.message;
                            reject(new Error(resp?.data?.message));
                        });
                    }
                })
                .catch((error) => {
                    runInAction(() => {
                        this.SectionLoader = false;
                        this.getProductList(section_id);
                        this.savedfun = true;

                        if (!error.response) {
                            this.errLoadSection = error?.message;
                            toast.error(error?.message);
                            this.setDuplicateProduct(false);
                            reject(error);
                        } else {
                            this.errLoadSection = error?.response?.data?.message;
                            this.setDuplicateProduct(false);
                            toast.error(error?.response?.data?.message);
                            reject(new Error(error?.response?.data?.message));
                        }
                    });
                });
        });
    };

    deleteProductSection = (recordid, Sectionid) => {
        runInAction(() => {
            this.setdelateLoader(true);
            this.SectionLoader = true;
            this.Productlist = [];

            this.errLoadSection = "";
        });
        postMethodData(`/api/v1/admin/sections/delete-section-product`, {

            "record_id": JSON.parse(recordid),

        }, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.LoadSection = false;
                    this.setdelateLoader(true);
                    this.SectionLoader = true;
                    this.Productlist = [];
                    this.errLoadSection = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.LoadSection = false;
                    this.setdelateLoader(false);
                    this.SectionLoader = false;
                    this.errLoadSection = resp?.data?.message;
                    this.getProductList(Sectionid);

                    this.Sectionid = [];

                    // toast.success(resp.data.message);
                })
                return;
            }
            runInAction(() => {
                this.LoadSection = false;
                this.errLoadSection = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadcity = false;
                    if (!error.response) {
                        this.LoadSection = false;
                        this.SectionLoader = false;
                        this.setdelateLoader(false);
                        this.deletepopup = false;
                        this.errLoadSection = error?.message;
                        toast.error(error?.message);
                    }
                    this.LoadSection = false;
                    this.errLoadSection = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            })
    }
}




export const SectionStoreContext = createContext(new SectionStore());
export const useSectionStore = () => useContext(SectionStoreContext);
