import {createContext,useContext} from 'react'
import { makeAutoObservable, runInAction } from "mobx";
import {getMethodData,putMethod, patchMethodData} from "../../ApiMethods/api"
import { toast } from "react-toastify";

export class TransactionStore {

    Token = null;

    transaction = [];
    errloadtransaction = "";
    loadtransaction = false;
    nolist = false;
    

    addloadtransaction = false;
    // erradduser = false;

    homecourtdrop = [];
    citydrop=[];
    updateuser = {};
    username = "";
    leaguename = "";
    paymentstatus="";
    newemail = "";
    newlevel = "";
    status = "" ;
    newmobile = "";
    homeCourt = {};
    homeCourtid = "";
    newcity = {};
    newcityname = ""
    newgender = "";
    dob = ""
    Statename=[]
    leaguedropdown = []
    leveldropdownid = ""
    level = {}
    userid = ""
    Subscription=""

    image = ""

    saved = false

    usermobile= ""
 
    startdate = "";
    enddate = "";

    tempfirstName = "";
    templastName = "";

    constructor(isloading = false) {
        makeAutoObservable(this);
        
        this.Token = localStorage.getItem("Admintoken");
        // if(isloading.slectcity){
        //   // this.gethomecourtdropdown()
        //   this.getleaguedropdown()
        
        // }
        if(isloading.isloading){
          this.getTransaction();
          this.getLeaguedropdown()
          return
        }
        
        // if(isloading.editloading){
        //   this.getuserone(isloading.id);
        //   this.getcitydropdown()
        //   //  this.gethomecourtdropdown();
        //    this.getleaguedropdown()
        //   return
        // }
      
      }

       // get User name
  getUsername = (text) => {
    this.username = text;
  }

  //get League name
  getLeaguename = (text) => {
    this.leaguename = text;
  }
  //get start date
  getstartdate = (text) => {
    this.startdate = text;
  };
  //get end date
  getenddate = (text) => {
    this.enddate = text;
  };

  // first name last name
  getfirstname = (text) => {
    this.fName = text;
    if (text?.split(" ")?.length == 1) {
      this.tempfirstName = text;
      this.templastName = text;
    } else {
      this.tempfirstName = text?.substring(0, text.indexOf(" "))?.trim();
      this.templastName = text?.substring(text.indexOf(" ") + 1)?.trim();
    }
  };

   //get paymentstatus 
   getPaymentstatus = (text) => {
    this.paymentstatus = text;
  }

   //get updated city
   updatedcityname = (text) => {
    this.newcityname = text;
  };

  //get city name id
  updatedcitynameid = (text) => {
    this.newcitynameid = text;
  };
  getstate=(text)=>{
    this.statesid=text;
  }

  //get new email
  getNewemail= (text) => {
    this.newemail = text;
  }


 //get new status 
 getNewstatus= (text) => {
  this.status = text;
}

//get new mobile 
getNewmobile= (text) => {
  this.newmobile = text;

}

getusernumber = (text) => {
  this.usermobile = (text || "").toString()

}

//get new homeCourt
getNewhomecourt= (text) => {
  this.homeCourt = text;
}

//get homecourt id
getNewhomecourtid= (text) => {
  this.homeCourtid = text;
}

//get level dropdown
getNewlevel= (text) => {
  this.leveldropdownid = text;
}



//get new newcity
getNewcity= (text) => {
  this.newcity = text;
}

getNewcityname= (text) => {
  this.newcityname = text;
}
//get new newgender
getNewgender= (text) => {
  this.newgender = text;
}

//get new dob
getNewdob= (text) => {
  this.dob = text;
}

 // get city id
 getstatusid = (text) => {
  this.userid = text;
}
 // get subscription
 getSuscriptionstatus = (text) => {
  this.Subscription = text;
}
getToken=(e)=>{
  this.Token=e;
  }


    getchangeimage = (e )=>{
this.image = URL.createObjectURL(e.target.files[0])
    }

    //All clear
allclear=()=>{
  this.username="";
  this.leaguename="";
  this.paymentstatus="";
  this.startdate="";
  this.enddate="";
  this.tempfirstName="";
  this.templastName="";
  this.Subscription="";
  this.getfirstname("");
  }

    // get Transaction List
    getTransaction = () => {
        runInAction(() => {
            this.loadtransaction = true;
            this.nolist= false;
            this.errloadtransaction = "";
            this.saved = false; 
            this.transaction = [];
          });
        getMethodData("/api/admin/transacton",this.Token)
        .then((resp) => {
            if (resp?.data?.status === false) {
              runInAction(() => {
                this.loadtransaction = false;
                this.errloadtransaction = resp?.data?.message
              });
              return;
            }
            if (resp?.data?.status === true) {
              runInAction(() => {
                this.loadtransaction = false;
                this.errloadtransaction = resp?.data?.message;
                if(resp?.data?.data.length === 0) {
                  this.nolist = true;
                }else{
                  this.nolist = false;
                }
                this.transaction = resp?.data?.data;
                this.saved = false;
                // this.status = true;
              });
              return;
            }
            runInAction(() => {
              this.loadtransaction = false;
              this.errloadtransaction = resp?.data?.message;
            });
          })
          .catch((error) => {
            runInAction(() => {
                this.loadtransaction = false;
                if(!error.response){
                    this.errloadtransaction = error?.message;
                    toast.error(error?.message);
                }
                this.errloadtransaction = error?.response?.data?.message;
                toast.error(error?.response?.data?.message);
            })
            return;
        });
    };


    //filter Transaction api 

    getFiltertransaction = () => {
      runInAction(() => {
          this.loadtransaction = true;
          this.nolist= false;
          this.errloadtransaction = "";
          this.saved = false;
          this.transaction = [];
        });
      getMethodData(`/api/admin/transacton?filters[first_name]=${this.tempfirstName}&filters[last_name]=${this.templastName}&filters[league]=${this.leaguename}&filters[payment_status]=${this.paymentstatus}&filters[from_date]=${this.startdate}&filters[to_date]=${this.enddate}&filters[paid_status]=${this.Subscription}`,this.Token)
      .then((resp) => {

          if (resp?.data?.status === false) {
            runInAction(() => {
              this.loadtransaction = false;
              this.errloadtransaction = resp?.data?.message
            });
            return;
          }
          if (resp?.data?.status === true) {
            runInAction(() => {
              this.loadtransaction = false;
              this.errloadtransaction = resp?.data?.message;
              if(resp?.data?.data.length === 0) {
                this.nolist = true;
              }else{
                this.nolist = false;
              }
              this.transaction = resp?.data?.data;
              this.saved = false;
              // this.status = true;
            });
            return;
          }
          runInAction(() => {
            this.loadtransaction = false;
            this.errloadtransaction = resp?.data?.message;
          });
        })
        .catch((error) => {
          runInAction(() => {
              this.loadtransaction = false;
              if(!error.response){
                  this.errloadtransaction = error?.message;
                  toast.error(error?.message);
              }
              this.errloadtransaction = error?.response?.data?.message;
              toast.error(error?.response?.data?.message);
          })
          return;
      });
  };

  //league dropdown
 
  getLeaguedropdown = () => {
    runInAction(() => {
      this.errloadtransaction = "";
      // this.saved = false;
    });
    getMethodData("/api/get/leagues", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.errloadtransaction = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.errloadtransaction = resp?.data?.message;
            // toast.success(resp?.data?.message);
            this.leaguedropdown  = resp?.data?.data;
          });
          return;
        }
        runInAction(() => {
          this.errloadtransaction = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.errloadtransaction = error?.message;
            // toast.error(error?.message);
          }
          this.errloadtransaction = error?.response?.data?.message;
          // toast.error(error?.response?.data?.message);
        });
        return;
      });
  };



};


export const TransactionStoreContext = createContext(new TransactionStore());
export const useTransactionStore = () => useContext(TransactionStoreContext);