import React, { useEffect } from "react";
import {Link,useNavigate} from "react-router-dom";
import { MdOutlineClose,MdOutlineCheck, MdOutlineRefresh, MdOutlineSearch } from "react-icons/md";
import {Input,Button} from "react-daisyui"
import { useUserStore,UserStore,UserStoreContext } from "../store/UserStore";
import { observer } from "mobx-react";
import { HomecourtStore, HomecourtStoreContext, useHomecourtStore } from "../../HomecourtManagement/store/HomecourtStore";

const Filter = observer(() => {
    return(
        <HomecourtStoreContext.Provider value={new HomecourtStore({
            loadeditcitybol:false,
            editloading:false,
            slectcity:true
        })}>
            <Screen/>
        </HomecourtStoreContext.Provider>
    )
})

export default Filter;    

const Screen = observer(() => {
    const {
        saved,
        image,
        getchangeimage,
        fName,
        lName,
        Statename,
        newemail,
        getFirstname,
        newlevel,
        status,
        newmobile,
        newgender,
        homecourtdrop,
        newcity,
        newcityname,
        homeCourt,
        edituser,
        getNewemail,
        getNewlevel,
        getNewmobile,
        getLastname,
        getNewstatus,
        setisDelete,
        getNewhomecourt,
        getNewgender,
        getNewdob,
        leveldropdown,
        dob,
        getNewcity,
        getNewcityname,
        gethomecourtdropdown,
        getFilterUsers,
        getUsers,
        getstate,
        getNewhomecourtid,
        getNewleveldouble,
        getNewlevelmixed,
        getdwnloadbtn
       
      } = useUserStore();
       
      const { citydrop} = useHomecourtStore();

const Reset=()=>{
    getFirstname("")
    getLastname("")
    getNewemail("")
    getNewlevel("")
    getNewleveldouble("")
    getNewlevelmixed("")
    getNewgender("")
    getNewmobile("")
    getNewcityname("")
    getNewhomecourtid("")
    getNewstatus("")
    getstate("")
    getdwnloadbtn(false)
}
    return(
        <>
        
        <div className="card bg-base-100 shadow-md p-6 mt-3 mb-3">
            <form onSubmit={(e) => {getFilterUsers();e.preventDefault()}}>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
                    <div>
                        <label htmlFor="firstname" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">First Name</label>
                        <input 
                        name="firstname" 
                        onChange={(e) => {getFirstname(e.target.value)}}
                        value={fName}
                        placeholder="First Name" 
                        id="firstname" 
                        type="text" 
                        autoComplete="off" 
                        className="input focus:outline-0 w-full text-sm font-medium rounded-md capitalize focus:outline-offset-0 input-bordered"
                        />
                    </div>
                    <div>
                        <label htmlFor="lastname" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Last Name</label>
                        <input 
                        name="lastname" 
                        onChange={(e) => getLastname(e.target.value)}
                        value={lName}
                        placeholder="Last Name" 
                        id="lastname" 
                        type="text" 
                        autoComplete="off" 
                        className="input focus:outline-0 w-full text-sm font-medium rounded-md capitalize focus:outline-offset-0 input-bordered"
                        />
                    </div>
                    <div>
                        <label htmlFor="email" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Email</label>
                        <input 
                        name="email" 
                        onChange={(e) => getNewemail(e.target.value)}
                        value={newemail}
                        placeholder="Email" 
                        id="email" 
                        type="text" 
                        autoComplete="off" 
                        className="input focus:outline-0 w-full text-sm font-medium rounded-md capitalize focus:outline-offset-0 input-bordered"
                        />
                    </div>
                    
                    {/* <div>
                        <label htmlFor="level" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Level</label>
                        <Input 
                        name="level" 
                         onChange={(e) => getNewlevel(e.target.value)}
                        placeholder="Level" 
                        id="level" 
                        type="text" 
                        autoComplete="off" 
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div> */}
                    {/* <div>
                        <label htmlFor="dob" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">DOB</label>
                        <input 
                        name="dob" 
                    
                        //  onChange={(e) => getNewdob(e.target.value)}
                        //  value={dob?.slice(0, 10)}
                         placeholder="DOB" 
                        id="dob" 
                        type="date" 
                        autoComplete="off" 
                        className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
                        />
                    </div> */}
                    <div>
                        <label htmlFor="Gender" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Gender</label>
                        <select
                        id="Gender"
                        onChange={(e) => {getNewgender(e.target.value)}} 
                        defaultValue=""
                        name="Gender"
                        className={`select input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                        <option value=""  className="">All</option>
                        <option value="Male" >Male</option>
                        <option value="Female">Female</option> 
                        </select>
                    </div>
                    {/* <div>
                        <label htmlFor="Mobile" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Mobile</label>
                        <input 
                        name="Mobile" 
                        onChange={(e) => getNewmobile(e.target.value)}
                        placeholder="Mobile" 
                        id="Mobile" 
                        type="number" 
                        autoComplete="off" 
                        className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
                        />
                    </div> */}
                       <div>
                        <label htmlFor="Level" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Single Level</label>
                        {/* <input 
                        name="Level" 
                        required
                        min={0}
                        max={7}
                         onChange={(e) => getNewlevel(e.target.value)}
                         value={newlevel}
                         placeholder="Level" 
                        id="Level" 
                        type="number" 
                        autoComplete="off" 
                        className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
                        /> */}
                        <select
                        id="Level"
                        // value={leveldropdownid}
                        
                        onChange={(e) => {getNewlevel(e.target.value)}} 
                        name="Level"
                        className={`select input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                         <option value="">All</option>
                        {leveldropdown?.map((data,index)=> (
                        <option value={data?._id} key={index}>{data?.name.toFixed(1)} </option>
                        ))}

                        </select>
                    </div>

                    <div>
                        <label htmlFor="Level" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Double Level</label>
                        <select
                        id="Level"
                        // value={leveldropdownid}
                        onChange={(e) => {getNewleveldouble(e.target.value)}} 
                        name="Level"
                        className={`select input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                         <option value="">All</option>
                        {leveldropdown?.map((data,index)=> (
                        <option value={data?._id} key={index}>{data?.name.toFixed(1)} </option>
                        ))}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="Level" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Mixed Level</label>
                        <select
                        id="Level"
                        // value={leveldropdownid}
                        onChange={(e) => {getNewlevelmixed(e.target.value)}} 
                        name="Level"
                        className={`select input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                         <option value="">All</option>
                        {leveldropdown?.map((data,index)=> (
                        <option value={data?._id} key={index}>{data?.name.toFixed(1)} </option>
                        ))}
                        </select>
                    </div>

                   {/* <div>
              <label
                htmlFor="state"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                State
              </label>
              <select
                id="state"
                onChange={(e) => getstate(e.target.value)}
                name="state"
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="">All</option>
                {Statename.map((data,index)=> (
                <option value={data?._id} key={index}>{data?.name}</option>
            ))}
              </select>
            </div> */}
                    <div>
            <label htmlFor="City" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">City</label>
            <select
            id="City"
            defaultValue=""
            // value={city}
            //  onChange={(e) => {getNewcityname(e.target.value);gethomecourtdropdown(e.target.value)}} 
             onChange={(e) => {getNewcityname(e.target.value);}} 
            name="City"
            className={`select input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                <option value=""  className="">All</option>
                {citydrop.map((data,index)=> (
                <option value={data?._id} key={index}>{data?.city_name}</option>
            ))}
            {/* <option value="" disabled className="disabled-value hidden">City</option>
            <option>city1 </option>
            <option>city2</option> */}

            </select>
        </div>
                    
                    <div>
                        <label htmlFor="Homecourt" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Home Court</label>
                        <select
                        id="Homecourt"
                        // value={homeCourt}
                        defaultValue=""
                        onChange={(e) => {getNewhomecourtid(e.target.value)}} 
                        name="Homecourt"
                        className={`select input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                            {/* <option value={homeCourt?._id} selected disabled className="disabled-value hidden">
                                {status ? "Active" : "Inactive"}{homeCourt?.name}
                            </option> */}
                            <option value="" className="">All</option>
                            {homecourtdrop?.map((data,index)=> (
                        <option value={data?._id} key={index}>{data?.displayName} </option>
                        ))}
                        {/* <option >homeCourt </option> */}
                        </select>

                    </div>
                    <div>
                        <label htmlFor="Country" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Country</label>
                        <select 
                        id="Country"
                        name="Country"
                        className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                            <option value="USA">USA</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="Status" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Status</label>
                        <select 
                        id="Status"
                         onChange={(e) => getNewstatus(e.target.value)}
                        name="Status"
                        defaultValue=""
                        className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                            <option value="" className="">All</option>
                            <option value="true">Active</option>
                            <option value="false">Inactive </option>
                            <option  value="isDelete">Deleted</option>
                        </select>
                    </div>
                </div>
                <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                        <button type="reset" onClick={()=>{Reset(); getUsers();}}  className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"><MdOutlineRefresh className="mr-1"/>Reset</button>
                        <button  className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"><MdOutlineSearch className="mr-1"/>Search</button>
                </div>
            </form>
        </div>
                
        </>
    )
})
