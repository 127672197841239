import { createContext, useContext } from "react";
import {makeAutoObservable,observable,runInAction} from "mobx";
import { postMethodData, getMethodData, putMethod , delMethod , patchMethodData} from "../../ApiMethods/api";
import { toast } from "react-toastify";


export class HomecourtStore {

  Token = null;
  reason ="";
  loadreject= false;
  rejectpopup = false;
  suggest="";
  charCheck="";
  HomeCourtValid = false;
  Homecourtpopup= false;
  BulkHomeCourt= false;
  HomecourtApprove = false;
  HomeLoader=false;
  ApproveHomeLoader= false;
  pending= false;
  loadcitylist = false;
  formData = new FormData();
  homecourt = [];
  verify=false
  valueverify=""
  addedby="";
  approvedby="";
  errloadhomecourt = "";
  loadhomecourt = false;
  nolist = false;
  checkcourt= ""
  ImportLoader= false
  bulkcheck= false;
  addloadhomecourt = false;
  erraddhomecourt = "";
  homename = "";
  cityname = "";
  check = false
  name = "";
  zipcode = "";
  country  = "";
  status = "true";
  address ="";
  mylatitude="";
  mylongitude="";
  myaddress="";
  editaddress ="";
  editlatitude="";
  editlongitude="";
  updatedcity = {};
  cityid = "";
  setCsvFile= "";
  citydrop = [];
  bulk= false;
  unsuccessdata = []
  failed="";
  success="";
  updatedzipcode="";
  isVerified=""
  apikeyss=""
  filterstatus=""

  newcityname= {};
  newcitynameid="";
  statuspopup = false;

  courtnickname=""
  updatecourtnickname=""
  filtercourtnickname=""

  saved = false

  constructor(isloading = false) {
    makeAutoObservable(this);
    this.Token = localStorage.getItem("Admintoken");
    this.apikeyss=localStorage.getItem("Mapkey");
    // this.getcitydropdown();
    if(isloading.slectcity){
      this.getcitydropdown();
      this.getApikey();
    }
    if(isloading.isloading){
      this.getHomecourt();
      return
    }
    if(isloading.editloading){
      this.getHomeone(isloading.id);
      return
    }
    
  }
verified=(e)=>{
this.checkcourt=e;
}
setrejectpopup = () => {
  this.rejectpopup = !this.rejectpopup
}
setReason= (e) => {
  this.reason = e
}
setHomeCourtValid=(e)=>{
this.HomeCourtValid=e
}

sethomecourtpopup = (e) => {
  this.Homecourtpopup = e;
};
setBulkhomecourt = (e) => {
  this.BulkHomeCourt= e;
};
sethomecourtApprove = (e) => {
  this.HomecourtApprove = e;
};
setaddedby=(e)=>{
  this.addedby=e;
}
setapprovedby=(e)=>{
  this.approvedby=e;
}
// 
  handleChange = (e) => {

  this.setCsvFile = e.currentTarget.files[0]
};
reset=()=>{
  this.unsuccessdata="";
}
  // status popup
  setstatuspopup = (e) => {
    this.statuspopup = e;
    // this.setCsvFile="";
  };

  setbulkpopup = () => {
    
    this.formData= new FormData();
    this.bulkcheck = true
    this.errPassword = ""
    this.setCsvFile = "";
    // this.currentpassword = ""
    // this.newpassword = ""
    // this.confirmnewpassword = ""
}

setbulkpopupclose = () => {
  this.unsuccessdata = []
  this.formData= new FormData();
  this.bulkcheck = false
  this.errPassword = ""
  this.setCsvFile = "";
  // this.currentpassword = ""
  // this.newpassword = ""
  // this.confirmnewpassword = ""
}
 
  // get city id
  getid = (text) => {
    this.cityid = text;
  }

  // get home name
  getHomename = (text) => {
    const delimiterRegex = /[-,]/;
    const extractedName = text?.split(delimiterRegex)[0];
    this.homename = extractedName;
  }

  // get status 
  getstatus = (text) => {
    this.status = text;
  };

  
  // get filter status 
  getfilterstatus = (text) => {
    this.filterstatus = text;
  };

  //get city name
  getcityname = (text) => {
    this.cityname = text;
  };

 //get name
  getname = (text) => {
    const delimiterRegex = /[-,]/; // Specify multiple delimiters using square brackets
    const extractedName = text?.split(delimiterRegex)[0].trim();
    this.name = extractedName ;
  };

  //get updated city
  updatedcityname = (text) => {
    this.newcityname = text;
  };

  //get city name id
  updatedcitynameid = (text) => {
    this.newcitynameid = text;
  };

    //suggest
    updatedsuggested = (text) => {
      this.suggest = text;
    };
  
  //get zipcode
  getStorezipcode = (text) => {
    this.zipcode = text;
  };
  getzipcode = (text) => {
    this.zipcode = text;
  };
//get adresss
getaddress =(text)=>{
this.address = text;
}
// get latitude
getlatitude =(text)=>{
    this.mylatitude = text;
}

//get longitude
  getlongitude=(text)=>{
  this.mylongitude = text; 
}
getAddress=(text)=>{
  this.myaddress = text; 
}
//get longitude
getpendingcourt=(text)=>{
  this.pending = text; 
}

  // get country name
  getcountryname = (text) => {
    this.country = text;
  };

  //get drop id of city
  getdropid = (text) => {
    this.dropid = text
  }
  set3charCheck = (text) => {
    this.charCheck  = text
  };
  
  //edit address
  editaddressed =(text) =>{
    this.editaddress= text;
  }
  //edit latitutde
  editlatitudeed=(text)=>{
    this.editlatitude=text;
  }
  //edit longitude
  editlongitudeed=(text)=>{
    this.editlongitude = text;
  }

   // get verify 
   getverify = (e) => {
    this.valueverify = e;
  };

  //get Court nickname
  getCourtNickname = (e) => {
    this.courtnickname = e;
  };

  //update Court Nickname
  updatedCourtNickname = (e) => {
    this.updatecourtnickname = e;
  };
   //filter Court Nickname
   getfilterCourtNickname = (e) => {
    this.filtercourtnickname = e;
  };
//get token
  getToken = (text) => {
    this.Token = text;
  };



  // get Homecourt list
  getHomecourt = () => {
    runInAction(() => {
      this.loadhomecourt = true;
      this.nolist=false;
      this.errloadhomecourt = "";
      this.saved = false;
      this.homecourt=[];
    });
    getMethodData("/api/admin/home-court", this.Token)
      .then((resp) => {
       
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadhomecourt = false;
            this.errloadhomecourt = resp?.data?.message
          });
          return;
        }
        if (resp?.data?.status === true) {
          
          runInAction(() => {
            this.loadhomecourt = false;
            this.errloadhomecourt = resp?.data?.message;
            if(resp?.data?.data.length === 0) {
              this.nolist = true;
            }else{
              this.nolist = false;
            }
         
            this.homecourt = resp?.data?.data;
            // this.verify =resp?.data?.data?.isVerified;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadhomecourt = false;
          this.errloadhomecourt = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
            this.loadhomecourt = false;
            if(!error.response){
                this.errloadhomecourt = error?.message;
                toast.error(error?.message);
            }
            this.errloadhomecourt = error?.response?.data?.message;
            toast.error(error?.response?.data?.message);
        })
        return;
    });
  };
  // get Homecourt list
  DelHomecourt = () => {
    runInAction(() => {
      this.ApproveHomeLoader = true;
      this.errloadhomecourt = "";
      this.loadhomecourt = true;
      this.nolist=false;
      this.saved = false;
      this.homecourt=[];
    });
    delMethod(`/api/admin/home-court/${this.cityid}`, this.Token, this.reason)
      .then((resp) => {
       
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.ApproveHomeLoader = true;
            this.errloadhomecourt = resp?.data?.message
          });
          return;
        }
        if (resp?.data?.status === true) {
          
          runInAction(() => {
            this.ApproveHomeLoader = false;
            this.setrejectpopup();
            this.HomecourtApprove = false;
            this.errloadhomecourt = resp?.data?.message;
            if(resp?.data?.data.length === 0) {
              this.nolist = true;
            }else{
              this.nolist = false;
            }
            this.getHomecourt();
           toast.success(resp.data.message);
            // this.verify =resp?.data?.data?.isVerified;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.ApproveHomeLoader = true;
          this.errloadhomecourt = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.ApproveHomeLoader = true;
            if(!error.response){
                this.errloadhomecourt = error?.message;
                toast.error(error?.message);
            }
            this.errloadhomecourt = error?.response?.data?.message;
            toast.error(error?.response?.data?.message);
        })
        return;
    });
  };



  // filter Home court

  getFilterHomecourt = () => {
    runInAction(() => {
      this.loadhomecourt = true;
      this.nolist=false;
      this.errloadhomecourt = "";
      this.saved = false;
      this.homecourt = [];
    });
    getMethodData(`/api/admin/home-court?filters[city]=${this.newcitynameid}&filters[name]=${this.homename}&filters[zipcode]=${this.zipcode}&filters[isActive]=${this.filterstatus}&filters[isVerified]=${this.approvedby}&filters[isAdded]=${this.addedby}`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadhomecourt = false;
            this.errloadhomecourt = resp?.data?.message
          });
          return;
        }
        if (resp?.data?.status === true) {
          
          runInAction(() => {
            this.loadhomecourt = false;
            this.errloadhomecourt = resp?.data?.message;
            if(resp?.data?.data.length === 0) {
              this.nolist = true;
            }else{
              this.nolist = false;
            }
            this.homecourt = resp?.data?.data;
            this.saved = false;
          });
          return;
        }
        runInAction(() => {
          this.loadhomecourt = false;
          this.errloadhomecourt = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
            this.loadhomecourt = false;
            if(!error.response){
                this.errloadhomecourt = error?.message;
                toast.error(error?.message);
            }
            this.errloadhomecourt = error?.response?.data?.message;
            toast.error(error?.response?.data?.message);
        })
        return;
    });
  };
  //filter Approved
  getApprovedHomecourt = () => {
    runInAction(() => {
      this.errloadhomecourt = "";
      this.saved = false;
    });
    getMethodData(`/api/admin/home-court?filters[isVerified]=${this.pending}`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.errloadhomecourt = resp?.data?.message
          });
          return;
        }
        if (resp?.data?.status === true) {
          
          runInAction(() => {
            this.errloadhomecourt = resp?.data?.message;
            if(resp?.data?.data.length === 0) {
              this.nolist = true;
            }else{
              this.nolist = false;
            }
            this.homecourt = resp?.data?.data;
            this.saved = false;
          });
          return;
        }
        runInAction(() => {
          this.errloadhomecourt = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
            if(!error.response){
                this.errloadhomecourt = error?.message;
                toast.error(error?.message);
            }
            this.errloadhomecourt = error?.response?.data?.message;
            toast.error(error?.response?.data?.message);
        })
        return;
    });
  };
// BULK upload
bulkupload = () => {
  this.formData.append('csv_file', this.setCsvFile);
  runInAction(() => {
    this.addloadhomecourt = true;
    this.BulkHomeCourt=true;
    this.ImportLoader= true;
    this.erraddhomecourt = "";
  });
  
  postMethodData("/api/admin/home-court/import-csv",
    this.formData
    ,this.Token).then((resp) => {
      if (resp?.data?.status === false) {
        runInAction(() => {
          this.addloadhomecourt = false;
          this.ImportLoader= true;
          this.BulkHomeCourt=true;
          this.erraddhomecourt = resp?.data?.message;
          toast.error(resp.data.message);
        });
        return;
      }
      if (resp?.data?.status === true) {
        runInAction(() => {
          this.formData= new FormData();
          this.addloadhomecourt = false;
          this.ImportLoader= false;
          this.erraddhomecourt = resp?.data?.message;
          this.unsuccessdata=resp.data.data.unSuccessData;
          if (this.unsuccessdata.length == 0
            ) {
          this.BulkHomeCourt=false; 
          }
          this.success =resp.data.data.success;
          this.failed=resp.data.data.failed;
          toast.success(resp.data.message);
          this.getHomecourt();
          this.saved = true;
          this.setbulkpopup();
    
        })
        return
      }
      runInAction(() => {
        this.addloadhomecourt = false;
        this.erraddhomecourt = resp?.data?.message;
      });
      
    })
    .catch((error) => {
      runInAction(() => {
          this.addloadhomecourt = false;
          this.ImportLoader= false;
          this.BulkHomeCourt=true;
          
          if(!error.response){
              this.erraddhomecourt = error?.message;
              toast.error(error?.message);
          }
          this.erraddhomecourt = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
      })
      return;
  });
};
  // add Home court
  addHomecourt = () => {
    runInAction(() => {
      this.addloadhomecourt = true;
      this.erraddhomecourt = "";
    });
    postMethodData("/api/admin/home-court",{
        city: this.cityname,
        name: this.name,
        displayName:this.courtnickname,
        address: this.address,
        latitude:this.mylatitude,
        longitude: this.mylongitude,
        zipcode: this.zipcode,
        isActive: this.status,
        isVerified: true

      },this.Token).then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => { 
            this.addloadhomecourt = false;
            this.erraddhomecourt = resp?.data?.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.addloadhomecourt = false;
            this.erraddhomecourt = resp?.data?.message;
           toast.success(resp?.data?.message);
            this.getHomecourt();
         
            this.saved = true;
          })
          return
        }
        runInAction(() => {
          this.addloadhomecourt = false;
          this.erraddhomecourt = resp?.data?.message;
        });
        
      })
      .catch((error) => {
        runInAction(() => {
            this.addloadhomecourt = false;
            if(!error.response){
                this.erraddhomecourt = error?.message;
                toast.error(error?.message);
            }
            this.erraddhomecourt = error?.response?.data?.message;
            toast.error(error?.response?.data?.message);
        })
        return;
    });
  };

  //Verified 
  Verifiedstatus = (id) => {
    runInAction(() => {
      this.errloadhomecourt = "";
    });
    patchMethodData(`/api/admin/home-court/verify/${id}`,"",this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadreject = true;
            this.errloadhomecourt = resp?.data?.message;
            toast.error(resp?.data?.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.errloadhomecourt = resp?.data?.message;
            // this.getHomecourt();
            // toast.success(resp?.data?.message);
          })
          return;
        }
        runInAction(() => {
          this.errloadhomecourt = resp?.data?.message;
        })
      })
      .catch((error) => {
        runInAction(() => {
            if(!error.response){
              this.errloadhomecourt = error?.message;
              toast.error(error?.message);
            }
              toast.error(error?.response?.data?.message);
              this.errloadhomecourt = error?.response?.data?.message;
        })
        return;
    })
  };
    //Active and inactive
    statusChange = () => {
      runInAction(() => {
        this.HomeLoader = true;
        this.Homecourtpopup = true;
        this.errloadhomecourt = "";
      });
      patchMethodData(`/api/admin/home-court/status/${this.cityid}`,{ isActive : `${!this.status}` },this.Token)
        .then((resp) => {
          if (resp?.data?.status === false) {
            runInAction(() => {
              this.HomeLoader = true;
              this.Homecourtpopup = true;
              this.errloadhomecourt = resp?.data?.message;
              toast.error(resp?.data?.message);
            });
            return;
          }
          if (resp?.data?.status === true) {
            runInAction(() => {
              this.HomeLoader = false;
              this.Homecourtpopup = false;

              this.errloadhomecourt = resp?.data?.message;
              this.getHomecourt();
              toast.success(resp?.data?.message);
            })
            return;
          }
          runInAction(() => {
            this.HomeLoader = false;
            this.errloadhomecourt = resp?.data?.message;
          })
        })
        .catch((error) => {
          runInAction(() => {
              if(!error.response){
                this.errloadhomecourt = error?.message;
                toast.error(error?.message);
              }
                toast.error(error?.response?.data?.message);
                this.errloadhomecourt = error?.response?.data?.message;
          })
          return;
      })
    };
  
  //get court by id
  getHomeone =(id) => {
    getMethodData( `/api/admin/home-court/${id}`, this.Token)
    .then((resp) => {
        runInAction(() => {
            this.errloadhomecourt = "";
          });
      if (resp?.data?.status === false) {
        runInAction(() => {
          this.errloadhomecourt = resp.data.message;
          toast.error(resp.data.message);
        });
        return;
      }
      if (resp?.data?.status === true) {
        runInAction(() => {
          this.errloadhomecourt = resp?.data?.message;
          this.updatecourtnickname = resp?.data?.data?.displayName
          this.homename = resp?.data?.data?.name;
          this.updatedcity= resp.data.data;
          this.editaddress=resp.data.data.address;
          this.editlatitude=resp.data.data.latitude;
          this.editlongitude =resp.data.data.longitude;
          this.newcityname = resp?.data?.data?.city;
          this.newcitynameid =resp?.data?.data?.city?._id;
          this.updatedsuggested(resp?.data?.data?.suggestedBy)
          this.verify= resp?.data?.data?.isVerified
          this.valueverify= resp?.data?.data?.isVerified
          this.country = resp?.data?.data?.country;
          this.zipcode = resp?.data?.data?.zipcode;
          this.status =resp?.data?.data?.isActive;
        })
        return;
      }
      runInAction(() => {
        this.errloadhomecourt = resp.data.message;
        toast.error(resp.data.message);
      });
    })
    .catch((error) => {
      runInAction(() => {
          if(!error.response){
            this.errloadhomecourt = error?.message;
            toast.error(error?.message);
          }
            toast.error(error?.response?.data?.message);
            this.errloadhomecourt = error?.response?.data?.message;
      })
      return;
  })
}


//update method
updatecity = (id,approve) => {
    runInAction(() => {
      this.addloadhomecourt = true;
      this.erraddhomecourt = "";
      
    });
    putMethod(`/api/admin/home-court/${id}`,
      {
        city: this.newcityname._id,
        name: this.homename,
        displayName:this.updatecourtnickname,
        suggestedBy:this.suggest,
        address: this.editaddress,
        latitude:this.editlatitude,
        longitude: this.editlongitude,
        zipcode: this.zipcode,
        isActive: this.status,
        isVerified: approve,
      },
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.addloadhomecourt = false;
            this.erraddhomecourt = resp?.data?.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
              this.addloadhomecourt = false;
              this.erraddhomecourt = resp?.data?.message;
              toast.success(resp?.data?.message);
              this.getHomecourt();
              this.saved = true;
            })
            return
        }
        runInAction(() => {
          this.addloadhomecourt = false;
          this.erraddhomecourt = resp?.data?.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
            if(!error.response){
              this.addloadhomecourt = false;
              this.erraddhomecourt = error?.message;
              toast.error(error?.message);
            }
              toast.error(error?.response?.data?.message);
              this.addloadhomecourt = false;
              this.erraddhomecourt = error?.response?.data?.message;
        })
        return;
    })
  };


  // city drop down
  getcitydropdown =() =>{
     runInAction(() => {
      this.errloadhomecourt = "";
      // this.saved = false;
     });
    getMethodData("/api/get/cities", this.Token)
    .then((resp) => {
      if (resp?.data?.status === false) {
        runInAction(() => {
          this.errloadhomecourt = resp?.data?.message
        });
        return;
      }
      if (resp?.data?.status === true) {
        runInAction(() => {
          this.errloadhomecourt = resp?.data?.message;
          // toast.success(resp?.data?.message);
          this.citydrop = resp?.data?.data;
         
        });
        return;
      }
      runInAction(() => {
        this.errloadhomecourt = resp?.data?.message;
      });
    })
    .catch((error) => {
      runInAction(() => {
          if(!error.response){
              this.errloadhomecourt = error?.message;
              // toast.error(error?.message);
          }
          this.errloadhomecourt = error?.response?.data?.message;
          // toast.error(error?.response?.data?.message);
      })
      return;
  });
  }

  //api key
  getApikey = async() => {
  
    await  getMethodData("/api/get/admin/setting/GOOGLE_MAP_API_KEY",this.Token)
      .then((resp) => { 
        if (resp?.data?.status === true) {
          runInAction(() => {
       
            // this.status = true;
            localStorage.setItem("Mapkey", resp?.data?.data?.value)
          });
          return;
        }
      
      })
      .catch((error) => {
    
        return;
      });
  };


}




export const HomecourtStoreContext = createContext(new HomecourtStore());
export const useHomecourtStore = () => useContext(HomecourtStoreContext);