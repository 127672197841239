import React, { useState,useRef } from 'react'
import { Input, Button } from "react-daisyui";
import {AiFillEdit} from "react-icons/ai"
import { DivisionStore, DivisionStoreContext, useDivisionStore } from './store/DivisionStore';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { observer } from 'mobx-react';

const TieBreaker = () => {
  const { id,leagueid } = useParams();
  const store = new DivisionStore({
    isloading: true,
    tielist: true,
    id:id,
    leagueid:leagueid
    // Divison: true,
  });
  return (
    <DivisionStoreContext.Provider value={store}>
      <Screen />
    </DivisionStoreContext.Provider>
  );
};
export default TieBreaker;

const Screen = observer(() => {
  const { id,leagueid,leaguename } = useParams();

  const {
    nolisttie,
    Tielist,
    loadTie,
    updatetie,
  } = useDivisionStore();

    const [showinput, setShowinput] = useState(true)
    const [changevalue, setChangevalue] = useState("")
    const [clear, setClear] = useState("")
    const [indexbtn, setindexbtn] = useState(0)
    const [iswinner, setiswinner] = useState(false);
    const [update,setUpdate] = useState({
      division: "",
      league:"",
      level: "",
      score: "",
      player: "",
      id:""
    })
    // const hasMarkwin = Tielist.some(Tielist =>Tielist.winner);
   
    const hasWinner = Tielist.some(Tielist =>Tielist.isWinnerGetted);
    useEffect(() => {
      if(hasWinner){
        setiswinner(true)
      }
      
     
    }, [hasWinner]) 

   
  
    const validateNo = (e) => {
        const inputValue = parseInt(e.target.value);
        if (inputValue > 3 || inputValue ===0) {
          e.target.value = "";
        } else {
          e.target.value = e.target.value.replace(/\D/g, "").slice(0, 1);
        }
      };
  return (
    <div className=" mt-[-19px] flex flex-col  lg:flex-row  px-4 justify-center">
    <div className=" w-full py-11 flex flex-col">
      <p className=" text-center  text-[black] text-xl md:text-xl capitalize">
        Tie Breaker
        <br />
      </p>
      <div
        id=""
        className="  flex-grow flex-shrink-0 border-[1px] border-color_theme   my-5 max-h-[450px] overflow-auto"
      >
        <div id="">
          <table className="table table-zebra w-full" >
            <thead>
              <tr className=" sticky top-0  ">
                <th className="px-3 text-left ">#</th>
                {leaguename.includes("Singles") ?
                <th className="md:py-5 text-left px-3 ">Player Name</th>
                :
                <th className="md:py-5 text-left px-3 ">Team</th>
                 }
                
                <th className="text-left px-3">Win</th>
                <th className="text-left px-3">Loss</th>
                <th className="text-left px-3">Points</th>
                <th>set as winner</th>
                <th className="text-left px-3" >Tie Point</th>
                {!iswinner&& <th className="text-left px-3">Action</th> }  
              </tr>
            </thead>
            <tbody className="text-xs text-[black] lg:text-sm">
              {loadTie && (
                <>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                </>
              )}

              {Tielist.map((data, index) => ( 
              
                <tr key={index} className="text-[black] bg-[#282B2D] text-[13px] ">
                  <td className="px-3 ">{index+1}</td>
                  {leaguename.includes("Singles ")? 
                  <td className=' capitalize'>
                 
                  { data?.player?.user?.firstName} {data?.player?.user?.lastName}
                    </td>

                  :
                  <td className=' capitalize'>
                 
                  {  data?.player?.user?.firstName} {data?.player?.user?.lastName}{(index+1 ? ", " : "")} 
                  {data?.player?.mate?.firstName??""} {data?.player?.mate?.lastName??""}
                    </td>
                   }

                 
                  <td className="px-3">
                  {data?.win}
                  </td>
                   <td className="px-3">
                   {data?.loss}
                  </td>

                  <td
                    className="  px-3 whitespace-nowrap "
                  >
                   {data?.totalPoints}
                  </td>
                 
                  <td className="w-5 text-center ">

                    {
                      (data?.isWinnerGetted == false) 
                        ? (
                            <input name='winner' type="radio"
                              value={index+1} 
                              onClick={(e)=>{ 
                                  setClear(e);
                                  setChangevalue("");
                                  setShowinput(!showinput);
                                  setindexbtn(index+1);
                                  }
                                } />) 
                        : (data?.isWinnerGetted == true && data?.winner == true ) 
                          ? <span className='font-bold bg-color_theme px-3 py-1 rounded-full'>Winner</span>
                          : "Looser"
                    }
                    
                  </td>
           
                  <td>
                    
                    {
                      (data?.isWinnerGetted == false && !data?.score) 
                      ?
                        (
                          <input 
                            id={index+1}
                            min={1}
                            max={3}
                            className='p-[0.5] border focus:outline-none text-center appearance-none my-input'
                            onInput={(e) => {validateNo(e); }}
                            onChange={(e)=>{
                              setChangevalue(e.target.value);
                              setUpdate({...update , score: e.target.value})
                              }}
                            type='number'
                            disabled={indexbtn==index+1 ? false:true}
                            value={indexbtn==index+1 ? changevalue : ""}
                            
                            />
                        ) 
                        : data.score || 0
                    }
                    
                          </td>
                          {!iswinner&&   
                           <td>
                     {!changevalue == "" && indexbtn===index+1
                       && 
                      <label
                       htmlFor="courtstatus" 
                       className=' bg-color_theme cursor-pointer rounded text-lg'
                       onClick={()=> {
                        setUpdate(
                          {...update , division:data?.division?._id,
                            league:data?.league?._id,
                            level: data?.level?._id,
                            player: data?.player?._id,
                            id: data?._id
                       })
                      
                       }}
                       >
                      <AiFillEdit />
                      </label> }
                      </td> 
                    }   
                     </tr>    

                   ))}

          
                
                {nolisttie && (
                <tr>
                  <td colSpan={8} className="my-10 text-center">
                    <p className=" font-semibold text-lg">No Tie Breaker Found</p>
                  </td>
                </tr>
              )}
             
            </tbody>
          </table>
          {/* popup for submit tie breaker points */}
          <input type="checkbox" id="courtstatus" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="courtstatus"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to submit points ?
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor="courtstatus"
                  className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                  onClick={() => {updatetie(update)}}
                >
                  Yes
                </label>
                <label
                  htmlFor="courtstatus"
                  className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                >
                  No
                </label>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    
  </div>
  )
}
)
