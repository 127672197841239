import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from './Home/Dashboard';
import Profile from './Profile/Profile';
/////Brand/////
import BrandManagement from './BrandMangement/Brand';
import AddBrandManagement from './BrandMangement/AddBrand';
import EditBrand from './BrandMangement/EditBrand';
/////AdminUsers/////
import AdminUsers from './AdminUsers/AdminUsers';
import AddUser from './AdminUsers/AddUsers';
import EditAdminUsers from './AdminUsers/EditAdminUsers';
import AdminPermissions from './AdminUsers/AdminPermissions';
/////Categories/////
import CategoriesManagement from './CategoriesMangement/Categories';
import AddCategories from './CategoriesMangement/AddCategories';
import EditCategories from './CategoriesMangement/EditCategories';
/////ProdcutOptions/////
import ProductOptionsManagement from './ProductOptions/ProductOptions';
import AddPrductMangment from './ProductOptions/AddProductOptions';
import EditPrductMangment from './ProductOptions/EditProductOptions';
import OptionsValues from './ProductOptions/OptionsValues';
import AddPrductValuesMangment from './ProductOptions/AddProductValues';
import EditOptionValues from './ProductOptions/EditOptionValues';
/////ProdcutOptions/////
import ProductCatalogList from './ProductCatalog/ProductCatalogList';
import AddPrductCatalog from './ProductCatalog/AddProductCatalog';
import EditPrductCatalog from './ProductCatalog/EditProductCatalog';
/////Sections/////
import SectionManagement from './Sections/Sections';
import EditSection from './Sections/EditSections';
import AddSection from './Sections/AddSections';
import SectionProduct from './Sections/ViewProduct';
/////Order/////
import OrderManagement from './OrderMangment/Order';
import OrderDetail from './OrderMangment/OrderDetail';
import ProductTagProduct from './ProductTags/ProductTag';
/////Banner/////
import BannerManagement from './BannerMangement/Banner';
import AddBannerManagement from './BannerMangement/AddBanner';
import EditBannerManagement from './BannerMangement/EditBanner';
/////Users/////
import Users from './Users/Users';
/////Seller list/////
import AUserLists from './A-User-list/UserList';
import AddAUser from './A-User-list/AddAUser';
import EditAUsers from './A-User-list/EditAUserList';
/////Coupen/////
import CoupenManagement from './CoupenManagement/CoupenManagement';
/////Reviews/////
import ReviewManagement from './Reviews/reviewlist';
/////Location/////
import LocationManagement from './LocationMangement/LocationList';
import AddLocation from './LocationMangement/AddLocation';
import EditLocation from './LocationMangement/EditLocation';
/////PreOrder/////
import PreOrderSettings from './PreOrders/PreOrderSettings';
import PreOrderManagement from './PreOrders/PreOrderListing';

 /////Website/////
import ContactPage from './Website/ContactPage';
import WebsiteManagement from './Website/OtherPages';
import AddNewPage from './Website/AddNewPage';
import EditNewPage from './Website/EditPages';
 /////Employee/////
import EmployeeManagement from './Employees/employeesList';
import AddEmployee from './Employees/AddEmployee';
import EditEmployee from './Employees/EditEmployee';
import EmployeeDetail from './Employees/ViewDetail';
 /////Reward/////
import RewardManagement from './Reward/RewardMangement';
 /////FAQ/////
 import FAQProduct from './FAQMangement/FAQlist';
 /////Query/////
import QueryManagement from './Quertlist/QueryList';
/////BulkOrder/////
import BulkOrderManagement from './BulkOrderList/BulkOrderList';


import UserManagement from './UserManagement/UserManagement';
import DeletedUser from './UserManagement/DeletedUser';
import TieBreakerManagement from './TieBreakerMangement/tiebreakermangement';
import Setteled from './TieBreakerMangement/Setteled';
import AddUserManagement from './UserManagement/AddUserManagement';
import CityManagement from './CityMangement/CityManagement';
import AddCityManagement from './CityMangement/AddCityManagement';
import EditCityManagement from './CityMangement/EditCityManagement';
import PlayerMangement from './PlayerManagement/PlayerMangement';
import AddPlayerManagement from './PlayerManagement/AddPlayerManagement';
import DivisionManagement from './DivisionManagement/DivisionManagement';
import AddDivisionMAnagement from './DivisionManagement/AddDivisionMAnagement';
import SeasonManagement from './SeasonsManagement/SeasonManagement';
import EditSeason from './SeasonsManagement/EditSeason';
import LeagueManagement from './LeagueManagement/LeagueManagement';
import CurrentLeague from './LeagueManagement/CurrentLeague';
import HomecourtMangement from './HomecourtManagement/HomecourtMangement';
import Setting from './Setting/Setting';
import PlayerList from './DivisionManagement/PlayerList';
import MatchList from './DivisionManagement/MatchList';
import MatchManagement from './MatchMangement/MatchMangement';
import AddSeasonsManagement from './SeasonsManagement/AddSeasonsManagement';
// import AddLeagueManagement from './LeagueManagement/AddLeagueManagement';
import EditLeagueManagement from './LeagueManagement/EditLeagueManagement';
import PendingHomeCourt from './HomecourtManagement/PendingHomeCourts';
import AddHomeCourtManagement from './HomecourtManagement/AddHomeCourtManagement';
import AddLevelManagement from './LevelMangement/LevelFilter/AddLevelManagement';
import EditHomecourtManagement from './HomecourtManagement/EditHomecourtManagement';
import EditUserManagement from './UserManagement/EditUserManagement';
import LevelManagement from './LevelMangement/LevelFilter/LevelManagement';
import EditLevelManagement from './LevelMangement/LevelFilter/EditLevelManagement';
// import EditSeasonManagement from './SeasonsManagement/EditSeasonManagement';
import StateManagement from './StateManagement/StateManagement';
import { PageNotFound } from './PageNotFound/PageNoteFound';
import EditStateManagement from './StateManagement/EditStateManagement';
import AddStateManagement from './StateManagement/AddStateManagement';
import AddSetting from './Setting/AddSetting/AddSettings';
import AddCoupenManagement from './CoupenManagement/AddCoupenManagement/AddCoupenMangament';
import EditCoupenManagement from './CoupenManagement/EditCoupenManagement/EditCoupenManagement';
import CmsManagement from './CmsMangement/CmsManagement';
import EditCmsManagement from './CmsMangement/CmsEdit/CmsEdit';
import TransactionManagement from './TransactionManagement/TransactionManagement';
import NotificationList from './Notifications/NotificationsList';
import Playerstree from './Playertree/Playerstree';
import PlayerDetail from './PlayerManagement/PlayerDetail';
import MatchhHistory from './PlayerManagement/MatchhHistory';
import Playoffmatches from './LeagueManagement/Playoffmatches';
// import PlayerReason from './PlayerReason/PlayerReason';
import PlayerReason from './PlayerReason/PlayerReason';


export const pages= [
  
    {path:"/", component: <Dashboard/> },
    {path:"/profile", component: <Profile/> },
    {path:"/categories", component: <CategoriesManagement/> },
    {path:"/bulkorder", component: <BulkOrderManagement/> },

    {path:"/categories/addcategories", component: <AddCategories/> },
    {path:"/categories/:id", component: <EditCategories/> },

    {path:"/productoptions", component: <ProductOptionsManagement/> },
    {path:"/productoptions/addProductOptionsmanagement", component: <AddPrductMangment/> },
    {path:"/productoptions/:id", component: <EditPrductMangment/> },
    {path:"/productoptions/optionvalues/:name/:id", component: <OptionsValues/> },
    {path:"/productoptions/optionvalues/addoptionvalues/:id", component: <AddPrductValuesMangment/> },
    {path:"/productoptions/optionvalues/editoptionvalues/:option_id/:id", component: <EditOptionValues/> },

    {path:"/productcatalog", component: <ProductCatalogList/> },
    {path:"/productcatalog/addproductcatalog", component: <AddPrductCatalog/> },
    {path:"/productcatalog/addproductcatalog/product/:name/:id", component: <AddPrductCatalog/> },
    
    {path:"/productcatalog/:id", component: <EditPrductCatalog/> },
    
    {path:"/usermanagement", component: <UserManagement/> },
    {path:"/usermanagement/adduser", component: <AddUserManagement/> },
    {path:"/usermanagement/deleteuser", component: <DeletedUser/> },
    {path:"/tiebreaker", component: <TieBreakerManagement/> }, 
    {path:"/tiebreaker/settled", component: <Setteled/> },
    {path:"/usermanagement/:id", component: <EditUserManagement/> },
    {path:"/citymanagement", component: <CityManagement/> },
    /////Brand/////
    {path:"/brandmanagement", component: <BrandManagement/> },
    {path:"/brandmanagement/addbrandmanagement", component: <AddBrandManagement/> },
    {path:"/brandmanagement/:id", component: <EditBrand/> },
    /////AdminUsers/////
    {path:"/adminusers", component: <AdminUsers/> },
    {path:"/adminusers/adduser", component: <AddUser/> },
    {path:"/adminusers/edituser/:id", component: <EditAdminUsers/> },
    {path:"/adminusers/adminpermissions/:id/:name", component: <AdminPermissions/> },
    /////Sections/////
    {path:"/sections", component: <SectionManagement/> },
    {path:"/sections/:id", component: <EditSection/> },
    {path:"/sections/addsections", component: <AddSection/> },
    {path:"/sections/product/:id/:name", component: <SectionProduct/> },
    /////Order/////
    {path:"/order", component: <OrderManagement/> },
    {path:"/order/product/:id/:amount", component: <OrderDetail/> },
    /////Banner/////
    {path:"/banner", component: <BannerManagement/> },
    {path:"/banner/addbannermanagement", component: <AddBannerManagement/> },
    {path:"/banner/:id", component: <EditBannerManagement/> },

    /////ProductTag/////
    {path:"/tag", component: <ProductTagProduct/> },
    /////Users/////
    {path:"/users", component: <Users/> },
    /////Seller List/////
    {path:"/customerlist", component: <AUserLists/> },
    {path:"/approvedusers/addapprovedusers", component: <AddAUser/> },
    {path:"/approvedusers/:id", component: <EditAUsers/> },
    /////Coupen/////
    {path:"/coupenmanagement", component: <CoupenManagement/> },
    {path:"/coupenmanagement/addcoupen", component: <AddCoupenManagement/> },
    {path:"/coupenmanagement/:id", component: <EditCoupenManagement/> },
    /////Reviews/////
    {path:"/review", component: <ReviewManagement/> },
    /////location/////
    {path:"/location", component: <LocationManagement/> },
    {path:"/location/addlocation", component: <AddLocation/> },
    {path:"/location/:id", component: <EditLocation/> },
    
    /////PreOrder/////
    {path:"/preordersettings", component: <PreOrderSettings/> },
    {path:"/preorder", component: <PreOrderManagement/> },

    /////Website/////
    {path:"/contactpage", component: <ContactPage/> },
    {path:"/otherpages", component: <WebsiteManagement/> },
    {path:"/otherpages/addnewpage", component: <AddNewPage/> },
    {path:"/otherpages/:id", component: <EditNewPage/> },

    /////Employee/////
    {path:"/employee", component: <EmployeeManagement/> },
    {path:"/employee/addemployee", component: <AddEmployee/> },
    {path:"/employee/:id", component: <EditEmployee/> },

    {path:"/adminusers/viewemployee/:id", component: <EmployeeDetail/> },
    /////Reward/////
    {path:"/reward", component: <RewardManagement/> },
    /////FAQ/////
    {path:"/faq", component: <FAQProduct/> },
    /////Query/////
    {path:"/querylist", component: <QueryManagement/> },



    {path:"/citymanagement/addcity", component: <AddCityManagement/> },
    {path:"/citymanagement/:id", component: <EditCityManagement/> },
    {path:"/playersmanagement", component: <PlayerMangement/> },
    {path:"/playersmanagement/league/:id", component: <PlayerMangement/> },
    {path:"/playersmanagement/match/:id", component: <PlayerMangement/> },
    {path:"/playersmanagement/addplayer", component: <AddPlayerManagement/> },
    {path:"/divisionmanagement/league/:id", component: <DivisionManagement/> },
    {path:"/divisionmanagement", component: <DivisionManagement/> },
    {path:"/divisionmanagement/playerlist/:id", component: <PlayerList/> },
    {path:"/divisionmanagement/league/:id/playerlist/:id", component: <PlayerList/> },
    {path:"/divisionmanagement/league/:id/matchlist/:id/:leagueid/:dcode/:leaguename/:levelid/:levelname", component: <MatchList/> },
    {path:"/divisionmanagement/matchlist/:id/:leagueid/:dcode/:leaguename/:levelid/:levelname", component: <MatchList/> },
    {path:"/divisionmanagement/matchlist/:id/:leagueid/:dcode/:leaguename/:levelid/:levelname", component: <MatchList/> },
    {path:"/matchmanagement", component: <MatchManagement/> },
    {path:"/divisionmanagement/adddivision", component: <AddDivisionMAnagement/> },
    {path:"/seasonmanagement", component: <SeasonManagement/> },
    {path:"/seasonmanagement/:id",component:<EditSeason/>},
    {path:"/levelmanagement/:id",component:<EditLevelManagement/>},
    {path:"/levelmanagement/",component:<LevelManagement/>},
    {path:"/statemanagement/",component:<StateManagement/>},
    {path:"/statemanagement/:id",component:<EditStateManagement/>},
    {path:"/seasonmanagement/addseason", component: <AddSeasonsManagement/> },
    {path:"/statemanagement/addstate", component: <AddStateManagement/> },
    // {path:"/seasonmanagement/editseason", component: <EditSeasonManagement/> },
    {path:"/leaguemanagement", component: <LeagueManagement/> },
    {path:"/leaguemanagement/currentleague", component: <CurrentLeague/> },
    {path:"/leaguemanagement/season/:id", component: <LeagueManagement/> },
    // {path:"/leaguemanagement/addleague", component: <AddLeagueManagement/> },
    {path:"/leaguemanagement/:id", component: <EditLeagueManagement/> },
    {path:"/homecourtmanagement", component: <HomecourtMangement/> },
    {path:"/homecourtmanagement/pendinghomecourts", component: <PendingHomeCourt/> },
    {path:"/homecourtmanagement/add/addhomecourt", component: <AddHomeCourtManagement/> },
    {path:"/levelmanagement/addlevel", component: <AddLevelManagement/> },
    {path:"/homecourtmanagement/edit/:id", component: <EditHomecourtManagement/> },
    {path:"/setting", component: <Setting/> },
    {path:"/setting/addsetting", component: <AddSetting/> },
    {path:"/coupenmanagement", component: <CoupenManagement/> },
    {path:"/coupenmanagement/addcoupen", component: <AddCoupenManagement/> },
    {path:"/coupenmanagement/:id", component: <EditCoupenManagement/> },
    {path:"/cmsmanagement", component: <CmsManagement/> },
    {path:"/cmsmanagement/:id", component: <EditCmsManagement/> },
    {path:"/transactionmanagement", component: <TransactionManagement/> },
    {path:"/notifications", component: <NotificationList/> },
    { path: "/playerstree/:id/:levelid/:leaguename", component: <Playerstree /> },
    { path: "/playerdetail/:id/:name", component: <PlayerDetail /> },
    { path: "/matchhistory/:id/:leagueid", component: <MatchhHistory/> },
    { path: "/playoffmatches/:id/:levelid/:leaguename", component: <Playoffmatches/> },
    { path: "/playerreason", component: <PlayerReason/> },
    { path: "/playerreason/:id", component: <PlayerReason/> },

]

const Routess = () => {
  return (
    <Routes>
      <Route index element={<Dashboard />} />
       {pages.map(({component, path})=>(
        <Route key={path}
            element={component}
            path={path}
        />
       ))}
       <Route
        path="*"
        element={<PageNotFound/>}
      />
    </Routes>
  
  )
}

export default Routess