import React, { useState } from "react";
import { RxPencil1 } from "react-icons/rx";
import { Link, useParams } from "react-router-dom";
import { MdAdd, MdFAQingWatermark, MdLocationCity, MdOutlineDelete, MdOutlineGroup, MdCancel, MdRemoveRedEye, MdExpandMore, MdEdit, MdQuestionMark } from "react-icons/md";
import { observer } from "mobx-react";
import ReactPaginate from 'react-paginate';
import { FAQStore, useFAQStore, FAQStoreContext } from "./store/FAQStore";
import { useAuthStore } from "../Auth/store/Auth";
import { useEffect } from "react";
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import { toast, ToastContainer } from "react-toastify";
import { Button, Input } from "react-daisyui";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";

const FAQProduct = () => {
    const { id } = useParams();

    const store = new FAQStore({
        Productlist: true,
        // id: id
    });
    return (
        <FAQStoreContext.Provider value={store}>
            <Screen />
        </FAQStoreContext.Provider>
    )
}
export default FAQProduct;


const Screen = observer(() => {
    const {
        ProductCatalog,
        nolist,
        EditFAQFormDetails,
        setEditFAQFormDetails,
        ProductCatalogLoader,
        statusChange,
        FAQ_Object,
        Productlist,
        DuplicateProduct,
        savedfun,
        TotalPages,
        FAQAddpopup,
        addloadFAQ,
        FAQEditLoader,
        addFAQ,
        EditFaq,
        getrec_id,
        getprod_id,
        setquestion,
        setanswer,
        deleteProductFAQ,
        setFAQAddpopup,
        getFAQform,
        getProductCatalogList,
        setPaginationPage,
        getFilterFAQ,
        setFAQpopup,
        addProductFAQ,
        FAQpopup,
        deletepopup,
        setdeletepopup,
        setdelateLoader,
        delateLoader,
        getEditFaqForm,
        deletefunction,
        getToken
    } = useFAQStore();

    const { adminAuthApproved, admin_permissions } = useAuthStore();
    const { id, name } = useParams();

    useEffect(() => {
        if (adminAuthApproved) {
            getToken(localStorage.getItem("Admintoken"));
        }

    }, [adminAuthApproved]);

    const [currentPage, setCurrentPage] = useState(0);
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [Edit, setEdit] = useState(false)
    // const [checkeddd, setchecked] = useState(false)

    const [photoIndex, setPhotoIndex] = useState("");
    const [FAQIndex, setFAQIndex] = useState(null);

    const ITEMS_PER_PAGE = 10;
    const handlePageChange = ({ selected }) => {
        setPaginationPage(selected);
        getProductCatalogList();
        setCurrentPage(selected);
    };

    const handleClick = () => {
        const element = document.getElementById("tablescroll");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }
    const startIndex = currentPage * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;

    const [searchQuery, setSearchQuery] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState("");
    const [showList, setShowList] = useState(false);
    const [isListVisible, setListVisibility] = useState(false);
    const [value, setValue] = useState("Select option...");
    const [categoryvalue, setcategoryvalue] = useState("Select option...");
    const [formData, setFormData] = useState({});
    const [qaArray, setQaArray] = useState([]);

    const handleEdit = (index) => {
        setFAQIndex(index);
    };

    const handleSave = (index) => {
        setFAQIndex(null);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleUpdateFaq = (e, rec_id) => {
        // e.preventDefault();
        // Adding new question-answer pair to the array
        // setQaArray(prevArray => [...prevArray, { question: formData.question, answer: formData.answer }]);
        // Clearing the form fields
        // setquestion(formData.question)
        EditFaq(rec_id);
        // setFormData({ question: '', answer: '' });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Adding new question-answer pair to the array
        // setQaArray(prevArray => [...prevArray, { question: formData.question, answer: formData.answer }]);
        // Clearing the form fields
        // setquestion(formData.question)
        addFAQ();
        // setFormData({ question: '', answer: '' });
    };
    const handleFocus = () => {
        // Set the list visibility to true when the input is focused
        setListVisibility(true);
        setShowList(true);

        // Initial filtering to show the entire list
        setFilteredItems(ProductCatalog.map(item => item));
    };

    const handleBlur = () => {
        // Set the list visibility to false when the input loses focus
        setListVisibility(false);
        setShowList(true);

        // setFilteredItems(productList.map(item => item));

    };

 console.log("ProductCatalog",ProductCatalog);

    return (
        <>
          <div className="flex justify-between mt-[51px] ">
                <div className=" mb-4">
                    <p className=" text-2xl font-semibold">FAQ Management </p>
                    <p className=" opacity-80 text-sm">Home / FAQ  </p>
                </div>

            </div>
            {/* <Filter /> */}


            <div className="card bg-base-100 shadow-md p-6 mt-8">
                <div className="overflow-x-auto w-full">
                    <table id="tablescroll" className="table table-zebra w-full">
                        <thead>
                            <tr>
                                {/* <th></th> */}
                                <th>Product</th>
                                <th></th>
                                <th></th>
                                <th>Action</th>
                            </tr>
                        </thead>

                        <tbody className="text-sm relative">
                            {ProductCatalogLoader &&
                                <>
                                    <tr>
                                        <td colSpan={5} className="animate-pulse py-6"></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} className="animate-pulse py-6"></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} className="animate-pulse py-6"></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} className="animate-pulse py-6"></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} className="animate-pulse py-6"></td>
                                    </tr>
                                </>
                            }
                            {
                                ProductCatalog?.map((data, index) => (
                                    <tr key={index} className="capitalize">
                                        {/* <td>

                                            <input
                                                value={data?.id}
                                                onChange={(e) => { setchecked(false); isallcheck(e.target.value); }}
                                                type="checkbox"
                                                name="myCheckbox"
                                            />
                                        </td> */}
                                        {/* <td>
                                            {currentPage * ITEMS_PER_PAGE + data?.id}
                                        </td> */}

                                        <td>
                                            {data.product_name}
                                        </td>

                                        <td>

                                        </td>
                                        <td>

                                        </td>

                                        <th>
                                            <div className="flex space-x-3">

                                                <div className="flex items-center space-x-3">

                                                    <button title="View FAQ's">
                                                        {/* <Link to={`./product/${data?.id}/${data?.name}`}> */}
                                                        <label
                                                            title="View FAQ's"
                                                            htmlFor="my-modal-5"
                                                            className={`btn capitalize  text-white border-hidden  btn-xs`}
                                                            onClick={(e) => {
                                                                getFAQform(data?.product_id);
                                                                getprod_id(data?.product_id);
                                                                getrec_id(data?.record_id);
                                                                setFAQpopup(true);
                                                                // statusChange(data?.is_active === 1 ? 0 : 1);
                                                            }}
                                                        >
                                                            <MdRemoveRedEye />

                                                        </label>
                                                    </button>
                                                    {/* {admin_permissions.Product === 1 ? */}
                                                    <button title="Add FAQ's">
                                                        <label
                                                            title="Add FAQ's"
                                                            htmlFor="my-modal-6"
                                                            className={`btn capitalize  text-white border-hidden  btn-xs`}

                                                            onClick={(e) => {
                                                                getprod_id(data?.product_id);
                                                                setFAQAddpopup(true);
                                                                // statusChange(data?.is_active === 1 ? 0 : 1);
                                                            }}
                                                        >
                                                            <MdAdd />
                                                            Add more

                                                        </label>

                                                    </button>
                                                    {/* :
                                                        <button onClick={() => toast.warning("You have no permissions to edit.")} title="Edit">

                                                            <RxPencil1 />

                                                        </button>
                                                    } */}

                                                </div>
                                            </div>

                                        </th>

                                    </tr>
                                ))
                            }

                            {nolist &&
                                <tr>
                                    <td colSpan={5} className="my-10 text-center">
                                        <MdQuestionMark className=" text-[#dedede] text-8xl mx-auto" />
                                        <p className=" font-semibold text-lg">No FAQ's</p>
                                        {/* <Link
                                            to="./addProduct"
                                            className="btn btn-sm mt-4 bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                                        >
                                            <MdAdd />
                                            Add FAQ's
                                        </Link> */}
                                    </td>
                                </tr>}
                        </tbody>
                    </table>
                    {/* {!city?.length == 0 && */}

                    <ReactPaginate
                        initialPage={0}
                        pageCount={Math.ceil(TotalPages)}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageChange}
                        onClick={handleClick}
                        containerClassName={'pagination'}
                        activeClassName={'active'}

                    />
                    {/* } */}

                    {/* status change model start */}
                    {/* <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="my-modal-5"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status ?
              </h3>
              <div className="modal-action justify-center">
                      <label
                        htmlFor="my-modal-5"
                        className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                        onClick={() => statusChange()}
                      >
                        Yes
                      </label> 
                <label htmlFor="my-modal-5" className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]">
                  No
                </label>
              </div>
            </div>
          </div> */}
                    {/* status change model end */}
                    {deletepopup &&
                        <>
                            <input type="checkbox" id="deletecity" className="modal-toggle" />
                            <div className="modal">
                                <div className="modal-box relative">
                                    <label

                                        className="btn btn-sm btn-circle absolute right-2 top-2"
                                        onClick={() => { setdelateLoader(false); setdeletepopup(false) }}
                                    >
                                        ✕
                                    </label>
                                    <h3 className="text-lg font-bold text-center">
                                        Are you sure you want to Delete this Section
                                    </h3>
                                    <div className="modal-action justify-center">
                                        {/* <label
                                            htmlFor="deletecity"
                                            className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                            onClick={() => deletefunction()}
                                        >
                                            Yes
                                        </label> */}
                                        {!delateLoader ?
                                            <label

                                                className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                                onClick={() => deletefunction()}
                                            >
                                                Yes
                                            </label> :
                                            <button
                                                // type="submit"
                                                disabled
                                                className="flex items-center bg-color_theme px-3 mx-5 rounded-md"
                                            // onClick={() => {
                                            //   PostMatchscore();
                                            // }}
                                            >
                                                Yes..
                                                <svg
                                                    className="animate-spin mr-1 h-5 w-5 text-white"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle
                                                        className="opacity-25"
                                                        cx="12"
                                                        cy="12"
                                                        r="10"
                                                        stroke="currentColor"
                                                        strokeWidth="4"
                                                    ></circle>
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    ></path>
                                                </svg>
                                            </button>}
                                        <label
                                            onClick={() => setdeletepopup(false)}
                                            className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </>

                    }
                    {FAQpopup &&
                        <>
                            <input type="checkbox" id="my-modal-5" className="modal-toggle" />
                            <div className="modal">
                                <div className="modal-box space-y-5 relative">
                                    <label

                                        className="btn btn-sm btn-circle  absolute right-2 top-2"
                                        onClick={() => { setFAQpopup(false); setFAQIndex(null); }}
                                    >
                                        x
                                    </label>
                                    <div className="space-y-2">
                                        {FAQ_Object.map((data, index) => (
                                            <div className="flex space-x-5 text-center items-center">
                                                <div className="w-10/12   ">
                                                    {FAQIndex === index ?
                                                        <form>
                                                            <div className="space-y-2">
                                                                <Input
                                                                    name="question"
                                                                    // value={formData.question}

                                                                    value={EditFAQFormDetails.question}
                                                                    onChange={(e) => {
                                                                        setEditFAQFormDetails({
                                                                            ...EditFAQFormDetails,
                                                                            question: e.target.value,
                                                                        });
                                                                    }}
                                                                    required
                                                                    placeholder="Question"
                                                                    id="Question"
                                                                    type="text"
                                                                    autoComplete="off"
                                                                    className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                                                                />
                                                                <Input
                                                                    name="answer"
                                                                    // value={formData.answer}

                                                                    value={EditFAQFormDetails.answer}
                                                                    onChange={(e) => {
                                                                        setEditFAQFormDetails({
                                                                            ...EditFAQFormDetails,
                                                                            answer: e.target.value,
                                                                        });
                                                                    }}
                                                                    required
                                                                    placeholder="Answer"
                                                                    id="Answer"
                                                                    type="text"
                                                                    autoComplete="off"
                                                                    className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                                                                />
                                                                {/* {qaArray.map((data, index) => (
        <div className="flex space-x-2 text-center items-center">
            <div className="w-10/12">
                <Accordion key={index}>
                    <AccordionSummary
                        expandIcon={<MdExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        {data.question}
                    </AccordionSummary>
                    <AccordionDetails className="bg-[#000] text-white">
                        <Typography>
                            {data.answer}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>



    ))} */}
                                                            </div>
                                                            <div className=" flex space-x-1 justify-end mt-3">
                                                                {/* <button
        onClick={handleSubmit}
        className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
            <MdAdd className="mr-1" />
            add
        </button> */}


                                                            </div>

                                                        </form>
                                                        :

                                                        <Accordion key={index}>
                                                            <AccordionSummary
                                                                expandIcon={<MdExpandMore />}
                                                                aria-controls="panel1a-content"
                                                                className="break-all"
                                                                id="panel1a-header"
                                                            >
                                                                {data.question}
                                                            </AccordionSummary>
                                                            <AccordionDetails className="bg-[#000] text-white">
                                                                <Typography className="break-all">
                                                                    {data.answer}
                                                                </Typography>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    }
                                                </div>
                                                <div className="w-2/12 flex justify-center gap-2 text-center items-center ">
                                                    {FAQIndex === index ?
                                                        !FAQEditLoader ?
                                                            <button
                                                                // onClick={()=>setFAQIndex(null)}
                                                                onClick={(e) => { handleUpdateFaq(e, data.record_id); setFAQIndex(null) }}
                                                                className="btn btn-sm bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
                                                                submit
                                                            </button>
                                                            :
                                                            <button
                                                                className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                                                type="button"
                                                            //  onClick={()=> changeStep("4")}
                                                            >
                                                                <svg
                                                                    className="animate-spin mr-1 h-5 w-5 text-white"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                >
                                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                    <path
                                                                        className="opacity-75"
                                                                        fill="currentColor"
                                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                                    ></path>
                                                                </svg>
                                                                Loading..
                                                            </button>


                                                        :
                                                        <>
                                                            <label
                                                                title="Change Status"
                                                                className={`btn capitalize Active" ${data?.is_active === 1 ? "bg-[#5dff5d] hover:bg-[#5dff5d]" : "bg-warning hover:bg-warning"}  text-[#000] border-hidden  btn-xs`}

                                                                onClick={(e) => {
                                                                    // getid(data?.id);
                                                                    statusChange(data?.record_id, data?.is_active === 1 ? 0 : 1, data?.product_id);
                                                                }}
                                                            >
                                                                {data?.is_active === 1 ? "Active" : "Inactive"}
                                                            </label>

                                                            <label
                                                                htmlFor="deletecity"
                                                                className=" text-[#d02c2c] cursor-pointer"
                                                                title="Delete"
                                                                onClick={() => { deletefunction(data?.record_id, data?.product_id); }}
                                                            >
                                                                <MdOutlineDelete />
                                                            </label>
                                                            <label
                                                                htmlFor="edit"
                                                                className=" text-[#d02c2c] cursor-pointer"
                                                                title="Edit"
                                                                onClick={() => { getEditFaqForm(data?.record_id); handleEdit(index) }}
                                                            >
                                                                <MdEdit />
                                                            </label>
                                                        </>


                                                    }


                                                </div>
                                            </div>



                                        ))}
                                        {FAQ_Object.length == 0 &&
                                            <span className="flex justify-center text-center text-[black] p-7">No FAQ'S</span>
                                        }
                                    </div>

                                </div>
                            </div>

                        </>
                    }
                    {FAQAddpopup &&
                        <>
                            <input type="checkbox" id="my-modal-6" className="modal-toggle" />
                            <div className="modal">
                                <div className="modal-box space-y-5 relative">
                                    <label

                                        className="btn btn-sm btn-circle  absolute right-2 top-2"
                                        onClick={() => setFAQAddpopup(false)}
                                    >
                                        x
                                    </label>
                                    <form onSubmit={handleSubmit} >
                                        <div className="space-y-2">
                                            <Input
                                                name="question"
                                                // value={formData.question}
                                                onChange={(e) => setquestion(e.target.value)}
                                                required
                                                placeholder="Question"
                                                id="Question"
                                                type="text"
                                                autoComplete="off"
                                                className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                                            />
                                            <Input
                                                name="answer"
                                                // value={formData.answer}
                                                onChange={(e) => setanswer(e.target.value)}
                                                required
                                                placeholder="Answer"
                                                id="Answer"
                                                type="text"
                                                autoComplete="off"
                                                className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                                            />
                                            {/* {qaArray.map((data, index) => (
                                            <div className="flex space-x-2 text-center items-center">
                                                <div className="w-10/12">
                                                    <Accordion key={index}>
                                                        <AccordionSummary
                                                            expandIcon={<MdExpandMore />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        >
                                                            {data.question}
                                                        </AccordionSummary>
                                                        <AccordionDetails className="bg-[#000] text-white">
                                                            <Typography>
                                                                {data.answer}
                                                            </Typography>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                            </div>



                                        ))} */}
                                        </div>
                                        <div className=" flex space-x-1 justify-end mt-3">
                                            {/* <button
                                            onClick={handleSubmit}
                                            className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
                                                <MdAdd className="mr-1" />
                                                add
                                            </button> */}
                                            {!addloadFAQ ?
                                                <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
                                                    submit
                                                </button>
                                                :
                                                <button
                                                    className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                                    type="button"
                                                //  onClick={()=> changeStep("4")}
                                                >
                                                    <svg
                                                        className="animate-spin mr-1 h-5 w-5 text-white"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                        <path
                                                            className="opacity-75"
                                                            fill="currentColor"
                                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                        ></path>
                                                    </svg>
                                                    Loading..
                                                </button>
                                            }


                                        </div>

                                    </form>


                                </div>
                            </div>

                        </>

                    }
                </div>
            </div>

        </>
    );
});

