/* eslint-disable react-hooks/exhaustive-deps */
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Input } from "react-daisyui";
import {
  MdOutlineCheck,
  MdOutlineClose,
  MdOutlineRefresh,
  MdOutlineSearch,
} from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import {
  useHomecourtStore,
  HomecourtStore,
  HomecourtStoreContext,
} from "../store/HomecourtStore";
import { set } from "mobx";
import { useState } from "react";

// const Filter = observer(() => {

//     return(
//         <HomecourtStoreContext.Provider value={new HomecourtStore({
//             loadeditcitybol:false,
//             editloading:false,
//             slectcity:true
//         })}>
//             <Screen/>
//         </HomecourtStoreContext.Provider>
//     )
// })

// export default Filter;

const Filter = observer((prop) => {
  const {
    updatedcitynameid,
    updatedcityname,
    getzipcode,
    getHomename,
    getstatus,
    setapprovedby,
    setaddedby,
    saved,
    pending,
    citydrop,
    getFilterHomecourt,
    getApprovedHomecourt,
    getHomecourt,
    getpendingcourt,
    getfilterCourtNickname,
    getfilterstatus,
    homename,
    zipcode
  } = useHomecourtStore();

  const [isapproved, setapproved] = useState(true);

useEffect(()=>{
  if(  prop.prop === "pendinghomecourts" ){
    getApprovedHomecourt();
  }

})

  const Reset = () => {
    getHomename("");
    updatedcitynameid("");
    getzipcode("");
    getstatus("");
    setapprovedby("");
    getpendingcourt("");
    getfilterCourtNickname("");
    setapproved(false);
    getfilterstatus("")
  };

  return (
    <>
      <div className="card bg-base-100 shadow-md p-6 mt-3 mb-10">
        <form
          onSubmit={(e) => {
            getFilterHomecourt();
            e.preventDefault();
          }}
        >
          <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
          {/* <div>
              <label
                htmlFor="CourtNickname"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Court Nickname
              </label>
              <Input
                name="CourtNickname"
                onChange={(e) => getfilterCourtNickname(e.target.value)}
                placeholder="Court Nickname"
                id="CourtNickname"
                type="text"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div> */}

            <div>
              <label
                htmlFor="CourtName"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Court Name
              </label>
              <Input
                name="CourtName"
                onChange={(e) => {getHomename(e.target.value); }}
                value={homename}
                placeholder="Court Name"
                id="CourtName"
                type="text"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            <div>
              <label
                htmlFor="City"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                City
              </label>
              <select
                defaultValue=""
                id="city"
                name="city"
                onChange={(e) => updatedcitynameid(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                {/* <option value="" disabled className="hidden">Select city</option> */}
                <option value="" className="">
                  All
                </option>
                {citydrop.map((data, index) => (
                  <option value={data?._id} key={index}>
                    {data?.city_name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="Zipcode"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Zip Code
              </label>
              <Input
                name="Zipcode"
                onChange={(e) => getzipcode(e.target.value)}
                value={zipcode}
                placeholder="Zip Code"
                id="Zipcode"
                type="text"
                maxLength={5}
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            <div>
              <label
                htmlFor="Country"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Country
              </label>
              <select
                id="Country"
                name="Country"
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="USA">USA</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="addedby"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Added By
              </label>
              <select
                id="addedby"
                name="addedby"
                defaultValue=""
                onChange={(e) => setaddedby(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="" className="">
                  All
                </option>
                <option value="admin">Admin</option>
                <option value="user">User</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="AdminApproved"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Admin Approved{" "}
              </label>
              <select
                id="AdminApproved "
                name="addedby"
                value={
                  prop.prop === "pendinghomecourts" ? 
                  isapproved === true
                    ? pending
                    : null
                    :
                    null
                }
                onChange={(e) => setapprovedby(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="" className="">
                  All
                </option>

                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="Status"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Status
              </label>
              <select
                id="Status"
                name="Status"
                defaultValue=""
                onChange={(e) => getfilterstatus(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="" className="">
                  All
                </option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
            </div>
          </div>
          <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
            <button
              type="reset"
              onClick={() => {
                getHomecourt();
                Reset();
              }}
              className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
            >
              <MdOutlineRefresh className="mr-1" />
              Reset
            </button>
            <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
              <MdOutlineSearch className="mr-1" />
              Search
            </button>
          </div>
        </form>
      </div>
    </>
  );
});

export default Filter;
