import React, { useEffect, useState } from "react";
import { Input } from "react-daisyui";
import { MdOutlineRefresh, MdOutlineSearch } from "react-icons/md";

import { observer } from "mobx-react";
import { usePlayerStore } from "../store/PlayerStore";
import { useLeagueStore } from "../../LeagueManagement/store/LeagueStore";

// const Filter = (props) => {
//   return (
//       <Screen id={props.league} />
//   );
// };

const Filter = observer((props) => {
  const {
    citydrop,
    homecourtdrop,
    leaguesdropdown,
    leaguename,
    level,
    League_Id,
    getlevel,
    getcity,
    getleague,
    gethomecourt,
    getfirstname,
    getPlayer,
    getlastname,
    getFilterPlayercourt,
    getPlayerList,
    allclear,
    getNewemail,
    leagueClear,
    newemail,
    fname,
    lname
  } = usePlayerStore();
  useEffect(() => {
    if (props.league) {
      getPlayer(props.league);
    }
  }, []);

  const {
    leveldropdown,
    getleveldropdown
  } = useLeagueStore();

  useEffect(() => {
    getleveldropdown()
  },[])


  return (
    <>
      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <form
          onSubmit={(e) => {
            getFilterPlayercourt();
            e.preventDefault();
          }}
        >
          <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
            <div>
              <label
                htmlFor="firstName"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                First Name
              </label>
              <Input
                name="FirstName"
                placeholder="first Name"
                // value={leaguename}
                value={fname}
                onChange={(e) => getfirstname(e.target.value)}
                id="firstName"
                type="text"
                autoComplete="off"
                className="focus:outline-0 capitalize w-full text-sm font-medium rounded-md"
              />
            </div>
            <div>
              <label
                htmlFor="lastName"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Last Name
              </label>
              <Input
                name="lastName"
                placeholder="Last Name"
                // value={leaguename}
                value={lname}
                onChange={(e) => getlastname(e.target.value)}
                id="lastName"
                type="text"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            <div className="hidden">
              <label
                htmlFor="LeagueName"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                League Name
              </label>
              <select
                id="LeagueName"
                name="LeagueName"
                onClick={leagueClear}
                value={leaguename ? leaguename._id : ""} // Set the value to the ID of leaguename if it exists
                onChange={(e) => getleague(e.target.value)}
                className={`select capitalize focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value={leaguename ? leaguename._id : ""} className="">
                  {leaguename ? leaguename.name : "Select a league"}
                </option>

                {leaguesdropdown?.map((data, index) => (
                  <option value={data._id} key={index}>
                    {data.name}
                  </option>
                ))}
              </select>
            </div>
              <div>
                        <label htmlFor="email" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Email</label>
                        <input 
                        name="email" 
                        onChange={(e) => getNewemail(e.target.value)}
                        value={newemail}
                        placeholder="Email" 
                        id="email" 
                        type="text" 
                        autoComplete="off" 
                        className="input focus:outline-0 w-full text-sm font-medium rounded-md capitalize focus:outline-offset-0 input-bordered"
                        />
                    </div>
            <div>
            <label
                htmlFor="level"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Level
              </label>
              <select
                   onChange={(e) => getlevel(e.target.value)}
                   value={level}
                  className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                  // aria-label="Default select example"
                >  
                 <option value=""> Select Level</option>
                {leveldropdown?.map((data,index)=> (
                        <option value={data?._id} key={index}>{data?.name.toFixed(1)} </option>
                        ))}
                  
                </select>
            </div>
            <div>
              <label
                htmlFor=" HomeCourt"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Home Court
              </label>
              <select
                defaultValue=""
                // value={newcitynameid}
                id="HomeCourt"
                name="HomeCourt"
                onChange={(e) => gethomecourt(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                {/* <option value="" disabled className="hidden">Select city</option> */}
                <option value="" className="">
                  All
                </option>
                {homecourtdrop.map((data, index) => (
                  <option value={data?._id} key={index}>
                    {data?.displayName}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label
                htmlFor="City"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                City
              </label>
              <select
                defaultValue=""
                // value={newcitynameid}
                id="city"
                name="city"
                onChange={(e) => getcity(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                {/* <option value="" disabled className="hidden">Select city</option> */}
                <option value="" className="">
                  All
                </option>
                {citydrop.map((data, index) => (
                  <option value={data?._id} key={index}>
                    {data?.city_name}
                  </option>
                ))}
              </select>
            </div>

            {/* <div>
              <label
                htmlFor="FeesAmount"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Fees Amount
              </label>
              <Input
                name="FeesAmount"
                placeholder="Fees Amount"
                id="FeesAmount"
                type="number"
                onChange={(e) => addfeesamnt(e.target.value)}
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div> */}
            {/* <div>
              <label
                htmlFor="lastdate"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Last Registration Date
              
              </label>
              <Input
                name="lastdate"
                placeholder=""
                onChange={(e) => addlastregesteration(e.target.value)}
                id="lastdate"
                type="date"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div> */}

            {/* <div>
              <label
                htmlFor="status"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Status
              </label>
              <select
                name="Status"
                defaultValue=""
                // onChange={(e) => addstat(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="">All</option>
                <option value={"true"}>Active</option>
                <option value={"false"}>Inactive</option>
              </select>
            </div> */}
          </div>
          <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
            <button
              type="reset"
              onClick={() => {
                allclear();
                getPlayerList();
              }}
              className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
            >
              <MdOutlineRefresh className="mr-1" />
              Reset
            </button>
            <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
              <MdOutlineSearch className="mr-1" />
              Search
            </button>
          </div>
        </form>
      </div>
    </>
  );
});
export default Filter;
