import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineClose, MdOutlineCheck, MdImageAspectRatio, MdImage, MdSave, MdEdit } from "react-icons/md";
import { Input, Button, Select } from "react-daisyui";
// import { CityStore, CityStoreContext } from "./store/CityStore";
import { PreOrderStore, PreOrderStoreContext, usePreOrderStore } from "./store/PreOrderStore";
import { observer } from "mobx-react";
import { useAuthStore } from "../Auth/store/Auth";
import { useState } from "react";
import ImgCrop from "antd-img-crop";
import { Upload } from 'antd';
import axios from "axios";
const SEO_URL = process.env.REACT_APP_SEO_URL;

const PreOrderSettings = observer(() => {

    return (
        <PreOrderStoreContext.Provider
            value={
                new PreOrderStore({
                    isloading: true,
                    // addloading: true,
                })
            }
        >
            <Screen />
        </PreOrderStoreContext.Provider>
    );
});
export default PreOrderSettings;
const Screen = observer(() => {
    const { getstatus, setaddPreOrdername, addPreOrder, updatePreOrder, setCrop, setPreOrderFormDetails, PreOrderCircle , Base64BannerImage  , PreOrderFormDetails, PreOrder, setBannerDimiensions, getlogoimage, getbanner_image, crop, logoimage, errorMessages, addPreOrdername, saved, getseourl, seoUrl, addloadcity, getToken } = usePreOrderStore();
    const { adminAuthApproved } = useAuthStore();
    const [logofile, setlogofile] = useState();
    const [bannerfile, setbannerfile] = useState();
    const [croppedImage, setCroppedImage] = useState(null);
    // const [crop, setCrop] = useState({ aspect: 16 / 9, dimensions: { width: 480, height: 270 } });
    // const { register, handleSubmit, errors } = useForm();
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    const onSubmit = (data) => {
        // Handle form submission logic here
        console.log(data);
    };
    console.log(PreOrderFormDetails);
    useEffect(() => {
        if (adminAuthApproved) {
            getToken(localStorage.getItem("Admintoken"));
        }

    }, [adminAuthApproved]);
    const navigate = useNavigate();


    // useEffect(() => {
    //     if (saved) {
    //         navigate("../PreOrdermanagement");
    //     }
    // }, [saved]);

    const handleChange = (e) => {
        // resetErrors(); // Reset errors on input change
        console.log(e);
        switch (e) {
            case 'PreOrdername':
                setaddPreOrdername(e.target.value);
                break;
            case 'status':
                getstatus(e.target.value);
                break;
            // Handle other form fields in a similar manner
            default:
                break;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        updatePreOrder();
        // Perform API call or any other logic using MobX store data
        // Example: addCity();
    };

    console.log(SEO_URL);







    const getLogoSrcFromFile = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
        });
    };

    const getBase64 = file => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
        });
    };





    const LogoPreview = async (file) => {
        const src = file.url || (await getLogoSrcFromFile(file));
        if (src) {
            const imgWindow = window.open(src);
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }
            if (imgWindow) {
                const image = new Image();
                image.src = src;
                imgWindow.document.write(image.outerHTML);
            } else {
                window.location.href = src;
            }
        };
    }





    const getBannerSrcFromFile = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
        });
    };

    const LogChange = async ({ fileList: newFileList }) => {
        // Allow only the first file in the list
        const file = newFileList[0];
        // Get the base64 representation of the file
        if (file) {
            const base64Image = await getBase64(file.originFileObj);
            const files = base64Image;
            setPreOrderFormDetails({
                ...PreOrderFormDetails,
                image: files,
            })
            getlogoimage(files);

        }

        // Update the logofile state with the newFileList
        setlogofile(newFileList.slice(0, 1));

    };

    const BannerChange = async ({ fileList: newFileList }) => {
        // Allow only the first file in the list
        const file = newFileList[0];
        if (file) {
            const base64Image = await getBase64(file.originFileObj);
            const files = base64Image;
            getbanner_image(files);
        }
        setbannerfile(newFileList.slice(0, 1));
    };

    const BannerPreview = async (file) => {
        const src = file.url || (await getBannerSrcFromFile(file));
        if (src) {
            const imgWindow = window.open(src);

            if (imgWindow) {
                const image = new Image();
                image.src = src;
                imgWindow.document.write(image.outerHTML);
            } else {
                window.location.href = src;
            }
        }

    };
    const [selectedOption, setSelectedOption] = useState("Desktop"); // Default value for Desktop

    const getbannerstatus = (value) => {
        setSelectedOption(value);
    };

    const getAspectByOption = () => {
        switch (selectedOption) {
            case 'Desktop': // Desktop
                return 16 / 9; // Example aspect ratio for Desktop
            case 'Ipad': // iPad or Mobile
                return 4 / 3; // Example aspect ratio for iPad or Mobile
            case 'Mobile': // iPad or Mobile
                return 9 / 16; // Example aspect ratio for iPad or Mobile
        }
    };
    const handleAspectRatioChange = (aspect) => {
        let dimensions;

        switch (aspect) {
            case 16 / 9:
                dimensions = { width: 480, height: 270 };
                break;
            case 9 / 1:
                dimensions = { width: 500, height: 500 };
                break;
            // Add more cases for other aspect ratios
            default:
                dimensions = { width: 200, height: 200 };
        }

        setCrop({ aspect, dimensions });
    };

    const getDimensionsByOption = () => {
        let dimensions
        switch (selectedOption) {
            case 'Desktop':
                dimensions = { width: 2000, height: 500 };
            case 'Ipad':
                dimensions = { width: 1024, height: 576 };
            case 'Mobile':
                dimensions = { width: 640, height: 360 };
            default:
                dimensions = { width: 200, height: 200 };
        }
        setBannerDimiensions({ dimensions })
    };

    const [minDate, setMinDate] = useState('');
console.log(Base64BannerImage);
    useEffect(() => {
        // Get the current date and time in the New York time zone
        const now = new Date();
        const newYorkTimeZone = 'America/New_York';
        const nyNow = new Date(now.toLocaleString('en-US', { timeZone: newYorkTimeZone }));

        // Format the date in ISO format (YYYY-MM-DD)
        const minDate = nyNow.toISOString().split('T')[0];
        setMinDate(minDate);
    }, []);


    const handleCrop = croppedImageUrl => {
        setCroppedImage(croppedImageUrl);
    };

    const isBase64 = (str) => {
        const base64Regex = /^(data:image\/([a-zA-Z]+);base64,)/;
        return base64Regex.test(str);
    };

    return (
        <>
          <div className="flex justify-between mt-[51px] ">
                <div className=" mb-4">
                    <p className=" text-2xl font-semibold">PreOrder Settings</p>
                    <p className=" opacity-80 text-sm">Home /Preorder Settings</p>
                </div>

            </div>
            {PreOrderCircle ?
                <>
                    <svg
                        className="animate-spin  mr-0 ml-[45%] mt-[15%] h-[5%] w-[5%] text-color_theme"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                        ></circle>
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                    </svg>
                </>
                :
                <div className="card bg-base-100 shadow-md p-6 mt-8">
                    <form onSubmit={handleSubmit}>
                        <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
                            <div>
                                <label
                                    htmlFor="PreOrdername"
                                    className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                                >
                                    Product name<span className="text-[#f00]">&nbsp;*</span>
                                </label>
                                <Input
                                    name="Productname"
                                    required
                                    value={PreOrderFormDetails?.product_name}
                                    onChange={(e) => {
                                        setPreOrderFormDetails({
                                            ...PreOrderFormDetails,
                                            product_name: e.target.value,
                                        });
                                        // getseourl(e.target.value);
                                    }}
                                    placeholder="Product Name"
                                    id="Productname"
                                    type="text"
                                    autoComplete="off"
                                    className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                                />

                            </div>
                            <div>
                                <label htmlFor="startdate" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Valid upto<span className="text-[#f00]">&nbsp;*</span></label>
                                <Input
                                    name="startdate"
                                    placeholder=""
                                    value={PreOrderFormDetails?.valid_till}
                                    onChange={(e) => {
                                        setPreOrderFormDetails({
                                            ...PreOrderFormDetails,
                                            valid_till: e.target.value,
                                        });
                                        // getseourl(e.target.value);
                                    }}
                                    id="startdate"
                                    min={minDate}
                                    type="date"
                                    autoComplete="off"
                                    required
                                    className="focus:outline-0 w-full text-sm font-medium rounded-md"
                                />
                            </div>

                            <div>
                                <label
                                    htmlFor="Status"
                                    className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                                >
                                    PreOrder Status
                                </label>
                                <select
                                    id="Status"
                                    value={PreOrderFormDetails?.is_active}
                                    onChange={(e) => {
                                        setPreOrderFormDetails({
                                            ...PreOrderFormDetails,
                                            is_active: e.target.value,
                                        });
                                        // getseourl(e.target.value);
                                    }}
                                    name="Status"
                                    className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                                >
                                    <option value="1">Active</option>
                                    <option value="0">Inactive</option>
                                </select>
                            </div>
                        </div>
                        <div className="border-t-2  border-dashed mt-9 ml-3 ">
                            <label htmlFor="image" className="block text-md font-semibold text-zinc-700 mt-3 dark:text-stone-400 mb-2">
                                Poster<span className="text-[#f00]">*</span>
                            </label>

                            {/* {imageurl && <img className="w-[50%]" src={imageurl} alt="Selected" />} */}
                            <ImgCrop
                                aspect={crop.aspect}
                                resize={crop.dimensions}
                                showGrid
                                rotationSlider
                                aspectSlider
                                showReset
                                onCrop={handleCrop}
                            >
                                <Upload
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    listType="picture-circle"
                                    fileList={logofile}
                                    onChange={LogChange}
                                    onPreview={LogoPreview}
                                    className="logo"
                                >

                                    {(logofile == undefined && logofile?.length === 0) && !PreOrderFormDetails?.image ? (
                                        <span className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ">Upload Logo</span>
                                    )
                                        :

                                        (logofile == undefined || logofile?.length === 0) && (

                                            (PreOrderFormDetails?.image == null || PreOrderFormDetails?.image == "") ?
                                                <span className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ">Upload Logo</span>
                                                :
                                                <div className="relative group">
                                                    {isBase64(PreOrderFormDetails?.image) ?
                                                        <img
                                                            crossOrigin="anonymous"
                                                            src={BASE_URL + `/uploads/preOrder/${Base64BannerImage}`}
                                                            alt="Default Logo"
                                                            className="w-full h-auto rounded-[50%]"
                                                        />
                                                        :
                                                        <img
                                                            crossOrigin="anonymous"
                                                            src={BASE_URL + `/uploads/preOrder/${PreOrderFormDetails?.image}`}
                                                            alt="Default Logo"
                                                            className="w-full h-auto rounded-[50%]"
                                                        />

                                                    }

                                                    <div className="absolute inset-0 bg-color_theme bg-opacity-50 rounded-[50%] flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                                                        <MdEdit className="text-white text-lg" />
                                                    </div>
                                                </div>
                                        )

                                    }

                                </Upload>
                                {/* Conditionally render the default image */}

                            </ImgCrop>
                        </div>


                        <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                            {/* <Link
                            // to="../PreOrdermanagement"
                            className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                        >
                            <MdOutlineClose className="mr-1" />
                            Cancel
                        </Link> */}
                            {!addloadcity ?
                                <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
                                    <MdSave className="mr-1" />
                                    Save PreOrder
                                </button>
                                :
                                <button
                                    className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                    type="button"
                                //  onClick={()=> changeStep("4")}
                                >
                                    <svg
                                        className="animate-spin mr-1 h-5 w-5 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        ></path>
                                    </svg>
                                    Loading..
                                </button>
                            }

                        </div>
                    </form>
                </div>
            }
        </>
    );
});

