import {createContext,useContext} from 'react'
import { makeAutoObservable, runInAction } from "mobx";
import {getMethodData,putMethod, patchMethodData} from "../../ApiMethods/api"
import { toast } from "react-toastify";

export class UserStore {

    Token = null;
    Statuspopup=false;
    isDeleted = false;
    users = [];
    errloaduser = "";
    loaduser = false;
    nolist = false;
    nolistuser = false;
    UserLoader=false;

    addloaduser = false;
    erradduser = false;

    homecourtdrop = [];
    citydrop=[];
    updateuser = {};
    fName = "";
    lName = "";
    newemail = "";
    newlevel = "";
    status = "" ;
    newmobile = "";
    homeCourt = {};
    homeCourtid = "";
    newcity = {};
    newcityname = ""
    newgender = "";
    dob = ""
    Statename=[]
    leveldropdown= []
    leveldropdownid = ""
    leveldropdowndoubleid=""
    leveldropdownmixedid=""
    level = {}
    userid = ""

    image = ""

    saved = false

    usermobile= ""
    dwnloadbtn =false

    errloaduserdelete = "";
    loaduserdelete = false;
    Userdelete =[];
    deleteuser=true;
    nolistdelete = false;


    


 
    constructor(isloading = false) {
        makeAutoObservable(this);
        
        this.Token = localStorage.getItem("Admintoken");
        if(isloading.slectcity){
          // this.gethomecourtdropdown()
          this.getleveldropdown()
        
        }
        if(isloading.isloading){
          this.getUsers();
          this.getStateList();
          this.getUserhomecourtdropdown();
          return
        }
        
        if(isloading.editloading){
          this.getuserone(isloading.id);
          this.getcitydropdown()
          //  this.gethomecourtdropdown();
           this.getleveldropdown()
          return
        }
      
      }

       // get first name
  getFirstname = (text) => {
    this.fName = text;
  }
  setisDelete=()=>{
    this.isDeleted = !this.isDeleted
  }
   //get updated city
   updatedcityname = (text) => {
    this.newcityname = text;
  };
  setstatusVoidpopup = (e) => {
    this.Statuspopup = e;
  };
  //get city name id
  updatedcitynameid = (text) => {
    this.newcitynameid = text;
  };
  getstate=(text)=>{
    this.statesid=text;
  }

  //get last name
  getLastname = (text) => {
    this.lName = text;
  }

  //get new email
  getNewemail= (text) => {
    this.newemail = text;
  }


 //get new status 
 getNewstatus= (text) => {
  this.status = text;
 
  if(text == "isDelete"){
    this.isDeleted = true;
  }
  else{
    this.isDeleted = false;

  }
}

//get new mobile 
getNewmobile= (text) => {
  this.newmobile = text;

}

getusernumber = (text) => {
  this.usermobile = (text || "").toString()

}

//get new homeCourt
getNewhomecourt= (text) => {
  this.homeCourt = text;
}

//get homecourt id
getNewhomecourtid= (text) => {
  this.homeCourtid = text;
}

//get level dropdown
getNewlevel= (text) => {
  this.leveldropdownid = text;
}

//get level double dropdown
getNewleveldouble= (text) => {
  this.leveldropdowndoubleid = text;
}

//get level mixed dropdown
getNewlevelmixed= (text) => {
  this.leveldropdownmixedid = text;
}


//get new newcity
getNewcity= (text) => {
  this.newcity = text;
}

getNewcityname= (text) => {
  this.newcityname = text;
}
//get new newgender
getNewgender= (text) => {
  this.newgender = text;
}

//get new dob
getNewdob= (text) => {
  this.dob = text;
}

 // get city id
 getstatusid = (text) => {
  this.userid = text;
}

 // download btn false
 getdwnloadbtn = (text) => {
  this.dwnloadbtn = text;
}


    getchangeimage = (e )=>{
this.image = URL.createObjectURL(e.target.files[0])
    }

    getToken=(e)=>{
      this.Token=e;
      }

    // get user List
    getUsers = () => {
        runInAction(() => {
            this.loaduser = true;
            this.errloaduser = "";
            this.saved = false;
            this.nolistuser = false;
            this.users = [];
          });
        getMethodData("/api/admin/user",this.Token)
        .then((resp) => {
  
            if (resp?.data?.status === false) {
              runInAction(() => {
                this.loaduser = false;
                this.errloaduser = resp?.data?.message
              });
              return;
            }
            if (resp?.data?.status === true) {
              runInAction(() => {
                this.loaduser = false;
                this.errloaduser = resp?.data?.message;
                if(resp?.data?.data?.length === 0) {
                  this.nolistuser = true;
                }else{
                  this.nolistuser = false;
                }
                this.users = resp?.data?.data;
                this.saved = false;
                // this.status = true;
              });
              return;
            }
            runInAction(() => {
              this.loaduser = false;
              this.errloaduser = resp?.data?.message;
            });
          })
          .catch((error) => {
            runInAction(() => {
                this.loaduser = false;
                if(!error.response){
                    this.errloaduser = error?.message;
                    toast.error(error?.message);
                }
                this.errloaduser = error?.response?.data?.message;
                toast.error(error?.response?.data?.message);
            })
            return;
        });
    };


    //filter user api 

    getFilterUsers = () => {
      runInAction(() => {
          this.loaduser = true;
          this.errloaduser = "";
          this.saved = false;
          this.nolistuser = false;
          this.users =[]
          this.dwnloadbtn = false
        });
      getMethodData(`/api/admin/user?filters[firstName]=${this.fName}&filters[lastName]=${this.lName}&filters[email]=${this.newemail}&filters[gender]=${this.newgender}&filters[city]=${this.newcityname}&filters[homeCourt]=${this.homeCourtid}&${this.isDeleted ? `filters[isDelete]=${this.isDeleted}`:`filters[isActive]=${this.status}` }&filters[level]=${this.leveldropdownid}&filters[doubles_level]=${this.leveldropdowndoubleid}&filters[mixed_level]=${this.leveldropdownmixedid}`,this.Token)
      .then((resp) => {

          if (resp?.data?.status === false) {
            runInAction(() => {
              this.loaduser = false;
              this.errloaduser = resp?.data?.message
              this.dwnloadbtn = false
            });
            return;
          }
          if (resp?.data?.status === true) {
            runInAction(() => {
              this.loaduser = false;
              this.errloaduser = resp?.data?.message;
              if(resp?.data?.data.length === 0) {
                this.nolistuser = true;
              }else{
                this.nolistuser = false;
              }
              this.dwnloadbtn = true
              this.users = resp?.data?.data;
              this.saved = false;
              // this.status = true;
            });
            return;
          }
          runInAction(() => {
            this.loaduser = false;
            this.dwnloadbtn = false
            this.errloaduser = resp?.data?.message;
          });
        })
        .catch((error) => {
          runInAction(() => {
              this.loaduser = false;
              this.dwnloadbtn = false
              if(!error.response){
                  this.errloaduser = error?.message;
                  toast.error(error?.message);
              }
              this.errloaduser = error?.response?.data?.message;
              toast.error(error?.response?.data?.message);
          })
          return;
      });
  };

    //get user edit data (show)

    getuserone =(id) => {
      getMethodData( `/api/admin/user/${id}`, this.Token)
      .then((resp) => {
          runInAction(() => {
              this.errloaduser = "";
            });
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.errloaduser = resp.data.message;
            // toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.fName =resp?.data?.data?.firstName ;
            this.lName =resp?.data?.data?.lastName ;
            this.newemail =resp?.data?.data?.email ;
            this.newlevel =resp?.data?.data?.level ;
            if(resp?.data?.data?.dob){
              this.dob =new Date(resp?.data?.data?.dob) ;
            }
            this.newgender =resp?.data?.data?.gender;
            this.newmobile = resp?.data?.data?.mobile;
            this.usermobile = ("+1"+resp?.data?.data?.mobile).toString();
            // this.usermobile = resp?.data?.data?.mobile;
            this.homeCourt =resp?.data?.data?.homeCourt;
            this.homeCourtid = resp?.data?.data?.homeCourt?._id;
            this.newcity = resp?.data?.data?.city;
            this.newcityname = resp?.data?.data?.city?._id
            this.status =resp?.data?.data?.isActive;
            this.updateuser = resp?.data?.data ;
            this.leveldropdownid = resp?.data?.data?.level;
            this.leveldropdowndoubleid = resp?.data?.data?.doubles_level;
            this.leveldropdownmixedid = resp?.data?.data?.mixed_level;
            if(resp?.data?.data?.city){
              this.gethomecourtdropdown(resp?.data?.data?.city?._id)
            }
          })
          return;
        }
        runInAction(() => {
          this.errloaduser = resp.data.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
            if(!error.response){
              this.errloaduser = error?.message;
              toast.error(error?.message);
            }
              toast.error(error?.response?.data?.message);
              this.errloaduser = error?.response?.data?.message;
        })
        return;
    })
  }

   //get homecourt drop down according to city

   gethomecourtdropdown =(cityid) =>{
    runInAction(() => {
      this.errloaduser = "";
      this.saved = false;
    });
    getMethodData(`/api/get/home-courts/${cityid}`, this.Token)
   .then((resp) => {
     if (resp?.data?.status === false) {
       runInAction(() => {
        this.errloaduser = resp?.data?.message;
        // toast.error(resp.data.message);
       });
       return;
     }
     if (resp?.data?.status === true) {
       runInAction(() => {
        this.errloaduser = resp?.data?.message;
         // toast.success(resp?.data?.message);
         this.homecourtdrop = resp?.data?.data;
       });
       return;
     }
     runInAction(() => {
      this.errloaduser = resp?.data?.message;
     });
   })
   .catch((error) => {
     runInAction(() => {
      
         if(!error.response){
          this.errloaduser = error?.message;
             // toast.error(error?.message);
         }
         this.errloaduser = error?.response?.data?.message;
         // toast.error(error?.response?.data?.message);
     })
     return;
 });
 }
 getUserhomecourtdropdown =() =>{
  runInAction(() => {
    this.errloaduser = "";
    this.saved = false;
  });
  getMethodData(`/api/get/home-courts`, this.Token)
 .then((resp) => {
   if (resp?.data?.status === false) {
     runInAction(() => {
      this.errloaduser = resp?.data?.message;
      // toast.error(resp.data.message);
     });
     return;
   }
   if (resp?.data?.status === true) {
     runInAction(() => {
      this.errloaduser = resp?.data?.message;
       // toast.success(resp?.data?.message);
       this.homecourtdrop = resp?.data?.data;
      
     });
     return;
   }
   runInAction(() => {
    this.errloaduser = resp?.data?.message;
   });
 })
 .catch((error) => {
   runInAction(() => {
       if(!error.response){
        this.errloaduser = error?.message;
           // toast.error(error?.message);
       }
       this.errloaduser = error?.response?.data?.message;
       // toast.error(error?.response?.data?.message);
   })
   return;
});
}
  // get State list
  getStateList = () => {
    runInAction(() => {
      this.loadSate = true;
      this.errStatelevel = "";
      this.saved = false;
    });
    getMethodData("/api/admin/state", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadleague = false;
            this.errloadhomecourt = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadSate = false;
            this.errStatelevel = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.Statename = resp?.data?.data;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.addleague = false;
          this.loadSate = false;
          this.errStatelevel = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadSate = false;
          if (!error.response) {
            this.errloadlevel = error?.message;
            toast.error(error?.message);
          }
          this.errloadlevel = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };

    // get level dropdown
    getleveldropdown =() =>{
      runInAction(() => {
        this.errloaduser = "";
        this.saved = false;
      });
    getMethodData("/api/get/lavels", this.Token)
    .then((resp) => {
      if (resp?.data?.status === false) {
        runInAction(() => {
          this.errloaduser = resp?.data?.message;
          // toast.error(resp.data.message);
        });
        return;
      }
      if (resp?.data?.status === true) {
        runInAction(() => {
          this.errloaduser = resp?.data?.message;
          // toast.success(resp?.data?.message);
          //  this.homecourtdrop = resp?.data?.data;
            this.leveldropdown =resp?.data?.data;
        });
        return;
      }
      runInAction(() => {
        this.errloaduser = resp?.data?.message;
      });
    })
    .catch((error) => {
      runInAction(() => {
          if(!error.response){
            this.errloaduser = error?.message;
              // toast.error(error?.message);
          }
          this.errloaduser = error?.response?.data?.message;
          // toast.error(error?.response?.data?.message);
      })
      return;
    });
    }

 // user update

 edituser = (id) => {
  runInAction(() => {
    this.addloaduser = true;
    this.erradduser = "";
    
  });
  putMethod(`/api/admin/user/${id}`,
    {
      firstName: this.fName,
      lastName: this.lName,
      email: this.newemail,
      level: this.leveldropdownid, 
      doubles_level:this.leveldropdowndoubleid,
      mixed_level:this.leveldropdownmixedid,
      gender: this.newgender,
      mobile: this.usermobile.replace(/[^\d]/g, "").substring(1),
      homeCourt: this.homeCourt._id,
      city: this.newcityname,   
      isActive: this.status, 
      dob: this.dob,
      image: this.image,


      // city: this.newcityname,
      // name: this.homename,
      // zipcode: this.zipcode,
      // isActive: this.status,
    },
    this.Token
  )
    .then((resp) => {
      if (resp?.data?.status === false) {
        runInAction(() => {
          this.addloaduser = false;
          this.erradduser = resp?.data?.message;
          // toast.error(resp.data.message);
        });
        return;
      }
      if (resp?.data?.status === true) {
        runInAction(() => {
            this.addloaduser = false;
            this.erradduser = resp?.data?.message;
            toast.success(resp.data.message);
             this.getUsers();
            this.saved = true;
          })
          return
      }
      runInAction(() => {
        this.addloaduser = false;
        this.erradduser = resp?.data?.message;
        toast.error(resp.data.message);
      });
    })
    .catch((error) => {
      runInAction(() => {
          if(!error.response){
            this.addloaduser = false;
            this.erradduser = error?.message;
            toast.error(error?.message);
          }
            toast.error(error?.response?.data?.message);
            this.addloaduser = false;
            this.erradduser = error?.response?.data?.message;
      })
      return;
  })
};
getcitydropdown =() =>{
  runInAction(() => {
   this.loadhomecourt = true;
   this.errloadhomecourt = "";
   // this.saved = false;
  });
 getMethodData("/api/get/cities", this.Token)
 .then((resp) => {
   if (resp?.data?.status === false) {
     runInAction(() => {
       this.loadhomecourt = false;
       this.errloadhomecourt = resp?.data?.message
     });
     return;
   }
   if (resp?.data?.status === true) {
     runInAction(() => {
       this.loadhomecourt = false;
       this.errloadhomecourt = resp?.data?.message;
       // toast.success(resp?.data?.message);
       this.citydrop = resp?.data?.data;
      
     });
     return;
   }
   runInAction(() => {
     this.loadhomecourt = false;
     this.errloadhomecourt = resp?.data?.message;
   });
 })
 .catch((error) => {
   runInAction(() => {
       this.loadhomecourt = false;
       if(!error.response){
           this.errloadhomecourt = error?.message;
           // toast.error(error?.message);
       }
       this.errloadhomecourt = error?.response?.data?.message;
       // toast.error(error?.response?.data?.message);
   })
   return;
});
}

//Active and inactive
statusChange = () => {
  runInAction(() => {
    this.Statuspopup = true;
    this.UserLoader=true;
    this.errloaduser = "";
  });
  patchMethodData(`/api/admin/user/status/${this.userid}`,{ isActive : `${!this.status}` },this.Token)
    .then((resp) => {
      if (resp?.data?.status === false) {
        runInAction(() => {
          this.UserLoader=true;

          this.errloaduser = resp?.data?.message;
          // toast.error(resp?.data?.message);
        });
        return;
      }
      if (resp?.data?.status === true) {
        runInAction(() => {
          this.UserLoader=false;
          this.Statuspopup = false;

          this.errloaduser = resp?.data?.message;
          this.getUsers();
          toast.success(resp?.data?.message);
        })
        return;
      }
      runInAction(() => {
        this.UserLoader=true;

        this.errloaduser = resp?.data?.message;
      })
    })
    .catch((error) => {
      runInAction(() => {
          if(!error.response){
            this.UserLoader=true;
            this.Statuspopup = true;

            this.errloaduser = error?.message;
            toast.error(error?.message);
          }
            toast.error(error?.response?.data?.message);
            this.UserLoader=true;
            this.Statuspopup = true;
            this.errloaduser = error?.response?.data?.message;
      })
      return;
  })
};


//getUserDeletedFilter

getUserDeletedFilter = () => {
  runInAction(() => {
    this.loaduserdelete = true;
    this.nolistdelete = false;
    this.errloaduserdelete = "";
    this.saved = false;
    this.Userdelete =[];
  });

  getMethodData(`/api/admin/user?filters[isDelete]=${this.deleteuser}`, this.Token)
    .then((resp) => {
      if (resp?.data?.status === false) {
        runInAction(() => {
          this.loaduserdelete = false;
          this.errloaduserdelete = resp?.data?.message
        });
        return;
      }
      if (resp?.data?.status === true) {

        runInAction(() => {
          this.loaduserdelete = false;
          this.errloaduserdelete = resp?.data?.message;
          if (resp?.data?.data?.length == 0) {
            this.nolistdelete = true;
          } else {
            this.nolistdelete = false;
          }
          this.Userdelete = resp?.data?.data;
          this.saved = false;
          this.status = true;
        });
        return;
      }
      runInAction(() => {
        this.loaduserdelete = false;
        this.errloaduserdelete = resp?.data?.message;
      });
    })
    .catch((error) => {
      runInAction(() => {
        this.loaduserdelete = false;
        if (!error.response) {
          this.errloaduserdelete = error?.message;
          toast.error(error?.message);
        }
        this.errloaduserdelete = error?.response?.data?.message;
        toast.error(error?.response?.data?.message);
      })
      return;
    });
};


    // add new user

    // addUser = (data) => {
    //     runInAction(() => {
    //         this.addloaduser = true;
    //         this.erradduser = "";
    //     })
    //     postMethodData("api",data,this.Token).then((resp) => {
    //         if(resp?.data?.status === false){
    //             runInAction(() => {
    //                 this.addloaduser = false;
    //                 this.erradduser = resp?.data?.message;
    //                 toast.error(resp.data.message);
    //             })
    //             return;
    //         }
    //         runInAction(() => {
    //             this.addloaduser = false;
    //             this.erradduser = resp?.data?.message;
    //             toast.success(resp.data.message);
    //         })
    //     }).catch((error) => {
    //         runInAction(() => {
    //             this.addloaduser = false;
    //             this.erradduser = error?.message;
    //             toast.error(error?.message);
    //         })
    //     })
    // };

};


export const UserStoreContext = createContext(new UserStore());
export const useUserStore = () => useContext(UserStoreContext);