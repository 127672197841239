import { observer } from 'mobx-react';
import React, {useEffect, useState}from 'react'
import { MdOutlineCheck, MdOutlineClose } from 'react-icons/md';
import { Link,useParams ,useNavigate } from 'react-router-dom';
import { useUserStore,UserStore,UserStoreContext } from "./store/UserStore";
import {useHomecourtStore,HomecourtStore,HomecourtStoreContext} from '../HomecourtManagement/store/HomecourtStore';
import { CityDropdown } from './citydropdown';
import userimage from "../../src/assets/images/user.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from "moment";

import Input from 'react-phone-number-input/input'
import moment from 'moment';
import { useAuthStore } from '../Auth/store/Auth';



const EditUserManagement = observer(() => {
      const { id } = useParams();
      
    return(
        <UserStoreContext.Provider value={new UserStore({
            loadeditcitybol:false,
            editloading:true,
            slectcity:true,
            id:id
          })}>
         <Screen/>
        </UserStoreContext.Provider>
    )
})

export default EditUserManagement

const Screen= observer(()=>{
    const {
        saved,
        loaduser,
        image,
        getchangeimage,
        fName,
        lName,
        newemail,
        getFirstname,
        updatedcityname,
        newlevel,
        status,
        newmobile,
        newgender,
        homecourtdrop,
        newcity,
        newcityname,
        homeCourt,
        edituser,
        getNewemail,
        getNewlevel,
        getNewmobile,
        getLastname,
        getNewstatus,
        getNewhomecourt,
        getNewgender,
        getNewdob,
        dob,
        citydrop,
        getNewcity,
        getNewcityname,
        leveldropdown,
        leveldropdownid,
        leveldropdowndoubleid,
        leveldropdownmixedid,
        gethomecourtdropdown,
        usermobile,
        getusernumber,
        getNewleveldouble,
        getNewlevelmixed,
        addloaduser,
        getToken
      } = useUserStore();

      const {id} = useParams()
      const { adminAuthApproved } = useAuthStore();
  
      useEffect(() => {
        if (adminAuthApproved) {
          getToken(localStorage.getItem("Admintoken"));
        }
      
      }, [adminAuthApproved]);

      const navigate = useNavigate();
      useEffect(() => {
        if (saved) {
          navigate("../usermanagement");
        }
      }, [saved,loaduser]);

      function getMaxDate() {
        const today = new Date();
        today.setDate(today.getDate() - 1);
        const maxYear = today.getFullYear() - 18;
        const maxMonth = today.getMonth() + 1;
        const maxDay = today.getDate();
        return `${maxYear}-${maxMonth < 10 ? `0${maxMonth}` : maxMonth}-${maxDay < 10 ? `0${maxDay}` : maxDay}`;
      }

      const dateString = dob;
      const date = moment(dateString,  "YYYY-MM-DD");
      const isoString = date.format("YYYY-MM-DD");



    return(
        <>
         <div className='flex justify-between'>
            <div className=" mb-4">
                <p className=" text-2xl font-semibold">Edit User</p>
                {/* <p className=" opacity-80 text-sm">Home /City/ Edit</p> */}
            </div>
            <div>
                <Link to="../usermanagement" className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]">Back</Link>
            </div>
        </div>
        <div className="card bg-base-100 shadow-md p-6 mt-8">
            <form onSubmit={(e) => {edituser(id);e.preventDefault()}}>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
                {/* <div className='col-span-3'>
                        <label htmlFor="Image" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Image</label>
                        <div className='relative'>
                            <img className='h-[150px] w-[150px]' src={userimage}/>
                        </div>
                    </div> */}
                    <div>
                        <label htmlFor="Firstname" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">First Name<span className="text-[#f00]">&nbsp;*</span></label>
                        <input 
                        name="Firstname" 
                        required
                        // onChange={(e) =>{{ getFirstname(e.target.value) }}}
                        onChange={(e) => {
                          const capitalizedValue = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
                          getFirstname(capitalizedValue);
                        }}
                        value={fName ?? ""}
                        placeholder="First Name" 
                        id="Firstname" 
                        type="text" 
                        autoComplete="off" 
                        className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered capitalize"
                        />
                    </div>
                    <div>
                        <label htmlFor="Lastname" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Last Name<span className="text-[#f00]">&nbsp;*</span></label>
                        <input 
                        name="Lastname" 
                        required
                        //  onChange={(e) => getLastname(e.target.value)}
                         onChange={(e) => {
                          const capitalizedValue = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
                          getLastname(capitalizedValue);
                        }}
                         value={lName ?? ""}
                         placeholder="Last Name" 
                        id="Lastname" 
                        type="text" 
                        autoComplete="off" 
                        className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered capitalize"
                        />
                    </div>
                    <div>
                        <label htmlFor="Email" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Email<span className="text-[#f00]">&nbsp;*</span></label>
                        <input 
                        name="Email" 
                        disabled
                        // required
                        //  onChange={(e) => getNewemail(e.target.value)}
                         value={newemail ?? ""}
                        placeholder="Email" 
                        id="Email" 
                        type="email" 
                        autoComplete="off" 
                        className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
                        />
                    </div>
                  
                    <div>
                        <label htmlFor="Level" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Single Level<span className="text-[#f00]">&nbsp;*</span></label>
                        {/* <input 
                        name="Level" 
                        required
                        min={0}
                        max={7}
                         onChange={(e) => getNewlevel(e.target.value)}
                         value={newlevel}
                         placeholder="Level" 
                        id="Level" 
                        type="number" 
                        autoComplete="off" 
                        className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
                        /> */}
                        <select
                        id="Level"
                        required
                        value={leveldropdownid ?? ""}
                        onChange={(e) => {getNewlevel(e.target.value)}} 
                        name="Level"
                        className={`select input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                        <option value="" className="hidden">Select Level</option>
                        {leveldropdown?.map((data,index)=> (
                        <option value={data?._id} key={index}>{data?.name.toFixed(1)} </option>
                        ))}

                        </select>
                    </div>
                      <div>
                        <label htmlFor="Level" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Double Level<span className="text-[#f00]">&nbsp;*</span></label>
                   
                        <select
                        id="Level"
                        required
                        value={leveldropdowndoubleid ?? ""}
                        onChange={(e) => {getNewleveldouble(e.target.value)}} 
                        name="Level"
                        className={`select input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                        <option value="" className="hidden">Select Level</option>
                        {leveldropdown?.map((data,index)=> (
                        <option value={data?._id} key={index}>{data?.name.toFixed(1)} </option>
                        ))}

                        </select>
                    </div>
                      <div>
                        <label htmlFor="Level" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Mixed Level<span className="text-[#f00]">&nbsp;*</span></label>
                        <select
                        id="Level"
                        required
                        value={leveldropdownmixedid ?? ""}
                        onChange={(e) => {getNewlevelmixed(e.target.value)}} 
                        name="Level"
                        className={`select input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                        <option value="" className="hidden">Select Level</option>
                        {leveldropdown?.map((data,index)=> (
                        <option value={data?._id} key={index}>{data?.name.toFixed(1)} </option>
                        ))}

                        </select>
                    </div>
                            
                    <div>
                        <label htmlFor="dob" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">DOB<span className="text-[#f00]">&nbsp;*</span></label>
                       {/* <input
 
                        name="dob" 
                        required
                        onChange={(e) => getNewdob(e.target.value)}
                        value={dob?.slice(0, 10)}
                        // placeholder="DOB"
                        maxDate={moment().subtract(500, "years")}
                        minDate={moment().subtract(18, "years")}
                        dateFormat
                        id="dob" 
                         type="date" 
                        // autoComplete="off" 
                        className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
                        /> */}
                        {/* <DatePicker
                        // onChange={(e) => getNewdob(e)}
                        placeholderText="DOB"
                        value={new Date(dob).toLocaleDateString()}
                        maxDate={new Date(Moment().subtract(18, "years"))}
                        selected={startDate}
                        onChange={(date) => {getNewdob(date);setStartDate(date)}}
                        // selected={<Moment format="DD/MM/YYYY">{dob.toString()}</Moment>}
                        showYearDropdown
                        dateFormat="Pp"
                        // dateFormat="iso"
                        scrollableYearDropdown
                        className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
                        /> */}
                          {/* <DatePicker
                            required
                            showYearDropdown
                            placeholderText="DOB"
                            onChange={(e) => getNewdob(e)}
                            value={dob}
                            selected={dob}
                            max={2005}
                            minDate={new Date("02-01-1855")}
                            maxDate={new Date("04-29-2005")}
                            dateFormatCalendar="MMMM"
                            yearDropdownItemNumber={150}
                            scrollableYearDropdown
                            className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
                          /> */}

                          <input
                      // defaultValue={signResponse?.user?.dob}
                      // {...register("dob", {onChange: (e)=> {setdobuser(e.target.value)}, required: true,
                      //   // validate:validateDate
                      //  })}
                      name="dob"
                      id="dob"
                      onChange={(e) => getNewdob(e.target.value)}
                      value={isoString}
                      autoComplete="dob"
                      placeholder="DOB"
                      max={getMaxDate()}
                      // min={"02-01-1855"}
                      // max={"02-02-2000"}
                      // max={new Date(Moment().subtract(18, "years"))}
                      // required
                      // className=" input-bordered text-[#837575] w-full rounded-lg px-6 py-3 focus:outline-none"
                      className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
                      // placeholder="DOB"
                      type="date"
                    />

                    </div>
                    <div>
                        <label htmlFor="Gender" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Gender<span className="text-[#f00]">&nbsp;*</span></label>
                        <select
                        id="Gender"
                        disabled
                        // onChange={(e) => {getNewgender(e.target.value)}} 
                        value={newgender}
                        name="Gender"
                        required
                        className={`select input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                        {/* <option value="" disabled className='hidden' >Select Gender</option> */}
                        <option value="male" >Male</option>
                        <option value="female">Female</option> 
                        </select>
                    </div>
                    <div>
                        <label htmlFor="Mobile" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Mobile<span className="text-[#f00]">&nbsp;*</span></label>
                        <Input 
                        name="Mobile" 
                        minLength={14}
                        maxLength={14}
                        required
                        onChange={getusernumber}
                        value={usermobile ? usermobile : usermobile}
                        // value={`+1${usermobile ?? ""}`}
                        defaultCountry="US"
                        placeholder="Mobile" 
                        id="Mobile" 
                        // autoComplete="off" 
                        className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
                        />
                    </div>
                    <div>
                        <label htmlFor="Country" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Country<span className="text-[#f00]">&nbsp;*</span></label>
                        <input 
                        name="Country" 
                        disabled
                        // onChange={(e) => getcountryname(e.target.value)}
                        // value={country}
                        placeholder="USA" 
                        id="Country" 
                        type="text" 
                        autoComplete="off" 
                        className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
                        />
                    </div>
                    {/* <CityDropdown city={newcityname} functionprop={getNewcityname} cityget={gethomecourtdropdown}/> */}
                    <div>
                      <label
                        htmlFor="City"
                        className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                      >
                        City<span className="text-[#f00]">&nbsp;*</span>
                      </label>
                      <select
                        required
                        onChange={(e) => {gethomecourtdropdown(e.target.value); updatedcityname(e.target.value)}}
                        value={newcityname ?? ""}
                        id="city"
                        name="city"
                        className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                      >
                        <option value={newcity?._id ?? ""} disabled className="hidden">
                          {newcity?.city_name ?? "Select City"}
                        </option>

                        {citydrop.map((data, index) => (
                          <option value={data?._id} key={index}>
                            {data?.city_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                        <label htmlFor="Homecourt" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Home Court<span className="text-[#f00]">&nbsp;*</span></label>
                        {/* <input 
                        name="Homecourt" 
                        required
                        // onChange={(e) => getcountryname(e.target.value)}
                        // value={country}
                        placeholder="Homecourt" 
                        id="Homecourt" 
                        type="text" 
                        autoComplete="off" 
                        className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
                        /> */}
                        <select
                        id="Homecourt"
                        required
                        value={homeCourt?._id}
                        onChange={(e) => {getNewhomecourt(e.target.value)}} 
                        name="Homecourt"
                        className={`select input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                        
                        {/* <option value={homeCourt._id} className="hidden">{homeCourt.name}</option> */}

                        {/* <option value={homeCourt?._id ?? ""} className="hidden capitalize">
                          {homeCourt?.name ?? "Select Home Court"} 
                        </option> */}
                        <option value="" className="hidden capitalize">
                            Select Home Court
                        </option>
                        {homecourtdrop?.map((data,index)=> (
                        <option value={data?._id} key={index}>{data?.displayName} </option>
                        ))}

                        </select>

                    </div>
                   
                    

                    <div>
                        <label htmlFor="Status" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Status<span className="text-[#f00]">&nbsp;*</span></label>
                        <select
                        id="Status"
                        value={status ?? ""}
                        required
                        onChange={(e) => {getNewstatus(e.target.value)}} 
                        name="Status"
                        className={`select input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                        <option value="" disabled className='hidden'>Status</option>
                        <option value="true">Active</option>
                        <option value="false">Inactive</option>
                        </select>
                    </div>
                </div>
                <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                        <Link to="../usermanagement" className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"><MdOutlineClose className="mr-1"/>Cancel</Link>
                        {/* {new Date(dob) >= new Date("02-29-2005") ?   <button  className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4" ><MdOutlineCheck className="mr-1"/>Update</button>:   <button  className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4" disabled><MdOutlineCheck className="mr-1"/>Update</button>} */}
                        {!addloaduser?
                        <button  className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4" ><MdOutlineCheck className="mr-1"/>Update</button>
                        :
                        <button 
                         className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                          type="button"
                        //  onClick={()=> changeStep("4")}
                        >
                        <svg
                            className="animate-spin mr-1 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            >
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                          Loading..
                        </button>
                        }
                </div>
            </form>
        </div>

        </>
    )
})