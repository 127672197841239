import React, { useEffect, useState, useRef, useMemo } from "react";
import { Input } from "react-daisyui";
import { MdOutlineCheck, MdOutlineClose } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { useCmsStore, CmsStore, CmsStoreContext } from "../Store/CmsStore";
import JoditEditor from "jodit-react";
import { useCallback } from "react";

const EditCmsManagement = observer(() => {
  const { id } = useParams();
  return (
    <CmsStoreContext.Provider
      value={
        new CmsStore({
          loadeditcitybol: false,
          editloading: true,
          id: id,
        })
      }
    >
      <Screen />
    </CmsStoreContext.Provider>
  );
});
export default EditCmsManagement;

const Screen = observer(() => {
  const [contents, setContent] = useState("");
  const placeholder = "hy raj";
  const {
    saved,
    title,
    content,
    status,
    updatedcms,
    getbytitle,
    getcontent,
    getstatus,
    loadCms,
    loadeditCms
  } = useCmsStore();
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (saved) {
      navigate("../cmsmanagement");
    }
  }, [saved]);
  const editor = useRef(null);

  const onChange = useCallback(
    (newContent) => {
      getcontent(newContent);
    },
    [content]
  );
  return (
    <>
    <div className="flex justify-between mt-[51px] ">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold">Edit Cms</p>
          <p className=" opacity-80 text-sm">Home / Cms / Edit</p>
        </div>
        <div>
          <Link
            to="../cmsmanagement"
            className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
          >
            Back
          </Link>
        </div>
      </div>
      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <form
          onSubmit={(e) => {
            updatedcms(id);
            e.preventDefault();
          }}
        >
          <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
            <div>
              <label
                htmlFor="Fees"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Title<span className="text-[#f00] ">&nbsp;*</span>
              </label>
              <Input
                name="Coupon Name"
                required
                placeholder="Title"
                onChange={(e) => getbytitle(e.target.value)}
                value={title}
                id="Coupon Name"
                type="text"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            {/* <div>
                        <label htmlFor="Fees" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Content<span className="text-red-500">&nbsp;*</span></label>
                        <Input 
                        name="Coupon Code"
                        required
                        placeholder="Coupon Code" 
                        onChange={(e) => getcontent(e.target.value)}
                        value={content}
                        id="Coupon Code" 
                        type="text" 
                        autoComplete="off" 
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div> */}

            <div>
              <label
                htmlFor="status"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Status<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <select
                name="Status"
                required
                onChange={(e) => getstatus(e.target.value)}
                value={status}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value={"true"}>Active</option>
                <option value={"false"}>Inactive </option>
              </select>
            </div>
          </div>
          <div className="grid  mt-7  md:gap-8 gap-4">
            <JoditEditor
              ref={editor}
              value={content}
              tabIndex={1}
              onChange={onChange}
            />
          </div>
          <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
            <Link
              to="../cmsmanagement"
              className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
            >
              <MdOutlineClose className="mr-1" />
              Cancel
            </Link>
            {!loadeditCms?
            <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
            <MdOutlineCheck className="mr-1" />
            Update
          </button>
           :
           <button 
            className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
             type="button"
           //  onClick={()=> changeStep("4")}
           >
           <svg
               className="animate-spin mr-1 h-5 w-5 text-white"
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               viewBox="0 0 24 24"
               >
               <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
               <path
                   className="opacity-75"
                   fill="currentColor"
                   d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
               ></path>
           </svg>
             Loading..
           </button>
           }
          </div>
        </form>
      </div>
    </>
  );
});
