import React, { useRef } from 'react'
import { Input, Textarea } from 'react-daisyui'
import { MdOutlineCheck, MdOutlineClose } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useCouponStore } from '../Store/CouponStore';
import { useState } from 'react';
import { useEffect } from 'react';
import { useAuthStore } from '../../Auth/store/Auth';

const AddCoupenManagement = observer(() => {

  const navigate = useNavigate();
  const { adminAuthApproved } = useAuthStore();
  const [startdatestate, setstartdatestate] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showList, setShowList] = useState(false);
  const [isListVisible, setListVisibility] = useState(false);
  const [value, setValue] = useState("Select option...");
  const [categoryvalue, setcategoryvalue] = useState("Select option...");
  const [check, setCheck] = useState(false);
  const {
    getname,
    setper_person_limit,
    setmax_offer_amt,
    setmin_eligible_amt,
    settotal_use_limit,
    getcode,
    getdiscount,
    getdiscount_type,
    getstartdate,
    getenddate,
    getstatus,
    addCoupon,
    getToken,
    getAllCategoriesList,
    setparentId,
    discount_type,
    All_categories,
    discount,
    loadCoupon,
    saved,
  } = useCouponStore();

  useEffect(() => {
    if (adminAuthApproved) {
      getToken(localStorage.getItem("Admintoken"));
    }
  }, [adminAuthApproved]);

  useEffect(() => {
    if (saved) {
      navigate("../coupenmanagement");
    }
  }, [saved]);
  useEffect(() => {
    getAllCategoriesList();
  }, []);

  const handleDiscountChange = (e) => {
    const inputValue = e.target.value.replace(/\./g, '');
    if (discount_type == "percent") {
      if (!isNaN(inputValue) && inputValue >= 0 && inputValue <= 100) {
        // Update the state only if the input is a valid number within the desired range
        getdiscount(inputValue?.toString());
      }
    }
    else {
      if (!isNaN(inputValue) && inputValue >= 0) {
        // Update the state only if the input is a valid number within the desired range
        getdiscount(inputValue);
      }

    }

  };
  const [minDate, setMinDate] = useState('');

  useEffect(() => {
    // Get the current date and time in the New York time zone
    const now = new Date();
    const newYorkTimeZone = 'America/New_York';
    const nyNow = new Date(now.toLocaleString('en-US', { timeZone: newYorkTimeZone }));

    // Format the date in ISO format (YYYY-MM-DD)
    const minDate = nyNow.toISOString().split('T')[0];
    setMinDate(minDate);
  }, []);

  const SearchableDropdown = () => {
    const [query, setQuery] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [HideShow, setHideShow] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        document.addEventListener("click", toggle);
        return () => document.removeEventListener("click", toggle);
    }, []);

    const selectOption = (option) => {
        setQuery(() => "");
        setValue(option['name'] || option);
        setCheck(true);
        setcategoryvalue(`${">".repeat(option.level || 1)} ${option["name"] || option}`
        );
        setparentId(option?.id || null)
        setIsOpen((isOpen) => !isOpen);
    };

    function toggle(e) {
        setIsOpen(e && e.target === inputRef.current);
    }


    const getDisplayValue = () => {
        if (query) return query;
        if (value) return value;

        return "";
    };

    const filter = (options) => {
        return options.filter(
            (option) => option['name'].toLowerCase().indexOf(query.toLowerCase()) > -1
        );
    };
    return (
        <div className="dropdowncategory">
            <div className="control">
                <div className="selected-value">
                    <div
                        onClick={() => setHideShow(!HideShow)}

                        className=" p-3 border-[1px] border-[#e5e7eb] focus:outline-0 w-full text-sm font-medium rounded-md">
                        {categoryvalue}
                    </div>

                    {HideShow &&
                        <div class="relative">
                            <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                            <input
                                ref={inputRef}
                                class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                type="text"
                                placeholder="Search..."
                                // value={getDisplayValue()}
                                name="searchTerm"
                                onChange={(e) => {
                                    setQuery(e.target.value);
                                    // setValue(null);
                                }}
                                onClick={toggle}
                            />
                        </div>


                    }


                </div>

                <div className={`arrow ${isOpen ? "open" : ""}`}></div>
            </div>

            <div className={`options ${isOpen ? "open" : ""}`}>


                <div
                    onClick={() => selectOption("Root Categories")}
                    className={`option selected`}

                >
                    {`${">"} Root Categories`}

                </div>

                {filter(All_categories).map((option, index) => (
                    <div
                        onClick={() => selectOption(option)}
                        className={`option ${option["name"] === value ? "selected" : ""}`}
                        key={`${"id"}-${index}`}
                    >
                        {`${">".repeat(option.level)} ${option["name"]}`}
                    </div>
                ))}
            </div>
        </div>
    );
}






  return (
    <>
      <div className='flex justify-between'>
        <div className=" mb-4">
          <p className=" text-2xl font-semibold">Add Promo Code</p>
          <p className=" opacity-80 text-sm">Home / Promo Code / Add</p>
        </div>
        <div>
          <Link to="../coupenmanagement" onClick={() => { getdiscount(""); getdiscount_type("") }} className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]">Back</Link>
        </div>
      </div>
      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <form onSubmit={(e) => { addCoupon(); e.preventDefault() }}>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">


            <div>
              <label htmlFor="Fees" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Promo Code Name<span className="text-[#f00]">&nbsp;*</span></label>
              <Input
                name="Coupon Name"
                required
                placeholder="Promo Code Name"
                onChange={(e) => getname(e.target.value)}
                id="Coupon Name"
                maxLength={15}
                type="text"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            <div>
              <label htmlFor="Fees" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Description<span className="text-[#f00]">&nbsp;*</span></label>
              <Textarea
                name="Description"
                required
                placeholder="description....."
                onChange={(e) => getcode(e.target.value)}
                id="Description"
                type="text"
                rows={1}
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            <div>
              <label
                htmlFor="Categoryidentifier"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Product Sub Category<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <SearchableDropdown/>



            </div>
            <div>
              <label htmlFor="status" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Discount Type<span className="text-[#f00]">&nbsp;*</span></label>
              <select
                name="Discount Type"
                defaultValue=""
                required
                onClick={() =>
                  getdiscount("")
                }
                onChange={(e) => getdiscount_type(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                <option value="" disabled className="hidden">
                  Select Discount Type
                </option>
                <option value={'amount'}>Amount</option>
                <option value={'percent'}>Percentage</option>
              </select>
            </div>

            <div>
              <label htmlFor="Fees" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Discount<span className="text-[#f00]">&nbsp;*</span></label>
              <Input
                name="Discount"
                disabled={discount_type == ""}
                required
                placeholder="Discount"
                value={discount}
                onChange={handleDiscountChange}
                id="Discount"
                type="number"
                step="1"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            <div>
              <label htmlFor="Fees" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Total Use Limit<span className="text-[#f00]">&nbsp;*</span></label>
              <Input
                name="limit"
                required
                placeholder="Total Use Limit"
                onChange={(e) => settotal_use_limit(e.target.value)}
                min={0}
                id="limit"
                type="number"
                step="1"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            <div>
              <label htmlFor="Fees" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Total Person Limit<span className="text-[#f00]">&nbsp;*</span></label>
              <Input
                name="limit"
                required
                min={0}
                placeholder="Total Person Limit"
                onChange={(e) => setper_person_limit(e.target.value)}
                id="limit"
                type="number"
                step="1"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            <div>
              <label htmlFor="Fees" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Maximum Discounted Amount<span className="text-[#f00]">&nbsp;*</span></label>
              <Input
                name="Maximum"
                required
                placeholder="value"
                onChange={(e) => setmax_offer_amt(e.target.value)}
                id="Maximum"
                type="number"
                step="1"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            <div>
              <label htmlFor="Fees" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Minimum Eligible Amount<span className="text-[#f00]">&nbsp;*</span></label>
              <Input
                name="Minimum"
                required
                placeholder="value"
                onChange={(e) => setmin_eligible_amt(e.target.value)}
                id="Minimum"
                type="number"
                step="1"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            <div>
              <label htmlFor="startdate" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Start Date<span className="text-[#f00]">&nbsp;*</span></label>
              <Input
                name="startdate"
                placeholder=""
                onChange={(e) => { getstartdate(e.target.value); setstartdatestate(e.target.value) }}
                id="startdate"
                min={minDate}
                type="date"
                autoComplete="off"
                required
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>

            <div>
              <label htmlFor="enddate" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">End Date<span className="text-[#f00]">&nbsp;*</span></label>
              <Input
                name="enddate"
                placeholder=""
                onChange={(e) => getenddate(e.target.value)}
                disabled={startdatestate === null ? true : false}
                min={startdatestate ? startdatestate : minDate}
                id="enddate"
                type="date"
                autoComplete="off"
                required
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>


            <div>
              <label htmlFor="status" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Status<span className="text-[#f00]">&nbsp;*</span></label>
              <select
                name="Status"
                required
                onChange={(e) => getstatus(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                <option value="" className="hidden">
                  Select Status
                </option>
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
            </div>
          </div>
          <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
            <Link to="../coupenmanagement" onClick={() => { getdiscount(""); getdiscount_type("") }} className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"><MdOutlineClose className="mr-1" />Cancel</Link>
            {!loadCoupon ?
              <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"><MdOutlineCheck className="mr-1" />Add Promo Code</button>
              :
              <button
                className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                type="button"
              //  onClick={()=> changeStep("4")}
              >
                <svg
                  className="animate-spin mr-1 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Loading..
              </button>
            }
          </div>
        </form>
      </div>
    </>
  )
});

export default AddCoupenManagement