import { createContext, useContext } from "react";
import { makeAutoObservable, observable, runInAction } from "mobx";
import {AuthStore} from "../../Auth/store/Auth"

import {
  postMethodData,
  getMethodData,
  putMethod,
  delMethod,
  patchMethodData,
} from "../../ApiMethods/api";
import { toast } from "react-toastify";

export class NotificationsStore {
  Token = null;
  nolist = false;
  saved = false;
  loadNotifications = false;
  errNotifications = "";
  Notificationlist = [];
  

  constructor(isloading = false) {
    makeAutoObservable(this);
    this.Token = localStorage.getItem("Admintoken");
    if (isloading.isloading) {
        this.getNotificationList()
      return
    }
  }


  // get Notification List
  getNotificationList = () => {
    runInAction(() => {
      this.loadNotifications = true;
      this.errNotifications = "";
      this.saved = false;
    });
    getMethodData("/api/admin/notifications", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadNotifications = false;
            this.errNotifications = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
            
          runInAction(() => {
            this.loadNotifications = false;
            this.errNotifications = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.Notificationlist = resp?.data?.data;
               this.saved = false;
          });
          const authnotification = new AuthStore()
          authnotification.getNotificationCount()
          return;
        }
        runInAction(() => {
          this.loadNotifications = false;
          this.errNotifications = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadNotifications = false;
          if (!error.response) {
            this.errNotifications = error?.message;
            toast.error(error?.message);
          }
          this.errNotifications = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };

//   clear Notification list

ClearNotificationList = () => {
    runInAction(() => {
      this.loadNotifications = true;
      this.errNotifications = "";
      this.saved = false;
    });
    delMethod("/api/admin/notifications", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadNotifications = false;
            this.errNotifications = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadNotifications = false;
            this.errNotifications = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.Notificationlist = resp?.data?.data;
               this.saved = false;
              //  const authnotification = new AuthStore()
              //  authnotification.getNotificationCount()
          });
          
          return;
        }
        runInAction(() => {
          this.loadNotifications = false;
          this.errNotifications = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadNotifications = false;
          if (!error.response) {
            this.errNotifications = error?.message;
            toast.error(error?.message);
          }
          this.errNotifications = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };

}

export const NotificationsStoreContext = createContext(new NotificationsStore());
export const useNotificationsStore = () => useContext(NotificationsStoreContext);
