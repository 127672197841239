import { createContext, useContext } from "react";
import { makeAutoObservable, observable, runInAction } from "mobx";
import { format } from "date-fns";
import {
  postMethodData,
  getMethodData,
  putMethod,
  delMethod,
  patchMethodData,
} from "../../ApiMethods/api";
import { toast } from "react-toastify";
import Moment from "react-moment";

export class DivisionStore {
  Token = null;
  // League_Id= null;
  ForfitCheck="";
  ForfitCheck1=""
  WithdrawCheck="";
  WithdrawCheck1="";
  ScoreCheck ="";
  myforfietvalue="";
  matchdate="";
  StateCheck="";
  matchtime="";
  homecourtdrop=[];
  matchid="";
  mysetone = null;
  mysettwo = null;
  mysetthree = null;
  oppsetone = null;
  oppsetwo = null;
  oppsetthree = null;
  openwithdrawpopup=false;
  voidpopup = false;
  opensubmit=false;
  openforfietpopup= false;
  buttondisable = false;
  newpop = false;
  ThirdScoreCheck=true;
  nolist = false;
  nolistmatch =false;
  nolisttie=false
  ScoreObject = [];
  EditScore = false;
  Dname = "";
  metchid = "";
  Scorehomecourt = "";
  city = "";
  citydrop = [];
  playerlist = [];
  Leaguesdropdown = [];
  HomeName = [];
  leaguename = "";
  homecourt = "";
  loadDivision = false;
  errloadDivision = false;
  DivisionList = [];
  saved = false;
  loadMatch=false;
  loadTie=false;
  errloadMatch=false;
  Matchlist=[];
  division_ranks=[];
  matchdetails = {};
  Tielist=[];
  teamOne = "";
  editloadtie = false;
  erredittie = "";
  voidloder=false;
  divisionid="";
  leaguetieid="";
  levelid="";
  playerid="";
  score="";
  level=""
 
  id=""
  leagueid=""

 
  
  constructor(isloading = false) {
    makeAutoObservable(this);
    this.Token = localStorage.getItem("Admintoken");
    if(isloading.isloading){
      this.getDivisionList();
    }
    if(isloading.id){
      this.getFilterDivision(isloading.id)
    }
    if (isloading.playerlist) {
      this.getUserhomecourtdropdown();
      this.getLeaguesdropdown();
      return;
    }
    if (isloading.tielist) {
     this.getTieList(isloading.id,isloading.leagueid)
     this.id=isloading.id;
     this.leagueid=isloading.leagueid;
     return
   }
  }

  //   // status popup
  //   setstatuspopup = () => {
  //     this.statuspopup = !this.statuspopup;
  //   };
  //All clear
  allclear = () => {
    this.Dname = "";
    this.leaguename = "";
    this.homecourt = "";
    this.level = "";
  };

  // leagueClear=()=>{
  //     this.League_Id = null;
  // }

  setScoreObject = (e) => {
    this.ScoreObject = e;
  };
  getlevel = (text) => {
    this.level = text;
  };
  getmatchdate = (e) => {
    this.matchdate = e;
  };
  SetStateCheck=(e)=>{
    this.StateCheck=e;
  }
  setThirdScoreCheck=(e)=>{
    this.ThirdScoreCheck = e
  }
  getmatchtime=(e) => {
    this.matchtime=e;
  }
  setmyone = (e) => {
    this.mysetone = parseInt(e);
  };
  setmytwo = (e) => {
    this.mysettwo = parseInt(e);
    
  };
  setmatchid=(e)=>{
    this.matchid=e;
  }
  setmythree = (e) => {
    this.mysetthree = parseInt(e);
   
  };
  setoppone = (e) => {
    this.oppsetone = parseInt(e);
    
  };
  setopptwo = (e) => {
    this.oppsetwo =parseInt(e);

  };
  setoppthree = (e) => {
    this.oppsetthree = parseInt(e);
    
  };
  getbuttondisable = (e) => {
    this.buttondisable = parseInt(e);
  };
  setoppforfietvalue = (e,check) => {
    this.oppforfietvalue = e;
    this.ForfitCheck1 = check;

   
  };
  getDivisionname = (text) => {
    this.Dname = text;
  };
  setteamOne = (text) => {
    this.teamOne = text;
  };
  setteamTwo = (text) => {
    this.teamTwo = text;
  };

  SetEditScore = () => {
    this.EditScore = !this.EditScore;
  };
  SetScorehomecourt = (text) => {
    this.Scorehomecourt = text;
  };
  gethomecourt = (text) => {
    this.homecourt = text;
  };
  getleague = (text) => {
    this.leaguename = text;
    
  };

getToken=(e)=>{
this.Token=e;
}
//get division id
getDivisionid=(text)=>{
  this.divisionid= text;
   }

   //get league id
getLeagueid=(text)=>{
  this.leaguetieid= text;
   }
     //get level id
getLevelid=(text)=>{
  this.levelid= text;
   }
       //get player id
getPlayerid=(text)=>{
  this.playerid= text;
   }
     //get score
     getScore=(text)=>{
      this.score= text;
       }

  getmatchid = (e) => {
    this.metchid = e;
  };
  setnewpopup = (e) => {
    this.newpop = e;
  };
  setopenwithdrawpopup = (e) => {
    this.openwithdrawpopup = e;
  };
  setopenVoidpopup = (e) => {
    this.voidpopup = e;
  };
  setopensubmit = (e) => {
    this.opensubmit = e;
  };
  setopenforfietpopup = (e) => {
    this.openforfietpopup = e;
  };
  // get Division list
  getDivisionList = () => {
    runInAction(() => {
      this.loadDivision = true;
      this.nolist = false;
      this.errloadDivision = "";
      this.saved = false;
      this.DivisionList = [];
    });
    getMethodData("/api/admin/division", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadDivision = false;
            this.errloadDivision = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadDivision = false;
            this.errloadDivision = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.DivisionList = resp?.data?.data;
            this.HomeName = resp?.data?.data?.home_courts;
            this.saved = false;
            this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.addleague = false;
          this.loadDivision = false;
          this.errloadDivision = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadDivision = false;
          if (!error.response) {
            this.errloadDivision = error?.message;
            toast.error(error?.message);
          }
          this.errloadDivision = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };

     // get League list
 getLeagueList = () => {
  runInAction(() => {
    this.loadleague = true;
    this.errloadleague = "";
    this.saved = false;
  });
  getMethodData(`/api/admin/league`, this.Token)
    .then((resp) => {
      if (resp?.data?.status === false) {
        runInAction(() => {
          this.loadleague = false;
          this.errloadhomecourt = resp?.data?.message;
        });
        return;
      }
      if (resp?.data?.status === true) {
        runInAction(() => {
          this.loadleague = false;
          this.errloadhomecourt = resp?.data?.message;
          this.leaguename=resp?.data?.data;
          // this.League_Id =resp?.data?.data?._id;
          // this.getFilterDivision();
        });
        return;
      }
      runInAction(() => {
        this.addleague = false;
        this.loadleague = false;
        this.errloadleague = resp?.data?.message;
      });
    })
    .catch((error) => {
      runInAction(() => {
        this.loadSeason = false;
        if (!error.response) {
          this.errloadseason = error?.message;
          toast.error(error?.message);
        }
        this.errloadseason = error?.response?.data?.message;
        toast.error(error?.response?.data?.message);
      });
      return;
    });
};



  // get Player list
  getPlayerList = (id) => {
    runInAction(() => {
      this.loadDivision = true;
      this.nolist = false;
      this.errloadDivision = "";
      this.saved = false;
      this.playerlist = []
    });
    getMethodData(`/api/admin/division?filters[division]=${id}`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadDivision = false;
            this.errloadDivision = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadDivision = false;
            this.errloadDivision = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.playerlist = resp.data.data;
            this.HomeName = resp?.data?.data?.home_courts;
            this.saved = false;
            this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.addleague = false;
          this.loadDivision = false;
          this.errloadDivision = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadDivision = false;
          if (!error.response) {
            this.errloadDivision = error?.message;
            toast.error(error?.message);
          }
          this.errloadDivision = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };

  setradiovalue = (e) => {
    if (e === undefined) {
    this.radiovalue = "score";
    this.forfietvalue = "";
    this.ForfitCheck="";
    this.ForfitCheck1= undefined;
    this.WithdrawCheck="";
    this.WithdrawCheck1=undefined;
    this.withdrawvalue = "";

    this.myforfietvalue = "";
    this.oppforfietvalue = "";

    this.mywithdrawvalue = "";
    this.oppwithdrawvalue = "";

    }
    else{
      this.radiovalue = e;
      this.forfietvalue = "";
      this.withdrawvalue = "";
      this.ForfitCheck="";
      this.ForfitCheck1="";
      this.WithdrawCheck="";
      this.WithdrawCheck1="";
      this.myforfietvalue = "";
      this.oppforfietvalue = "";
  
      this.mywithdrawvalue = "";
      this.oppwithdrawvalue = "";
    }
   

   
  };
  
  setforfietvalue = (e) => {
    if (e === undefined) {
      this.forfietvalue = "Forfieted";
      this.radiovalue = "";
      this.withdrawvalue = "";
      this.WithdrawCheck="";
      this.WithdrawCheck1="";
      this.mysetone = "";
      this.mysettwo = "";
      this.mysetthree = NaN;
      this.oppsetone = "";
      this.oppsetwo = "";
      this.oppsetthree = NaN;

      this.mywithdrawvalue = "";
      this.oppwithdrawvalue = "";
    }
    else{
      this.forfietvalue = e;
      this.radiovalue = "";
      this.withdrawvalue = "";
      this.WithdrawCheck="";
      this.WithdrawCheck1="";
      this.mysetone = "";
      this.mysettwo = "";
      this.mysetthree = NaN;
      this.oppsetone = "";
      this.oppsetwo = "";
      this.oppsetthree = NaN;
  
      this.mywithdrawvalue = "";
      this.oppwithdrawvalue = "";
    }
  

    // this.myteamid = null;
    // this.oppteamid = null;
    
  };
  setwithdrawvalue = (e) => {
    if(e === undefined){
      this.withdrawvalue = "withdrew";
      this.ForfitCheck="";
      this.ForfitCheck1="";
      this.forfietvalue = "";
      this.mysetone = "";
      this.mysettwo = "";
      this.mysetthree = NaN;
      this.oppsetone = "";
      this.oppsetwo = "";
      this.oppsetthree = NaN;

  
      this.myforfietvalue = "";
      this.oppforfietvalue = "";
    }
    else{
      this.withdrawvalue = e;
      this.forfietvalue = "";
      this.ForfitCheck="";
      this.ForfitCheck1="";
      this.mysetone = "";
      this.mysettwo = "";
      this.mysetthree = NaN;
      this.oppsetone = "";
      this.oppsetwo = "";
      this.oppsetthree = NaN;

  
      this.myforfietvalue = "";
      this.oppforfietvalue = "";
    }
   

    // this.myteamid = null;
    // this.oppteamid = null;
    
  };
  setmyforfietvalue = (e,check) => {
    this.myforfietvalue = e;
    this.ForfitCheck = check;
  };
  setmywithdrawvalue = (e,check) => {
    this.mywithdrawvalue = e;
    this.WithdrawCheck = check;
  
  };
  setoppwithdrawvalue = (e,check) => {
    this.oppwithdrawvalue = e;
    this.WithdrawCheck1 = check;

    
  };
  // filter Player

  getFilterDivision = (leaugeidddd) => {
    const newvar = leaugeidddd ? leaugeidddd : "";
    runInAction(() => {
      this.loadDivision = true;
      this.nolist = false;
      this.errloadDivision = "";
      this.saved = false;
      this.DivisionList=[];
    });

    getMethodData(
      `/api/admin/division?filters[name]=${this.Dname}&filters[level]=${this.level}&filters[league]=${this.leaguename || newvar }&filters[home_court]=${this.homecourt}`,
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadDivision = false;
            this.errloadDivision = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadDivision = false;
            this.errloadDivision = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.DivisionList = resp?.data?.data;
            this.saved = false;
            this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadDivision = false;
          this.errloadDivision = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadDivision = false;
          if (!error.response) {
            this.errloadDivision = error?.message;
            toast.error(error?.message);
          }
          this.errloadDivision = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };
  //      // city drop down
  //      getcitydropdown =() =>{
  //       runInAction(() => {
  //        this.loadPlayer = true;
  //        this.errloadPlayer = "";
  //        // this.saved = false;
  //       });
  //      getMethodData("/api/get/cities", this.Token)
  //      .then((resp) => {
  //        if (resp?.data?.status === false) {
  //          runInAction(() => {
  //            this.loadPlayer = false;
  //            this.errloadPlayer = resp?.data?.message
  //          });
  //          return;
  //        }
  //        if (resp?.data?.status === true) {
  //          runInAction(() => {
  //            this.loadPlayer = false;
  //            this.errloadPlayer = resp?.data?.message;
  //            // toast.success(resp?.data?.message);
  //            this.citydrop = resp?.data?.data;

  //          });
  //          return;
  //        }
  //        runInAction(() => {
  //          this.loadPlayer = false;
  //          this.errloadPlayer = resp?.data?.message;
  //        });
  //      })
  //      .catch((error) => {
  //        runInAction(() => {
  //            this.loadPlayer = false;
  //            if(!error.response){
  //                this.errloadPlayer = error?.message;
  //                // toast.error(error?.message);
  //            }
  //            this.errloadPlayer = error?.response?.data?.message;
  //            // toast.error(error?.response?.data?.message);
  //        })
  //        return;
  //    });
  //    }
  getUserhomecourtdropdown = () => {
    runInAction(() => {
      // this.loaduser = true;
      this.errloaduser = "";
      this.saved = false;
    });
    getMethodData(`/api/get/home-courts`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            // toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            // toast.success(resp?.data?.message);
            this.homecourtdrop = resp?.data?.data;
          });
          return;
        }
        runInAction(() => {
          this.loaduser = false;
          this.errloaduser = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loaduser = false;
          if (!error.response) {
            this.errloaduser = error?.message;
            // toast.error(error?.message);
          }
          this.errloaduser = error?.response?.data?.message;
          // toast.error(error?.response?.data?.message);
        });
        return;
      });
  };
  // Level drop down
  getLeaguesdropdown = () => {
    runInAction(() => {
      this.errloadDivision = "";
      // this.saved = false;
    });
    getMethodData("/api/get/leagues", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.errloadDivision = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.errloadDivision = resp?.data?.message;
            // toast.success(resp?.data?.message);
            this.Leaguesdropdown = resp?.data?.data;
          });
          return;
        }
        runInAction(() => {
          this.errloadDivision = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.errloadDivision = error?.message;
            // toast.error(error?.message);
          }
          this.errloadDivision = error?.response?.data?.message;
          // toast.error(error?.response?.data?.message);
        });
        return;
      });
  };
  // get Matchs list
  getMatchList = (id) => {
    runInAction(() => {
      this.loadMatch = true;
      this.nolistmatch = false;
      this.errloadMatch = "";
      this.saved = false;
      this.Matchlist = [];

    });
    getMethodData(
      `/api/admin/division/get-matches?filters[division]=${id}`,
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadMatch = true;
            this.errloadMatch = resp?.data?.message;
            this.nolistmatch = false;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadMatch = false;
            this.errloadMatch = resp?.data?.message;
            if (resp?.data?.data.matches.length == 0) {
              this.nolistmatch = true;
            } else {
              this.nolistmatch = false;
            }
            this.Matchlist = resp.data.data.matches;
            this.matchdetails = resp.data.data.matches;
            this.division_ranks = resp.data.data?.division_ranks;
            // this.HomeName =  resp?.data?.data?.home_courts;
            // this.saved = false;
            this.status = true;
          });
          return;
        }
        runInAction(() => {
          // this.addleague = false;
          this.loadMatch = false;
          this.errloadMatch = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadMatch = false;
          if (!error.response) {
            this.errloadMatch = error?.message;
            toast.error(error?.message);
          }
          this.errloadMatch = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };

    // get tie list
    getTieList = (id,leagueid) => {
      runInAction(() => {
        this.loadTie = true;
        this.nolisttie=false;
        this.errloadMatch = "";
        // this.saved = false;
      });
      getMethodData(`/api/admin/tie/players?filters[league]=${leagueid}&filters[division]=${id}`, this.Token)
        .then((resp) => {
          if (resp?.data?.status === false) {
            runInAction(() => {
              this.loadTie = false;
              this.errloadMatch = resp?.data?.message;
              this.nolisttie=true;
  
            });
            return;
          }
          if (resp?.data?.status === true) {
            runInAction(() => {
              this.loadTie = false;
              this.errloadMatch = resp?.data?.message;
              this.Tielist = resp?.data?.data;
            
              // this.divisionid = resp?.data?.data;
              if (resp?.data?.data.length === 0) {
                this.nolisttie = true;
              } else {
                this.nolisttie = false;
              }
              
              // this.division_ranks=  resp.data.data?.division_ranks;
              // this.HomeName =  resp?.data?.data?.home_courts;
              // this.saved = false;
              // this.status = true;
            });
            return;
          }
          runInAction(() => {
            // this.addleague = false;
            this.loadTie = false;
            this.errloadMatch = resp?.data?.message;
          });
        })
        .catch((error) => {
          runInAction(() => {
            this.loadTie = false;
            if (!error.response) {
              this.errloadMatch = error?.message;
              toast.error(error?.message);
            }
            this.errloadMatch = error?.response?.data?.message;
            toast.error(error?.response?.data?.message);
          });
          return;
        });
    };
    //update tie method
updatetie = (data) => {
  runInAction(() => {
    this.editloadtie = true;
    this.erredittie = "";
  });

  putMethod(`/api/admin/tie/players/${data?.id}`,
    data,
    this.Token
  )
    .then((resp) => {
      if (resp?.data?.status === false) {
        runInAction(() => {
          this.editloadtie = false;
          this.erredittie = resp?.data?.message;
          toast.error(resp.data.message);
        });
        return;
      }
      if (resp?.data?.status === true) {
        runInAction(() => {
            this.editloadtie = false;
            this.erredittie = resp?.data?.message;
            this.getTieList(this.id,this.leagueid);
            toast.success(resp.data.message);
            this.saved = true;
          })
          return
      }
      runInAction(() => {
        this.editloadtie = false;
        this.erredittie = resp?.data?.message;
        toast.error(resp.data.message);
      });
    })
    .catch((error) => {
      runInAction(() => {
          if(!error.response){
            this.editloadtie = false;
            this.erredittie = error?.message;
            toast.error(error?.message);
          }
            toast.error(error?.response?.data?.message);
            this.editloadtie = false;
            this.erredittie = error?.response?.data?.message;
      })
      return;
  })
};

//Void API
VoidScore = (id,divisionID) => {
  runInAction(() => {
    this.voidloder = true;
  });
  putMethod(`/api/match/status/update/${id}`,
  {
    status: "void"
  },
    this.Token
  )
    .then((resp) => {
      if (resp?.data?.status === false) {
        runInAction(() => {
          this.voidloder = true;
         this.voidpopup=true;

          toast.error(resp.data.message);
        });
        return;
      }
      if (resp?.data?.status === true) {
        runInAction(() => {
          this.voidloder = false;
         this.voidpopup=false
            toast.success(resp.data.message);
            this.getMatchList(divisionID);
            this.saved = true;
          })
          return
      }
      runInAction(() => {
        this.voidloder = false;
        toast.error(resp.data.message);
      });
    })
    .catch((error) => {
      runInAction(() => {
          if(!error.response){
            this.voidloder = false;
           this.voidpopup=true;
            toast.error(error?.message);
          }
            toast.error(error?.response?.data?.message);
            this.voidloder = false;
      })
      return;
  })
};


   

  //Update Score
  PostMatchscore = async (id) => {
    runInAction(() => {
      this.errloadDivision = "";
      this.buttondisable = true;
      this.notificationNo = false;
    });
    await postMethodData(
      "/api/admin/save-match-score",
      {
        match: this.metchid,
        home_court: this.Scorehomecourt._id,
        match_date: this.matchdate,
        match_time: this.matchdate,
        result: this.radiovalue || this.forfietvalue || this.withdrawvalue,
        forfiet_by: this.myforfietvalue || this.oppforfietvalue,
        Withdraw_by: this.mywithdrawvalue || this.oppwithdrawvalue,
        team_first_score: {
          team: this.teamOne,
          set_one: parseInt(this.mysetone),
          set_two: parseInt(this.mysettwo),
          set_three: parseInt(this.mysetthree),
        },
        team_second_score: {
          team: this.teamTwo,
          set_one: parseInt(this.oppsetone),
          set_two: parseInt(this.oppsetwo),
          set_three: parseInt(this.oppsetthree),
        },
      },
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.notificationNo = false;
            this.buttondisable = false;
            this.addloaduser = false;
            this.erradduser = resp?.data?.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.notificationNo = true;
            this.buttondisable = false;
            this.addloaduser = false;
            this.erradduser = resp?.data?.message;
            this.getMatchList(id);
            toast.success(resp.data.message);
            this.saved = true;
            this.newpop = false;
            this.opensubmit = false;
            this.openforfietpopup = false;
            this.openwithdrawpopup = false;
          });
          return;
        }
        runInAction(() => {
          this.addloaduser = false;
          this.erradduser = resp?.data?.message;
          if(resp)
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        if(error.response){
          toast.error( error.response.data.message);
          this.addloaduser = false;
          this.buttondisable = false;
  
        }
      
        runInAction(() => {
          if (!error.response) {
            this.addloaduser = false;
            this.erradduser = error?.message;
          }
          this.addloaduser = false;
          this.erradduser = error?.response?.data?.message;
        });
        return;
      });
  };

  


}

export const DivisionStoreContext = createContext(new DivisionStore());
export const useDivisionStore = () => useContext(DivisionStoreContext);
