import React, { useEffect, useState } from "react";
import { Input } from "react-daisyui";
import { MdOutlineRefresh, MdOutlineSearch } from "react-icons/md";

import { observer } from "mobx-react";
import { usePlayerReasonStore } from "../store/PlayerReasonStore";
import { useLeagueStore } from "../../LeagueManagement/store/LeagueStore";
import { useParams } from "react-router-dom";

// const Filter = (props) => {
//   return (
//       <Screen id={props.league} />
//   );
// };

const Filter = observer((props) => {
  const {
    citydrop,
    homecourtdrop,
    leaguesdropdown,
    leaguename,
    level,
    League_Id,
    getlevel,
    getcity,
    getleague,
    gethomecourt,
    getfirstname,
    getPlayer,
    getlastname,
    getFilterPlayercourt,
    getPlayerList,
    allclear,
    leagueClear,
    getIsrefunded
  } = usePlayerReasonStore();
//   useEffect(() => {
//     if (props.league) {
//       getPlayer(props.league);
//     }
//   }, []);
const { id } = useParams();
  const {
    leveldropdown,
    getleveldropdown
  } = useLeagueStore();

  useEffect(() => {
    getleveldropdown()
  },[])
  useEffect(() => {
    if(id){
      getFilterPlayercourt(id)
      getleague(id)
    }
  },[id])

  return (
    <>
      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <form
          onSubmit={(e) => {
            getFilterPlayercourt();
            e.preventDefault();
          }}
        >
          <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
            {/* <div>
              <label
                htmlFor="firstName"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                First Name
              </label>
              <Input
                name="FirstName"
                placeholder="first Name"
                // value={leaguename}
                // onChange={(e) => getfirstname(e.target.value)}
                id="firstName"
                type="text"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            <div>
              <label
                htmlFor="lastName"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Last Name
              </label>
              <Input
                name="lastName"
                placeholder="Last Name"
                // value={leaguename}
                // onChange={(e) => getlastname(e.target.value)}
                id="lastName"
                type="text"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div> */}
            <div>
              <label
                htmlFor="LeagueName"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                League Name
              </label>
              <select
                id="LeagueName"
                name="LeagueName"
                // onClick={leagueClear}
                value={leaguename}
                // value={leaguename ? leaguename._id : ""} // Set the value to the ID of leaguename if it exists
                 onChange={(e) => getleague(e.target.value)}
                className={`select capitalize focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
               <option value="" className="">
                  All
                </option>

                {leaguesdropdown?.map((data, index) => (
                  <option value={data._id} key={index}>
                    {data.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
            <label
                htmlFor="level"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Level
              </label>
              <select
                   onChange={(e) => getlevel(e.target.value)}
                //    value={level}
                  className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                  // aria-label="Default select example"
                >  
                 <option value=""> Select Level</option>
                {leveldropdown?.map((data,index)=> (
                        <option value={data?._id} key={index}>{data?.name.toFixed(1)} </option>
                        ))}
                  
                </select>
            </div>
            

            {/* <div>
              <label
                htmlFor="FeesAmount"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Fees Amount
              </label>
              <Input
                name="FeesAmount"
                placeholder="Fees Amount"
                id="FeesAmount"
                type="number"
                onChange={(e) => addfeesamnt(e.target.value)}
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div> */}
            {/* <div>
              <label
                htmlFor="lastdate"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Last Registration Date
              
              </label>
              <Input
                name="lastdate"
                placeholder=""
                onChange={(e) => addlastregesteration(e.target.value)}
                id="lastdate"
                type="date"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div> */}

            <div>
              <label
                htmlFor="status"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Status
              </label>
              <select
                name="Status"
                defaultValue=""
                 onChange={(e) => getIsrefunded(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="">All</option>
                <option value={"true"}>Paid</option>
                <option value={"false"}>Due</option>
              </select>
            </div>
          </div>
          <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
            <button
              type="reset"
              onClick={() => {
                 allclear();
                getPlayerList();
                props.reset(false);
                props.email([]);
              }}
              className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
            >
              <MdOutlineRefresh className="mr-1" />
              Reset
            </button>
            <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
              <MdOutlineSearch className="mr-1" />
              Search
            </button>
          </div>
        </form>
      </div>
    </>
  );
});
export default Filter;
