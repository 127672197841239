import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineRefresh, MdOutlineSearch } from "react-icons/md";
import { Input } from "react-daisyui";
import { observer } from "mobx-react";
import {
  StateStore,
  useStateStore,
  StateStoreContext,
} from "../StateStore/StateStore";
const Filter = observer(() => {
  const {
    addstate,
    getstatus,
    getFilterdstatecourt,
    allclear,
    dropdownLevel,
    getStateList,
  } = useStateStore();
  return (
    <>
      <div className="card bg-base-100 shadow-md p-6 mt-3 mb-10">
        <form
          onSubmit={(e) => {
            getFilterdstatecourt();
            e.preventDefault();
          }}
        >
          <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
            <div>
              <label
                htmlFor="state"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                State
              </label>
              <select
                id="state"
                onChange={(e) => addstate(e.target.value)}
                name="state"
                className={`select capitalize focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="">All</option>
                {dropdownLevel.map((data, index) => (
                  <option key={index} value={data?.name}>
                    {data?.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="Country"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Country
              </label>
              <select
                id="Country"
                name="Country"
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="USA">USA</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="Status"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Status
              </label>
              <select
                id="Status"
                defaultValue=""
                onChange={(e) => getstatus(e.target.value)}
                name="Status"
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="">All</option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
            </div>
          </div>
          <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
            <button
              onClick={() => {
                allclear();
                getStateList();
              }}
              type="reset"
              className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
            >
              <MdOutlineRefresh className="mr-1" />
              Reset
            </button>
            <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
              <MdOutlineSearch className="mr-1" />
              Search
            </button>
          </div>
        </form>
      </div>
    </>
  );
});
export default Filter;
