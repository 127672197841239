import React, { useState } from "react";
import { RxPencil1 } from "react-icons/rx";
import { Link, useParams } from "react-router-dom";
import { MdAdd, MdReviewingWatermark, MdLocationCity, MdOutlineDelete, MdOutlineGroup, MdRemoveRedEye, MdExpand, MdMoveUp, MdSwipeUp, MdArrowDropUp } from "react-icons/md";
import { observer } from "mobx-react";
import ReactPaginate from 'react-paginate';
import { ReviewStore, useReviewStore, ReviewStoreContext } from "./store/ReviewStore";
import { useAuthStore } from "../Auth/store/Auth";
import { useEffect } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
// import { ExpandMore } from "@mui/icons-material";
import Filter from "./ReviewFilter/Filter";
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import Moment from "react-moment";

import dem05 from "../assets/images/2.png"
import { toast } from "react-toastify";


const ReviewManagement = () => {
    const store = new ReviewStore({
        isloading: true,
        editloading: false
    });
    return (
        <ReviewStoreContext.Provider value={store}>
            <Screen />
        </ReviewStoreContext.Provider>
    )
}
export default ReviewManagement;


const Screen = observer(() => {
    const {
        Review,
        Reviewsummary,
        shipping_addr,
        billing_addr,
        nolist,
        Citypopup,
        BulkDelete,
        BulkstatusChange,
        ReviewLoader,
        // setallReview,
        // allReview,
        setPaginationPage,
        setalldeactivate,
        alldeactivate,
        getReviewList,
        getFilterReview,
        TotalPages,
        setReviewpopup,
        Reviewpopup,
        deletepopup,
        setdeletepopup,
        setdelateLoader,
        delateLoader,
        statusChange,
        setstatuspopup,
        getid,
        getstatus,
        deletefunction,
        setchecked,
        checkeddd,
        getToken
    } = useReviewStore();

    const { adminAuthApproved, admin_permissions } = useAuthStore();

    useEffect(() => {
        if (adminAuthApproved) {
            getToken(localStorage.getItem("Admintoken"));
        }

    }, [adminAuthApproved]);

    const { id, amount } = useParams();

    const [currentPage, setCurrentPage] = useState(0);
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [allReview, setallReview] = useState([])
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isatend, setisatend] = useState(false);
    const [isAtBeginning, setisAtBeginning] = useState(false);
    const [Imagelength, setImagelength] = useState("");
    const [Imagearray, setImagearray] = useState([]);

    const prevSlide = (image) => {
        setCurrentSlide((prev) => (prev === 0 ? image.length - 1 : prev - 1));
        setisAtBeginning(currentSlide === 0)

    };
    const handleImageError = (event) => {
        // This function will be called when the image fails to load
        event.target.src = dem05; // Replace with the path to your default image
    };

    const nextSlide = (image) => {
        setCurrentSlide((prev) => (prev === image.length - 1 ? 0 : prev + 1));
        setisatend(currentSlide === image.length - 1)
    };
    // const [checkeddd, setchecked] = useState(false)

    const [photoIndex, setPhotoIndex] = useState("");
    const ITEMS_PER_PAGE = 10;

    const handlePageChange = ({ selected }) => {
        setPaginationPage(selected +1);
        // getReviewList();
        getFilterReview()
        setCurrentPage(selected);
    };

    const handleClick = () => {
        const element = document.getElementById("tablescroll");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }
    const startIndex = currentPage * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const Reviews = Review?.slice(startIndex, endIndex);
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const openLightbox = (index) => {
        setLightboxIsOpen(true);
    };

    const closeLightbox = () => {
        setLightboxIsOpen(false);
    };
    
    return (
        <>
          <div className="flex justify-between mt-[51px] ">
                <div className=" mb-4">
                    <p className=" text-2xl font-semibold">Review </p>
                    <p className=" opacity-80 text-sm">Home / Review </p>

                </div>

            </div>

            <Filter />

            <div className="card bg-base-100  shadow-md p-6 mt-8">
                <div className="overflow-x-auto ">
                    <table id="tablescroll" className="table table-zebra w-full">
                        <thead>
                            <tr>
                                <th>Product</th>
                                <th>Rating</th>
                                <th>Review</th>
                                <th>Action</th>
                            </tr>
                        </thead>

                        <tbody className="text-sm relative">
                            {ReviewLoader &&
                                <>
                                    <tr>
                                        <td colSpan={5} className="animate-pulse py-6"></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} className="animate-pulse py-6"></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} className="animate-pulse py-6"></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} className="animate-pulse py-6"></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} className="animate-pulse py-6"></td>
                                    </tr>
                                </>
                            }
                            {
                            
                                (Reviews?.length === 0 ? Review : Reviews)?.map((data, index) => (
                                <tr className="capitalize">
                               
                                    <td>
                                     {data.product_name}
                                    </td>
                                    <td>
                                        {data.rating}
                                    </td>
                                    <td
                                    className="flex flex-col"
                                        // onClick={() => { setImagearray(data.image_name); openLightbox() }}
                                        onClick={() => {openLightbox() }}


                                    >
                                        <span>{data.user_review}</span>


                                        <label
                                            htmlFor="imagecarousel"
                                            className=" flex space-x-[-5px] cursor-pointer"
                                            title="Delete"

                                        >
                                            {


                                                <>
                                                    <img

                                                        onError={handleImageError}
                                                        alt="ProductCatalog Image" className="w-[55px] bg-white transition duration-300 hover:opacity-70 rounded-xl bReview-[2px] p-1 bReview-[#d7d7d7] cursor-pointer h-[55px]" crossOrigin="anonymous" src={BASE_URL + `/uploads/Review`} />
                                                </>

                                            }





                                        </label>



                                    </td>




                                    <th >
                                        <div className="flex space-x-3">

                                            <div className="flex items-center space-x-3">
                                                {/* {admin_permissions.Review === 1 ? */}
                                                    <label
                                                        htmlFor="deletecity"
                                                        className=" text-[#d02c2c] cursor-pointer"
                                                        title="Delete"
                                                        onClick={() => { setdeletepopup(true); getid(data?.review_id) }}
                                                    >
                                                        <MdOutlineDelete />
                                                    </label>
                                                    {/* :
                                                    <label
                                                        htmlFor="deletecity"
                                                        className=" text-[#d02c2c] cursor-pointer"
                                                        title="Delete"
                                                        onClick={() => { toast.warning("You have no permissions to edit.") }}
                                                    >
                                                        <MdOutlineDelete />
                                                    </label>
                                                } */}


                                            </div>
                                        </div>

                                    </th>

                                </tr>
                                ))
                            }

                            {nolist &&
                                <tr>
                                    <td colSpan={7} className="my-10 text-center">
                                        <MdOutlineGroup className=" text-[#dedede] text-8xl mx-auto" />
                                        <p className=" font-semibold text-lg">No Reviews</p>

                                    </td>
                                </tr>}
                        </tbody>
                    </table>
                    {/* {!city?.length == 0 && */}

                    <ReactPaginate
                        initialPage={0}
                        pageCount={Math.ceil(TotalPages)}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageChange}
                        onClick={handleClick}
                        containerClassName={'pagination'}
                        activeClassName={'active'}

                    /> 
                    {/* }

                    {/* status change model start */}
                    {/* <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="my-modal-5"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status ?
              </h3>
              <div className="modal-action justify-center">
                      <label
                        htmlFor="my-modal-5"
                        className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                        onClick={() => statusChange()}
                      >
                        Yes
                      </label> 
                <label htmlFor="my-modal-5" className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]">
                  No
                </label>
              </div>
            </div>
          </div> */}

                    {lightboxIsOpen &&
                        <>
                            <input type="checkbox" id="imagecarousel" className="modal-toggle" />
                            <div className="modal w-full">
                                <div className="modal-box relative">
                                    <label

                                        className="btn btn-sm btn-circle absolute right-2 top-2"
                                        onClick={() => { setdelateLoader(false); setCurrentSlide(0); setLightboxIsOpen(false) }}
                                    >
                                        ✕
                                    </label>
                                    <div className="modal-action justify-center">
                                        <div className="carousel w-full">
                                            {/* {Imagearray.map((image, i) => ( */}
                                            <div
                                                // key={i}
                                                // id={`slide${i}`}
                                                className={`carousel-item relative w-full `}
                                            >
                                                <img
                                                    onError={handleImageError}
                                                    crossOrigin="anonymous" src={BASE_URL + `/uploads/Review/${Imagearray}`} className="w-full" />
                                            </div>
                                            {Imagearray.length > 1 &&
                                                <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">

                                                    <button disabled={isAtBeginning} onClick={() => { prevSlide(Imagearray); setImagelength(Imagearray); }} className={`btn btn-circle`}>
                                                        ❮
                                                    </button>

                                                    <button disabled={isatend} onClick={() => { nextSlide(Imagearray); setImagelength(Imagearray); }} className={`btn btn-circle`}>
                                                        ❯
                                                    </button>



                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>

                    }
                    {/* status change model end */}
                    {deletepopup &&
                        <>
                            <input type="checkbox" id="deletecity" className="modal-toggle" />
                            <div className="modal">
                                <div className="modal-box relative">
                                    <label

                                        className="btn btn-sm btn-circle absolute right-2 top-2"
                                        onClick={() => { setdelateLoader(false); setdeletepopup(false) }}
                                    >
                                        ✕
                                    </label>
                                    <h3 className="text-lg font-bold text-center">
                                        Are you sure you want to Delete this Review
                                    </h3>
                                    <div className="modal-action justify-center">
                                        {/* <label
                                            htmlFor="deletecity"
                                            className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                            onClick={() => deletefunction()}
                                        >
                                            Yes
                                        </label> */}
                                        {!delateLoader ?
                                            <label

                                                className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                                onClick={() => deletefunction()}
                                            >
                                                Yes
                                            </label> :
                                            <button
                                                // type="submit"
                                                disabled
                                                className="flex items-center bg-color_theme px-3 mx-5 rounded-md"
                                            // onClick={() => {
                                            //   PostMatchscore();
                                            // }}
                                            >
                                                Yes..
                                                <svg
                                                    className="animate-spin mr-1 h-5 w-5 text-white"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle
                                                        className="opacity-25"
                                                        cx="12"
                                                        cy="12"
                                                        r="10"
                                                        stroke="currentColor"
                                                        strokeWidth="4"
                                                    ></circle>
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    ></path>
                                                </svg>
                                            </button>}
                                        <label
                                            onClick={() => setdeletepopup(false)}
                                            className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </>

                    }
                    {Reviewpopup &&
                        <>
                            <input type="checkbox" id="my-modal-5" className="modal-toggle" />
                            <div className="modal">
                                <div className="modal-box relative">
                                    <label

                                        className="btn btn-sm btn-circle absolute right-2 top-2"
                                        onClick={() => setReviewpopup(false)}
                                    >
                                        ✕
                                    </label>
                                    <h3 className="text-lg font-bold text-center">
                                        Are you sure you want to change Status ?
                                    </h3>
                                    <div className="modal-action justify-center">
                                        {!ReviewLoader ? <label

                                            className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                            onClick={() => statusChange()}
                                        >
                                            Yes
                                        </label> :
                                            <button
                                                // type="submit"
                                                disabled
                                                className="flex items-center bg-color_theme px-3 mx-5 rounded-md"
                                            // onClick={() => {
                                            //   PostMatchscore();
                                            // }}
                                            >
                                                Yes..
                                                <svg
                                                    className="animate-spin mr-1 h-5 w-5 text-white"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle
                                                        className="opacity-25"
                                                        cx="12"
                                                        cy="12"
                                                        r="10"
                                                        stroke="currentColor"
                                                        strokeWidth="4"
                                                    ></circle>
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    ></path>
                                                </svg>
                                            </button>}

                                        <label
                                            onClick={() => setReviewpopup(false)}
                                            // htmlFor="voidstatus"
                                            className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                                        >
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>

                        </>

                    }
                </div>
            </div>






        </>
    );
});

