import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import {AuthStoreContext,AuthStore} from "./Auth/store/Auth"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthStoreContext.Provider value={new AuthStore()}>
      <ProSidebarProvider>
        <HashRouter >
          <App />
        </HashRouter>
      </ProSidebarProvider>
    </AuthStoreContext.Provider>
  </React.StrictMode>
);
reportWebVitals();