import { createContext, useContext } from "react";
import { makeAutoObservable, observable, runInAction } from "mobx";
import { format } from "date-fns";
import {
  postMethodData,
  getMethodData,
  putMethod,
  delMethod,
  patchMethodData,
} from "../../ApiMethods/api";
import { toast } from "react-toastify";
import Moment from "react-moment";

export class PlayerStore {
  Token = null;
  loadplayer = false;
  nolist = false;
  fname = "";
  lname = "";
  level = "";
  city = "";
  citydrop = [];
  leaguesdropdown=[];
  homecourtdrop = [];
  leaguename = "";
  League_Id= null;
  homecourt = "";
  loadPlayer = false;
  errloadPlayer = false;
  PlayerList = [];
  saved = false;
  newemail="";
  SeasonYear="2023";


  seasondropdown=[];

  SeasonMatch=[];
  noSeasonList=false;

  //get history of profile
  gethistory = []
  nolistHistory=false

  // get match history
  MatchHistory=[]
  

  constructor(isloading = false) {
    makeAutoObservable(this);
 
    this.Token = localStorage.getItem("Admintoken");

    if(isloading.match){
      this.GetMatchHistory(isloading.id,isloading.leagueid)
      return;
    }

    if (isloading.playerlist) {
      this.getPlayerList();
      this.getcitydropdown();
      this.getleaguesdropdown();
      this.getUserhomecourtdropdown();
      return;
    }

     if(isloading.isloading){
     this.getseasondropdown()
     this.gethistorydetail(isloading.id,this.SeasonYear)  
    //  this.GetMatchHistory(isloading.id,isloading.leagueid)

      return;
    }
 
  }

  //   // status popup
  //   setstatuspopup = () => {
  //     this.statuspopup = !this.statuspopup;
  //   };
  leagueClear=()=>{
        this.League_Id = null;
  }
  //All clear
  allclear = () => {
    this.fname = "";
    this.League_Id = null;
    this.lname = "";
    this.level ="";
    this.city = "";
    this.newemail="";
    this.leaguename = "";
    this.homecourt = "";
  };

  getNewemail= (text) => {
    this.newemail = text;
  }



  getfirstname = (text) => {
    this.fname = text;
  };
  getlastname = (text) => {
    this.lname = text;
  };
  getlevel = (text) => {
    this.level = text;
  };
  getcity = (text) => {
    this.city = text;
  };
  getleague = (text) => {
    this.leaguename = text;
  };
  gethomecourt = (text) => {
    this.homecourt = text;
  };

  getSeasonYear=(e)=>{
    this.SeasonYear=e;
  }
  getToken=(e)=>{
    this.Token=e;
    }

  //  getplayername=(text)=>{

  //  }

  //   //   // get Season id
  //   getleagaueid = (text) => {
  //     this.leagueid = text;
  //   };

  // get League list
  getPlayerList = () => {
    runInAction(() => {
      this.loadPlayer = true;
      this.nolist = false;
      this.errloadPlayer = "";
      this.saved = false;
      this.PlayerList =[];
    });
    getMethodData("/api/admin/players", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadPlayer = false;
            this.errloadPlayer = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadPlayer = false;
            this.errloadPlayer = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.PlayerList = resp?.data?.data;
            this.saved = false;
            this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.addleague = false;
          this.loadPlayer = false;
          this.errloadPlayer = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadPlayer = false;
          if (!error.response) {
            this.errloadPlayer = error?.message;
            toast.error(error?.message);
          }
          this.errloadPlayer = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };
  // filter Player

  getFilterPlayercourt = () => {
    runInAction(() => {
      this.loadPlayer = true;
      this.nolist = false;
      this.errloadPlayer = "";
      this.saved = false;
      this.PlayerList = [];
    });

    getMethodData(
      `/api/admin/players?filters[first_name]=${this.fname}&filters[last_name]=${this.lname}&filters[lavel]=${this.level}&filters[league]=${this.League_Id === null ? this.leaguename :this.League_Id}&filters[email]=${this.newemail}&filters[city]=${this.city}&filters[home_court]=${this.homecourt}`,
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadPlayer = false;
            this.errloadleague = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadPlayer = false;
            this.errloadleague = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.PlayerList = resp?.data?.data;
            this.saved = false;
            this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadPlayer = false;
          this.errloadPlayer = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadPlayer = false;
          if (!error.response) {
            this.errloadPlayer = error?.message;
            toast.error(error?.message);
          }
          this.errloadPlayer = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };
  // city drop down
  getcitydropdown = () => {
    runInAction(() => {
      this.errloadPlayer = "";
      // this.saved = false;
    });
    getMethodData("/api/get/cities", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.errloadPlayer = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.errloadPlayer = resp?.data?.message;
            // toast.success(resp?.data?.message);
            this.citydrop = resp?.data?.data;
          });
          return;
        }
        runInAction(() => {
          this.errloadPlayer = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.errloadPlayer = error?.message;
            // toast.error(error?.message);
          }
          this.errloadPlayer = error?.response?.data?.message;
          // toast.error(error?.response?.data?.message);
        });
        return;
      });
  };
   // leagues drop down
   getleaguesdropdown = () => {
    runInAction(() => {
      this.errloadPlayer = "";
      // this.saved = false;
    });
    getMethodData("/api/get/leagues", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.errloadPlayer = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.errloadPlayer = resp?.data?.message;
            // toast.success(resp?.data?.message);
            this.leaguesdropdown = resp?.data?.data;
          });
          return;
        }
        runInAction(() => {
          this.errloadPlayer = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.errloadPlayer = error?.message;
            // toast.error(error?.message);
          }
          this.errloadPlayer = error?.response?.data?.message;
          // toast.error(error?.response?.data?.message);
        });
        return;
      });
  };
 // get League list
 getPlayer = (id) => {
  runInAction(() => {
    this.loadleague = true;
    this.errloadleague = "";
    this.saved = false;
  });
  getMethodData(`/api/admin/league/${id}`, this.Token)
    .then((resp) => {
      if (resp?.data?.status === false) {
        runInAction(() => {
          this.loadleague = false;
          this.errloadhomecourt = resp?.data?.message;
        });
        return;
      }
      if (resp?.data?.status === true) {
        runInAction(() => {
          this.loadleague = false;
          this.errloadhomecourt = resp?.data?.message;
          this.leaguename=resp?.data?.data;
          this.League_Id =resp?.data?.data?._id;
          this.getFilterPlayercourt();
        });
        return;
      }
      runInAction(() => {
        this.addleague = false;
        this.loadleague = false;
        this.errloadleague = resp?.data?.message;
      });
    })
    .catch((error) => {
      runInAction(() => {
        this.loadSeason = false;
        if (!error.response) {
          this.errloadseason = error?.message;
          toast.error(error?.message);
        }
        this.errloadseason = error?.response?.data?.message;
        toast.error(error?.response?.data?.message);
      });
      return;
    });
};


  getUserhomecourtdropdown = () => {
    runInAction(() => {
      // this.loaduser = true;
      this.errloaduser = "";
      this.saved = false;
    });
    getMethodData(`/api/get/home-courts`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            // toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            // toast.success(resp?.data?.message);
            this.homecourtdrop = resp?.data?.data;
          });
          return;
        }
        runInAction(() => {
          this.loaduser = false;
          this.errloaduser = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loaduser = false;
          if (!error.response) {
            this.errloaduser = error?.message;
            // toast.error(error?.message);
          }
          this.errloaduser = error?.response?.data?.message;
          // toast.error(error?.response?.data?.message);
        });
        return;
      });
  };

  // get season dropdown
  getseasondropdown = async () => {
    runInAction(() => {
      //   this.loaduser = true;
      //   this.errloaduser = "";
      //   this.saved = false;
    });
    await getMethodData("/api/get/seasons", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            // this.loaduser = false;
            // this.errloaduser = resp?.data?.message;
            // toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.seasondropdown = resp?.data?.data;
          });
          return;
        }
        runInAction(() => {
          //   this.loaduser = false;
          //   this.errloaduser = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          //   this.loaduser = false;
          if (!error.response) {
            //   this.errloaduser = error?.message;
            // toast.error(error?.message);
          }
          //  this.errloaduser = error?.response?.data?.message;
          // toast.error(error?.message);
        });
        return;
      });
  };
  // get Matches 
  getMatchDetails = async (id,leaguid) => {
    runInAction(() => {
      //   this.loaduser = true;
      //   this.errloaduser = "";
      //   this.saved = false;
    });
    await getMethodData(`/api/season/matches/${id}?filters[season]=${leaguid}`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            // this.loaduser = false;
            // this.errloaduser = resp?.data?.message;
            // toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            if(resp?.data?.data?.length === 0) {
              this.noSeasonList = true;
            }else{
              this.noSeasonList = false;
            }
            this.SeasonMatch=resp?.data?.data;
          });
          return;
        }
        runInAction(() => {
          //   this.loaduser = false;
          //   this.errloaduser = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          //   this.loaduser = false;
          if (!error.response) {
            //   this.errloaduser = error?.message;
            // toast.error(error?.message);
          }
          //  this.errloaduser = error?.response?.data?.message;
          // toast.error(error?.message);
        });
        return;
      });
  };
//get history details
  gethistorydetail = async (id,year) => {
    runInAction(() => {
      // this.myDetailsLoad= true;
      // this.myDetailserrr= "";
    });
    await getMethodData(`/api/admin/season/history/${id}?filters[year]=${year}`, this.Token)
      .then((resp) => {
      
        if (resp?.data?.status === false) {
          runInAction(() => {
            // this.myDetailsLoad= false;
            // this.myDetailserrr= resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            // this.myDetailsLoad= false;
            this.gethistory= resp?.data?.data

            if(resp?.data?.data?.length === 0) {
              this.nolistHistory = true;   
            }else{
              this.nolistHistory = false;
            }
            // this.myDetailserrr= resp?.data?.message;
           
          });
          return;
        }
        runInAction(() => {
          // this.myDetailsLoad= false;
          // this.myDetailserrr= resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
            if(!error.response){
                // this.myDetailsLoad= false;
                // this.myDetailserrr= error?.message;
                toast.error(error?.message);
            }
            // this.myDetailserrr= error?.response?.data?.message;
            toast.error(error?.message);
        })
        return;
    });
  }

  //Player Match History
GetMatchHistory = async(id,leagueid) => {

  runInAction(() => {
    // this.loadMatch = true;
    // this.errloadMatch = "";
    // this.saved = false;
    // this.leaguesiddata = "";
  });
  await getMethodData(`/api/admin/season/matches/${id}?filters[league]=${leagueid}`, this.Token)
    .then((resp) => {
      if (resp?.data?.status === false) {
        runInAction(() => {
          // this.leaugesfalse = false;
          // this.loadMatch = false;
          // this.leaguesiddata = "";
          // this.errloadMatch = resp?.data?.message;
        });
        return;
      }
 
      if (resp?.data?.status === true) {
        runInAction(() => {
         this.MatchHistory=resp.data.data;
         if(resp?.data?.data?.length === 0) {
          this.nolistmatchHistory = true;   
        }else{
          this.nolistmatchHistory = false;
        }
        });
        return;
      }

      runInAction(() => {
        // this.leaugesfalse = false;
        // this.loadMatch = false;
        // this.errloadMatch = resp?.data?.message;
      });
    })
    .catch((error) => {
      runInAction(() => {
        // this.leaugesfalse = false;
        // this.loadMatch = false;
        if (!error.response) {
          // this.errloadMatch = error?.message;
          toast.error(error?.message);
        }
        // this.errloadMatch = error?.response?.data?.message;
        toast.error(error?.message);
      });
      return;
    });
};
}

export const PlayerStoreContext = createContext(new PlayerStore());
export const usePlayerStore = () => useContext(PlayerStoreContext);
