import { createContext, useContext } from "react";
import { makeAutoObservable, observable, runInAction } from "mobx";
import { postMethodData, getMethodData, putMethod, delMethod, patchMethodData } from "../../ApiMethods/api";
import { toast } from "react-toastify";




export class ProfileStore {

  Token = null;
  loadcitylist = false;

  city = [];
  errloadprofile = "";
  loadprofile = false;
  nolist = false;

  addloadprofile = false;
  erraddprofile = "";
  cityname = "";
  email = "";
  lastname = "";
  status = true;

  // upadateprofile = {};
  cityid = ""


  updatedzipcode = "";

  firstname = "";
  statuspopup = false;


  saved = false

  constructor(isloading = false) {
    makeAutoObservable(this);
    this.Token = localStorage.getItem("Admintoken");
    if (isloading.isloading) {
      // this.getCity();
      return
    }
    if (isloading.editloading) {
      this.getprofileone(isloading.id);
      return
    }
  }

  // status popup
  setstatuspopup = () => {
    this.statuspopup = !this.statuspopup;
  };

  // get city id
  getid = (text) => {
    this.cityid = text;
  }

  // get status 
  getstatus = (text) => {
    this.status = text;
  };

  //get city name
  getcityname = (text) => {
    this.cityname = text;
  };

  //get updated city
  updatedfirstname = (text) => {
    this.firstname = text;
  };

  //get email
  getemail = (text) => {
    this.email = text;
  };

  // get lastname name
  getlastname = (text) => {
    this.lastname = text;
  };


  //get profile 
  getprofileone = () => {
    getMethodData(`/api/admin/get-profile`, this.Token)
      .then((resp) => {
        runInAction(() => {
          this.loadprofile = true;
          this.errloadprofile = "";
        });
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadprofile = false;
            this.errloadprofile = resp.data.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadprofile = false;
            this.errloadprofile = resp?.data?.message;
            // this.upadateprofile= resp.data.data;
            this.firstname = resp?.data?.data?.firstName;
            this.lastname = resp?.data?.data?.lastName;
            this.email = resp?.data?.data?.email;
            // this.status = resp?.data?.data?.isActive;
          })
          return;
        }
        runInAction(() => {
          this.loadprofile = false;
          this.errloadprofile = resp.data.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.loadprofile = false;
            this.errloadprofile = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.loadprofile = false;
          this.errloadprofile = error?.response?.data?.message;
        })
        return;
      })
  }


 


}




export const ProfileStoreContext = createContext(new ProfileStore());
export const useProfileStore = () => useContext(ProfileStoreContext);
