import { createContext, useContext } from "react";
import { makeAutoObservable, observable, runInAction } from "mobx";
import { format } from "date-fns";
import {
  postMethodData,
  getMethodData,
  putMethod,
  delMethod,
  patchMethodData,
} from "../../ApiMethods/api";
import { toast } from "react-toastify";
import Moment from "react-moment";

export class TieStore {
  Token = null;
  settled_tie = false;
  Dname = "";
  settled="";
  leaguename = "";
  nolist = false;
  EditScore = false;
  loadTie = false;
  errloadTie = false;
  TieList = [];

  constructor(isloading = false) {
    makeAutoObservable(this);
    this.Token = localStorage.getItem("Admintoken");
    if (isloading.tiebreaker) {
      this.getTieList();
      return;
    }
  }

  //All clear


  getToken = (e) => {
    this.Token = e;
  };
  getleague = (text) => {
    this.leaguename = text;
  };
  Settled_tie=(e)=>{
    this.settled_tie = e;
  }
  SetSettled=(e)=>{
    this.settled = e;
  }
  getDname = (text) => {
    this.Dname = text;
  };

  // get Tie list
  getTieList = () => {
    runInAction(() => {
      this.loadTie = true;
      this.nolist=false;
      this.errloadTie = "";
      this.saved = false;
      this.TieList = [];
    });
    getMethodData(
      `/api/admin/tie/players?filters[league]=${this.leaguename}`,
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadTie = false;
            this.errloadTie = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadTie = false;
            this.errloadTie = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.TieList = resp?.data.data;
            this.saved = false;
            this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadTie = false;
          this.errloadTie = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadTie = false;
          if (!error.response) {
            this.errloadTie = error?.message;
            toast.error(error?.message);
          }
          this.errloadTie = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };
  //   // filter Tie
  getFilterTieList = () => {
    runInAction(() => {
      this.loadTie = true;
      this.nolist=false;
      this.errloadTie = "";
      this.saved = false;
      this.TieList = [];
    });
    getMethodData(
      `/api/admin/tie/players?filters[league]=${this.leaguename}&filters[division_code]=${this.Dname}&filters[settled]=${this.settled}`,
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadTie = false;
            this.errloadTie = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadTie = false;
            this.errloadTie = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.TieList = resp?.data.data;

            this.saved = false;
            this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadTie = false;
          this.errloadTie = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadTie = false;
          if (!error.response) {
            this.errloadTie = error?.message;
            toast.error(error?.message);
          }
          this.errloadTie = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };
  
  // settled ties
    getSettledTieBreaker = () => {
      runInAction(() => {
        this.loadTie = true;
        this.nolist=false;
        this.errloadTie = "";
        this.saved = false;
      });
      getMethodData(
        `/api/admin/tie/players?filters[settled]=${this.settled_tie}`,
        this.Token
      )
        .then((resp) => {
          if (resp?.data?.status === false) {
            runInAction(() => {
              this.loadTie = false;
              this.errloadTie = resp?.data?.message;
            });
            return;
          }
          if (resp?.data?.status === true) {
            runInAction(() => {
              this.loadTie = false;
              // this.getTieList();
              const data = resp?.data.data;
              this.TieList = data.reduce((x, y) => {
                    (x[y?.division?.code] = x[y?.division?.code] || []).push(y);
                    return x;
              }, {});
              this.errloadTie = resp?.data?.message;
              if (resp?.data?.data.length === 0) {
                this.nolist = true;
              } else {
                this.nolist = false;
              }
              this.saved = false;
              this.status = true;
            });
            return;
          }
          runInAction(() => {
            this.loadTie = false;
            this.errloadTie = resp?.data?.message;
          });
        })
        .catch((error) => {
          runInAction(() => {
            this.loadTie = false;
            if (!error.response) {
              this.errloadTie = error?.message;
              toast.error(error?.message);
            }
            this.errloadTie = error?.response?.data?.message;
            toast.error(error?.response?.data?.message);
          });
          return;
        });
    };
 
}

export const TieStoreContext = createContext(new TieStore());
export const useTieStore = () => useContext(TieStoreContext);
