import React from 'react';
import moment from 'moment';
import Moment from 'react-moment';
import { PlayerStore, PlayerStoreContext, usePlayerStore } from './store/PlayerStore';
import { Link, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

const MatchhHistory = () => {

    const { id,leagueid } = useParams();
    const store = new PlayerStore({
       isloading: true,
       match: true,
       id: id,
       leagueid: leagueid
    //   levelid:levelid,
    });
    return (
      <PlayerStoreContext.Provider value={store}>
        <Screen />
      </PlayerStoreContext.Provider>
    );
  };
  export default MatchhHistory;

const Screen = observer(() => {
  const { id,leagueid } = useParams();
    const {

        MatchHistory,
        nolistmatchHistory

      } =usePlayerStore()
      const goBack = () => {
        window.history.back(); // Go back to the previous pagehistory.goBack(); // Go back to the previous page
      };
      const matchHistoryLevel = MatchHistory[0]?.level;
  
      const matchLeagueName = MatchHistory[0]?.league?.name
  
  return (
    <> 
    <div className='flex justify-between'>
            <div className=" mb-4 flex">
                <p className=" text-2xl font-semibold">Player Match History for <span className='text-color_theme'> {matchLeagueName}</span> </p>
                <Link 
          //  to={`/playoffmatches/${leagueid}/${matchHistoryLevel}/${matchLeagueName}`}
          to={ `/playerstree/${leagueid}/${matchHistoryLevel}/${matchLeagueName}`}
          className="font-bold rounded-md p-2 bg-color_theme text-[#000] text-sm ml-2 ">
             Championship
            </Link>
            </div>
            

            <div>
                <Link onClick={goBack} className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]">Back</Link>
            </div>
        </div>
        <div className="relative h-full">
    <div className="relative px-9 z-1 py-5">
      <div className="block">
        {/* <div className="align-baseline ">
            {route[0]=== "/playerdetail" ?  <Link to={`/playerdetail/${id}`} className=" ">
            <p className="flex items-center md:pl-20 pl-3 top-5 text-[20px] text-[#ffffff]  ">
              <FaAngleLeft /> Back to Player 
            </p>
          </Link>: <Link to={`/mymatch/${leagueid}`} className=" ">
            <p className="flex items-center md:pl-20 pl-3 top-5 text-[20px] text-[#ffffff]  ">
              <FaAngleLeft /> Back to Matches 
            </p>
          </Link> }
       
        </div> */}

        {/* <div className="md:px-36 pb-1 ">
          <p className=" text-center text-[#fff] text-3xl md:text-3xl  capitalize">
            Match History for <span className="text-color_theme">{MatchHistoryOfUser?.replace("(Female)", "")?.replace("(Male)", "")}</span>  
            <br />
          </p>
        </div> */}
      </div>
      <div className=" bg-[#fff] border-2  border-color_theme rounded mt-3">
        <div
          className="rounded-t-lg overflow-auto   "
          id="style-1"
        >
          <table id="tablescroll" className="table table-zebra w-full">
            <thead className="sticky top-0">
              <tr className="">
                <th className="py-5  px-5 ">#</th>
                <th className="md:py-5">Opponent</th>
                <th className="md:py-5">Mate</th>
                <th className="md:py-5">Round</th>
                <th>Played On</th>
                <th>Home Court</th>
                <th>Result</th>
              </tr>
            </thead>

            <tbody className="text-sm relative" >
              {/* {nolistHistory && ( */}
              {MatchHistory.map((res, index) => (
                <tr
                  key={index}
                  className="hover:bg-[#39fd13] hover:bg-opacity-5 "
                >
                  <td>{index + 1}</td>
                  <td className="text-sm  py-3">
                    {res?.opponent.map((playername, index) => (
                      <span
                        key={index}
                        onClick={(e) => {
                          // RedirectChanged(`/mymatch/${id}`, true);
                          // navigate(
                          //   `/playerdetail/${playername?._id}`
                          // );
                        }}
                        className="capitalize cursor-auto "
                      >
                        {(index ? ", " : "") + playername?.firstName}{" "}
                        {playername?.lastName}
                      </span>
                    ))}
                    {/* {res.opponent.firstName} {res.opponent.lastName} */}
                  </td>
                  <td className="text-sm  py-3 px-3">
                    {res?.mate == undefined
                      ? "-"
                      : res?.mate?.firstName + " " + res?.mate?.lastName}

                    {/* {res.opponent.firstName} {res.opponent.lastName} */}
                  </td>
                  {/* {playername?._id != user?._id ? <>{playername.firstName} {playername.lastName},</>  :"" }  */}
                  {/* {leaguetype != "singles" ? ( */}
                  {/* <td>
                              <span
                                title="View Profile"
                                className="capitalize cursor-pointer  hover:text-color_theme"
                                onClick={(e) => {
                                  navigate(
                                    `/playerdetail/${res?.mate?._id}`
                                  );
                                }}
                              >
                                {res?.mate?.firstName} {res?.mate?.lastName}
                              </span>
                            </td> */}
                  {/* ) : (
                            ""
                          )} */}
                          <td className="py-3 capitalize">{res?.matchType}</td>
                  <td className=" py-9 flex ">
                    {" "}
                    {res?.schedule_date !== null ? (
                      <span className="flex" title="Schedule Dates">
                        <Moment
                          format={
                            moment(res?.schedule_date)
                              .format("MMM D")
                              .includes("May")
                              ? "MMM DD"
                              : "MMM. DD"
                          }
                        >
                          {res?.schedule_date}
                        </Moment>
                        <p className="px-1"> @ </p>
                        {moment(res?.schedule_time).format("hh:mm A")}
                      </span>
                    ) : (
                      "N/A"
                    )}{" "}
                  </td>

                  <td className="py-3">{res?.home_court?.displayName}</td>

                  <td>
                    {" "}
                    <p className="text md:text-sm text-xs">
                      {res.match_result == "loss" ||
                      res.match_result == "win" ? (
                        <>
                          <span className="border border-color_theme   2xl:mx-2 mx-[1px] lg:p-1">
                            {res?.team_first_score?.set_one}-
                            {res?.team_second_score?.set_one}
                          </span>
                          <span className="border border-color_theme   2xl:mx-2 mx-[1px] lg:p-1">
                            {res?.team_first_score?.set_two}-
                            {res?.team_second_score?.set_two}
                          </span>
                          {res?.team_first_score?.set_three ||
                          res?.team_second_score?.set_three != null ? (
                            <span className="border border-color_theme  2xl:mx-2 mx-[1px] lg:p-1">
                              {res?.team_first_score?.set_three}-
                              {res?.team_second_score?.set_three}
                            </span>
                          ) : (
                            ""
                          )}
                          -{" "}
                          {res?.match_result === "loss" ? (
                            <span className="  2xl:mx-2 mx-[1px] lg:p-1">
                              Lost
                            </span>
                          ) : (
                            <span className="  2xl:mx-2 mx-[1px] lg:p-1">
                              Won
                            </span>
                          )}
                        </>
                      ) : res?.result === "score" ? (
                        <>
                          <span className="border border-color_theme   2xl:mx-2 mx-[1px] lg:p-1">
                            {res?.team_first_score?.set_one}-
                            {res?.team_second_score?.set_one}
                          </span>
                          <span className="border border-color_theme  2xl:mx-2 mx-[1px] lg:p-1">
                            {res?.team_first_score?.set_two}-
                            {res?.team_second_score?.set_two}
                          </span>
                          {res?.team_first_score?.set_three ||
                          res?.team_second_score?.set_three != null ? (
                            <span className="border border-color_theme  2xl:mx-2 mx-[1px] lg:p-1">
                              {res?.team_first_score?.set_three}-
                              {res?.team_second_score?.set_three}
                            </span>
                          ) : (
                            ""
                          )}
                        </>
                      ) : res?.is_score_update === false ? (
                        <span className="md:text-sm capitalize">-</span>
                      ) : (
                        <span className="md:text-sm capitalize">
                          {res?.result === "Forfieted" ? (
                            <span>Forfeited By {res?.forfiet_by}</span>
                          ) : (
                            <span>Withdrew By {res?.Withdraw_by}</span>
                          )}
                        </span>
                      )}
                    </p>
                  </td>
                </tr>
              ))}
              {nolistmatchHistory && (
                        <tr>
                          <td colSpan={6} className="py-20 ">
                            <p className=" text-center font-semibold text-3xl">
                               This Player Not Played Any Matches Yet.
                            </p>
                          </td>
                        </tr>
                      )}
            </tbody>
          </table>
        </div>
        {/* <div className="text-[#3636364d] flex justify-center mt-40 text-3xl ">
                You have not played any season yet
              </div> */}
      </div>
    </div>
  </div>
    </>
   
  )
})
