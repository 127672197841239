import { createContext, useContext } from "react";
import { makeAutoObservable, observable, runInAction } from "mobx";
import { format } from "date-fns";
import {
  postMethodData,
  getMethodData,
  putMethod,
  delMethod,
  patchMethodData,
} from "../../ApiMethods/api";
import { toast } from "react-toastify";
import Moment from "react-moment";

export class PlayerReasonStore {
  Token = null;
  // loadplayer = false;
  loadrefund = false;
  refundpopup = false;
  nolist = false;
  playername="";
  Transaction_Date="";
  ignored_player_id="";
  refundCheck="";
  matename="";
  Remark="";
  fname = "";
  lname = "";
  level = "";
  city = "";
  citydrop = [];
  leaguesdropdown=[];
  homecourtdrop = [];
  leaguename = "";
  League_Id= null;
  homecourt = "";
  loadPlayer = false;
  errloadPlayer = false;
  PlayerList = [];
  saved = false;

  SeasonYear="2023";


  seasondropdown=[];

  SeasonMatch=[];
  noSeasonList=false;

  //get history of profile
  gethistory = []
  nolistHistory=false

  // get match history
  MatchHistory=[]


  //post send email
  subject =""
  description=""

  emailsent =false
  refund = false

  isrefunded=""
  

  constructor(isloading = false) {
    makeAutoObservable(this);
 
    this.Token = localStorage.getItem("Admintoken");
  

     if(isloading.isloading){
    //  this.getseasondropdown()
     this.getPlayerList();
    //  this.gethistorydetail(isloading.id,this.SeasonYear)  
     this.getcitydropdown();
     this.getleaguesdropdown();
     this.getUserhomecourtdropdown();
    //  this.GetMatchHistory(isloading.id,isloading.leagueid)

      return;
    }
 
  }

  //   // status popup
  //   setstatuspopup = () => {
  //     this.statuspopup = !this.statuspopup;
  //   };
  leagueClear=()=>{
        this.League_Id = null;
  }
  //All clear
  allclear = () => {
   
    this.level ="";
    this.isrefunded ="";
    this.leaguename = "";

    // this.city = "";
    // this.homecourt = "";
  };
  getfirstname = (text) => {
    this.fname = text;
  };
  setPlayer=(e)=>{
    this.playername=e;
  }
  getlastname = (text) => {
    this.lname = text;
  };
  getlevel = (text) => {
    this.level = text;
  };
  getcity = (text) => {
    this.city = text;
  };
  getleague = (text) => {
    this.leaguename = text;
  };
  setRemark = (text) => {
    this.Remark = text;
  };

  getsubject = (text) => {
    this.subject = text;
  };
  getDescription = (text) => {
    this.description = text;
  };
  getIsrefunded = (text) => {
    this.isrefunded = text;
  };
  

  setTransaction_Date=(e)=>{
    this.Transaction_Date = e;
  }
setignored_player_id=(e)=>{
  this.ignored_player_id=e;
}
setrefundCheck=(e)=>{
  this.refundCheck=e;
}
setmatename=(e)=>{
  this.matename = e;
}
getToken=(e)=>{
  this.Token=e;
  }
  //  getplayername=(text)=>{


  // get Player list
  getPlayerList = () => {
    runInAction(() => {
      this.loadPlayer = true;
      this.nolist = false;
      this.errloadPlayer = "";
      this.saved = false;
      this.PlayerList =[];
    });
    getMethodData("/api/admin/ignored/registers", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadPlayer = false;
            this.errloadPlayer = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadPlayer = false;
            this.errloadPlayer = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.PlayerList = resp?.data?.data;

            this.saved = false;
            this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.addleague = false;
          this.loadPlayer = false;
          this.errloadPlayer = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadPlayer = false;
          if (!error.response) {
            this.errloadPlayer = error?.message;
            toast.error(error?.message);
          }
          this.errloadPlayer = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };
  // filter Player excluded

  getFilterPlayercourt = (id) => {
    const newvar = id ? id : "";
    runInAction(() => {
      this.loadPlayer = true;
      this.nolist = false;
      this.errloadPlayer = "";
      this.saved = false;
      this.PlayerList = [];
    });

    getMethodData(
      `/api/admin/ignored/registers?filters[level]=${this.level}&filters[league]=${this.leaguename || newvar}&filters[is_refunded]=${this.isrefunded}`,
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadPlayer = false;
            this.errloadleague = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadPlayer = false;
            this.errloadleague = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.PlayerList = resp?.data?.data;
            this.saved = false;
            this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadPlayer = false;
          this.errloadPlayer = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadPlayer = false;
          if (!error.response) {
            this.errloadPlayer = error?.message;
            toast.error(error?.message);
          }
          this.errloadPlayer = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };


  //send bulk email
  sendemail = (emails) => {
    runInAction(() => {
      this.errloadPlayer = "";
      this.emailsent =false
      this.PlayerList=[]
      this.loadEmail = true;
    });
    
    postMethodData(
      "/api/admin/notify/excluded/users",
      {
        email: emails,
        subject: this.subject,
        body: this.description,
      },
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadEmail = false;
            this.emailsent =false;
            this.errloadPlayer = resp?.data?.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadEmail = false;
            this.emailsent =true;
            this.saved = true;
            this.getPlayerList()
            this.subject=""
            this.description =""
            toast.success(resp.data.message);
          });
          return;
        }
        runInAction(() => {
          this.loadEmail = false;
          this.errloadPlayer = resp?.data?.message;
          this.emailsent =false
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadEmail = false;
          this.emailsent =false
          if (!error.response) {
            this.errloadPlayer = error?.message;
            toast.error(error?.message);
          }
          this.errloadPlayer = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };

  // city drop down
  getcitydropdown = () => {
    runInAction(() => {
      this.errloadPlayer = "";
      // this.saved = false;
    });
    getMethodData("/api/get/cities", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.errloadPlayer = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.errloadPlayer = resp?.data?.message;
            // toast.success(resp?.data?.message);
            this.citydrop = resp?.data?.data;
          });
          return;
        }
        runInAction(() => {
          this.errloadPlayer = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.errloadPlayer = error?.message;
            // toast.error(error?.message);
          }
          this.errloadPlayer = error?.response?.data?.message;
          // toast.error(error?.response?.data?.message);
        });
        return;
      });
  };
   // leagues drop down
   getleaguesdropdown = () => {
    runInAction(() => {
      this.errloadPlayer = "";
      // this.saved = false;
    });
    getMethodData("/api/get/leagues", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.errloadPlayer = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.errloadPlayer = resp?.data?.message;
            // toast.success(resp?.data?.message);
            this.leaguesdropdown = resp?.data?.data;
          });
          return;
        }
        runInAction(() => {
          this.errloadPlayer = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.errloadPlayer = error?.message;
            // toast.error(error?.message);
          }
          this.errloadPlayer = error?.response?.data?.message;
          // toast.error(error?.response?.data?.message);
        });
        return;
      });
  };

  getUserhomecourtdropdown = () => {
    runInAction(() => {
      // this.loaduser = true;
      this.errloaduser = "";
      this.saved = false;
    });
    getMethodData(`/api/get/home-courts`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            // toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            // toast.success(resp?.data?.message);
            this.homecourtdrop = resp?.data?.data;
          });
          return;
        }
        runInAction(() => {
          this.loaduser = false;
          this.errloaduser = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loaduser = false;
          if (!error.response) {
            this.errloaduser = error?.message;
            // toast.error(error?.message);
          }
          this.errloaduser = error?.response?.data?.message;
          // toast.error(error?.response?.data?.message);
        });
        return;
      });
  };
  setrefundpopup = () => {
    this.refundpopup = !this.refundpopup
}
   // Refund 
   Refund_Deatils = () => {
    runInAction(() => {
      this.loadrefund = true;
      this.errloadPlayer = "";
      this.refund=false
      
    });
    postMethodData("/api/user/refund", {
      ignored_player_id: this.ignored_player_id,
      refund_date: this.Transaction_Date ,
      remark: this.Remark

    }, this.Token).then((resp) => {

      if (resp?.data?.status === false) {

        runInAction(() => {
          this.refund=false;
          this.loadrefund = true;
          this.errloadPlayer = resp?.data?.message;
          toast.error(resp.data.message);
        });
        return;

      }
      if (resp?.data?.status === true) {
        runInAction(() => {
          this.setrefundpopup();
          this.getPlayerList()
          this.loadrefund = false;
          this.errloadPlayer = resp?.data?.message;
          this.saved = true;
          this.refund=true;
          toast.success(resp?.data?.message);

        })
        return
      }
      runInAction(() => {
        this.loadrefund = false;
        this.refund=false;
        this.errloadPlayer = resp?.data?.message;
      });

    })
      .catch((error) => {
        runInAction(() => {
          this.addseasoncourt = false;
          this.loadrefund = false;
          this.refund=false;
          if (!error.response) {
            this.errloadPlayer = error?.message;
            toast.error(error?.message);
          }
          this.errloadPlayer = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        })
        return;
      });
  };



}

export const PlayerReasonStoreContext = createContext(new PlayerReasonStore());
export const usePlayerReasonStore = () => useContext(PlayerReasonStoreContext);
