import { createContext, useContext } from "react";
import { makeAutoObservable, observable, runInAction } from "mobx";
import { postMethodData, getMethodData, putMethod, delMethod, patchMethodData } from "../../ApiMethods/api";
import { toast } from "react-toastify";
import { format } from 'date-fns';

export class SeasonStore {

  Token = null;
  loadcitylist = false;
  Seasonpopup=false;
  SeasonLoader=false;
  season = [];
  errloadseason = "";
  nolist = false;
  loadSeason = false;
  addseasoncourt = false;
  addleagueload=false;
  erraddseasoncourt = "";
  seasonname = "";
  updatedStartDate = "";
  updatedEndDate = "";
  updatedStatus = true;
  startdate = "";
  editstart = "";
   editend = null;
   editname="";
   citydrop=[];
   geteditstatus="";
  enddate = "";
  editstatus="";
  name = "";
  zipcode = "";
  country = "";
  status = "";
  edityear=""
  updatedcity = {};
  seasonid = ""
  year =""
  newcitynameid=""
  cityname=""
  updatedzipcode = "";
  newcityname = "";
  statuspopup = false;
  modalclose = true;
  saved = false;
  loadlist= false

  constructor(isloading = false) {
    makeAutoObservable(this);
    this.Token = localStorage.getItem("Admintoken");
    if (isloading.isloading) {
      this.getSeasons();
      this.getcitydropdown();
      return
    }
    if(isloading.slectcity){
      this.getcitydropdown();
    }
    if (isloading.editloading) {
      this.getSeasonOne(isloading.id);
      return
    }
  }

  //   // status popup
  //   setstatuspopup = () => {
  //     this.statuspopup = !this.statuspopup;
  //   };

  //   // get Season id
  getseasonid = (text) => {
    this.seasonid = text;
  }
  setseasonpopup = (e) => {
    this.Seasonpopup = e;
  };
  //   // get home name
  //   getHomename = (text) => {
  //     this.homename = text;
  //   }

  // get status 
  getstatus = (text) => {
    this.status = text;
  };
// get updated status 
getupdatedstatus =(text)=>{
  this.updatedStatus = text;
}
getcityname = (text) => {
  this.cityname = text;
};

//get year
 getyear =(text)=>{
this.year=text;
 }
  //get name
  getname = (text) => {
    this.name = text;
  };

  //get start date
  getstartdate = (text) => {
    this.startdate = text;
  };
  //get end date
  getenddate = (text) => {
    this.enddate = text;
  };
//edit start date
editstartdate =(text)=>{
this.updatedStartDate=text
}
//edit end date
editenddate =(text)=>{
  this.updatedEndDate=text
  }

//edit season name 
editseasonname =(text)=>{
  this.editname = text
}
//edit year 
edityears=(text)=>{
  this.edityear=text;
}
editseasonstatus =(text)=>{
this.editstatus =text
}
updatedcityname = (text) => {
  this.newcityname = text;
};
updatedcitynameid = (text) => {
  this.newcitynameid = text;
};
getToken=(e)=>{
  this.Token=e;
  }

  //   //get zipcode
  //   getzipcode = (text) => {
  //     this.zipcode = text;
  //   };

  //   // get country name
  //   getcountryname = (text) => {
  //     this.country = text;
  //   };

  // get Season list
  getSeasons = () => {
    runInAction(() => {
      this.loadlist = true;
      this.errloadseason = "";
      this.saved = false;
      this.nolist = false;
    });
    getMethodData("/api/admin/season", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadlist = false;
            this.errloadhomecourt = resp?.data?.message
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadlist = false;
            this.errloadhomecourt = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.season = resp?.data?.data;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.addseasoncourt = false;
          this.loadlist = false;
          this.errloadseason = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadlist = false;
          if (!error.response) {
            this.errloadseason = error?.message;
            toast.error(error?.message);
          }
          this.errloadseason = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        })
        return;
      });
  };

  // get filter Seasons
  getFilterSeason = () => {
    runInAction(() => {
      this.loadlist = true;
      this.errloadseason = "";
      this.saved = false;
      this.season = [];
      this.nolist = false;
    });
    getMethodData(`/api/admin/season?filters[name]=${this.name}&filters[year]=${this.year}&filters[city]=${this.newcitynameid}&filters[from_date]=${this.startdate}&filters[to_date]=${this.enddate}&filters[isActive]=${this.status}`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadlist = false;
            this.errloadhomecourt = resp?.data?.message
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadlist = false;
            this.errloadhomecourt = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.season = resp?.data?.data;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.addseasoncourt = false;
          this.loadlist = false;
          this.errloadseason = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadlist = false;
          if (!error.response) {
            this.errloadseason = error?.message;
            toast.error(error?.message);
          }
          this.errloadseason = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        })
        return;
      });
  };

  // add Season
  addSeason = () => {
    runInAction(() => {
      this.addseasoncourt = true;
      this.erraddseasoncourt = "";
    });
    postMethodData("/api/admin/season", {
      name: this.name,
      start_date: this.startdate,
      end_date: this.enddate,
      city: this.cityname,
      isActive: this.status,
      year:parseInt(this.year)
    }, this.Token).then((resp) => {

      if (resp?.data?.status === false) {

        runInAction(() => {
          this.addseasoncourt = false;
          this.erraddhomecourt = resp?.data?.message;
          toast.error(resp.data.message);
        });
        return;

      }
      if (resp?.data?.status === true) {
        runInAction(() => {

          this.addseasoncourt = false;
          this.errloadseason = resp?.data?.message;
          // toast.success(resp.data.message);
          this.getSeasons();
          this.saved = true;
        })
        return
      }
      runInAction(() => {
        this.addseasoncourt = false;
        this.erraddseasoncourt = resp?.data?.message;
      });

    })
      .catch((error) => {
        runInAction(() => {
          this.addseasoncourt = false;
          if (!error.response) {
            this.erraddhomecourt = error?.message;
            toast.error(error?.message);
          }
          this.erraddseasoncourt = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        })
        return;
      });
  };
  //league added
  addLeague = () => {
    runInAction(() => {
      this.addleagueload = true;
      this.modalclose = false;
      this.erraddseasoncourt = "";
    });
    // return
    postMethodData("/api/admin/league", {
      season_id:this.seasonid
    }, this.Token).then((resp) => {

      if (resp?.data?.status === false) {

        runInAction(() => {
          this.modalclose = true;
          this.addleagueload = false;
          this.erraddhomecourt = resp?.data?.message;
          toast.error(resp.data.message);
        });
        return;

      }
      if (resp?.data?.status === true) {
        runInAction(() => {
          this.modalclose = true;
          this.addleagueload = false;
          this.errloadseason = resp?.data?.message;
          // toast.success(resp.data.message);
          this.getSeasons();
          this.saved = true;
        })
        return
      }
      runInAction(() => {
        this.modalclose = true;
        this.addleagueload = false;
        this.erraddseasoncourt = resp?.data?.message;
      });

    })
      .catch((error) => {
        runInAction(() => {
          this.modalclose = true;
          this.addleagueload = false;
          if (!error.response) {
            this.erraddhomecourt = error?.message;
            toast.error(error?.message);
          }
          this.erraddseasoncourt = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        })
        return;
      });
  };


  //Active and inactive
  statusChange = () => {
    runInAction(() => {
      this.SeasonLoader = true;
      this.Seasonpopup=true;
      this.errloadseason = "";
    });
    patchMethodData(`/api/admin/season/status/${this.seasonid}`, { isActive: `${!this.status}` }, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.SeasonLoader = true;
            this.Seasonpopup=true;
            this.errloadhomecourt = resp?.data?.message;
            toast.error(resp?.data?.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.SeasonLoader = false;
            this.Seasonpopup=false;

            this.errloadhomecourt = resp?.data?.message;
            this.getSeasons();
            toast.success(resp?.data?.message);
          })
          return;
        }
        runInAction(() => {
          this.errloadseason = resp?.data?.message;
        })
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.errloadseason = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.errloadseason = error?.response?.data?.message;
        })
        return;
      })
  };

  //get Season by id
  getSeasonOne = (id) => {
    getMethodData(`/api/admin/season/${id}`, this.Token)
      .then((resp) => {
        runInAction(() => {
          this.loadhomecourt = true;
          this.errloadhomecourt = "";
        });
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadhomecourt = false;
            this.errloadhomecourt = resp.data.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
          this.loadhomecourt = false;
          this.errloadhomecourt = resp?.data?.message;
          this.updatedStartDate = format(new Date(resp?.data?.data?.start_date), 'yyyy-MM-dd');
          this.updatedEndDate = format(new Date(resp?.data?.data?.end_date), 'yyyy-MM-dd');
          this.editname = resp?.data?.data?.name;
          this.newcityname =resp?.data?.data?.city;
          this.edityear = resp?.data?.data?.year;
          this.updatedStatus = resp?.data?.data?.isActive;
          })
          return;
        }
        runInAction(() => {
          this.loadhomecourt = false;
          this.errloadhomecourt = resp.data.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.loadhomecourt = false;
            this.errloadhomecourt = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.loadhomecourt = false;
          this.errloadhomecourt = error?.response?.data?.message;
        })
        return;
      })
  }


  //update method
  updatedseason = (id) => {
    runInAction(() => {
      // this.loadSeason = true;
      this.errloadseason = "";
    });
    putMethod(`/api/admin/season/${id}`,
      {
        name: this.editname,
        start_date: this.updatedStartDate,
        city: this.newcityname,
        end_date:this.updatedEndDate,
        isActive: this.updatedStatus,
        year:parseInt(this.edityear)
      },
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            // this.loadSeason = false;
            this.erraddcity = resp?.data?.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
              this.addloadcity = false;
              this.erraddcity = resp?.data?.message;
              toast.success(resp.data.message);
              this.saved = true;
            })
            return
        }
        runInAction(() => {
          this.addloadcity = false;
          this.erraddcity = resp?.data?.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
            if(!error.response){
              this.addloadcity = false;
              this.erraddcity = error?.message;
              toast.error(error?.message);
            }
              toast.error(error?.response?.data?.message);
              // this.loadSeason = false;
              this.errloadseason = error?.response?.data?.message;
        })
        return;
    })
  };
  getcitydropdown =() =>{
    runInAction(() => {
     this.erraddseasoncourt = "";
     // this.saved = false;
    });
   getMethodData("/api/get/cities", this.Token)
   .then((resp) => {
     if (resp?.data?.status === false) {
       runInAction(() => {
         this.erraddseasoncourt = resp?.data?.message
       });
       return;
     }
     if (resp?.data?.status === true) {
       runInAction(() => {
         this.erraddseasoncourt = resp?.data?.message;
         // toast.success(resp?.data?.message);
         this.citydrop = resp?.data?.data;
        
       });
       return;
     }
     runInAction(() => {
       this.errloadseason = resp?.data?.message;
     });
   })
   .catch((error) => {
     runInAction(() => {
         if(!error.response){
             this.errloadseason = error?.message;
             // toast.error(error?.message);
         }
         this.errloadseason = error?.response?.data?.message;
         // toast.error(error?.response?.data?.message);
     })
     return;
 });
 }
}




export const SeasonStoreContext = createContext(new SeasonStore());
export const useSeasonStore = () => useContext(SeasonStoreContext);