import { createContext, useContext } from "react";
import { makeAutoObservable, observable, runInAction } from "mobx";
import { postMethodData, getMethodData, putMethod, delMethod, patchMethodData } from "../../ApiMethods/api";
import { toast } from "react-toastify";


export class CmsStore {

  Token = null;
  loadCmslist = false;
  title="";
  content="";
  saved=false;
  startdate="";
  enddate="";
  discount="";
  discount_type="";
  status="";
  cms = [];
  errloadCms = "";
  nolist = false;
  loadCms = false;
  cms_id = ""
  loadeditCms=false

  constructor(isloading = false) {
    makeAutoObservable(this);
    this.Token = localStorage.getItem("Admintoken");
    if (isloading.isloading) {
      this.getCms();
      return
    }
    if (isloading.editloading) {
      this.getcmsbyid(isloading.id);
      return
    }
  }
  getbytitle=(text)=>{
    this.title = text;
  }
  getcontent=(text)=>{
    this.content = text;
  }
getcmsid=(text)=>{
this.cms_id = text;
}
getstatus = (text) => {
    this.status = text;
  };


  // get Coupen list
  getCms = () => {
    runInAction(() => {
      this.loadCms = true;
      this.nolist= false;
      this.errloadCms = "";
      this.saved = false;
    });
    getMethodData("/api/admin/cms-page", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadCms = false;
            this.errloadCms = resp?.data?.message
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadCms = false;
            this.errloadCms = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.cms = resp?.data?.data;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadCms = false;
          this.addseasoncourt = false;
          this.loadSeason = false;
          this.errloadseason = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadCms = false;
          if (!error.response) {
            this.errloadCms = error?.message;
            toast.error(error?.message);
          }
          this.errloadCms = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        })
        return;
      });
  };

  //Active and inactive
  statusChange = () => {
    runInAction(() => {
      this.loadSeason = true;
      this.errloadseason = "";
    });
    patchMethodData(`/api/admin/cms-page/status/${this.cms_id}`, { isActive: `${!this.status}` }, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.errloadCms = resp?.data?.message;
            toast.error(resp?.data?.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.errloadCms = resp?.data?.message;
            this.getCms();
            toast.success(resp?.data?.message);
          })
          return;
        }
        runInAction(() => {
          this.errloadCms = resp?.data?.message;
        })
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.errloadCms = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.errloadCms = error?.response?.data?.message;
        })
        return;
      })
  };

//   //get Cms by id
  getcmsbyid = (id) => {
    getMethodData(`/api/admin/cms-page/${id}`, this.Token)
      .then((resp) => {
        runInAction(() => {
          this.errloadCms = "";
        });
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.errloadCms = resp.data.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
          this.errloadCms = resp?.data?.message;
          this.title = resp?.data?.data?.name;
          this.content = resp?.data?.data?.content;
          this.status = resp?.data?.data?.isActive;
          })
          return;
        }
        runInAction(() => {
          this.errloadCms = resp.data.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.errloadCms = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.errloadCms = error?.response?.data?.message;
        })
        return;
      })
  }


//   //update method
  updatedcms = (id) => {
    runInAction(() => {
      this.loadeditCms = true;
      this.errloadCms = "";
    });
    putMethod(`/api/admin/cms-page/${id}`,
      {
        name: this.title,
        content: this.content,
        isActive: this.status,
      },
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadeditCms = false;
            this.errloadCms = resp?.data?.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
              this.loadeditCms = false;
              this.errloadCms = false;
              this.erraddcity = resp?.data?.message;
              toast.success(resp.data.message);
              this.saved = true;
            })
            return
        }
        runInAction(() => {
          this.loadeditCms = false;
          this.errloadCms = resp?.data?.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
            if(!error.response){
              this.loadeditCms = false;
              this.errloadCms = error?.message;
              toast.error(error?.message);
            }
              toast.error(error?.response?.data?.message);
              this.loadeditCms = false;
              this.errloadCms = error?.response?.data?.message;
        })
        return;
    })
  };
}




export const CmsStoreContext = createContext(new CmsStore());
export const useCmsStore = () => useContext(CmsStoreContext);