import React, { useEffect, useState } from 'react'
import { Input } from 'react-daisyui'
import {  MdOutlineRefresh, MdOutlineSearch } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useSeasonStore} from "../store/SeasonStore";

const Filter = observer(() => {
    const {startdate, enddate,addSeason ,getstartdate ,getenddate, citydrop, updatedcitynameid ,getname,getstatus,saved,getFilterSeason,getSeasons,getyear } = useSeasonStore();
  
    const [startdatestate,setstartdatestate] = useState(null)

    const [years, setYears] = useState([]);
    useEffect(() => {
      const getCurrentYear = () => {
        return new Date().getFullYear();
      };
  
      const generateYears = () => {
        const currentYear = getCurrentYear();
        const yearArray = [];
  
        for (let i = 0; i <= 5; i++) {
          const yearcurr = currentYear + i;
          yearArray.push(yearcurr);
        }
  
        setYears(yearArray);
      };
  
      generateYears();
    }, []);
    
    const Reset=()=>{
        getname("")
        getyear("")
        setstartdatestate(null)
        updatedcitynameid("")
        getstartdate("")
        getenddate("")
        getstatus("")
    }
  return (
    <>
     
        <div className="card bg-base-100 shadow-md p-6  mt-3 mb-10">
            <form onSubmit={(e) => {getFilterSeason();e.preventDefault()}}>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
                <div>
              <label
                htmlFor="SeasonName"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Season Name
              </label>
              <select
                id="SeasonName"
                onChange={(e) => getname(e.target.value)}
                name="SeasonName"
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                  <option value="">All</option>
                <option value="Spring">Spring</option>
                <option value="Summer">Summer</option>
                <option value="Fall">Fall</option>
                <option value="Winter">Winter</option>
              </select>
            </div>
            <div>
                        <label htmlFor="City" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">City</label>
                        <select     
                        defaultValue=""
                        id="city"
                        name="city"
                        onChange={(e) => updatedcitynameid(e.target.value)}
                        className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                            {/* <option value="" disabled className="hidden">Select city</option> */}
                            <option value="" className="">All</option>
                            {citydrop.map((data,index)=> (
                                <option value={data?._id} key={index}>{data?.city_name}</option>
                         
                            ))}
                        </select>  
                    </div>
                    <div>
                        <label htmlFor="Year" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Year</label>
                        <select 
                        defaultValue=""
                        name="Year"
                        id="Year"
                         onChange={(e) => getyear(e.target.value)}
                        className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                            <option value="" disabled className="hidden">Select Year</option>
                              {years.map((year, index) => (
                              <option value={year} key={index}>
                              {year}
                              </option>
                              ))}

                            {/* <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option> */}
                        </select>
                    </div>
                    
                    <div>
                        <label htmlFor="startdate" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Start Date</label>
                        <input 
                        name="startdate" 
                        placeholder="" 
                        onChange={(e) =>{ getstartdate(e.target.value);setstartdatestate(e.target.value)}}
                        id="startdate" 
                        value={startdate}
                        // min={new Date().toISOString().split('T')[0]}
                        // max="2025-01-01"
                        type="date" 
                        autoComplete="off" 
                        className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
                        />
                    </div>
                    <div>
                        <label htmlFor="enddate" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">End Date</label>
                
                        <input 
                        name="enddate" 
                        placeholder="" 
                        onChange={(e) => getenddate(e.target.value)}
                        id="enddate" 
                        disabled={startdatestate === null ? true: false}                   
                        min={new Date(startdatestate ? startdatestate : null).toISOString().split('T')[0]}
                        // value={enddate}
                        type="date" 
                        autoComplete="off" 
                        className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
                        />
                    </div>
            
                    <div>
                        <label htmlFor="Status" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Status</label>
                        <select 
                        name="Status"
                        id="Status"
                        defaultValue=""
                        onChange={(e) => getstatus(e.target.value)}
                        className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                             <option value="" className="">All</option>
                            <option value="true">Active</option>
                            <option value="false">Inactive </option>
                        </select>
                    </div>
                </div>
                <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                <button type='reset'  onClick={()=>{getSeasons(); Reset()}} className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"><MdOutlineRefresh className="mr-1"/>Reset</button>
                <button type='submit'  className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"><MdOutlineSearch className="mr-1"/>Search</button>
                </div>
            </form>
        </div>
    </>
  )
});

export default Filter;