import React, { useEffect, useState } from "react";
import { Input } from "react-daisyui";
import { MdOutlineRefresh, MdOutlineSearch } from "react-icons/md";

import { observer } from "mobx-react";
import { useDivisionStore } from "../store/DivisionStore";
import { useLeagueStore } from "../../LeagueManagement/store/LeagueStore";

const Filter = observer((props) => {
  const {
    homecourtdrop,
    leaguename,
    getDivisionname,
    Leaguesdropdown,
    getleague,
    getDivisionList,
    allclear,
    getFilterDivision,
    gethomecourt,
    getLeagueList,
    getlevel,
    level,
    Dname
  } = useDivisionStore();

  

  useEffect(() => {
    if (props.league) {
      getleague(props.league);
      // getFilterDivision()
    }
  }, []);


  const {
    leveldropdown,
    getleveldropdown
  } = useLeagueStore();

  useEffect(() => {
    getleveldropdown(leaguename)
  },[])

  return (
    <>
      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <form
          onSubmit={(e) => {
            getFilterDivision();
            e.preventDefault();
          }}
        >
          <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
            <div>
              <label
                htmlFor="DivisionName"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Division Code
              </label>
              <Input
                name="DivisionName"
                placeholder="Division Code"
                 value={Dname}
                onChange={(e) => getDivisionname(e.target.value)}
                id="DivisionName"
                type="number"
                min={0}
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>

            <div>
              <label
                htmlFor="LeagueName"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                League Name
              </label>
              <select
                id="LeagueName"
                name="LeagueName"
                value={leaguename}
                onChange={(e) => getleague(e.target.value)}
                className={`select capitalize focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                {/* <option value="" disabled className="hidden">Select city</option> */}
                <option value="" className="">
                  All
                </option>
                {Leaguesdropdown.map((data, index) => (
                  <option value={data?._id} className="">
                  {data?.name}
                  {/* Display the league name if it exists */}
                </option>
                ))}
              </select>
        
            </div>
            <div>
            <label
                htmlFor="level"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Level
              </label>
              <select
                   onChange={(e) => getlevel(e.target.value)}
                   value={level}
                  className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                  // aria-label="Default select example"
                >  
                 <option value=""> Select Level</option>
                  {leveldropdown?.map((data,index)=> (
                    <option value={data?._id} key={index}>{data?.name.toFixed(1)} </option>
                  ))}
                </select>
            </div>
            <div>
              <label
                htmlFor=" HomeCourt"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Home Court
              </label>
              <select
                defaultValue=""
                // value={newcitynameid}
                id="HomeCourt"
                name="HomeCourt"
                onChange={(e) => gethomecourt(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                {/* <option value="" disabled className="hidden">Select city</option> */}
                <option value="" className="">
                  All
                </option>
                {homecourtdrop.map((data, index) => (
                  <option value={data?._id} key={index}>
                    {data?.displayName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
            <button
              type="reset"
                onClick={() => {
                  allclear();
                  getDivisionList();
                }}
              className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
            >
              <MdOutlineRefresh className="mr-1" />
              Reset
            </button>
            <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
              <MdOutlineSearch className="mr-1" />
              Search
            </button>
          </div>
        </form>
      </div>
    </>
  );
});
export default Filter;