import React from "react";
import {
  MdHome,
  MdMoney,
  MdOutlinedFlag,
  MdSportsHandball,
  MdSportsTennis,
  MdDelete,
  MdPriceCheck,
  MdOutlineGridOn,
  MdBuildCircle,
  MdPageview,
  MdFirstPage,
  MdFindInPage,
  MdRequestPage,
  MdRestorePage,
  MdOutlineGroupOff,
  MdSportsBasketball,
  MdLogoDev,
  MdNewspaper,
  MdArrowLeft,
  MdArrowRightAlt,
  MdBugReport,
  MdCancel,
  MdAccountCircle,
  MdOutlineVpnKey,
  MdLogout
} from "react-icons/md";
import {
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  ListItemSuffix,
  Chip,
  Accordion,
  AccordionHeader,
  AccordionBody,
  Alert,
  Input,
  Drawer,
  Card,
} from "@material-tailwind/react";
import { RxCalendar, RxCross1, RxDashboard, RxDot, RxFigmaLogo, RxHamburgerMenu, RxPerson, RxSketchLogo, RxTransparencyGrid } from "react-icons/rx";
import { observer } from "mobx-react";
import { HomeStore, HomeStoreContext, useHomeStore } from "../Store/HomeStore";
import { Link, useNavigate } from "react-router-dom";
import bubble from "../../assets/images/Background.png"
import profile from "../../assets/images/Image.png"
import bubble2 from "../../assets/images/Background (1).png"
import editIcon from "../../assets/images/Icon.png"
import coustomer from "../../assets/images/Customers.png"
import order from "../../assets/images/order.png"
import { Button, Dropdown } from "react-daisyui";
import { useAuthStore } from "../../Auth/store/Auth";
import logo from "../../assets/images/logo.png"
const CardDashbord = () => {
  const store = new HomeStore({
    isloading: true,
    editloading: false,
  });
  return (
    <HomeStoreContext.Provider value={store}>
      <Screen />
    </HomeStoreContext.Provider>
  );
};
export default CardDashbord;

const Screen = observer(() => {
  const {
    todays_order,
    recent_orders,
    Customer_count,
    Order_Count,
    buyer_details,
    loaduser,
    current_leagues,
    matches_this_week,
    tolal_income,
    tolal_leagues,
    tolal_matches,
    tolal_players,
    tolal_seasons,
    tolal_users,
    deleteuser_count,
    total_tieBreakerCount,
    approval_pending_home_courts,
  } = useHomeStore();
  const { logout, setpasswordpopup, admin_name } = useAuthStore();
  const navigate = useNavigate()
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [open, setOpen] = React.useState(0);
  const [openAlert, setOpenAlert] = React.useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };
  console.log("todays_order", todays_order);
  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);
  const handleImageError = (event) => {
    // This function will be called when the image fails to load
    event.target.src = logo; // Replace with the path to your default image
  };
  return (
    <>
      <div className=" flex justify-center  gap-3 w-full">
        {loaduser ?
          <>
            <svg
              className="animate-spin  mr-0  mt-[17%] h-[3%] w-[3%] text-color_theme"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </>
          :
          <>
            <div className={`flex gap-5 ${window.innerWidth <= 820 ? "flex-col" : "flex-row"}   mt-5 w-8/12`}>
              <div className="flex h-[395px] flex-col space-y-3">
                <div className="text-white w-[268px] gap-[12px] flex flex-col space-y-3 relative bg-[#ff6600] rounded-[12px] h-screen" >
                  <div>
                    <span className="text-[18px] absolute top-[24px] left-[24px] font-bold">
                      Todays Order
                    </span>
                    <span className=" absolute top-[6.07px]  left-[209.15px]">
                      <img src={bubble} />
                      <div className="w-2 h-2 absolute top-[17.07px] left-[13.15px]  rounded-full bg-white"></div>
                    </span>
                    <span className=" w-[228px] h-[228px]  absolute bottom-[6.07px]  left-[170.15px]">
                      <img src={bubble2} />
                    </span>

                  </div>
                  {todays_order == undefined ?
                    <span className="absolute left-[39%] top-[50%]">   NO data</span>
                    :
                    <>
                      <div className="flex space-x-5 absolute top-[83px] left-[24px] " >
                        <span className="bg-white w-[44px] h-[44px] rounded-full" >
                          <img

                            onError={handleImageError}
                            crossOrigin="anonymous" src={BASE_URL + `/uploads/userProfile/${todays_order?.image}`}
                            className="w-[44px] h-[44px] rounded-full" />
                        </span>
                        <div className="flex flex-col " >
                          <span className="text-[14px] font-bold" >{todays_order?.buyer_fullname}</span>
                          <span className="text-[11px] font-normal text-[#D6E1E6]">
                            {`${todays_order?.addr_city} ${" "} ${todays_order?.addr_title} ${" "}   ${todays_order?.addr_zip} `}
                          </span>
                        </div>

                      </div>
                      <div className=" space-y-2 absolute top-[150px] left-[24px] " >
                        <div className="text-[14px] font-normal text-[#D6E1E6]">
                          Order date
                        </div>
                        <div className="text-[14px]  text-[#FFFFFF] font-bold">

                          {todays_order?.order_date_time}
                        </div>
                      </div>
                      <div className=" space-y-2 absolute top-[205px] left-[24px] " >
                        <div className="text-[14px] font-normal text-[#D6E1E6]">
                          Status -         <span className="text-[14px]  text-[#FFFFFF] font-bold">
                            {todays_order?.order_status}
                          </span>
                        </div>

                      </div>
                      <div className="flex space-x-5 absolute top-[235px] left-[24px] " >
                        <div className="flex flex-col space-y-2" >
                          <div className="text-[14px] font-normal text-[#D6E1E6]">
                            product Name
                          </div>
                          <div className="text-[14px]  text-[#FFFFFF] font-bold">
                            {todays_order?.product_name}

                          </div>
                        </div>

                        <div className="flex flex-col space-y-2" >
                          <div className="text-[14px] font-normal text-[#D6E1E6]">
                            unit
                          </div>
                          <div className="text-[14px]  text-[#FFFFFF] font-bold">
                            {todays_order?.product_qty}

                          </div>
                        </div>
                      </div>
                      <div className="flex space-x-5 absolute top-[305px] left-[24px] " >
                        <div className="flex flex-col space-y-2" >
                          <div className="text-[14px] font-normal text-[#D6E1E6]">
                            Price
                          </div>
                          <div className="text-[14px]  text-[#FFFFFF] font-bold">
                            ₹{todays_order?.total_amount}
                          </div>
                        </div>

                        <div className="flex flex-col mt-2 " >
                          <Link to={`/order/product/${todays_order?.order_id}/${todays_order?.total_amount}`} className=" ml-7 bg-white flex space-x-[12px] p-3 px-7 rounded-full text-[#092C4C] text-[13px]  cursor-pointer"><span> See Detail </span></Link>
                        </div>
                      </div>
                    </>
                  }

                </div>



              </div>

              <div className="flex flex-col   space-y-3">
                <div className={`flex  ${window.innerWidth <= 820 ? "flex-col " : "flex-row"}  gap-3`}>
                  <div>
                    <div className="bg-white relative w-[205px] h-[133px]">
                      <span className="absolute top-[24px] left-[24px] text-[18px] text-[#7E92A2] font-medium ">Customers</span>
                      <span className="font-semibold absolute text-[25px] text-[#092C4C] top-[64px] left-[24px]" >{Customer_count == "" ? "0" : Customer_count}</span>
                      <img className="absolute top-[44px] left-[126px]  " width={'70px'} src={coustomer} />
                    </div>
                  </div>
                  <div>
                    <div className="bg-white relative w-[205px] h-[133px]">
                      <span className="absolute top-[24px] left-[24px] text-[18px] text-[#7E92A2] font-medium ">orders</span>
                      <span className="font-semibold absolute text-[25px] text-[#092C4C] top-[64px] left-[24px]" >{Order_Count == "" ? "0" : Order_Count}</span>
                      <img className="absolute top-[44px] left-[126px]  " width={'70px'} src={order} />
                    </div>
                  </div>
                </div>
                <div className={`bg-white ${window.innerWidth <= 820 ? "w-[278px] " : "w-[411px]"}  h-[250px] rounded-[12px]`} >
                  <div className="flex mt-5 px-5  justify-between">
                    <span className="md:text-[18px] text-[13px]   font-bold">
                      Recent Orders
                    </span>
                    <Link to={"/order"} className="md:text-[14px] cursor-pointer text-[11px] text-[#ff6600] font-medium">
                      View All
                    </Link >
                  </div>

                  <div className="max-h-[300px] px-5  overflow-auto  ">
                    {recent_orders.length == 0 ?

                      <span className="flex justify-center mt-16" >   NO data</span>
                      :

                      recent_orders.map((key, index, length) => (
                        (index == 0 || index == 1 || index == 2) &&
                        <div key={index} className="flex justify-between  mt-5 ">

                          <div className="flex " >
                            {/* <img className="w-[37px] h-[37px]" src={profile} /> */}
                            <div className="flex flex-col ">

                              <span className="md:text-[14px] text-[13px] ml-3 text-[#092C4C] font-bold">
                                {` ${key?.addr_title}`}


                              </span>
                              <span className="md:text-[14px] text-[13px] ml-3 text-[#7E92A2] font-normal" >
                                {`  ${key?.addr_address1}`}
                              </span>


                            </div>

                          </div>

                          <div className="flex items-end  flex-col ">

                            <span className="md:text-[14px] text-[12px] ml-3  text-[#092C4C] font-bold">
                              ₹{key?.total_amount}

                            </span>
                            <span className="md:text-[14px] text-[12px] ml-3 text-[#7E92A2] font-normal" >
                              {key?.order_date_time}
                            </span>


                          </div>

                        </div>



                      ))



                    }


                  </div>




                </div>


                {/* <div className="bg-white md:w-[411px]  w-[282px] h-[330px]  rounded-[12px]" >

              <div className="flex border-b-[1px] border-gray-300 pb-3 justify-between mt-5 ">
                <div className="flex px-5  " >
                  <img className="w-[37px] h-[37px]" src={profile} />
                  <div className="flex flex-col  ">

                    <span className="md:text-[16px] text-[13px] ml-3 text-[#092C4C] font-bold">
                      Arpan

                    </span>
                    <span className="md:text-[14px] text-[12px] ml-3 text-[#7E92A2] font-normal" >
                      Dehradun, UK 248001
                    </span>


                  </div>

                </div>

                <div className="flex items-center px-5 text-center justify-center  space-x-1">
                  <button className="p-2 px-3 md:text-[12px] text-[11px] font-medium text-[#ff6600] bg-[#ECECFE] rounded-full " >IN PROGRESS</button>
                  <span><MdArrowRightAlt /></span>

                </div>

              </div>

              <div className="px-7">
                <ol className="relative text-gray-500 border-s px-5 mt-3 border-gray-200 dark:border-gray-700 dark:text-gray-400">
                  <li className="mb-10 ms-6">
                    <span className="absolute flex items-center justify-center w-8 h-8 bg-[#ff6600] text-white rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                      <RxDot />
                    </span>
                    <span className="font-normal text-[14px] text-[#7E92A2] leading-tight">17 Nov 2021</span>
                    <p className="text-[14px] text-[#092C4C] font-medium">Design in printing Progress</p>
                  </li>
                  <li className="mb-10 ms-6">
                    <span className="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
                      <RxDot />
                    </span>
                    <span className="font-normal text-[14px] text-[#7E92A2] leading-tight">19 Nov 2021</span>
                    <p className="text-[14px] text-[#092C4C] font-medium">Dispactured</p>
                  </li>

                </ol>
                <p className="text-[16px] cursor-pointer text-center mt-5 text-[#ff6600] font-bold" >Load More</p>
              </div>





            </div> */}
              </div>

            </div>

            <div className={`  ${window.innerWidth <= 820 ? "hidden" : "flex "}  flex-col`} >

              <div className="w-4/12">

                <div className="bg-[#EEF6FBE5] px-5 h-[410px] overflow-auto  w-[361px] " >
                  <div className="flex  justify-between">
                    <span className="text-[18px] mt-5    font-bold">
                      Customers
                    </span>
                    <Link to={"/customerlist"} className="text-[14px] mt-5 cursor-pointer  text-[#ff6600] font-medium">
                      View All
                    </Link>
                  </div>
                  {buyer_details.length == 0 ?
                    <span className="flex justify-center mt-28" >   NO data</span>
                    :
                    buyer_details.map((key, index) => (
                      (index == 0 || index == 1 || index == 2 || index == 3  || index == 5) &&
                      <div className="flex  justify-between mt-5 ">
                        <div className="flex " >
                          {/* <img className="w-[37px] h-[37px]" src={profile} /> */}
                          <div className="flex flex-col ">

                            <span className="text-[16px] ml-3 text-[#092C4C] font-bold">
                              {`  ${key?.first_name} ${" "} ${key?.last_name}`}

                            </span>
                            <span className="text-[14px] ml-3 text-[#7E92A2] font-normal" >
                              {key?.email}
                            </span>


                          </div>

                        </div>
                        {/* <div className="flex items-end  flex-col ">
  
  
                      <img src={editIcon} />
  
  
                    </div> */}



                      </div>
                    ))
                  }




                </div>

              </div>



            </div>


            {isDrawerOpen ? (
              <RxCross1 onClick={closeDrawer} className={`fixed ${window.innerWidth <= 820 ? "display" : "hidden"}  top-[5px] right-[25px] h-8 w-[1.3rem] stroke-2 p-0 bg-none z-50 `} />
            ) : (
              <RxHamburgerMenu onClick={openDrawer} className={`fixed ${window.innerWidth <= 820 ? "display" : "hidden"} top-[5px] right-[25px] h-8 w-[1.3rem] stroke-2 p-0 bg-none z-50 `}/>
            )}
            <Drawer className={`" ${isDrawerOpen ? "left-[5%]  drawer" : "left-[-15%]"} ${window.innerWidth <= 820 ? "display" : "hidden"}  "`} open={isDrawerOpen} >
              <Card
                color="transparent"
                shadow={false}
                className="h-[calc(100vh-2rem)] w-full p-4"
              >
                <div className="mb-2 flex items-center gap-4 p-4">
                  <img
                    src="https://docs.material-tailwind.com/img/logo-ct-dark.png"
                    alt="brand"
                    className="h-8 w-8"
                  />
                  <Typography variant="h5" color="blue-gray">
                    Sidebar
                  </Typography>
                </div>
                <div className="w-4/12 ">



                  <div className="bg-[#EEF6FBE5] px-10 w-[361px] h-screen " >
                    <div className=" md:hidden  flex space-x-3  justify-between items-center text-center    p-3 ">
                      <button className="  bg-[#ff6600] flex  space-x-[9px] p-3 px-5 rounded-full text-white text-[14px]  cursor-pointer"><span> Add New </span> <span className="text"  >+</span> </button>
                      <Dropdown vertical="end">
                        {/* <Button color="ghost" className="avatar" shape="circle">
              <div className="w-10 rounded-full">
                <img
                  alt="..."
                  src="https://api.lorem.space/image/face?hash=33791"
                />
              </div>
            </Button> */}
                        <span className="ghost font-semibold cursor-pointer">Welcome, {admin_name == undefined ? "Admin" : admin_name}</span>
                        <Dropdown.Menu className="w-52 menu-compact">
                          {/* <li>
                <a className="justify-between">
                  Profile
                  <span className="badge">New</span>
                
              </li> */}
                          <Link to="/profile">
                            <Dropdown.Item>
                              <MdAccountCircle />Profile
                            </Dropdown.Item>
                          </Link>
                          <label onClick={() => setpasswordpopup()} htmlFor="my-modal-3">
                            <Dropdown.Item>
                              <MdOutlineVpnKey />Change Password
                            </Dropdown.Item>
                          </label>
                          {/* <Dropdown.Item>
                  <RxGear />Settings
                </Dropdown.Item> */}
                          <Dropdown.Item onClick={() => { logout(); navigate("/") }}><MdLogout />Logout</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                 
                  <div className="flex  justify-between">
                    <span className="text-[18px] mt-5    font-bold">
                      Customers
                    </span>
                    <Link to={"/customerlist"} className="text-[14px] mt-5 cursor-pointer  text-[#ff6600] font-medium">
                      View All
                    </Link>
                  </div>
                  {buyer_details.length == 0 ?
                    <span className="flex justify-center mt-28" >   NO data</span>
                    :
                    buyer_details.map((key, index) => (
                      (index == 0 || index == 1 || index == 2 || index == 3  || index == 5) &&
                      <div className="flex  justify-between mt-5 ">
                        <div className="flex " >
                          {/* <img className="w-[37px] h-[37px]" src={profile} /> */}
                          <div className="flex flex-col ">

                            <span className="text-[16px] ml-3 text-[#092C4C] font-bold">
                              {`  ${key?.first_name} ${" "} ${key?.last_name}`}

                            </span>
                            <span className="text-[14px] ml-3 text-[#7E92A2] font-normal" >
                              {key?.email}
                            </span>


                          </div>

                        </div>
                        {/* <div className="flex items-end  flex-col ">
  
  
                      <img src={editIcon} />
  
  
                    </div> */}



                      </div>
                    ))
                  }


              


                  </div>

                </div>

              </Card>
            </Drawer>

          </>
        }
      </div>

    </>
  );
});
