import { createContext, useContext } from "react";
import { action, makeAutoObservable, makeObservable, observable, runInAction } from "mobx";
import { postMethodData, getMethodData, putMethod, delMethod, patchMethodData } from "../../ApiMethods/api";
import { toast } from "react-toastify";
const SEO_URL = process.env.REACT_APP_SEO_URL;





export class BrandStore {

    Token = null;
    Brand = [];
    filterSearch = "";
    PaginationPage = "1";
    TotalPages = "";
    BannerDimiensions = ""
    crop = {};
    errLoadBrand = "";
    LoadBrand = false;
    delateLoader = false;
    nolist = false;
    brandpopup = false;
    deletepopup = false;
    brandname = "";
    status = "1";
    statesid = "";
    brandid = "";
    seoUrl = "";
    statuspopup = false;
    addbrandname = "";
    imageurl = "";
    logoimage = "";
    bannerimage = "";
    Base64logoImage = "";
    Base64BannerImage = "";
    logofile = [];
    allbrand = [];
    alldeactivate = 'false';
    isBannerUpdated = "false";
    isLogoUpdated="false"
    checkeddd = '';
    BrandFormDetails = {
        "name": "",
        "seo_url": "",
        "logo_image": "",
        "banner_image": "",
        "is_active": ""
    };
    saved = false;

    constructor(isloading = false) {
        makeAutoObservable(this, {
            addbrandname: observable,
            status: observable,
            errorMessages: observable,
            setaddbrandname: action,
            getstatus: action,
            resetErrors: action,
        });

        this.Token = localStorage.getItem("Admintoken");
        if (isloading.isloading) {
            this.getFilterBrand();

            //   this.getStateList();
            return
        }
        if (isloading.addloading) {
            //   this.getStateList();
        }
        if (isloading.brandeditloading) {
            this.getbrandform(isloading.id);
            return
        }
    }
    //All Clear 
    allclear = () => {
        this.filterSearch = ""
        this.getFilterBrand();
        //   this.status="";
        //   this.statesid="";
    }
    setimage = (text, type) => {
        this.imageurl = URL.createObjectURL(text[0]);
    }

    setalldeactivate = (text) => {
        console.log(text);
        this.alldeactivate = !this.alldeactivate;
    };

    getid = (text) => {
        this.brandid = text;
    };


    // status popup
    setstatuspopup = () => {
        this.statuspopup = !this.statuspopup;
    };


    setallBrand = (text) => {
        this.allbrand = text;
    }

    // get status 
    getstatus = (text) => {
        this.status = text;
    };

    //get city name
    getbrandname = (text) => {
        this.brandname = text;
    };

    setaddbrandname = (text) => {
        this.addbrandname = text;
    };
    setCrop = (text) => {
        this.crop = text;
    };

    setBannerDimiensions = (text) => {
        this.BannerDimiensions = text;
    };

    setlogofile = (e) => {
        this.logofile = e;
        console.log(this.logofile);
    };

    setbrandpopup = (e) => {
        this.brandpopup = e;
    };
    setdeletepopup = (e) => {
        this.deletepopup = e;
    };
    setdelateLoader = (e) => {
        this.delateLoader = e;
    }
    getToken = (e) => {
        this.Token = e;
    }
    getseourl = (text) => {
        // console.log(`https://demo.e-kart.com/${text}`);
        this.seoUrl = text;
    };
    getlogoimage = (text) => {
        console.log("checking in the brand", text);
        this.logoimage = text;
    };
    getbanner_image = (text) => {
        console.log(text);
        this.bannerimage = text;
    };
    SetfilterSearch = (text) => {
        this.filterSearch = text;
    };
    setPaginationPage = (text) => {
        this.PaginationPage = text;
    };
    SetTotalPages = (text) => {
        this.TotalPages = text;
    };
    setchecked = (text) => {
        this.checkeddd = text;
    };
    setisBannerUpdated= (text) => {
        this.isBannerUpdated = text;
    };
    setisLogoUpdated= (text) => {
        this.isLogoUpdated = text;
    };

    isBase64 = (str) => {
        try {
            return btoa(atob(str)) === str;
        } catch (err) {
            return false;
        }
    };
    setBrandFormDetails = (brandDetail) => {
        this.BrandFormDetails = {
            ...this.BrandFormDetails,
            name: brandDetail.name || "", // Use default value if name is undefined
            seo_url: brandDetail.seo_url || "", // Use default value if seo_url is undefined
            // logo_image: this.isBase64( this.BrandFormDetails.logo_image) ? this.logofile.length == 0 ? brandDetail.logo_image    : this.BrandFormDetails.logo_image  : this.logofile.length !== 0 ? this.BrandFormDetails.logo_image :  brandDetail.logo_image   || "",
            // banner_image: this.isBase64(brandDetail.banner_image) ? this.BrandFormDetails.banner_image : brandDetail.banner_image || "",
            logo_image: brandDetail.logo_image || "",
            banner_image: brandDetail.banner_image || "",
            is_active: brandDetail.is_active
        };
    };
    // setBrandFormDetails = (text) => {
    //     this.BrandFormDetails = text;
    //     console.log("setBrandFormDetails", this.BrandFormDetails.brandname);
    // };
    base64toFile(base64Data, fileName, mimeType) {
        if (base64Data) {
            const byteCharacters = atob(base64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                const slice = byteCharacters.slice(offset, offset + 512);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const file = new File(byteArrays, fileName, { type: mimeType });

            return file;
        }

    }

    // get brand list
    getBrandList = () => {
        runInAction(() => {
            this.brandLoader = true;
            this.errLoadBrand = "";
            this.saved = false;
            this.nolist = false;
            this.Brand = [];
        });
        getMethodData(`/api/v1/admin/brand/fetch?page=${this.PaginationPage + 1}`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.brandLoader = false;
                        this.errLoadBrand = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.brandLoader = false;
                        this.errLoadBrand = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.Brand = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadBrand = false;
                    this.errLoadBrand = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.brandLoader = false;
                    if (!error.response) {
                        this.errLoadBrand = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadBrand = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };
    //Filter city
    // filter League court

    getFilterBrand = () => {
        runInAction(() => {
            this.LoadBrand = true;
            this.errLoadBrand = "";
            this.saved = false;
            this.nolist = false;
            this.getBrandList = [];
        });
        postMethodData(`/api/v1/admin/brand/search?page=${ this.PaginationPage}`, {
            keyword: this.filterSearch
        }, this.Token)
            .then((resp) => {

                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.LoadBrand = false;
                        this.errloadleague = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {

                    runInAction(() => {
                        this.LoadBrand = false;
                        this.errloadleague = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.Brand = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.loadcity = false;
                    this.errloadcuty = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.LoadBrand = false;

                    if (!error.response) {
                        this.errloadleague = error?.message;
                        toast.error(error?.message);
                    }
                    this.errloadleague = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    // add new brand
    addbrand = () => {
        runInAction(() => {
            this.addloadbrand = true;
            this.errLoadBrand = "";
        });
        const formData = new FormData();
        if (this.logoimage) {
            const Logobase64String = this.logoimage.split(',')[1];
            const logoFile = this.base64toFile(Logobase64String, 'logo.png', 'image/png');
            formData.append('logo_image', logoFile);
        }
        else {
            formData.append('logo_image', null);
        }
        if (this.bannerimage) {
            const Bannerbase64String = this.bannerimage.split(',')[1];
            const BannerFile = this.base64toFile(Bannerbase64String, 'logo.png', 'image/png');
            formData.append('banner_image', BannerFile);
        }
        else {
            formData.append('banner_image', null);
        }
        if (this.crop.dimensions?.height) {
            formData.append('logo_height', this.crop.dimensions?.height);
        }
        else {
            formData.append('logo_height', null);
        }

        if (this.crop.dimensions?.width) {
            formData.append('logo_width', this.crop.dimensions?.width);
        }
        else {
            formData.append('logo_width', null);

        }
        if (this.BannerDimiensions?.dimensions?.height) {
            formData.append('banner_height', this.BannerDimiensions?.dimensions?.height);
        }
        else {
            formData.append('banner_height', null);
        }

        if (this.BannerDimiensions?.dimensions?.width) {
            formData.append('banner_width', this.BannerDimiensions?.dimensions?.width);
        }
        else {
            formData.append('banner_width', null);
        }
        formData.append('name', this.addbrandname);
        formData.append('seo_url', `${SEO_URL}${this.seoUrl}`);
        formData.append('status', this.status);



        postMethodData("/api/v1/admin/brand/add", formData, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.addloadbrand = false;
                    this.errLoadBrand = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.addloadbrand = false;
                    this.errLoadBrand = resp?.data?.message;
                    // toast.success(resp.data.message);
                    this.getFilterBrand();
                    this.saved = true;
                })
                return
            }
            runInAction(() => {
                this.addloadbrand = false;
                this.errLoadBrand = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadbrand = false;
                    if (!error.response) {
                        this.errLoadBrand = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadBrand = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    //Active and inactive
    statusChange = () => {
        runInAction(() => {
            this.brandLoader = true;
            this.brandpopup = true;

            this.errLoadBrand = "";
        });
        postMethodData(`/api/v1/admin/brand/update-status`, {

            "brandId": JSON.stringify(this.brandid),
            "status": JSON.stringify(this.status)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.brandLoader = false;
                        this.brandpopup = false;
                        this.errLoadBrand = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.brandLoader = false;
                        this.brandpopup = false;

                        this.errLoadBrand = resp?.data?.message;
                        this.getFilterBrand();
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.brandLoader = false;
                    this.brandpopup = false;

                    this.errLoadBrand = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.brandLoader = false;
                        this.brandpopup = false;

                        this.errLoadBrand = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.brandLoader = false;
                    this.brandpopup = false;

                    this.errLoadBrand = error?.response?.data?.message;
                })
                return;
            })
    };
    // Bulk Active and inactive
    BulkstatusChange = (allbrand) => {
        runInAction(() => {
            this.brandLoader = true;
            this.brandpopup = true;

            this.errLoadBrand = "";
        });

        postMethodData(`/api/v1/admin/brand/toggle-bulk-status`, {

            "branchId": JSON.stringify(allbrand),
            "status": JSON.stringify(this.alldeactivate ? 1 : 0)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.brandLoader = false;
                        this.brandpopup = false;
                        this.errLoadBrand = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.brandLoader = false;
                        this.brandpopup = false;

                        this.errLoadBrand = resp?.data?.message;
                        this.getFilterBrand();
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.brandLoader = false;
                    this.brandpopup = false;

                    this.errLoadBrand = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.brandLoader = false;
                        this.brandpopup = false;

                        this.errLoadBrand = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.brandLoader = false;
                    this.brandpopup = false;

                    this.errLoadBrand = error?.response?.data?.message;
                })
                return;
            })
    };

    //Delete method
    deletefunction = () => {
        runInAction(() => {
            this.setdelateLoader(true);
            this.deletepopup = true;
            this.errLoadBrand = "";
        });
        postMethodData(`/api/v1/admin/brand/delete`, {

            "brandId": JSON.stringify(this.brandid),

        }, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.LoadBrand = false;
                    this.setdelateLoader(true);
                    this.deletepopup = true;
                    this.errLoadBrand = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.LoadBrand = false;
                    this.setdelateLoader(false);
                    this.deletepopup = false;
                    this.errLoadBrand = resp?.data?.message;
                    this.getFilterBrand();
                    // toast.success(resp.data.message);
                })
                return;
            }
            runInAction(() => {
                this.LoadBrand = false;
                this.errLoadBrand = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadcity = false;
                    if (!error.response) {
                        this.LoadBrand = false;
                        this.setdelateLoader(false);
                        this.deletepopup = false;
                        this.errLoadBrand = error?.message;
                        toast.error(error?.message);
                    }
                    this.LoadBrand = false;
                    this.errLoadBrand = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            })
    }
    // Bulk Delete 
    BulkDelete = (allbrand) => {
        runInAction(() => {
            this.brandLoader = true;
            this.brandpopup = true;

            this.errLoadBrand = "";
        });

        postMethodData(`/api/v1/admin/brand/multi-delete`, {

            "brandId": JSON.stringify(allbrand),
            // "status": JSON.stringify(this.alldeactivate ? 1 : 0)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.brandLoader = false;
                        this.brandpopup = false;
                        this.errLoadBrand = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.brandLoader = false;
                        this.brandpopup = false;

                        this.errLoadBrand = resp?.data?.message;
                        this.getFilterBrand();
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.brandLoader = false;
                    this.brandpopup = false;

                    this.errLoadBrand = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.brandLoader = false;
                        this.brandpopup = false;

                        this.errLoadBrand = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.brandLoader = false;
                    this.brandpopup = false;

                    this.errLoadBrand = error?.response?.data?.message;
                })
                return;
            })
    };

    //get Brand by id
    getbrandform = (id) => {
        runInAction(() => {
            this.brandLoader = true;
            this.brandpopup = true;

            this.errLoadBrand = "";
        });
        postMethodData(`/api/v1/admin/brand/form`, {

            "brandId": id,
            // "status": JSON.stringify(this.status)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.brandLoader = false;
                        this.brandpopup = false;
                        this.errLoadBrand = resp?.data?.message;
                        // toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.setBrandFormDetails(resp.data.data.brandDetail);
                        this.Base64BannerImage = resp.data.data.brandDetail.banner_image;
                        this.Base64logoImage = resp.data.data.brandDetail.logo_image;
                        this.brandLoader = false;
                        this.brandpopup = false;
                        this.brandname = resp.data.data.brandDetail.name;
                        this.errLoadBrand = resp?.data?.message;
                    })
                    return;
                }
                runInAction(() => {
                    this.brandLoader = false;
                    this.brandpopup = false;

                    this.errLoadBrand = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.brandLoader = false;
                        this.brandpopup = false;

                        this.errLoadBrand = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.brandLoader = false;
                    this.brandpopup = false;

                    this.errLoadBrand = error?.response?.data?.message;
                })
                return;
            })
    };



    //update method
    updateBrand = (id) => {
        runInAction(() => {
            this.addloadbrand = true;
            this.errLoadBrand = "";
        });

        const formData = new FormData();
        if (this.BrandFormDetails.logo_image) {
            const Logobase64String = this.BrandFormDetails.logo_image?.split(',')[1];
            const logoFile = this.base64toFile(Logobase64String, 'logo.png', 'image/png');
            formData.append('logo_image', logoFile);
        }


        if (this.BrandFormDetails.banner_image) {
            const Bannerbase64String = this.BrandFormDetails.banner_image?.split(',')[1];
            const BannerFile = this.base64toFile(Bannerbase64String, 'logo.png', 'image/png');
            formData.append('banner_image', BannerFile);
        }


        if (this.crop.dimensions?.height) {
            formData.append('logo_height', this.crop.dimensions?.height);
        }


        if (this.crop.dimensions?.width) {
            formData.append('logo_width', this.crop.dimensions?.width);
        }

        if (this.BannerDimiensions?.dimensions?.height) {
            formData.append('banner_height', this.BannerDimiensions?.dimensions?.height);
        }


        if (this.BannerDimiensions?.dimensions?.width) {
            formData.append('banner_width', this.BannerDimiensions?.dimensions?.width);
        }

        formData.append('brandId', id);
        formData.append('name', this.BrandFormDetails.name);
        formData.append('seo_url', this.BrandFormDetails.seo_url);
        formData.append('is_active', this.BrandFormDetails.is_active);
        // formData.append('isBannerUpdated', false  );
        // formData.append('isLogoUpdated', true  );


        putMethod(`/api/v1/admin/brand/edit`,
            formData,
            this.Token
        )
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.addloadcity = false;
                        this.erraddcity = resp?.data?.message;
                        toast.error(resp.data.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.addloadcity = false;
                        this.erraddcity = resp?.data?.message;
                        toast.success(resp.data.message);
                        // this.getCity();
                        this.getBrandList();
                        this.saved = true;
                    })
                    return
                }
                runInAction(() => {
                    this.addloadcity = false;
                    this.erraddcity = resp?.data?.message;
                    toast.error(resp.data.message);
                });
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.addloadcity = false;
                        this.erraddcity = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.addloadcity = false;
                    this.erraddcity = error?.response?.data?.message;
                })
                return;
            })
    };
}




export const BrandStoreContext = createContext(new BrandStore());
export const useBrandStore = () => useContext(BrandStoreContext);
