import { createContext, useContext } from "react";
import { makeAutoObservable, observable, runInAction } from "mobx";
import {
  postMethodData,
  getMethodData,
  putMethod,
  delMethod,
  patchMethodData,
} from "../../ApiMethods/api";

import { toast } from "react-toastify";

export class StateStore {
  Token = null;
  Statepopup=false;
  StateLoader = false;
  State = [];
  addstates=""
  dropdownLevel =[]
  FilterLevel = [];
  getstatusss=""
  errStatelevel = "";
  addlevels = "";
  adddescriptions = "";
  editstatus = "";
  addstatusss = "";
  stateid=""
  saved = false;
  nolist = false;
  nostatelist = false;

  status = "";

  loadstate=false
  errloadstate=""

  editloadState = false;
  errState = "";

  constructor(isloading = false) {
    makeAutoObservable(this);
    this.Token = localStorage.getItem("Admintoken");
    if (isloading.seasonlist) {
      this.getStateList();
      this.getDropdownList();
      return;
    }
    if (isloading.isloading) {
      this.getStateList();
     

      return;
    }
    if (isloading.editloading) {
      this.editstatelist(isloading.id);
      
      return;
    }
  }

  //   // status popup
  //   setstatuspopup = () => {
  //     this.statuspopup = !this.statuspopup;
  //   };
  //All clear
  allclear = () => {
this.status="";
this.addstates="";
  };
  setstatepopup = (e) => {
    this.Statepopup = e;
  };
//Add status
addstatus=(text)=>{
    this.status=text
}
// get state id
getstateid=(text)=>{
    this.stateid= text
}
//get status
getstatus=(text)=>{
this.status=text;
}
//Add state
addstate=(text)=>{
    this.addstates=text
}

getToken=(e)=>{
  this.Token=e;
  }
  
  // get State list
  getStateList = () => {
    runInAction(() => {
      this.loadSate = true;
      this.errStatelevel = "";
      this.saved = false;
      this.nolist = false;
      this.State = []
    });
    getMethodData("/api/admin/state", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadleague = false;
            this.errloadhomecourt = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadSate = false;
            this.errStatelevel = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.State = resp?.data?.data;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.addleague = false;
          this.loadSate = false;
          this.errStatelevel = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadSate = false;
          if (!error.response) {
            this.errloadlevel = error?.message;
            toast.error(error?.message);
          }
          this.errloadlevel = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };
  getDropdownList = () => {
    runInAction(() => {
      this.loadlevel = true;
      this.errloadlevel = "";
      this.saved = false;
      this.nostatelist = false;
    });
    getMethodData("/api/admin/state", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadleague = false;
            this.errloadhomecourt = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadleague = false;
            this.errloadhomecourt = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nostatelist = true;
            } else {
              this.nostatelist = false;
            }
            this.dropdownLevel = resp?.data?.data;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.addleague = false;
          this.loadleague = false;
          this.errloadleague = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadSeason = false;
          if (!error.response) {
            this.errloadlevel = error?.message;
            toast.error(error?.message);
          }
          this.errloadlevel = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };
  // add level
  addstatelist = () => {
    runInAction(() => {
      this.loadstate = true;
      this.errloadstate = "";
    });
    postMethodData(
      "/api/admin/state",
      {
        name: this.addstates,
        isActive: this.status,
      },
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadstate = false;
            this.errloadstate = resp?.data?.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadstate = false;
            this.errloadstate = resp?.data?.message;
            toast.success(resp.data.message);
            this.getStateList();

            this.saved = true;
          });
          return;
        }
        runInAction(() => {
          this.loadstate = false;
          this.errloadstate = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadstate = false;
          if (!error.response) {
            this.errloadstate = error?.message;
            toast.error(error?.message);
          }
          this.errloadstate = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };

  // filter League court

  getFilterdstatecourt = () => {
    runInAction(() => {
      this.loadSate = true;
      this.errStatelevel = "";
      this.saved = false;
      this.State = [];
      this.nolist = false;
    });
    getMethodData(
      `/api/admin/state?filters[page]=1&filters[name]=${this.addstates}&filters[isActive]=${this.status}`,
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadSate = false;
            this.errStatelevel = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadSate = false;
            this.errStatelevel = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.State = resp?.data?.data;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadSate = false;
          this.errStatelevel = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadSate = false;
          if (!error.response) {
            this.errStatelevel = error?.message;
            toast.error(error?.message);
          }
          this.errStatelevel = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };

  // Edit league
  editstatelist = (id) => {
    runInAction(() => {
      this.loadlevel = true;
      this.errloadlevel = "";
    });

    getMethodData(`/api/admin/state/${id}`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadstat = false;
            this.errStatelevel = resp?.data?.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.addstates = resp.data.data.name;
            this.status = resp.data.data.isActive;
          });
          return;
        }
        runInAction(() => {
          this.addlevel = false;
          this.erraddseasoncourt = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.addseasoncourt = false;
          if (!error.response) {
            this.erraddhomecourt = error?.message;
            toast.error(error?.message);
          }
          this.erraddseasoncourt = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };

  //Active and inactive
  statusChange = () => {
    runInAction(() => {
      this.StateLoader = true;
      this.Statepopup=true;
      this.errloadleague = "";
    });
    patchMethodData(
      `/api/admin/state/status/${this.stateid}`,
      { isActive: `${!this.status}` },
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.StateLoader = true;
            this.Statepopup=true;
            this.errStatelevel = resp?.data?.message;
            toast.error(resp?.data?.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.StateLoader = false;
            this.Statepopup=false;
            this.errStatelevel = resp?.data?.message;
            this.getStateList();
            toast.success(resp?.data?.message);
          });
          return;
        }
        runInAction(() => {
          this.StateLoader = false;
          this.errStatelevel = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.StateLoader = false;
            this.Statepopup=true;
            this.errStatelevel = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.loadSate = false;
          this.Statepopup=true;
          this.errStatelevel = error?.response?.data?.message;
        });
        return;
      });
  };
  //League status
  getallleaguestatus = () => {
    runInAction(() => {
      this.loadleague = true;
      this.errloadleague = "";
    });
    patchMethodData(
      `/api/admin/league/leagueStatus/${this.leagueid}`,
      { league_status: this.leaguestatus },
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadleague = false;
            this.errloadleague = resp?.data?.message;
            toast.error(resp?.data?.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadleague = false;
            this.errloadleague = resp?.data?.message;
            this.getLeagueList();
            toast.success(resp?.data?.message);
          });
          return;
        }
        runInAction(() => {
          this.loadleague = false;
          this.errloadleague = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.loadleague = false;
            this.errloadleague = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.loadleague = false;
          this.errloadleague = error?.response?.data?.message;
        });
        return;
      });
  };

  //update method
  updatestate = (id) => {
    runInAction(() => {
      this.editloadState = true;
      this.errState = "";
      this.saved = false;
    });
    putMethod(
      `/api/admin/state/${id}`,
      {
        name: this.addstates,
        isActive: this.status,
      },
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.editloadState = false;
            this.errState = resp?.data?.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.editloadState = false;
            this.errState = resp?.data?.message;
            toast.success(resp.data.message);
            this.saved = true;
          });
          return;
        }
        runInAction(() => {
          this.editloadState = false;
          this.errState = resp?.data?.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.editloadState = false;
            this.errState = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.editloadState = false;
          this.errState = error?.response?.data?.message;
        });
        return;
      });
  };
}

export const StateStoreContext = createContext(new StateStore());
export const useStateStore = () => useContext(StateStoreContext);
