import React, { useEffect, useState } from 'react';
import {Link, useNavigate} from "react-router-dom"
import { RxDashboard, RxEyeOpen, RxPencil1, RxPerson, RxPlus } from 'react-icons/rx';
import { MdAdd, MdLocationCity, MdOutlineDelete,MdDownload } from "react-icons/md";
import userimage from "../../src/assets/images/user.png";
import ReactPaginate from 'react-paginate';


import { useUserStore,UserStore,UserStoreContext } from "./store/UserStore";
import { observer } from 'mobx-react';
import Filter from './UserFilter/userfilter';
import { useAuthStore } from '../Auth/store/Auth';

const UserManagement = () => {
  const store = new UserStore({
    isloading:true,
    editloading:false,
    slectcity:true
  });
  return(
    <UserStoreContext.Provider value={store}>
      <Screen/>
    </UserStoreContext.Provider>
  )
}

export default UserManagement

const Screen = observer(()=>{
  const {
    users,
    loaduser,
    nolistuser,
    UserLoader,
    Statuspopup,
    setstatusVoidpopup,
    getNewstatus,
    getstatusid,
    statusChange,
    dwnloadbtn ,
    getToken
  } = useUserStore();
  const { adminAuthApproved } = useAuthStore();
  
  useEffect(() => {
    if (adminAuthApproved) {
      getToken(localStorage.getItem("Admintoken"));
    }
  
  }, [adminAuthApproved]);

  const [currentPage, setCurrentPage] = useState(0);
  const ITEMS_PER_PAGE = 50;
  const handlePageChange = ({ selected }) => { 
    setCurrentPage(selected);  
   
  };

  const handleClick=()=>{
    const element = document.getElementById("tablescroll");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }
  const startIndex = currentPage * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentUser = users.slice(startIndex, endIndex);
  const navigate = useNavigate();

  const generateCSV = () => {
    const csvContent = [];
    csvContent?.push('Name,Email'); // Headers

    users?.forEach(user => {
      const row = `${user.firstName} ${user.lastName},${user.email}`;
      csvContent?.push(row);
    });

    const blob = new Blob([csvContent?.join('\n')], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'user_names_emails.csv';
    a.click();
    URL.revokeObjectURL(url);
  };

  // const handleClickScroll = () => {
  //   const element = document.getElementById("table");
  //   if (element) {
  //     element.scrollTop({ behavior: "auto" });
  //   }
  // };
  return (
    <>
    <div  className='flex justify-between' >
    <div  className=" mb-4">
        <p className=" text-2xl font-semibold">User Management</p>
        {/* <p className=" opacity-80 text-sm">Home / User</p> */}
      </div>
      {/* <div>
        <Link to="./adduser" className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"><MdAdd/>Add User</Link>
      </div> */}
     </div>
     <Filter  />
     <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
    { dwnloadbtn && 
      <button onClick={generateCSV}  className="btn btn-sm bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"><MdDownload/>Download Data For Email</button>
    }
     </div>
    <div className="card bg-base-100 shadow-md p-6 mt-2">
     
        <div  className="overflow-x-auto w-full">
        <table id="tablescroll"  className="table table-zebra w-full">
        
            <thead  >           
            <tr>
                <th >#</th>
                <th>Name</th>
                {/* <th>Email</th> */}
                <th>Gender</th>
                <th>Mobile</th>
                <th>level</th>
                <th>City</th>
                <th>Court Name</th>
                <th>Last login device</th>  
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="text-sm relative">

            {loaduser && 
              <>
              <tr>
                <td colSpan={10} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={10} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={10} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={10} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={10} className="animate-pulse py-6"></td>
              </tr>
              </>
              }
        
          
            
           { (currentUser.length === 0 ?  users : currentUser )?.map((data,index)=>(
             
              <tr key={index}>
              <td>
              {currentPage * ITEMS_PER_PAGE + index+1}
                </td>
              
                <td>
                <div className="flex items-center space-x-3">
                    {/* <div className="avatar">
                    <div className="mask mask-squircle w-12 h-12">
                        <img src={userimage} alt="Avatar Tailwind CSS Component" />
                    </div>
                    </div> */}
                    <div>
                    <div className="font-bold capitalize text-[#7ac46b] cursor-pointer"
                    
                     onClick={() => {
                      if(data?.firstName!==undefined){
                      !data?.isDelete &&
                        navigate(`/playerdetail/${data?._id}/${
                      (data?.firstName??"-")+" "+(data?.lastName??"-")

                        }`);
                      }   
                    }}
                    >
                      {(data?.firstName??"-")+" "+(data?.lastName??"-")}
                      </div>
                    {/* <div className="font-bold">Lname</div> */}
                  <div className="text-sm ">{data?.email}</div>
                    </div>
                    </div>
                </td>
                {/* <td>
               
                </td> */}
                <td className=" capitalize">{data?.gender ?? "N/A"}</td>
                <td>{data?.mobile?.replace(/\D/g, '')?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2 - $3') ?? "N/A"}</td>        
                <td>
               <div className="text-xs">S-{data?.level?.name.toFixed(1)?? 0}</div>
               <div className="text-xs">D-{data?.doubles_level?.name.toFixed(1)?? 0}</div>
               <div className="text-xs whitespace-nowrap">M-{data?.mixed_level?.name.toFixed(1)?? 0}</div>
                </td>
                <td className=" capitalize">{data?.city?.city_name ?? "N/A"}</td>
                <td className=" capitalize">{data?.homeCourt?.displayName ?? "N/A"}</td>
                <td className=" capitalize">{data?.deviceId ?? "N/A"}</td>
                {/* <td>{data?.dob?.slice(0, 10)}</td> */}
                
                
                
                {/* <td>USA</td> */}
                
                <td>
                    <label
                      title={data?.isDelete ?`User Deleted`:`Change Status` }
                      htmlFor="my-modal-5"
                      className={`btn capitalize ${data?.isDelete ? "bg-[red] hover:bg-[red] " : data?.isActive ? "bg-color_theme hover:bg-color_theme" : "bg-warning hover:bg-warning"}  text-[#000] border-hidden  btn-xs`}
                      onClick={(e) => {
                       ( data?.isDelete ?
                        setstatusVoidpopup(false)
                        :
                        setstatusVoidpopup(true))

                        getstatusid(data?._id);
                        getNewstatus(data?.isActive);
                      }}
                    >
                      {data?.isDelete ? "Deleted" :  data?.isActive ? "Active" : "Inactive"}
                    </label>
                  </td>
                <td className=" text-base">
                    <div className="flex items-center space-x-4">
                      {/* <label
                        htmlFor="deletecity"
                        className=" text-[#d02c2c] cursor-pointer"
                        title="Delete"
                        // onClick={(e) => {getid(data?._id)}}
                      >
                        <MdOutlineDelete />
                      </label> */}
                      <button title="Edit">
                        {data?.isDelete ? "-":  <Link to={`/usermanagement/${data?._id}`}>
                            <RxPencil1 />
                        </Link>}
                       
                      </button>
                    </div>
                  </td>
                </tr>
               
            ))}
          
           
            {nolistuser && 
              <tr>
                <td colSpan={10} className="my-10 text-center">
                  <RxPerson className=" text-[#dedede] text-8xl mx-auto" />
                  <p className=" font-semibold text-lg">No User Found</p>
                  {/* <Link
                    to="./addcity"
                    className="btn btn-sm mt-4 bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                  >
                    <MdAdd />
                    Add City
                  </Link> */}
                </td>
              </tr>}
            </tbody>
        </table>
        {!users?.length == 0 &&
        <ReactPaginate
        initialPage={0}
        pageCount={Math.ceil(users.length / ITEMS_PER_PAGE)}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        onPageChange={handlePageChange}
        onClick={handleClick}
        containerClassName={'pagination'}
        activeClassName={'active'}
        />
      }
        {/* status change model start */}

        {Statuspopup && 
        <>
         <input type="checkbox" id="my-modal-5" className="modal-toggle" />

<div className="modal">
  <div className="modal-box relative">
    <label
     htmlFor="my-modal-5"
      className="btn btn-sm btn-circle absolute right-2 top-2"
      onClick={()=>setstatusVoidpopup(false)}
    >
      ✕
    </label>
    <h3 className="text-lg font-bold text-center">
      Are you sure you want to change Status ?
    </h3>
    <div className="modal-action justify-center">
      {!UserLoader ?        <label
      htmlFor="my-modal-5"
        className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
        onClick={() => statusChange()}
      >
        Yes
      </label> :   
      <button
                  // type="submit"
                  disabled
                  className="flex items-center bg-color_theme px-3 mx-5 rounded-md"
                  // onClick={() => {
                  //   PostMatchscore();
                  // }}
                >
                  Yes..
                  <svg
                    className="animate-spin mr-1 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </button> }

                <label
  onClick={()=>setstatusVoidpopup(false)}
    // htmlFor="voidstatus"
    htmlFor="my-modal-5"
    className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
  >
    No
  </label>
    </div>
  </div>
</div>
        </>
       
}

          {/* status change model end */}
        </div>
    
    </div>

    </>
    
  )
})