import { observer } from "mobx-react";
import { MdDoorbell, MdNotifications } from "react-icons/md";
import { NotificationsStore, NotificationsStoreContext, useNotificationsStore } from "./store/NotificationStore";
import Moment from "react-moment";
import { useAuthStore } from "../Auth/store/Auth";
import { useEffect } from "react";


const NotificationList = () => {
    const store = new NotificationsStore({
      isloading: true,
    });
    return (
      <NotificationsStoreContext.Provider value={store}>
        <Screen />
      </NotificationsStoreContext.Provider>
   
    );
  };
  export default NotificationList;



const Screen = observer(() => {

    const {getNotificationCount,notificationcount} = useAuthStore()
    const {Notificationlist ,ClearNotificationList,getNotificationList} = useNotificationsStore()

    useEffect(() => {
        getNotificationCount()
        // getNotificationList()
    },[notificationcount])

    return(
        <section className="h-auto overflow-hidden w-full ">
            <div className="flex justify-between mb-5">
                <div className=" mb-4">
                    <p className=" text-2xl font-semibold">Notifications</p>
                </div>
                {Notificationlist.length != 0 && <label htmlFor="notificationpopup" className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">Clear All</label>}
            </div>
            <div className="card bg-base-100 shadow-md ">
                {Notificationlist && Notificationlist?.map((data,index) => (
                    <div key={index} className='flex items-start w-full py-3 border-b last:border-b-0 border-[#0000005f]'>
                        <div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 text-7xl font-bold mx-3"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                            />
                        </svg>
                        </div>
                        <div className='flex items-center w-11/12 '>
                        <p className='text-[15px]'>{data?.body}</p>
                        </div>
                        <div className='flex items-start w-1/12 justify-end  mx-3'>
                        <p className='text-[11px]'><Moment fromNow>{data?.createdAt ?? "N/A"}</Moment></p>
                        </div>
                    </div>
                ))}
                {!Notificationlist || (Notificationlist.length === 0 && <div className="text-white my-10 text-center col-span-4">No Notification Yet.</div>)}
            </div>
{/* popup for notification  */}
            <input type="checkbox" id="notificationpopup" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="notificationpopup"
                className="btn btn-sm btn-circle absolute right-2 top-2"
                // onClick={()=>{
                //   setLeagueid("");
                //   setLevel("")
                //   setLeaguename("")
                //   }}
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
              Are you sure ? you want to clear all notifications.
              </h3>
              <div className="modal-action justify-center">
                <label
                   htmlFor="notificationpopup"
                  className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                  onClick={()=>ClearNotificationList()}
                >
                  Yes
                </label>
                <label
                  htmlFor="notificationpopup"
                  className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                //   onClick={()=>{
                //   setLeagueid("");
                //   setLevel("")
                //   setLeaguename("")
                //   }}
                >
                  No
                </label>
              </div>
            </div>
          </div>

        </section>
    )
})