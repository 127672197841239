import React, { useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { MdOutlineClose, MdOutlineCheck } from "react-icons/md";
import { Input } from "react-daisyui";
import { CityStore, useCityStore, CityStoreContext } from "./store/CityStore";
import { observer } from "mobx-react";
import { useAuthStore } from "../Auth/store/Auth";

const EditCityManagement = observer(() => {
  const { id } = useParams();
  return (
    <CityStoreContext.Provider
      value={
        new CityStore({
          loadeditcitybol: false,
          editloading: true,
          id: id,
        })
      }
    >
      <Screen />
    </CityStoreContext.Provider>
  );
});
export default EditCityManagement;

const Screen = observer(() => {
  const {
    saved,
    updatedcity,
    updatedcityname,
    getstates,
    getcountryname,
    updatecity,
    getstatus,
    newcityname,
    getstate,
    Statename,
    status,
    statesid,
    addloadcity,
    getToken
  } = useCityStore();
  const { adminAuthApproved } = useAuthStore();
  
  useEffect(() => {
    if (adminAuthApproved) {
      getToken(localStorage.getItem("Admintoken"));
    }
  
  }, [adminAuthApproved]);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (saved) {
      navigate("../citymanagement");
    }
  }, [saved]);
  return (
    <>
    <div className="flex justify-between mt-[51px] ">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold">Edit City</p>
          {/* <p className=" opacity-80 text-sm">Home /City/ Edit</p> */}
        </div>
        <div>
          <Link
            to="../citymanagement"
            className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
          >
            Back
          </Link>
        </div>
      </div>
      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <form
          onSubmit={(e) => {
            updatecity(id);
            e.preventDefault();
          }}
        >
          <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
            <div>
              <label
                htmlFor="Cityname"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                City Name<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <input
                name="Cityname"
                required
                onChange={(e) => updatedcityname(e.target.value)}
                value={newcityname}
                placeholder="City Name"
                id="Cityname"
                type="text"
                autoComplete="off"
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
              />
            </div>
            <div>
              <label
                htmlFor="state"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                State
              </label>
              <select
                id="state"
                onChange={(e) => getstate(e.target.value)}
                value={getstates}
                name="state"
                className={`select capitalize focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                {Statename.map((data, index) => (
                  <option value={data?._id} key={index}>
                    {data?.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="Country"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Country<span className="text-[#f00]">&nbsp;*</span>
              </label>
              {/* <input
                name="Country"
                required
                onChange={(e) => getcountryname(e.target.value)}
                // value={country}
                placeholder="country"
                id="Country"
                type="text"
                autoComplete="off"
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
              /> */}
              <select 
              // value={country}
              // onChange={(e) => getcountryname(e.target.value)}
              id="Country"
              name="Country"
              className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                  <option value="USA">USA</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="Status"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Status
              </label>
              <select
                value={status}
                id="Status"
                onChange={(e) => {getstatus(e.target.value)}} 
                name="Status"
                className={`select input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
                </select>
            </div>
          </div>
          <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
            <Link
              to="../citymanagement"
              className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
            >
              <MdOutlineClose className="mr-1" />
              Cancel
            </Link>
            {!addloadcity? 
            <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
              <MdOutlineCheck className="mr-1" />
              Update
            </button>
            :
            <button 
                           className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                            type="button"
                          //  onClick={()=> changeStep("4")}
                          >
                          <svg
                              className="animate-spin mr-1 h-5 w-5 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              >
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                          </svg>
                            Loading..
                          </button>
            }
          </div>
        </form>
      </div>
    </>
  );
});
