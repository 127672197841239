import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineClose, MdOutlineCheck, MdImageAspectRatio, MdImage } from "react-icons/md";
import { Input, Button, Select } from "react-daisyui";
// import { CityStore, CityStoreContext } from "./store/CityStore";
// import { AdminUseStore, useAdminUsedStore, AdminUserStoreContext } from "./store/AdminUsersStore";
import { useProductOptionsStore, ProductOptionsStore , ProductOptionsStoreContext } from "./Store/ProductOptionsStore";
import { observer } from "mobx-react";
import { useAuthStore } from "../Auth/store/Auth";
import { useState } from "react";
import { RxEyeClosed, RxEyeOpen } from "react-icons/rx";
import { toast } from "react-toastify";

const AddPrductMangment = observer(() => {

    return (
        <ProductOptionsStoreContext.Provider
            value={
                new ProductOptionsStore({
                    loadeditcitybol: false,
                    addloading: true,
                })
            }
        >
            <Screen />
        </ProductOptionsStoreContext.Provider>
    );
});
export default AddPrductMangment;
const Screen = observer(() => {
    const {  setname, setstatus, setis_display_in_filter , setoption_is_color , setoption_is_separate_images ,getToken,addProductOptions ,getusername, setemail, setalert, Confirmpassw, passwordChanged, errPassword, saved, addloadProductOptions } = useProductOptionsStore();
    const { adminAuthApproved } = useAuthStore();
    const [showpassword, setshowpassword] = useState(false);
    const [confirmpassword, setconfirmpassword] = useState(false);
    // const [crop, setCrop] = useState({ aspect: 16 / 9, dimensions: { width: 480, height: 270 } });
    // const { register, handleSubmit, errors } = useForm();

    const onSubmit = (data) => {
        // Handle form submission logic here
        console.log(data);
    };

    useEffect(() => {
        if (adminAuthApproved) {
            getToken(localStorage.getItem("Admintoken"));
        }

    }, [adminAuthApproved]);
    const navigate = useNavigate();


    useEffect(() => {
        if (saved) {
            navigate("../productoptions");
        }
    }, [saved]);



    const handleSubmit = (e) => {
        e.preventDefault();
        addProductOptions();
        // Perform API call or any other logic using MobX store data
        // Example: addCity();
    };



    return (
        <>
          <div className="flex justify-between mt-[51px] ">
                <div className=" mb-4">
                    <p className=" text-2xl font-semibold">Admin Add Product Option Setup</p>
                    <p className=" opacity-80 text-sm">Home / Product Options/ Add Product Option </p>
                </div>
                <div>
                    <Link
                        to="../productoptions"
                        className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                    >
                        Back
                    </Link>
                </div>
            </div>
            <div className="card bg-base-100 shadow-md p-6 mt-8">
                <form onSubmit={handleSubmit}>
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
                        <div>
                            <label
                                htmlFor="name"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                Full name<span className="text-[#f00]">&nbsp;*</span>
                            </label>
                            <Input
                                name="name"
                                required
                                placeholder="name"
                                onChange={(e) => { setname(e.target.value); }}
                                id="name"
                                type="text"
                                autoComplete="off"
                                className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                            />

                        </div>
                    </div>
                    <div className="grid lg:grid-cols-2  border-dashed border-t-2 mt-9 md:grid-cols-2 md:gap-8 gap-4">

          

                        <div className="mt-7">
                            <label class="relative bg-[#f1f1f1]  justify-between text-[#837575] w-full border border-[#7E7777] rounded-lg px-2 py-3 focus:outline-none inline-flex items-center mb-5 cursor-pointer">

                                <span class=" text-sm  font-medium text-gray-900 dark:text-gray-300"> Option values have separate images</span>
                                <div className="relative">
                                    <input value="1"
                                        onClick={(e) => setoption_is_separate_images()} type="checkbox" className="sr-only peer" />
                                    <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>

                                </div>
                            </label>


                        </div>
                        
                        <div className="mt-7">
                            <label class="relative bg-[#f1f1f1]  justify-between text-[#837575] w-full border border-[#7E7777] rounded-lg px-2 py-3 focus:outline-none inline-flex items-center mb-5 cursor-pointer">

                                <span class=" text-sm  font-medium text-gray-900 dark:text-gray-300">Option value is a color</span>
                                <div className="relative">
                                    <input value="1"
                                        onClick={(e) => setoption_is_color()} type="checkbox" className="sr-only peer" />
                                    <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>

                                </div>
                            </label>


                        </div>
                        
                        <div className="mt-7">
                            <label class="relative bg-[#f1f1f1]  justify-between text-[#837575] w-full border border-[#7E7777] rounded-lg px-2 py-3 focus:outline-none inline-flex items-center mb-5 cursor-pointer">

                                <span class=" text-sm  font-medium text-gray-900 dark:text-gray-300">Display option values as filters (subcategory listing page)</span>
                                <div className="relative">
                                    <input value="1"
                                        onClick={(e) => setis_display_in_filter()} type="checkbox" className="sr-only peer" />
                                    <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>

                                </div>
                            </label>


                        </div>
                        
                        
                

                    </div>
                 
                    <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                        <Link
                            to="../productoptions"
                            className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                        >
                            <MdOutlineClose className="mr-1" />
                            Cancel
                        </Link>
                        {!addloadProductOptions ?
                            <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
                                <MdOutlineCheck className="mr-1" />
                                Add Product Option

                            </button>
                            :
                            <button
                                className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                type="button"
                            //  onClick={()=> changeStep("4")}
                            >
                                <svg
                                    className="animate-spin mr-1 h-5 w-5 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                                Loading..
                            </button>
                        }

                    </div>
                </form>
            </div>
        </>
    );
});

