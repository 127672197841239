import React, { useState } from "react";
import { RxPencil1 } from "react-icons/rx";
import { Link, useParams } from "react-router-dom";
import { MdAdd, MdRewardingWatermark, MdLocationCity, MdOutlineDelete, MdOutlineGroup, MdRemoveRedEye, MdExpand, MdMoveUp, MdSwipeUp, MdArrowDropUp, MdSave } from "react-icons/md";
import { observer } from "mobx-react";
import ReactPaginate from 'react-paginate';
import { RewardStore, useRewardStore, RewardStoreContext } from "./store/RewardStore";
import { useAuthStore } from "../Auth/store/Auth";
import { useEffect } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
// import { ExpandMore } from "@mui/icons-material";

// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import Moment from "react-moment";

import dem05 from "../assets/images/2.png"
import { toast } from "react-toastify";
import { Input } from "react-daisyui";


const RewardManagement = () => {
    const store = new RewardStore({
        isloading: true,
        editloading: false
    });
    return (
        <RewardStoreContext.Provider value={store}>
            <Screen />
        </RewardStoreContext.Provider>
    )
}
export default RewardManagement;


const Screen = observer(() => {
    const {
        Reward,
        Rewardsummary,
        shipping_addr,
        billing_addr,
        referral,
        registration,
        RewardFormDetails,
        setreferral,
        setregistration,
        updateReward,
        setRewardFormDetails,
        getToken
    } = useRewardStore();

    const { adminAuthApproved, admin_permissions } = useAuthStore();

    useEffect(() => {
        if (adminAuthApproved) {
            getToken(localStorage.getItem("Admintoken"));
        }

    }, [adminAuthApproved]);

   

 

    return (
        <>
          <div className="flex justify-between mt-[51px] ">
                <div className=" mb-4">
                    <p className=" text-2xl font-semibold">Reward </p>
                    <p className=" opacity-80 text-sm">Home / Reward </p>

                </div>

            </div>

                <div className="card bg-base-100  shadow-md p-6 mt-8">
                    <div >


                        <p className="text-lg text-gray-700">
                            Referral
                        </p>

                        <div className="w-[50%] mt-5">
                            <label
                                htmlFor="Referral"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                Referral Amount
                            </label>
                            <div className="flex ">
                                <Input
                                    name="Referral"
                                    required
                                    value={referral}
                                    // onChange={(e) => {
                                    //     setRewardFormDetails({
                                    //         ...RewardFormDetails,
                                    //         Ref_amount: e.target.value,
                                    //     });
                                    //     // getseourl(e.target.value);
                                    // }}
                                    onChange={(e) => { setreferral(e.target.value);}}
                                    placeholder="Referral"
                                    id="Referral"
                                    type="text"
                                    autoComplete="off"
                                    className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                                />
                                <button
                                    onClick={()=>updateReward("referral")}
                                    className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
                                    <MdSave className="mr-1" />
                                    Save
                                </button>
                            </div>





                            {/* {!addloadcity ?
                            <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
                                <MdSave className="mr-1" />
                                Save Employee
                            </button>
                            :
                            <button
                                className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                type="button"
                            >
                                <svg
                                    className="animate-spin mr-1 h-5 w-5 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                                Loading..
                            </button>
                        } */}

                        </div>




                    </div>
                </div>
                <div className="card bg-base-100  shadow-md p-6 mt-8">
                    <div >


                        <p className="text-lg text-gray-700">
                            New User Registration
                        </p>

                        <div className="w-[50%] mt-5">
                            <label
                                htmlFor="Referral"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                Registration Amount
                            </label>
                            <div className="flex ">
                                <Input
                                    name="Amount"
                                    required
                                    value={registration}
                                    // onChange={(e) => {
                                    //     setRewardFormDetails({
                                    //         ...RewardFormDetails,
                                    //         Ref_amount: e.target.value,
                                    //     });
                                    //     // getseourl(e.target.value);
                                    // }}
                                    onChange={(e) => { setregistration(e.target.value);}}
                                    // onChange={(e) => { setaddEmployeename(e.target.value); getseourl(e.target.value) }}
                                    placeholder="Amount"
                                    id="Amount"
                                    type="number"
                                    autoComplete="off"
                                    className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                                />
                                <button
                                    onClick={()=>updateReward("registration")}

                                    className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
                                    <MdSave className="mr-1" />
                                    Save
                                </button>
                            </div>





                            {/* {!addloadcity ?
                            <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
                                <MdSave className="mr-1" />
                                Save Employee
                            </button>
                            :
                            <button
                                className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                type="button"
                            >
                                <svg
                                    className="animate-spin mr-1 h-5 w-5 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                                Loading..
                            </button>
                        } */}

                        </div>




                    </div>
                </div>








        </>
    );
});

