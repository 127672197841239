import { observer } from 'mobx-react';
import React from 'react'
import { useUserStore } from './store/UserStore';
import { RxPencil1, RxPerson } from 'react-icons/rx';
import {Link, useNavigate} from "react-router-dom"
import { useEffect } from 'react';
import { useAuthStore } from '../Auth/store/Auth';

const DeletedUser = observer(() => {

    const {
        loaduserdelete,
        setstatusVoidpopup,
        getNewstatus,
        getstatusid,
        Userdelete,
        getUserDeletedFilter,
        getToken,
        nolistdelete,
      } = useUserStore();

      const { adminAuthApproved } = useAuthStore();
      const navigate = useNavigate();


      useEffect(() => {
        if (adminAuthApproved) {
          getToken(localStorage.getItem("Admintoken"));
        }
      }, [adminAuthApproved]);
      
      useEffect(()=>{
        getUserDeletedFilter()    
        },[])

    return (
        <>
        <div  className='flex justify-between' >
        <div  className=" mb-4">
            <p className=" text-2xl font-semibold">Deleted Users</p>
            {/* <p className=" opacity-80 text-sm">Home / User</p> */}
          </div>
          <div>
          <button
             onClick={()=>{window?.history?.back()}}
            className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
          >
            Back
          </button>
        </div>
         </div>
         {/* <Filter  /> */}
        
        <div className="card bg-base-100 shadow-md p-6 mt-2">
         
            <div  className="overflow-x-auto w-full">
            <table id="tablescroll"  className="table table-zebra w-full">
            
                <thead  >           
                <tr>
                    <th >#</th>
                    <th>Name</th>
                    {/* <th>Email</th> */}
                    <th>Gender</th>
                    <th>Mobile</th>
                    <th>level</th>
                    <th>City</th>
                    <th>Court Name</th>
                    <th>Last login device</th>  
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="text-sm relative">
    
                {loaduserdelete && 
                  <>
                  <tr>
                    <td colSpan={10} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={10} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={10} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={10} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={10} className="animate-pulse py-6"></td>
                  </tr>
                  </>
                  }
            
              
                
               { Userdelete?.map((data,index)=>(
                 
                  <tr key={index}>
                  <td>
                  {index+1}
                    </td>
                  
                    <td>
                    <div className="flex items-center space-x-3">
                        {/* <div className="avatar">
                        <div className="mask mask-squircle w-12 h-12">
                            <img src={userimage} alt="Avatar Tailwind CSS Component" />
                        </div>
                        </div> */}
                        <div>
                        <div className="font-bold capitalize text-[#7ac46b] cursor-pointer"
                        
                         onClick={() => {
                          if(data?.firstName!==undefined){
                          !data?.isDelete &&
                            navigate(`/playerdetail/${data?._id}/${
                          (data?.firstName??"-")+" "+(data?.lastName??"-")
    
                            }`);
                          }   
                        }}
                        >
                          {(data?.firstName??"-")+" "+(data?.lastName??"-")}
                          </div>
                        {/* <div className="font-bold">Lname</div> */}
                      <div className="text-sm ">{data?.email}</div>
                        </div>
                        </div>
                    </td>
                    {/* <td>
                   
                    </td> */}
                    <td className=" capitalize">{data?.gender ?? "N/A"}</td>
                    <td>{data?.mobile?.replace(/\D/g, '')?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2 - $3') ?? "N/A"}</td>        
                    <td>
                   <div className="text-xs">S-{data?.level?.name?.toFixed(1)?? 0}</div>
                   <div className="text-xs">D-{data?.doubles_level?.name.toFixed(1)?? 0}</div>
                   <div className="text-xs whitespace-nowrap">M-{data?.mixed_level?.name.toFixed(1)?? 0}</div>
                    </td>
                    <td className=" capitalize">{data?.city?.city_name ?? "N/A"}</td>
                    <td className=" capitalize">{data?.homeCourt?.displayName ?? "N/A"}</td>
                    <td className=" capitalize">{data?.deviceId ?? "N/A"}</td>
                    {/* <td>{data?.dob?.slice(0, 10)}</td> */}
                    
                    
                    
                    {/* <td>USA</td> */}
                    
                    <td>
                        <label
                          title={data?.isDelete ?`User Deleted`:`Change Status` }
                          htmlFor="my-modal-5"
                          className={`btn capitalize ${data?.isDelete ? "bg-[red] hover:bg-[red] " : data?.isActive ? "bg-color_theme hover:bg-color_theme" : "bg-warning hover:bg-warning"}  text-[#000] border-hidden  btn-xs`}
                          onClick={(e) => {
                           ( data?.isDelete ?
                            setstatusVoidpopup(false)
                            :
                            setstatusVoidpopup(true))
    
                            getstatusid(data?._id);
                            getNewstatus(data?.isActive);
                          }}
                        >
                          {data?.isDelete ? "Deleted" :  data?.isActive ? "Active" : "Inactive"}
                        </label>
                      </td>
                    <td className=" text-base">
                        <div className="flex items-center space-x-4">
                          {/* <label
                            htmlFor="deletecity"
                            className=" text-[#d02c2c] cursor-pointer"
                            title="Delete"
                            // onClick={(e) => {getid(data?._id)}}
                          >
                            <MdOutlineDelete />
                          </label> */}
                          <button title="Edit">
                            {data?.isDelete ? "-":  <Link to={`/usermanagement/${data?._id}`}>
                                <RxPencil1 />
                            </Link>}
                           
                          </button>
                        </div>
                      </td>
                    </tr>
                   
                ))}
              
               
                {nolistdelete && 
                  <tr>
                    <td colSpan={10} className="my-10 text-center">
                      <RxPerson className=" text-[#dedede] text-8xl mx-auto" />
                      <p className=" font-semibold text-lg">No Deleted User Found</p>
                      {/* <Link
                        to="./addcity"
                        className="btn btn-sm mt-4 bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                      >
                        <MdAdd />
                        Add City
                      </Link> */}
                    </td>
                  </tr>}
                </tbody>
            </table>
            </div>
        
        </div>
    
        </>
        
      )
});

export default DeletedUser

