import React from 'react'
import { Input } from 'react-daisyui'
import { MdOutlineCheck, MdOutlineClose } from 'react-icons/md'
import { Link } from 'react-router-dom'

const AddPlayerManagement = () => {
  return (
    <>
        <div className='flex justify-between'>
            <div className=" mb-4">
                <p className=" text-2xl font-semibold">Add Player</p>
                <p className=" opacity-80 text-sm">Home / Player/ Add</p>
            </div>
            <div>
                <Link to="../playersmanagement" className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]">Back</Link>
            </div>
        </div>
        <div className="card bg-base-100 shadow-md p-6 mt-8">
            <form onSubmit={(e) => {e.preventDefault()}}>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
                    <div>
                        <label htmlFor="name" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Name<span className="text-red-500">&nbsp;*</span></label>
                        <Input 
                        name="name" 
                        placeholder="" 
                        id="name" 
                        type="text" 
                        autoComplete="off" 
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div>
                    <div>
                        <label htmlFor="email" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Email<span className="text-red-500">&nbsp;*</span></label>
                        <Input 
                        name="email" 
                        placeholder="e.g. user@example.com" 
                        id="email" 
                        type="email" 
                        autoComplete="off" 
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div>
                    <div>
                        <label htmlFor="date" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">DOB<span className="text-red-500">&nbsp;*</span></label>
                        <Input 
                        name="date" 
                        placeholder="" 
                        id="date" 
                        type="date" 
                        autoComplete="off" 
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div>
                    <div>
                        <label htmlFor="Address" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Address<span className="text-red-500">&nbsp;*</span></label>
                        <Input 
                        name="Address" 
                        placeholder="" 
                        id="Address" 
                        type="text" 
                        autoComplete="off" 
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div>
                    <div>
                        <label htmlFor="city" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">City<span className="text-red-500">&nbsp;*</span></label>
                        <Input 
                        name="city" 
                        placeholder="" 
                        id="city" 
                        type="text" 
                        autoComplete="off" 
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div>
                    <div>
                        <label htmlFor="state" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">State<span className="text-red-500">&nbsp;*</span></label>
                        <Input 
                        name="state" 
                        placeholder="" 
                        id="state" 
                        type="text" 
                        autoComplete="off" 
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div>
                    <div>
                        <label htmlFor="zipcode" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Zipcode<span className="text-red-500">&nbsp;*</span></label>
                        <Input 
                        name="zipcode" 
                        placeholder="" 
                        id="zipcode" 
                        type="number" 
                        autoComplete="off" 
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div>
                    <div>
                        <label htmlFor="status" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Status<span className="text-red-500">&nbsp;*</span></label>
                        <select 
                        name="Status"
                        className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                            {/* <option value='' disabled selected>
                            ---
                            </option> */}
                            <option value={'Active'}>Active</option>
                            <option value={'Inctive'}>Inactive </option>
                        </select>
                    </div>
                </div>
                <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                        <Link to="../playersmanagement" className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"><MdOutlineClose className="mr-1"/>Cancel</Link>
                        <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"><MdOutlineCheck className="mr-1"/>Add Player</button>
                </div>
            </form>
        </div>
    </>
  )
}

export default AddPlayerManagement