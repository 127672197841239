import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdOutlineClose, MdOutlineCheck, MdImageAspectRatio, MdImage } from "react-icons/md";
import { Input, Button, Select } from "react-daisyui";
// import { CityStore, CityStoreContext } from "./store/CityStore";
// import { AdminUseStore, useAdminUsedStore, AdminUserStoreContext } from "./store/AdminUsersStore";
import { useProductOptionsStore, ProductOptionsStore, ProductOptionsStoreContext } from "./Store/ProductOptionsStore";
import { observer } from "mobx-react";
import { useAuthStore } from "../Auth/store/Auth";
import { useState } from "react";
import { RxEyeClosed, RxEyeOpen } from "react-icons/rx";
import { toast } from "react-toastify";
import ImgCrop from "antd-img-crop";
import { Upload } from 'antd';


const AddPrductValuesMangment = observer(() => {

    return (
        <ProductOptionsStoreContext.Provider
            value={
                new ProductOptionsStore({
                    loadeditcitybol: false,
                    addproductvalue: true,
                })
            }
        >
            <Screen />
        </ProductOptionsStoreContext.Provider>
    );
});
export default AddPrductValuesMangment;
const Screen = observer(() => {
    const { setnamevalue,setdisplayvalue ,getToken, addProductvalue, setdisplay, getlogoimage, setstockAvailiable, getunit_type_id, getunit_id, unit_type_id, setstock_qty, UnitDetails, stockAvailiable, getusername, setemail, setalert, Confirmpassw, passwordChanged, errPassword, saved, addloadProductOptions } = useProductOptionsStore();
    const { adminAuthApproved } = useAuthStore();
    const [logofile, setlogofile] = useState();

    const { id } = useParams();

    // const [crop, setCrop] = useState({ aspect: 16 / 9, dimensions: { width: 480, height: 270 } });
    // const { register, handleSubmit, errors } = useForm();

    const onSubmit = (data) => {
        // Handle form submission logic here
        console.log(data);
    };

    const getLogoSrcFromFile = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
        });
    };

    const getBase64 = file => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
        });
    };
    useEffect(() => {
        if (adminAuthApproved) {
            getToken(localStorage.getItem("Admintoken"));
        }

    }, [adminAuthApproved]);
    const navigate = useNavigate();


    useEffect(() => {
        if (saved) {
            navigate(-1);
        }
    }, [saved]);

    const LogoPreview = async (file) => {
        const src = file.url || (await getLogoSrcFromFile(file));
        if (src) {
            const imgWindow = window.open(src);
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }
            if (imgWindow) {
                const image = new Image();
                image.src = src;
                imgWindow.document.write(image.outerHTML);
            } else {
                window.location.href = src;
            }
        };
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        addProductvalue(id);
        // Perform API call or any other logic using MobX store data
        // Example: addCity();
    };

    const LogChange = async ({ fileList: newFileList }) => {
        // Allow only the first file in the list
        const file = newFileList[0];
        // Get the base64 representation of the file
        if (file) {
            const base64Image = await getBase64(file.originFileObj);
            const files = base64Image;
            getlogoimage(files);

        }

        // Update the logofile state with the newFileList
        setlogofile(newFileList.slice(0, 1));

    };

    return (
        <>
          <div className="flex justify-between mt-[51px] ">
                <div className=" mb-4">
                    <p className=" text-2xl font-semibold">Admin Add Product Value </p>
                    <p className=" opacity-80 text-sm">Home / Product Value / Add Product Value </p>
                </div>
                <div>

                    <Link
                        onClick={() => navigate(-1)}
                        className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                    >
                        Back
                    </Link>
                </div>
            </div>
            <div className="card bg-base-100 shadow-md p-6 mt-8">
                <form onSubmit={handleSubmit}>
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
                        <div>
                            <label
                                htmlFor="name"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                Full name<span className="text-[#f00]">&nbsp;*</span>
                            </label>
                            <Input
                                name="name"
                                required
                                placeholder="name"
                                onChange={(e) => { setnamevalue(e.target.value); }}
                                id="name"
                                type="text"
                                autoComplete="off"
                                className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                            />

                        </div>
                        <div>
                            <label
                                htmlFor="name"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                Display name<span className="text-[#f00]">&nbsp;*</span>
                            </label>
                            <Input
                                name="displayname"
                                required
                                placeholder="display name"
                                onChange={(e) => { setdisplayvalue(e.target.value); }}
                                id="name"
                                type="text"
                                autoComplete="off"
                                className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                            />

                        </div>
                        <div>
                            <label
                                htmlFor="Display"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                Display order<span className="text-[#f00]">&nbsp;*</span>
                            </label>
                            <Input
                                name="Display"
                                required
                                placeholder="Display"
                                onChange={(e) => { setdisplay(e.target.value); }}
                                id="Display"
                                type="text"
                                autoComplete="off"
                                className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                            />

                        </div>
                        <div className="mt-7">
                            <label class="relative bg-[#f1f1f1]  justify-between text-[#837575] w-full border border-[#7E7777] rounded-lg px-2 py-3 focus:outline-none inline-flex items-center mb-5 cursor-pointer">

                                <span class=" text-sm  font-medium text-gray-900 dark:text-gray-300">Stock Availiable</span>
                                <div className="relative">
                                    <input
                                        value="1"
                                        onClick={(e) => setstockAvailiable()}

                                        type="checkbox" className="sr-only peer" />
                                    <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>

                                </div>
                            </label>


                        </div>
                        {
                            stockAvailiable &&
                            <>
                                <div>
                                    <label
                                        htmlFor="Display"
                                        className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                                    >
                                        Stock Quantity<span className="text-[#f00]">&nbsp;*</span>
                                    </label>
                                    <Input
                                        name="Display"
                                        required
                                        placeholder="quantity"

                                        onChange={(e) => { setstock_qty(e.target.value); }}
                                        id="Display"
                                        type="number"
                                        autoComplete="off"
                                        className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                                    />

                                </div>

                                <div>
                                    <label htmlFor="Status" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">
                                        Dimensions
                                    </label>
                                    <select
                                        id="Status"
                                        required
                                        onChange={(e) => getunit_type_id(e.target.value)}
                                        name="Status"
                                        className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                                    >
                                        <option value="">Select Dimensions</option>
                                        {
                                            UnitDetails.map((item, index) => (
                                                <option key={index} value={item.unit_type_id}>{item.unit_type}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                {unit_type_id !== "" &&
                                    <div>
                                        <label htmlFor="Status" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">
                                            Unit list
                                        </label>
                                        <select
                                            id="Status"
                                            required
                                            onChange={(e) => getunit_id(e.target.value)}
                                            name="Status"
                                            className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                                        >
                                            <option value="">Select unit</option>
                                            {
                                                // Filter UnitDetails array based on selected unit_type_id
                                                UnitDetails.filter(item => item.unit_type_id == unit_type_id).map((filteredItem, filteredIndex) => (
                                                    filteredItem.data.map((unit, index) => (
                                                        <option key={index} value={unit.unit_id }>{unit.unit_name}</option>
                                                    ))
                                                ))
                                            }
                                        </select>
                                    </div>
                                }


                            </>



                        }


                    </div>

                    <div className="border-t-2  border-dashed mt-9 ml-3 ">
                        <label htmlFor="image" className="block text-md font-semibold text-zinc-700 mt-3 dark:text-stone-400 mb-2">
                            Icon<span className="text-[#f00]">*</span>
                        </label>

                        <ImgCrop
                            showGrid
                            rotationSlider
                            aspectSlider
                            showReset
                        // onCrop={handleCrop}
                        >
                            <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture-circle"
                                accept="image/*"
                                fileList={logofile}
                                onChange={LogChange}
                                onPreview={LogoPreview}
                            >
                                {(logofile == undefined || logofile.length === 0) && (
                                    <span className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ">Upload Logo</span>
                                )}
                            </Upload>
                        </ImgCrop>
                        <span className="text-[11px]">This Will Be Displayed In 100*100 On Your Store</span>



                    </div>

                    <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                        <Link
                            onClick={() => navigate(-1)}

                            className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                        >
                            <MdOutlineClose className="mr-1" />
                            Cancel
                        </Link>
                        {!addloadProductOptions ?
                            <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
                                <MdOutlineCheck className="mr-1" />
                                Add Option Value
                            </button>
                            :
                            <button
                                className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                type="button"
                            //  onClick={()=> changeStep("4")}
                            >
                                <svg
                                    className="animate-spin mr-1 h-5 w-5 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                                Loading..
                            </button>
                        }

                    </div>
                </form>
            </div>
        </>
    );
});

