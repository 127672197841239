import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { LeagueStore, LeagueStoreContext, useLeagueStore } from './store/LeagueStore';
import { observer } from 'mobx-react';
import Moment from "react-moment";
import moment from 'moment';


const Playoffmatches = () => {
    const { id, levelid, leaguename } = useParams();
    const store = new LeagueStore({
      isloading: true,
      playofflist: true,
      id:id,
      levelid:levelid
    });
    return (
      <LeagueStoreContext.Provider value={store}>
        <Screen />
      </LeagueStoreContext.Provider>
    );
  };
  export default Playoffmatches;

const Screen = observer(() => {
    const { id, levelid, leaguename } = useParams();
    const {
       playofflist,
       loadplayoff,
       noplayofflist,
       updateplayoff
      } = useLeagueStore();

      const [update,setUpdate] = useState({
        match: "",
        team_first_score: { team: "", set_one: null, set_two: null, set_three: null },
        team_second_score: { team: "", set_one: null, set_two: null, set_three: null },
        winByAdmin: ""
      })

      const goBack = () => {
        window.history.back(); 
      };
   
  return (
    <div className=" mt-[-19px] flex flex-col  lg:flex-row  px-4 justify-center">
    <div className=" w-full py-11 flex flex-col">
    <div className='flex justify-between'>
      <p className=" text-[black] text-xl md:text-2xl font-bold capitalize">
         Playoff Matches Of {leaguename}
        <br />
      </p>
    
          <Link
            onClick={goBack}
            // to="../leaguemanagement"
            className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
          >
            Back
          </Link>
        </div>
        <p className=" text-center  text-[black] font-medium text-xl md:text-xl capitalize my-3">
         Matches
        <br />
      </p>
      <div
        id=""
        className="  flex-grow flex-shrink-0 border-[1px] border-color_theme   my-5 max-h-[450px] overflow-auto"
      >
        <div id="">
          <table className="table table-zebra w-full" >
          <thead>
                    <tr className=" sticky top-0  ">
                      <th className="px-3 text-left ">#</th>
                      <th className="md:py-5 text-left px-3 ">Playoff Type</th>
                      <th className="md:py-5 text-left px-3 ">Team 1</th>
                      <th className="text-left px-3">Team 2</th>
                      <th className="text-left px-3">Date to Play</th>
                      <th className="text-left px-3">Home Court</th>
                      <th>Result</th>
                      <th>Set As Winner</th>
                      {/* <th>Ranking</th> */}
                    </tr>
                  </thead>
                  <tbody className="text-xs text-[black] lg:text-sm">
                    {loadplayoff && (
                      <>
                        <tr>
                          <td colSpan={8} className="animate-pulse py-6"></td>
                        </tr>
                        <tr>
                          <td colSpan={8} className="animate-pulse py-6"></td>
                        </tr>
                        <tr>
                          <td colSpan={8} className="animate-pulse py-6"></td>
                        </tr>
                        <tr>
                          <td colSpan={8} className="animate-pulse py-6"></td>
                        </tr>
                        <tr>
                          <td colSpan={8} className="animate-pulse py-6"></td>
                        </tr>
                        <tr>
                          <td colSpan={8} className="animate-pulse py-6"></td>
                        </tr>
                        <tr>
                          <td colSpan={8} className="animate-pulse py-6"></td>
                        </tr>
                      </>
                    )}

                    {playofflist?.map((data, index) => (
                      <tr
                        className=" text-[black] text-[13px]" 
                      >
                        <td className="px-3 ">{index + 1}</td>
                        <td className="px-3 capitalize">{data?.playoffRound?.round_name}</td>
                        <td className="px-3 ">
                          <span
                            title="Player"
                            className="capitalize cursor-pointer "
                          >
                            {data.team_first_players?.length !== undefined
                              ? data?.team_first_players.map((name, index) => (
                                  <span key={index}>
                                    <>
                                      {" "}
                                      {(index ? " , " : "") +
                                        name?.firstName +
                                        " " +
                                        name?.lastName}
                                    </>
                                  </span>
                                ))
                              : "-"}
                          </span>

                          {/* {res.opponent.firstName} {res.opponent.lastName} */}
                        </td>
                        <td className="px-3">
                          <span
                            title="Player"
                            className="capitalize cursor-pointer "
                          >
                            {data.team_second_players?.length !== undefined
                              ? data?.team_second_players.map((name, index) => (
                                  <span key={index}>
                                    <>
                                      {" "}
                                      {(index ? " , " : "") +
                                        name?.firstName +
                                        " " +
                                        name?.lastName}
                                    </>
                                  </span>
                                ))
                              : "-"}
                          </span>
                        </td>

                        <td className="px-1 whitespace-nowrap">
                          {data?.propose_dates.length != 0 &&
                          data?.schedule_date === null ? (
                            <>
                              {data?.propose_dates.map((purpose, index) => (
                                <span key={index} title="Proposed Dates">
                                  {(index ? `/` : "") + " "}{" "}
                                  {
                                    <Moment
                                      format={
                                        purpose?.date?.includes("05")
                                          ? "MMM DD"
                                          : "MMM. DD"
                                      }
                                    >
                                      {purpose?.date}
                                    </Moment>
                                  }
                                </span>
                              ))}{" "}
                            </>
                          ) : (
                            <>
                              {" "}
                              {data?.schedule_date === null &&
                              data?.propose_dates.length === 0 ? (
                                <span
                                  className="flex items-center"
                                  title="Proposed Dates"
                                >
                                  <Moment
                                    format={
                                      data?.schedule_from_date?.includes("05")
                                        ? "MMM DD"
                                        : "MMM. DD"
                                    }
                                  >
                                    {data?.schedule_from_date}
                                  </Moment>
                                  <p className="px-1"> - </p>
                                  <Moment
                                    format={
                                      data?.schedule_to_date?.includes("05")
                                        ? "MMM DD"
                                        : "MMM. DD"
                                    }
                                  >
                                    {data?.schedule_to_date}
                                  </Moment>
                                </span>
                              ) : (
                                <>
                                  <span className="flex" title="Schedule Dates">
                                    {" "}
                                    <Moment
                                      format={
                                        data?.schedule_date?.includes("5")
                                          ? "MMM DD"
                                          : "MMM. DD"
                                      }
                                    >
                                      {data?.schedule_date}
                                    </Moment>
                                    <p className="px-1"> @ </p>
                                    {moment(
                                      new Date(data?.schedule_time)
                                    ).format("hh:mm A")}
                                  </span>
                                </>
                              )}
                            </>
                          )}
                        </td>
                        <td
                          title="Homecourt"
                          className="  cursor-pointer px-3 whitespace-nowrap "
                        >
                          {data?.home_court?.displayName}
                        </td>
                        <td className="py-4 justify-center px-3">
                          <p className="text-[black] whitespace-nowrap text md:text-sm text-xs">
                            {data?.result === "score" ? (
                              <>
                                <span className="border 2xl:mx-2 mx-[1px] lg:p-1">
                                  {data?.team_first_score?.set_one} -
                                  {data?.team_second_score?.set_one}
                                </span>
                                <span className="border 2xl:mx-2 mx-[1px] lg:p-1">
                                  {data?.team_first_score?.set_two}-
                                  {data?.team_second_score?.set_two}
                                </span>
                                {data?.team_first_score?.set_three ||
                                data?.team_second_score?.set_three != null ? (
                                  <span className="border 2xl:mx-2 mx-[1px] lg:p-1">
                                    {data?.team_first_score?.set_three}-
                                    {data?.team_second_score?.set_three}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              <span className="md:text-xs capitalize">
                                {data?.result === "Forfieted" ? (
                                  <span>Forfeited By {data?.forfiet_by}</span>
                                ) : data?.result === "withdrew" ? (
                                  <span>Withdrew By {data?.Withdraw_by}</span>
                                ) : (
                                  data?.result === "byAdmin" ? (
                                    <>
                                    <span> {data?.winByAdmin} </span>
                                    <br/>
                                    <span className='text-[12px]'>(Win By System)</span>
                                    </>
                                  ):
                                  "TBA"
                                )}
                              </span>
                            )}
                          </p>
                        </td>
                        {data?.result==null || data?.result==""
                        ?
                        <td>
                          <div className='flex items-center'>
                          <label htmlFor="updateplay" className='ml-1'>
                            
                        
                                      {/* {data.team_first_players?.length !== undefined
                                    ? data?.team_first_players.map((name, index) => ( */}
                                      <span>
                                          <input name='winner' type="radio" 
                                        htmlFor="updateplay"
                                      
                                        onClick={() => {
                                          document.getElementById('updateplay').checked = true;
                                          setUpdate(
                                            {...update ,  
                                            match: data?._id,
                                            team_first_score: { team: data?.team_first_score?.team, set_one: null, set_two: null, set_three: null },
                                            team_second_score: { team: data?.team_second_score?.team, set_one: null, set_two: null, set_three: null },
                                            winByAdmin:  data?.team_first_score?.team
                                         })
                                        }}
                                        // id={index+1}
                                          // value={index+1} 
                                          // onClick={(e)=>{  
                                          //     setClear(e);
                                          //     setChangevalue("");
                                          //     setShowinput(!showinput);
                                          //     setindexbtn(index+1);
                                          //     }} 
                                              />

                                        
                                      <label
                                      //  htmlFor={index+1}
                                      className='ml-1 capitalize'
                                        key={index}>
                                        Team 1
                                      </label>
                                      </span>
                          
                                  </label>
                                  </div>

                                  <div className='flex items-center mt-1' >
                                 
                                  <label for="html" className='ml-1'>
                                 
                                 
                                 
                                  
                                  <span key={index}>
                                      <input name='winner' type="radio"
                                     htmlFor="updateplay"
                                    onClick={() => {
                                      document.getElementById('updateplay').checked = true;
                                      setUpdate(
                                        {...update ,  
                                        match: data?._id,
                                        team_first_score: { team: data?.team_first_score?.team, set_one: null, set_two: null, set_three: null },
                                        team_second_score: { team: data?.team_second_score?.team, set_one: null, set_two: null, set_three: null },
                                        winByAdmin:  data?.team_second_score?.team
                                     })
                                    }}
                              // value={index+1} 
                              // onClick={(e)=>{ 
                              //     setClear(e);
                              //     setChangevalue("");
                              //     setShowinput(!showinput);
                              //     setindexbtn(index+1);
                              //     }} 
                                  />
                                       <label className='ml-1 capitalize' >
                                       Team 2
                                       </label>
                                        </span>
                                     
                                  </label>
                                  </div>
                        </td>

                        :
                        <td>-</td>
                      }
                        
                      
                      </tr>
                    ))}

                    {noplayofflist && (
                <tr>
                  <td colSpan={8} className="my-10 text-center">
                    <p className=" font-semibold text-lg">No Matches Found</p>
                  </td>
                </tr>
              )}
                  </tbody>
          </table>
          {/* popup for playoff */}
          <input type="checkbox" id="updateplay" className="modal-toggle"
           onChange={(e) => {
            if (!e.target.checked) {
              // Reset the radio button selection if the checkbox is unchecked
              const radioButtons = document.getElementsByName('winner');
              for (let i = 0; i < radioButtons.length; i++) {
                radioButtons[i].checked = false;
              }
            }
          }}
           />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="updateplay"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
              Are you sure you want to set this team as winner ?
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor="updateplay"
                  className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                  onClick={() => {updateplayoff(update)}}
                >
                  Yes
                </label>
                <label
                  htmlFor="updateplay"
                  className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                >
                  No
                </label>
              </div>
            </div>
          </div>

          
          
        </div>
      </div>
    </div>
    
  </div>
  )
})

