import { createContext, useContext } from "react";
import { action, makeAutoObservable, makeObservable, observable, runInAction } from "mobx";
import { postMethodData, getMethodData, putMethod, delMethod, patchMethodData } from "../../ApiMethods/api";
import { toast } from "react-toastify";
const SEO_URL = process.env.REACT_APP_SEO_URL;





export class EmployeeStore {

    Token = null;
    Employee = [];
    type = "";
    Employeetype = [];
    filterSearch = "";
    PaginationPage = "1";
    TotalPages = "";
    EmployeeDimiensions = ""
    crop = {};
    errLoadEmployee = "";
    LoadEmployee = false;
    delateLoader = false;
    nolist = false;
    Employeepopup = false;
    deletepopup = false;
    Employeename = "";
    status = "1";
    statesid = "";
    Employeeid = [];
    seoUrl = "";
    Base64logoaadhar_img_1 = "";
    Base64logoaadhar_img_2 = "";
    Base64logolicence_img_1 = "";
    Base64loglicence_img_2 = "";
    Base64logopan_img_1 = "";
    Base64logvoter_img_1 = "";
    Base64logvoter_img_2 = "";
    Base64logoUser_img = "";
    Base64logoBank_pass_image = "";
    statuspopup = false;
    addEmployeename = "";
    imageurl = "";
    logoimage = "";
    Employeeimage = "";
    Base64logoImage = "";
    Base64EmployeeImage = "";
    logofile = [];
    allEmployee = [];
    alldeactivate = 'false';
    isEmployeeUpdated = "false";
    isLogoUpdated = "false"
    checkeddd = '';
    EmployeeFormDetails = {
        "title": "",
        "section_name": "",
        "url": "",
        "section_id": "",
        "image_name": "",
        "is_active": ""
    };
    licence_img_1 = "";
    licence_img_2 = "";
    panImage = "";
    voter_img_2 = "";
    voter_img_1 = "";
    aadhar_img_1 = ""
    aadhar_img_2 = ""
    bank_passbook_img = ""
    fullname = ""
    username = ""
    Designation = ""
    email = ""
    salary = "";
    password = ""
    confirmresetpas = ""
    mobile = ""
    bank_ifsc = ""
    bank_branch_addr = ""
    bank_ac_no = ""
    bankName = ""
    aadhar_no = ""
    voter_no = ""
    pan_no = ""
    licence_no = ""
    addr_line_1 = ""
    addr_line_2 = ""
    city = ""
    state = ""
    AdminUserFormDetails = {
        "full_name": "",
        "image": "",
        "username": "",
        "user_id": "",
        "mobile": "",
        "username": "",
        "email": "",
        "system_generated_alert": "",
        "is_active": ""
    };

    OtherFormDetail = {
        "aadhar_img_1": "",
        "pan_no": "",
        "aadhar_no": "" , 
        "voter_no": "",
        "licence_no":"",
        "address_line_2": "",
        "designation": "",
        "aadhar_img_2": "",
        "address_line_2": "",
        "designation": "",
        "licence_img_1": "",
        "licence_img_2": "",
        "pan_img_1": "",
        "salary": "",
        "voter_img_1": "",
        "voter_img_2": "",
    };
    bankDetail = {
        "acc_no": "",
        "bank_ifsc": "",
        "bank_name": "",
        "branch_addr": "",
        "address_line_2": "",
        "passbook_img": ""
    };
    saved = false;

    constructor(isloading = false) {
        makeAutoObservable(this, {
            addEmployeename: observable,
            status: observable,
            errorMessages: observable,
            setaddEmployeename: action,
            getstatus: action,
            resetErrors: action,
        });

        this.Token = localStorage.getItem("Admintoken");
        // if (isloading.isloading) {
        //     this.getEmployeeList();
        //     //   this.getStateList();
        //     return
        // }
        // if (isloading.addloading) {
        //     this.getEmployeeTypeList();
        // }
        if (isloading.Employeeeditloading) {
            this.EditEmployee(isloading.id);


            return
        }
    }
    //All Clear 
    allclear = () => {
        this.filterSearch = ""
        this.getFilterEmployee();
        //   this.status="";
        //   this.statesid="";
    }

    setimage = (text, type) => {
        this.imageurl = URL.createObjectURL(text[0]);
    }

    setalldeactivate = (text) => {
        console.log(text);
        this.alldeactivate = !this.alldeactivate;
    };

    getid = (text) => {
        this.Employeeid.push(text);
    };

    // status popup
    setstatuspopup = () => {
        this.statuspopup = !this.statuspopup;
    };


    setallEmployee = (text) => {
        this.allEmployee = text;
    }

    // get status 
    getstatus = (text) => {
        this.status = text;
    };

    //get city name
    getEmployeename = (text) => {
        this.Employeename = text;
    };

    setaddEmployeename = (text) => {
        this.addEmployeename = text;
    };
    setCrop = (text) => {
        this.crop = text;
    };

    setEmployeeDimiensions = (text) => {
        this.EmployeeDimiensions = text;
    };

    setlogofile = (e) => {
        this.logofile = e;
        console.log(this.logofile);
    };

    setEmployeepopup = (e) => {
        this.Employeepopup = e;
    };
    setdeletepopup = (e) => {
        this.deletepopup = e;
    };
    setdelateLoader = (e) => {
        this.delateLoader = e;
    }
    getToken = (e) => {
        this.Token = e;
    }
    gettype = (e) => {
        this.type = e;
    }
    getseourl = (text) => {
        // console.log(`https://demo.e-kart.com/${text}`);
        this.seoUrl = text;
    };
    getlogoimage = (text) => {
        console.log("checking in the Employee", text);
        this.logoimage = text;
    };


    getEmployee_image = (text) => {
        console.log(text);
        this.Employeeimage = text;
    };



    SetfilterSearch = (text) => {
        this.filterSearch = text;
    };



    setPaginationPage = (text) => {
        this.PaginationPage = text;
    };
    SetTotalPages = (text) => {
        this.TotalPages = text;
    };




    setchecked = (text) => {
        this.checkeddd = text;
    };



    setisEmployeeUpdated = (text) => {
        this.isEmployeeUpdated = text;
    };


    setisLogoUpdated = (text) => {
        this.isLogoUpdated = text;
    };
    isBase64 = (str) => {
        const base64Regex = /^(data:image\/([a-zA-Z]+);base64,)/;
        return base64Regex.test(str);
    };
    setlicence_img_1 = (text) => {
        this.licence_img_1 = text;
    };

    setlicence_img_2 = (text) => {
        this.licence_img_2 = text;
    };

    setpan_img = (text) => {
        this.panImage = text;
    };


    setvoter_img_2 = (text) => {
        console.log("zjhsdklfklsdklfkldjhfn", text);
        this.voter_img_2 = text;
    };

    setvoter_img_1 = (text) => {
        this.voter_img_1 = text;
    };

    setaadhar_img_1 = (text) => {
        this.aadhar_img_1 = text;
    };
    setuserId = (text) => {
        this.userId = text;
    };
    setaadhar_img_2 = (text) => {
        this.aadhar_img_2 = text;
    };

    setbank_passbook_img = (text) => {
        this.bank_passbook_img = text;
    };

    setlicence_img_1 = (text) => {
        this.licence_img_1 = text;
    };

    setlicence_img_1 = (text) => {
        this.licence_img_1 = text;
    };

    setlicence_img_1 = (text) => {
        this.licence_img_1 = text;
    };
    getusername = (text) => {
        // console.log(`https://demo.e-kart.com/${text}`);
        this.username = text;
    };
    getlogoimage = (text) => {
        console.log("checking in the brand", text);
        this.logoimage = text;
    };

    // get password value
    passwordChanged = (text) => {
        this.password = text
    };
    setmobile = (text) => {
        this.mobile = text
    };

    Confirmpassw = (text) => {
        this.confirmresetpas = text
    };
    setname = (text) => {
        this.name = text;
    };

    setbank_ifsc = (text) => {
        this.bank_ifsc = text;
    };

    setbank_branch_addr = (text) => {
        this.bank_branch_addr = text;
    };

    setbank_ac_no = (text) => {
        this.bank_ac_no = text;
    };

    setaadhar_no = (text) => {
        this.aadhar_no = text;
    };
    setvoter_no = (text) => {
        this.voter_no = text;
    };

    setpan_no = (text) => {
        this.pan_no = text;
    };

    setlicence_no = (text) => {
        this.licence_no = text;
    };
    setaddr_line_1 = (text) => {
        this.addr_line_1 = text;
    };

    setaddr_line_2 = (text) => {
        this.addr_line_2 = text;
    };

    setcity = (text) => {
        this.city = text;
    };

    setstate = (text) => {
        this.state = text;
    };
    setemail = (text) => {
        this.email = text;
    };

    setsalary = (text) => {
        this.salary = text;
    };

    setDesignation = (text) => {
        this.Designation = text;
    };
    setbankName = (text) => {
        this.bankName = text;
    };

    setAdminUserFormDetails = (AdminUserDetail) => {
        this.AdminUserFormDetails = {
            ...this.AdminUserFormDetails,
            full_name: AdminUserDetail.full_name || "", // Use default value if name is undefined
            username: AdminUserDetail.username || "", // Use default value if seo_url is undefined
            // logo_image: this.isBase64( this.AdminUserFormDetails.logo_image) ? this.logofile.length == 0 ? AdminUserDetail.logo_image    : this.AdminUserFormDetails.logo_image  : this.logofile.length !== 0 ? this.AdminUserFormDetails.logo_image :  AdminUserDetail.logo_image   || "",
            // banner_image: this.isBase64(AdminUserDetail.banner_image) ? this.AdminUserFormDetails.banner_image : AdminUserDetail.banner_image || "",
            email: AdminUserDetail.email || "",
            image: AdminUserDetail.image || "",
            mobile: AdminUserDetail.mobile || "",
            user_id: AdminUserDetail.user_id || "",
            system_generated_alert: AdminUserDetail.system_generated_alert || "",
            is_active: AdminUserDetail.is_active
        };
    };
  
    setotherDetail = (otherDetail) => {
        console.log("==================>>>>>>>>>>>>>>>>>>>>>>>>>>",otherDetail);
        this.OtherFormDetail = {
            ...this.OtherFormDetail,
            aadhar_img_1: otherDetail.aadhar_img_1 || "", // Use default value if name is undefined
            aadhar_img_2: otherDetail.aadhar_img_2 || "", // Use default value if seo_url is undefined
            // logo_image: this.isBase64( this.AdminUserFormDetails.logo_image) ? this.logofile.length == 0 ? otherDetail.logo_image    : this.AdminUserFormDetails.logo_image  : this.logofile.length !== 0 ? this.AdminUserFormDetails.logo_image :  otherDetail.logo_image   || "",
            // banner_image: this.isBase64(otherDetail.banner_image) ? this.AdminUserFormDetails.banner_image : otherDetail.banner_image || "",
            address_line_2: otherDetail.address_line_2 || "",
            aadhar_no : otherDetail.aadhar_no || "",
            voter_no: otherDetail.voter_no || "",
            licence_no: otherDetail.licence_no || "",
            pan_no: otherDetail.pan_no || "",
            designation: otherDetail.designation || "",
            licence_img_1: otherDetail.licence_img_1 || "",
            licence_img_2: otherDetail.licence_img_2 || "",
            pan_img_1: otherDetail.pan_img_1,
            voter_img_1: otherDetail.voter_img_1 || "",
            voter_img_2: otherDetail.voter_img_2 || "",
            salary: otherDetail.salary || "",
            address_line_2: otherDetail.address_line_2 || "",
            designation: otherDetail.designation || "",

        };
    };
    bankDetail = {
        "acc_no": "",
        "bank_ifsc": "",
        "bank_name": "",
        "branch_addr": "",
        "address_line_2": "",
        "passbook_img": ""
    };
    setbankDetail = (bankDetail ) => {
        this.bankDetail = {
            ...this.OtherFormDetail,
            acc_no: bankDetail .acc_no || "", 
            bank_ifsc: bankDetail .bank_ifsc || "",
            bank_name: bankDetail .bank_name || "",
            branch_addr: bankDetail .branch_addr || "",
            address_line_2: bankDetail .address_line_2 || "",
            passbook_img: bankDetail .passbook_img || "",
           
        };
    };














    setEmployeeFormDetails = (EmployeeDetail) => {
        this.EmployeeFormDetails = {
            ...this.EmployeeFormDetails,
            title: EmployeeDetail.title || "", // Use default value if name is undefined
            url: EmployeeDetail.url || "", // Use default value if seo_url is undefined
            // logo_image: this.isBase64( this.EmployeeFormDetails.logo_image) ? this.logofile.length == 0 ? EmployeeDetail.logo_image    : this.EmployeeFormDetails.logo_image  : this.logofile.length !== 0 ? this.EmployeeFormDetails.logo_image :  EmployeeDetail.logo_image   || "",
            // Employee_image: this.isBase64(EmployeeDetail.Employee_image) ? this.EmployeeFormDetails.Employee_image : EmployeeDetail.Employee_image || "",
            image_name: EmployeeDetail.image_name || "",
            section_id: EmployeeDetail.section_id || "",
            section_name: EmployeeDetail.section_name || "",

            is_active: EmployeeDetail.is_active
        };
    };
    // setEmployeeFormDetails = (text) => {
    //     this.EmployeeFormDetails = text;
    //     console.log("setEmployeeFormDetails", this.EmployeeFormDetails.Employeename);
    // };
    base64toFile(base64Data, fileName, mimeType) {
        if (base64Data) {
            const byteCharacters = atob(base64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters?.length; offset += 512) {
                const slice = byteCharacters?.slice(offset, offset + 512);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const file = new File(byteArrays, fileName, { type: mimeType });

            return file;
        }

    }

    // get Employee list
    getEmployeeList = () => {
        runInAction(() => {
            this.EmployeeLoader = true;
            this.errLoadEmployee = "";
            this.saved = false;
            this.nolist = false;
            this.Employee = [];
        });
        getMethodData(`/api/v1/admin/Employee/get-list`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.EmployeeLoader = false;
                        this.errLoadEmployee = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.EmployeeLoader = false;
                        this.errLoadEmployee = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.Employee = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadEmployee = false;
                    this.errLoadEmployee = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.EmployeeLoader = false;
                    if (!error.response) {
                        this.errLoadEmployee = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadEmployee = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    // get Employee type list
    getEmployeeTypeList = () => {
        runInAction(() => {
            this.EmployeeLoader = true;
            this.errLoadEmployee = "";
            this.saved = false;
            this.nolist = false;
            this.Employeetype = [];
        });
        getMethodData(`/api/v1/admin/sections/auto-complete`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.EmployeeLoader = false;
                        this.errLoadEmployee = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.EmployeeLoader = false;
                        this.errLoadEmployee = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.Employeetype = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadEmployee = false;
                    this.errLoadEmployee = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.EmployeeLoader = false;
                    if (!error.response) {
                        this.errLoadEmployee = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadEmployee = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };
    //Filter city
    // filter League court

    getFilterEmployee = () => {
        runInAction(() => {
            this.LoadEmployee = true;
            this.errLoadEmployee = "";
            this.saved = false;
            this.nolist = false;
            this.getEmployeeList = [];
        });
        postMethodData(`/api/v1/admin/Employee/search?page=${this.PaginationPage}`, {
            keyword: this.filterSearch
        }, this.Token)
            .then((resp) => {

                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.loadleague = false;
                        this.errloadleague = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {

                    runInAction(() => {
                        this.loadcity = false;
                        this.errloadleague = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.Employee = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.loadcity = false;
                    this.errloadcuty = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.loadleague = false;
                    if (!error.response) {
                        this.errloadleague = error?.message;
                        toast.error(error?.message);
                    }
                    this.errloadleague = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    // addUser = () => {
    //     console.log(this.password , this.confirmresetpas);
    //     if (this.password != this.confirmresetpas) {
    //         runInAction(() => {
    //             toast.error("The password and confirmation password do not match.")
    //         })
    //         return;
    //     }
    //     runInAction(() => {
    //         this.addloadAdminUsersLoadAdminUsers = true;
    //         this.errLoadAminUsers = "";
    //     });
    //     const formData = new FormData();

    //     formData.append('fullname', this.name);



    //     formData.append('username', this.username);

    //     formData.append('email', this.email);


    //     formData.append('password', this.password);
    //     formData.append('mobile', this.mobile);
    //     formData.append('confirmPassword', this.confirmresetpas);
    //     formData.append('status', this.status);
    //     formData.append('alert', this.alert ? 0 : 1);




    //     postMethodData("/api/v1/admin/admin-users/add", formData, this.Token).then((resp) => {
    //         if (resp?.data?.status === false) {
    //             runInAction(() => {
    //                 this.addloadAdminUsersLoadAdminUsers = false;
    //                 this.errLoadAminUsers = resp?.data?.message;
    //                 toast.error(resp.data.message);
    //             });
    //             return;
    //         }
    //         if (resp?.data?.status === true) {
    //             runInAction(() => {
    //                 this.addloadAdminUsersLoadAdminUsers = false;
    //                 this.errLoadAminUsers = resp?.data?.message;
    //                 // toast.success(resp.data.message);
    //                 this.getFilterAdminUser();
    //                 this.saved = true;
    //             })
    //             return
    //         }
    //         runInAction(() => {
    //             this.addloadAdminUsersLoadAdminUsers = false;
    //             this.errLoadAminUsers = resp?.data?.message;
    //         });

    //     })
    //         .catch((error) => {
    //             runInAction(() => {
    //                 this.addloadAdminUsersLoadAdminUsers = false;
    //                 console.log(error.message);
    //                 toast.error(error?.response?.data?.message);
    //                 if (!error.response) {
    //                     this.errLoadAminUsers = error?.message;
    //                     toast.error(error?.response?.data?.message);
    //                 }
    //                 if (error.response && error.response.data && error.response.data.formErrors) {
    //                     const formErrors = error.response.data.formErrors;
    //                     Object.keys(formErrors).forEach((key) => {
    //                         const errorMessage = formErrors[key];
    //                         toast.error(`${key}: ${errorMessage}`);
    //                     });
    //                 }
    //             });
    //             return;
    //         });
    // };

    addEmployee = () => {
        runInAction(() => {
            this.addloadPreOrder = true;
            this.errLoadPreOrder = "";
        });

        if (this.password != this.confirmresetpas) {
            runInAction(() => {
                toast.error("The password and confirmation password do not match.")
            })
            return;
        }

        const formData = new FormData();

        if (this.isBase64(this.licence_img_1)) {
            const PreOrderbase64String = this.licence_img_1.split(',')[1];
            const PreOrderFile = this.base64toFile(PreOrderbase64String, 'logo.png', 'image/png');
            formData.append('licence_img_1', PreOrderFile);
        }
        else {
            formData.append('licence_img_1', this.Base64licence_img_1);
        }

        if (this.isBase64(this.licence_img_2)) {
            const PreOrderbase64String = this.licence_img_2.split(',')[1];
            const PreOrderFile = this.base64toFile(PreOrderbase64String, 'logo.png', 'image/png');
            formData.append('licence_img_2', PreOrderFile);
        }
        else {
            formData.append('licence_img_2', this.Base64licence_img21);
        }

        if (this.isBase64(this.panImage)) {
            const PreOrderbase64String = this.panImage.split(',')[1];
            const PreOrderFile = this.base64toFile(PreOrderbase64String, 'logo.png', 'image/png');
            formData.append('pan_img_1', PreOrderFile);
        }
        else {
            formData.append('pan_img_1', this.Base64licence_img21);
        }
        if (this.isBase64(this.Employeeimage)) {
            const PreOrderbase64String = this.Employeeimage.split(',')[1];
            const PreOrderFile = this.base64toFile(PreOrderbase64String, 'logo.png', 'image/png');
            formData.append('profile_img', PreOrderFile);
        }
        else {
            formData.append('pan_img_1', this.Base64licence_img21);
        }



        if (this.isBase64(this.voter_img_2)) {
            const PreOrderbase64String = this.voter_img_2.split(',')[1];
            const PreOrderFile = this.base64toFile(PreOrderbase64String, 'logo.png', 'image/png');
            formData.append('voter_img_2', PreOrderFile);
        }
        else {
            formData.append('voter_img_2', this.Base64voter_img_2);
        }

        if (this.isBase64(this.voter_img_1)) {
            const PreOrderbase64String = this.voter_img_1.split(',')[1];
            const PreOrderFile = this.base64toFile(PreOrderbase64String, 'logo.png', 'image/png');
            formData.append('voter_img_1', PreOrderFile);
        }
        else {
            formData.append('voter_img_1', this.Base64voter_img_1);
        }

        if (this.isBase64(this.aadhar_img_2)) {
            const PreOrderbase64String = this.aadhar_img_2.split(',')[1];
            const PreOrderFile = this.base64toFile(PreOrderbase64String, 'logo.png', 'image/png');
            formData.append('aadhar_img_2', PreOrderFile);
        }
        else {
            formData.append('aadhar_img_2', this.Base64aadhar_img_2);
        }

        if (this.isBase64(this.aadhar_img_1)) {
            const PreOrderbase64String = this.aadhar_img_1.split(',')[1];
            const PreOrderFile = this.base64toFile(PreOrderbase64String, 'logo.png', 'image/png');
            formData.append('aadhar_img_1', PreOrderFile);
        }
        else {
            formData.append('aadhar_img_1', this.Baseaadhar_img_1);
        }

        if (this.isBase64(this.bank_passbook_img)) {
            const PreOrderbase64String = this.bank_passbook_img.split(',')[1];
            const PreOrderFile = this.base64toFile(PreOrderbase64String, 'logo.png', 'image/png');
            formData.append('bank_passbook_img', PreOrderFile);
        }
        else {
            formData.append('bank_passbook_img', this.Basebank_passbook_img);
        }


        formData.append('fullname', this.name);
        formData.append('username', this.username);
        formData.append('confirmPassword', this.confirmresetpas);
        formData.append('email', this.email);
        formData.append('password', this.password);
        formData.append('mobile', this.mobile);
        formData.append('bank_ifsc', this.bank_ifsc);
        formData.append('salary', this.salary);
        formData.append('designation', this.Designation);
        formData.append('bank_branch_addr', this.bank_branch_addr);
        formData.append('bank_ac_no', this.bank_ac_no);
        formData.append('aadhar_no', this.aadhar_no);
        formData.append('voter_no', this.voter_no);
        formData.append('pan_no', this.pan_no);
        formData.append('licence_no', this.licence_no);
        formData.append('addr_line_1', this.addr_line_1);
        formData.append('addr_line_2', this.addr_line_2);
        formData.append('city', this.city);
        formData.append('state', this.state);
        formData.append('bank_name', this.bankName);


        // formData.append('section_id', this.PreOrderFormDetails.section_id == "" ? null : this.PreOrderFormDetails.section_id);


        // formData.append('status', this.PreOrderFormDetails.is_active);

        // formData.append('isPreOrderUpdated', false  );
        // formData.append('isLogoUpdated', true  );


        postMethodData(`/api/v1/admin/admin-users/add`,
            formData,
            this.Token
        )
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.addloadcity = false;
                        this.erraddcity = resp?.data?.message;
                        toast.error(resp.data.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {

                        this.addloadcity = false;
                        this.erraddcity = resp?.data?.message;
                        toast.success(resp.data.message);
                        // this.getCity();
                        // this.getPreOrderList();
                        this.saved = true;
                    })
                    return
                }
                runInAction(() => {
                    this.addloadcity = false;
                    this.erraddcity = resp?.data?.message;
                    toast.error(resp.data.message);
                });
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.addloadcity = false;
                        this.erraddcity = error?.message;
                        toast.error(error?.message);
                    }
                    if (error.response && error.response.data && error.response.data.formErrors) {
                        const formErrors = error.response.data.formErrors;
                        Object.keys(formErrors).forEach((key) => {
                            const errorMessage = formErrors[key];
                            toast.error(`${key}: ${errorMessage}`);
                        });
                    }
                    this.addloadcity = false;
                    this.erraddcity = error?.response?.data?.message;
                })
                return;
            })
    };
    //get Brand by id
    EditEmployee = (id) => {
        runInAction(() => {
            this.AdminUserLoader = true;
            this.adminUserpopup = true;

            this.errLoadAminUsers = "";
        });
        postMethodData(`/api/v1/admin/admin-users/form`, {

            "recordId": id,
            // "status": JSON.stringify(this.status)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.AdminUserLoader = false;
                        this.adminUserpopup = false;
                        this.errLoadAminUsers = resp?.data?.message;
                        // toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        console.log(resp.data.data);
                        this.setAdminUserFormDetails(resp.data.data.userDetail);
                        this.setotherDetail(resp.data.data.otherDetail);
                        this.setbankDetail(resp.data.data.bankDetail);
                        this.Base64logoaadhar_img_1 = resp.data.data.otherDetail.aadhar_img_1
                        this.Base64logoaadhar_img_2 = resp.data.data.otherDetail.aadhar_img_2

                        this.Base64logolicence_img_1 = resp.data.data.otherDetail.licence_img_1

                        this.Base64loglicence_img_2 = resp.data.data.otherDetail.licence_img_2

                        this.Base64logvoter_img_1 = resp.data.data.otherDetail.voter_img_1
                        this.Base64logvoter_img_2 = resp.data.data.otherDetail.voter_img_2
                        this.Base64logopan_img_1 = resp.data.data.otherDetail.pan_img_1

                        this.Base64logoUser_img = resp.data.data.userDetail.image
                        this.Base64logoBank_pass_image = resp.data.data.bankDetail.passbook_img


                        this.alert = resp.data.data.userDetail.system_generated_alert;
                        this.AdminUserLoader = false;
                        this.adminUserpopup = false;
                        this.errLoadAminUsers = resp?.data?.message;
                    })
                    return;
                }
                runInAction(() => {
                    this.AdminUserLoader = false;
                    this.adminUserpopup = false;

                    this.errLoadAminUsers = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.AdminUserLoader = false;
                        this.adminUserpopup = false;

                        this.errLoadAminUsers = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.AdminUserLoader = false;
                    this.adminUserpopup = false;

                    this.errLoadAminUsers = error?.response?.data?.message;
                })
                return;
            })
    };

    //Active and inactive
    //Active and inactive
    statusChange = () => {
        runInAction(() => {
            this.SectionLoader = true;
            this.Sectionpopup = true;

            this.errLoadSection = "";
        });
        postMethodData(`/api/v1/admin/Employee/update-status`, {

            "record_id": this.Employeeid,
            "status": JSON.stringify(this.status)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.Sectionid = [];
                        this.Employeeid = [];

                        this.SectionLoader = false;
                        this.Employeepopup = false;
                        this.errLoadSection = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.SectionLoader = false;
                        this.Employeepopup = false;
                        this.errLoadSection = resp?.data?.message;
                        this.getEmployeeList();
                        this.Sectionid = [];
                        this.Employeeid = [];
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.SectionLoader = false;
                    this.Employeepopup = false;

                    this.errLoadSection = resp?.data?.message;
                })
            })
            .catch((error) => {
                this.Employeeid = [];

                runInAction(() => {
                    if (!error.response) {
                        this.SectionLoader = false;
                        this.Employeepopup = false;
                        this.Sectionid = [];
                        this.errLoadSection = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.SectionLoader = false;
                    this.Employeepopup = false;
                    this.Sectionid = [];


                    this.errLoadSection = error?.response?.data?.message;
                })
                return;
            })
    };
    // Bulk Active and inactive
    BulkstatusChange = (allEmployee) => {
        runInAction(() => {
            this.EmployeeLoader = true;
            this.Employeepopup = true;

            this.errLoadEmployee = "";
        });

        postMethodData(`/api/v1/admin/Employee/update-status`, {

            "branchId": JSON.stringify(allEmployee),
            "status": JSON.stringify(this.alldeactivate ? 1 : 0)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.EmployeeLoader = false;
                        this.Employeepopup = false;
                        this.errLoadEmployee = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.EmployeeLoader = false;
                        this.Employeepopup = false;

                        this.errLoadEmployee = resp?.data?.message;
                        this.getFilterEmployee();
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.EmployeeLoader = false;
                    this.Employeepopup = false;

                    this.errLoadEmployee = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.EmployeeLoader = false;
                        this.Employeepopup = false;

                        this.errLoadEmployee = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.EmployeeLoader = false;
                    this.Employeepopup = false;

                    this.errLoadEmployee = error?.response?.data?.message;
                })
                return;
            })
    };

    //Delete method
    deletefunction = () => {
        runInAction(() => {
            this.setdelateLoader(true);
            this.deletepopup = true;
            this.errLoadEmployee = "";
        });
        postMethodData(`/api/v1/admin/Employee/delete`, {

            "record_id": this.Employeeid,

        }, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.Employeeid = [];

                    this.LoadEmployee = false;
                    this.setdelateLoader(true);
                    this.deletepopup = true;
                    this.errLoadEmployee = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.LoadEmployee = false;
                    this.Employeeid = [];

                    this.setdelateLoader(false);
                    this.deletepopup = false;
                    this.errLoadEmployee = resp?.data?.message;
                    this.getEmployeeList();
                    toast.success(resp.data.message);
                })
                return;
            }
            runInAction(() => {
                this.LoadEmployee = false;
                this.errLoadEmployee = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadcity = false;
                    this.Employeeid = [];

                    if (!error.response) {
                        this.LoadEmployee = false;
                        this.setdelateLoader(false);
                        this.deletepopup = false;
                        this.errLoadEmployee = error?.message;
                        toast.error(error?.message);
                    }
                    this.LoadEmployee = false;
                    this.errLoadEmployee = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            })
    }
    // Bulk Delete 
    BulkDelete = (allEmployee) => {
        runInAction(() => {
            this.EmployeeLoader = true;
            this.Employeepopup = true;

            this.errLoadEmployee = "";
        });

        postMethodData(`/api/v1/admin/Employee/multi-delete`, {

            "EmployeeId": JSON.stringify(allEmployee),
            // "status": JSON.stringify(this.alldeactivate ? 1 : 0)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.EmployeeLoader = false;
                        this.Employeepopup = false;
                        this.errLoadEmployee = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.EmployeeLoader = false;
                        this.Employeepopup = false;

                        this.errLoadEmployee = resp?.data?.message;
                        this.getFilterEmployee();
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.EmployeeLoader = false;
                    this.Employeepopup = false;

                    this.errLoadEmployee = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.EmployeeLoader = false;
                        this.Employeepopup = false;

                        this.errLoadEmployee = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.EmployeeLoader = false;
                    this.Employeepopup = false;

                    this.errLoadEmployee = error?.response?.data?.message;
                })
                return;
            })
    };

    //get Employee by id
    getEmployeeform = (id) => {
        runInAction(() => {
            this.EmployeeLoader = true;
            this.Employeepopup = true;

            this.errLoadEmployee = "";
        });
        postMethodData(`/api/v1/admin/Employee/form`, {

            "record_id": id,
            // "status": JSON.stringify(this.status)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.EmployeeLoader = false;
                        this.Employeepopup = false;
                        this.errLoadEmployee = resp?.data?.message;
                        // toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        console.log(resp.data.data);
                        this.setEmployeeFormDetails(resp.data.data);
                        this.Base64EmployeeImage = resp.data.data.image_name;
                        this.EmployeeLoader = false;
                        this.Employeepopup = false;
                        this.Employeename = resp.data.data.title;
                        this.type = resp.data.data.section_id;
                        this.errLoadEmployee = resp?.data?.message;
                    })
                    return;
                }
                runInAction(() => {
                    this.EmployeeLoader = false;
                    this.Employeepopup = false;

                    this.errLoadEmployee = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.EmployeeLoader = false;
                        this.Employeepopup = false;

                        this.errLoadEmployee = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.EmployeeLoader = false;
                    this.Employeepopup = false;

                    this.errLoadEmployee = error?.response?.data?.message;
                })
                return;
            })
    };



    //update method
    updateEmployee = (id) => {
        runInAction(() => {
            this.addloadEmployee = true;
            this.errLoadEmployee = "";
        });
        console.log("this.OtherFormDetail.aadhar_img_1",this.OtherFormDetail.aadhar_img_1);

        const formData = new FormData();
        if (this.isBase64(this.OtherFormDetail.licence_img_1)) {
            const PreOrderbase64String = this.OtherFormDetail.licence_img_1.split(',')[1];
            const PreOrderFile = this.base64toFile(PreOrderbase64String, 'logo.png', 'image/png');
            formData.append('licence_img_1', PreOrderFile);
        }
        else {
            formData.append('licence_img_1', this.Base64logoaadhar_img_1);
        }

        if (this.isBase64(this.OtherFormDetail.licence_img_2)) {
            const PreOrderbase64String = this.OtherFormDetail.licence_img_2.split(',')[1];
            const PreOrderFile = this.base64toFile(PreOrderbase64String, 'logo.png', 'image/png');
            formData.append('licence_img_2', PreOrderFile);
        }
        else {
            formData.append('licence_img_2', this.Base64logoaadhar_img_2);
        }

        if (this.isBase64(this.OtherFormDetail.pan_img_1)) {
            const PreOrderbase64String = this.OtherFormDetail.pan_img_1.split(',')[1];
            const PreOrderFile = this.base64toFile(PreOrderbase64String, 'logo.png', 'image/png');
            formData.append('pan_img_1', PreOrderFile);
        }
        else {
            formData.append('pan_img_1', this.Base64logopan_img_1);
        }

        if (this.isBase64(this.AdminUserFormDetails.image)) {
            const PreOrderbase64String = this.AdminUserFormDetails.image.split(',')[1];
            const PreOrderFile = this.base64toFile(PreOrderbase64String, 'logo.png', 'image/png');
            formData.append('profile_img', PreOrderFile);
        }
        else {
            formData.append('profile_img', this.Base64logoUser_img);
        }
        



        if (this.isBase64(this.OtherFormDetail.voter_img_2)) {
            const PreOrderbase64String = this.OtherFormDetail.voter_img_2.split(',')[1];
            const PreOrderFile = this.base64toFile(PreOrderbase64String, 'logo.png', 'image/png');
            formData.append('voter_img_2', PreOrderFile);
        }
        else {
            formData.append('voter_img_2', this.Base64logvoter_img_2);
        }

        if (this.isBase64(this.OtherFormDetail.voter_img_1)) {
            const PreOrderbase64String = this.OtherFormDetail.voter_img_1.split(',')[1];
            const PreOrderFile = this.base64toFile(PreOrderbase64String, 'logo.png', 'image/png');
            formData.append('voter_img_1', PreOrderFile);
        }
        else {
            formData.append('voter_img_1', this.Base64logvoter_img_1);
        }

        if (this.isBase64(this.OtherFormDetail.aadhar_img_2)) {
            const PreOrderbase64String = this.OtherFormDetail.aadhar_img_2.split(',')[1];
            const PreOrderFile = this.base64toFile(PreOrderbase64String, 'logo.png', 'image/png');
            formData.append('aadhar_img_2', PreOrderFile);
        }
        else {
            formData.append('aadhar_img_2', this.Base64logoaadhar_img_2);
        }

        if (this.isBase64(this.OtherFormDetail.aadhar_img_1)) {
            const PreOrderbase64String = this.OtherFormDetail.aadhar_img_1.split(',')[1];
            const PreOrderFile = this.base64toFile(PreOrderbase64String, 'logo.png', 'image/png');
            formData.append('aadhar_img_1', PreOrderFile);
        }
        else {
            formData.append('aadhar_img_1', this.Base64logoaadhar_img_1);
        }

        if (this.isBase64(this.bankDetail.passbook_img)) {
            const PreOrderbase64String = this.bankDetail.passbook_img.split(',')[1];
            const PreOrderFile = this.base64toFile(PreOrderbase64String, 'logo.png', 'image/png');
            formData.append('bank_passbook_img', PreOrderFile);
        }
        else {
            formData.append('bank_passbook_img', this.Base64logoBank_pass_image);
        }


        formData.append('fullname', this.AdminUserFormDetails.full_name);
        formData.append('username', this.AdminUserFormDetails.username);
        formData.append('email',this.AdminUserFormDetails.email);
        formData.append('mobile',this.AdminUserFormDetails.mobile);
        formData.append('bank_ifsc', this.bankDetail.bank_ifsc);
        formData.append('salary', this.OtherFormDetail.salary);
        formData.append('designation',  this.OtherFormDetail.designation);
        formData.append('bank_branch_addr', this.bankDetail.branch_addr);
        formData.append('bank_ac_no', this.bankDetail.acc_no);
        formData.append('aadhar_no',this.OtherFormDetail.aadhar_no);
        formData.append('voter_no',this.OtherFormDetail.voter_no);
        formData.append('pan_no', this.OtherFormDetail.pan_no);
        formData.append('licence_no',this.OtherFormDetail.licence_no);
        formData.append('addr_line_1', this.addr_line_1);
        formData.append('addr_line_2', this.OtherFormDetail.address_line_2);
        // formData.append('city', this.city);
        // formData.append('state', this.state);
        formData.append('bank_name', this.bankDetail.bank_name);


        // formData.append('isEmployeeUpdated', false  );
        formData.append('recordId', id  );


        putMethod(`/api/v1/admin/admin-users/edit`,
            formData,
            this.Token
        )
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.addloadcity = false;
                        this.erraddcity = resp?.data?.message;
                        toast.error(resp.data.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.addloadcity = false;
                        this.erraddcity = resp?.data?.message;
                        toast.success(resp.data.message);
                        // this.getCity();
                        this.saved = true;
                    })
                    return
                }
                runInAction(() => {
                    this.addloadcity = false;
                    this.erraddcity = resp?.data?.message;
                    toast.error(resp.data.message);
                });
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.addloadcity = false;
                        this.erraddcity = error?.message;
                        toast.error(error?.message);
                    }
                    if (error.response && error.response.data && error.response.data.formErrors) {
                        const formErrors = error.response.data.formErrors;
                        Object.keys(formErrors).forEach((key) => {
                            const errorMessage = formErrors[key];
                            toast.error(`${key}: ${errorMessage}`);
                        });
                    }
                    this.addloadcity = false;
                    this.erraddcity = error?.response?.data?.message;
                })
                return;
            })
    };

}




export const EmployeeStoreContext = createContext(new EmployeeStore());
export const useEmployeeStore = () => useContext(EmployeeStoreContext);
