import { createContext, useContext } from "react";
import { action, makeAutoObservable, makeObservable, observable, runInAction } from "mobx";
import { postMethodData, getMethodData, putMethod, delMethod, patchMethodData } from "../../ApiMethods/api";
import { toast } from "react-toastify";
const SEO_URL = process.env.REACT_APP_SEO_URL;





export class AdminUseStore {

    Token = null;
    AdminUsers = [];
    LoadAdminUsers = false;
    filterSearch = "";
    PaginationPage = "1";
    alert = "";
    TotalPages = "";
    BannerDimiensions = ""
    crop = {};
    errLoadAminUsers = "";
    LoadAdminUsers = false;
    AdminUserLoader = false;
    delateLoader = false;
    nolist = false;
    adminUserpopup = false;
    deletepopup = false;
    name = "";
    status = "1";
    statesid = "";
    user_id = [];
    seoUrl = "";
    statuspopup = false;
    addbrandname = "";
    imageurl = "";
    logoimage = "";
    bannerimage = "";
    Base64logoImage = "";
    Base64BannerImage = "";
    logofile = [];
    allbrand = [];
    alldeactivate = 'false';
    checkeddd = '';
    password = "";
    mobile="";
    errPassword = "";
    confirmresetpas = "";
    username = "";
    email = "";
    alert = "false";
    passwordpopup = false;
    passwordLoading = false;
    AdminPermissions = [];
    adminpermissions = {};
    AdminUserFormDetails = {
        "full_name": "",
        "mobile": "",
        "username": "",
        "email": "",
        "system_generated_alert": "",
        "is_active": ""
    };
    saved = false;

    constructor(isloading = false) {
        makeAutoObservable(this, {
            addbrandname: observable,
            status: observable,
            errorMessages: observable,
            setaddbrandname: action,
            getstatus: action,
            resetErrors: action,
        });

        this.Token = localStorage.getItem("Admintoken");
        if (isloading.isloading) {
            this.getFilterAdminUser();

            //   this.getStateList();
            return
        }
        if (isloading.addloading) {
            //   this.getStateList();
        }
        if (isloading.AdminUsereditloading) {
            this.getAdminUserform(isloading.id);
            return
        }
        if (isloading.AdminPermissions) {
            this.getAdminPermissions(isloading.id);
            return
        }
    }
    //All Clear 
    allclear = (id) => {
        this.filterSearch = ""
        this.getFilterAdminUser(id);
        // this.getAdminPermissions(id);
        //   this.status="";
        //   this.statesid="";
    }
    setimage = (text, type) => {
        this.imageurl = URL.createObjectURL(text[0]);
    }

    setalldeactivate = (text) => {
        console.log(text);
        this.alldeactivate = !this.alldeactivate;
    };

    getid = (text) => {
        this.user_id.push(text);
    };


    // status popup
    setstatuspopup = () => {
        this.statuspopup = !this.statuspopup;
    };


    setallBrand = (text) => {
        this.allbrand = text;
    }

    // get status 
    getstatus = (text) => {
        this.status = text;
    };

    //get city name
    getbrandname = (text) => {
        this.brandname = text;
    };
    setemail = (text) => {
        this.email = text;
    };
    setname = (text) => {
        this.name = text;
    };
    setCrop = (text) => {
        this.crop = text;
    };

    setBannerDimiensions = (text) => {
        this.BannerDimiensions = text;
    };

    setlogofile = (e) => {
        this.logofile = e;
        console.log(this.logofile);
    };

    setadminuserspopup = (e) => {
        this.adminUserpopup = e;
    };
    setdeletepopup = (e) => {
        this.deletepopup = e;
    };
    setdelateLoader = (e) => {
        this.delateLoader = e;
    }
    getToken = (e) => {
        this.Token = e;
    }
    getusername = (text) => {
        // console.log(`https://demo.e-kart.com/${text}`);
        this.username = text;
    };
    getlogoimage = (text) => {
        console.log("checking in the brand", text);
        this.logoimage = text;
    };
    getbanner_image = (text) => {
        console.log(text);
        this.bannerimage = text;
    };
    SetfilterSearch = (text) => {
        this.filterSearch = text;
    };
    setPaginationPage = (text) => {
        this.PaginationPage = text;
    };
    SetTotalPages = (text) => {
        this.TotalPages = text;
    };
    setchecked = (text) => {
        this.checkeddd = text;
    };
    // get password value
    passwordChanged = (text) => {
        this.password = text
    };
    setmobile = (text) => {
        this.mobile = text
    };

    Confirmpassw = (text) => {
        this.confirmresetpas = text
    };
    setalert = (text) => {
        this.alert = !this.alert
    };
    setpasswordpopup = () => {
        this.passwordpopup = !this.passwordpopup
        this.errPassword = ""
        // this.currentpassword = ""
        // this.newpassword = ""
        // this.confirmnewpassword = ""
    }

    isBase64 = (str) => {
        try {
            return btoa(atob(str)) === str;
        } catch (err) {
            return false;
        }
    };
    setAdminUserFormDetails = (AdminUserDetail) => {
        this.AdminUserFormDetails = {
            ...this.AdminUserFormDetails,
            full_name: AdminUserDetail.full_name || "", // Use default value if name is undefined
            username: AdminUserDetail.username || "", // Use default value if seo_url is undefined
            // logo_image: this.isBase64( this.AdminUserFormDetails.logo_image) ? this.logofile.length == 0 ? AdminUserDetail.logo_image    : this.AdminUserFormDetails.logo_image  : this.logofile.length !== 0 ? this.AdminUserFormDetails.logo_image :  AdminUserDetail.logo_image   || "",
            // banner_image: this.isBase64(AdminUserDetail.banner_image) ? this.AdminUserFormDetails.banner_image : AdminUserDetail.banner_image || "",
            email: AdminUserDetail.email || "",
            mobile: AdminUserDetail.mobile || "",
            system_generated_alert: AdminUserDetail.system_generated_alert || "",
            is_active: AdminUserDetail.is_active
        };
    };
    setadminpermissions = (permissions) => {
        this.adminpermissions = {
            ...this.adminpermissions,
            ...permissions,
        };
        console.log(JSON.stringify(this.adminpermissions));
    };
    // setAdminUserFormDetails = (text) => {
    //     this.AdminUserFormDetails = text;
    //     console.log("setAdminUserFormDetails", this.AdminUserFormDetails.brandname);
    // };
    base64toFile(base64Data, fileName, mimeType) {
        if (base64Data) {
            const byteCharacters = atob(base64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                const slice = byteCharacters.slice(offset, offset + 512);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const file = new File(byteArrays, fileName, { type: mimeType });

            return file;
        }

    }

    // get brand list
    getAdminUserList = () => {
        runInAction(() => {
            this.AdminUserLoader = true;
            this.errLoadAminUsers = "";
            this.saved = false;
            this.nolist = false;
            this.AdminUsers = [];
        });
        getMethodData(`/api/v1/admin/admin-users/get-list?page=${this.PaginationPage + 1}`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.AdminUserLoader = false;
                        this.errLoadAminUsers = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.AdminUserLoader = false;
                        this.errLoadAminUsers = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.AdminUsers = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadAdminUsers = false;
                    this.errLoadAminUsers = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.AdminUserLoader = false;
                    if (!error.response) {
                        this.errLoadAminUsers = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadAminUsers = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };
    //Filter city
    // filter Admin User

    getFilterAdminUser = () => {
        runInAction(() => {
            this.LoadAdminUsers = true;
            this.errLoadAminUsers = "";
            this.saved = false;
            this.nolist = false;
        });
        postMethodData(`/api/v1/admin/admin-users/search?page=${this.PaginationPage}`, {
            keyword: this.filterSearch
        }, this.Token)
            .then((resp) => {

                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.LoadAdminUsers = false;
                        this.errloadleague = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {

                    runInAction(() => {
                        this.LoadAdminUsers = false;
                        this.errloadleague = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.AdminUsers = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.loadcity = false;
                    this.errloadcuty = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.LoadAdminUsers = false;
                    if (!error.response) {
                        this.errloadleague = error?.message;
                        toast.error(error?.message);
                    }
                    this.errloadleague = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    // filter User Permissions

    getFilterUserPermissions = (id) => {
        runInAction(() => {
            this.LoadAdminUsers = true;
            this.errLoadAminUsers = "";
            this.saved = false;
            this.nolist = false;
            this.getAdminUserList = [];
        });
        postMethodData(`/api/v1/admin/admin-permissions/search`, {
            keyword: this.filterSearch,
            recordId: id

        }, this.Token)
            .then((resp) => {

                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.loadleague = false;
                        this.errloadleague = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {

                    runInAction(() => {
                        this.loadcity = false;
                        this.errloadleague = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.AdminPermissions = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.loadcity = false;
                    this.errloadcuty = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.loadleague = false;
                    if (!error.response) {
                        this.errloadleague = error?.message;
                        toast.error(error?.message);
                    }
                    this.errloadleague = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    // add new User
    addUser = () => {
        console.log(this.password , this.confirmresetpas);
        if (this.password != this.confirmresetpas) {
            runInAction(() => {
                toast.error("The password and confirmation password do not match.")
            })
            return;
        }
        runInAction(() => {
            this.addloadAdminUsersLoadAdminUsers = true;
            this.errLoadAminUsers = "";
        });
        const formData = new FormData();

        formData.append('fullname', this.name);



        formData.append('username', this.username);

        formData.append('email', this.email);


        formData.append('password', this.password);
        formData.append('mobile', this.mobile);
        formData.append('confirmPassword', this.confirmresetpas);
        formData.append('status', this.status);
        formData.append('alert', this.alert ? 0 : 1);




        postMethodData("/api/v1/admin/admin-users/add", formData, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.addloadAdminUsersLoadAdminUsers = false;
                    this.errLoadAminUsers = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.addloadAdminUsersLoadAdminUsers = false;
                    this.errLoadAminUsers = resp?.data?.message;
                    // toast.success(resp.data.message);
                    this.getFilterAdminUser();
                    this.saved = true;
                })
                return
            }
            runInAction(() => {
                this.addloadAdminUsersLoadAdminUsers = false;
                this.errLoadAminUsers = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadAdminUsersLoadAdminUsers = false;
                    console.log(error.message);
                    toast.error(error?.response?.data?.message);
                    if (!error.response) {
                        this.errLoadAminUsers = error?.message;
                        toast.error(error?.response?.data?.message);
                    }
                    if (error.response && error.response.data && error.response.data.formErrors) {
                        const formErrors = error.response.data.formErrors;
                        Object.keys(formErrors).forEach((key) => {
                            const errorMessage = formErrors[key];
                            toast.error(`${key}: ${errorMessage}`);
                        });
                    }
                });
                return;
            });
    };

    //Active and inactive
    statusChange = (id) => {
        runInAction(() => {
            this.AdminUserLoader = true;
            this.adminUserpopup = true;

            this.errLoadAminUsers = "";
        });
        console.log("statussssssssssssssss", this.user_id);
        postMethodData(`/api/v1/admin/admin-users/update-status`, {
            "status": this.status,
            "recordId": this.user_id
        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.AdminUserLoader = false;
                        this.adminUserpopup = false;
                        this.errLoadAminUsers = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.AdminUserLoader = false;
                        this.adminUserpopup = false;
                        this.user_id = [];
                        this.errLoadAminUsers = resp?.data?.message;
                        this.getFilterAdminUser();
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.AdminUserLoader = false;
                    this.adminUserpopup = false;

                    this.errLoadAminUsers = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.AdminUserLoader = false;
                        this.adminUserpopup = false;

                        this.errLoadAminUsers = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.AdminUserLoader = false;
                    this.adminUserpopup = false;

                    this.errLoadAminUsers = error?.response?.data?.message;
                })
                return;
            })
    };
    // Bulk Active and inactive
    BulkstatusChange = (allbrand) => {
        runInAction(() => {
            this.AdminUserLoader = true;
            this.adminUserpopup = true;

            this.errLoadAminUsers = "";
        });

        postMethodData(`/api/v1/admin/admin-users/update-status`, {


            "recordId": JSON.stringify(allbrand),
            "status": JSON.stringify(this.alldeactivate ? 1 : 0)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.AdminUserLoader = false;
                        this.adminUserpopup = false;
                        this.errLoadAminUsers = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.AdminUserLoader = false;
                        this.adminUserpopup = false;

                        this.errLoadAminUsers = resp?.data?.message;
                        this.getFilterAdminUser();
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.AdminUserLoader = false;
                    this.adminUserpopup = false;

                    this.errLoadAminUsers = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.AdminUserLoader = false;
                        this.adminUserpopup = false;

                        this.errLoadAminUsers = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.AdminUserLoader = false;
                    this.adminUserpopup = false;

                    this.errLoadAminUsers = error?.response?.data?.message;
                })
                return;
            })
    };

    //Delete method
    deletefunction = () => {
        runInAction(() => {
            this.setdelateLoader(true);
            this.deletepopup = true;
            this.errLoadAminUsers = "";
        });
        postMethodData(`/api/v1/admin/brand/delete`, {

            "user_id": JSON.stringify(this.user_id),

        }, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.LoadAdminUsers = false;
                    this.setdelateLoader(true);
                    this.deletepopup = true;
                    this.errLoadAminUsers = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.LoadAdminUsers = false;
                    this.setdelateLoader(false);
                    this.deletepopup = false;
                    this.errLoadAminUsers = resp?.data?.message;
                    this.getFilterAdminUser();
                    // toast.success(resp.data.message);
                })
                return;
            }
            runInAction(() => {
                this.LoadAdminUsers = false;
                this.errLoadAminUsers = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadcity = false;
                    if (!error.response) {
                        this.LoadAdminUsers = false;
                        this.setdelateLoader(false);
                        this.deletepopup = false;
                        this.errLoadAminUsers = error?.message;
                        toast.error(error?.message);
                    }
                    this.LoadAdminUsers = false;
                    this.errLoadAminUsers = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            })
    }
    // Bulk Delete 
    BulkDelete = (allbrand) => {
        runInAction(() => {
            this.AdminUserLoader = true;
            this.adminUserpopup = true;

            this.errLoadAminUsers = "";
        });

        postMethodData(`/api/v1/admin/brand/multi-delete`, {

            "user_id": JSON.stringify(allbrand),
            // "status": JSON.stringify(this.alldeactivate ? 1 : 0)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.AdminUserLoader = false;
                        this.adminUserpopup = false;
                        this.errLoadAminUsers = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.AdminUserLoader = false;
                        this.adminUserpopup = false;

                        this.errLoadAminUsers = resp?.data?.message;
                        this.getFilterAdminUser();
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.AdminUserLoader = false;
                    this.adminUserpopup = false;

                    this.errLoadAminUsers = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.AdminUserLoader = false;
                        this.adminUserpopup = false;

                        this.errLoadAminUsers = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.AdminUserLoader = false;
                    this.adminUserpopup = false;

                    this.errLoadAminUsers = error?.response?.data?.message;
                })
                return;
            })
    };

    //get Brand by id
    getAdminUserform = (id) => {
        runInAction(() => {
            this.AdminUserLoader = true;
            this.adminUserpopup = true;

            this.errLoadAminUsers = "";
        });
        postMethodData(`/api/v1/admin/admin-users/form`, {

            "recordId": id,
            // "status": JSON.stringify(this.status)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.AdminUserLoader = false;
                        this.adminUserpopup = false;
                        this.errLoadAminUsers = resp?.data?.message;
                        // toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        console.log(resp.data.data);
                        this.setAdminUserFormDetails(resp.data.data.userDetail);
                        this.alert = resp.data.data.userDetail.system_generated_alert;
                        this.AdminUserLoader = false;
                        this.adminUserpopup = false;
                        this.errLoadAminUsers = resp?.data?.message;
                    })
                    return;
                }
                runInAction(() => {
                    this.AdminUserLoader = false;
                    this.adminUserpopup = false;

                    this.errLoadAminUsers = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.AdminUserLoader = false;
                        this.adminUserpopup = false;

                        this.errLoadAminUsers = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.AdminUserLoader = false;
                    this.adminUserpopup = false;

                    this.errLoadAminUsers = error?.response?.data?.message;
                })
                return;
            })
    };



    //update method
    updateEditAdmin = (id) => {
        runInAction(() => {
            this.addloadAdminUsersLoadAdminUsers = true;
            this.errLoadAminUsers = "";
        });

        const formData = new FormData();
        console.log(this.AdminUserFormDetails.system_generated_alert);

        formData.append('fullname', this.AdminUserFormDetails.full_name);
        formData.append('username', this.AdminUserFormDetails.username);

        formData.append('recordId', id);

        formData.append('email', this.AdminUserFormDetails.email);
        formData.append('mobile', this.AdminUserFormDetails.mobile);
        formData.append('alert', this.AdminUserFormDetails.system_generated_alert || this.alert);

        formData.append('status', this.AdminUserFormDetails.is_active);




        putMethod(`/api/v1/admin/admin-users/edit`,
            formData,
            this.Token
        )
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.AdminUserLoader = false;
                        this.errLoadAminUsers = resp?.data?.message;
                        toast.error(resp.data.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.AdminUserLoader = false;
                        this.errLoadAminUsers = resp?.data?.message;
                        toast.success(resp.data.message);
                        // this.getCity();
                        this.getFilterAdminUser();
                        this.saved = true;
                    })
                    return
                }
                runInAction(() => {
                    this.AdminUserLoader = false;
                    this.errLoadAminUsers = resp?.data?.message;
                    toast.error(resp.data.message);
                });
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.AdminUserLoader = false;
                        this.errLoadAminUsers = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.AdminUserLoader = false;
                    this.errLoadAminUsers = error?.response?.data?.message;
                })
                return;
            })
    };
    //Admin Permissions List 

    getAdminPermissions = (id) => {
        runInAction(() => {
            this.AdminUserLoader = true;
            this.adminUserpopup = true;

            this.errLoadAminUsers = "";
        });
        getMethodData(`/api/v1/admin/admin-permissions/list/${id}`,
            this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.AdminUserLoader = false;
                        this.adminUserpopup = false;
                        this.errLoadAminUsers = resp?.data?.message;
                        // toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.AdminPermissions = resp?.data?.data;
                        this.AdminUserLoader = false;
                        this.adminUserpopup = false;
                        this.errLoadAminUsers = resp?.data?.message;
                    })
                    return;
                }
                runInAction(() => {
                    this.AdminUserLoader = false;
                    this.adminUserpopup = false;

                    this.errLoadAminUsers = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.AdminUserLoader = false;
                        this.adminUserpopup = false;

                        this.errLoadAminUsers = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.AdminUserLoader = false;
                    this.adminUserpopup = false;

                    this.errLoadAminUsers = error?.response?.data?.message;
                })
                return;
            })
    };
    //update password
    updateUserPass = (id) => {
        if (this.password != this.confirmresetpas) {
            runInAction(() => {
                toast.error("The password and confirmation password do not match.")
            })
            return;
        }
        runInAction(() => {
            this.passwordLoading = true;
            this.errLoadAminUsers = "";
        });

        const formData = new FormData();
        formData.append('password', this.password);
        formData.append('confirmPassword', this.confirmresetpas);
        formData.append('recordId', id);

        postMethodData(`/api/v1/admin/admin-users/update-password`,
            formData,
            this.Token
        )
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.AdminUserLoader = false;
                        this.passwordLoading = false;
                        this.errLoadAminUsers = resp?.data?.message;
                        toast.error(resp.data.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.setpasswordpopup();
                        toast.success(resp.data.message);
                        this.getFilterAdminUser();
                        this.saved = true;
                    })
                    return
                }
                runInAction(() => {
                    this.AdminUserLoader = false;
                    this.errLoadAminUsers = resp?.data?.message;
                    toast.error(resp.data.message);
                });
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.AdminUserLoader = false;
                        this.passwordLoading = false;
                        this.errLoadAminUsers = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.AdminUserLoader = false;
                    this.errLoadAminUsers = error?.response?.data?.message;
                })
                return;
            })
    };
    //Update User Permissions
    updateUserPermissions = async (id, permission_value) => {
        await postMethodData(`/api/v1/admin/admin-permissions/update`,
            {
                permissionList: this.adminpermissions,
                recordId: id
            },
            this.Token
        )
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.AdminUserLoader = false;
                        this.passwordLoading = false;
                        this.errLoadAminUsers = resp?.data?.message;
                        toast.error(resp.data.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        toast.success(resp.data.message);
                        this.getAdminPermissions(id);
                        this.saved = true;
                    })
                    return
                }
                runInAction(() => {
                    this.AdminUserLoader = false;
                    this.errLoadAminUsers = resp?.data?.message;
                    toast.error(resp.data.message);
                });
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {

                        this.AdminUserLoader = false;
                        this.passwordLoading = false;
                        this.errLoadAminUsers = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    console.log(error);

                    this.AdminUserLoader = false;
                    this.errLoadAminUsers = error?.response?.data?.message;
                })
                return;
            })
    };
}





export const AdminUserStoreContext = createContext(new AdminUseStore());
export const useAdminUsedStore = () => useContext(AdminUserStoreContext);
