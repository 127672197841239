/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Input } from "react-daisyui";
import { MdOutlineCheck, MdOutlineClose } from "react-icons/md";
import { Link, useParams, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";

import { useLevelStore , LevelStore, LevelStoreContext } from "./store/LevelStore";
const EditLevelManagement = observer(() => {
  const { id } = useParams();
  return (
    <LevelStoreContext.Provider
      value={
        new LevelStore({
          loadeditcitybol: false,
          editloading: true,
          id: id,
        })
      }
    >
      <Screen />
    </LevelStoreContext.Provider>
  );
});
export default EditLevelManagement;

const Screen = observer(() => {
  const {
  addlevel,
  adddescription,
  addstatus,
  addlevels,
  adddescriptions,
  addstatusss,
  updateLevel,
  saved,
  loadeditlevel
  } = useLevelStore();
  const { id } = useParams()
  const navigate = useNavigate()
  useEffect(() => {
    if (saved) {
      navigate("../levelmanagement")
    }
  }, [saved])
  

  const [startdatestate,setstartdatestate] = useState(null)

  return (
    <>
    <div className="flex justify-between mt-[51px] ">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold">Edit Level</p>
        </div>
        <div>
          <Link
            to="../levelmanagement"
            className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
          >
            Back
          </Link>
        </div>
      </div>
      <div className="card bg-base-100 shadow-md p-6 mt-8">
            <form onSubmit={(e) => {updateLevel(id);e.preventDefault()}}>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
              
                 
                    <div>
                        <label htmlFor="level" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Level<span className="text-[#f00]">&nbsp;*</span></label>
                        <Input 
                        name="level" 
                        placeholder="Level"  
                        onChange={(e) => addlevel(e.target.value)}
                        value={addlevels}
                        step="0.1"
                        id="level" 
                        type="number" 
                        min={0}
                        required
                        autoComplete="off" 
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div>

                    <div>
                        <label htmlFor="description" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Description<span className="text-[#f00]">&nbsp;*</span></label>
                        <textarea
                        name="description" 
                        placeholder="" 
                        value={adddescriptions}
                        onChange={(e) => adddescription(e.target.value)}
                        id="description" 
                        type="text" 
                        autoComplete="off" 
                        required
                        className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
                        />
                      
                    </div>
                    <div>
                        <label htmlFor="Status" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Status<span className="text-[#f00]">&nbsp;*</span></label>
                        <select 
                        id="Status"
                        name="Status"
                        required
                        value={addstatusss}
                        onChange={(e) => addstatus(e.target.value)}
                        className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
        
                            <option value="true">Active</option>
                            <option value="false">Inactive </option>
                        </select>
                    </div>
                
                </div>
                <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                        <Link to="../levelmanagement" className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"><MdOutlineClose className="mr-1"/>Cancel</Link>
                        {!loadeditlevel?
                        <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"><MdOutlineCheck className="mr-1"/>Update</button>
                        :
                        <button 
                         className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                          type="button"
                        //  onClick={()=> changeStep("4")}
                        >
                        <svg
                            className="animate-spin mr-1 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            >
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                          Loading..
                        </button>
                        }
                </div>
            </form>
        </div>
    </>
  );
});
