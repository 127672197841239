import { createContext, useContext } from "react";
import { makeAutoObservable, observable, runInAction } from "mobx";
import { format } from 'date-fns';
import {
  postMethodData,
  getMethodData,
  putMethod,
  delMethod,
  patchMethodData,
} from "../../ApiMethods/api";
import { toast } from "react-toastify";
import Moment from "react-moment";

export class LeagueStore {
  Token = null;
  LeagueLoader=false;
  Leaguepopup=false;
  loadleague = false;
  current="true";
  League = [];
  SeasonList = [];
  edityear="";
  currenntleaguestatus="open";
  listofseason = "";
  errloadleague = "";
  nolist = false;
  leaguename ="";
  addleague = false;
  editleagueCat = "";
  editleaguetype = "";
  editstartdates = "";
  editenddates = "";
  editfeesamount = "";
  editlastreg = "";
  newseasonname="";
  editstatus = "";
  getseasoniddd = "";
  erraddseasoncourt = "";
  seasonname = "";
  updatedStartDate = "";
  updatedEndDate = "";
  updatedStatus = true;
  startdate = null;
  editstart = null;
  editend = null;
  citydrop=[];
  editname = "";
  startreg="";
  geteditstatus = "";
  enddate = null;
  name = "";
  zipcode = "";
  country = "";
  status = false;
  newcitynameid = "";
 
  updatedcity = {};
  leagueid = "";

  updatedzipcode = "";

  newcityname = "";
  statuspopup = false;

  saved = false;
  /////////////////////////
  season_id = "";
  league_catagory = "";
  league_type = "";
  start_date = "";
  end_date = "";
  fees_amount = "";
  last_registration_date = "";
  isActive = "";
  //////////////////////////////
  leaguestatus = "";
  getleagStatus="";

  leveldropdown=[];

  playofflist=[]
  noplayofflist = false;

  loadplayoff = false;
  errloadplayoff = "";

   editloadplay = false;
   erreditplay = "";
   id="";
   levelid="";

  constructor(isloading = false) {
    makeAutoObservable(this);
    this.Token = localStorage.getItem("Admintoken");
    if (isloading.seasonlist) {
      this.getSeasonList();
      this.getLeagueList();
      this.getcitydropdown();
      this.getleveldropdown();
      return
    }

    if(isloading.playofflist){
      this.getPlayoffList(isloading.id,isloading.levelid)
      this.id=isloading.id;
      this.levelid=isloading.levelid;
      return
    }

    if (isloading.isloading) {
      this.getLeagueList();
      
      return;
    }
    if (isloading.editloading) {
      this.editleaguelist(isloading.id);
      this.getSeasonList();
   
      return
    }
    if(isloading.Leagueloading){
      this.getSeasonOne(isloading.id);
      return
    }
    

  }

  //   // status popup
  //   setstatuspopup = () => {
  //     this.statuspopup = !this.statuspopup;
  //   };
//All clear
allclear=()=>{
  this.editleagueCat="";
  this.editleaguetype = "";
  this.currenntleaguestatus="";
  this.listofseason="";
  this.editstartdates="";
  this.editenddates="";
  this.editstatus="";
  this.leaguename="";
  this.newcitynameid="";
  this.getleagStatus = "";
  this.leaguestatus ="";
  this.edityear="";
  this.setcurrent("false")


}
getToken=(e)=>{
  this.Token=e;
  }
setcurrent=(e)=>{
  this.current =e;
}
setleaguepopup = (e) => {
  this.Leaguepopup = e;
};
  //   // get Season id
  getleagaueid = (text) => {
    this.leagueid = text;
  };
  updatedcitynameid = (text) => {
    this.newcitynameid = text;
  };
  //Get league status
  getleaguestatus = (text) => {
    this.leaguestatus = text;
  }

  getCurrentleaguestatus = (text) => {
    this.currenntleaguestatus = text;
  }
  startregisteration=(text)=>{
    this.startreg = text;
  }
  //edit year 
edityears=(text)=>{
  this.edityear=text;
}
// Edit league status
editleaguestatus=(text)=>{
  this.editedstatus = text;
}

  //   // get home name
  //   getHomename = (text) => {
  //     this.homename = text;
  //   }

  // get status
  getstatus = (text) => {
    this.status = text;
  };
  // get updated status
  getupdatedstatus = (text) => {
    this.updatedStatus = text;
  };

  //get name
  getname = (text) => {
    this.name = text;
  };

  //get start date
  getstartdate = (text) => {
    this.startdate = text;
  };
  //get end date
  getenddate = (text) => {
    this.enddate = text;
  };
  //edit start date
  editstartdate = (text) => {
    this.updatedStartDate = text;
  };
  //edit end date
  editenddate = (text) => {
    this.updatedEndDate = text;
  };

  //edit season name
  editseasonname = (text) => {
    this.editname = text;
  };
  editseasonstatus = (text) => {
    this.editstatus = text;
  };

  //add league Category
  addleagueCategory = (text) => {
    this.editleagueCat = text;
  };
  //add league Type
  addleaguetyp = (text) => {
    this.editleaguetype = text;
  };
  //add Start Date
  addstartdat = (text) => {
    this.editstartdates = text;
  };
  //add End Date
  addenddat = (text) => {
    this.editenddates = text;
  };
  getleaguestats=(text)=>{
    this.getleagStatus = text
  }
  //add Fees Amount
  addfeesamnt = (text) => {
    this.editfeesamount = text;
  };
  //add last Registeration
  addlastregesteration = (text) => {
    this.editlastreg = text;
  };
  addseasontyp = (text) => {
    this.listofseason = text;
  }
  //add status
  addstat = (text) => {
    this.editstatus = text;
  };
  getseasonidforleague = (text) => {
    this.getseasoniddd = text
   
  }
  // get  league name
  addname= (text) =>{
  this.leaguename = text;
  }
  getyear=(text)=>{
    this.edityear=text;
  }
  // get League list
  getLeagueList = () => {
    runInAction(() => {
      this.loadleague = true;
      this.nolist = false;
      this.errloadleague = "";
      this.saved = false;
      this.League = [];
    });
    getMethodData("/api/admin/league", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadleague = false;
            this.errloadhomecourt = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadleague = false;
            this.errloadhomecourt = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.League = resp?.data?.data;
            this.saved = false;
            this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.addleague = false;
          this.loadleague = false;
          this.errloadleague = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadSeason = false;
          if (!error.response) {
            this.errloadseason = error?.message;
            toast.error(error?.message);
          }
          this.errloadseason = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };
  // filter League court

  getFilterLeaguecourt = () => {
    runInAction(() => {
      this.loadleague = true;
      this.nolist = false;
      this.errloadleague = "";
      this.saved = false;
      this.League =[];
    });

    getMethodData(`/api/admin/league?filters[name]=${this.leaguename}&filters[season_id]=${this.listofseason}&filters[year]=${this.edityear}&filters[league_catagory]=${this.editleagueCat}&filters[league_type]=${this.editleaguetype}&filters[from_date]=${this.editstartdates}&filters[to_date]=${this.editenddates}&filters[isActive]=${this.editstatus}&filters[league_status]=${this.leaguestatus}&filters[city]=${this.newcitynameid}`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadleague = false;
            this.errloadleague = resp?.data?.message
          });
          return;
        }
        if (resp?.data?.status === true) {

          runInAction(() => {
            this.loadleague = false;
            this.errloadleague = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.League = resp?.data?.data;
            this.saved = false;
            this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadleague = false;
          this.errloadleague = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadleague = false;
          if (!error.response) {
            this.errloadleague = error?.message;
            toast.error(error?.message);
          }
          this.errloadleague = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        })
        return;
      });
  };
  //getCurrentleagueFilter

  getCurrentleagueFilter = () => {
    runInAction(() => {
      this.loadleague = true;
      this.nolist = false;
      this.errloadleague = "";
      this.saved = false;
      this.League =[];
    });

    getMethodData(`/api/admin/league?filters[league_status]=${this.currenntleaguestatus}`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadleague = false;
            this.errloadleague = resp?.data?.message
          });
          return;
        }
        if (resp?.data?.status === true) {

          runInAction(() => {
            this.loadleague = false;
            this.errloadleague = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.League = resp?.data?.data;
            this.saved = false;
            this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadleague = false;
          this.errloadleague = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadleague = false;
          if (!error.response) {
            this.errloadleague = error?.message;
            toast.error(error?.message);
          }
          this.errloadleague = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        })
        return;
      });
  };
  //get Season by id
  getSeasonOne = (id) => {
    getMethodData(`/api/admin/season/${id}`, this.Token)
      .then((resp) => {
        runInAction(() => {
          this.loadhomecourt = true;
          this.errloadhomecourt = "";
        });
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadhomecourt = false;
            this.errloadhomecourt = resp.data.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
          this.loadhomecourt = false;
          this.errloadhomecourt = resp?.data?.message;
          this.editstartdates = format(new Date(resp?.data?.data?.start_date), 'yyyy-MM-dd');
          this.editenddates = format(new Date(resp?.data?.data?.end_date), 'yyyy-MM-dd');
          this.listofseason = resp?.data?.data?._id;
          this.newcitynameid =resp?.data?.data?.city;
          this.edityear = resp?.data?.data?.year;
          // this.updatedStatus = resp?.data?.data?.isActive;
          this.getFilterLeaguecourt();
          })
          return;
        }
        runInAction(() => {
          this.loadhomecourt = false;
          this.errloadhomecourt = resp.data.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.loadhomecourt = false;
            this.errloadhomecourt = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.loadhomecourt = false;
          this.errloadhomecourt = error?.response?.data?.message;
        })
        return;
      })
  }
  // get Edit league
  editleaguelist = (id) => {
    runInAction(() => {
      this.addleague = true;
      this.errloadleague = "";
    });

    getMethodData(
      `/api/admin/league/${id}`, this.Token
    )
      .then((resp) => {

        if (resp?.data?.status === false) {
          runInAction(() => {
            this.addseasoncourt = false;
            this.erraddhomecourt = resp?.data?.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.addleague = false;
            this.errloadleague = resp?.data?.message;
            this.editleagueCat = resp.data.data.league_catagory;
            this.editleaguetype = resp.data.data.league_type;
            this.editstartdates =format(new Date(resp?.data?.data?.start_date), 'yyyy-MM-dd');
            this.startreg= format(new Date(resp?.data?.data?.start_registration_date), 'yyyy-MM-dd');
            this.editenddates = format(new Date(resp?.data?.data?.end_date), 'yyyy-MM-dd');
            this.editlastreg = format(new Date(resp?.data?.data?.last_registration_date), 'yyyy-MM-dd');
            this.editstatus = resp.data.data.isActive;
            this.leaguename=resp.data.data.name;
            this.edityear = resp?.data?.data?.season_id.year;
            this.leaguestatus=resp.data.data.league_status;
            this.editfeesamount = resp.data.data.fees_amount;
            this.listofseason=resp.data.data.season_id._id;
           

          });
          return;
        
        }
        runInAction(() => {
          this.addseasoncourt = false;
          this.erraddseasoncourt = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.addseasoncourt = false;
          if (!error.response) {
            this.erraddhomecourt = error?.message;
            toast.error(error?.message);
          }
          this.erraddseasoncourt = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };
  // Season list
  getSeasonList = () => {
    runInAction(() => {
      this.errloadleague = "";
      this.saved = false;
    });
    getMethodData("/api/get/seasons", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.errloadhomecourt = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.errloadhomecourt = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.SeasonList = resp?.data?.data;
            // this.listofseason = resp?.data?.data?.name;
            this.saved = false;
            this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.addleague = false;
          this.errloadleague = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadSeason = false;
          if (!error.response) {
            this.errloadseason = error?.message;
            toast.error(error?.message);
          }
          this.errloadseason = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };

  //Active and inactive
  statusChange = () => {
    runInAction(() => {
      this.LeagueLoader = true;
      this.Leaguepopup=true;
      this.errloadleague = "";
    });
    patchMethodData(
      `/api/admin/league/status/${this.leagueid}`,
      { isActive: `${!this.status}` },
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.LeagueLoader = true;
            this.Leaguepopup=true;
            this.errloadleague = resp?.data?.message;
            toast.error(resp?.data?.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.LeagueLoader = false;
            this.Leaguepopup=false;
            this.errloadleague = resp?.data?.message;
            this.getLeagueList()
            toast.success(resp?.data?.message);
          });
          return;
        }
        runInAction(() => {
          this.errloadleague = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.LeagueLoader = false;
            this.errloadleague = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.errloadleague = error?.response?.data?.message;
        });
        return;
      });
  };
  //League status 
  getallleaguestatus = () => {
    runInAction(() => {
      this.errloadleague = "";
    });
    patchMethodData(
      `/api/admin/league/leagueStatus/${this.leagueid}`,
      { league_status: this.leaguestatus },
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.errloadleague = resp?.data?.message;
            toast.error(resp?.data?.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.errloadleague = resp?.data?.message;
            this.getLeagueList()
            toast.success(resp?.data?.message);
          });
          return;
        }
        runInAction(() => {
          this.errloadleague = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.errloadleague = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.errloadleague = error?.response?.data?.message;
        });
        return;
      });
  };



  //update method
  updateLeagues = (id) => {
    runInAction(() => {
      this.addleague = true;
      this.errloadhomecourt = "";
      this.saved = false;
    });
    putMethod(`/api/admin/league/${id}`,
      {
        season_id: this.listofseason,
        name: this.leaguename,
        league_catagory: this.editleagueCat,
        league_type: this.editleaguetype,
        start_date: this.editstartdates,
        start_registration_date: this.startreg,
        end_date: this.editenddates,
        fees_amount: this.editfeesamount,
        last_registration_date: this.editlastreg,
        isActive: this.editstatus,
        league_status:this.leaguestatus,
        year:parseInt(this.edityear)
      },
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.addleague = false;
            this.addloadhomecourt = false;
            this.erraddhomecourt = resp?.data?.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.addleague = false;
            this.addloadhomecourt = false;
            this.erraddhomecourt = resp?.data?.message;
            toast.success(resp.data.message);
            this.saved = true;
          })
          return
        }
        runInAction(() => {
          this.addleague = false;
          this.addloadhomecourt = false;
          this.erraddhomecourt = resp?.data?.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.addleague = false;
            this.addloadhomecourt = false;
            this.erraddhomecourt = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.addleague = false;
          this.addloadhomecourt = false;
          this.erraddhomecourt = error?.response?.data?.message;
        })
        return;
      })
  };
    // city drop down
    getcitydropdown =() =>{
      runInAction(() => {
       this.loadhomecourt = true;
       this.errloadhomecourt = "";
       // this.saved = false;
      });
     getMethodData("/api/get/cities", this.Token)
     .then((resp) => {
       if (resp?.data?.status === false) {
         runInAction(() => {
           this.loadhomecourt = false;
           this.errloadhomecourt = resp?.data?.message
         });
         return;
       }
       if (resp?.data?.status === true) {
         runInAction(() => {
           this.loadhomecourt = false;
           this.errloadhomecourt = resp?.data?.message;
           // toast.success(resp?.data?.message);
           this.citydrop = resp?.data?.data;
          
         });
         return;
       }
       runInAction(() => {
         this.loadhomecourt = false;
         this.errloadhomecourt = resp?.data?.message;
       });
     })
     .catch((error) => {
       runInAction(() => {
           this.loadhomecourt = false;
           if(!error.response){
               this.errloadhomecourt = error?.message;
               // toast.error(error?.message);
           }
           this.errloadhomecourt = error?.response?.data?.message;
           // toast.error(error?.response?.data?.message);
       })
       return;
   });
   }

   // get level dropdown
getleveldropdown =() =>{
  runInAction(() => {
    this.loadhomecourt = true;
    this.errloadhomecourt = "";
    // this.saved = false;
  });
 getMethodData("/api/get/lavels", this.Token)
 .then((resp) => {
   if (resp?.data?.status === false) {
     runInAction(() => {
      this.loadhomecourt = false;
      this.errloadhomecourt = resp?.data?.message;
      // toast.error(resp.data.message);
     });
     return;
   }
   if (resp?.data?.status === true) {
     runInAction(() => {
      this.loadhomecourt = false;
      this.errloadhomecourt = resp?.data?.message;
       // toast.success(resp?.data?.message);
      //  this.homecourtdrop = resp?.data?.data;
        this.leveldropdown =resp?.data?.data;
     });
     return;
   }
   runInAction(() => {
    this.loadhomecourt = false;
    this.errloadhomecourt = resp?.data?.message;
   });
 })
 .catch((error) => {
   runInAction(() => {
    this.loadhomecourt = false;
       if(!error.response){
        this.errloadhomecourt = error?.message;
           // toast.error(error?.message);
       }
       this.errloadhomecourt = error?.response?.data?.message;
       // toast.error(error?.response?.data?.message);
   })
   return;
});
}


  // get Playoff list
  getPlayoffList = (id,levelid) => {
    runInAction(() => {
      this.loadplayoff = true;
      this.errloadplayoff = "";
      // this.saved = false;
    });
    getMethodData(`/api/admin/playoff/matches?filters[league]=${id}&filters[level]=${levelid}&filters[matchType]=playoff`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadplayoff = false;
          
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadplayoff = false;
         
            if (resp?.data?.data?.matches?.length === 0) {
              this.noplayofflist = true;
            } else {
              this.noplayofflist = false;
            }
            this.playofflist = resp?.data?.data?.matches;
            // this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
    
          this.loadplayoff = false;
          this.errloadplayoff = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadSeason = false;
          if (!error.response) {
            // this.errloadseason = error?.message;
            toast.error(error?.message);
          }
          // this.errloadseason = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };



   //update playoff method
   updateplayoff = (data) => { 
    runInAction(() => {
      this.editloadplay = true;
      this.erreditplay = "";
    });
  
    postMethodData(`/api/admin/playoff/match/update`,
      data,
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.editloadplay = false;
            this.erreditplay = resp?.data?.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
              this.getPlayoffList(this.id,this.levelid);
              this.editloadplay = false;
              this.erreditplay = resp?.data?.message;    
              toast.success(resp.data.message);
              this.saved = true;
            })
            return
        }
        runInAction(() => {
          this.editloadplay = false;
          this.erreditplay = resp?.data?.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
            if(!error.response){
              this.editloadplay = false;
              this.erreditplay = error?.message;
              toast.error(error?.message);
            }
              toast.error(error?.response?.data?.message);
              this.editloadplay = false;
              this.erreditplay = error?.response?.data?.message;
        })
        return;
    })
  };

}

export const LeagueStoreContext = createContext(new LeagueStore());
export const useLeagueStore = () => useContext(LeagueStoreContext);
