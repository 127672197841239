import React, { useEffect } from "react";
import {Link,useNavigate} from "react-router-dom";
import { MdOutlineClose,MdOutlineCheck } from "react-icons/md";
import {Input} from "react-daisyui"
import { observer } from "mobx-react";

const AddSetting = observer(() => {
    // const { addCity,getcityname,getstatus,saved } = useCityStore();
    const navigate = useNavigate()
    
    // useEffect(() => {
    //     if(saved){
    //         navigate("../citymanagement")
    //     }
    // },[saved])
    return(
        <>
        <div className='flex justify-between'>
            <div className=" mb-4">
                <p className=" text-2xl font-semibold">Add City</p>
                {/* <p className=" opacity-80 text-sm">Home /City/ Add</p> */}
            </div>
            <div>
                <Link to="../citymanagement" className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]">Back</Link>
            </div>
        </div>
        <div className="card bg-base-100 shadow-md p-6 mt-8">
            <form onSubmit={(e) => {e.preventDefault()}}>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
                    <div>
                        <label htmlFor="Cityname" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Label<span className="text-[#f00]">&nbsp;*</span></label>
                        <Input 
                        name="Cityname" 
                        required
                        // onChange={(e) => getcityname(e.target.value)}
                        placeholder="City Name" 
                        id="Cityname" 
                        type="text" 
                        autoComplete="off" 
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div>
                    <div>
                        <label htmlFor="Cityname" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Key<span className="text-[#f00]">&nbsp;*</span></label>
                        <Input 
                        name="Key" 
                        required
                        // onChange={(e) => getcityname(e.target.value)}
                        placeholder="Key" 
                        id="Key" 
                        type="text" 
                        autoComplete="off" 
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div>
                    <div>
                        <label htmlFor="Cityname" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Value<span className="text-[#f00]">&nbsp;*</span></label>
                        <Input 
                        name="Value" 
                        required
                        // onChange={(e) => getcityname(e.target.value)}
                        placeholder="Value" 
                        id="Value" 
                        type="number" 
                        autoComplete="off" 
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div>
                    <div>
                            <label htmlFor="state" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Input Type</label>
                            <select
                                id="state"
                                // onChange={(e) => getstatus(e.target.value)}
                                name="state"
                                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                                <option value="">All</option>
                                <option value="text">Text</option>
                                <option value="number">Number</option>
                                <option value="radio">Radio</option>
                                <option value="email">Email</option>
                                <option value="date">date</option>
                                <option value="file">File</option>
                                <option value="url">URL</option>
                            </select>
                        </div>
                 
              
                </div>
                <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                        <Link to="../citymanagement" className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"><MdOutlineClose className="mr-1"/>Cancel</Link>
                        <button  className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"><MdOutlineCheck className="mr-1"/>Add City</button>
                </div>
            </form>
        </div>
                
        </>
    )
})
export default AddSetting;