import React from "react";
// import { Tooltip } from 'react-daisyui'
import {
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { BarChart, Bar } from "recharts";
import { HomeStore, HomeStoreContext, useHomeStore } from "../Store/HomeStore";
import { observer } from "mobx-react";

const Graph = () => {
  const store = new HomeStore({
    isloading: true,
    editloading: false,
  });
  return (
    <HomeStoreContext.Provider value={store}>
      <Screen />
    </HomeStoreContext.Provider>
  );
};
export default Graph;



const data1 = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
];

const data3 = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const Bullet = ({ backgroundColor, size }) => {
  return (
    <div
      className="CirecleBullet"
      style={{
        backgroundColor,
        width: size,
        height: size,
      }}
    ></div>
  );
};

const CustomizedLegend = (props) => {
  const { payload } = props;

  return (
    <ul className=" flex justify-around">
      {payload.map((entry, index) => (
        <li key={`item-${index}`}>
          
          <div className="BulletLabel">
            <Bullet
              className=""
              backgroundColor={entry?.payload?.fill}
              size="10px"
            />
            <div className="BulletLabelText ">
              {entry?.payload?.payload?.payload?.group}
            </div>
          </div>
          <div style={{ marginLeft: "20px" }}>{entry?.payload?.value}</div>
        </li>
      ))}
    </ul>
  );
};
const CustomizedRevenueLegend = (props) => {
  const { payload } = props;

  return (
    <ul className=" flex justify-around">
      {payload.map((entry, index) => (
        <li key={`item-${index}`}>
        
          <div className="BulletLabel">
            <Bullet
              className=""
              backgroundColor={entry?.payload?.fill}
              size="10px"
            />
            <div className="BulletLabelText ">
              {entry?.payload?.payload?.payload?.month}
            </div>
          </div>
          <div style={{ marginLeft: "20px" }}>${entry?.payload?.value}</div>
        </li>
      ))}
    </ul>
  );
};
const CustomTooltip = ({ active, payload, label }) => {
  if ( payload) {
   
    return (
      <div className="custom-tooltip">
        <p className="label">{` ${payload[0]?.payload?.payload?.group}: ${payload[0]?.payload?.payload?.value}`}</p>
     
      </div>
    );
  }
}
const Revenue = ({ active, payload, label }) => {
  if ( payload) {
   
    return (
      <div className="custom-tooltip">
        <p className="label">{` ${payload[0]?.payload?.payload?.month}: $${payload[0]?.payload?.payload?.value}`}</p>
     
      </div>
    );
  }
}

const Screen = observer(() => {
  const {
    PlayerData,
    league_chart_data,
    players_chart_data,
    revanue_chart_data,
  } = useHomeStore();
  const allValuesZero = league_chart_data.every((data) => data.value === 0);
  return (
    <>
      <div className=" grid xl:grid-cols-2 mt-8 gap-8">
        <div className="card bg-base-100 shadow-md p-6 xl:col-span-1">
          <p className=" text-lg font-semibold uppercase">User Graph</p>
          <div style={{ width: "100%", height: 400 }}>
            <ResponsiveContainer>
              <AreaChart
                width={100}
                height={400}
                data={PlayerData}
                margin={{ top: 100, right: 0, left: 0, bottom: 0 }}
              >
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="month" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                {/* <Area type="monotone" dataKey="uv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" /> */}
                <Area
                  type="monotone"
                  dataKey="value"
                  name="user"
                  stroke="#82ca9d"
                  fillOpacity={1}
                  fill="url(#colorPv)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="card bg-base-100 shadow-md p-6">
          <p className=" text-lg font-semibold uppercase text-center">
          Traffic
          </p>
          <div className="relative" style={{ width: "100%", height: 400 }}>
            {allValuesZero ? <> 
              <p className="text-[black] text-[25px] flex absolute top-[30%] left-[30%]   justify-center">No Data Found</p>
              <ResponsiveContainer>
              <PieChart width={300} height={400}>
                <Pie
                  cx={230}
                  cy={200}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  label
                >
                  {league_chart_data.map((entry, index) => (
                    <>
                  
                      <Cell
                        key={`cell-${entry?.group}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    </>
                  ))}
                 
                </Pie>
              
                <Legend
                  content={<CustomizedLegend data={league_chart_data} />}
                />
                   <Tooltip content={<CustomTooltip />} />
              </PieChart>

            </ResponsiveContainer> </>  :  <ResponsiveContainer>
              <PieChart width={300} height={400}>
                <Pie
                  data={league_chart_data}
                  cx={230}
                  cy={200}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                  label
                >
                  {league_chart_data.map((entry, index) => (
                    <>
                   
                      <Cell
                        key={`cell-${entry?.group}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    </>
                  ))}
                 
                </Pie>
              
                <Legend
                  content={<CustomizedLegend data={league_chart_data} />}
                />
                   <Tooltip content={<CustomTooltip />} />
              </PieChart>

            </ResponsiveContainer>  }
           
          </div>
        </div>
        <div className="card bg-base-100 shadow-md p-6 xl:col-span-2">
          <p className=" text-lg font-semibold uppercase text-center">Visitors Statistics </p>
          <div style={{ width: "100%", height: 400 }}>
            <ResponsiveContainer>
              <AreaChart
                width={100}
                height={400}
                data={players_chart_data}
                margin={{ top: 100, right: 0, left: 0, bottom: 0 }}
              >
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="month" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                {/* <Area type="monotone" dataKey="uv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" /> */}
                <Area
                  type="monotone"
                  dataKey="value"
                  name="Player"
                  stroke="#82ca9d"
                  fillOpacity={1}
                  fill="url(#colorPv)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>


      <div className="card bg-base-100 shadow-md p-6 xl:col-span-2">
          <p className=" text-lg font-semibold uppercase text-center">
            Revenue Graph
          </p>
          <div style={{ width: "100%", height: 400 }}>
            <ResponsiveContainer>
              <AreaChart
                width={100}
                height={400}
                data={revanue_chart_data}
                margin={{ top: 100, right: 0, left: 0, bottom: 0 }}
              >
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="month" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                {/* <Area type="monotone" dataKey="uv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" /> */}
                <Area
                  type="monotone"
                  dataKey="value"
                  name="Revenue"
                  stroke="#82ca9d"
                  fillOpacity={1}
                  fill="url(#colorPv)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
          {/* <div style={{ width: "100%", height: 400 }}>
            <ResponsiveContainer>
              <PieChart width={300} height={400}>
                <Pie
                  data={revanue_chart_data}
                  cx={230}
                  cy={200}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                  label
                >
                  {league_chart_data.map((entry, index) => (
                    <>
                      <Cell
                        key={`cell-${entry?.group}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    </>
                  ))}
                </Pie>
                <Tooltip content={Revenue} />
                <Legend
                  content={
                    <CustomizedRevenueLegend data={revanue_chart_data} />
                  }
                />
              </PieChart>
            </ResponsiveContainer>
          </div> */}
        </div>
      
      </div>
    </>
  );
});
