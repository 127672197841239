import React, { useState } from "react";
import { RxHamburgerMenu, RxPencil1 } from "react-icons/rx";
import { Link, useParams } from "react-router-dom";
import { MdAdd, MdProductOptionsingWatermark, MdLocationCity, MdOutlineDelete, MdOutlineGroup } from "react-icons/md";
import { observer } from "mobx-react";
import Filter from "./ProductOptionsValues/Filter";
import ReactPaginate from 'react-paginate';
import { ProductOptionsStore, useProductOptionsStore, ProductOptionsStoreContext } from "./Store/ProductOptionsStore";
import { useAuthStore } from "../Auth/store/Auth";
import { useEffect } from "react";
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import { toast, ToastContainer } from "react-toastify";

const OptionsValues = () => {
    const { id } = useParams();

    const store = new ProductOptionsStore({
        isloading: true,
        optionvalues: true,
        id: id
    });
    return (
        <ProductOptionsStoreContext.Provider value={store}>
            <Screen />
        </ProductOptionsStoreContext.Provider>
    )
}
export default OptionsValues;


const Screen = observer(() => {
    const {
        ProductOptions,
        loadcity,
        nolist,
        ProductValues,
        BulkDelete,
        BulkstatusChange,
        ProductOptionsLoader,
        // setallProductOptions,
        // allProductOptions,
        setPaginationPage,
        setalldeactivate,
        alldeactivate,
        getProductOptionsList,
        getFilterProductOptions,
        TotalPages,
        setProductOptionspopup,
        ProductOptionspopup,
        deletepopup,
        setdeletepopup,
        setdelateLoader,
        delateLoader,
        statusChange,
        setstatuspopup,
        getValueid,
        getstatus,
        deleteValuefunction,
        setchecked,
        checkeddd,
        getToken
    } = useProductOptionsStore();

    const { adminAuthApproved, admin_permissions } = useAuthStore();
    const { name, id } = useParams();

    useEffect(() => {
        if (adminAuthApproved) {
            getToken(localStorage.getItem("Admintoken"));
        }

    }, [adminAuthApproved]);

    const [currentPage, setCurrentPage] = useState(0);
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [allProductOptions, setallProductOptions] = useState([])
    // const [checkeddd, setchecked] = useState(false)

    const [photoIndex, setPhotoIndex] = useState("");
    const ITEMS_PER_PAGE = 10;
    const handlePageChange = ({ selected }) => {
        setPaginationPage(selected + 1);
        getFilterProductOptions();
        setCurrentPage(selected);
    };

    const handleClick = () => {
        const element = document.getElementById("tablescroll");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }
    const startIndex = currentPage * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const ProductOption = ProductValues?.slice(startIndex, endIndex);
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const openLightbox = (index) => {
        setLightboxIsOpen(true);
    };

    const closeLightbox = () => {
        setLightboxIsOpen(false);
    };

    const checkAllCheckboxes = () => {
        setallProductOptions([]);
        // Get all checkboxes with the same name attribute
        var checkboxes = document.getElementsByName('myCheckbox');

        if (checkeddd) {
            for (var i = 0; i < checkboxes.length; i++) {
                checkboxes[i].checked = false;
            }
            setallProductOptions([]);
        } else {
            var emails = [];
            var commaSeparatedEmails = [];
            // var emailArray = emailString.split(",").map((email) => email.trim());
            // Loop through each checkbox and check it
            for (var i = 0; i < checkboxes.length; i++) {
                checkboxes[i].checked = true;
                var emailValues = checkboxes[i].value.split(',');
                if (emailValues.length > 1) {
                    commaSeparatedEmails.push(...emailValues);
                } else {
                    var emailValue = emailValues[0].trim();
                    if (emailValue !== '' && !emails.includes(emailValue)) {
                        emails.push(emailValue);
                    }

                }
            }
            setallProductOptions((prevEmails) => [...prevEmails, ...emails, ...commaSeparatedEmails]);
        }

    }
    const isallcheck = (e) => {
        var checkboxes = document.getElementsByName('myCheckbox');
        for (var i = 0; i < checkboxes.length; i++) {
            var updatedEmails = Array.from(checkboxes)
                .filter((checkbox) => checkbox.checked)
                .flatMap((checkbox) => checkbox.value.split(',').map((email) => email.trim()));
            setallProductOptions(updatedEmails);
            if (!checkboxes[i].checked) {
                setchecked(false)
                return
            }
            else {
                setchecked(true)
            }

        }
    }

    return (
        <>
          <div className="flex justify-between mt-[51px] ">
                <div className=" mb-4">
                    <p className=" text-2xl font-semibold">Product Values</p>
                    <p className=" opacity-80 text-sm">Home / <Link to="../productoptions" >ProductOptions</Link>  / <span className="font-extrabold">{name}</span></p>
                </div>
                <div>
                    <Link
                        to={`/productoptions/optionvalues/addoptionvalues/${id}`}
                        className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                    >
                        <MdAdd />
                        Add Product Values
                    </Link>
                </div>
            </div>
            <Filter />

            {/* <div className="flex justify-between items-center py-2">
                <div className="ml-3">
                    <label className=" font-extrabold">
                        <input className="mr-2" checked={checkeddd} type="checkbox" name="selectall"
                            onClick={() => { checkAllCheckboxes(); setchecked(!checkeddd) }}


                        />
                        Select All
                    </label>
                </div>
                <div className="space-x-3 flex justify-center">

                    <label class={`relative ${allProductOptions.length == 0 && "opacity-50"}  justify-center text-center   inline-flex   cursor-pointer`}>
                        {admin_permissions.ProductOptions === 1 ?

                            <button disabled={allProductOptions.length == 0 && true} className="flex" onClick={() => { BulkDelete(allProductOptions) }}  >
                                <MdOutlineDelete className="text-[red]" />
                                <span class="ms-1 text-sm font-medium text-center text-gray-900 dark:text-gray-300">
                                    All Delete
                                </span>
                            </button>
                            :
                            <button disabled={allProductOptions.length == 0 && true} className="flex" onClick={() => { toast.warning("You have no permissions to edit.") }}  >
                                <MdOutlineDelete className="text-[red]" />
                                <span class="ms-1 text-sm font-medium text-center text-gray-900 dark:text-gray-300">
                                    All Delete
                                </span>
                            </button>
                        }

                    </label>


                </div>
            </div> */}
            <div className="card bg-base-100 shadow-md p-6 mt-8">
                <div className="overflow-x-auto w-full">
                    <table id="tablescroll" className="table table-zebra w-full">
                        <thead>
                            <tr>
                                <th>Display Order</th>
                                <th>Display Name</th>
                                <th>Quantity</th>
                                <th>Unit</th>
                                <th>Option Value Name</th>
                                <th>Action</th>
                            </tr>
                        </thead>

                        <tbody className="text-sm relative">
                            {ProductOptionsLoader &&
                                <>
                                    <tr>
                                        <td colSpan={6} className="animate-pulse py-6"></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={6} className="animate-pulse py-6"></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={6} className="animate-pulse py-6"></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={6} className="animate-pulse py-6"></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={6} className="animate-pulse py-6"></td>
                                    </tr>
                                </>
                            }
                            {
                                (ProductValues.length === 0 ? ProductValues : ProductValues)?.map((data, index) => (
                                    <tr key={index} className="capitalize">
                                        {/* <td>

                                            <input
                                                value={data?.id}
                                                onChange={(e) => { setchecked(false); isallcheck(e.target.value); }}
                                                type="checkbox"
                                                name="myCheckbox"
                                            />
                                        </td> */}
                                        {/* <td>
                                            {currentPage * ITEMS_PER_PAGE + data?.id}
                                            {console.log("ProductOptions list", ProductOptions)}
                                        </td> */}

                                        <td>
                                            {data?.display_order ?? "N/A"}
                                        </td>
                                        <td>
                                            {data?.display_name ?? "N/A"}
                                        </td>
                                        
                                        <td>
                                            {data?.stock_qty ?? "N/A"}
                                        </td>
                                        <td>
                                            {data?.unit_name ?? "N/A"}
                                        </td>
                                        <td>
                                            {data?.option_value_name ?? "N/A"}
                                        </td>


                                        <th >
                                            <div className="flex space-x-3">

                                                <div className="flex items-center space-x-3">
                                                    {admin_permissions.product_options === 1 ?
                                                        <label
                                                            htmlFor="deletecity"
                                                            className=" text-[#d02c2c] cursor-pointer"
                                                            title="Delete"
                                                            onClick={() => { setdeletepopup(true); getValueid(data?.id) }}
                                                        >
                                                            <MdOutlineDelete />
                                                        </label>
                                                        :
                                                        <label
                                                            htmlFor="deletecity"
                                                            className=" text-[#d02c2c] cursor-pointer"
                                                            title="Delete"
                                                            onClick={() => { toast.warning("You have no permissions to edit.") }}
                                                        >
                                                            <MdOutlineDelete />
                                                        </label>
                                                    }

                                                    {/* {admin_permissions.product_options === 1 ? */}
                                                        <button title="Edit">
                                                            <Link to={`/productoptions/optionvalues/editoptionvalues/${id}/${data?.id}`}>
                                                                <RxPencil1 />
                                                            </Link>
                                                        </button>
                                                        {/* :
                                                        <button onClick={() => toast.warning("You have no permissions to edit.")} title="Edit">

                                                            <RxPencil1 />

                                                        </button>
                                                    } */}

                                                </div>
                                            </div>

                                        </th>

                                    </tr>
                                ))
                            }

                            {nolist &&
                                <tr>
                                    <td colSpan={5} className="my-10 text-center">
                                        <MdOutlineGroup className=" text-[#dedede] text-8xl mx-auto" />
                                        <p className=" font-semibold text-lg">No Product Values Found</p>
                                        <Link
                                            to={`/productoptions/optionvalues/addoptionvalues/${id}`}
                                            className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                                        >
                                            <MdAdd />
                                            Add Product Values
                                        </Link>
                                    </td>
                                </tr>}
                        </tbody>
                    </table>
                    {/* {!city?.length == 0 && */}

                    <ReactPaginate
                        initialPage={0}
                        pageCount={Math.ceil(TotalPages)}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageChange}
                        onClick={handleClick}
                        containerClassName={'pagination'}
                        activeClassName={'active'}

                    />

                    {/* status change model end */}
                    {deletepopup &&
                        <>
                            <input type="checkbox" id="deletecity" className="modal-toggle" />
                            <div className="modal">
                                <div className="modal-box relative">
                                    <label

                                        className="btn btn-sm btn-circle absolute right-2 top-2"
                                        onClick={() => { setdelateLoader(false); setdeletepopup(false) }}
                                    >
                                        ✕
                                    </label>
                                    <h3 className="text-lg font-bold text-center">
                                        Are you sure you want to Delete this Product Values
                                    </h3>
                                    <div className="modal-action justify-center">
                                        {/* <label
                                            htmlFor="deletecity"
                                            className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                            onClick={() => deleteValuefunction()}
                                        >
                                            Yes
                                        </label> */}
                                        {!delateLoader ?
                                            <label

                                                className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                                onClick={() => deleteValuefunction(id)}
                                            >
                                                Yes
                                            </label> :
                                            <button
                                                // type="submit"
                                                disabled
                                                className="flex items-center bg-color_theme px-3 mx-5 rounded-md"
                                            // onClick={() => {
                                            //   PostMatchscore();
                                            // }}
                                            >
                                                Yes..
                                                <svg
                                                    className="animate-spin mr-1 h-5 w-5 text-white"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle
                                                        className="opacity-25"
                                                        cx="12"
                                                        cy="12"
                                                        r="10"
                                                        stroke="currentColor"
                                                        strokeWidth="4"
                                                    ></circle>
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    ></path>
                                                </svg>
                                            </button>}
                                        <label
                                            onClick={() => setdeletepopup(false)}
                                            className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </>

                    }
                    {ProductOptionspopup &&
                        <>
                            <input type="checkbox" id="my-modal-5" className="modal-toggle" />
                            <div className="modal">
                                <div className="modal-box relative">
                                    <label

                                        className="btn btn-sm btn-circle absolute right-2 top-2"
                                        onClick={() => setProductOptionspopup(false)}
                                    >
                                        ✕
                                    </label>
                                    <h3 className="text-lg font-bold text-center">
                                        Are you sure you want to change Status ?
                                    </h3>
                                    <div className="modal-action justify-center">
                                        {!ProductOptionsLoader ? <label

                                            className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                            onClick={() => statusChange()}
                                        >
                                            Yes
                                        </label> :
                                            <button
                                                // type="submit"
                                                disabled
                                                className="flex items-center bg-color_theme px-3 mx-5 rounded-md"
                                            // onClick={() => {
                                            //   PostMatchscore();
                                            // }}
                                            >
                                                Yes..
                                                <svg
                                                    className="animate-spin mr-1 h-5 w-5 text-white"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle
                                                        className="opacity-25"
                                                        cx="12"
                                                        cy="12"
                                                        r="10"
                                                        stroke="currentColor"
                                                        strokeWidth="4"
                                                    ></circle>
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    ></path>
                                                </svg>
                                            </button>}

                                        <label
                                            onClick={() => setProductOptionspopup(false)}
                                            // htmlFor="voidstatus"
                                            className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                                        >
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>

                        </>

                    }
                </div>
            </div>
        </>
    );
});

