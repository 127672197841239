import { createContext, useContext, useState } from "react";
import {
  makeAutoObservable,
  useLocalObservable,
  observable,
  runInAction,
} from "mobx";
import {
  postMethodData,
  getMethodData,
  putMethod,
  patchMethodData,
} from "../../ApiMethods/api";
import { toast } from "react-toastify";

export class SettingStore {
  Token = null;
  disabled = false;
  imageurl = "";
  setting = [];
  id = "";
  newfields = false;
  key = "";
  value = "";
  errloadsetting = "";
  loadsetting = false;
  editbtncheck = false;
  nolist = false;
  saved = false;

  constructor(isloading = false) {
    makeAutoObservable(this);

    this.Token = localStorage.getItem("Admintoken");
    if (isloading.slectcity) {
      // this.gethomecourtdropdown()
      this.getleveldropdown();
      this.getSetting();
      this.getImagekey();
    }
    if (isloading.isloading) {
      this.getSetting();
      this.getImagekey();

      // this.gethomecourtdropdown();
      return;
    }
  }

  

  // button toggle
  buttonCheck = () => {
    this.editbtncheck = true;
  };
  newcheck = () => {
    this.editbtncheck = false;
  };
  disabledCheck = () => {
    this.disabled = true;
  };
  disabledon = () => {
    this.newfields = false;
  };
  // Get Key
  Getkey = (text) => {
    this.key = text;
  };
  getvalue = (text) => {
    this.value = text;
  };
  getnewfeilds = () => {
    this.newfields = true;
  };
  // get sett List
  getSetting = () => {
    runInAction(() => {
      this.loadsetting = true;
      this.errloadsetting = "";
      this.saved = false;
    });
    getMethodData("/api/admin/setting", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadsetting = false;
            this.errloadsetting = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loaduser = false;
            this.getImagekey();

            this.errloaduser = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.setting = resp?.data?.data;
            this.imageurl = resp?.data?.data?.image
            this.id = resp?.data?.data._id;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loaduser = false;
          this.errloaduser = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loaduser = false;
          if (!error.response) {
            this.errloaduser = error?.message;
            toast.error(error?.message);
          }
          this.errloaduser = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };

  //api key
  getImagekey = async() => {
  
    await  getMethodData("/api/get/admin/setting/WEBSITE_LOGO",this.Token)
      .then((resp) => { 
        if (resp?.data?.status === true) {
          runInAction(() => {
            // this.status = true;
         this.imageurl= resp?.data?.data?.value;
          });
          return;
        }
      
      })
      .catch((error) => {
    
        return;
      });
  };
  //update method
  updatesetting = () => {
    runInAction(() => {
      this.loadsetting = true;
      this.errloadsetting = "";
      this.saved = false;
    });
    putMethod(
      `/api/admin/setting`,
      {
        id: this.id,
        key: this.key,
        value: this.value,
      },
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.addlevel = false;
            this.errloadsetting = resp?.data?.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadsetting = false;
            this.errloadsetting = resp?.data?.message;
            this.getImagekey();
            this.getSetting();
            toast.success(resp.data.message);
            this.saved = true;
          });
          return;
        }
        runInAction(() => {
          this.addloadhomecourt = false;
          this.errloadsetting = resp?.data?.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.addloadhomecourt = false;
            this.errloadsetting = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.addloadhomecourt = false;
          this.errloadsetting = error?.response?.data?.message;
        });
        return;
      });
  };
}

export const UserSettingContext = createContext(new SettingStore());
export const useSettingStore = () => useContext(UserSettingContext);
