import { createContext, useContext } from "react";
import { makeAutoObservable, observable, runInAction } from "mobx";
import { format } from 'date-fns';
import {
  postMethodData,
  getMethodData,
  putMethod,
  delMethod,
  patchMethodData,
} from "../../ApiMethods/api";
import { toast } from "react-toastify";
import Moment from "react-moment";

export class MatchStore {
  Token = null;
  nolist = false;
  Dname=""
  citydrop=[];
  homecourtdrop=[];
  cityDropdown = [];
  playerlist=[];
  leveldropdown=[]
  HomeName=[];
  leaguename ="";
  city="";
  division="";
  loadMatches = false;
  errloadMatches = false;
  MatchList=[];
  saved = false;
  level="";

  leaguedropdown = []

  constructor(isloading = false) {
    makeAutoObservable(this);
    this.Token = localStorage.getItem("Admintoken");
    if (isloading.isloading) {
       this.getMatchList();
       this.getcity();
       this.getLeaguedropdown()
      return
    }
 
    
   
  }

  //   // status popup
  //   setstatuspopup = () => {
  //     this.statuspopup = !this.statuspopup;
  //   };
//All clear
allclear=()=>{
this.leaguename="";
this.city="";
this.division="";
this.level = "";
}
    //get city 
    getcitydrop=(text)=>{
      this.city= text;
      }
    //get division
    getdivision=(text)=>{
    this.division= text;
    }
    //get league
    getleague=(text)=>{
    this.leaguename= text;
    }

    getlevel = (text) => {
      this.level = text;
    };
    getToken=(e)=>{
      this.Token=e;
      }



  // get match List
  getMatchList = () => {
    runInAction(() => {
      this.loadMatches = true;
      this.nolist=false;
      this.errloadMatches = "";
      this.saved = false;
      this.MatchList =[];
    });
    getMethodData("/api/admin/matches", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadMatches = false;
            this.errloadMatches = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadMatches = false;
            this.errloadMatches = resp?.data?.message;
            if (resp?.data?.data?.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.MatchList = resp?.data?.data;
            // this.HomeName =  resp?.data?.data?.home_courts;
               this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.addleague = false;
          this.loadMatches = false;
          this.errloadMatches = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadMatches = false;
          if (!error.response) {
            this.errloadMatches = error?.message;
            toast.error(error?.message);
          }
          this.errloadMatches = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };
   //league dropdown
 
   getLeaguedropdown = () => {
    runInAction(() => {
      this.loadtransaction = true;
      this.errloadtransaction = "";
      // this.saved = false;
    });
    getMethodData("/api/get/leagues", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadtransaction = false;
            this.errloadtransaction = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadtransaction = false;
            this.errloadtransaction = resp?.data?.message;
            // toast.success(resp?.data?.message);
            this.leaguedropdown  = resp?.data?.data;
          });
          return;
        }
        runInAction(() => {
          this.loadtransaction = false;
          this.errloadtransaction = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadtransaction = false;
          if (!error.response) {
            this.errloadtransaction = error?.message;
            // toast.error(error?.message);
          }
          this.errloadtransaction = error?.response?.data?.message;
          // toast.error(error?.response?.data?.message);
        });
        return;
      });
  };

  //homecourt dropdown

  getUserhomecourtdropdown = () => {
    runInAction(() => {
      // this.loaduser = true;
      this.errloaduser = "";
      this.saved = false;
    });
    getMethodData(`/api/get/home-courts`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            // toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            // toast.success(resp?.data?.message);
            this.homecourtdrop = resp?.data?.data;
          });
          return;
        }
        runInAction(() => {
          this.loaduser = false;
          this.errloaduser = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loaduser = false;
          if (!error.response) {
            this.errloaduser = error?.message;
            // toast.error(error?.message);
          }
          this.errloaduser = error?.response?.data?.message;
          // toast.error(error?.response?.data?.message);
        });
        return;
      });
  };

  //city dropdown
  getcity = () => {
    runInAction(() => {
      this.loadseason = true;
      this.errloadseason = "";
      this.saved = false;
    });
    getMethodData("/api/get/cities", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadseason = false;
            this.errloadseason = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadseason = false;
            this.errloadseason = resp?.data?.message;
            // if (resp?.data?.data.length === 0) {
            //   this.nolist = true;
            // } else {
            //   this.nolist = false;
            // }
            this.cityDropdown = resp?.data?.data;
            this.saved = false;

            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadseason = false;
          this.errloadseason = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadseason = false;
          if (!error.response) {
            this.errloadseason = error?.message;
            toast.error(error?.message);
          }
          this.errloadseason = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };

  // filter match

  getFilterMatches = () => {
    runInAction(() => {
      this.loadMatches = true;
      this.nolist=false;
      this.errloadMatches = "";
      this.saved = false;
      this.MatchList =[]
    });

    getMethodData(`/api/admin/matches?filters[league]=${this.leaguename}&filters[city]=${this.city}&filters[level]=${this.level}&filters[division]=${this.division}`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadMatches = false;
            this.errloadMatches = resp?.data?.message
          });
          return;
        }
        if (resp?.data?.status === true) {

          runInAction(() => {
            this.loadMatches = false;
            this.errloadMatches = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.MatchList = resp?.data?.data;
            this.saved = false;
            this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadMatches = false;
          this.errloadMatches = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadMatches = false;
          if (!error.response) {
            this.errloadMatches = error?.message;
            // toast.error(error?.message);
          }
          this.errloadMatches = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        })
        return;
      });
  };





   

}

export const MatchStoreContext = createContext(new MatchStore());
export const useMatchStore = () => useContext(MatchStoreContext);
