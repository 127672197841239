import { observer } from "mobx-react-lite";
import React, { useState } from "react";
// import { Link } from "react-daisyui";
import { MdFlag, MdRemoveRedEye, MdSportsTennis } from "react-icons/md";
import { RxPencil1 } from "react-icons/rx";
import Filter from "./MatchFilter/Filter";
import ReactPaginate from 'react-paginate';
import {
  useMatchStore,
  MatchStore,
  MatchStoreContext,
} from "./store/MatchStore";
import Moment from "react-moment";
import moment from "moment";
import { Link } from "react-router-dom";
import { useAuthStore } from "../Auth/store/Auth";
import { useEffect } from "react";
const MatchManagement = () => {
  const store = new MatchStore({
    isloading: true,
    // playerlist: true,
  });
  return (
    <MatchStoreContext.Provider value={store}>
      <Screen />
    </MatchStoreContext.Provider>
 
  );
};
export default MatchManagement;

const Screen = observer(() => {
    const {
      MatchList,
      nolist,
      loadMatches,
      getToken
      
    } = useMatchStore();
    const { adminAuthApproved } = useAuthStore();
    useEffect(() => {
      if (adminAuthApproved) {
        getToken(localStorage.getItem("Admintoken"));
      }
    
    }, [adminAuthApproved]);

    const [currentPage, setCurrentPage] = useState(0);
    const ITEMS_PER_PAGE = 50;
    const handlePageChange = ({ selected }) => {
      setCurrentPage(selected);

      const element = document.getElementById("tablescroll");
    // if (element) {
      window.scrollTo({ top:0 ,behavior: "smooth" });
    // }
    };
    const startIndex = currentPage * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const currentMatch = MatchList?.slice(startIndex, endIndex);

  return (
    <>
    <div className="flex justify-between mt-[51px] ">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold">Match Management</p>
          {/* <p className=" opacity-80 text-sm">Home / Match</p> */}
        </div>
        {/* <div>
        <Link to="./adddivision" className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"><MdAdd/>Add Division</Link>
      </div> */}
      </div>
      <Filter />
      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <div className="overflow-x-auto w-full">
          <table id="tablescroll" className="table table-zebra w-full">
            <thead>
              <tr>
                <th>#</th>
                <th>City </th>
                <th>Division </th>
                <th>League </th>
                <th>Level </th>
                 {/* <th>Type </th> */}
                 <th>Team 1</th>
                 <th>Team 2</th>
                <th> Planned Dates </th>
                <th> Played on </th>
                <th> Home Court </th>
                <th>Action</th>
                {/* <th> Result </th> */}
              </tr>
            </thead>
            <tbody className="text-sm relative">
              {loadMatches && (
                <>
                  <tr>
                    <td colSpan={11} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={11} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={11} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={11} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={11} className="animate-pulse py-6"></td>
                  </tr>
                </>
              )}
              {(currentMatch?.length === 0 ? MatchList : currentMatch)?.map((data, index) => (
                <tr key={index} className="capitalize">
                  <td>{currentPage * ITEMS_PER_PAGE + index+1}</td>
                  <td>{data?.city?.city_name ?? "N/A"}</td>
                  <td>{data?.division?.code ?? "N/A"}</td>
                  <td>{data?.league ? data?.league.name ?? "N/A" : "N/A"}</td>
                  <td>{data?.level?.name.toFixed(1) ?? "N/A"}</td>
                  <td>{data?.teams[0]?.players?.map((inrdata,index)=> (
                    <div key={index}>
                      <p>{inrdata?.firstName +" "+ inrdata?.lastName}</p>
                    </div>
                  ))}
                  </td>
                  <td>{data?.teams[1]?.players?.map((inrdata,index)=> (
                    <div key={index}>
                      <p>{inrdata?.firstName +" "+ inrdata?.lastName}</p>
                    </div>
                  ))}
                  </td>
                  <td>
                  <div>
                    <Moment format="MM/DD/YYYY">
                      {data?.schedule_from_date??"N/A"}
                    </Moment> - <Moment format="MM/DD/YYYY">
                      {data?.schedule_to_date??"N/A"}
                    </Moment>
                  </div>
                  </td>
                  <td>
                    {data?.schedules?.length != 0  ? <div>
                      {data?.schedules?.map((ress,index) => (
                       ress?.schedule_time != null || ress?.schedule_date != null ? 
                       <span key={index} className="flex" title="Schedule Dates">
                       {ress?.schedule_date != null ?  <Moment
                         format={
                           ress?.schedule_date?.includes("5")
                             ? "MMM DD"
                             : "MMM. DD"
                         }
                       >
                         {ress?.schedule_date}
                       </Moment>: "N/A" }
                      
                       <p className="px-1"> @ </p>
                       {ress?.schedule_time != null ? 
                         moment(
                           new Date(ress?.schedule_time)
                         ).format("hh:mm A"): "N/A"  }
                     
                     </span>  : <span className="flex justify-center">--</span>
                       
                      ))}
                    </div>
                    :
                    <span className="flex justify-center">--</span>
                    }
                  </td>
                  <td>{data?.home_court?.displayName ?? "N/A"}</td>
                  <td className=" text-base">
                    <span data-tip="View Matches" className="tooltip flex items-center space-x-4">
                    <Link
                   
                      to={
                        data?.division !==  null ?
                        `/divisionmanagement/matchlist/${
                        data?.division?._id
                      }/${data?.league?._id}/${data?.division?.code}/${
                        data?.league?.name
                      }/${data?.level?._id}/${data?.level?.name}`
                      :
                      `/playoffmatches/${data?.league?._id}/${data?.level?._id}/${data?.league?.name}`
                    }
                    >
                        <MdRemoveRedEye  className="cursor-pointer" />
                      </Link>
                    </span>
                  </td>
                </tr>
              ))}
              {nolist && (
                <tr>
                  <td colSpan={11} className="my-10 text-center">
                    <MdSportsTennis className=" text-[#dedede] text-8xl mx-auto" />
                    <p className=" font-semibold text-lg">No Match Found</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {!MatchList?.length == 0 &&

<ReactPaginate
  initialPage={0}
  pageCount={Math.ceil(MatchList?.length / ITEMS_PER_PAGE)}
  pageRangeDisplayed={5}
  marginPagesDisplayed={2}
  onPageChange={handlePageChange}
  containerClassName={'pagination'}
  activeClassName={'active'}

  />
          }
        

        </div>
      </div>
    </>
  );
});
