/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Input } from "react-daisyui";
import { MdOutlineCheck, MdOutlineClose } from "react-icons/md";
import { Link, useParams, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import {
  useLeagueStore,
  LeagueStore,
  LeagueStoreContext,
} from "./store/LeagueStore";
import { useAuthStore } from "../Auth/store/Auth";
const EditLeaugeManagement = observer(() => {
  const { id } = useParams();
  return (
    <LeagueStoreContext.Provider
      value={
        new LeagueStore({
          loadeditcitybol: false,
          editloading: true,
          id: id,
        })
      }
    >
      <Screen />
    </LeagueStoreContext.Provider>
  );
});
export default EditLeaugeManagement;

const Screen = observer(() => {
  const {
    addleagueCategory,
    addleaguetyp,
    addstartdat,
    addenddat,
    addfeesamnt,
    addlastregesteration,
    startregisteration,
    startreg,
    addstat,
    editleagueCat,
    editenddates,
    editstartdates,
    editlastreg,
    editfeesamount,
    editleaguetype,
    editstatus,
    SeasonList,
    addseasontyp,
    updateLeagues,
    listofseason,
    addname,
    leaguename,
    getleaguestatus,
    leaguestatus,
    saved,
    edityear,
    edityears,
    addleague,
    getToken
  } = useLeagueStore();
  const { adminAuthApproved } = useAuthStore();

  const { id } = useParams()
  const navigate = useNavigate()
  useEffect(() => {
    if (adminAuthApproved) {
      getToken(localStorage.getItem("Admintoken"));
    }
  
  }, [adminAuthApproved]);
  useEffect(() => {
    if (saved) {
      navigate("../leaguemanagement")
    }
  }, [saved])

  const [startdatestate,setstartdatestate] = useState(null)

  return (
    <>
    <div className="flex justify-between mt-[51px] ">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold">Edit League</p>
        </div>
        <div>
          <Link
            to="../leaguemanagement"
            className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
          >
            Back
          </Link>
        </div>
      </div>
      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <form onSubmit={(e) => { updateLeagues(id); e.preventDefault() }}>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                League Name<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <Input
                name="name"
                required
                disabled
                placeholder="League Name"
                onChange={(e) => addname(e.target.value)}
                value={leaguename}
                id="name"
                type="text"
                autoComplete="off"
                className="capitalize focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            <div>
              <label
                htmlFor="Status"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                League Category<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <select
                name="Status"
                id="Status"
                disabled
                required
                onChange={(e) => addleagueCategory(e.target.value)}
                value={editleagueCat}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
            <div>
                <label htmlFor="Year" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Year<span className="text-[#f00]">&nbsp;*</span></label>
                {/* <Input 
                name="Year" 
                placeholder="year" 
                onChange={(e) => edityears(e.target.value) }
                value={edityear}
                min={2023}
                max={2099}
                id="Year"
                type="number" 
                required
                autoComplete="off" 
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                /> */}
                <select 
                value={edityear}
                required
                disabled
                name="Year"
                id="Year"
                onChange={(e) => edityears(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                    <option value="" disabled className="hidden">Select Year</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                </select>
            </div>
            <div>
              <label
                htmlFor="Leaguetype"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                League type<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <select
                name="Leaguetype"
                required
                disabled
                value={editleaguetype}
                id="Leaguetype"
                onChange={(e) => addleaguetyp(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="singles">Singles</option>
                <option value="doubles">Double</option>
                <option value="mixed">Mixed</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="seasonlist"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Season List<span className="text-[#f00]">&nbsp;*</span>
              </label>

              <select
                name="seasonlist"
                required
                disabled
                id="seasonlist"
                value={listofseason}
                onChange={(e) => addseasontyp(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                {SeasonList.map((data, index) =>  (
                    <option value={data?._id} key={index}>
                      {data.name}
                    </option>
                  ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="startdate"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Start Date<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <Input
                name="startdate"
                placeholder=""
                disabled
                onChange={(e) => {addstartdat(e.target.value);setstartdatestate(e.target.value)}}
                value={editstartdates}
                id="startdate"
                type="date"
                autoComplete="off"
                required
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>

            <div>
              <label
                htmlFor="enddate"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                End Date<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <Input
                name="enddate"
                placeholder=""
                disabled
                // disabled={editstartdates === "" ? true: false}
                onChange={(e) => addenddat(e.target.value)}
                min={new Date(editstartdates ? editstartdates : null).toISOString().split('T')[0]}
                value={editenddates}
                id="enddate"
                type="date"
                autoComplete="off"
                required
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            <div>
              <label
                htmlFor="Fees"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Fees Amount<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <Input
                name="Fees"
                required
                disabled
                placeholder="Fees Amount"
                onChange={(e) => addfeesamnt(e.target.value)}
                value={editfeesamount}
                id="Address"
                type="number"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            <div>
              <label
                htmlFor="startdate"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Start Registration Date
                <span className="text-[#f00]">&nbsp;*</span>
              </label>
              <Input
                name="startdate"
                placeholder=""
                onChange={(e) => startregisteration(e.target.value)}
                value={startreg}
                id="startdate"
                disabled
                type="date"
                autoComplete="off"
                required
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            <div>
              <label
                htmlFor="lastdate"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Last Registration Date
                <span className="text-[#f00]">&nbsp;*</span>
              </label>
              <Input
                name="lastdate"
                placeholder=""
                disabled
                onChange={(e) => addlastregesteration(e.target.value)}
                value={editlastreg}
                min={new Date(startreg ? startreg : null).toISOString().split('T')[0]}
                id="lastdate"
                type="date"
                autoComplete="off"
                required
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            {/* <div>
              <label
                htmlFor="LeagueStatus"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                League Status<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <select
                name="LeagueStatus"
                id="LeagueStatus"
                required
                onChange={(e) => getleaguestatus(e.target.value)}
                value={leaguestatus}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="entry_open">Entry Open</option>
                <option value="open"> Open</option>
                <option value="closed">Closed</option>
                <option value="completed">Completed</option>
              </select>
            </div> */}

            <div>
              <label
                htmlFor="status"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Status<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <select
                name="Status"
                required
                value={editstatus}
                onChange={(e) => addstat(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="true">Active</option>
                <option value="false">Inactive </option>
              </select>
            </div>
          </div>
          <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
            <Link
              to="../leaguemanagement"
              className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
            >
              <MdOutlineClose className="mr-1" />
              Cancel
            </Link>
            {!addleague?
             <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
             <MdOutlineCheck className="mr-1" />
             Update
           </button>
           :
           <button 
                          className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                           type="button"
                         //  onClick={()=> changeStep("4")}
                         >
                         <svg
                             className="animate-spin mr-1 h-5 w-5 text-white"
                             xmlns="http://www.w3.org/2000/svg"
                             fill="none"
                             viewBox="0 0 24 24"
                             >
                             <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                             <path
                                 className="opacity-75"
                                 fill="currentColor"
                                 d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                             ></path>
                         </svg>
                           Loading..
                         </button>
            }
           
          </div>
        </form>
      </div>
    </>
  );
});
