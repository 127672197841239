import React, { useEffect, useState } from "react";
import { Input } from "react-daisyui";
import { MdOutlineRefresh, MdOutlineSearch } from "react-icons/md";
import { Link, useParams, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import {
  useLeagueStore,
} from "../store/LeagueStore";

const Filter = (props) => {
  return (
    <>
      <Screen id={props.league} />
    </>
  );
};
export default Filter;

const Screen = observer((props) => {
  const [startdatestate, setstartdatestate] = useState(null);

  useEffect(() => {
    if (props.id) {
      getSeasonOne(props.id);
    }
  }, []);

  const {
    addleagueCategory,
    addleaguetyp,
    addstartdat,
    addenddat,
    getyear,
    addstat,
    newseasonname,
    listofseason,
    editstartdates,
    editenddates,
    leaguename,
    edityear,
    getLeagueList,
    updatedcitynameid,
    newcitynameid,
    citydrop,
    SeasonList,
    getSeasonOne,
    addseasontyp,
    getFilterLeaguecourt,
    allclear,
    addname,
    getleaguestatus,
  } = useLeagueStore();

  const [years, setYears] = useState([]);
  useEffect(() => {
    const getCurrentYear = () => {
      return new Date().getFullYear();
    };

    const generateYears = () => {
      const currentYear = getCurrentYear();
      const yearArray = [];

      for (let i = 0; i <= 5; i++) {
        const yearcurr = currentYear + i;
        yearArray.push(yearcurr);
      }

      setYears(yearArray);
    };

    generateYears();
  }, []);


  return (
    <>
      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <form
          onSubmit={(e) => {
            getFilterLeaguecourt();
            e.preventDefault();
          }}
        >
          <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                League Name
              </label>
              <Input
                name="name"
                placeholder="League Name"
                onChange={(e) => addname(e.target.value)}
                value={leaguename}
                id="name"
                type="text"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            <div>
              <label
                htmlFor="LeagueCategory"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                League Category
              </label>
              <select
                name="LeagueCategory"
                id="LeagueCategory"
                defaultValue=""
                onChange={(e) => addleagueCategory(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="">All</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="mixed">Mixed</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="Leaguetype"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                League Type
              </label>
              <select
                name="Leaguetype"
                defaultValue=""
                id="Leaguetype"
                onChange={(e) => addleaguetyp(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="">All</option>
                <option value="single">Singles</option>
                <option value="double">Doubles</option>
                <option value="mixed">Mixed</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="LeagueStatus"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                League Status
              </label>
              <select
                name="LeagueStatus"
                id="LeagueStatus"
                onChange={(e) => getleaguestatus(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="">All</option>
                <option value="entry_open">Entry Open</option>
                <option value="open"> Open</option>
                <option value="entry_close">Entry Closed</option>
                <option value="completed">Completed</option>
                <option value="upcoming">Upcoming</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="seasonlist"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Season Name
              </label>

              <select
                name="seasonlist"
                value={listofseason}
                id="seasonlist"
                onChange={(e) => addseasontyp(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="">All</option>
                {SeasonList.map((data, index) => (
                  <option value={data?._id} key={index}>
                    {data.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="City"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                City
              </label>
              <select
                defaultValue=""
                value={newcitynameid}
                id="city"
                name="city"
                onChange={(e) => updatedcitynameid(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                {/* <option value="" disabled className="hidden">Select city</option> */}
                <option value="" className="">
                  All
                </option>
                {citydrop.map((data, index) => (
                  <option value={data?._id} key={index}>
                    {data?.city_name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="Year"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Year
              </label>
              <select
                defaultValue=""
                value={edityear}
                name="Year"
                id="Year"
                onChange={(e) => getyear(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                    <option value="" className="">
                  All
                </option>
                {years.map((year, index) => (
                <option value={year} key={index}>
                {year}
                </option>
                ))}
                {/* <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option> */}
              </select>
            </div>
            <div>
              <label
                htmlFor="startdate"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Start Date
              </label>
              <input
                name="startdate"
                placeholder=""
                value={editstartdates}
                onChange={(e) => {
                  addstartdat(e.target.value);
                  setstartdatestate(e.target.value);
                }}
                id="startdate"
                //  min={new Date().toISOString().split('T')[0]}
                max="2025-01-01"
                type="date"
                autoComplete="off"
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
              />
            </div>
            <div>
              <label
                htmlFor="enddate"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                End Date
              </label>

              <input
                name="enddate"
                placeholder=""
                value={editenddates}
                id="enddate"
                disabled={startdatestate === null ? true : false}
                min={
                  new Date(startdatestate ? startdatestate : null)
                    .toISOString()
                    .split("T")[0]
                }
                onChange={(e) => addenddat(e.target.value)}
                max="2025-01-01"
                type="date"
                autoComplete="off"
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
              />
            </div>
            {/* <div>
              <label
                htmlFor="FeesAmount"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Fees Amount
              </label>
              <Input
                name="FeesAmount"
                placeholder="Fees Amount"
                id="FeesAmount"
                type="number"
                onChange={(e) => addfeesamnt(e.target.value)}
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div> */}
            {/* <div>
              <label
                htmlFor="lastdate"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Last Registration Date
              
              </label>
              <Input
                name="lastdate"
                placeholder=""
                onChange={(e) => addlastregesteration(e.target.value)}
                id="lastdate"
                type="date"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div> */}

            <div>
              <label
                htmlFor="status"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Status
              </label>
              <select
                name="Status"
                defaultValue=""
                onChange={(e) => addstat(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="">All</option>
                <option value={"true"}>Active</option>
                <option value={"false"}>Inactive</option>
              </select>
            </div>
          </div>
          <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
            <button
              type="reset"
              onClick={() => {
                allclear();
                getLeagueList();
              }}
              className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
            >
              <MdOutlineRefresh className="mr-1" />
              Reset
            </button>
            <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
              <MdOutlineSearch className="mr-1" />
              Search
            </button>
          </div>
        </form>
      </div>
    </>
  );
});
