import React, { useState } from "react";
import { MdAdd, MdOutlinedFlag } from "react-icons/md";
import { RxEyeOpen, RxPencil1 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import Filter from "./StateFilter/Filter";
import { StateStore , useStateStore , StateStoreContext } from "./StateStore/StateStore";
import ReactPaginate from 'react-paginate';
import { useAuthStore } from "../Auth/store/Auth";
import { useEffect } from "react";
const StateManagement = () => {
  const store = new StateStore({
    isloading: true,
    seasonlist: true,
    editloading: false,
  });
  return (

      <StateStoreContext.Provider value={store}>
        <Screen />
      </StateStoreContext.Provider>
  

  );
};
export default StateManagement;

const Screen = observer(() => {
  const {
State,
loadSate,
nolist,
StateLoader,
Statepopup,
setstatepopup,
getstateid,
getstatus,
statusChange,
getToken
  } = useStateStore();

  const { adminAuthApproved } = useAuthStore();
  
  useEffect(() => {
    if (adminAuthApproved) {
      getToken(localStorage.getItem("Admintoken"));
    }
  
  }, [adminAuthApproved]);

  const [currentPage, setCurrentPage] = useState(0);
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
    
    const element = document.getElementById("tablescroll");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const ITEMS_PER_PAGE = 50;

  const startIndex = currentPage * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentState = State?.slice(startIndex, endIndex);

  return (
    <>
    <div className="flex justify-between mt-[51px] ">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold">State Management</p>
          {/* <p className=" opacity-80 text-sm">Home / League</p> */}
        </div>
        <div>
          <Link to="./addstate" className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"><MdAdd/>Add State</Link>
        </div>
      </div>
<Filter/>
      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <div className="overflow-x-auto w-full">
          <table className="table table-zebra w-full">
            <thead>
              <tr>
                <th>#</th>
                <th>State Name </th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="text-sm relative">
              {loadSate && (
                <>
                  <tr>
                    <td colSpan={4} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={4} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={4} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={4} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={4} className="animate-pulse py-6"></td>
                  </tr>
                </>
              )}

           
               {
                
                (currentState.length === 0 ?  State : currentState )?.map((data, index) => (
                <tr key={index} className=" capitalize">
                  <td>{currentPage * ITEMS_PER_PAGE + index+1}</td>
                  <td>{data.name ?? "N/A"}</td>
                  <th>
                    <label
                      title="state"
                      htmlFor="statesss"
                      className={`btn capitalize ${
                        data?.isActive
                          ? "bg-color_theme hover:bg-color_theme"
                          : "bg-warning hover:bg-warning"
                      }  text-[#000] border-hidden  btn-xs`}
                      onClick={(e) => {
                        getstateid(data?._id);
                        getstatus(data?.isActive);
                        setstatepopup(true)
                      }}
                    >
                      {data?.isActive ? "Active" : "Inactive"}
                    </label>
                  </th>
                  <td className=" text-base">
                    <span className="flex items-center space-x-4">
                      <button title="Edit">
                        <Link to={`/statemanagement/${data?._id}`}>
                          <RxPencil1 />
                        </Link>
                      </button>
                    </span>
                  </td>
                </tr>
              ))}
            
              {nolist && (
                <tr>
                  <td colSpan={4} className="my-10 text-center">
                    <MdOutlinedFlag className=" text-[#dedede] text-8xl mx-auto" />
                    <p className=" font-semibold text-lg">No State Found</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {!State.length == 0 &&
          <ReactPaginate
        initialPage={0}
        pageCount={Math.ceil(State.length / ITEMS_PER_PAGE)}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        onPageChange={handlePageChange}
        containerClassName={'pagination'}
        activeClassName={'active'}
      
        />

      }         
        {Statepopup && 
        <>
          <input type="checkbox" id="statesss" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
         
                className="btn btn-sm btn-circle absolute right-2 top-2"
                onClick={()=>setstatepopup(false)}
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status ?
              </h3>
              <div className="modal-action justify-center">
                {!StateLoader ?        <label
           
                  className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                  onClick={() => statusChange()}
                >
                  Yes
                </label> :   
                <button
                            // type="submit"
                            disabled
                            className="flex items-center bg-color_theme px-3 mx-5 rounded-md"
                            // onClick={() => {
                            //   PostMatchscore();
                            // }}
                          >
                            Yes..
                            <svg
                              className="animate-spin mr-1 h-5 w-5 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          </button> }
         
                          <label
            onClick={()=>setstatepopup(false)}
              // htmlFor="voidstatus"
              className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
            >
              No
            </label>
              </div>
            </div>
          </div>
        
        </>
       
}
          {/* <input type="checkbox" id="statesss" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="statesss"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status ?
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor="statesss"
                  className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                  onClick={() => statusChange()}
                >
                  Yes
                </label>
                <label
                  htmlFor="statesss"
                  className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                >
                  No
                </label>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
});
