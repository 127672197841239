import React, { useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdOutlineClose, MdOutlineCheck, MdImageAspectRatio, MdImage, MdCancel, MdSearch, MdEdit } from "react-icons/md";
import { Input, Button, Select, Textarea } from "react-daisyui";
// import { CityStore, CityStoreContext } from "./store/CityStore";
// import { BrandStore, BrandStoreContext, useBrandStore } from "./store/BrandStore";
import { CategoriesUseStore, CategoriesStoreContext, useCategoriesStore } from "./Store/CategoriesManagement";
import { observer } from "mobx-react";
import { useAuthStore } from "../Auth/store/Auth";
import { useState } from "react";
import ImgCrop from "antd-img-crop";
import { Upload } from 'antd';
import axios from "axios";
const SEO_URL = process.env.REACT_APP_SEO_URL;

const EditCategories = observer(() => {
    const { id } = useParams();

    return (
        <CategoriesStoreContext.Provider
            value={
                new CategoriesUseStore({
                    loadeditcitybol: false,
                    categorieseditloading: true,
                    id: id,
                })
            }
        >

            <Screen />
        </CategoriesStoreContext.Provider>
    );

});
export default EditCategories;

const Screen = observer(() => {
    const { setCategoriesFormDetails, setCat_identifiername, setpublish, setaddcatname, addcategories, updateCategories, setrating_categories_id, setrating_type_detail, setparentId, isBase64, setrating_type_detail_id, publish, rating_type_detail_id, rating_type_detail, CategoriesFormDetails, Base64logoImage, Base64BannerImage, rating_categories_id, ratingList, root_categories, All_categories, setCrop, setBannerDimiensions, getlogoimage, getthumbimage, getbanner_image, crop, logoimage, thumbimage, errorMessages, addbrandname, saved, getseourl, seoUrl, addloadcity, getToken } = useCategoriesStore();
    const { adminAuthApproved } = useAuthStore();
    const [logofile, setlogofile] = useState();
    const [thumbfile, setthumbfile] = useState();
    const [bannerfile, setbannerfile] = useState();
    const [croppedImage, setCroppedImage] = useState(null);
    // const [crop, setCrop] = useState({ aspect: 16 / 9, dimensions: { width: 480, height: 270 } });
    // const { register, handleSubmit, errors } = useForm();
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const { id } = useParams();

    useEffect(() => {
        if (adminAuthApproved) {
            getToken(localStorage.getItem("Admintoken"));
        }

    }, [adminAuthApproved]);
    const navigate = useNavigate();


    useEffect(() => {
        if (saved) {
            navigate("../categories");
        }
    }, [saved]);



    const handleSubmit = (e) => {
        e.preventDefault();
        updateCategories(id);
        // Perform API call or any other logic using MobX store data
        // Example: addCity();
    };









    const getLogoSrcFromFile = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
        });
    };

    const getBase64 = file => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
        });
    };





    const LogoPreview = async (file) => {
        const src = file.url || (await getLogoSrcFromFile(file));
        if (src) {
            const imgWindow = window.open(src);
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }
            if (imgWindow) {
                const image = new Image();
                image.src = src;
                imgWindow.document.write(image.outerHTML);
            } else {
                window.location.href = src;
            }
        };
    }


    const ThumbPreview = async (file) => {
        const src = file.url || (await getLogoSrcFromFile(file));
        if (src) {
            const imgWindow = window.open(src);
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }
            if (imgWindow) {
                const image = new Image();
                image.src = src;
                imgWindow.document.write(image.outerHTML);
            } else {
                window.location.href = src;
            }
        };
    }




    const getBannerSrcFromFile = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
        });
    };

    const LogChange = async ({ fileList: newFileList }) => {
        // Allow only the first file in the list
        const file = newFileList[0];
        // Get the base64 representation of the file
        if (file) {
            const base64Image = await getBase64(file.originFileObj);
            const files = base64Image;
            setCategoriesFormDetails({
                ...CategoriesFormDetails,
                image: files,
            })
            getlogoimage(files);

        }

        // Update the logofile state with the newFileList
        setlogofile(newFileList.slice(0, 1));

    };
    const ThumbChange = async ({ fileList: newFileList }) => {
        // Allow only the first file in the list
        const file = newFileList[0];
        // Get the base64 representation of the file
        if (file) {
            const base64Image = await getBase64(file.originFileObj);
            const files = base64Image;
            getthumbimage(files);

        }

        // Update the logofile state with the newFileList
        setthumbfile(newFileList.slice(0, 1));

    };


    const BannerChange = async ({ fileList: newFileList }) => {
        // Allow only the first file in the list
        const file = newFileList[0];
        if (file) {
            const base64Image = await getBase64(file.originFileObj);
            const files = base64Image;
            setCategoriesFormDetails({
                ...CategoriesFormDetails,
                banner_image: files,
            })
            getbanner_image(files);
        }
        setbannerfile(newFileList.slice(0, 1));
    };

    const BannerPreview = async (file) => {
        const src = file.url || (await getBannerSrcFromFile(file));
        const imgWindow = window.open(src);

        if (imgWindow) {
            const image = new Image();
            image.src = src;
            imgWindow.document.write(image.outerHTML);
        } else {
            window.location.href = src;
        }
    };
    const [selectedOption, setSelectedOption] = useState("Desktop"); // Default value for Desktop

    const getbannerstatus = (value) => {
        setSelectedOption(value);
    };

    const getAspectByOption = () => {
        switch (selectedOption) {
            case 'Desktop': // Desktop
                return 16 / 9; // Example aspect ratio for Desktop
            case 'Ipad': // iPad or Mobile
                return 4 / 3; // Example aspect ratio for iPad or Mobile
            case 'Mobile': // iPad or Mobile
                return 9 / 16; // Example aspect ratio for iPad or Mobile
        }
    };
    const handleAspectRatioChange = (aspect) => {
        let dimensions;

        switch (aspect) {
            case 16 / 9:
                dimensions = { width: 480, height: 270 };
                break;
            case 9 / 1:
                dimensions = { width: 500, height: 500 };
                break;
            // Add more cases for other aspect ratios
            default:
                dimensions = { width: 200, height: 200 };
        }

        setCrop({ aspect, dimensions });
    };

    const getDimensionsByOption = () => {
        let dimensions
        switch (selectedOption) {
            case 'Desktop':
                dimensions = { width: 2000, height: 500 };
            case 'Ipad':
                dimensions = { width: 1024, height: 576 };
            case 'Mobile':
                dimensions = { width: 640, height: 360 };
            default:
                dimensions = { width: 200, height: 200 };
        }
        setBannerDimiensions({ dimensions })
    };




    const handleCrop = croppedImageUrl => {
        setCroppedImage(croppedImageUrl);
    };

    const [searchQuery, setSearchQuery] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [showList, setShowList] = useState(false);
    const [isListVisible, setListVisibility] = useState(false);
    const [value, setValue] = useState("Select option...");
    const [categoryvalue, setcategoryvalue] = useState("Root Category");
    const [Showevalue, setShowvalue] = useState("");



    const handleFocus = () => {
        // Set the list visibility to true when the input is focused
        setListVisibility(true);
        setShowList(true);

        // Initial filtering to show the entire list
        setFilteredItems(ratingList.map(item => item));
    };

    const handleBlur = () => {
        // Set the list visibility to false when the input loses focus
        setListVisibility(false);
        setShowList(true);

        setFilteredItems(ratingList.map(item => item));

    };

    const handleSearch = (query) => {
        const filtered = ratingList.map(item => item.rating_name).filter((item) =>
            item.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredItems(filtered);
        setSearchQuery(query);
        setShowList(true);
    };


    const handleSelect = (item, id) => {
        console.log(item);
        // Assuming item is an object and you want to add the whole object to selectedOptions
        setrating_type_detail([...rating_type_detail, item]);
        setrating_type_detail_id([...rating_type_detail, id]);

        setrating_categories_id([...rating_categories_id, id])
        setSearchQuery('');
        setFilteredItems([]);
        setShowList(false);
    };
    const handleRemove = (index) => {
        const updatedOptions = [...selectedOptions];
        const updatedRatingOptions = [...rating_categories_id];
        const updatedRating = [...rating_type_detail];



        updatedOptions.splice(index, 1);
        updatedRating.splice(index, 1);

        updatedRatingOptions.splice(index, 1);
        setSelectedOptions(updatedOptions);
        setrating_categories_id(updatedRatingOptions)
        setrating_type_detail(updatedRating)
        setrating_type_detail_id(updatedRating);



    };


    const SearchableDropdown = observer(() => {
        const [query, setQuery] = useState("");
        const [isOpen, setIsOpen] = useState(false);
        const [HideShow, setHideShow] = useState(false);
        const inputRef = useRef(null);

        useEffect(() => {
            document.addEventListener("click", toggle);
            return () => document.removeEventListener("click", toggle);
        }, []);

        useEffect(() => {
            // Set default value when the component mounts
            const defaultOption = All_categories.find(option => option.id === CategoriesFormDetails?.parent_id);
            console.log("CategoriesFormDetails?.parent_id", CategoriesFormDetails?.parent_id);
            if (defaultOption) {
                setcategoryvalue(`${">".repeat(defaultOption.level)} ${defaultOption.name}`);
                setparentId(defaultOption.id);
            }
        }, []);

        // const selectOption = (option) => {

        //     setQuery(() => "");
        //     setValue(option['name']);
        //     setcategoryvalue(`${">".repeat(option.level)} ${option["name"]}`
        //     );
        //     setparentId(option?.id)
        //     setIsOpen((isOpen) => !isOpen);
        // };

        const selectOption = (option) => {
            { console.log("categoryvalue", option.id) }
            setQuery(""); // Clear the query
            setValue(option.name); // Set the selected option's name
            // setcategoryvalue(`${">".repeat(option.level)} ${option["name"]}`); // Update categoryvalue with the selected option's formatted name
            setShowvalue(`${">".repeat(option.level)} ${option["name"] == undefined ? option : option["name"]}`); // Update categoryvalue with the selected option's formatted name
            setCategoriesFormDetails({
                ...CategoriesFormDetails,
                parent_id: option.id == undefined ? 'null' : option.id,
            });
            setparentId(option.id); // Update parentId with the selected option's id
            setIsOpen((isOpen) => !isOpen);
        };

        function toggle(e) {
            setIsOpen(e && e.target === inputRef.current);
        }


        const getDisplayValue = () => {
            if (query) return query;
            if (value) return value;

            return "";
        };

        const filter = (options) => {
            return options.filter(
                (option) => option['name'].toLowerCase().indexOf(query.toLowerCase()) > -1
            );
        };

        console.log(BASE_URL + `/uploads/productCategory/${CategoriesFormDetails.image}`);

        return (
            <div className="dropdowncategory">
                <div className="control">
                    <div className="selected-value">
                        <div
                            onClick={() => setHideShow(!HideShow)}

                            className=" p-3 border-[1px] border-[#e5e7eb] focus:outline-0 w-full text-sm font-medium rounded-md">
                            {Showevalue || categoryvalue}
                        </div>

                        {HideShow &&
                            <div class="relative">
                                <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                    <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                    </svg>
                                </div>
                                <input
                                    ref={inputRef}
                                    class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    type="text"
                                    placeholder="Search..."
                                    // value={getDisplayValue()}
                                    name="searchTerm"
                                    onChange={(e) => {
                                        setQuery(e.target.value);
                                        // setValue(null);
                                    }}
                                    onClick={toggle}
                                />
                            </div>


                        }


                    </div>

                    <div className={`arrow ${isOpen ? "open" : ""}`}></div>
                </div>

                <div className={`options ${isOpen ? "open" : ""}`}>
                    {publish == "0" &&

                        <div
                            onClick={() => selectOption("Root Categories")}
                            className={`option selected`}

                        >
                            {`${">"} Root Categories`}

                        </div>
                    }


                    {filter(All_categories).map((option, index) => (
                        <div
                            onClick={() => selectOption(option)}
                            className={`option ${option.name === value ? "selected" : ""}`}
                            key={`${"id"}-${index}`}
                        >
                            {`${">".repeat(option.level)} ${option.name}`}
                        </div>
                    ))}
                </div>
            </div>
        );
    });
    console.log(BASE_URL + `/uploads/productCategory/${Base64BannerImage}`);

    return (
        <>
          <div className="flex justify-between mt-[51px] ">
                <div className=" mb-4">
                    <p className=" text-2xl font-semibold">Edit Categories</p>
                    <p className=" opacity-80 text-sm">Home / Categories/ Edit Categories</p>
                </div>
                <div>
                    <Link
                        to="../categories"
                        className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                    >
                        Back
                    </Link>
                </div>
            </div>
            <div className="card bg-base-100 shadow-md p-6 mt-8">
                <form onSubmit={handleSubmit}>
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
                        {/* <div>
                            <label
                                htmlFor="Categoryidentifier"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                Category identifier<span className="text-[#f00]">&nbsp;*</span>
                            </label>
                            <Input
                                name="Categoryidentifier"
                                required
                                value={CategoriesFormDetails.identifier}
                                onChange={(e) => {
                                    setCategoriesFormDetails({
                                        ...CategoriesFormDetails,
                                        identifier: e.target.value,
                                    });
                                }}
                                // placeholder="Category identifier"
                                id="Categoryidentifier"
                                type="text"
                                autoComplete="off"
                                className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                            />

                        </div> */}
                        <div>
                            <label
                                htmlFor="Categoryidentifier"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                Category name<span className="text-[#f00]">&nbsp;*</span>
                            </label>
                            <Input
                                name="Categoryname"
                                required
                                value={CategoriesFormDetails.category_name}
                                onChange={(e) => {
                                    setCategoriesFormDetails({
                                        ...CategoriesFormDetails,
                                        category_name: e.target.value,
                                    });
                                    getseourl(e.target.value);
                                }}
                                // placeholder="Category identifier"
                                id="Categoryname"
                                type="text"
                                autoComplete="off"
                                className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                            />

                        </div>
                        <div>
                            <label
                                htmlFor="seo"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                Brand SEO-friendly URL<span className="text-[#f00]">&nbsp;*</span>
                            </label>
                            <Input
                                name="seo"
                                required
                                value={CategoriesFormDetails.seo_url}
                                onChange={(e) => {
                                    setCategoriesFormDetails({
                                        ...CategoriesFormDetails,
                                        seo_url: e.target.value,
                                    });
                                    getseourl(e.target.value);
                                }}
                                // placeholder="SEO-friendly URL"
                                id="seo"
                                type="text"
                                autoComplete="off"
                                className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                            <span className="text-color_theme font-light ml-1 text-xs">{`${SEO_URL}${seoUrl}`}</span>
                        </div>
                        {/* <div className="">
                            <label
                                htmlFor="seo"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                Rating types
                            </label>

                            <div className="relative">
                                <input
                                    type="text"
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    value={searchQuery}
                                    onChange={(e) => handleSearch(e.target.value)}
                                    className="p-3 pl-8 rounded  w-full border border-gray-200  focus:bg-white focus:outline-none "
                                    placeholder="Type to search..."
                                />
                                <svg
                                    className="w-4 h-4 absolute left-2.5 top-3.5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                    />
                                </svg>
                            </div>
                            <div className="flex flex-wrap mt-1 gap-2">
                             
                                {rating_type_detail.map((option, index) => (
                                    <div
                                        key={index}
                                        className="bg-blue-100 p-1 rounded flex items-center"
                                    >
                                        {option?.rating_name || option}
                                        <button
                                            type="button"
                                            className="ml-2 text-red-500 hover:text-red-700"
                                            onClick={() => handleRemove(index)}
                                        >
                                            <MdCancel />
                                        </button>
                                    </div>
                                ))
                                }


                            </div>
                            {showList && (
                                <ul className="bg-white border border-gray-100 w-full mt-2">
                                    {filteredItems.map((item, index) => (
                                        <li
                                            key={index}
                                            onClick={() => handleSelect(item.rating_name, item.id)}
                                            className="pl-8 pr-2 py-1 border-b-2 border-gray-100 relative cursor-pointer hover:bg-yellow-50 hover:text-gray-900"
                                        >
                                            {item.rating_name}
                                        </li>
                                    ))}
                                </ul>
                            )}


                        </div> */}
                        <div>
                            <label
                                htmlFor="Categoryidentifier"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                Parent category<span className="text-[#f00]">&nbsp;*</span>
                            </label>
                            <SearchableDropdown />

                        </div>
                        <div>
                            <label htmlFor="Fees" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Description<span className="text-[#f00]">&nbsp;*</span></label>
                            <Textarea
                                name="Description"
                                required
                                placeholder="Description....."
                                value={CategoriesFormDetails.description}
                                onChange={(e) => {
                                    setCategoriesFormDetails({
                                        ...CategoriesFormDetails,
                                        description: e.target.value,
                                    });
                                }}
                                id="Description"
                                type="text"
                                rows={1}
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>

                        <div className="mt-7">
                            <label class="relative bg-[#f1f1f1]  justify-between text-[#837575] w-full border border-[#7E7777] rounded-lg px-2 py-3 focus:outline-none inline-flex items-center mb-5 cursor-pointer">

                                <span class=" text-sm  font-medium text-gray-900 dark:text-gray-300">Publish</span>
                                <div className="relative">
                                    <input
                                        checked={CategoriesFormDetails.is_product == "1"}
                                        disabled={CategoriesFormDetails?.parent_id == "" || publish == "1"}
                                        onChange={(e) => {
                                            setCategoriesFormDetails({
                                                ...CategoriesFormDetails,
                                                is_product: e.target.checked ? "1" : "0",
                                            });
                                        }}
                                        type="checkbox" className="sr-only peer" />
                                    <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>

                                </div>
                            </label>


                        </div>


                    </div>

                    <div className="border-t-2  border-dashed mt-9 ml-3 ">
                        <label htmlFor="image" className="block text-md font-semibold text-zinc-700 mt-3 dark:text-stone-400 mb-2">
                            Icon
                        </label>

                        {/* {imageurl && <img className="w-[50%]" src={imageurl} alt="Selected" />} */}
                        <ImgCrop
                            showGrid
                            rotationSlider
                            aspectSlider
                            showReset
                            onCrop={handleCrop}
                        >
                            <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture-circle"
                                fileList={logofile}
                                onChange={LogChange}
                                onPreview={LogoPreview}
                                className="logo"
                            >

                                {(logofile == undefined && logofile?.length === 0) && !CategoriesFormDetails.image ? (
                                    <span className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ">Upload Logo</span>
                                )
                                    :

                                    (logofile == undefined || logofile?.length === 0) && (

                                        (CategoriesFormDetails.image == null || CategoriesFormDetails.image == "") ?
                                            <span className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ">Upload Logo</span>
                                            :
                                            <div className="relative group">
                                                {isBase64(CategoriesFormDetails.image) ?
                                                    <img
                                                        crossOrigin="anonymous"
                                                        src={BASE_URL + `/uploads/productCategory/${Base64logoImage}`}
                                                        alt="Default Logo"
                                                        className="w-full h-auto rounded-[50%]"
                                                    />
                                                    :
                                                    <img
                                                        crossOrigin="anonymous"
                                                        src={BASE_URL + `/uploads/productCategory/${CategoriesFormDetails.image}`}
                                                        alt="Default Logo"
                                                        className="w-full h-auto rounded-[50%]"
                                                    />

                                                }

                                                <div className="absolute inset-0 bg-color_theme bg-opacity-50 rounded-[50%] flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                                                    <MdEdit className="text-white text-lg" />
                                                </div>
                                            </div>
                                    )

                                }

                            </Upload>
                            {/* Conditionally render the default image */}

                        </ImgCrop>

                        <span className="text-[11px]">This Will Be Displayed In 100*100 On Your Store</span>




                    </div>


                    {/* <div className="border-t-2  border-dashed mt-9 ml-3 ">
                        <label htmlFor="image" className="block text-md font-semibold text-zinc-700 mt-3 dark:text-stone-400 mb-2">
                            Thumb
                        </label>

                        <ImgCrop
                            showGrid
                            rotationSlider
                            aspectSlider
                            showReset
                            onCrop={handleCrop}
                        >
                            <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture-circle"
                                accept="image/*"
                                fileList={thumbfile}
                                onChange={ThumbChange}
                                onPreview={ThumbPreview}
                            >

                                {(thumbfile == undefined && thumbfile?.length === 0) && !CategoriesFormDetails.thumb_image ? (
                                    <span className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ">Upload Logo</span>
                                )
                                    :

                                    (thumbfile == undefined || thumbfile?.length === 0) && (

                                        (CategoriesFormDetails.image == null || CategoriesFormDetails.thumb_image == "") ?
                                            <span className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ">Upload Logo</span>
                                            :
                                            <div className="relative group">
                                                {isBase64(CategoriesFormDetails.thumb_image) ?
                                                    <img
                                                        crossOrigin="anonymous"
                                                        src={BASE_URL + `/uploads/productCategory/${Base64logoImage}`}
                                                        alt="Default Logo"
                                                        className="w-full h-auto rounded-[50%]"
                                                    />
                                                    :
                                                    <img
                                                        crossOrigin="anonymous"
                                                        src={BASE_URL + `/uploads/productCategory/${CategoriesFormDetails.thumb_image}`}
                                                        alt="Default Logo"
                                                        className="w-full h-auto rounded-[50%]"
                                                    />

                                                }

                                                <div className="absolute inset-0 bg-color_theme bg-opacity-50 rounded-[50%] flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                                                    <MdEdit className="text-white text-lg" />
                                                </div>
                                            </div>
                                    )

                                }

                            </Upload>

                        </ImgCrop>

                        <span className="text-[11px]">This Will Be Displayed In 100*100 On Your Store</span>




                    </div>


                    <div className="border-t-2 grid md:grid-cols-2 md:gap-8 gap-4 mt-3 border-dashed  ">


                        <div>
                            <label htmlFor="image" className="block text-xl font-semibold mt-3 text-zinc-700 dark:text-stone-400 mb-2">
                                Banner<span className="text-[#f00]">*</span>
                            </label>
                            <div className="mb-5">
                                <label htmlFor="image" className="block text-sm font-semibold mt-3  text-zinc-700 dark:text-stone-400 mb-4">
                                    Display for
                                </label>
                                <select
                                    id="Status"
                                    // value={selectedOption}
                                    onChange={(e) => getbannerstatus(e.target.value)}
                                    name="Status"
                                    className={`select  focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                                >
                                    <option value="Desktop">Desktop</option>
                                    <option value="Ipad">Ipad</option>
                                    <option value="Mobile">Mobile</option>
                                </select>
                            </div>
                            <div className="ml-3">
                                <ImgCrop
                                    aspect={getAspectByOption()} // Set aspect ratio dynamically
                                    resize={getDimensionsByOption()}
                                    showGrid
                                    rotationSlider
                                    // aspectSlider
                                    showReset
                                >
                                    <Upload
                                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                        listType="picture"
                                        fileList={bannerfile}
                                        onChange={BannerChange}
                                        onPreview={BannerPreview}
                                        className="banner"
                                    >


                                        {(bannerfile == undefined && bannerfile?.length === 0) && !CategoriesFormDetails.banner_image ? (
                                            <span className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ">Upload Banner</span>
                                        )
                                            :

                                            (bannerfile == undefined || bannerfile?.length === 0) && (
                                                // console.log("checking")
                                                (CategoriesFormDetails.banner_image == null || CategoriesFormDetails.banner_image == "") ?
                                                    <span className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ">Upload Banner</span>
                                                    :

                                                    isBase64(CategoriesFormDetails.banner_image) ?
                                                        Base64BannerImage == null ?
                                                            <span className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ">Upload Banner</span>
                                                            :
                                                            <div className="relative group">

                                                                <img
                                                                    crossOrigin="anonymous"
                                                                    src={BASE_URL + `/uploads/productCategory/${Base64BannerImage}`}
                                                                    alt="Default Logo"
                                                                    className="w-full h-auto "
                                                                />

                                                                <div className="absolute inset-0 bg-color_theme bg-opacity-50  flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                                                                    <MdEdit className="text-white text-2xl" />
                                                                </div>
                                                            </div>

                                                        :
                                                        <div className="relative group">

                                                            <img
                                                                crossOrigin="anonymous"
                                                                src={BASE_URL + `/uploads/productCategory/${CategoriesFormDetails.banner_image}`}
                                                                alt="Default Logo"
                                                                className="w-full h-auto "
                                                            />

                                                            <div className="absolute inset-0 bg-color_theme bg-opacity-50  flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                                                                <MdEdit className="text-white text-2xl" />
                                                            </div>
                                                        </div>



                                            )

                                        }



                                    </Upload>
                                </ImgCrop>
                            </div>

                        </div>









                    </div> */}
                    <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                        <Link
                            to="../categories"
                            className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                        >
                            <MdOutlineClose className="mr-1" />
                            Cancel
                        </Link>
                        {!addloadcity ?
                            <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
                                <MdOutlineCheck className="mr-1" />
                                Add Categories
                            </button>
                            :
                            <button
                                className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                type="button"
                            //  onClick={()=> changeStep("4")}
                            >
                                <svg
                                    className="animate-spin mr-1 h-5 w-5 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                                Loading..
                            </button>
                        }

                    </div>
                </form>
            </div>
        </>
    );
});

