import { createContext, useContext } from "react";
import { action, makeAutoObservable, makeObservable, observable, runInAction } from "mobx";
import { postMethodData, getMethodData, putMethod, delMethod, patchMethodData } from "../../ApiMethods/api";
import { toast } from "react-toastify";
const SEO_URL = process.env.REACT_APP_SEO_URL;





export class LocationStore {
    Token = null;
    country_code = "";
    addloadhomecourt= false;
    countries=[];
    BulkHomeCourt= false;
    state_name="";
    state=[];
    EditloadLocation= false;
    country_name = "";
    state_code = "";
    state_name = "";
    city = "";
    pincode = "";
    Location = [];
    unsuccessdata = [];
    StatusLoader = false;
    type = "";
    Locationtype = [];
    filterSearch = "";
    PaginationPage = "1";
    TotalPages = "";
    LocationDimiensions = ""
    crop = {};
    errLoadLocation = "";
    LoadLocation = false;
    delateLoader = false;
    nolist = false;
    Locationpopup = false;
    deletepopup = false;
    Locationname = "";
    status = "1";
    ImportLoader= false
    statesid = "";
    Locationid = "";
    seoUrl = "";
    statuspopup = false;
    addLocationname = "";
    imageurl = "";
    logoimage = "";
    setCsvFile= "";
    Locationimage = "";
    Base64logoImage = "";
    Base64LocationImage = "";
    logofile = [];
    allLocation = [];
    alldeactivate = 'false';
    isLocationUpdated = "false";
    isLogoUpdated = "false"
    checkeddd = '';
    bulk= false;
    bulkcheck= false;
    formData = new FormData();
    failed="";
    success="";
    country_obj ={
        "countryId": "",
        "countryName": ""

    }
   state_obj ={
        "stateId": "",
        "stateName": ""
    }
    LocationFormDetails = {
        "country_code": "",
        "country_name": "",
        "state_code": "",
        "state_name": "",
        "city": "",
        "pincode": ""
    };
    saved = false;

    constructor(isloading = false) {
        makeAutoObservable(this, {
            addLocationname: observable,
            status: observable,
            errorMessages: observable,
            setaddLocationname: action,
            getstatus: action,
            resetErrors: action,
        });

        this.Token = localStorage.getItem("Admintoken");
        if (isloading.isloading) {
            this.getLocationList();
            this.getcountriesList();
            //   this.getStateList();
            return
        }
        // if (isloading.addloading) {
        //     this.getLocationTypeList();
        // }
        if (isloading.getLocationDetails) {
            this.getLocationform(isloading.id);
            this.getcountriesList();


            // this.getLocationTypeList();

            return
        }
    }
    //All Clear 
    allclear = () => {
        this.SetfilterSearch("");
        this.getFilterLocation();
        //   this.status="";
        //   this.statesid="";
    }
    setbulkpopup = () => {
    
        this.formData= new FormData();
        this.bulkcheck = true
        this.errPassword = ""
        this.setCsvFile = "";
        // this.currentpassword = ""
        // this.newpassword = ""
        // this.confirmnewpassword = ""
    }

    setbulkpopupclose = () => {
        this.unsuccessdata = []
        this.formData= new FormData();
        this.bulkcheck = false
        this.errPassword = ""
        this.setCsvFile = "";
        // this.currentpassword = ""
        // this.newpassword = ""
        // this.confirmnewpassword = ""
      }

    setimage = (text, type) => {
        this.imageurl = URL.createObjectURL(text[0]);
    }

    setalldeactivate = (text) => {
        console.log(text);
        this.alldeactivate = !this.alldeactivate;
    };

    getid = (text) => {
        this.Locationid = text;
    };

    handleChange = (e) => {

        this.setCsvFile = e.currentTarget.files[0]
      };

    // status popup
    setstatuspopup = () => {
        this.statuspopup = !this.statuspopup;
    };

    setBulkhomecourt = (e) => {
        this.BulkHomeCourt= e;
      };

    setallLocation = (text) => {
        this.allLocation = text;
    }

    // get status 
    getstatus = (text) => {
        this.status = text;
    };

    //get city name
    getLocationname = (text) => {
        this.Locationname = text;
    };

    setaddLocationname = (text) => {
        this.addLocationname = text;
    };
    setCrop = (text) => {
        this.crop = text;
    };

    setLocationDimiensions = (text) => {
        this.LocationDimiensions = text;
    };

    setlogofile = (e) => {
        this.logofile = e;
        console.log(this.logofile);
    };

    setLocationpopup = (e) => {
        this.Locationpopup = e;
    };
    setdeletepopup = (e) => {
        this.deletepopup = e;
    };
    setdelateLoader = (e) => {
        this.delateLoader = e;
    }
    getToken = (e) => {
        this.Token = e;
    }
    gettype = (e) => {
        this.type = e;
    }
    getseourl = (text) => {
        // console.log(`https://demo.e-kart.com/${text}`);
        this.seoUrl = text;
    };
    getlogoimage = (text) => {
        console.log("checking in the Location", text);
        this.logoimage = text;
    };
    getLocation_image = (text) => {
        console.log(text);
        this.Locationimage = text;
    };
    SetfilterSearch = (text) => {
        console.log("text update",text);
        this.filterSearch = text;
    };
    setPaginationPage = (text) => {
        this.PaginationPage = text;
    };
    SetTotalPages = (text) => {
        this.TotalPages = text;
    };
    setchecked = (text) => {
        this.checkeddd = text;
    };
    setisLocationUpdated = (text) => {
        this.isLocationUpdated = text;
    };
    setisLogoUpdated = (text) => {
        this.isLogoUpdated = text;
    };
    setcountry_code = (text) => {
        this.country_code = text;
    };
    setcountry_name = (text) => {
        this.country_name = text;
    };
    setstate_code = (text) => {
        this.state_code = text;
    };
    setstate_name = (text) => {
        this.state_name = text;
        console.log("zxdfgnjkzjksdjkfjkjsdfn",text);
    };
    setcity = (text) => {
        this.city = text;
    };
    setpincode = (text) => {
        this.pincode = text;
    };



    isBase64 = (str) => {
        try {
            return btoa(atob(str)) === str;
        } catch (err) {
            return false;
        }
    };

    setLocationFormDetails = (LocationDetail) => {
        this.LocationFormDetails = {
            ...this.LocationFormDetails,
            country_code: LocationDetail.country_code || "", // Use default value if name is undefined
            country_name: LocationDetail.country_name || "", // Use default value if seo_url is undefined
            // logo_image: this.isBase64( this.LocationFormDetails.logo_image) ? this.logofile.length == 0 ? LocationDetail.logo_image    : this.LocationFormDetails.logo_image  : this.logofile.length !== 0 ? this.LocationFormDetails.logo_image :  LocationDetail.logo_image   || "",
            // Location_image: this.isBase64(LocationDetail.Location_image) ? this.LocationFormDetails.Location_image : LocationDetail.Location_image || "",
            state_code: LocationDetail.state_code || "",
            state_name: LocationDetail.state_name || "",
            section_name: LocationDetail.section_name || "",
            city: LocationDetail.city,
            pincode: LocationDetail.pincode

        };
    };
    // setLocationFormDetails = (text) => {
    //     this.LocationFormDetails = text;
    //     console.log("setLocationFormDetails", this.LocationFormDetails.Locationname);
    // };
    base64toFile(base64Data, fileName, mimeType) {
        if (base64Data) {
            const byteCharacters = atob(base64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters?.length; offset += 512) {
                const slice = byteCharacters?.slice(offset, offset + 512);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const file = new File(byteArrays, fileName, { type: mimeType });

            return file;
        }

    }

    // get Location list
    getLocationList = () => {
        runInAction(() => {
            this.LocationLoader = true;
            this.errLoadLocation = "";
            this.saved = false;
            this.nolist = false;
            this.Location = [];
        });
        getMethodData(`/api/v1/admin/location/get-list`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.LocationLoader = false;
                        this.errLoadLocation = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.LocationLoader = false;
                        this.errLoadLocation = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.Location = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadLocation = false;
                    this.errLoadLocation = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.LocationLoader = false;
                    if (!error.response) {
                        this.errLoadLocation = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadLocation = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    // get Location type list
    getLocationTypeList = () => {
        runInAction(() => {
            this.LocationLoader = true;
            this.errLoadLocation = "";
            this.saved = false;
            this.nolist = false;
            this.Locationtype = [];
        });
        getMethodData(`/api/v1/admin/sections/auto-complete`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.LocationLoader = false;
                        this.errLoadLocation = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.LocationLoader = false;
                        this.errLoadLocation = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.Locationtype = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadLocation = false;
                    this.errLoadLocation = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.LocationLoader = false;
                    if (!error.response) {
                        this.errLoadLocation = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadLocation = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };
    //Filter city
    // filter League court

    getFilterLocation = () => {
        runInAction(() => {
            this.LoadLocation = true;
            this.errLoadLocation = "";
            this.saved = false;
            this.nolist = false;
            this.getLocationList = [];
        });
        postMethodData(`/api/v1/admin/location/search?page=${this.PaginationPage}`, {
            page: this.PaginationPage,
            keyword: this.filterSearch
        }, this.Token)
            .then((resp) => {

                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.loadleague = false;
                        this.errloadleague = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {

                    runInAction(() => {
                        this.loadcity = false;
                        this.errloadleague = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.Location = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.loadcity = false;
                    this.errloadcuty = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.loadleague = false;
                    if (!error.response) {
                        this.errloadleague = error?.message;
                        toast.error(error?.message);
                    }
                    this.errloadleague = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    // add new Location
    addLocation = () => {
        runInAction(() => {
            this.addloadLocation = true;
            this.errLoadLocation = "";
        });
        const formData = new FormData();

        if (this.Locationimage) {
            const Locationbase64String = this.Locationimage.split(',')[1];
            const LocationFile = this.base64toFile(Locationbase64String, 'logo.png', 'image/png');
            formData.append('Location_image', LocationFile);
        }
        else {
            formData.append('Location_image', null);
        }

        formData.append('country_code',  this.country_name.countryid);
        formData.append('country_name', this.country_name.countryname);
        formData.append('state_code', this.state_name.stateid);
        formData.append('state_name', this.state_name.statename);
        formData.append('city', this.city);
        formData.append('pincode', this.pincode);






        postMethodData("/api/v1/admin/location/add", formData, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.addloadLocation = false;
                    this.errLoadLocation = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.addloadLocation = false;
                    this.errLoadLocation = resp?.data?.message;
                    // toast.success(resp.data.message);
                    this.getLocationList();
                    this.saved = true;
                })
                return
            }
            runInAction(() => {
                this.addloadLocation = false;
                this.errLoadLocation = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadLocation = false;
                    if (!error.response) {
                        this.errLoadLocation = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadLocation = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };
    bulkupload = () => {
        this.formData.append('csvFile', this.setCsvFile);
        runInAction(() => {
          this.addloadhomecourt = true;
          this.BulkHomeCourt=true;
          this.ImportLoader= true;
          this.erraddhomecourt = "";
        });
        
        postMethodData("/api/v1/admin/location/csv-upload",
          this.formData
          ,this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.formData= new FormData();
                    this.addloadhomecourt = false;
                    this.ImportLoader= false;
                    this.erraddhomecourt = resp?.data?.message;
                    // this.unsuccessdata=resp.data.data.unSuccessData;
                    // if (this.unsuccessdata.length == 0
                    //   ) {
                    // this.BulkHomeCourt=false; 
                    // }
                    // this.success =resp.data.data.success;
                    // this.failed=resp.data.data.failed;
                    toast.error("Invalid entry");
                    // this.getHomecourt();
                    // this.getLocationList();
                    this.setbulkpopup();
              
                  })
            }
       
       
            if (resp?.data?.status === true) {
              runInAction(() => {
                this.formData= new FormData();
                this.addloadhomecourt = false;
                this.ImportLoader= false;
                this.erraddhomecourt = resp?.data?.message;
                // this.unsuccessdata=resp.data.data.unSuccessData;
                // if (this.unsuccessdata.length == 0
                //   ) {
                // this.BulkHomeCourt=false; 
                // }
                // this.success =resp.data.data.success;
                // this.failed=resp.data.data.failed;
                toast.success(resp.data.message);
                // this.getHomecourt();
                this.saved = true;
                this.BulkHomeCourt=false; 
                // this.getLocationList();
                this.setbulkpopup();
          
              })
              return
            }
            runInAction(() => {
              this.addloadhomecourt = false;
              this.erraddhomecourt = resp?.data?.message;
            });
            
          })
          .catch((error) => {
            runInAction(() => {
                this.addloadhomecourt = false;
                this.ImportLoader= false;
                this.BulkHomeCourt=true;
                
                if(!error.response){
                    this.erraddhomecourt = error?.message;
                    toast.error(error?.message);
                }
                this.erraddhomecourt = error?.response?.data?.message;
                toast.error(error?.response?.data?.message);
            })
            return;
        });
      };
    //Active and inactive
    //Active and inactive
    statusChange = () => {
        runInAction(() => {
            this.StatusLoader = true;
            this.Sectionpopup = true;

            this.errLoadSection = "";
        });
        postMethodData(`/api/v1/admin/location/update-status`, {

            "record_id": this.Locationid,
            "status": JSON.stringify(this.status)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.Sectionid = [];
                        this.StatusLoader = false;
                        this.Locationpopup = false;
                        this.errLoadSection = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.StatusLoader = false;
                        this.Locationpopup = false;
                        this.errLoadSection = resp?.data?.message;
                        this.getFilterLocation();
                        this.Sectionid = [];
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.StatusLoader = false;
                    this.Locationpopup = false;

                    this.errLoadSection = resp?.data?.message;
                })
            })
            .catch((error) => {


                runInAction(() => {
                    if (!error.response) {
                        this.StatusLoader = false;
                        this.Locationpopup = false;
                        this.Sectionid = [];
                        this.errLoadSection = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.StatusLoader = false;
                    this.Locationpopup = false;
                    this.Sectionid = [];


                    this.errLoadSection = error?.response?.data?.message;
                })
                return;
            })
    };
    // Bulk Active and inactive
    BulkstatusChange = (allLocation) => {
        runInAction(() => {
            this.LocationLoader = true;
            this.Locationpopup = true;

            this.errLoadLocation = "";
        });

        postMethodData(`/api/v1/admin/Location/update-status`, {

            "branchId": JSON.stringify(allLocation),
            "status": JSON.stringify(this.alldeactivate ? 1 : 0)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.LocationLoader = false;
                        this.Locationpopup = false;
                        this.errLoadLocation = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.LocationLoader = false;
                        this.Locationpopup = false;

                        this.errLoadLocation = resp?.data?.message;
                        this.getFilterLocation();
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.LocationLoader = false;
                    this.Locationpopup = false;

                    this.errLoadLocation = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.LocationLoader = false;
                        this.Locationpopup = false;

                        this.errLoadLocation = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.LocationLoader = false;
                    this.Locationpopup = false;

                    this.errLoadLocation = error?.response?.data?.message;
                })
                return;
            })
    };

    //Delete method
    deletefunction = () => {
        runInAction(() => {
            this.setdelateLoader(true);
            this.deletepopup = true;
            this.errLoadLocation = "";
        });
        postMethodData(`/api/v1/admin/location/delete`, {

            "record_id": this.Locationid,

        }, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {


                    this.LoadLocation = false;
                    this.setdelateLoader(true);
                    this.deletepopup = true;
                    this.errLoadLocation = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.LoadLocation = false;


                    this.setdelateLoader(false);
                    this.deletepopup = false;
                    this.errLoadLocation = resp?.data?.message;
                    this.getFilterLocation();
                    toast.success(resp.data.message);
                })
                return;
            }
            runInAction(() => {
                this.LoadLocation = false;
                this.errLoadLocation = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadcity = false;
                    this.Locationid = [];

                    if (!error.response) {
                        this.LoadLocation = false;
                        this.setdelateLoader(false);
                        this.deletepopup = false;
                        this.errLoadLocation = error?.message;
                        toast.error(error?.message);
                    }
                    this.LoadLocation = false;
                    this.errLoadLocation = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            })
    }
    // Bulk Delete 
    BulkDelete = (allLocation) => {
        runInAction(() => {
            this.LocationLoader = true;
            this.Locationpopup = true;

            this.errLoadLocation = "";
        });

        postMethodData(`/api/v1/admin/Location/multi-delete`, {

            "LocationId": JSON.stringify(allLocation),
            // "status": JSON.stringify(this.alldeactivate ? 1 : 0)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.LocationLoader = false;
                        this.Locationpopup = false;
                        this.errLoadLocation = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.LocationLoader = false;
                        this.Locationpopup = false;

                        this.errLoadLocation = resp?.data?.message;
                        this.getFilterLocation();
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.LocationLoader = false;
                    this.Locationpopup = false;

                    this.errLoadLocation = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.LocationLoader = false;
                        this.Locationpopup = false;

                        this.errLoadLocation = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.LocationLoader = false;
                    this.Locationpopup = false;

                    this.errLoadLocation = error?.response?.data?.message;
                })
                return;
            })
    };

    //get Location by id
    getLocationform = (id) => {
        runInAction(() => {
            this.LocationLoader = true;
            this.Locationpopup = true;

            this.errLoadLocation = "";
        });
        postMethodData(`/api/v1/admin/location/form`, {

            "record_id": id,
            // "status": JSON.stringify(this.status)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.LocationLoader = false;
                        this.Locationpopup = false;
                        this.errLoadLocation = resp?.data?.message;
                        // toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        console.log(resp.data.data);
                        this.setLocationFormDetails(resp.data.data);
                        this.country_obj.countryName = resp.data.data.country_name
                        this.country_obj.countryId = resp.data.data.country_code
                        this.state_obj.stateId  = resp.data.data.state_code
                        this.state_obj.stateName  = resp.data.data.state_name
                        this.country_name =  resp.data.data.country_name
                        this.Base64LocationImage = resp.data.data.image_name;
                        this.LocationLoader = false;
                        this.Locationpopup = false;
                        this.Locationname = resp.data.data.title;
                        this.type = resp.data.data.section_id;
                        this.errLoadLocation = resp?.data?.message;
                    })
                    return;
                }
                runInAction(() => {
                    this.LocationLoader = false;
                    this.Locationpopup = false;

                    this.errLoadLocation = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.LocationLoader = false;
                        this.Locationpopup = false;

                        this.errLoadLocation = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.LocationLoader = false;
                    this.Locationpopup = false;

                    this.errLoadLocation = error?.response?.data?.message;
                })
                return;
            })
    };

    getcountriesList = () => {
        runInAction(() => {
            this.LocationLoader = true;
            this.errLoadLocation = "";
            this.saved = false;
            this.nolist = false;
        });
        getMethodData(`/api/v1/admin/get-countries`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.LocationLoader = false;
                        this.errLoadLocation = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.LocationLoader = false;
                        this.errLoadLocation = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.countries = resp?.data.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadLocation = false;
                    this.errLoadLocation = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.LocationLoader = false;
                    if (!error.response) {
                        this.errLoadLocation = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadLocation = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };
    getstateList = (id) => {
        runInAction(() => {
            this.LocationLoader = true;
            this.errLoadLocation = "";
            this.saved = false;
            this.nolist = false;
        });
        postMethodData(`/api/v1/admin/get-states/${id}`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.LocationLoader = false;
                        this.errLoadLocation = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.LocationLoader = false;
                        this.errLoadLocation = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.state = resp?.data.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadLocation = false;
                    this.errLoadLocation = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.LocationLoader = false;
                    if (!error.response) {
                        this.errLoadLocation = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadLocation = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };
    //update method
    updateLocation = (id) => {
        runInAction(() => {
            this.EditloadLocation = true;
            this.errLoadLocation = "";
        });
        console.log(this.country_obj);
        const formData = new FormData();
        formData.append('country_code', this.LocationFormDetails.country_code);
        formData.append('country_name', this.country_name.countryname || this.country_obj.countryName );
        formData.append('state_code', this.LocationFormDetails.state_code);

        formData.append('state_name', this.state_name.statename ||  this.state_obj.stateName   );
        formData.append('city', this.LocationFormDetails.city);
        formData.append('pincode', this.LocationFormDetails.pincode);
        formData.append('record_id', id);

        // formData.append('isLocationUpdated', false  );
        // formData.append('isLogoUpdated', true  );


        postMethodData(`/api/v1/admin/location/edit`,
            formData,
            this.Token
        )
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.addloadcity = false;
                        this.EditloadLocation = false;

                        this.erraddcity = resp?.data?.message;
                        toast.error(resp.data.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.addloadcity = false;
                        this.EditloadLocation = false;

                        this.erraddcity = resp?.data?.message;
                        toast.success(resp.data.message);
                        // this.getCity();
                        this.getLocationList();
                        this.saved = true;
                    })
                    return
                }
                runInAction(() => {
                    this.addloadcity = false;
                    this.erraddcity = resp?.data?.message;
                    toast.error(resp.data.message);
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.EditloadLocation = false;

                    if (!error.response) {
                        this.addloadcity = false;
                        this.erraddcity = error?.message;
                        toast.error(error?.message);
                    }
                    if (error.response && error.response.data && error.response.data.formErrors) {
                        const formErrors = error.response.data.formErrors;
                        Object.keys(formErrors).forEach((key) => {
                            const errorMessage = formErrors[key];
                            toast.error(`${key}: ${errorMessage}`);
                        });
                    }
                    this.addloadcity = false;
                    this.erraddcity = error?.response?.data?.message;
                })
                return;
            })
    };
}




export const LocationStoreContext = createContext(new LocationStore());
export const useLocationStore = () => useContext(LocationStoreContext);
