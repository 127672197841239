import { createContext, useContext } from "react";
import { action, makeAutoObservable, makeObservable, observable, runInAction } from "mobx";
import { postMethodData, getMethodData, putMethod, delMethod, patchMethodData } from "../../ApiMethods/api";
import { toast } from "react-toastify";
import { format } from 'date-fns';
const SEO_URL = process.env.REACT_APP_SEO_URL;





export class PreOrderStore {

    Token = null;
    PreOrder = [];
    PreOrderImage = "";
    type = "";
    PreOrdertype = [];
    PreOrderCircle = false;
    filterSearch = "";
    PaginationPage = "1";
    TotalPages = "";
    PreOrderDimiensions = ""
    crop = {};
    errLoadPreOrder = "";
    LoadPreOrder = false;
    delateLoader = false;
    nolist = false;
    PreOrderpopup = false;
    deletepopup = false;
    PreOrdername = "";
    status = "1";
    statesid = "";
    PreOrderid = [];
    seoUrl = "";
    statuspopup = false;
    addPreOrdername = "";
    imageurl = "";
    logoimage = "";
    PreOrderimage = "";
    Base64logoImage = "";
    Base64PreOrderImage = "";
    logofile = [];
    allPreOrder = [];
    alldeactivate = 'false';
    isPreOrderUpdated = "false";
    isLogoUpdated = "false"
    Base64BannerImage = "";
    checkeddd = '';

    PreOrderFormDetails = {
        "product_name": "",
        "valid_till": "",
        "is_active": "",
        "section_id": "",
        "image": ""
    };
    saved = false;

    constructor(isloading = false) {
        makeAutoObservable(this, {
            addPreOrdername: observable,
            status: observable,
            errorMessages: observable,
            setaddPreOrdername: action,
            getstatus: action,
            resetErrors: action,
        });

        this.Token = localStorage.getItem("Admintoken");
        if (isloading.isloading) {
            this.getPreOrderSettings();
            //   this.getStateList();
            return
        }
        if (isloading.listing) {
            this.getPreOrderList();
            //   this.getStateList();
            return
        }

        if (isloading.addloading) {
            this.getPreOrderTypeList();
        }
        if (isloading.PreOrdereditloading) {
            this.getPreOrderform(isloading.id);
            this.getPreOrderTypeList();

            return
        }
    }
    //All Clear 
    allclear = () => {
        this.filterSearch = ""
        this.getFilterPreOrder();
        //   this.status="";
        //   this.statesid="";
    }
    setimage = (text, type) => {
        this.imageurl = URL.createObjectURL(text[0]);
    }

    setalldeactivate = (text) => {
        this.alldeactivate = !this.alldeactivate;
    };

    getid = (text) => {
        this.PreOrderid.push(text);
    };



    // status popup
    setstatuspopup = () => {
        this.statuspopup = !this.statuspopup;
    };


    setallPreOrder = (text) => {
        this.allPreOrder = text;
    }

    // get status 
    getstatus = (text) => {
        this.status = text;
    };

    //get city name
    getPreOrdername = (text) => {
        this.PreOrdername = text;
    };

    setaddPreOrdername = (text) => {
        this.addPreOrdername = text;
    };
    setCrop = (text) => {
        this.crop = text;
    };

    setPreOrderDimiensions = (text) => {
        this.PreOrderDimiensions = text;
    };

    setlogofile = (e) => {
        this.logofile = e;
    };

    setPreOrderpopup = (e) => {
        this.PreOrderpopup = e;
    };
    setdeletepopup = (e) => {
        this.deletepopup = e;
    };
    setdelateLoader = (e) => {
        this.delateLoader = e;
    }
    getToken = (e) => {
        this.Token = e;
    }
    gettype = (e) => {
        this.type = e;
    }
    getseourl = (text) => {
        // console.log(`https://demo.e-kart.com/${text}`);
        this.seoUrl = text;
    };
    getlogoimage = (text) => {
        this.logoimage = text;
    };
    getPreOrder_image = (text) => {
        this.PreOrderimage = text;
    };
    SetfilterSearch = (text) => {
        this.filterSearch = text;
    };
    setPaginationPage = (text) => {
        this.PaginationPage = text;
    };
    SetTotalPages = (text) => {
        this.TotalPages = text;
    };
    setchecked = (text) => {
        this.checkeddd = text;
    };
    setisPreOrderUpdated = (text) => {
        this.isPreOrderUpdated = text;
    };
    setisLogoUpdated = (text) => {
        this.isLogoUpdated = text;
    };

    isBase64 = (str) => {
        const base64Regex = /^(data:image\/([a-zA-Z]+);base64,)/;
        return base64Regex.test(str);
    };
    setPreOrderFormDetails = (PreOrderDetail) => {
        console.log(PreOrderDetail);
        if (PreOrderDetail != null) {
            this.PreOrderFormDetails = {
                ...this.PreOrderFormDetails,
                product_name: PreOrderDetail?.product_name || "", // Use default value if name is undefined
                valid_till: format(new Date(PreOrderDetail?.valid_till), 'yyyy-MM-dd') || "", // Use default value if seo_url is undefined
                // logo_image: this.isBase64( this.PreOrderFormDetails.logo_image) ? this.logofile.length == 0 ? PreOrderDetail.logo_image    : this.PreOrderFormDetails.logo_image  : this.logofile.length !== 0 ? this.PreOrderFormDetails.logo_image :  PreOrderDetail.logo_image   || "",
                // PreOrder_image: this.isBase64(PreOrderDetail.PreOrder_image) ? this.PreOrderFormDetails.PreOrder_image : PreOrderDetail.PreOrder_image || "",
                image_name: PreOrderDetail?.image_name || "",
                image: PreOrderDetail?.image || "",
                // section_name: PreOrderDetail.section_name || "",

                is_active: PreOrderDetail?.is_active
            };
        }
        else{
            this.PreOrderFormDetails = {
                ...this.PreOrderFormDetails,
                product_name: PreOrderDetail?.product_name || "", // Use default value if name is undefined
                valid_till: format(new Date(), 'yyyy-MM-dd') || "", // Use default value if seo_url is undefined
                // logo_image: this.isBase64( this.PreOrderFormDetails.logo_image) ? this.logofile.length == 0 ? PreOrderDetail.logo_image    : this.PreOrderFormDetails.logo_image  : this.logofile.length !== 0 ? this.PreOrderFormDetails.logo_image :  PreOrderDetail.logo_image   || "",
                // PreOrder_image: this.isBase64(PreOrderDetail.PreOrder_image) ? this.PreOrderFormDetails.PreOrder_image : PreOrderDetail.PreOrder_image || "",
                image_name: PreOrderDetail?.image_name || "",
                image: PreOrderDetail?.image || "",
                // section_name: PreOrderDetail.section_name || "",

                is_active: PreOrderDetail?.is_active
            };
        }

        // console.log("PreOrderDetail?.valid_till ", PreOrderDetail?.valid_till);
    };
    // setPreOrderFormDetails = (text) => {
    //     this.PreOrderFormDetails = text;
    //     console.log("setPreOrderFormDetails", this.PreOrderFormDetails.PreOrdername);
    // };
    base64toFile(base64Data, fileName, mimeType) {
        if (base64Data) {
            const byteCharacters = atob(base64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters?.length; offset += 512) {
                const slice = byteCharacters?.slice(offset, offset + 512);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const file = new File(byteArrays, fileName, { type: mimeType });

            return file;
        }

    }

    // get PreOrder list
    getPreOrderSettings = () => {
        runInAction(() => {
            this.PreOrderCircle = true;
            this.errLoadPreOrder = "";
            this.saved = false;
            this.nolist = false;
            this.PreOrder = [];
        });

        getMethodData(`/api/v1/admin/pre-order/setting`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.PreOrderCircle = false;
                        this.errLoadPreOrder = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.PreOrderCircle = false;
                        this.errLoadPreOrder = resp?.data?.message;
                        if (resp?.data?.data?.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }

                        this.setPreOrderFormDetails(resp?.data?.data);
                        this.PreOrder = resp?.data?.data;
                        this.Base64BannerImage = resp?.data?.data?.image;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadPreOrder = false;
                    this.errLoadPreOrder = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.PreOrderCircle = false;
                    if (!error.response) {
                        this.errLoadPreOrder = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadPreOrder = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };
    getPreOrderList = () => {
        runInAction(() => {
            this.PreOrderLoader = true;
            this.errLoadPreOrder = "";
            this.saved = false;
            this.nolist = false;
            this.PreOrder = [];
        });
        getMethodData(`/api/v1/admin/pre-order/get-detail`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.PreOrderLoader = false;
                        this.errLoadPreOrder = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.PreOrderLoader = false;
                        this.errLoadPreOrder = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.PreOrder = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadPreOrder = false;
                    this.errLoadPreOrder = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.PreOrderLoader = false;
                    if (!error.response) {
                        this.errLoadPreOrder = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadPreOrder = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    // get PreOrder type list
    getPreOrderTypeList = () => {
        runInAction(() => {
            this.PreOrderLoader = true;
            this.errLoadPreOrder = "";
            this.saved = false;
            this.nolist = false;
            this.PreOrdertype = [];
        });
        getMethodData(`/api/v1/admin/sections/auto-complete`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.PreOrderLoader = false;
                        this.errLoadPreOrder = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.PreOrderLoader = false;
                        this.errLoadPreOrder = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.PreOrdertype = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadPreOrder = false;
                    this.errLoadPreOrder = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.PreOrderLoader = false;
                    if (!error.response) {
                        this.errLoadPreOrder = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadPreOrder = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };
    //Filter city
    // filter League court

    getFilterPreOrder = () => {
        runInAction(() => {
            this.LoadPreOrder = true;
            this.errLoadPreOrder = "";
            this.saved = false;
            this.nolist = false;
            this.getPreOrderList = [];
        });
        postMethodData(`/api/v1/admin/pre-order/search?page=${this.PaginationPage}`, {
            keyword: this.filterSearch
        }, this.Token)
            .then((resp) => {

                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.loadleague = false;
                        this.errloadleague = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {

                    runInAction(() => {
                        this.loadcity = false;
                        this.errloadleague = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.PreOrder = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.loadcity = false;
                    this.errloadcuty = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.loadleague = false;
                    if (!error.response) {
                        this.errloadleague = error?.message;
                        toast.error(error?.message);
                    }
                    this.errloadleague = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    // add new PreOrder
    addPreOrder = () => {
        runInAction(() => {
            this.addloadPreOrder = true;
            this.errLoadPreOrder = "";
        });
        const formData = new FormData();

        if (this.PreOrderimage) {
            const PreOrderbase64String = this.PreOrderimage.split(',')[1];
            const PreOrderFile = this.base64toFile(PreOrderbase64String, 'logo.png', 'image/png');
            formData.append('PreOrder_image', PreOrderFile);
        }
        else {
            formData.append('PreOrder_image', null);
        }

        formData.append('title', this.addPreOrdername);
        formData.append('url', `${SEO_URL}${this.seoUrl}`);
        formData.append('section_id', this.type);
        formData.append('status', this.status);



        postMethodData("/api/v1/admin/PreOrder/add", formData, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.addloadPreOrder = false;
                    this.errLoadPreOrder = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.addloadPreOrder = false;
                    this.errLoadPreOrder = resp?.data?.message;
                    // toast.success(resp.data.message);
                    this.getPreOrderList();
                    this.saved = true;
                })
                return
            }
            runInAction(() => {
                this.addloadPreOrder = false;
                this.errLoadPreOrder = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadPreOrder = false;
                    if (!error.response) {
                        this.errLoadPreOrder = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadPreOrder = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    //Active and inactive
    //Active and inactive
    statusChange = () => {
        runInAction(() => {
            this.SectionLoader = true;
            this.Sectionpopup = true;

            this.errLoadSection = "";
        });
        postMethodData(`/api/v1/admin/PreOrder/update-status`, {

            "record_id": this.PreOrderid,
            "status": JSON.stringify(this.status)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.Sectionid = [];
                        this.PreOrderid = [];

                        this.SectionLoader = false;
                        this.PreOrderpopup = false;
                        this.errLoadSection = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.SectionLoader = false;
                        this.PreOrderpopup = false;
                        this.errLoadSection = resp?.data?.message;
                        this.getPreOrderList();
                        this.Sectionid = [];
                        this.PreOrderid = [];
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.SectionLoader = false;
                    this.PreOrderpopup = false;

                    this.errLoadSection = resp?.data?.message;
                })
            })
            .catch((error) => {
                this.PreOrderid = [];

                runInAction(() => {
                    if (!error.response) {
                        this.SectionLoader = false;
                        this.PreOrderpopup = false;
                        this.Sectionid = [];
                        this.errLoadSection = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.SectionLoader = false;
                    this.PreOrderpopup = false;
                    this.Sectionid = [];


                    this.errLoadSection = error?.response?.data?.message;
                })
                return;
            })
    };
    // Bulk Active and inactive
    BulkstatusChange = (allPreOrder) => {
        runInAction(() => {
            this.PreOrderLoader = true;
            this.PreOrderpopup = true;

            this.errLoadPreOrder = "";
        });

        postMethodData(`/api/v1/admin/PreOrder/update-status`, {

            "branchId": JSON.stringify(allPreOrder),
            "status": JSON.stringify(this.alldeactivate ? 1 : 0)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.PreOrderLoader = false;
                        this.PreOrderpopup = false;
                        this.errLoadPreOrder = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.PreOrderLoader = false;
                        this.PreOrderpopup = false;

                        this.errLoadPreOrder = resp?.data?.message;
                        this.getFilterPreOrder();
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.PreOrderLoader = false;
                    this.PreOrderpopup = false;

                    this.errLoadPreOrder = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.PreOrderLoader = false;
                        this.PreOrderpopup = false;

                        this.errLoadPreOrder = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.PreOrderLoader = false;
                    this.PreOrderpopup = false;

                    this.errLoadPreOrder = error?.response?.data?.message;
                })
                return;
            })
    };

    //Delete method
    deletefunction = () => {
        runInAction(() => {
            this.setdelateLoader(true);
            this.deletepopup = true;
            this.errLoadPreOrder = "";
        });
        postMethodData(`/api/v1/admin/PreOrder/delete`, {

            "record_id": this.PreOrderid,

        }, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.PreOrderid = [];

                    this.LoadPreOrder = false;
                    this.setdelateLoader(true);
                    this.deletepopup = true;
                    this.errLoadPreOrder = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.LoadPreOrder = false;
                    this.PreOrderid = [];

                    this.setdelateLoader(false);
                    this.deletepopup = false;
                    this.errLoadPreOrder = resp?.data?.message;
                    this.getPreOrderList();
                    toast.success(resp.data.message);
                })
                return;
            }
            runInAction(() => {
                this.LoadPreOrder = false;
                this.errLoadPreOrder = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadcity = false;
                    this.PreOrderid = [];

                    if (!error.response) {
                        this.LoadPreOrder = false;
                        this.setdelateLoader(false);
                        this.deletepopup = false;
                        this.errLoadPreOrder = error?.message;
                        toast.error(error?.message);
                    }
                    this.LoadPreOrder = false;
                    this.errLoadPreOrder = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            })
    }
    // Bulk Delete 
    BulkDelete = (allPreOrder) => {
        runInAction(() => {
            this.PreOrderLoader = true;
            this.PreOrderpopup = true;

            this.errLoadPreOrder = "";
        });

        postMethodData(`/api/v1/admin/PreOrder/multi-delete`, {

            "PreOrderId": JSON.stringify(allPreOrder),
            // "status": JSON.stringify(this.alldeactivate ? 1 : 0)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.PreOrderLoader = false;
                        this.PreOrderpopup = false;
                        this.errLoadPreOrder = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.PreOrderLoader = false;
                        this.PreOrderpopup = false;

                        this.errLoadPreOrder = resp?.data?.message;
                        this.getFilterPreOrder();
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.PreOrderLoader = false;
                    this.PreOrderpopup = false;

                    this.errLoadPreOrder = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.PreOrderLoader = false;
                        this.PreOrderpopup = false;

                        this.errLoadPreOrder = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.PreOrderLoader = false;
                    this.PreOrderpopup = false;

                    this.errLoadPreOrder = error?.response?.data?.message;
                })
                return;
            })
    };

    //get PreOrder by id
    getPreOrderform = (id) => {
        runInAction(() => {
            this.PreOrderLoader = true;
            this.PreOrderpopup = true;

            this.errLoadPreOrder = "";
        });
        postMethodData(`/api/v1/admin/PreOrder/form`, {

            "record_id": id,
            // "status": JSON.stringify(this.status)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.PreOrderLoader = false;
                        this.PreOrderpopup = false;
                        this.errLoadPreOrder = resp?.data?.message;
                        // toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.setPreOrderFormDetails(resp.data.data);
                        this.Base64PreOrderImage = resp.data.data.image_name;
                        this.PreOrderLoader = false;
                        this.PreOrderpopup = false;
                        this.PreOrdername = resp.data.data.title;
                        this.type = resp.data.data.section_id;
                        this.errLoadPreOrder = resp?.data?.message;
                    })
                    return;
                }
                runInAction(() => {
                    this.PreOrderLoader = false;
                    this.PreOrderpopup = false;

                    this.errLoadPreOrder = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.PreOrderLoader = false;
                        this.PreOrderpopup = false;

                        this.errLoadPreOrder = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.PreOrderLoader = false;
                    this.PreOrderpopup = false;

                    this.errLoadPreOrder = error?.response?.data?.message;
                })
                return;
            })
    };



    //update method
    updatePreOrder = () => {
        runInAction(() => {
            this.addloadPreOrder = true;
            this.errLoadPreOrder = "";
        });
        var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
        const formData = new FormData();


        if (this.isBase64(this.PreOrderFormDetails.image)) {
            const PreOrderbase64String = this.PreOrderFormDetails.image.split(',')[1];
            const PreOrderFile = this.base64toFile(PreOrderbase64String, 'logo.png', 'image/png');
            formData.append('pre_order_image', PreOrderFile);
        }


        if (this.Base64BannerImage) {
            formData.append('pre_order_image', this.Base64BannerImage);

        }



        formData.append('prod_name', this.PreOrderFormDetails.product_name);
        formData.append('valid_till', this.PreOrderFormDetails.valid_till);
        // formData.append('section_id', this.PreOrderFormDetails.section_id == "" ? null : this.PreOrderFormDetails.section_id);


        formData.append('status', this.PreOrderFormDetails.is_active);

        // formData.append('isPreOrderUpdated', false  );
        // formData.append('isLogoUpdated', true  );


        postMethodData(`/api/v1/admin/pre-order/save-setting`,
            formData,
            this.Token
        )
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.addloadcity = false;
                        this.erraddcity = resp?.data?.message;
                        toast.error(resp.data.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {

                        this.addloadcity = false;
                        this.erraddcity = resp?.data?.message;
                        toast.success(resp.data.message);
                        this.getPreOrderSettings();
                        // this.getCity();
                        // this.getPreOrderList();
                        this.saved = true;
                    })
                    return
                }
                runInAction(() => {
                    this.addloadcity = false;
                    this.erraddcity = resp?.data?.message;
                    toast.error(resp.data.message);
                });
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.addloadcity = false;
                        this.erraddcity = error?.message;
                        toast.error(error?.message);
                    }
                    if (error.response && error.response.data && error.response.data.formErrors) {
                        const formErrors = error.response.data.formErrors;
                        Object.keys(formErrors).forEach((key) => {
                            const errorMessage = formErrors[key];
                            toast.error(`${key}: ${errorMessage}`);
                        });
                    }
                    this.addloadcity = false;
                    this.erraddcity = error?.response?.data?.message;
                })
                return;
            })
    };
}




export const PreOrderStoreContext = createContext(new PreOrderStore());
export const usePreOrderStore = () => useContext(PreOrderStoreContext);
