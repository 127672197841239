import { createContext, useContext } from "react";
import {makeAutoObservable,observable,runInAction} from "mobx";
import { postMethodData, getMethodData, putMethod , delMethod, patchMethodData } from "../../ApiMethods/api";
import { toast } from "react-toastify";




export class CityStore {

  Token = null;
  loadcitylist = false;
  CityLoader=false;
  city = [];
  errloadcity = "";
  loadcity = false;
  nolist = false;
  nostatelist = false;
  Citypopup=false;
  addloadcity = false;
  erraddcity = "";
  cityname = "";
  zipcode = "";
  country  = "";
  status = "";
  statesid="";
  updatedcity = {};
  cityid = "";
  Statename=[];
  getstates="";
  updatedzipcode="";

  newcityname= "";
  statuspopup = false;


  saved = false

  constructor(isloading = false) {
    makeAutoObservable(this);
   
    this.Token = localStorage.getItem("Admintoken");
    if(isloading.isloading){
      this.getCity();
      this.getStateList();
      return
    }
    if(isloading.addloading){
      this.getStateList();
    }
    if(isloading.editloading){
      this.getcityone(isloading.id);
      this.getStateList();
      return
    }
  }
//All Clear 
allclear=()=>{
  this.cityname="";
  this.status="";
  this.statesid="";
}
  // status popup
  setstatuspopup = () => {
    this.statuspopup = !this.statuspopup;
  };
  getstate=(text)=>{
    this.getstates=text;
  }

  //get state fliter
  getstatefilter=(text)=>{
    this.statesid=text;
  }
  // get city id
  getid = (text) => {
    this.cityid = text;
  }

  // get status 
  getstatus = (text) => {
    this.status = text;
  };

  //get city name
  getcityname = (text) => {
    this.cityname = text;
  };

  //get updated city
  updatedcityname = (text) => {
    this.newcityname = text;
  };

  //get zipcode
  getzipcode = (text) => {
    this.zipcode = text;
  };

  // get country name
  getcountryname = (text) => {
    this.country = text;
  };
  setcitypopup = (e) => {
    this.Citypopup = e;
  };

  getToken=(e)=>{
    this.Token=e;
    }
    
  // get city list
  getCity = () => {
    runInAction(() => {
      this.loadcity = true;
      this.errloadcity = "";
      this.saved = false;
      this.nolist = false;
      this.city = [];
    });
    getMethodData("/api/admin/city", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadcity = false;
            this.errloadcity = resp?.data?.message
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadcity = false;
            this.errloadcity = resp?.data?.message;
            if(resp?.data?.data.length === 0) {
              this.nolist = true;
            }else{
              this.nolist = false;
            }
            this.city = resp?.data?.data;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadcity = false;
          this.errloadcity = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
            this.loadcity = false;
            if(!error.response){
                this.errloadcity = error?.message;
                toast.error(error?.message);
            }
            this.errloadcity = error?.response?.data?.message;
            toast.error(error?.response?.data?.message);
        })
        return;
    });
  };
  //Filter city
   // filter League court

   getFilterCity = () => {
    runInAction(() => {
      this.loadcity = true;
      this.errloadcity = "";
      this.saved = false;
      this.nolist = false;
      this.city =[];
    });
    getMethodData(`/api/admin/city?filters[page]=1&filters[city_name]=${this.cityname}&filters[isActive]=${this.status}&filters[state]=${this.statesid}`, this.Token)
      .then((resp) => {
  
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadleague = false;
            this.errloadleague = resp?.data?.message
          });
          return;
        }
        if (resp?.data?.status === true) {

          runInAction(() => {
            this.loadcity = false;
            this.errloadleague = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.city = resp?.data?.data;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadcity = false;
          this.errloadcuty = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadleague = false;
          if (!error.response) {
            this.errloadleague = error?.message;
            toast.error(error?.message);
          }
          this.errloadleague = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        })
        return;
      });
  };
  // get State list
  getStateList = () => {
    runInAction(() => {
      this.loadSate = true;
      this.errStatelevel = "";
      this.saved = false;
      this.nostatelist = false;
    });
    getMethodData("/api/admin/state", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadleague = false;
            this.errloadhomecourt = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadSate = false;
            this.errStatelevel = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nostatelist = true;
            } else {
              this.nostatelist = false;
            }
            this.Statename = resp?.data?.data;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.addleague = false;
          this.loadSate = false;
          this.errStatelevel = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadSate = false;
          if (!error.response) {
            this.errloadlevel = error?.message;
            toast.error(error?.message);
          }
          this.errloadlevel = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };

  // add new city
  addCity = () => {
    runInAction(() => {
      this.addloadcity = true;
      this.erraddcity = "";
    });
    postMethodData("/api/admin/city",{
        city_name: this.cityname,
        // zipcode: this.zipcode,
        state:this.getstates,
        isActive:`${this.status}`,
      },this.Token).then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.addloadcity = false;
            this.erraddcity = resp?.data?.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.addloadcity = false;
            this.erraddcity = resp?.data?.message;
            // toast.success(resp.data.message);
            this.getCity();
            this.saved = true;
          })
          return
        }
        runInAction(() => {
          this.addloadcity = false;
          this.erraddcity = resp?.data?.message;
        });
        
      })
      .catch((error) => {
        runInAction(() => {
            this.addloadcity = false;
            if(!error.response){
                this.erraddcity = error?.message;
                toast.error(error?.message);
            }
            this.erraddcity = error?.response?.data?.message;
            toast.error(error?.response?.data?.message);
        })
        return;
    });
  };


  //Active and inactive
  statusChange = () => {
    runInAction(() => {
      this.CityLoader = true;
      this.Citypopup=true;

      this.errloadcity = "";
    });
    patchMethodData(`/api/admin/city/status/${this.cityid}`,{ isActive : `${!this.status}` },this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.CityLoader = false;
            this.Citypopup=false;

            this.errloadcity = resp?.data?.message;
            toast.error(resp?.data?.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.CityLoader = false;
            this.Citypopup=false;

            this.errloadcity = resp?.data?.message;
            this.getCity();
            toast.success(resp?.data?.message);
          })
          return;
        }
        runInAction(() => {
          this.CityLoader = false;
          this.Citypopup=false;

          this.errloadcity = resp?.data?.message;
        })
      })
      .catch((error) => {
        runInAction(() => {
            if(!error.response){
              this.CityLoader = false;
              this.Citypopup=false;

              this.errloadcity = error?.message;
              toast.error(error?.message);
            }
              toast.error(error?.response?.data?.message);
              this.CityLoader = false;
              this.Citypopup=false;

              this.errloadcity = error?.response?.data?.message;
        })
        return;
    })
  };
  

  //Delete method
  deletefunction = () =>{ 
      runInAction(() => {
        this.loadcity = true;
        this.errloadcity = "";
      });
      delMethod(`/api/admin/city/${this.cityid}`,this.Token).then((resp) => {
          if (resp?.data?.status === false) {
            runInAction(() => {
              this.loadcity = false;
              this.errloadcity = resp?.data?.message;
              toast.error(resp.data.message);
            });
            return;
          }
          if (resp?.data?.status === true) {
            runInAction(() => {
              this.loadcity = false;
              this.errloadcity = resp?.data?.message;
              this.getCity();
              // toast.success(resp.data.message);
            })
            return;
          }
          runInAction(() => {
            this.loadcity = false;
            this.errloadcity = resp?.data?.message;
          });
          
        })
        .catch((error) => {
          runInAction(() => {
              this.addloadcity = false;
              if(!error.response){
                this.loadcity = false;
                this.errloadcity = error?.message;
                toast.error(error?.message);
              }
                this.loadcity = false;
                this.errloadcity = error?.response?.data?.message;
                toast.error(error?.response?.data?.message);
          })
          return;
        })
  }


  //get city by id
  getcityone =(id) => {
    getMethodData( `/api/admin/city/${id}`, this.Token)
    .then((resp) => {
        runInAction(() => {
            this.loadcity = true;
            this.errloadcity = "";
          });
      if (resp?.data?.status === false) {
        runInAction(() => {
          this.loadcity = false;
          this.errloadcity = resp.data.message;
          toast.error(resp.data.message);
        });
        return;
      }
      if (resp?.data?.status === true) {
        runInAction(() => {
          this.loadcity = false;
          this.errloadcity = resp?.data?.message;
          this.updatedcity= resp.data.data;
          this.newcityname = resp?.data?.data?.city_name;
          this.country = resp?.data?.data?.country;
          this.zipcode = resp?.data?.data?.zipcode;
          this.getstates= resp?.data?.data?.state;
          this.status = resp?.data?.data?.isActive;
        })
        return;
      }
      runInAction(() => {
        this.loadcity = false;
        this.errloadcity = resp.data.message;
        toast.error(resp.data.message);
      });
    })
    .catch((error) => {
      runInAction(() => {
          if(!error.response){
            this.loadcity = false;
            this.errloadcity = error?.message;
            toast.error(error?.message);
          }
            toast.error(error?.response?.data?.message);
            this.loadcity = false;
            this.errloadcity = error?.response?.data?.message;
      })
      return;
  })
}


//update method
updatecity = (id) => {
    runInAction(() => {
      this.addloadcity = true;
      this.erraddcity = "";
    });
    putMethod(`/api/admin/city/${id}`,
      {
        city_name: this.newcityname,
        state: this.getstates,
        zipcode: this.zipcode,
        isActive: this.status,
      },
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.addloadcity = false;
            this.erraddcity = resp?.data?.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
              this.addloadcity = false;
              this.erraddcity = resp?.data?.message;
              toast.success(resp.data.message);
              this.getCity();
              this.saved = true;
            })
            return
        }
        runInAction(() => {
          this.addloadcity = false;
          this.erraddcity = resp?.data?.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
            if(!error.response){
              this.addloadcity = false;
              this.erraddcity = error?.message;
              toast.error(error?.message);
            }
              toast.error(error?.response?.data?.message);
              this.addloadcity = false;
              this.erraddcity = error?.response?.data?.message;
        })
        return;
    })
  };
}




export const CityStoreContext = createContext(new CityStore());
export const useCityStore = () => useContext(CityStoreContext);
