import React, { useCallback, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdOutlineClose, MdOutlineCheck, MdImageAspectRatio, MdImage, MdCancel, MdPlusOne, MdHdrPlus, MdAdd, MdEdit, MdSave } from "react-icons/md";
import { Input, Button, Select } from "react-daisyui";
import SearchableDropdown from "../common/SearchableDropdown/SearchableDropdown";
// import { CityStore, CityStoreContext } from "./store/CityStore";
// import { AdminUseStore, useAdminUsedStore, AdminUserStoreContext } from "./store/AdminUsersStore";
import { ProductCatalogStore, useProductCatalogStore, ProductCatalogStoreContext } from "./Store/ProductCatalogStore";
import { observer } from "mobx-react";
import { useAuthStore } from "../Auth/store/Auth";
import { useState } from "react";
import JoditEditor from "jodit-react";
import ImgCrop from "antd-img-crop";
import { Upload } from 'antd';
import debounce from 'lodash/debounce';
import { autorun } from "mobx";
import { toast } from "react-toastify";

const EditPrductCatalog = observer(() => {
    const { id } = useParams();

    return (
        <ProductCatalogStoreContext.Provider
            value={
                new ProductCatalogStore({
                    loadeditcitybol: false,
                    editProductCataloglist: true,
                    id: id
                })
            }
        >
            <Screen />
        </ProductCatalogStoreContext.Provider>
    );
});
export default EditPrductCatalog;

const Screen = observer(() => {
    const { frame_image, productValueid, shipping, ProductFormLoader, editloadProductCatalog, combined_option_data, options_in_combination, addloadProductCatalog, productDetail, cod_available, product_approved, product_featured, defaultImageDetail, productOptionDetail, optionArray, optionDetail, productSpecificationDetail, code_for_all_variant, is_active, productName, productIdentifier, prodcutwarranty, product_code, warranty_type, minimumprice, YouTubevideo, model, optionslist, producttype, UnitDetails, options_dropdown, ativate, setativate, getProductCatalogform, setshipping, getunit_type_idss, getunit_id, getUnitDetails, setmaster_option, getToken, getcontent, setproduct_code, getlogoimage, deleteSpecification, addProductImage, deleteImagefunction, getProductValuesList, setproducttype, setproductIdentifier, setproductName, setproduct_featured, setproduct_approved, setcod_available, setmodel, setminimumprice, setprodcutwarranty, setwarranty_type, setYouTubevideo, setVideotitle, setvideo_desc, video_desc, Videotitle, logoimage, setcode_for_all_variant, UpdateProductCatalog, uploaded_file, ProductOptions, ProductValues, content, specificationDetail, setdisplay, getusername, setemail, setalert, Confirmpassw, passwordChanged, errPassword, saved, All_categories, image_array, frame_name_array, image_name_array,
        has_options, isBase64, Brand, shop } = useProductCatalogStore();
    const { adminAuthApproved } = useAuthStore();

    const [QtyPrice, setQtyPrice] = useState([

        [
            {
                option_id: '',
                option_value_id: '',
                optionvaluename: '',
                pricetype: '',
                type: '',
                costing: [
                    { quantity: '', price: '' } // Initial QtyPrice array for each data object
                ]
            }
        ]
    ]);

    const [combinationFields, setcombinationFields] = useState([


        [
            {

                material_consume: "",
                material_consume_unit: "",
                options_detail: [],
                option_set: [
                    { option_id: '', option_value_id: '' } // Initial QtyPrice array for each data object
                ]
            }
        ]

    ]);
    const { id } = useParams();
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [searchQuery, setSearchQuery] = useState('');
    const [NewFrameDescIndex, setNewFrameDescIndex] = useState('');
    const [otheroptionlength, setotheroptionlength] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    const [optionlength, setoptionlength] = useState('');
    const [option, setoption] = useState("1")
    const [material_consume, setmaterial_consume] = useState('');
    // const [logoimage, setlogoimage] = useState();
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [showList, setShowList] = useState(false);
    const [isListVisible, setListVisibility] = useState(false);
    const [addedItems, setAddedItems] = useState([]);
    const [isFixed, setIsFixed] = useState(true);
    const [activeTab, setActiveTab] = useState('basic'); // Set the default active tab
    const basicDetailsRef = useRef(null);
    const variantsRef = useRef(null);
    const media = useRef(null);
    const specifications = useRef(null);
    const [specificationsList, setSpecificationsList] = useState([]);
    const nameRef = useRef(null);
    const valueRef = useRef(null);
    const Framemedia = useRef(null);
    const groupRef = useRef(null);
    const [isparentID, setisparentID] = useState('');
    const [price, setprice] = useState('');
    const [price_type, setprice_type] = useState('');
    const [quantity, setquantity] = useState('');
    const [errorvalueMessage, setErrorvalueMessage] = useState('');
    const [errornameMessage, setErrornameMessage] = useState('');
    const [selectedOptionsInPreviousDropdowns, setSelectedOptionsInPreviousDropdowns] = useState([]);
    const [initiallySelectedOptions, setInitiallySelectedOptions] = useState([]);
    const [isCombinationShowList, setisCombinationShowList] = useState(combinationFields.map(() => false));
    const [CombofilteredItemsList, setComboFilteredItemsList] = useState(combinationFields.map(() => []));
    const [ComboselectedOptionsInPreviousDropdowns, setComboSelectedOptionsInPreviousDropdowns] = useState([]);
    const [CombofilteredItemsList_zero, setComboFilteredItemsList_zero] = useState(combinationFields.map(() => []));
    const [isCombinationShowList_Zero, setisisCombinationShowList_Zero] = useState(combinationFields.map(() => false));






    const [hideShowCombinationStates, sethideShowCombinationStates] = useState(combinationFields.map(() => false));

    const [isOpenCombinationStates, setIsOpenCombinationStates] = useState(combinationFields.map(() => false));





    const [categoryCombinationvalue, setcategoryCombinationvalue] = useState(combinationFields.map(() => "Select option..."));
    const [categoryCombinationvalue_Id, setcategoryCombination_Id] = useState(combinationFields.map(() => "Select option..."));

    const [ComboselectedOptionsList_zero, setComboSelectedOptionsList_zero] = useState(combinationFields.map(() => []));


    const [ComboselectedOptionsList, setComboSelectedOptionsList] = useState(combinationFields.map(() => []));

    const [ComboselectedOptionsList_Index, setComboselectedOptionsList_Index] = useState(combinationFields.map(() => []));


    const [inputFieldVisibility, setInputFieldVisibility] = useState();

    // Function to toggle the visibility of the input field based on index
    const [visibleInputIndex, setVisibleInputIndex] = useState([]);

    // Function to toggle the visibility of the input field based on parent index and child index
    const toggleInputField = (parentIndex, childIndex) => {
        // Create a copy of the current visibility state
        const updatedVisibility = { ...visibleInputIndex };
        setInputFieldVisibility(parentIndex)
        // Toggle the visibility of the input field for the specified parent index and child index
        updatedVisibility[parentIndex] = updatedVisibility[parentIndex] === childIndex ? null : childIndex;

        // Update the visibility state
        setVisibleInputIndex(updatedVisibility);
    };


    const [logofile, setlogofile] = useState([]);
    const [logoFramefile, setlogoFramefile] = useState([]);

    useEffect(() => {
        // Define a function to update logofile based on the store data
        const updateLogofile = () => {
            if (specificationDetail && specificationDetail.length > 0) {
                // Set logofile state with default images
                setSpecificationsList(specificationDetail);
            }
        };

        // Call updateLogofile when the store data changes
        const disposer = autorun(updateLogofile);

        // Cleanup function
        return () => {
            disposer(); // Dispose the autorun when the component unmounts
        };
    }, [specificationDetail]); // Run this effect whenever the store changes




    useEffect(() => {
        // Define a function to update logofile based on the store data
        console.log("image_arrayyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy",image_array);
        const updateLogofile = () => {
            if (image_array && image_array.length > 0) {
                // Set logofile state with default images
                setlogofile(image_array);
            }
        };

        // Call updateLogofile when the store data changes
        const disposer = autorun(updateLogofile);

        // Cleanup function
        return () => {
            disposer(); // Dispose the autorun when the component unmounts
        };
    }, [image_array]); // Run this effect whenever the store changes


    useEffect(() => {
        // Define a function to update logofile based on the store data
        const updateLogofile = () => {
            console.log("frammmmmmmmmmmmmmmmmmmmmmmmmmmmmeeeeeeeeeeee",frame_image);
            if (frame_image && frame_image.length > 0) {
                // Set logofile state with default images
                setlogoFramefile(frame_image);
            }
        };

        // Call updateLogofile when the store data changes
        const disposer = autorun(updateLogofile);

        // Cleanup function
        return () => {
            disposer(); // Dispose the autorun when the component unmounts
        };
    }, [frame_image]); // Run this effect whenever the store changes


    const [selectedValues, setSelectedValues] = useState(Array(logofile?.length).fill(''));

    useEffect(() => {
        // Define a function to update logofile based on the store data

        const updateLogofile = () => {
            if (optionDetail && optionDetail.length > 0) {
                // Set logofile state with default images
                setSelectedValues(optionDetail);
            }
        };

        // Call updateLogofile when the store data changes
        const disposer = autorun(updateLogofile);

        // Cleanup function
        return () => {
            disposer(); // Dispose the autorun when the component unmounts
        };
    }, [optionDetail]); // Run this effect whenever the store changes

    // const handleSelectChange = (index, value) => {
    //   // Update the selected value for the specific select field
    //   const newSelectedValues = [...selectedValues];
    //   newSelectedValues[index] = value;
    //   setSelectedValues(newSelectedValues);
    // };
    /////////////////////////////////////
    const [inputFields, setInputFields] = useState([


        [
            {
                option_id: '',
                option_value_id: '',
                option_name: "",
                option_value_name: '',
                pricetype: '',
                type: '',
                available_options: [],
                costing: [
                    { quantity: '', price: '' } // Initial QtyPrice array for each data object
                ]
            }
        ]

    ]);


    const [option_combination, setoption_combination] = useState([


        [
            {
                option_id: '',
                option_value_id: '',
                optionvaluename: '',
                pricetype: '',
                type: '',
                option_set: [
                    { option_id: '', option_value_id: '' } // Initial QtyPrice array for each data object
                ]
            }
        ]
    ]);
    const [filteredItemsList, setFilteredItemsList] = useState(inputFields.map(() => []));
    const [frameDescriptions, setFrameDescriptions] = useState([]);
    const [selectedPreviousDropdowns, setSelectedPreviousDropdowns] = useState([]);
    const [hideShowStates, setHideShowStates] = useState(inputFields.map(() => false));
    const [isOpenStates, setIsOpenStates] = useState(inputFields.map(() => false));
    const [isShowList, setisShowList] = useState(inputFields.map(() => false));
    const [categoryvalue, setcategoryvalue] = useState(inputFields.map(() => "Select option..."));
    const [selectedId, setSelectedId] = useState(null);
    const [productValuesList, setProductValuesList] = useState([]);
    const [unit_type_id, getunit_type_id] = useState(inputFields.map(() => []));
    const [selectedOptionsList, setSelectedOptionsList] = useState(inputFields.map(() => []));
    const [newFields, setNewFields] = useState([]);
    const [newComboFields, setNewComboFields] = useState([]);
    const filteredUnitDetails = UnitDetails.filter(item =>
        productValueid.some(key => key.unit_type_id == item.unit_type_id)
    );

    const handleAddFields = () => {
        // Retrieve the entire costing array from the first field if inputFields exists and has a length greater than 0
        const existingQuantities = inputFields?.length > 0
            ? inputFields[0][0]?.costing.map(cost => cost.quantity)
            : [];

        // Create a new field object with the quantity values from the existing costing array
        const newField = [
            {
                option_id: '',
                option_value_id: '',
                material_consume: '',
                material_consume_unit: '',
                option_name: '',
                unit_type_id: '',
                unit_name: '',
                option_value_name: '',
                available_options: [],
                costing: existingQuantities.length > 0
                    ? existingQuantities.map(quantity => ({ quantity, price: '' }))
                    : [{ quantity: '', price: '' }]
            }
        ];

        // Track the index of the newly added field
        setNewFields([...newFields, inputFields.length]);
        console.log("inputttttttttttttttttttttttttttttttttt", inputFields);
        // Add the new field to the inputFields array
        setInputFields(prevInputFields => [...prevInputFields, newField]);

        // If inputFields has only one field, add the newField to QtyPrice
        if (inputFields?.length == 1) {
            setQtyPrice(prevQtyPrice => [...prevQtyPrice, newField]);
        }

        // Logging for debugging
        console.log("insideeeeeAdddFunctionnnnnnnn", inputFields[0][0]?.costing);

        // Update UI-related states
        setHideShowStates(prevHideShowStates => [...prevHideShowStates, false]);
        setIsOpenStates(prevIsOpenStates => [...prevIsOpenStates, false]);
        setisShowList(prevIsShowList => [...prevIsShowList, false]);
        setcategoryvalue(prevCategoryValue => [...prevCategoryValue, "Select option..."]);
        setSelectedOptionsList(prevSelectedOptionsList => [...prevSelectedOptionsList, []]);
        setInitiallySelectedOptions(prevInitiallySelectedOptions => [...prevInitiallySelectedOptions, false]);
    };

    const checkEqualQuantity = () => {
        const newInputFields = [...inputFields];

        // Extract the total quantity value and array count of the 0th index
        const baseQuantity = newInputFields[0]?.reduce((acc, curr) => {
            if (curr.costing) {
                // Iterate over each costing item and sum up their quantities
                return {
                    totalQuantity: acc.totalQuantity + curr.costing.reduce((costingAcc, costingCurr) => {
                        return costingAcc + parseFloat(costingCurr.quantity || 0);
                    }, 0),
                    arrayCount: acc.arrayCount + curr.costing.length
                };
            }
            return acc;
        }, { totalQuantity: 0, arrayCount: 0 });
        setoptionlength(baseQuantity.arrayCount)
        // Check if any other index has the same total quantity and array count as the 0th index
        for (let i = 1; i < newInputFields.length; i++) {
            const currQuantity = newInputFields[i]?.reduce((acc, curr) => {
                if (curr.costing) {
                    // Iterate over each costing item and sum up their quantities
                    return {
                        totalQuantity: acc.totalQuantity + curr.costing.reduce((costingAcc, costingCurr) => {
                            return costingAcc + parseFloat(costingCurr.quantity || 0);
                        }, 0),
                        arrayCount: acc.arrayCount + curr.costing.length
                    };
                }
                return acc;
            }, { totalQuantity: 0, arrayCount: 0 });
            setotheroptionlength(currQuantity.arrayCount)



            if (currQuantity.totalQuantity === baseQuantity.totalQuantity && currQuantity.arrayCount === baseQuantity.arrayCount) {

                return;
            }
        }

    };



    // useEffect(() => {

    //     inputFields?.flat().map((key, index) => (
    //         getunit_type_idss(key.unit_type_id)
    //     ))

    // }, [inputFields])
    // useEffect(() => {


    //     getunit_type_idss(productDetail.unit_type_id)


    // }, [productDetail])


    const handleChangeQtyPriceInputOn0th = (options, event, parentIndex, index, indx) => {
        const { name, value } = event?.target;


        const newInputFields = [...inputFields];

        // Check if the parentIndex is valid and if the data property exists
        if (parentIndex >= 0 && parentIndex < newInputFields.length && newInputFields[parentIndex]) {
            const newData = [...newInputFields[parentIndex]];

            if (index === 0 && name === 'quantity') {
                // Update quantity for the specific index in the costing array for all entries
                newInputFields.forEach((parentData) => {
                    parentData.forEach((item) => {
                        if (item.costing && item.costing[indx]) {
                            item.costing[indx][name] = value;
                        }
                    });
                });
            } else {
                // Update the specific quantity or price based on the 'name' parameter for the current index
                if (!newData[index].costing) {
                    newData[index].costing = []; // Initialize costing array if it doesn't exist
                }
                if (!newData[index].costing[indx]) {
                    newData[index].costing[indx] = {}; // Initialize object if it doesn't exist
                }
                newData[index].costing[indx][name] = value;
            }

            // Update the data array in the newInputFields
            newInputFields[parentIndex] = newData;

            // Update the state with the modified inputFields
            setInputFields(newInputFields);
            setQtyPrice(newInputFields);

            // Call checkEqualQuantity to perform any necessary checks
            checkEqualQuantity();
        } else {
            console.error(`Invalid parentIndex: ${parentIndex}`);
        }
    };

    const handleChangeOptionInput = (options, event, parentIndex, index, indx) => {


        const newInputFields = [...combinationFields];

        // Check if the parentIndex is valid and if the data property exists
        if (parentIndex >= 0 && parentIndex < newInputFields.length && newInputFields[parentIndex]) {
            const newData = [...newInputFields[parentIndex]];
            if (options === "option_value_id") {
                // If event is an array, take the first element's ID, else take the ID directly
                const valueId = Array.isArray(event) ? event[0].id : event.id;

                // Update the option_value_id property with the single ID
                newData[index].option_set[indx][options] = valueId;
            } else {
                // Update the option_id property
                newData[indx].option_set[indx][options] = event.id;

            }

            // Update the data array in the newInputFields
            newInputFields[parentIndex] = newData;

            // Update the category value state
            setcategoryCombinationvalue(prevCategoryValue => {
                const newCategoryValue = [...prevCategoryValue];
                newCategoryValue[indx] = event.option_name;
                return newCategoryValue;
            });
            setcategoryCombination_Id(prevCategoryValue => {
                const newCategoryValue = [...prevCategoryValue];
                newCategoryValue[indx] = event.id;
                return newCategoryValue;
            });

            // Update the state with the modified inputFields
            setoption_combination(newInputFields);
            setcombinationFields(newInputFields);
        } else {
            console.error(`Invalid parentIndex: ${parentIndex}`);
        }
    };
    const transformedData = combinationFields.map(item => {
        // Check if option_set exists and is an array
        console.log("transsssssssssssssssssssssssssssssssssss", item);
        if (Array.isArray(item.option_set)) {
            // Transform the option_set array into an object
            const transformedOptionSet = item.option_set.reduce((acc, option) => {
                acc[option.option_id] = option.option_value_id;
                return acc;
            }, {});

            // Return the modified object with transformed option_set and other properties
            return {
                stock: item.stock || "",
                price: item.price || "",
                material_consume: item.material_consume || "",
                material_consume_unit: item.material_consume_unit || "",
                option_set: transformedOptionSet // Set option_set as an object
            };
        } else {
            // If option_set doesn't exist or is not an array, return the original item
            return item;
        }
    });



    const handleSelect_zero = (item, indexs, index, indx, option_id) => {
        const newSelectedOptionsList = [...selectedOptionsList];
        const newSelectedOptionsListID = [...selectedOptionsList];

        // Replace the selected option instead of adding it
        newSelectedOptionsListID[indexs] = [item];
        newSelectedOptionsList[indexs] = [item?.option_value_name];


        const newComboSelectedOptionsList_zero = [...ComboselectedOptionsList_zero];
        const newComboSelectedOptionsListID_zero = [...ComboselectedOptionsList_zero];

        newComboSelectedOptionsListID_zero[indx] = [item];
        newComboSelectedOptionsList_zero[indx] = [item?.option_value_name];


        // Update combinationFields
        const newCombinationFields = [...combinationFields];

        // Check if the option_id exists in option_set
        const optionSetIndex = newCombinationFields[indexs].option_set.findIndex(
            (option) => option.option_id == option_id
        );

        if (optionSetIndex != -1) {
            // Update the existing option
            newCombinationFields[indexs].option_set[optionSetIndex] = {
                option_id: option_id,
                option_value_id: item.option_value_id
            };
        } else {
            // Add a new option
            newCombinationFields[indexs].option_set = [
                ...newCombinationFields[indexs].option_set,
                { option_id: option_id, option_value_id: item.option_value_id }
            ];
        }

        setcombinationFields(newCombinationFields);

        if (indexs === 0) {
            setSelectedOptions([item]);
        }

        if (option === 0) {
            setComboSelectedOptionsInPreviousDropdowns((prevSelectedOptions) => {
                const updatedOptions = [...prevSelectedOptions];
                updatedOptions[indx] = item?.option_value_name;
                return updatedOptions;
            });
            // handleChangeCombinationInputOn0th("option_value_id", "optionlists_0", newSelectedOptionsListID[indexs], indexs, index, indx);
        } else {
            setSelectedOptionsInPreviousDropdowns((prevSelectedOptions) => {
                const updatedOptions = [...prevSelectedOptions];
                updatedOptions[indexs] = item?.option_value_name;
                return updatedOptions;
            });
            // setSelectedOptionsList(newSelectedOptionsList);

            // handleChangeInput("option_value_id", newSelectedOptionsListID[indexs], indexs);
        }

        setComboSelectedOptionsList_zero(newComboSelectedOptionsList_zero);
        setSearchQuery('');

        const availableOptions = ProductValues.filter(
            (item) => !newSelectedOptionsList.flat().includes(item?.option_value_name)
        );

        const newFilteredItemsList = [...filteredItemsList];
        newFilteredItemsList[indexs] = availableOptions;
        setFilteredItemsList(newFilteredItemsList);

        const newComboFilteredItemsList = [...CombofilteredItemsList];
        newComboFilteredItemsList[indx] = availableOptions;
        setComboFilteredItemsList(newComboFilteredItemsList);

        const newComboFilteredItemsList_zero = [...CombofilteredItemsList_zero];
        newComboFilteredItemsList_zero[index] = availableOptions;
        setComboFilteredItemsList_zero(newComboFilteredItemsList_zero);

        const newIsShowList = [...isShowList];
        newIsShowList[indexs] = false;
        setisShowList(newIsShowList);

        const newComboIsShowList_zero = [...isCombinationShowList_Zero];
        newComboIsShowList_zero[index] = false;
        setisisCombinationShowList_Zero(newComboIsShowList_zero);


        setShowList(false);
    };


    const handleRemoveOptionFields = (qtyPriceIndex) => {
        // Iterate over each parent index

        const newInputFields = combinationFields.map((parent, parentIndex) => {
            // Check if the data property exists in the element at the specified parentIndex
            if (parent) {
                // Check if the QtyPrice array exists in the data object at the specified parentIndex



                if (parent[0]?.option_set) {
                    // Remove the QtyPrice object at the specified index
                    parent[0].option_set.splice(qtyPriceIndex, 1);
                } else {
                    console.error(`QtyPrice array does not exist at index ${qtyPriceIndex} of parent index ${parentIndex}`);
                }
            } else {
                console.error(`Data property does not exist at index ${parentIndex}`);
            }
            return parent; // Return the modified parent object
        });

        // Update the inputFields state with the modified array
        setoption_combination(newInputFields);
        setcombinationFields(newInputFields);
    };
    const handleRemoveFields = (index) => {
        const newInputFields = [...inputFields];
        const newHideShowStates = [...hideShowStates];
        const newIsOpenStates = [...isOpenStates];
        const newIsOpenstates = [...isShowList];
        const newIsCategorystates = [...categoryvalue];
        const newSelectedOptionsList = [...selectedOptionsList];
        newInputFields.splice(index, 1);
        newHideShowStates.splice(index, 1);
        newIsOpenStates.splice(index, 1);
        newSelectedOptionsList.splice(index, 1);
        newIsCategorystates.splice(index, 1);
        setInputFields(newInputFields);
        setHideShowStates(newHideShowStates);
        setIsOpenStates(newIsOpenStates);
        setisShowList(newIsOpenstates);
        setcategoryvalue(newIsCategorystates);
        setSelectedOptionsList(newSelectedOptionsList);
    };


    useEffect(() => {
        // Define a function to update logofile based on the store data
        const updateLogofile = () => {
            if (optionslist && optionslist.length > 0) {
                // Set logofile state with default images
                setInputFields(optionslist);
                setQtyPrice(optionslist);

            }
        };

        // Call updateLogofile when the store data changes
        const disposer = autorun(updateLogofile);

        // Cleanup function
        return () => {
            disposer(); // Dispose the autorun when the component unmounts
        };
    }, [optionslist]); // Run this effect whenever the store changes

    useEffect(() => {
        // Function to transform combined_option_data
        const transformCombinedOptionData = (data) => {
            return data.map(item => ({
                material_consume: item.material_consume || "",
                material_consume_unit: item.material_consume_unit || "",
                options_detail: item.options_detail || [],
                option_set: Object.entries(item.option_set).map(([option_id, option_value_id]) => ({
                    option_id,
                    option_value_id
                })),
                stock: item.stock,
                price: item.price,

            }));
        };

        // Define a function to update logofile based on the store data
        const updateLogofile = () => {
            if (combined_option_data && combined_option_data.length > 0) {
                // Transform combined_option_data before setting it
                const transformedData = transformCombinedOptionData(combined_option_data);
                setcombinationFields(transformedData);
                setoption_combination(transformedData);
            }
        };

        // Call updateLogofile when the store data changes
        const disposer = autorun(updateLogofile);

        // Cleanup function
        return () => {
            disposer(); // Dispose the autorun when the component unmounts
        };
    }, [combined_option_data]); // Run this effect whenever the store changes




    const handleChangeInput = (options, event, index) => {
        setQuery(""); // Clear query
        setIsOpen(isOpen => !isOpen); // Toggle isOpen state
        const newInputFields = [...inputFields]; // Copy inputFields state

        if (!newInputFields[index]) {
            // Handle the case where the array element at index does not exist
            return;
        }

        if (options === "option_id") {
            const values = Array.isArray(event) ? event : [event];
            newInputFields[index] = values.map(value => ({
                ...newInputFields[index][0], // Preserve other fields in data array
                option_id: value.id
            }));

            if (index === 0) {
                setisparentID(event.id)
            }
            setcategoryvalue(prevCategoryValue => {
                const newCategoryValue = [...prevCategoryValue];
                newCategoryValue[index] = event.option_name;
                return newCategoryValue;
            });

        } else if (options === "option_value_id") {
            const values = Array.isArray(event) ? event : [event];
            if (values.length > 0) {
                newInputFields[index] = values.map(value => ({
                    ...newInputFields[index][0], // Preserve other fields in data array
                    option_value_id: value.option_value_id || value.id,
                    optionvaluename: value.option_value_name,
                }));
            } else {
                newInputFields[index] = [
                    {
                        option_id: '',
                        option_value_id: '',
                        optionvaluename: '',
                        pricetype: '',
                        type: '',
                        costing: [
                            { quantity: '', price: '' } // Initial QtyPrice array for each data object
                        ]
                    }
                ];
            }

        } else if (
            options === "pricetype" ||
            options === "material_consume_unit" ||
            options === "material_consume"
        ) {
            // Update other fields in the "data" array
            newInputFields[index].forEach(item => {
                item[options] = event.target.value;
            });

            // Set other state variables if needed
            if (options === "price") {
                setprice(event.target.value);
            }

            if (options === "quantity") {
                setquantity(event.target.value);
            }

            if (options === "material_consume_unit") {
                setprice_type(event.target.value);
            }

            if (options === "material_consume") {
                setmaterial_consume(event.target.value);
            }
        }

        setInputFields(newInputFields);
        if (!newFields) {

            setQtyPrice(newInputFields);

        }



    };


    console.log("checkerrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr", newFields, newComboFields);


    const handleDescriptionChange = (index, newContent, frame_image, type , price) => {
        setFrameDescriptions(prevDescriptions => {
            const updatedDescriptions = [...prevDescriptions];
              console.log("updatedDescriptions", updatedDescriptions);
            // If it's not a new description and the frame exists
            if (updatedDescriptions[index] && frame_image !== "new_desc" && frame_image !== undefined) {
                // Update the description or price based on the type
                if (type === 'desc') {
                    updatedDescriptions[index].desc = newContent.replace(/<[^>]*>/g, '');
                } else if (type === 'price') {
                    updatedDescriptions[index].price = newContent;
                }
            }
            
            else {
                // If it doesn't exist or it's a new description, handle accordingly
                if (frame_image === "new_desc") {
                    console.log("Adding new description for Frame", index);
                    setNewFrameDescIndex(frame_name_array.length);

                    if (!updatedDescriptions[index]) {
                        updatedDescriptions[index] = { frame: `frame_${index}`, desc: '', price: '' };
                    }

                    if (type === 'desc') {
                        updatedDescriptions[index].desc = newContent.replace(/<[^>]*>/g, '');
                    } else if (type === 'price') {
                        updatedDescriptions[index].price = newContent;
                    }
                } else {
                    // Handle existing frame with a valid `frame_image`
                    console.log("typeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",type);
                    if (!updatedDescriptions[index]) {
                        updatedDescriptions[index] = { frame: frame_image, desc: '', price: '' };
                    }

             
                        updatedDescriptions[index].desc = newContent.replace(/<[^>]*>/g, '');
                
                        updatedDescriptions[index].price = price;
                    
                }
            }

            return updatedDescriptions;
        });
    };


    /////////////////////////////////////
    // Add refs for other tabs as needed
    const handleTabClick = (tab) => {
        setActiveTab(tab);
        // Scroll to the corresponding div based on the tab
        switch (tab) {
            case 'basic':
                scrollToRef(basicDetailsRef);
                break;
            case 'Variants':
                scrollToRef(variantsRef);
                break;
            case 'Media':
                scrollToRef(media);
                break;
            case 'Specifications':
                scrollToRef(specifications);
                break;
            // Add cases for other tabs
            default:
                break;
        }
    };

    // const handleAdd = () => {
    //     // Add a new item to the list
    //     setAddedItems([...addedItems, { /* Whatever data you want to add */ }]);
    // };

    // const handleDelete = (index) => {
    //     // Remove the item at the specified index
    //     const updatedItems = [...addedItems];
    //     updatedItems.splice(index, 1);
    //     setAddedItems(updatedItems);
    // };




    // const [crop, setCrop] = useState({ aspect: 16 / 9, dimensions: { width: 480, height: 270 } });
    // const { register, handleSubmit, errors } = useForm();


    useEffect(() => {
        if (adminAuthApproved) {
            getToken(localStorage.getItem("Admintoken"));
        }

    }, [adminAuthApproved]);
    const navigate = useNavigate();


    useEffect(() => {
        if (saved) {
            navigate(-1);
        }
    }, [saved]);

    useEffect(() => {

        inputFields?.flat().map((key, index) => (
            getunit_type_idss(key.unit_type_id)
        ))

    }, [inputFields])
    useEffect(() => {


        getunit_type_idss(productDetail.unit_type_id)


    }, [productDetail])

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY || document.documentElement.scrollTop;

            // Adjust this value to set the scroll position at which the fixed element becomes relative
            const togglePositionHeight = 200;

            // Toggle between fixed and relative based on scroll position
            setIsFixed(scrollPosition <= togglePositionHeight);
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const onChange = useCallback(
        (newContent) => {
            // const strippedContent = newContent.replace(/<[^>]*>/g, '');
            getcontent(newContent);
        },
        [content]
    );

    console.log("transformeddddddDataaaaaaaaaaaa", transformedData)
    const handleSubmit = (e) => {
        e.preventDefault();
        UpdateProductCatalog(inputFields, specificationsList, transformedData, processedImages, id, processedFrameImages, frameDescriptions, NewFrameDescIndex);
        // Perform API call or any other logic using MobX store data
        // Example: addCity();
    };

    const getBase64 = file => {
        return new Promise(resolve => {
            const FileArray = []
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
        });
    };


    console.log("====================>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", frameDescriptions);
    const DemoShops = [{ name: "Raj Shop" }]

    const handleFocus = (indexs, index) => {
        // Set the list visibility to true when the input is focused
        setListVisibility(true);
        setShowList(true);
        // Exclude options that have already been selected in previous dropdowns
        const availableOptions = ProductValues.filter(item => !selectedOptionsInPreviousDropdowns.includes(item?.option_value_name));
        const newFilteredItemsList = [...filteredItemsList];
        newFilteredItemsList[indexs] = availableOptions;
        setFilteredItemsList(newFilteredItemsList);

        newFilteredItemsList[index] = availableOptions;



        const newComboFilteredItemsList = [...CombofilteredItemsList];
        newComboFilteredItemsList[indexs] = availableOptions;
        setComboFilteredItemsList(newComboFilteredItemsList);

        const newIsShowList = [...isShowList];
        newIsShowList[indexs] = true; // Set to true when focused
        setisShowList(newIsShowList);


        const newIsComboShowList = [...isCombinationShowList];
        newIsComboShowList[index] = true; // Set to true when focused
        setisCombinationShowList(newIsComboShowList);




        // Initial filtering to show the entire list
    };



    const handleSearch = (query, indexs, index) => {
        // Get the list of removed options for this dropdown
        const removedOptions = selectedOptionsList[indexs]._removedOptions || [];

        const removedComboOptions = ComboselectedOptionsList[indexs]._removedOptions || [];


        // Filter the available options based on the search query
        const filtered = ProductValues.filter((item) =>
            item?.option_value_name.toLowerCase().includes(query?.toLowerCase())
        );

        // Include removed options in the filtered list
        const filteredWithRemoved = [...removedOptions, ...filtered];
        const CombofilteredWithRemoved = [...removedComboOptions, ...filtered];

        const availableOptions = ProductValues.filter(item => !selectedOptionsInPreviousDropdowns.includes(item?.option_value_name));
        const newFilteredItemsList = [...filteredItemsList];
        const newComboFilteredItemsList = [...filteredItemsList];


        newFilteredItemsList[indexs] = filteredWithRemoved;
        newComboFilteredItemsList[indexs] = CombofilteredWithRemoved;

        setFilteredItemsList(newFilteredItemsList);
        setComboFilteredItemsList(newComboFilteredItemsList)


        const newComboFilteredItemsList_zero = [...filteredItemsList];


        newComboFilteredItemsList_zero[index] = CombofilteredWithRemoved;
        setComboFilteredItemsList_zero(newComboFilteredItemsList_zero)


        setFilteredItems(filteredWithRemoved);
        setSearchQuery(query);
        setShowList(true);
        // Show the list
        const newIsShowList = [...isShowList];
        newIsShowList[indexs] = true;
        setisShowList(newIsShowList);

        const newIsComboShowList = [...isCombinationShowList];
        newIsComboShowList[indexs] = true; // Set to true when focused
        setisCombinationShowList(newIsComboShowList);
    };



    const handleBlur = (indexs) => {
        // Set the list visibility to false when the input loses focus
        setListVisibility(false);
        // setFilteredItems(ProductValues.map(item => item.option_value_name));
        // setShowList(true);
        const newIsShowList = [...isShowList];
        newIsShowList[indexs] = true; // Set to false when blurred
        setisShowList(newIsShowList);
    };

    const handleChangeQtyPriceInput = (options, event, parentIndex, index, indx) => {
        const { name, value } = options === "price" ? event?.target : event;

        const newInputFields = [...inputFields];

        // Validate parentIndex and ensure the data structure is intact
        if (parentIndex >= 0 && parentIndex < newInputFields.length && newInputFields[parentIndex]) {
            const newData = [...newInputFields[parentIndex]];

            // Ensure costing array exists and initialize if necessary
            if (!newData[index]?.costing) {
                newData[index].costing = [];
            }

            // Ensure costing object exists at indx and initialize if necessary
            if (!newData[index]?.costing[indx]) {
                newData[index].costing[indx] = {};
            }

            // Update quantity or price based on options
            if (options === 'quantity') {
                newData[index].costing[indx]["quantity"] = event;
            } else {
                newData[index].costing[indx][name] = value;
            }

            // Update parent index with modified data
            newInputFields[parentIndex] = newData;

            // Update state with modified inputFields and qtyPrice
            setInputFields(newInputFields);
            // setQtyPrice(newInputFields);

            // Additional logic if needed, e.g., validation
            checkEqualQuantity();
        } else {
            console.error(`Invalid parentIndex: ${parentIndex}`);
        }
    };
    const handleRemoveCombinationFields = (index) => {
        const newInputFields = [...combinationFields];

        const newHideShowStates = [...hideShowStates];
        const newIsOpenStates = [...isOpenStates];
        const newIsOpenstates = [...isShowList];
        const newIsCategorystates = [...categoryvalue];
        const newSelectedOptionsList = [...selectedOptionsList];

        newInputFields.splice(index, 1);
        newHideShowStates.splice(index, 1);
        newIsOpenStates.splice(index, 1);
        newSelectedOptionsList.splice(index, 1);
        newIsCategorystates.splice(index, 1);


        setcombinationFields(newInputFields);
        setoption_combination(newInputFields)
        setHideShowStates(newHideShowStates);
        setIsOpenStates(newIsOpenStates);
        setisShowList(newIsOpenstates);
        setcategoryCombinationvalue(newIsCategorystates);
        setcategoryCombination_Id(newIsCategorystates);

        setSelectedOptionsList(newSelectedOptionsList);
    };
    const handleAddCombinationFields = () => {


        const existingQuantities = combinationFields?.length > 0
            ? combinationFields[0].options_detail?.options_data?.map((values, indx) => (values))
            : [];
        console.log("combinationFieldssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss", existingQuantities);

        const Option_set = combinationFields?.length > 0
            ? combinationFields[0].option_set?.map((values, indx) => (values?.option_id))
            : [];
        console.log("combinationFieldssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss", existingQuantities);

        const newField = [
            {
                material_consume: "",
                material_consume_unit: "",
                options_detail: existingQuantities?.length > 0
                    ? existingQuantities.map(option_id => ({ option_name: option_id?.option_name, option_id: option_id?.option_id, option_value_id: '' }))
                    : [{ option_id: '', option_value_id: '' }],
                option_set: Option_set?.length > 0
                    ? Option_set.map(option_id => ({ option_id, option_value_id: '' }))
                    : [{ option_id: '', option_value_id: '' }]
            }
        ];

        const newFilteredItemsList = [...filteredItemsList];
        newFilteredItemsList.push([]);
        setcombinationFields(prevInputFields => [...prevInputFields, newField[0]]);

        setFilteredItemsList(newFilteredItemsList);
        setoption_combination([...option_combination, newField]);
        setNewComboFields([...newComboFields, combinationFields.length]);
        console.log("combooooooooooooooooooooooooooooooooo", newField);
        // setisCombinationShowList([...isCombinationShowList, false])
        setisisCombinationShowList_Zero([...isCombinationShowList_Zero, false])
        setComboSelectedOptionsList([...ComboselectedOptionsList, []]);
        setComboSelectedOptionsList_zero([...ComboselectedOptionsList_zero, []]);

        sethideShowCombinationStates([...hideShowCombinationStates, false]);
        setHideShowStates([...hideShowStates, false]);
        setIsOpenStates([...isOpenStates, false]);
        setisShowList([...isShowList, false]);
        setcategoryCombinationvalue([...categoryCombinationvalue, "Select option..."]);
        setcategoryCombination_Id([...categoryCombinationvalue_Id, "Select option..."]);
        getunit_type_id([...unit_type_id, []]);
        setSelectedOptionsList([...selectedOptionsList, []]);
        setInitiallySelectedOptions([...initiallySelectedOptions, false]); // Initialize for the new field
    };
    console.log("clickiiiiiiiiiiiingggggggggggggggggggggggggggggggg", combinationFields);


    const handleRemoveQtyPriceFields = (qtyPriceIndex) => {
        // Iterate over each parent index
        const newInputFields = inputFields.map((parent, parentIndex) => {
            // Check if the data property exists in the element at the specified parentIndex
            if (parent) {
                // Check if the QtyPrice array exists in the data object at the specified parentIndex



                if (parent[0]?.costing) {
                    // Remove the QtyPrice object at the specified index
                    parent[0].costing.splice(qtyPriceIndex, 1);
                } else {
                    console.error(`QtyPrice array does not exist at index ${qtyPriceIndex} of parent index ${parentIndex}`);
                }
            } else {
                console.error(`Data property does not exist at index ${parentIndex}`);
            }
            return parent; // Return the modified parent object
        });

        // Update the inputFields state with the modified array
        setInputFields(newInputFields);
        setQtyPrice(newInputFields); // Update the QtyPrice state
    };
    const handleAddQtyPriceFields = () => {
        const newInputFields = [...inputFields];

        // Loop through each element in the inputFields array
        newInputFields.forEach((data) => {
            // Find the object with the costing array
            const qtyPriceIndex = data.findIndex(item => item.costing);

            // If a costing array exists, push the new costing field to its costing array
            if (qtyPriceIndex !== -1) {
                data[qtyPriceIndex].costing.push({ quantity: '', price: '' });
            } else {
                // If no costing array exists, create a new one with the costing array
                data.push({ costing: [{ quantity: '', price: '' }] });
            }
        });

        // Update the inputFields state
        setQtyPrice(newInputFields);
        setInputFields(newInputFields);
        console.log("Updated inputFields with new quantity and price:", inputFields);
    };

    const handleAddOptionFields = (parentIndex) => {
        const newInputFields = [...combinationFields];
        setisisCombinationShowList_Zero([...isCombinationShowList_Zero, false]);

        // Check if the parentIndex is valid
        if (parentIndex >= 0 && parentIndex < newInputFields.length) {
            // Check if the data property exists in the element at the specified parentIndex
            if (newInputFields[parentIndex]) {
                // Get the data array of the specific parentIndex
                const data = newInputFields[parentIndex];
                // Find the object with QtyPrice array
                const qtyPriceIndex = data.findIndex(item => item.option_set);
                setisCombinationShowList([false]);
                setisisCombinationShowList_Zero([...isCombinationShowList_Zero, false]);
                setComboSelectedOptionsList([...ComboselectedOptionsList, []]);
                setComboSelectedOptionsList_zero([...ComboselectedOptionsList_zero, []]);

                const newFilteredItemsList = [...CombofilteredItemsList];
                const newFilteredItemsList_zero = [...CombofilteredItemsList_zero];
                newFilteredItemsList_zero.push([]);

                newFilteredItemsList.push([]);
                setComboFilteredItemsList(newFilteredItemsList);
                setComboFilteredItemsList_zero(newFilteredItemsList_zero);
                sethideShowCombinationStates([...hideShowCombinationStates, false]);
                setIsOpenCombinationStates([...isOpenCombinationStates, false]);
                setcategoryCombinationvalue([...categoryCombinationvalue, "Select option..."]);
                setcategoryCombination_Id([...categoryCombinationvalue_Id, "Select option..."]);

                // If a QtyPrice object exists, push the new QtyPrice field to its QtyPrice array
                if (qtyPriceIndex !== -1) {
                    data[qtyPriceIndex].option_set.push({ option_id: '', option_value_id: '' });
                } else {
                    // If no QtyPrice object exists, create a new one with the QtyPrice array
                    data.push({ option_set: [{ option_id: '', option_value_id: '' }] });
                }

                // Update the inputFields state
                setoption_combination(newInputFields);
                setcombinationFields(newInputFields);

            } else {
                console.error(`Data property does not exist at index ${parentIndex}`);
            }
        } else {
            console.error(`Invalid parentIndex: ${parentIndex}`);
        }
    };

    const handleFocus_zero = (indexs, index) => {
        // Set the list visibility to true when the input is focused
        setListVisibility(true);
        setShowList(true);
        // Exclude options that have already been selected in previous dropdowns
        const availableOptions = ProductValues.filter(item => !selectedOptionsInPreviousDropdowns.includes(item?.option_value_name));
        const newFilteredItemsList = [...filteredItemsList];
        newFilteredItemsList[indexs] = availableOptions;
        setFilteredItemsList(newFilteredItemsList);

        newFilteredItemsList[index] = availableOptions;
        const newComboFilteredItemsList_zero = [...CombofilteredItemsList_zero];
        newComboFilteredItemsList_zero[index] = availableOptions;
        setComboFilteredItemsList_zero(newComboFilteredItemsList_zero);


        const newIsShowList = [...isShowList];
        newIsShowList[indexs] = true; // Set to true when focused
        setisShowList(newIsShowList);


        const newIsComboShowList_zero = [...isCombinationShowList_Zero];
        newIsComboShowList_zero[index] = true; // Set to true when focused
        setisisCombinationShowList_Zero(newIsComboShowList_zero);



        // Initial filtering to show the entire list
    };

    const handleRemove_zero = (parentIndex, childIndex, comboIndex, index) => {
        const newSelectedOptionsList = [...selectedOptionsList];
        const newComboSelectedOptionsList = [...ComboselectedOptionsList_zero];


        // Remove the selected option from the list of selected options in previous dropdowns
        setSelectedOptionsInPreviousDropdowns(prevSelectedOptions => prevSelectedOptions.filter(option => option !== newComboSelectedOptionsList[comboIndex][childIndex]));
        newComboSelectedOptionsList[comboIndex].splice(childIndex, 1);

        setComboSelectedOptionsList_zero(newComboSelectedOptionsList);
        handleChangeCombinationInputOn0th("option_value_id", "optionlists_0", newComboSelectedOptionsList, parentIndex, index, comboIndex);
        // Here you can add further logic if needed, such as updating states or performing other actions

        // For example, if you want to update the input field value after removing the option
        // handleChangeCombinationInputOn0th("option_id", "optionlists_0", newComboSelectedOptionsList[comboIndex], parentIndex, index, comboIndex);
    };
    const handleRemove_combo = (parentIndex, childIndex, comboIndex, index) => {
        const newSelectedOptionsList = [...selectedOptionsList];
        const newComboSelectedOptionsList = [...ComboselectedOptionsList];


        // Remove the selected option from the list of selected options in previous dropdowns
        setSelectedOptionsInPreviousDropdowns(prevSelectedOptions => prevSelectedOptions.filter(option => option !== newComboSelectedOptionsList[comboIndex][childIndex]));
        newComboSelectedOptionsList[parentIndex].splice(childIndex, 1);

        setComboSelectedOptionsList(newComboSelectedOptionsList);
        handleChangeCombinationInput("option_value_id", "optionlists", newComboSelectedOptionsList, parentIndex, index, comboIndex);
        // Here you can add further logic if needed, such as updating states or performing other actions

        // For example, if you want to update the input field value after removing the option
        // handleChangeCombinationInputOn0th("option_id", "optionlists_0", newComboSelectedOptionsList[comboIndex], parentIndex, index, comboIndex);
    };

    const handleChangeCombinationInputOn0th = (options, check, event, parentIndex, index, indx) => {



        const newInputFields = [...combinationFields];
        // Check if the parentIndex is valid and if the data property exists
        if (parentIndex >= 0 && parentIndex < newInputFields.length && newInputFields[parentIndex]) {
            const newData = [...newInputFields[parentIndex]];

            // Ensure that QtyPrice array exists for the specific parentIndex and childIndex
            if (!newData[index].option_set) {
                newData[index].option_set = []; // Initialize option_set array if it doesn't exist
            }

            // Ensure that the new option_set array has an object at the specified index
            if (!newData[index].option_set[indx]) {
                newData[index].option_set[indx] = {}; // Initialize object if it doesn't exist
            }
            // Update the quantity or price based on the 'name' parameter
            if (options === "option_value_id") {
                // If event is an array, take the first element's ID, else take the ID directly
                if (event.length > 0) {
                    const valueId = Array.isArray(event) ? event[0].id : event?.id;

                    // Update the option_value_id property with the single ID
                    newData[index].option_set[indx][options] = valueId;
                }

            } else {
                // Update the option_id property
                if (check == "optionlists") {
                    newData[index].option_set[indx][options] = event

                }
                else {
                    newData[index].option_set[indx][options] = event.id
                    setcategoryCombinationvalue(prevCategoryValue => {
                        const newCategoryValue = [...prevCategoryValue];
                        newCategoryValue[indx] = event.option_name;
                        return newCategoryValue;
                    });
                    setcategoryCombination_Id(prevCategoryValue => {
                        const newCategoryValue = [...prevCategoryValue];
                        newCategoryValue[indx] = event.id;
                        return newCategoryValue;
                    })

                }
                ;

            }
            // Update the data array in the newInputFields
            newInputFields[parentIndex] = newData;

            // Call checkEqualQuantity to perform any necessary checks
            // checkEqualQuantity();
        } else {
            console.error(`Invalid parentIndex: ${parentIndex}`);
        }
    };
    const handleChangeCombinationInput = (options, event, index) => {
        setQuery(""); // Clear query
        setIsOpen(isOpen => !isOpen); // Toggle isOpen state

        // Make a deep copy of combinationFields to avoid direct state mutation
        const newInputFields = [...combinationFields];
        console.log("myindex", index);

        // Check if the object at index exists
        if (!newInputFields[index]) {
            // Handle the case where the array element at index does not exist
            return;
        }

        // Handle option_id change
        if (options === "option_id") {
            const values = Array.isArray(event) ? event : [event];
            newInputFields[index] = {
                ...newInputFields[index], // Preserve other fields in data object
                option_set: values.reduce((acc, value) => ({
                    ...acc,
                    [value.id]: newInputFields[index].option_set ? newInputFields[index].option_set[value.id] : null
                }), {})
            };

            if (index === 0) {
                setisparentID(event.id);
            }

            setcategoryvalue(prevCategoryValue => {
                const newCategoryValue = [...prevCategoryValue];
                newCategoryValue[index] = event.option_name;
                return newCategoryValue;
            });

            // Handle option_value_id change
        } else if (options === "option_value_id") {
            const values = Array.isArray(event) ? event : [event];
            if (values.length > 0) {
                newInputFields[index] = {
                    ...newInputFields[index], // Preserve other fields in data object
                    option_set: values.reduce((acc, value) => ({
                        ...acc,
                        [value.option_id]: value.id
                    }), {})
                };
            } else {
                // Reset option_set if no values are provided
                newInputFields[index] = {
                    ...newInputFields[index],
                    option_set: {}
                };
            }

            // Handle other fields
        } else if (
            options === "pricetype" ||
            options === "material_consume_unit" ||
            options === "material_consume" ||
            options === "price" ||
            options === "stock"
        ) {
            newInputFields[index] = {
                ...newInputFields[index],
                [options]: event.target.value
            };

            // Set other state variables if needed
            if (options === "price") {
                setprice(event.target.value);
            }
            if (options === "quantity") {
                setquantity(event.target.value);
            }
            if (options === "material_consume_unit") {
                setprice_type(event.target.value);
            }
            if (options === "material_consume") {
                setmaterial_consume(event.target.value);
            }
        }

        // Update the state with the modified newInputFields array
        setoption_combination(newInputFields);
        setcombinationFields(newInputFields);
    };

    const handleSelect = (item, indexs, index, indx) => {
        const newSelectedOptionsList = [...selectedOptionsList];
        const newSelectedOptionsListID = [...selectedOptionsList];
        const newunit_type_id = [...unit_type_id];
        const newIsShowList = [...isShowList];
        newIsShowList[indexs] = false; // Set to true when focused
        setisShowList(newIsShowList);



        // Replace the selected option instead of adding it
        newSelectedOptionsListID[indexs] = [item];
        newunit_type_id[indexs] = [item?.unit_type_id]
        newSelectedOptionsList[indexs] = [item?.option_value_name];
        const newComboSelectedOptionsList = [...ComboselectedOptionsList];
        const newComboSelectedOptionsList_Index = [...ComboselectedOptionsList_Index];

        // Replace the selected option instead of adding it
        newComboSelectedOptionsList[indexs] = [...(newComboSelectedOptionsList[indexs] || [])];
        newComboSelectedOptionsList[indexs][indx] = item?.option_value_name;
        newComboSelectedOptionsList_Index[indexs] = [...(newComboSelectedOptionsList_Index[indexs] || [])];
        newComboSelectedOptionsList_Index[indexs][indx] = item?.option_value_name;

        // Update the selected options for the specific dropdown
        if (indexs === 0) {
            setSelectedOptions([item]);
        } else {
            setSelectedOptionsInPreviousDropdowns(prevSelectedOptions => {
                const updatedOptions = [...prevSelectedOptions];
                updatedOptions[indexs] = item?.option_value_name;
                return updatedOptions;
            });
        }

        // Update combo selected options lists
        setComboSelectedOptionsList(newComboSelectedOptionsList);
        setComboselectedOptionsList_Index(newComboSelectedOptionsList_Index);
        if (indexs === 0) {
            setSelectedOptions([item]);
        }

        if (options_in_combination == 1) {

            setComboSelectedOptionsInPreviousDropdowns(prevSelectedOptions => {
                const updatedOptions = [...prevSelectedOptions];
                updatedOptions[indx] = item?.option_value_name;
                return updatedOptions;
            });
            // handleChangeCombinationInputOn0th("option_value_id", "optionlists_0", newSelectedOptionsListID[indexs], indexs, index, indx);




        }
        else {
            setSelectedOptionsInPreviousDropdowns(prevSelectedOptions => {
                const updatedOptions = [...prevSelectedOptions];
                updatedOptions[indexs] = item?.option_value_name;
                return updatedOptions;
            });
            setSelectedOptionsList(newSelectedOptionsList);
            getunit_type_id(newunit_type_id);
            handleChangeInput("getunit_type_id", newunit_type_id[indexs], indexs);
            handleChangeInput("option_value_id", newSelectedOptionsListID[indexs], indexs);
        }

        // Reset search query and hide dropdown lists
        setSearchQuery('');
        setShowList(false);
    };

    const handleRemove = (indx, optionindex, indexs, index,) => {
        const newSelectedOptionsList = [...selectedOptionsList];
        const newCombinationSelectedOptionsList = [...ComboselectedOptionsList];
        const newCombinationSelectedOptionsList_zero = [...ComboselectedOptionsList_zero];

        newCombinationSelectedOptionsList_zero[indexs] = [...(newCombinationSelectedOptionsList_zero[indexs] || [])];

        const removedOption = newSelectedOptionsList[indx][optionindex];


        // Remove the selected option from the list of selected options in previous dropdowns
        setSelectedOptionsInPreviousDropdowns(prevSelectedOptions => prevSelectedOptions.filter(option => option !== removedOption));
        newSelectedOptionsList[indx].splice(optionindex, 1);

        setSelectedOptionsList(newSelectedOptionsList);
        if (options_in_combination == 1) {
            const ComboremovedOption = newCombinationSelectedOptionsList[indx][optionindex];

            newCombinationSelectedOptionsList[indx].splice(optionindex, 1);
            newCombinationSelectedOptionsList_zero[indexs].splice(optionindex, 1);



            newCombinationSelectedOptionsList[indx].splice(optionindex, 1);
            setComboSelectedOptionsList(newCombinationSelectedOptionsList);
            setComboSelectedOptionsList_zero(newCombinationSelectedOptionsList_zero);


            setComboSelectedOptionsInPreviousDropdowns(prevSelectedOptions => prevSelectedOptions.filter(option => option !== ComboremovedOption));

            handleChangeCombinationInputOn0th("option_value_id", "optionlists_0", newCombinationSelectedOptionsList_zero, indexs, index, indx);


        }

        handleChangeInput("option_value_id", newSelectedOptionsList[indx], indx);
    };
    // const handleSelectChange = async (index, value) => {
    //     const newSelectedValues = [...selectedValues];

    //     // Convert the image to base64
    //     if (logofile[index]) {
    //       try {
    //         const base64Image = await getBase64(logofile[index].originFileObj);
    //         newSelectedValues[index] = { value_id: value, option_id: selectedOptions[index].id, data: [base64Image] };
    //         setSelectedValues(newSelectedValues);
    //       } catch (error) {
    //         console.error('Error converting image to base64:', error);
    //       }
    //     }
    //   };

    const [processedImages, setProcessedImages] = useState([]);
    const [processedFrameImages, setProcessedFrameImages] = useState([]);

    const [processedLoader, setProcessedLoader] = useState(false);
    const [processedFrameLoader, setProcessedFrameLoader] = useState(false);


    const getLogoSrcFromFile = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
        });
    };

    const dynamicUnits = (event, index) => {
        const newIsUnit = [...unit_type_id];
        newIsUnit[index] = event;
        getunit_type_id(newIsUnit);
    };


    console.log("commmmmmmmmmmbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbinationnnnnnnnnnnnnnnnnnnn", combinationFields);


    const LogChange = debounce(async ({ fileList: newFileList }) => {
        setProcessedLoader(false);
        const byteArrays = [];

        // Use a for loop instead of forEach to handle async operations
        for (let i = 0; i < newFileList.length; i++) {
            const file = newFileList[i];
            if (file.originFileObj) {
                // Ensure getBase64 returns a Promise
                const base64Image = await getBase64(file.originFileObj);
                byteArrays.push(base64Image);
            }
        }

        // Set the state with the entire array
        setProcessedImages(byteArrays);

        // Call getlogoimage after setting state
        getlogoimage(byteArrays);

        // Set logofile with updated status
        setlogofile(newFileList.map(file => ({ ...file, status: 'done' })));
    }, 3000); // 300 milliseconds debounce time




    const LogFrameChange = debounce(async ({ fileList: newFileList }) => {
        setProcessedFrameLoader(false);
        const byteArrays = [];

        // Use a for loop instead of forEach to handle async operations
        for (let i = 0; i < newFileList.length; i++) {
            const file = newFileList[i];
            if (file.originFileObj) {
                // Ensure getBase64 returns a Promise
                const base64Image = await getBase64(file.originFileObj);
                byteArrays.push(base64Image);
            }
        }

        // Set the state with the entire array
        setProcessedFrameImages(byteArrays);

        // Call getlogoimage after setting state
        getlogoimage(byteArrays);

        // Set logofile with updated status
        setlogoFramefile(newFileList.map(file => ({ ...file, status: 'done' })));
    }, 3000); // 300 milliseconds debounce time








    const handleImageDelete = (file) => {
        const indexToDelete = logofile?.findIndex((f) => f.uid === file.uid);
        if (indexToDelete !== -1) {
            // Extract the uploaded_file value from the deleted file
            const deletedImageName = image_name_array[indexToDelete];
            // Remove the deleted file from selectedValues
            const newSelectedValues = selectedValues.filter((_, index) => index !== indexToDelete);
            setSelectedValues(newSelectedValues);

            // Pass the value based on is_variant_image key

            deleteImagefunction(deletedImageName, id);

            // Also, update the logofile state to remove the deleted file
            const newLogofile = [...logofile];
            newLogofile.splice(indexToDelete, 1);
            setlogofile(newLogofile);
        }
    };

    const handleFrameImageDelete = (file) => {
        const indexToDelete = logoFramefile?.findIndex((f) => f.uid === file.uid);
        if (indexToDelete !== -1) {
            // Extract the uploaded_file value from the deleted file
            const deletedImageName = frame_name_array[indexToDelete];
            // Remove the deleted file from selectedValues
            const newSelectedValues = selectedValues.filter((_, index) => index !== indexToDelete);
            setSelectedValues(newSelectedValues);

            // Pass the value based on is_variant_image key

            deleteImagefunction(deletedImageName?.frame, id);

            // Also, update the logofile state to remove the deleted file
            const newLogofile = [...logoFramefile];
            newLogofile.splice(indexToDelete, 1);
            setlogoFramefile(newLogofile);
        }
    };


    const handleSelectChange = async (index, value, selectedData, image) => {
        const newSelectedValues = [...selectedValues];
        const id = selectedData.option_value_id;
        const type = selectedData.type;
        const quantity = selectedData.quantity;
        const price = selectedData.price;
        const pricetype = selectedData.pricetype;
        const option_id = selectedData.option_id;
        if (logofile[index]) {
            try {
                const file = logofile[index].originFileObj;
                const Logobase64String = logofile[index].thumbUrl?.split(',')[1];

                // If no object with the same name and length 1 uploaded_file, update the existing object or create a new one
                const optionIndex = newSelectedValues.findIndex(
                    (item) => item.option_value_id === id
                );


                // if (optionIndex !== -1) {
                //     // If option_id exists, update the existing object
                //     newSelectedValues[2].uploaded_file.push(image);
                //     newSelectedValues[optionIndex].price_type = pricetype;
                //     newSelectedValues[optionIndex].price = price;
                //     newSelectedValues[optionIndex].quantity = quantity;
                //     newSelectedValues[optionIndex].type = type;
                // } else {
                //     // If option_id doesn't exist, create a new object

                newSelectedValues[index] = {
                    option_value_id: id == undefined ? null : id,
                    // option_id: value !== "" ? 2 == "" ? option_id : isparentID : null,
                    option_id: 2,
                    is_variant_image: value === "" ? "0" : "1",
                    // price_type: value !== "" ? pricetype : null,
                    price: value !== "" ? price : null,
                    quantity: value !== "" ? quantity : null,
                    price_type: value !== "" ? type : null,
                };
                newSelectedValues[index].uploaded_file = [image]

                // }

                setSelectedValues(newSelectedValues);



            } catch (error) {
                console.error('Error converting image to File:', error);
            }
        }
    };
    const [editIndex, setEditIndex] = useState(null);

    const handleEdit = (index) => {
        setEditIndex(index);
    };

    const handleSave = (index) => {
        setEditIndex(null);
    }

    const handleSubmittable = () => {

        const newSpecification = {
            quantity: nameRef.current.value,
            price: valueRef.current.value,
        };
        if (valueRef.current && valueRef.current.value.trim() === '') {
            setErrorvalueMessage('Please fill correct value');
        } else {
            // Perform your submission logic here
            // If everything is fine, reset the error message
            setErrorvalueMessage('');
        }
        if (nameRef.current && nameRef.current.value.trim() === '') {
            setErrornameMessage('Please fill correct value');
        } else {
            // Perform your submission logic here
            // If everything is fine, reset the error message
            setErrornameMessage('');
        }

        if (!(nameRef.current.value == "" || valueRef.current.value == "")) {
            setSpecificationsList([...specificationsList, newSpecification]);
            nameRef.current.value = '';
            valueRef.current.value = '';
        }

        // Clear input fields after adding a specification

    };

    const cleartable = () => {
        nameRef.current.value = '';
        valueRef.current.value = '';
    }


    const handleDeletetable = (index, idd) => {
        const updatedList = [...specificationsList];
        updatedList.splice(index, 1);
        if (idd) {
            deleteSpecification(idd, id)
        }
        setSpecificationsList(updatedList);
    };
    const scrollToRef = (ref) => {
        if (ref && ref.current) {
            const yOffset = -90; // Adjust this value as needed
            const y = ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    };
    const [query, setQuery] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [HideShow, setHideShow] = useState(false);
    const [value, setValue] = useState("Select option...");
    const inputRef = useRef(null);
    const { setparentId } = useProductCatalogStore();

    useEffect(() => {
        document.addEventListener("click", toggle);
        return () => document.removeEventListener("click", toggle);
    }, []);

    const selectOption = (option, index) => {

        // handleChangeInput(index, event)
        if (option.id) {
            setQuery(() => "");
            setValue(option['option_name']);
            getProductValuesList(option.id)
            setparentId(option?.id)
            setIsOpen((isOpen) => !isOpen);
        }
        else {
            setQuery(() => "");
            setValue(option['option_name']);
            getProductValuesList(option)
            setparentId(option)
            setIsOpen((isOpen) => !isOpen);

        }

    };

    function toggle(e) {
        setIsOpen(e && e.target === inputRef.current);
    }

    const getDisplayValue = () => {
        if (query) return query;
        if (value) return value;

        return "";
    };

    const filter = (options) => {
        return options.filter(
            (option) => option['option_name'].toLowerCase().indexOf(query.toLowerCase()) > -1
        );
    };



    return (
        <>
            <div className="flex justify-between mt-[51px] ">
                <div className=" mb-4">
                    <p className=" text-2xl font-semibold">Admin Edit Product </p>
                    <p className=" opacity-80 text-sm">Home / Product Catalog / Edit Product  </p>
                </div>
                <div>

                    <Link
                        onClick={() => navigate(-1)}
                        className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                    >
                        Back
                    </Link>
                </div>
            </div>
            {ProductFormLoader ?
                <>
                    <svg
                        className="animate-spin  mr-0 ml-[45%] mt-[15%] h-[5%] w-[5%] text-color_theme"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                        ></circle>
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                    </svg>
                </>
                :
                <div className="card relative flex    bg-base-100 shadow-md p-6 mt-8">



                    <div >
                        <form className="flex md:flex-row flex-col space-x-1 " onSubmit={handleSubmit}>

                            <div
                                ref={basicDetailsRef}

                                className=" flex md:w-9/12 w-full justify-center  space-y-5 flex-col">

                                <div className="  border-[1px] p-2 bg-[#f7f7f7] rounded border-gray-300 " >
                                    <div
                                        className="flex flex-col mb-3 ">
                                        <label className="font-semibold text-xl">Basic details  </label>
                                        <span className="text-sm  mt-1" >     Manage the product's basic indivation. </span>
                                    </div>
                                    <div className="border-b-[1px] border-[gray] mt-5 mb-5"></div>
                                    <div className="mb-5">
                                        <label
                                            htmlFor="Product"
                                            className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                                        >
                                            Product identifier<span className="text-[#f00]">&nbsp;*</span>
                                        </label>
                                        <Input
                                            name="Product"
                                            required
                                            disabled
                                            placeholder="Product"
                                            value={productName}
                                            onChange={(e) => { setproductName(e.target.value); }}
                                            id="Product"
                                            type="text"
                                            autoComplete="off"
                                            className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                                        />
                                        <span className="text-[gray] text-xs">Enter a unique identifier associated with the product name.</span>
                                    </div>
                                    <div className="grid lg:grid-cols-1 mb-9 md:grid-cols-1 md:gap-8 mt-3 gap-4">
                                        <div>
                                            <label
                                                htmlFor="Categoryidentifier"
                                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                                            >
                                                Category<span className="text-[#f00]">&nbsp;*</span>
                                            </label>
                                            <SearchableDropdown List={All_categories} listingOff={"categories"} />



                                        </div>
                                    </div>

                                    <div className="grid lg:grid-cols-1 mb-9 md:grid-cols-1 md:gap-8 gap-2">
                                        <label
                                            htmlFor="Categoryidentifier"
                                            className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 "
                                        >
                                            YouTube video URL<span className="text-[#f00]">&nbsp;*</span>
                                        </label>
                                        <Input
                                            name="URL"
                                            required
                                            value={YouTubevideo}
                                            onChange={(e) => setYouTubevideo(e.target.value)}
                                            id="URL"
                                            type="text"
                                            autoComplete="off"
                                            className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                                        />

                                    </div>
                                    <div className="grid lg:grid-cols-1 mb-9 md:grid-cols-1 md:gap-8 gap-2">
                                        <label
                                            htmlFor="Categoryidentifier"
                                            className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 "
                                        >
                                            Video title<span className="text-[#f00]">&nbsp;*</span>
                                        </label>
                                        <Input
                                            name="title"
                                            required
                                            value={Videotitle}
                                            onChange={(e) => setVideotitle(e.target.value)}
                                            id="title"
                                            type="text"
                                            autoComplete="off"
                                            className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                                        />

                                    </div>
                                    <div className="mb-5">
                                        <label
                                            htmlFor="Product"
                                            className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                                        >
                                            Video Description
                                        </label>
                                        <JoditEditor
                                            value={video_desc}
                                            tabIndex={5}
                                            sanitizeHTML={true} // Set to true to remove HTML tags
                                            onChange={(newContent) => setvideo_desc(newContent)}
                                        />
                                    </div>
                                    <div className="grid lg:grid-cols-1 mb-9 md:grid-cols-1 md:gap-8 gap-2">
                                        <label
                                            htmlFor="Categoryidentifier"
                                            className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 "
                                        >
                                            Shipping amount<span className="text-[#f00]">&nbsp;*</span>
                                        </label>
                                        <Input
                                            name="shipping"
                                            required
                                            value={shipping}
                                            onChange={(e) => setshipping(e.target.value)}
                                            min={0}
                                            id="shipping"
                                            type="number"
                                            autoComplete="off"
                                            className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                                        />

                                    </div>


                                    {producttype == 1 ?
                                        <>


                                            <div className="grid lg:grid-cols-1 mb-5 md:grid-cols-1 ">
                                                {options_in_combination == 1 ?

                                                    <div>
                                                        <label
                                                            htmlFor="Categoryidentifier"
                                                            // onClick={() => { setoption("0"); setmaster_option("0") }}

                                                            className={`block text-sm ${options_in_combination == 1 ? "bg-color_theme cursor-pointer text-white" : "text-zinc-700"} cursor-pointer  text-center p-3 font-semibold  mb-2`}
                                                        >
                                                            Combination Options

                                                        </label>

                                                    </div>
                                                    :
                                                    <div>
                                                        <label
                                                            htmlFor="Categoryidentifier"
                                                            // onClick={() => { setoption("1"); setmaster_option("1") }}
                                                            className={`block text-sm ${options_in_combination == 0 ? "bg-color_theme cursor-pointer text-white" : "text-zinc-700"} text-center cursor-pointer p-3 font-semibold  mb-2`}
                                                        >
                                                            General Options
                                                        </label>

                                                    </div>

                                                }



                                            </div>






                                        </>

                                        :
                                        <>
                                            <div className="grid lg:grid-cols-2 mb-5 md:grid-cols-2 md:gap-8 gap-4">

                                                <div>
                                                    <label
                                                        htmlFor="Categoryidentifier"
                                                        className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                                                    >
                                                        Stock quantity<span className="text-[#f00]">&nbsp;*</span>
                                                    </label>
                                                    <Input
                                                        name="quantity"
                                                        required
                                                        placeholder="quantity"
                                                        min={1}
                                                        value={minimumprice}
                                                        onChange={(e) => { setminimumprice(e.target.value); }}
                                                        id="quantity"
                                                        type="number"
                                                        autoComplete="off"
                                                        className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                                                    />

                                                </div>
                                                <div>
                                                    <label htmlFor="Status" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">
                                                        Dimensions
                                                    </label>
                                                    <select
                                                        id="Status"
                                                        required
                                                        onChange={(e) => getunit_type_idss(e.target.value)}
                                                        name="Status"
                                                        className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                                                    >
                                                        <option value={productDetail.unit_type_id}>{productDetail.unit_type_name}</option>
                                                        {
                                                            UnitDetails.map((item, index) => (
                                                                <option key={index} value={item.unit_type_id}>{item.unit_type}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="grid lg:grid-cols-1 mb-5 md:grid-cols-1 w-full">

                                                {productDetail.unit_type_id !== "" &&
                                                    <div>
                                                        <label htmlFor="Status" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">
                                                            Unit list
                                                        </label>
                                                        <select
                                                            id="Status"
                                                            required
                                                            onChange={(e) => getunit_id(e.target.value)}
                                                            name="Status"
                                                            className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                                                        >
                                                            <option value={productDetail.unit_id}>{productDetail.unit_name}</option>
                                                            {
                                                                // Filter UnitDetails array based on selected unit_type_id
                                                                UnitDetails.filter(item => item.unit_type_id == unit_type_id).map((filteredItem, filteredIndex) => (
                                                                    filteredItem.data.map((unit, index) => (
                                                                        <option key={index} value={unit.unit_id}>{unit.unit_name}</option>
                                                                    ))
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                }

                                            </div>
                                        </>


                                    }
                                    {options_in_combination == 0 ?


                                        producttype == 1 &&
                                        inputFields.map((items, indexs) => (
                                            Array.isArray(items) && items.map((item, index) => (
                                                <div key={indexs} className="grid lg:grid-cols-1 mb-5 md:grid-cols-1 md:gap-8 gap-4">
                                                    <div className="grid lg:grid-cols-1 mb-5 md:grid-cols-1 md:gap-8 gap-4">
                                                        <div className="border-b-[1px] border-[#c6c6c6]"></div>
                                                        {/* <span className="text-[white] text-sm p-3 bg-[#fd397a] rounded-sm">
                                                            If the inventory has already been added, you cannot bind more options to this product.
                                                        </span> */}
                                                        <div className="flex justify-end mr-5 space-x-1">
                                                            {indexs > 0 && (
                                                                <div className="rounded-full cursor-pointer text-center flex justify-center items-center w-6 h-6 bg-slate-300 p-1" onClick={() => handleRemoveFields(indexs)}>
                                                                    <MdCancel />
                                                                </div>
                                                            )}
                                                            <div className="rounded-xl cursor-pointer text-[11px] text-center flex justify-center items-center bg-slate-300 p-3" onClick={handleAddFields}>
                                                                Add Options
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col space-y-3">
                                                            <div className="flex ml-[11px] space-x-2">
                                                                {newFields.includes(indexs) ? (
                                                                    <div className="w-5/12 " >
                                                                        <label htmlFor="Price" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">
                                                                            Options type
                                                                        </label>
                                                                        <div className="dropdowncategory">
                                                                            <div className="control">
                                                                                <div className="selected-value">
                                                                                    <div
                                                                                        onClick={() => {
                                                                                            const newHideShowStates = [...hideShowStates];
                                                                                            newHideShowStates[indexs] = !newHideShowStates[indexs];
                                                                                            setHideShowStates(newHideShowStates);
                                                                                        }

                                                                                        }


                                                                                        className=" p-3 border-[1px] border-[#e5e7eb] focus:outline-0 w-full text-sm font-medium rounded-md">
                                                                                        {categoryvalue[indexs] ? categoryvalue[indexs] : "Select option..."}
                                                                                    </div>

                                                                                    {hideShowStates[indexs] && (
                                                                                        <div class="relative">
                                                                                            <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                                                                                <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                                                                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                                                                                </svg>
                                                                                            </div>
                                                                                            <input
                                                                                                ref={inputRef}
                                                                                                class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                                                                type="text"
                                                                                                placeholder="Search..."
                                                                                                // value={getDisplayValue()}
                                                                                                name="searchTerm"

                                                                                                onChange={(e) => {
                                                                                                    setQuery(e.target.value);
                                                                                                    // setValue(null);
                                                                                                }}
                                                                                                onClick={() => {
                                                                                                    // Toggle the corresponding hideShow state
                                                                                                    const newIsOpenStates = [...isOpenStates];
                                                                                                    newIsOpenStates[indexs] = !newIsOpenStates[indexs];
                                                                                                    setIsOpenStates(newIsOpenStates);

                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    )}


                                                                                </div>

                                                                                <div className={`arrow ${isOpenStates[indexs] ? "open" : ""}`}></div>
                                                                            </div>

                                                                            <div className={`options ${isOpenStates[indexs] ? "open" : ""}`}>
                                                                                {filter(ProductOptions).filter(item => !selectedPreviousDropdowns.includes(item?.id)).map((option, index) => (
                                                                                    <div
                                                                                        name="option_id"
                                                                                        onClick={() => {
                                                                                            selectOption(option, indexs);
                                                                                            // Toggle the corresponding hideShow state
                                                                                            toggle();
                                                                                            const newIsOpenStates = [...isOpenStates];
                                                                                            newIsOpenStates[indexs] = !newIsOpenStates[indexs];
                                                                                            setIsOpenStates(newIsOpenStates);
                                                                                            handleChangeInput("option_id", option, indexs)

                                                                                            // setisShowList();

                                                                                        }}
                                                                                        value={option.option_name}
                                                                                        className={`option ${option["option_name"] === value ? "selected" : ""}`}
                                                                                        key={`${"id"}-${index}`}
                                                                                    >
                                                                                        {option.option_name}
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                ) : (
                                                                    <div className="w-5/12">
                                                                        <label htmlFor="Price" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">
                                                                            Options type
                                                                        </label>
                                                                        <div className="dropdowncategory">
                                                                            <div className="control">
                                                                                <div className="selected-value">
                                                                                    <div
                                                                                        onClick={() => {
                                                                                            const newHideShowStates = [...hideShowStates];
                                                                                            newHideShowStates[indexs] = !newHideShowStates[indexs];
                                                                                            setHideShowStates(newHideShowStates);
                                                                                        }}
                                                                                        className="p-3 border-[1px] border-[#e5e7eb] focus:outline-0 w-full text-sm font-medium rounded-md"
                                                                                    >
                                                                                        {item.option_name}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {newFields.includes(indexs) ?
                                                                    <div className="w-5/12 " >
                                                                        <label htmlFor="Price" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">
                                                                            Options value
                                                                        </label>
                                                                        <div className="relative">
                                                                            <input
                                                                                type="text"
                                                                                onFocus={() => {
                                                                                    handleFocus(indexs)
                                                                                }}
                                                                                onBlur={() => {
                                                                                    handleBlur(indexs)
                                                                                }}
                                                                                value={searchQuery}
                                                                                onChange={(e) => handleSearch(e.target.value, indexs)}
                                                                                className="p-3 pl-8 rounded  w-full border border-gray-200  focus:bg-white focus:outline-none "
                                                                                placeholder="Type to search..."
                                                                            />
                                                                            <svg
                                                                                className="w-4 h-4 absolute left-2.5 top-3.5"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                fill="none"
                                                                                viewBox="0 0 24 24"
                                                                                stroke="currentColor"
                                                                            >
                                                                                <path
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                    stroke-width="2"
                                                                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                                                                />
                                                                            </svg>

                                                                        </div>
                                                                        <div className="flex flex-wrap mt-1 gap-2">
                                                                            {selectedOptionsList[indexs]?.map((option, optionIndex) => (
                                                                                <div
                                                                                    key={optionIndex}
                                                                                    className="bg-blue-100 p-1 rounded flex items-center"
                                                                                >
                                                                                    {option}
                                                                                    <button
                                                                                        type="button"
                                                                                        className="ml-2 text-red-500 hover:text-red-700"
                                                                                        onClick={() => handleRemove(indexs, optionIndex)}
                                                                                    >
                                                                                        <MdCancel />
                                                                                    </button>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                        {isShowList[indexs] && (
                                                                            <ul className="bg-white border border-gray-100 w-full mt-2">
                                                                                {filteredItemsList[indexs]
                                                                                    .filter(item => !selectedOptionsInPreviousDropdowns.includes(item?.option_value_name))
                                                                                    .map((item, index) => (
                                                                                        <li
                                                                                            key={indexs}
                                                                                            onClick={() => {
                                                                                                handleSelect(item, indexs);


                                                                                            }}
                                                                                            className="pl-8 pr-2 py-1 border-b-2 border-gray-100 relative cursor-pointer hover:bg-yellow-50 hover:text-gray-900"
                                                                                        >
                                                                                            {/* You can customize the display of each item as needed */}
                                                                                            {item?.option_value_name}
                                                                                        </li>
                                                                                    ))}
                                                                            </ul>
                                                                        )}

                                                                    </div>
                                                                    :
                                                                    <div className="w-5/12">
                                                                        <label htmlFor="Price" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">
                                                                            Options value
                                                                        </label>
                                                                        <div className="flex flex-row justify-center items-center gap-3">
                                                                            <div className="flex flex-wrap gap-2">
                                                                                <div className="bg-blue-100 p-1 rounded flex items-center">
                                                                                    {item.option_value_name}
                                                                                </div>
                                                                            </div>
                                                                            <div className="flex flex-wrap cursor-pointer gap-2">
                                                                                <select
                                                                                    className="bg-blue-100 p-1 rounded w-full"
                                                                                    onChange={(event) => {
                                                                                        const selectedValue = JSON.parse(event.target.value);
                                                                                        handleSelect(selectedValue, indexs);
                                                                                    }}
                                                                                >
                                                                                    <option>Select option values</option>
                                                                                    {item?.available_options.map((value, indx) => (
                                                                                        <option key={indx} value={JSON.stringify(value)}>
                                                                                            {value.option_value_name}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>

                                                            {indexs === 0 && item?.costing?.map((values, indx) => (
                                                                <div key={indx} className="flex space-x-5 px-3">
                                                                    <span className="w-5/12">
                                                                        <label htmlFor="Quantity" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">
                                                                            Quantity
                                                                        </label>
                                                                        <input
                                                                            type="number"
                                                                            name="quantity"
                                                                            value={values?.quantity}
                                                                            onChange={(event) => handleChangeQtyPriceInputOn0th("quantity", event, indexs, index, indx)}
                                                                            placeholder="quantity"
                                                                            className="p-3 border-[1px] border-[#e5e7eb] focus:outline-0 w-full text-sm font-medium rounded-md"
                                                                        />
                                                                        {console.log("yashhhhhhhhhhhhhhhhhhhhhhhhhhhhh", item)}

                                                                    </span>
                                                                    <span className="w-5/12">
                                                                        <label htmlFor="Price" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">
                                                                            Price
                                                                        </label>
                                                                        <input
                                                                            type="number"
                                                                            name="price"
                                                                            required
                                                                            value={values?.price}
                                                                            onChange={(event) => handleChangeQtyPriceInputOn0th("price", event, indexs, index, indx)}
                                                                            placeholder="price"
                                                                            className="p-3 border-[1px] border-[#e5e7eb] focus:outline-0 w-full text-sm font-medium rounded-md"
                                                                        />
                                                                    </span>
                                                                    <div className="flex justify-center items-center text-center mt-5 space-x-1">
                                                                        {(indx > 0 && indexs == 0) && (
                                                                            <div className="rounded-full cursor-pointer text-center flex justify-center items-center w-6 h-6 bg-slate-300 p-1" onClick={() => handleRemoveQtyPriceFields(indx)}>
                                                                                <MdCancel />
                                                                            </div>
                                                                        )}


                                                                        {indexs == 0 &&
                                                                            <div className="rounded-full cursor-pointer text-center flex justify-center items-center w-6 h-6 bg-slate-300 p-1" onClick={() => handleAddQtyPriceFields(indexs)}>
                                                                                <MdAdd />
                                                                            </div>
                                                                        }

                                                                    </div>
                                                                </div>
                                                            ))}
                                                            {console.log("outtttttttttttttttttsideeeeeeeeeeeeeeeeeeeeeeee", QtyPrice[0][0].costing)}
                                                            {newFields.includes(indexs) ?

                                                                items.map((itemz, indx) => (
                                                                    itemz?.costing.map((values, indx) => (

                                                                        <div key={indx} className="flex space-x-5 px-3">

                                                                            <span className="w-5/12">
                                                                                <label htmlFor="Quantity" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">
                                                                                    Quantity
                                                                                </label>
                                                                                <input
                                                                                    type="number"
                                                                                    name="quantity"
                                                                                    disabled
                                                                                    value={values?.quantity}
                                                                                    placeholder="quantity"
                                                                                    className="p-3 border-[1px] border-[#e5e7eb] focus:outline-0 w-full text-sm font-medium rounded-md"
                                                                                />
                                                                            </span>
                                                                            <span className="w-5/12">
                                                                                <label htmlFor="Price" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">
                                                                                    Price
                                                                                </label>
                                                                                <input
                                                                                    type="number"
                                                                                    name="price"
                                                                                    required
                                                                                    value={item?.price}
                                                                                    onClick={(event) => handleChangeQtyPriceInput("quantity", values?.quantity, indexs, index, indx)}

                                                                                    onChange={(event) => handleChangeQtyPriceInput("price", event, indexs, index, indx)}
                                                                                    placeholder="price"
                                                                                    className="p-3 border-[1px] border-[#e5e7eb] focus:outline-0 w-full text-sm font-medium rounded-md"
                                                                                />
                                                                            </span>
                                                                        </div>

                                                                    ))

                                                                ))


                                                                :

                                                                indexs !== 0 &&
                                                                // QtyPrice[0][0].costing?.map((values, indx) => (
                                                                items.map((key, index) => (
                                                                    key.costing.map((item, indx) => (
                                                                        <div className="flex space-x-5 px-3">
                                                                            {console.log("insideeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee", item)}

                                                                            <>
                                                                                <span className="w-5/12">
                                                                                    <label htmlFor="Quantity" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">
                                                                                        Quantity
                                                                                    </label>
                                                                                    <input
                                                                                        type="number"
                                                                                        name="quantity"
                                                                                        disabled
                                                                                        value={item?.quantity}
                                                                                        placeholder="quantity"
                                                                                        className="p-3 border-[1px] border-[#e5e7eb] focus:outline-0 w-full text-sm font-medium rounded-md"
                                                                                    />
                                                                                </span>
                                                                                <span className="w-5/12">
                                                                                    <label htmlFor="Price" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">
                                                                                        Price
                                                                                    </label>
                                                                                    <input
                                                                                        type="number"
                                                                                        name="price"
                                                                                        required
                                                                                        value={item?.price}
                                                                                        onClick={(event) => handleChangeQtyPriceInput("quantity", item?.quantity, indexs, index, indx)}

                                                                                        onChange={(event) => handleChangeQtyPriceInput("price", event, indexs, index, indx)}
                                                                                        placeholder="price"
                                                                                        className="p-3 border-[1px] border-[#e5e7eb] focus:outline-0 w-full text-sm font-medium rounded-md"
                                                                                    />
                                                                                </span>
                                                                            </>




                                                                        </div>
                                                                    ))

                                                                ))

                                                                // ))
                                                            }
                                                            <div className="flex ml-[11px] space-x-3">
                                                                <span className="w-5/12">
                                                                    <label htmlFor="Price" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">
                                                                        Material Qty.
                                                                    </label>
                                                                    <input
                                                                        type="number"
                                                                        name="material"
                                                                        defaultValue={item.material_consume}
                                                                        onChange={(event) => handleChangeInput("material_consume", event, indexs)}
                                                                        placeholder="material qty"
                                                                        className="p-3 border-[1px] border-[#e5e7eb] focus:outline-0 w-full text-sm font-medium rounded-md"
                                                                    />
                                                                </span>
                                                                {!newFields.includes(indexs) &&
                                                                    <span className=" w-5/12">
                                                                        <label htmlFor="Price" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">
                                                                            Dimensions
                                                                        </label>
                                                                        <select
                                                                            id="Status"

                                                                            // value={item?.material_consume_unit}
                                                                            onChange={(e) => { dynamicUnits(e.target.value, indexs); }}
                                                                            name="Status"
                                                                            className={`select focus:outline-0 w-full text-sm font-medium  rounded-md focus:outline-offset-0 select-bordered`}
                                                                        >
                                                                            <option value="">Select Dimensions</option>
                                                                            {
                                                                                UnitDetails.map((item, index) => (
                                                                                    <option key={index} value={item.unit_type_id}>{item.unit_type}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </span>
                                                                }
                                                            </div>
                                                            {newFields.includes(indexs) ?
                                                                Array.isArray(items) && items.map((unit_type, index) => (
                                                                    <div className="grid lg:grid-cols-1 mb-5 md:grid-cols-1 w-full">

                                                                        {/* {item.material_consume_unit && */}
                                                                        <div>
                                                                            <label htmlFor="Status" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">
                                                                                Unit list
                                                                            </label>
                                                                            <select
                                                                                id="Status"
                                                                                required
                                                                                // value={item.material_consume_unit}
                                                                                onChange={(event) => handleChangeInput("material_consume_unit", event, indexs)}
                                                                                name="Status"
                                                                                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                                                                            >
                                                                                {/* <option value={item.material_consume_unit}>{item.unit_name}</option> */}
                                                                                <option value="">Select unit</option>
                                                                                {
                                                                                    // Filter UnitDetails array based on selected unit_type_id
                                                                                    UnitDetails.filter(item => item.unit_type_id == unit_type_id[indexs]).map((filteredItem, filteredIndex) => (
                                                                                        filteredItem.data.map((unit, index) => (
                                                                                            <option key={index} value={unit.unit_id}>{unit.unit_name}</option>
                                                                                        ))
                                                                                    ))
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                        {/* } */}

                                                                        {/* <select
                                        id="Status"
                                        required={selectedOptionsList[indexs].length > 0}
                                        // value={selectedOption}
                                        onChange={(event) => handleChangeInput("type", event, indexs)}
                                        name="type"
                                        className={`select  focus:outline-0 w-5/12  text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                                    >
                                        <option value="">Type</option>
                                        <option value="radio">radio</option>
                                        <option value="select">select</option>
                                        <option value="checkbox">checkbox</option>
                                        <option value="text">text</option>
                                        <option value="textarea">textarea</option>
                                        <option value="file">file</option>
                                        <option value="date">date</option>
                                        <option value="time">time</option>
                                        <option value="date&time">date & time</option>


                                    </select> */}


                                                                    </div>
                                                                ))
                                                                :
                                                                <div className="grid lg:grid-cols-1 mb-5 md:grid-cols-1 w-full">

                                                                    {/* {item.material_consume_unit && */}
                                                                    <div>
                                                                        <label htmlFor="Status" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">
                                                                            Unit list
                                                                        </label>
                                                                        <select
                                                                            id="Status"
                                                                            required
                                                                            // value={item.material_consume_unit}
                                                                            onChange={(event) => handleChangeInput("material_consume_unit", event, indexs)}
                                                                            name="Status"
                                                                            className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                                                                        >
                                                                            <option value={item.material_consume_unit}>{item.unit_name}</option>
                                                                            <option value="">Select unit</option>
                                                                            {
                                                                                // Filter UnitDetails array based on selected unit_type_id
                                                                                UnitDetails.filter(item => item.unit_type_id == unit_type_id[indexs]).map((filteredItem, filteredIndex) => (
                                                                                    filteredItem.data.map((unit, index) => (
                                                                                        <option key={index} value={unit.unit_id}>{unit.unit_name}</option>
                                                                                    ))
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                    {/* } */}

                                                                    {/* <select
                                        id="Status"
                                        required={selectedOptionsList[indexs].length > 0}
                                        // value={selectedOption}
                                        onChange={(event) => handleChangeInput("type", event, indexs)}
                                        name="type"
                                        className={`select  focus:outline-0 w-5/12  text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                                    >
                                        <option value="">Type</option>
                                        <option value="radio">radio</option>
                                        <option value="select">select</option>
                                        <option value="checkbox">checkbox</option>
                                        <option value="text">text</option>
                                        <option value="textarea">textarea</option>
                                        <option value="file">file</option>
                                        <option value="date">date</option>
                                        <option value="time">time</option>
                                        <option value="date&time">date & time</option>


                                    </select> */}


                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ))





                                        :

                                        producttype == 1 &&
                                        combinationFields.map((item, indexs) => (
                                            <div key={indexs} className="grid lg:grid-cols-1 mb-5 md:grid-cols-1 md:gap-8 gap-4">
                                                <div className="grid lg:grid-cols-1 mb-5 md:grid-cols-1 md:gap-8 gap-4">
                                                    <div className="border-b-[1px] border-[#c6c6c6]  "></div>

                                                    <div className="flex flex-col space-y-3  ">
                                                        <div className="flex justify-center flex-col  space-x-2 ">

                                                            <>
                                                                <div className="flex justify-end mr-5 space-x-1">
                                                                    {indexs > 0 && (
                                                                        <div className="rounded-full cursor-pointer text-center flex justify-center items-center w-6 h-6 bg-slate-300 p-1" onClick={() => handleRemoveCombinationFields(indexs)}>
                                                                            <MdCancel />
                                                                        </div>
                                                                    )}
                                                                    {indexs == 0 && (
                                                                        <div className="rounded-xl cursor-pointer text-[11px] text-center flex justify-center items-center bg-slate-300 p-3" onClick={handleAddCombinationFields}>
                                                                            Add Options
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                {/* <span className="text-[white] mb-5 text-sm p-3 bg-[#fd397a] rounded-sm " > If the inventory has already been added, you cannot bind more options to this product.</span> */}

                                                                <>


                                                                    <>
                                                                        {newComboFields.includes(indexs) ?

                                                                            <div className="flex flex-col space-y-3  ">
                                                                                <div className="flex justify-center flex-col  space-x-2 ">
                                                                                    {console.log("innnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnCheckkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk", item)}
                                                                                    {item?.options_detail?.map((values, index) => (
                                                                                        <>
                                                                                            {console.log("lzsjnkfdnklnksfjnkgjizjkofjojozsjdfjkmsdkmkjkdjjkihklojnkosnjkljhnihjnklmklm", values)}
                                                                                            {/* {indexs == 0 && */}
                                                                                            <>
                                                                                                <div className="flex justify-end mr-5 space-x-1">
                                                                                                    {/* {(indexs > 0) && (
                                                                                                        <div className="rounded-full cursor-pointer text-center flex justify-center items-center  bg-slate-300 p-3" onClick={() => handleRemoveCombinationFields(indexs)}>
                                                                                                            <MdCancel />
                                                                                                        </div>
                                                                                                    )} */}

                                                                                                    {indexs == 0 &&

                                                                                                        <div className="rounded-xl cursor-pointer text-[11px] text-center flex justify-center items-center  bg-slate-300 p-3" onClick={handleAddCombinationFields}>
                                                                                                            Add Options
                                                                                                        </div>

                                                                                                    }

                                                                                                </div>


                                                                                                <>

                                                                                                    <div key={index} className=" flex space-x-5 px-3">

                                                                                                        <span className="w-5/12 " >
                                                                                                            <label htmlFor="Price" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">
                                                                                                                Options type
                                                                                                            </label>
                                                                                                            <div className="dropdowncategory">
                                                                                                                <div className="control">
                                                                                                                    <div className=" space-y-3 selected-value">


                                                                                                                        <div
                                                                                                                            onClick={() => {
                                                                                                                                const newHideShowStates = [...hideShowCombinationStates];
                                                                                                                                newHideShowStates[index] = !newHideShowStates[index];
                                                                                                                                sethideShowCombinationStates(newHideShowStates);

                                                                                                                            }

                                                                                                                            }

                                                                                                                            className=" p-3 border-[1px] border-[#e5e7eb] focus:outline-0 w-full text-sm font-medium rounded-md">
                                                                                                                            {values?.option_name}
                                                                                                                        </div>


                                                                                                                        {/* 





*/}


                                                                                                                    </div>
                                                                                                                    {/* 
<div className={`arrow ${isOpenCombinationStates[indx] ? "open" : ""}`}></div> */}
                                                                                                                </div>
                                                                                                                {/* 
<div className={`options ${isOpenCombinationStates[indx] ? "open" : ""}`}>
{filter(ProductOptions).map((option, indexxx) => (
<div
name="option_id"
onClick={() => {
selectOption(option, indexxx);
// Toggle the corresponding hideShow state
toggle();
const newIsOpenStates = [...isOpenCombinationStates];
newIsOpenStates[indx] = !newIsOpenStates[indx];
setIsOpenCombinationStates(newIsOpenStates);
handleChangeCombinationInputOn0th("option_id", "optionlists_0", option, indexs, index, indx)
// handleChangeInput("option_id", option, indexs)

// setisShowList();

}}
value={option.option_name}
className={`option ${option["option_name"] === value ? "selected" : ""}`}
key={`${"id"}-${indexxx}`}
>
{option.option_name}
</div>
))}
</div> */}
                                                                                                            </div>
                                                                                                        </span>

                                                                                                        <span className="w-5/12 " >
                                                                                                            <label htmlFor="Price" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">
                                                                                                                Options value
                                                                                                            </label>
                                                                                                            <div className="ml-5">
                                                                                                                {console.log("options_dropdownnnnnnnnnnnnnnnnnnnnnnnnnnn", options_dropdown)}
                                                                                                                <select
                                                                                                                    className="bg-blue-100 p-1 rounded w-full"
                                                                                                                    onChange={(event) => {
                                                                                                                        const selectedValue = JSON.parse(event.target.value);
                                                                                                                        handleSelect_zero(selectedValue, indexs, index, index, values.option_id);
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <option>Select option values </option>
                                                                                                                    {options_dropdown?.map((value, dropdownIndex) => (
                                                                                                                        value.available_options
                                                                                                                            .filter(option => value.option_id === values.option_id)
                                                                                                                            .map((optionvalue, optionIndex) => (
                                                                                                                                <option key={optionIndex} value={JSON.stringify(optionvalue)}>
                                                                                                                                    {optionvalue.option_value_name}
                                                                                                                                </option>
                                                                                                                            ))
                                                                                                                    ))}
                                                                                                                </select>
                                                                                                            </div>

                                                                                                            {(isCombinationShowList_Zero[index] && showList) && (
                                                                                                                <ul className="bg-white border border-gray-100 w-full mt-2">
                                                                                                                    {console.log("Innnnnnnnnnn the  condition")}
                                                                                                                    {CombofilteredItemsList_zero[index]
                                                                                                                        .filter(item => !ComboselectedOptionsInPreviousDropdowns.includes(item?.option_value_name))
                                                                                                                        .map((item, inde) => (
                                                                                                                            <li
                                                                                                                                key={indexs}
                                                                                                                                onClick={() => {
                                                                                                                                    handleSelect_zero(item, indexs, index, index);
                                                                                                                                }}
                                                                                                                                className="pl-8 pr-2 py-1 border-b-2 border-gray-100 relative cursor-pointer hover:bg-yellow-50 hover:text-gray-900"
                                                                                                                            >

                                                                                                                                {/* You can customize the display of each item as needed */}
                                                                                                                                {item?.option_value_name}
                                                                                                                            </li>
                                                                                                                        ))}
                                                                                                                </ul>
                                                                                                            )}
                                                                                                            <div className="flex space-x-1">
                                                                                                                {/* {(indx > 0 && indexs == 0) && (
                                                                                                                        <div className="rounded-full cursor-pointer text-center flex justify-center items-center  bg-slate-300 p-3" onClick={() => handleRemoveOptionFields(indx)}>
                                                                                                                            <MdCancel />
                                                                                                                        </div>
                                                                                                                    )} */}
                                                                                                                {console.log("optionlength <  qtyprice?.costing.length", index + 1, optionlength)}

                                                                                                                {indexs == 0 &&

                                                                                                                    <div className="rounded-full cursor-pointer text-[11px] text-center flex justify-center items-center mt-2  bg-slate-300 p-3" onClick={() => handleAddOptionFields(indexs)}>
                                                                                                                        <MdAdd />
                                                                                                                    </div>
                                                                                                                }

                                                                                                            </div>

                                                                                                        </span>



                                                                                                    </div>
                                                                                                </>



                                                                                            </>



                                                                                            {/* <div


                                                                                                className="flex justify-end space-x-1">
                                                                                                {indexs > 0 && (
                                                                                                    <div className="rounded-full cursor-pointer text-center mt-2 flex justify-end items-center  bg-slate-300 p-3" onClick={() => handleRemoveCombinationFields(indexs)}>
                                                                                                        <MdCancel />
                                                                                                    </div>
                                                                                                )}


                                                                                            </div> */}



                                                                                            {console.log("index", index)}
                                                                                        </>
                                                                                    ))}



                                                                                </div>

                                                                                {console.log("itemmmmmmmmmmmmmmmmmmm", item)}

                                                                                <div className=" flex space-x-5 px-3">
                                                                                    <span className=" w-5/12">
                                                                                        <label htmlFor="Quantity" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">
                                                                                            Stock
                                                                                        </label>
                                                                                        <input
                                                                                            type="number"
                                                                                            name="quantity"
                                                                                            // value={values?.quantity}
                                                                                            onChange={(event) => handleChangeCombinationInput("stock", event, indexs)}
                                                                                            placeholder="quantity"
                                                                                            className="p-3 border-[1px] border-[#e5e7eb] focus:outline-0 w-full text-sm font-medium rounded-md"
                                                                                        />
                                                                                    </span>
                                                                                    <span className=" w-5/12">
                                                                                        <label htmlFor="Price" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">
                                                                                            Price
                                                                                        </label>
                                                                                        <input
                                                                                            type="number"
                                                                                            name="price"
                                                                                            required
                                                                                            // value={values?.price}
                                                                                            onChange={(event) => handleChangeCombinationInput("price", event, indexs)}
                                                                                            placeholder="price"
                                                                                            className="p-3 border-[1px] border-[#e5e7eb] focus:outline-0 w-full text-sm font-medium rounded-md"
                                                                                        />

                                                                                    </span>

                                                                                </div>
                                                                                {/* <div className=" flex ml-[11px] space-x-3 ">

                                                 <span className=" w-5/12">
                                                     <label htmlFor="Price" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">
                                                         Material Qty.
                                                     </label>
                                                     <input
                                                         type="number"
                                                         name="material"
                                                         value={item.quantity}
                                                         onChange={(event) => handleChangeCombinationInput("material_consume", event, indexs)}
                                                         placeholder="material qty"
                                                         className=" p-3 border-[1px]  border-[#e5e7eb] focus:outline-0 w-full text-sm font-medium rounded-md"

                                                     />
                                                 </span>


                                                 <span className=" w-5/12">
                                                     <label htmlFor="Price" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">
                                                         Dimensions
                                                     </label>
                                                     <select
                                                         id="Status"

                                                         onChange={(e) => getunit_type_id(e.target.value)}
                                                         name="Status"
                                                         className={`select focus:outline-0 w-full text-sm font-medium  rounded-md focus:outline-offset-0 select-bordered`}
                                                     >
                                                         <option value="">Select Dimensions</option>
                                                         {
                                                             UnitDetails.map((item, index) => (
                                                                 <option key={index} value={item.unit_type_id}>{item.unit_type}</option>
                                                             ))
                                                         }
                                                     </select>
                                                 </span>
                                             </div> */}
                                                                                {/* <div className="grid lg:grid-cols-1 mb-5 md:grid-cols-1 w-full">

                                                 {unit_type_id !== "" &&
                                                     <div>
                                                         <label htmlFor="Status" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">
                                                             Unit list
                                                         </label>
                                                         <select
                                                             id="Status"

                                                             // value={selectedOption}
                                                             onChange={(event) => handleChangeCombinationInput("material_consume_unit", event, indexs)}
                                                             name="Status"
                                                             className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                                                         >
                                                             <option value="">Select unit</option>
                                                             {
                                                                 // Filter UnitDetails array based on selected unit_type_id
                                                                 UnitDetails.filter(item => item.unit_type_id == unit_type_id).map((filteredItem, filteredIndex) => (
                                                                     filteredItem.data.map((unit, index) => (
                                                                         <option key={index} value={unit.unit_id}>{unit.unit_name}</option>
                                                                     ))
                                                                 ))
                                                             }
                                                         </select>
                                                     </div>
                                                 }

                                                 <select
                                                     id="Status"
                                                     required={selectedOptionsList[indexs].length > 0}
                                                     // value={selectedOption}
                                                     onChange={(event) => handleChangeInput("type", event, indexs)}
                                                     name="type"
                                                     className={`select  focus:outline-0 w-5/12  text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                                                 >
                                                     <option value="">Type</option>
                                                     <option value="radio">radio</option>
                                                     <option value="select">select</option>
                                                     <option value="checkbox">checkbox</option>
                                                     <option value="text">text</option>
                                                     <option value="textarea">textarea</option>
                                                     <option value="file">file</option>
                                                     <option value="date">date</option>
                                                     <option value="time">time</option>
                                                     <option value="date&time">date & time</option>


                                                 </select>


                                             </div> */}


                                                                            </div>

                                                                            :
                                                                            <>
                                                                                <div className=" flex space-x-5 px-3">
                                                                                    <span className="w-5/12 " >
                                                                                        <label htmlFor="Price" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">
                                                                                            Options type
                                                                                        </label>
                                                                                        <div className="dropdowncategory">
                                                                                            <div className="control">
                                                                                                <div className=" space-y-3 selected-value">

                                                                                                    {item?.options_detail?.options_data?.map((values, indx) => (
                                                                                                        <div
                                                                                                            onClick={() => {
                                                                                                                const newHideShowStates = [...hideShowCombinationStates];
                                                                                                                newHideShowStates[indx] = !newHideShowStates[indx];
                                                                                                                sethideShowCombinationStates(newHideShowStates);

                                                                                                            }

                                                                                                            }

                                                                                                            className=" p-3 border-[1px] border-[#e5e7eb] focus:outline-0 w-full text-sm font-medium rounded-md">
                                                                                                            {values.option_name}
                                                                                                        </div>
                                                                                                    ))}

                                                                                                    {/* 
                                                                                               
                                                                                                      
                                                                                                        
                                                                                                          
                                          
                                                                                                      */}


                                                                                                </div>
                                                                                                {/* 
                                                                                                <div className={`arrow ${isOpenCombinationStates[indx] ? "open" : ""}`}></div> */}
                                                                                            </div>
                                                                                            {/* 
                                                                                            <div className={`options ${isOpenCombinationStates[indx] ? "open" : ""}`}>
                                                                                                {filter(ProductOptions).map((option, indexxx) => (
                                                                                                    <div
                                                                                                        name="option_id"
                                                                                                        onClick={() => {
                                                                                                            selectOption(option, indexxx);
                                                                                                            // Toggle the corresponding hideShow state
                                                                                                            toggle();
                                                                                                            const newIsOpenStates = [...isOpenCombinationStates];
                                                                                                            newIsOpenStates[indx] = !newIsOpenStates[indx];
                                                                                                            setIsOpenCombinationStates(newIsOpenStates);
                                                                                                            handleChangeCombinationInputOn0th("option_id", "optionlists_0", option, indexs, index, indx)
                                                                                                            // handleChangeInput("option_id", option, indexs)

                                                                                                            // setisShowList();

                                                                                                        }}
                                                                                                        value={option.option_name}
                                                                                                        className={`option ${option["option_name"] === value ? "selected" : ""}`}
                                                                                                        key={`${"id"}-${indexxx}`}
                                                                                                    >
                                                                                                        {option.option_name}
                                                                                                    </div>
                                                                                                ))}
                                                                                            </div> */}
                                                                                        </div>
                                                                                    </span>
                                                                                    <span className="w-5/12">
                                                                                        <label htmlFor="Price" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">
                                                                                            Options value
                                                                                        </label>
                                                                                        {item?.options_detail?.options_values_data?.map((values, indx) => (
                                                                                            <div className="flex flex-row justify-center items-center py-2 px-2 ">

                                                                                                <div className="bg-blue-100 p-1 whitespace-nowrap rounded flex items-center">
                                                                                                    {values.option_value_name}
                                                                                                </div>
                                                                                                <div className="ml-5">
                                                                                                    <select
                                                                                                        className="bg-blue-100 p-1 rounded w-full"
                                                                                                        onChange={(event) => {
                                                                                                            const selectedValue = JSON.parse(event.target.value);
                                                                                                            handleSelect_zero(selectedValue, indexs, indx, indx, values.option_id);
                                                                                                        }}
                                                                                                    >
                                                                                                        <option>Select option values </option>
                                                                                                        {options_dropdown?.map((value, dropdownIndex) => (
                                                                                                            value.available_options
                                                                                                                .filter(option => value.option_id === values.option_id)
                                                                                                                .map((optionvalue, optionIndex) => (
                                                                                                                    <option key={optionIndex} value={JSON.stringify(optionvalue)}>
                                                                                                                        {optionvalue.option_value_name}
                                                                                                                    </option>
                                                                                                                ))
                                                                                                        ))}
                                                                                                    </select>
                                                                                                </div>

                                                                                            </div>
                                                                                        ))}

                                                                                    </span>


                                                                                </div>
                                                                                <div className=" flex space-x-5 px-3">
                                                                                    <span className=" w-5/12">
                                                                                        <label htmlFor="Quantity" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">
                                                                                            Stock
                                                                                        </label>
                                                                                        <input
                                                                                            type="number"
                                                                                            name="quantity"
                                                                                            value={item?.stock}
                                                                                            onChange={(event) => handleChangeCombinationInput("stock", event, indexs)}

                                                                                            placeholder="quantity"
                                                                                            className="p-3 border-[1px] border-[#e5e7eb] focus:outline-0 w-full text-sm font-medium rounded-md"
                                                                                        />
                                                                                    </span>
                                                                                    <span className=" w-5/12">
                                                                                        <label htmlFor="Price" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">
                                                                                            Price
                                                                                        </label>
                                                                                        <input
                                                                                            type="number"
                                                                                            name="price"
                                                                                            required
                                                                                            value={item?.price}
                                                                                            onChange={(event) => handleChangeCombinationInput("price", event, indexs)}
                                                                                            placeholder="price"
                                                                                            className="p-3 border-[1px] border-[#e5e7eb] focus:outline-0 w-full text-sm font-medium rounded-md"
                                                                                        />

                                                                                    </span>

                                                                                </div>

                                                                            </>


                                                                        }
                                                                    </>




                                                                </>







                                                            </>


                                                        </div>


                                                        {/* <div className=" flex ml-[11px] space-x-3 ">

                                                            <span className=" w-5/12">
                                                                <label htmlFor="Price" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">
                                                                    Material Qty.
                                                                </label>
                                                                <input
                                                                    type="number"
                                                                    name="material"
                                                                    value={item.material_consume}
                                                                    onChange={(event) => handleChangeCombinationInput("material_consume", event, indexs)}
                                                                    placeholder="material qty"
                                                                    className=" p-3 border-[1px]  border-[#e5e7eb] focus:outline-0 w-full text-sm font-medium rounded-md"

                                                                />
                                                            </span>



                                                        </div> */}



                                                    </div>


                                                </div>

                                            </div>
                                        ))



                                    }



                                    <div className="mb-5">
                                        <label
                                            htmlFor="Product"
                                            className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                                        >
                                            Description
                                        </label>
                                        <JoditEditor
                                            // ref={editor}
                                            value={content}
                                            tabIndex={5}
                                            sanitizeHTML={true} // Set to true to remove HTML tags
                                            onChange={(newContent) => getcontent(newContent)}
                                        />
                                    </div>


                                </div>

                                <div className="  border-[1px] p-2 relative bg-[#f7f7f7] rounded border-gray-300 " >
                                    <div
                                        ref={media}

                                        className="flex flex-col mb-5 ">
                                        <label className="font-semibold text-xl">Media</label>
                                        <span className="text-sm  mt-1" >Manage your product's image gallery.</span>
                                        <span className="text-xs text-red-500  mt-1" >Recommended Image size 1000 * 600 .</span>
                                    </div>
                                    {
                                        processedLoader &&
                                        <div className="p-3  w-full   absolute z-50 flex justify-center left-0 bottom-0 top-[28%] ">

                                            <svg
                                                className="animate-spin mr-1 h-12 w-12 text-[#c6c6c6]"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                            >
                                                <circle className="opacity-75" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path
                                                    className="opacity-75  flex justify-center"
                                                    fill="currentColor"
                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                ></path>
                                            </svg>


                                        </div>
                                    }

                                    <div className="text-center z-0 mb-5">
                                        <ImgCrop
                                            showGrid
                                            rotationSlider
                                            aspectSlider
                                            showReset
                                        >
                                            <Upload
                                                listType="picture-circle"
                                                accept="image/*"
                                                fileList={logofile}
                                                onChange={({ fileList }) => { LogChange({ fileList, actionType: 'upload' }); setProcessedLoader(true) }}
                                                onRemove={(file) => handleImageDelete(file)}
                                            >
                                                <span className="btn btn-md bg-color_theme text-[#000] text-xs hover:text-color_theme hover:bg-[#000]">Upload </span>
                                            </Upload>
                                        </ImgCrop>

                                    </div>



                                </div>

                                <div className="  border-[1px] p-2 relative bg-[#f7f7f7] rounded border-gray-300 " >
                                    <div
                                        ref={Framemedia}

                                        className="flex flex-col mb-5 ">
                                        <label className="font-semibold text-xl">Frame Media</label>
                                        <span className="text-sm  mt-1" >Manage your poster's Frame gallery.</span>
                                        <span className="text-xs text-red-500  mt-1" >Recommended Image size 1000 * 600 .</span>
                                    </div>
                                    {
                                        processedFrameLoader &&
                                        <div className="p-3  w-full   absolute z-50 flex justify-center left-0 bottom-0 top-[28%] ">

                                            <svg
                                                className="animate-spin mr-1 h-12 w-12 text-[#c6c6c6]"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                            >
                                                <circle className="opacity-75" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path
                                                    className="opacity-75  flex justify-center"
                                                    fill="currentColor"
                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                ></path>
                                            </svg>


                                        </div>
                                    }

                                    <div className="text-center z-0 mb-5">
                                        <ImgCrop
                                            showGrid
                                            rotationSlider
                                            aspectSlider
                                            showReset
                                        >
                                            <Upload
                                                listType="picture-circle"
                                                accept="image/*"
                                                fileList={logoFramefile}
                                                onChange={({ fileList }) => { LogFrameChange({ fileList, actionType: 'upload' }); setProcessedFrameLoader(true) }}
                                                onRemove={(file) => handleFrameImageDelete(file)}
                                            >
                                                <span className="btn btn-md bg-color_theme text-[#000] text-xs hover:text-color_theme hover:bg-[#000]">Upload </span>
                                            </Upload>
                                        </ImgCrop>

                                    </div>



                                </div>

                                {
                                    frame_name_array?.map((desc, index) => (
                                        <div className="mb-5" key={index}>
                                            <div className="flex items-center mb-2 justify-between">
                                                <label
                                                    htmlFor="Product"
                                                    className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                                                >
                                                    Frame {index + 1} Description
                                                </label>
                                            </div>

                                            <JoditEditor
                                                tabIndex={5}
                                                value={desc?.desc}
                                                onChange={(newContent) => handleDescriptionChange(index, newContent, desc.frame, "desc" , desc?.price )}
                                            />
                                            <div className="flex items-center mb-2 mt-3 justify-between">
                                                <label
                                                    htmlFor="Product"
                                                    className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                                                >
                                                    Frame {index + 1} Price
                                                </label>
                                            </div>
                                            <input
                                                type="number"
                                                name="price"
                                                required
                                                defaultValue={desc?.price || ""}
                                                onChange={(event) => handleDescriptionChange(index, event.target.value, desc.frame, 'price')}
                                                placeholder="price"
                                                className="p-3 border-[1px] border-[#e5e7eb] mt-3 focus:outline-0 w-full text-sm font-medium rounded-md"
                                            />
                                        </div>
                                    ))
                                }

                                {
                                    processedFrameImages.map((desc, index) => (
                                        <div className="mb-5" key={index}>
                                            <label
                                                htmlFor="Product"
                                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                                            >
                                                Frame {frame_name_array.length + index + 1} Description
                                            </label>

                                            <JoditEditor
                                                tabIndex={5}
                                                onChange={(newContent) => handleDescriptionChange(frame_name_array.length + index, newContent, "new_desc", 'desc')}
                                            />
                                            <div className="flex items-center mb-2 mt-3 justify-between">
                                                <label
                                                    htmlFor="Product"
                                                    className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                                                >
                                                    Frame {index + 1} Price
                                                </label>
                                            </div>
                                            <input
                                                type="number"
                                                name="price"
                                                required
                                                value={desc?.price}
                                                onChange={(event) => handleDescriptionChange(frame_name_array.length + index, event.target.value, "new_desc", 'price')}
                                                placeholder="price"
                                                className="p-3 border-[1px] border-[#e5e7eb] focus:outline-0 w-full text-sm font-medium rounded-md"
                                            />
                                        </div>
                                    ))
                                }

                                {producttype == 0 &&
                                    <div className=" border-[1px] p-2 overflow-x-auto  bg-[#f7f7f7] rounded border-gray-300 ">
                                        <div
                                            ref={specifications}

                                            className="flex flex-col mb-5 ">
                                            <label className="font-semibold text-xl">Product costing</label>
                                            {/* <span className="text-sm  mt-1" >Manage the product-related specifications..</span> */}
                                        </div>
                                        <div className="grid lg:grid-cols-2 mb-5 md:grid-cols-2 md:gap-8 gap-4">
                                            <div>
                                                <label
                                                    htmlFor="Categoryidentifier"
                                                    className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                                                >
                                                    Qty
                                                </label>
                                                <Input
                                                    name="Model"
                                                    ref={nameRef}

                                                    id="Model"
                                                    type="number"
                                                    autoComplete="off"

                                                    className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                                                />
                                                {errornameMessage && <span className="text-[red] text-xs">{errornameMessage}</span>}
                                            </div>
                                            <div>
                                                <label
                                                    htmlFor="Categoryidentifier"
                                                    className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                                                >
                                                    Price
                                                </label>
                                                <Input
                                                    name="price"
                                                    ref={valueRef}
                                                    id="price"
                                                    type="number"
                                                    autoComplete="off"
                                                    className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                                                />
                                                {errorvalueMessage && <span className="text-[red] text-xs">{errorvalueMessage}</span>}
                                                <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                                                    <span onClick={handleSubmittable} className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ">
                                                        <MdAdd className="mr-1" />
                                                        Add
                                                    </span>
                                                    <span
                                                        className="btn btn-md bg-[#000] text-color_theme ml-4 text-sm hover:bg-[#000]"
                                                        onClick={cleartable}
                                                    >
                                                        <MdOutlineClose className="mr-1" />
                                                        Clear
                                                    </span>



                                                </div>

                                            </div>
                                        </div>

                                        {specificationsList.length > 0 &&
                                            <div className="overflow-x-auto">
                                                <table className="mt-4 w-full">
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>Qty</th>
                                                            <th>Price</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {specificationsList.map((specification, index) => (
                                                            <tr className="text-center text-sm " key={index}>
                                                                {editIndex === index ? (
                                                                    <>
                                                                        <td>


                                                                            <Input
                                                                                type="text"
                                                                                value={specification.quantity}
                                                                                className=" focus:outline-0 w-full text-sm font-medium rounded-md"

                                                                                onChange={(e) => {
                                                                                    const updatedSpecification = { ...specification, quantity: e.target.value };
                                                                                    const updatedList = [...specificationsList];
                                                                                    updatedList[index] = updatedSpecification;
                                                                                    setSpecificationsList(updatedList);
                                                                                }}
                                                                            />

                                                                        </td>
                                                                        <td>

                                                                            <Input
                                                                                type="text"
                                                                                value={specification.price}
                                                                                className=" focus:outline-0 w-full text-sm font-medium rounded-md"

                                                                                onChange={(e) => {
                                                                                    const updatedSpecification = { ...specification, price: e.target.value };
                                                                                    const updatedList = [...specificationsList];
                                                                                    updatedList[index] = updatedSpecification;
                                                                                    setSpecificationsList(updatedList);
                                                                                }}
                                                                            />


                                                                        </td>


                                                                        <td>
                                                                            <span onClick={() => handleSave(index)} className="btn btn-xs bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ">
                                                                                <MdSave className="mr-1" />

                                                                            </span>
                                                                        </td>
                                                                    </>




                                                                ) : (
                                                                    <>
                                                                        <td className="mr-1">{index + 1})</td>
                                                                        <td style={{ whiteSpace: 'normal', wordBreak: 'break-all' }}>{specification.quantity || specification.quantity}</td>
                                                                        <td style={{ whiteSpace: 'normal', wordBreak: 'break-all' }}>{specification.price || specification.price}</td>

                                                                        {/* <td>{specification.name || specification.spec_name}</td>
                                                            <td>{specification.value || specification.spec_value}</td>
                                                            <td>{specification.group || specification.spec_group}</td> */}
                                                                        <td>
                                                                            <div className="flex justify-center space-x-1">
                                                                                <div
                                                                                    className="btn btn-xs bg-[#000] text-color_theme hover:bg-[#000]"
                                                                                    onClick={() => handleDeletetable(index, specification?.id)}
                                                                                >
                                                                                    <MdOutlineClose className="mr-1" />

                                                                                </div>
                                                                                <div
                                                                                    onClick={() => handleEdit(index)}
                                                                                    className="btn btn-xs bg-[#000] text-color_theme hover:bg-[#000]"
                                                                                >
                                                                                    <MdEdit className="mr-1" />

                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                    </>
                                                                )}
                                                            </tr>
                                                        ))}
                                                    </tbody>

                                                </table>
                                            </div>

                                        }


                                    </div>

                                }


                            </div>
                            <div className="md:w-5/12 w-full" >
                                <div className="grid lg:grid-cols-1 mb-5 md:grid-cols-1 md:gap-8 gap-4">


                                    <div className="flex p-2 bg-[#f7f7f7] rounded border-gray-300  flex-col ">
                                        {!editloadProductCatalog ?
                                            <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ">
                                                Update
                                            </button>
                                            :
                                            <button
                                                className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] "
                                                type="button"
                                            //  onClick={()=> changeStep("4")}
                                            >
                                                <svg
                                                    className="animate-spin mr-1 h-5 w-5 text-white"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    ></path>
                                                </svg>
                                                Loading..
                                            </button>
                                        }


                                        <div className="mt-3">
                                            <label class="relative bg-[#f1f1f1]  justify-between text-[#837575] w-full border border-[#7E7777] rounded-lg px-2 py-3 focus:outline-none inline-flex items-center mb-5 cursor-pointer">

                                                <span class=" text-sm  font-medium text-gray-900 dark:text-gray-300"> Activate it</span>
                                                <div className="relative">
                                                    <input
                                                        checked={ativate == "1"}
                                                        onChange={(e) => {
                                                            setativate(e.target.checked ? "1" : "0");
                                                        }}
                                                        type="checkbox" className="sr-only peer" />
                                                    <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>

                                                </div>
                                            </label>


                                        </div>
                                        <div className="mt-3">
                                            <label class="relative bg-[#f1f1f1]  justify-between text-[#837575] w-full border border-[#7E7777] rounded-lg px-2 py-3 focus:outline-none inline-flex items-center mb-5 cursor-pointer">

                                                <p className=" text-sm flex flex-col font-medium text-gray-900 dark:text-gray-300">
                                                    <span>Mark as featured </span>
                                                    <span className="text-xs text-gray-400 ">
                                                        Mark this product as a featured product, and it will be displayed under the featured product list on the front end.
                                                    </span>
                                                </p>
                                                <div className="relative">
                                                    <input value="1"
                                                        checked={product_featured == "1"}
                                                        onChange={(e) => {
                                                            setproduct_featured(e.target.checked ? "1" : "0");
                                                        }}
                                                        type="checkbox" className="sr-only peer" />
                                                    <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>

                                                </div>
                                            </label>


                                        </div>
                                        {/* <div className="mt-2">
                                            <label class="relative bg-[#f1f1f1]  justify-between text-[#837575] w-full border border-[#7E7777] rounded-lg px-2 py-3 focus:outline-none inline-flex items-center mb-5 cursor-pointer">

                                                <span class=" text-sm  font-medium text-gray-900 dark:text-gray-300"> Approval status</span>
                                                <div className="relative">
                                                    <input value="1"
                                                        onClick={(e) => setproduct_approved()}
                                                        type="checkbox" className="sr-only peer" />
                                                    <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>

                                                </div>
                                            </label>


                                        </div> */}
                                        {/* <div className="mt-2">
                    <label class="relative bg-[#f1f1f1]  justify-between text-[#837575] w-full border border-[#7E7777] rounded-lg px-2 py-3 focus:outline-none inline-flex items-center mb-5 cursor-pointer">

                        <span class=" text-sm  font-medium text-gray-900 dark:text-gray-300">Update other languages data</span>
                        <div className="relative">
                            <input value="1"
                                // onClick={(e) => setalert() } 
                                type="checkbox" className="sr-only peer" />
                            <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>

                        </div>
                    </label>


                </div> */}

                                    </div>
                                    {/* <div className="flex p-2 bg-[#f7f7f7] rounded border-gray-300  flex-col ">


                                        <div className="mt-3">
                                            <label class="relative bg-[#f1f1f1]  justify-between text-[#837575] w-full border border-[#7E7777] rounded-lg px-2 py-3 focus:outline-none inline-flex items-center mb-5 cursor-pointer">

                                                <p className=" text-sm flex flex-col font-medium text-gray-900 dark:text-gray-300">
                                                    <span>Mark as featured </span>
                                                    <span className="text-xs text-gray-400 ">
                                                        Mark this product as a featured product, and it will be displayed under the featured product list on the front end.
                                                    </span>
                                                </p>
                                                <div className="relative">
                                                    <input value="1"
                                                         checked={product_featured == "1"}
                                                         onChange={(e) => {
                                                            setproduct_featured(e.target.checked ? "1" : "0");
                                                         }}
                                                        type="checkbox" className="sr-only peer" />
                                                    <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>

                                                </div>
                                            </label>


                                        </div>

                                        <div className="mt-2">
                                            <label class="relative bg-[#f1f1f1]  justify-between text-[#837575] w-full border border-[#7E7777] rounded-lg px-2 py-3 focus:outline-none inline-flex items-center mb-5 cursor-pointer">

                                                <p className=" text-sm flex flex-col font-medium text-gray-900 dark:text-gray-300">
                                                    <span>Available for cash on delivery (COD)</span>
                                                    <span className="text-xs text-gray-400 ">
                                                        Activate this if the product is available for COD.
                                                        This feature is only allowed if the fulfillment method is Shipping.
                                                    </span>
                                                </p>
                                                <div className="relative">
                                                    <input value="1"
                                                        onClick={(e) => setcod_available()}
                                                        type="checkbox" className="sr-only peer" />
                                                    <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>

                                                </div>
                                            </label>


                                        </div>

                                    </div> */}



                                </div>
                            </div>
                        </form>


                    </div>

                </div>
            }
        </>

    );
});

