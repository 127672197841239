import { createContext, useContext } from 'react'
import { makeAutoObservable, observable, runInAction } from "mobx";
import { postLogin, getMethodData, patchMethodData } from "../../ApiMethods/api"
import { toast } from "react-toastify";

export class HomeStore {
  user = {};
  loaduser = false;
  nolist = false;
  errLoggingIn = "";
  users = [];
  Customer_count = "";
  Order_Count = "";

  buyer_details = [];
  todays_order = [];
  recent_orders = [];
  PlayerData = [];
  league_chart_data = [];
  players_chart_data = [];
  revanue_chart_data = [];
  loginLoading = false;
  current_leagues = "";
  userid = ""
  matches_this_week = "";
  tolal_income = "";
  approval_pending_home_courts = "";
  total_tieBreakerCount = "";
  tolal_leagues = "";
  tolal_matches = "";
  tolal_players = "";
  tolal_seasons = "";
  tolal_users = "";
  deleteuser_count = "";
  adminAuthApproved = false;
  Token = null;
  status = "";
  saved = false;

  constructor(isloading = false) {
    makeAutoObservable(this)
    this.Token = localStorage.getItem("Admintoken");
    if (isloading.isloading) {
      this.getDashboardData();

      return;
    }

  }
  getstatusid = (text) => {
    this.userid = text;
  }
  getNewstatus = (text) => {
    this.status = text;
  }

  // get Coupen list
  getDashboardData = () => {
    runInAction(() => {
      this.loaduser = true;
      this.nolist = false;
      this.errLoggingIn = true;
      this.errloadcoupon = "";
      this.saved = false;
    });
    getMethodData("/api/v1/admin/dashboard", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loaduser = false;
            this.loadCoupon = false;
            this.errloadcoupon = resp?.data?.message
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loaduser = false;
            this.loadCoupon = false;
            this.errloadcoupon = resp?.data?.message;
            if (resp?.data?.data?.new_users === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.todays_order = resp.data.data.todays_order.order_details;
            this.Customer_count = resp.data.data.total_buyers;
            this.Order_Count = resp.data.data.total_orders;
            this.buyer_details = resp.data.data.buyer_details;
            this.recent_orders = resp.data.data.recent_orders;

            console.log("resppppppppppppppppppppp", resp);
            this.saved = false;
          });
          return;
        }
        runInAction(() => {
          this.loaduser = false;
          this.addseasoncourt = false;
          this.loadSeason = false;
          this.errloadseason = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loaduser = false;
          this.loadCoupon = false;
          if (!error.response) {
            this.errloadcoupon = error?.message;
            toast.error(error?.message);
          }
          this.errloadcoupon = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        })
        return;
      });
  };
  statusChange = () => {
    runInAction(() => {
      this.nolist = false;
      this.errloaduser = "";
    });
    patchMethodData(`/api/admin/user/status/${this.userid}`, { isActive: `${!this.status}` }, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.errloaduser = resp?.data?.message;
            // toast.error(resp?.data?.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.errloaduser = resp?.data?.message;
            this.getDashboardCount();
            toast.success(resp?.data?.message);
          })
          return;
        }
        runInAction(() => {
          this.errloaduser = resp?.data?.message;
        })
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.errloaduser = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.errloaduser = error?.response?.data?.message;
        })
        return;
      })
  };





};


export const HomeStoreContext = createContext(new HomeStore());
export const useHomeStore = () => useContext(HomeStoreContext);