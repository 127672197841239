import React from "react";
import {
  MdAdd,
  MdFileDownload,
  MdFileUpload,
  MdOutlineLocationOn,
  MdRefresh,
} from "react-icons/md";
import { RxCheck, RxCheckCircled, RxCrossCircled, RxEyeOpen, RxPencil1 } from "react-icons/rx";
import { Link, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import Filter from "./HomeCourtFilter/Filter";
import ReactPaginate from "react-paginate";

import {
  useHomecourtStore,
  HomecourtStore,
  HomecourtStoreContext,
} from "./store/HomecourtStore";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { useAuthStore } from "../Auth/store/Auth";
const HomecourtMangement = () => {
  const store = new HomecourtStore({
    isloading: true,
    editloading: false,
    slectcity: true,
  });
  return (
    <HomecourtStoreContext.Provider value={store}>
      <Screen />
    </HomecourtStoreContext.Provider>
  );
};

export default HomecourtMangement;

const Screen = observer(() => {
  const {
    homecourt,
    ImportLoader,
    loadhomecourt,
    nolist,
    HomeLoader,
    ApproveHomeLoader,
    Homecourtpopup,
    HomecourtApprove,
    BulkHomeCourt,
    setBulkhomecourt,
    DelHomecourt,
    sethomecourtpopup,
    sethomecourtApprove,
    bulkupload,
    getid,
    Verifiedstatus,
    getstatus,
    setstatuspopup,
    setbulkpopupclose,
    statusChange,
    handleChange,
    setrejectpopup,
    setReason,
    reason,
    loadreject,
    rejectpopup,
    unsuccessdata,
    bulk,
    success,
    failed,
    setCsvFile,
    reset,
    setbulkpopup,
    getToken
  } = useHomecourtStore();

  const { adminAuthApproved } = useAuthStore();
  
  useEffect(() => {
    if (adminAuthApproved) {
      getToken(localStorage.getItem("Admintoken"));
    }
  
  }, [adminAuthApproved]);
  useEffect(() => {
    if (pendinghomecourts) {
      const element = document.getElementById("tablescroll");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  });
  const { pendinghomecourts } = useParams();
  const [currentPage, setCurrentPage] = useState(0);
  const ITEMS_PER_PAGE = 50;
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  const handleClick=()=>{
    const element = document.getElementById("tablescroll");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }
  const startIndex = currentPage * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentHomecourt = homecourt?.slice(startIndex, endIndex);
  const BASE_URL = process.env.REACT_APP_BASE_URL_HC;
  return (
    <>
    <div className="flex justify-between mt-[51px] ">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold">Home Court Management</p>
        </div>
        <div className="">
          <label
            title=""
            onClick={() => { setBulkhomecourt(true); setbulkpopup()}}
            htmlFor="csv"
            className="mr-2 btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
          >
            <MdFileDownload />
            Home Court Bulk Import
          </label>

          <Link
            to="/homecourtmanagement/add/addhomecourt"
            className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
          >
            <MdAdd />
            Add Court
          </Link>
        </div>
      </div>

      <Filter prop={pendinghomecourts} />
      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <div className="overflow-x-auto w-full">
          <table id="tablescroll" className="table table-zebra w-full">
            <thead>
              <tr>
                <th>#</th>
                <th>Court Nickname</th>
                <th>Court Name</th>
                <th>City</th>
                <th>Zip Code</th>
                <th>Added by user</th>
                <th>Admin Approved</th>
                {/* <th>Country</th> */}
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="text-sm relative">
              {loadhomecourt && (
                <>
                  <tr>
                    <td colSpan={9} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={9} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={9} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={9} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={9} className="animate-pulse py-6"></td>
                  </tr>
                </>
              )}

               {
                (currentHomecourt.length === 0 ?  homecourt : currentHomecourt).map((data, index) => (
                  <tr key={index} className="capitalize">
                    <td> {currentPage * ITEMS_PER_PAGE + index + 1}</td>
                    <td>{data?.displayName ?? "N/A"}</td>
                    <td>{data?.name ?? "N/A"}</td>
                    <td>{data?.city ? data?.city.city_name ?? "N/A" : "N/A"}</td>
                    <td>{data.zipcode ?? "N/A"}</td>
                    <td>{data?.suggestedBy === null ? "No" : "Yes"}</td>
                    <th>
                      <label
                        // title="verified"
                        // htmlFor={data.isVerified ?"": "verified" }
                        className={`btn capitalize ${
                          data?.isVerified
                            ? "bg-color_theme hover:bg-color_theme"
                            : "bg-warning hover:bg-warning"
                        }  text-[#000] border-hidden cursor-default btn-xs`}
                      >
                        {data?.isVerified ? "Yes" : "No"}
                      </label>
                    </th>
                    {/* <td>USA</td> */}
                    <th>
                      <label
                        title="Change Status"
                        htmlFor="courtstatus"
                        className={`btn capitalize ${
                          data?.isActive
                            ? "bg-color_theme hover:bg-color_theme"
                            : "bg-warning hover:bg-warning"
                        }  text-[#000] border-hidden  btn-xs`}
                        onClick={(e) => {
                          getid(data?._id);
                          getstatus(data?.isActive);
                          sethomecourtpopup(true);
                        }}
                      >
                        {data?.isActive ? "Active" : "Inactive"}
                      </label>
                    </th>
                    <td className="text-base">
                    <div className="flex">
                    <button title="Edit">
                          <Link to={`/homecourtmanagement/edit/${data?._id}`}>
                            <RxPencil1 />
                          </Link>
                        </button>
                        <label htmlFor="HomeCourtApprove"   onClick={(e) => {
                          sethomecourtApprove(true);
                          getid(data?._id);
                          setrejectpopup()
  
                        }}   data-tip="Remove/Reject"  className="ml-5 text-[#f06060] tooltip tooltip-left" >
                        
                        {!data?.isVerified  ? <RxCrossCircled />: null }    
                        
                        </label>
                      </div>
                  
                    </td>
                  </tr>
                ))
               }
              
            
              {nolist && (
                <tr>
                  <td colSpan={9} className="my-10 text-center">
                    <MdOutlineLocationOn className=" text-[#dedede] text-8xl mx-auto" />
                    <p className=" font-semibold text-lg">No Court Found</p>
                    <Link
                      to="./add/addhomecourt"
                      className="btn btn-sm mt-4 bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                    >
                      <MdAdd />
                      Add Court
                    </Link>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {!homecourt?.length == 0 &&
          <ReactPaginate
          initialPage={0}
          pageCount={Math.ceil(homecourt?.length / ITEMS_PER_PAGE)}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          onPageChange={handlePageChange}
          onClick={handleClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
           }
          
           {/* HomeCourt approve */}
           {/* {HomecourtApprove && (
            <>
              <input
                type="checkbox"
                id="HomeCourtApprove"
                className="modal-toggle"
              />
              <div className="modal">
                <div className="modal-box relative">
                  <label
                    className="btn btn-sm btn-circle absolute right-2 top-2"
                    onClick={() => sethomecourtApprove(false)}
                  >
                    ✕
                  </label>
                  <h3 className="text-lg font-bold text-center">
                    Are you sure you want to reject and remove this  Home Court  ?
                  </h3>
                  <div className="modal-action justify-center">
                    {!ApproveHomeLoader ? (
                      <label
                        className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                        onClick={() =>DelHomecourt()
                        }
                      >
                        Yes
                      </label>
                    ) : (
                      <button
                        // type="submit"
                        disabled
                        className="flex items-center bg-color_theme px-3 mx-5 rounded-md"
                        // onClick={() => {
                        //   PostMatchscore();
                        // }}
                      >
                        Yes..
                        <svg
                          className="animate-spin mr-1 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </button>
                    )}

                    <label
                      onClick={() => sethomecourtApprove(false)}
                      // htmlFor="voidstatus"
                      className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>
            </>
          )} */}




          {/* status change model start */}
          {Homecourtpopup && (
            <>
              <input
                type="checkbox"
                id="courtstatus"
                className="modal-toggle"
              />
              <div className="modal">
                <div className="modal-box relative">
                  <label
                    className="btn btn-sm btn-circle absolute right-2 top-2"
                    onClick={() => sethomecourtpopup(false)}
                  >
                    ✕
                  </label>
                  <h3 className="text-lg font-bold text-center">
                    Are you sure you want to change Status ?
                  </h3>
                  <div className="modal-action justify-center">
                    {!HomeLoader ? (
                      <label
                        className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                        onClick={() => statusChange()}
                      >
                        Yes
                      </label>
                    ) : (
                      <button
                        // type="submit"
                        disabled
                        className="flex items-center bg-color_theme px-3 mx-5 rounded-md"
                        // onClick={() => {
                        //   PostMatchscore();
                        // }}
                      >
                        Yes..
                        <svg
                          className="animate-spin mr-1 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </button>
                    )}

                    <label
                      onClick={() => sethomecourtpopup(false)}
                      // htmlFor="voidstatus"
                      className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>
            </>
          )}
  { BulkHomeCourt && (
            <>
          <input type="checkbox" id="csv" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <form
                onSubmit={(e) => {
                  bulkupload();
                  e.preventDefault();
                }}
              >
                <button type="reset">
                  <label
                    type="reset"
                    // htmlFor="csv"
                    className="btn btn-sm btn-circle absolute right-2 top-2"
                    onClick={() => {
                      setbulkpopupclose();
                      setBulkhomecourt(false)
                    }}
                  >
                    ✕
                  </label>
                </button>
                Download Sample File -:{" "}
                <a
                  href={`${BASE_URL}/court_addresses_import.csv`}
                  className=" btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] "
                  download
                >
                  <MdFileDownload /> Download
                </a>
                <input
                  required
                  value={setCsvFile?.value}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  type="file"
                  className="mt-3 input focus:outline-0 w-full p-3 text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
                  accept=".csv"
                />
                <div className="modal-action justify-center">
                  {!bulk ? (
                    <>
                      <button
                        type="reset"
                        onClick={reset}
                        className=" btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                      >
                        <MdRefresh />
                        Reset
                      </button>
                      {!ImportLoader ? (
                        <button
                          type="submit"
                          className=" btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                        >
                          <MdFileDownload /> Import
                        </button>
                      ) : (
                        <button
                          className=" btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                          type="button"
                          //  onClick={()=> changeStep("4")}
                        >
                          <svg
                            className="animate-spin mr-1 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                          Loading..
                        </button>
                      )}
                    </>
                  ) : (
                    <button
                      type="button"
                      className="mt-5 btn w-full bg-[#000] hover:bg-[#000] text-color_theme hover:text-color_theme font-semibold border-none flex justify-center items-center"
                    >
                      <svg
                        className="animate-spin mr-1 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    </button>
                  )}
                </div>
              </form>

              {unsuccessdata.length == 0 ? (
                <></>
              ) : (
                <>
                <div className="flex justify-between mt-[51px] ">
                    <div className=" mb-4 mt-9">
                      <p className=" text-2xl font-semibold text-[#ff2d2d]">
                        Result
                      </p>
                    </div>
                    <div className="">
                      <div
                        title="Success Count"
                        className="mt-9 ml-28 btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                      >
                        <span>Successful: {success}</span>
                      </div>
                    </div>
                    <div
                      title="Success Count"
                      className="mt-9 btn btn-sm bg-[#000] text-[#ff2d2d] text-sm hover:bg-[#000]"
                    >
                      <span>Failed: {failed}</span>
                    </div>
                  </div>
                  <div className="max-h-[300px] overflow-auto">
                    <table className="table table-zebra w-full">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>City</th>
                          <th>Address</th>
                          <th>Name</th>
                          <th>Zip Code</th>
                        </tr>
                      </thead>
                      <tbody className="text-sm relative">
                        {unsuccessdata.map((data, index) => (
                          <tr key={index} className="capitalize">
                            <td>{index + 1}</td>
                            <td>{data?.city}</td>
                            <td>{data?.address}</td>
                            <td>{data?.name ?? "N/A"}</td>
                            <td>{data.zipcode ?? "N/A"}</td>
                          </tr>
                        ))}
                        {nolist && (
                          <tr>
                            <td colSpan={5} className="my-10 text-center">
                              <MdOutlineLocationOn className=" text-[#dedede] text-8xl mx-auto" />
                              <p className=" font-semibold text-lg">
                                No Court Found
                              </p>
                              <Link
                                to="./addhomecourt"
                                className="btn btn-sm mt-4 bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                              >
                                <MdAdd />
                                Add Court
                              </Link>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </div>
          </div>
              </>
              )}

        {/* popup for Refund */}
        <input readOnly checked={rejectpopup} type="checkbox" id="verified" className="modal-toggle" />

<div className="modal">
  <div className="modal-box relative">
    <form 
    autoComplete="off"
        onSubmit={(e) => {
          DelHomecourt()
          e.preventDefault();
        }}
    
    >
    <label
    onClick={()=>{setrejectpopup();setReason("");}}
      htmlFor="refundpopup"
      className="btn btn-sm btn-circle absolute right-2 top-2"
    
    >
      ✕
    </label>
    {/* <h3 className="text-lg font-bold text-center">
    Mention Your Reason For Rejection To this Home Court 

    </h3> */}
   

      <div className="py-1">
      <label htmlFor="Remark" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">     Mention Your Reason For Rejection of this Home Court 
<span className="text-[#f00]">&nbsp;*</span></label>
   <textarea
    defaultValue=""
    name="Remark"
    autoComplete="Remark"
    id="Remark"
    value={reason}
    onChange={(e) => setReason(e.target.value)}
    required
    className="  text-[#000] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
    placeholder="Remark"
   ></textarea>
   </div>
   
    <div className="modal-action justify-center">
{!ApproveHomeLoader ? (   
<button type="submit"   className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">Reject</button>

) : (
<button
className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
type="button"
//  onClick={()=> changeStep("4")}
>
<svg
 className="animate-spin mr-1 h-5 w-5 text-white"
 xmlns="http://www.w3.org/2000/svg"
 fill="none"
 viewBox="0 0 24 24"
>
 <circle
   className="opacity-25"
   cx="12"
   cy="12"
   r="10"
   stroke="currentColor"
   strokeWidth="4"
 ></circle>
 <path
   className="opacity-75"
   fill="currentColor"
   d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
 ></path>
</svg>
Submitting..
</button>)}






 <label
        htmlFor="refundpopup"
        onClick={()=>{setrejectpopup();setReason("");}}
        className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
       
      >
        Cancel
      </label>
   
    </div>
    </form>
 
  </div>
</div>





         
        </div>
      </div>
    </>
  );
});
