import React from 'react'
import { PlayerStore, PlayerStoreContext, usePlayerStore } from './store/PlayerStore';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';


const PlayerDetail = () => {

    const { id } = useParams();
    const store = new PlayerStore({
      isloading: true,
       id:id,
    //   levelid:levelid,
    });
    return (
      <PlayerStoreContext.Provider value={store}>
        <Screen />
      </PlayerStoreContext.Provider>
    );
  };
  export default PlayerDetail;

const Screen = observer(() => {
    const { id , name } = useParams();
 const [division, setDivision] = useState(true);

 const {
    getSeasonYear,
    getSeasons,
    getMatchDetails,
    seasons,
    seasondropdown,
    SeasonYear,
    gethistory,
    nolistHistory,
  } =usePlayerStore()

  const goBack = () => {
    window.history.back(); // Go back to the previous pagehistory.goBack(); // Go back to the previous page
  };

  return (
        <>
        
        <div className='flex justify-between'>
        <div className=" mb-4">
        <p className=" text-2xl font-semibold">Player History for <span className='text-color_theme'>{name}</span> </p>
        </div>
        <div>
        <Link 
        onClick={goBack}
        className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]">
        Back
        </Link>
        </div>
        </div>
        <div className=" bg-[#fff] grid-cols-3 p-3 border-2 border-[#55ed5a]">
          {/* <div className=" flex items-center  justify-end   relative z-10">
            <div
              onClick={() => {
                setDivision(true);
                // setQutarFinal(false);
                setActiveTab("Season");
              }}
              className={`box-border border-[1px]  cursor-pointer flex flex-col mt-5 border-r border-[gray] justify-center items-center  bg-[#fff] h-10 w-[6rem] text-[12px] xl:p-[.30rem]  ${
                activeTab === "Season" ? "bg-color_theme" : ""
              }`}
            >
              <p className=" text-[#7E7777] font-medium md:text-3xl text-2xl  relative"></p>
              <p className=" text-[#7E7777] font-light ">Season</p>
            </div>

            <div
              onClick={() => {
                setDivision(false);
                getMatchDetails(id);
                setActiveTab("Matches");
              }}
              className={`box-border border-[1px]  cursor-pointer border-[gray]   flex flex-col mt-5  justify-center items-center  bg-[#fff] h-10 w-[6rem] text-[12px] xl:p-[.30rem]  ${
                activeTab === "Matches" ? "bg-color_theme" : ""
              }`}
            >
             <button
                      onClick={() => {
                        getMatchDetails(id);
                      }}
                      className=" text-[#7E7777] font-light"
                    >
                      Matches
                    </button>
            </div>
          </div> */}

        
            <label className="font-bold text-2xl text-[#000]">Seasons History</label>
         
          <div className="flex justify-end">
              <div className="w-2/12">
                <select
                  value={SeasonYear}
                  onChange={(e) => {
                    getSeasonYear(e.target.value);
                  }}
                  name="Year"
                  id="Year"
                  // required
                  className=" bg-[#f1f1f1] mt-3 text-[#837575] w-full border border-[#7E7777] rounded-lg px-3 focus:outline-none"
                >
                  <option
                    onChange={(e) => getSeasonYear(e.target.value)}
                    value={new Date().getFullYear()}
                  >
                    {new Date().getFullYear()}
                  </option>
                  {new Date().getFullYear() > 2023 ? (
                    <option
                      onChange={(e) => getSeasonYear(e.target.value)}
                      value={new Date().getFullYear() + 1}
                    >
                      {new Date().getFullYear() + 1}
                    </option>
                  ) : null}
                </select>
              </div>
      
          </div>

          <div
              id="style-1"
              className="card bg-base-100 shadow-md p-6"
            >
              <div className="overflow-x-auto w-full">
                <table id="tablescroll" className="table table-zebra w-full">
                  <thead>
                    <tr>
                      <th className="py-5">#</th>
                      <th className="py-5">League</th>
                      <th className="py-5">Division</th>
                      <th>Level</th>
                      <th>Win %</th>
                      <th>Loss %</th>
                    </tr>
                  </thead>
                  <tbody className="text-sm relative" >
                    {gethistory.map((data, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td className="py-5 capitalize">
                          <Link
                            
                            className="underline cursor-pointer hover:text-color_theme"
                             to={`/matchhistory/${id}/${data?._id}`}
                          >
                            {data?.league?.name ?? "N/A"}
                          </Link>
                        </td>
                        <td>{data?.division?.code ?? "N/A"}</td>
                        <td>{data?.level?.name.toFixed(1) ?? "N/A"}</td>
                        <td>{Math.trunc(data?.win) ?? "0"}%</td>
                        <td>{Math.trunc(data?.loss) ?? "0"}%</td>
                      </tr>
                    ))}

                    {nolistHistory && (
                      <tr>
                        <td colSpan={6} className="py-20 ">
                          <p className=" text-center capitalize font-semibold text-2xl">
                           This Player Not Played Any
                            Seasons Yet.
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {/* <div className="text-[#3636364d] flex justify-center mt-40 text-3xl ">
                    You have not played any season yet
                  </div> */}
            </div>

       
        </div>

        </>
    
  )
})

