import React, { useEffect } from "react";
import {Link,useNavigate} from "react-router-dom";
import { MdOutlineClose,MdOutlineCheck } from "react-icons/md";
import {Input,Button} from "react-daisyui"
import { StateStore , useStateStore , StateStoreContext } from "./StateStore/StateStore";
import { observer } from "mobx-react";
import { useAuthStore } from "../Auth/store/Auth";


const AddStateManagement = observer(() => {
   const {addstatus ,addstate,addstatelist, saved,loadstate,getToken}=useStateStore()
   const { adminAuthApproved } = useAuthStore();
    const navigate = useNavigate()

    useEffect(() => {
        if (adminAuthApproved) {
          getToken(localStorage.getItem("Admintoken"));
        }
      
      }, [adminAuthApproved]);
    
    useEffect(() => {
        if(saved){
            navigate("../statemanagement")
        }
    },[saved])
    return(
        <>
        <div className='flex justify-between'>
            <div className=" mb-4">
                <p className=" text-2xl font-semibold">Add State</p>
                {/* <p className=" opacity-80 text-sm">Home /City/ Add</p> */}
            </div>
            <div>
                <Link to="../statemanagement" className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]">Back</Link>
            </div>
        </div>
        <div className="card bg-base-100 shadow-md p-6 mt-8">
            <form onSubmit={(e) => {addstatelist();e.preventDefault()}}>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
                  
                    <div>
                            <label htmlFor="statename" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">State Name</label>
                            <Input
                                name="statename"
                                required
                                onChange={(e) => addstate(e.target.value)}
                                placeholder="State Name"
                                id="statename"
                                type="text"
                                autoComplete="off"
                                className="capitalize focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>
                    <div>
                        <label htmlFor="Country" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Country<span className="text-[#f00]">&nbsp;*</span></label>
                        <select 
                        id="Country"
                        name="Country"
                        className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                            <option value="USA">USA</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="Status" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Status</label>
                        <select 
                        required
                        id="Status"
                        onChange={(e) => addstatus(e.target.value)}
                        name="Status"
                        className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                            <option value="true">Active</option>
                            <option value="false">Inactive </option>
                        </select>
                    </div>
                </div>
                <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                        <Link to="../statemanagement" className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"><MdOutlineClose className="mr-1"/>Cancel</Link>
                        {!loadstate?
                        <button  className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"><MdOutlineCheck className="mr-1"/>Add State</button>
                        :
                        <button 
                                       className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                        type="button"
                                      //  onClick={()=> changeStep("4")}
                                      >
                                      <svg
                                          className="animate-spin mr-1 h-5 w-5 text-white"
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          >
                                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                          <path
                                              className="opacity-75"
                                              fill="currentColor"
                                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                          ></path>
                                      </svg>
                                        Loading..
                                      </button>
                    }
                </div>
            </form>
        </div>
                
        </>
    )
})
export default AddStateManagement;