import { Link } from "react-router-dom";
import nopage from "../assets/images/nopage.gif";

export const PageNotFound = () => {
    return(
        <div className="w-full bg-[#fff] flex items-center justify-center">
            <div className="text-center">
                <p className="text-8xl font-bold relative top-24"></p>
                <img className="h-[400px] object-contain" src={nopage}/>
                <div className=" relative -top-16">
                    <p className=" text-3xl font-semibold">In Progress</p>
                    {/* <p className=" text-base opacity-90">In Progress</p> */}
                    <Link to="/">
                        <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] mt-4">
                            Go TO DASHBOARD
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    )
}