import { createContext, useContext } from "react";
import { action, makeAutoObservable, makeObservable, observable, runInAction } from "mobx";
import { postMethodData, getMethodData, putMethod, delMethod, patchMethodData } from "../../ApiMethods/api";
import { toast } from "react-toastify";
const SEO_URL = process.env.REACT_APP_SEO_URL;





export class BulkOrderStore {

    Token = null;
    BulkOrder = [];
    type = "";
    BulkOrderLoader = false;
    BulkOrdertype = [];
    filterSearch = "";
    PaginationPage = "1";
    TotalPages = "";
    BulkOrderDimiensions = ""
    crop = {};
    errLoadBulkOrder = "";
    LoadBulkOrder = false;
    delateLoader = false;
    nolist = false;
    BulkOrderpopup = false;
    deletepopup = false;
    BulkOrdername = "";
    status = "1";
    statesid = "";
    BulkOrderid = [];
    seoUrl = "";
    statuspopup = false;
    addBulkOrdername = "";
    imageurl = "";
    logoimage = "";
    BulkOrderimage = "";
    Base64logoImage = "";
    Base64BulkOrderImage = "";
    logofile = [];
    allBulkOrder = [];
    alldeactivate = 'false';
    isBulkOrderUpdated = "false";
    isLogoUpdated = "false"
    checkeddd = '';
    BulkOrderFormDetails = {
        "title": "",
        "section_name": "",
        "url": "",
        "section_id": "",
        "image_name": "",
        "is_active": ""
    };
    saved = false;

    constructor(isloading = false) {
        makeAutoObservable(this, {
            addBulkOrdername: observable,
            status: observable,
            errorMessages: observable,
            setaddBulkOrdername: action,
            getstatus: action,
            resetErrors: action,
        });

        this.Token = localStorage.getItem("Admintoken");
        if (isloading.isloading) {
            this.getBulkOrderList();
            //   this.getStateList();
            return
        }
        // if (isloading.addloading) {
        //     this.getBulkOrderTypeList();
        // }
        // if (isloading.BulkOrdereditloading) {
        //     this.getBulkOrderform(isloading.id);
        //     this.getBulkOrderTypeList();

        //     return
        // }
    }
    //All Clear 
    allclear = () => {
        this.filterSearch = ""
        this.getFilterBulkOrder();
        //   this.status="";
        //   this.statesid="";
    }
    setimage = (text, type) => {
        this.imageurl = URL.createObjectURL(text[0]);
    }

    setalldeactivate = (text) => {
        console.log(text);
        this.alldeactivate = !this.alldeactivate;
    };

    getid = (text) => {
        this.BulkOrderid.push(text);
    };



    // status popup
    setstatuspopup = () => {
        this.statuspopup = !this.statuspopup;
    };


    setallBulkOrder = (text) => {
        this.allBulkOrder = text;
    }

    // get status 
    getstatus = (text) => {
        this.status = text;
    };

    //get city name
    getBulkOrdername = (text) => {
        this.BulkOrdername = text;
    };

    setaddBulkOrdername = (text) => {
        this.addBulkOrdername = text;
    };
    setCrop = (text) => {
        this.crop = text;
    };

    setBulkOrderDimiensions = (text) => {
        this.BulkOrderDimiensions = text;
    };

    setlogofile = (e) => {
        this.logofile = e;
        console.log(this.logofile);
    };

    setBulkOrderpopup = (e) => {
        this.BulkOrderpopup = e;
    };
    setdeletepopup = (e) => {
        this.deletepopup = e;
    };
    setdelateLoader = (e) => {
        this.delateLoader = e;
    }
    getToken = (e) => {
        this.Token = e;
    }
    gettype = (e) => {
        this.type = e;
    }
    getseourl = (text) => {
        // console.log(`https://demo.e-kart.com/${text}`);
        this.seoUrl = text;
    };
    getlogoimage = (text) => {
        console.log("checking in the BulkOrder", text);
        this.logoimage = text;
    };
    getBulkOrder_image = (text) => {
        console.log(text);
        this.BulkOrderimage = text;
    };
    SetfilterSearch = (text) => {
        this.filterSearch = text;
    };
    setPaginationPage = (text) => {
        this.PaginationPage = text;
    };
    SetTotalPages = (text) => {
        this.TotalPages = text;
    };
    setchecked = (text) => {
        this.checkeddd = text;
    };
    setisBulkOrderUpdated = (text) => {
        this.isBulkOrderUpdated = text;
    };
    setisLogoUpdated = (text) => {
        this.isLogoUpdated = text;
    };

    isBase64 = (str) => {
        try {
            return btoa(atob(str)) === str;
        } catch (err) {
            return false;
        }
    };

    setBulkOrderFormDetails = (BulkOrderDetail) => {
        this.BulkOrderFormDetails = {
            ...this.BulkOrderFormDetails,
            title: BulkOrderDetail.title || "", // Use default value if name is undefined
            url: BulkOrderDetail.url || "", // Use default value if seo_url is undefined
            // logo_image: this.isBase64( this.BulkOrderFormDetails.logo_image) ? this.logofile.length == 0 ? BulkOrderDetail.logo_image    : this.BulkOrderFormDetails.logo_image  : this.logofile.length !== 0 ? this.BulkOrderFormDetails.logo_image :  BulkOrderDetail.logo_image   || "",
            // BulkOrder_image: this.isBase64(BulkOrderDetail.BulkOrder_image) ? this.BulkOrderFormDetails.BulkOrder_image : BulkOrderDetail.BulkOrder_image || "",
            image_name: BulkOrderDetail.image_name || "",
            section_id: BulkOrderDetail.section_id || "",
            section_name: BulkOrderDetail.section_name || "",

            is_active: BulkOrderDetail.is_active
        };
    };
    // setBulkOrderFormDetails = (text) => {
    //     this.BulkOrderFormDetails = text;
    //     console.log("setBulkOrderFormDetails", this.BulkOrderFormDetails.BulkOrdername);
    // };
    base64toFile(base64Data, fileName, mimeType) {
        if (base64Data) {
            const byteCharacters = atob(base64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters?.length; offset += 512) {
                const slice = byteCharacters?.slice(offset, offset + 512);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const file = new File(byteArrays, fileName, { type: mimeType });

            return file;
        }

    }

    // get BulkOrder list
    getBulkOrderList = () => {
        runInAction(() => {
            this.BulkOrderLoader = true;
            this.errLoadBulkOrder = "";
            this.saved = false;
            this.nolist = false;
            this.BulkOrder = [];
        });
        getMethodData(`/api/v1/admin/order/bulk-order?page&limit=${this.PaginationPage}`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.BulkOrderLoader = false;
                        this.errLoadBulkOrder = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.BulkOrderLoader = false;
                        this.errLoadBulkOrder = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.BulkOrder = resp?.data?.data;
                        // this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.BulkOrderLoader = false;
                    this.errLoadBulkOrder = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.BulkOrderLoader = false;
                    if (!error.response) {
                        this.errLoadBulkOrder = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadBulkOrder = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };


    // filter League court

    getFilterBulkOrder = () => {
        runInAction(() => {
            this.BulkOrderLoader = true;
            this.errBulkOrderLoader = "";
            this.saved = false;
            this.nolist = false;
            this.getBulkOrderList = [];
        });
        postMethodData(`/api/v1/admin/order/bulk-order?page&limit=${this.PaginationPage}`, {
            keyword: this.filterSearch
        }, this.Token)
            .then((resp) => {

                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.loadleague = false;
                        this.BulkOrderLoader = false;

                        this.errloadleague = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {

                    runInAction(() => {
                        this.loadcity = false;
                        this.BulkOrderLoader = false;
                        this.errloadleague = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.BulkOrder = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.loadcity = false;
                    this.errloadcuty = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.loadleague = false;
                    this.BulkOrderLoader = false;

                    if (!error.response) {
                        this.errloadleague = error?.message;
                        toast.error(error?.message);
                    }
                    this.errloadleague = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };



    //Active and inactive
    statusChange = () => {
        runInAction(() => {
            this.SectionLoader = true;
            this.Sectionpopup = true;

            this.errLoadSection = "";
        });
        postMethodData(`/api/v1/admin/BulkOrder/update-status`, {

            "record_id": this.BulkOrderid,
            "status": JSON.stringify(this.status)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.Sectionid = [];
                        this.BulkOrderid = [];

                        this.SectionLoader = false;
                        this.BulkOrderpopup = false;
                        this.errLoadSection = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.SectionLoader = false;
                        this.BulkOrderpopup = false;
                        this.errLoadSection = resp?.data?.message;
                        this.getFilterBulkOrder();
                        this.Sectionid = [];
                        this.BulkOrderid = [];
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.SectionLoader = false;
                    this.BulkOrderpopup = false;

                    this.errLoadSection = resp?.data?.message;
                })
            })
            .catch((error) => {
                this.BulkOrderid = [];

                runInAction(() => {
                    if (!error.response) {
                        this.SectionLoader = false;
                        this.BulkOrderpopup = false;
                        this.Sectionid = [];
                        this.errLoadSection = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.SectionLoader = false;
                    this.BulkOrderpopup = false;
                    this.Sectionid = [];


                    this.errLoadSection = error?.response?.data?.message;
                })
                return;
            })
    };


    //Delete method
    deletefunction = () => {
        runInAction(() => {
            this.setdelateLoader(true);
            this.deletepopup = true;
            this.errLoadBulkOrder = "";
        });
        postMethodData(`/api/v1/admin/BulkOrder/delete`, {

            "record_id": this.BulkOrderid,

        }, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.BulkOrderid = [];

                    this.LoadBulkOrder = false;
                    this.setdelateLoader(true);
                    this.deletepopup = true;
                    this.errLoadBulkOrder = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.LoadBulkOrder = false;
                    this.BulkOrderid = [];

                    this.setdelateLoader(false);
                    this.deletepopup = false;
                    this.errLoadBulkOrder = resp?.data?.message;
                    this.getFilterBulkOrder();
                    toast.success(resp.data.message);
                })
                return;
            }
            runInAction(() => {
                this.LoadBulkOrder = false;
                this.errLoadBulkOrder = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadcity = false;
                    this.BulkOrderid = [];

                    if (!error.response) {
                        this.LoadBulkOrder = false;
                        this.setdelateLoader(false);
                        this.deletepopup = false;
                        this.errLoadBulkOrder = error?.message;
                        toast.error(error?.message);
                    }
                    this.LoadBulkOrder = false;
                    this.errLoadBulkOrder = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            })
    }






}




export const BulkOrderStoreContext = createContext(new BulkOrderStore());
export const useBulkOrderStore = () => useContext(BulkOrderStoreContext);
