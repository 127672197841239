import React from 'react'
import Graph from './ui/Graph'
import Table from './ui/Table'
import CardDashbord from './ui/CardDashbord'

const Dashboard = () => {
  return (
    <>
      {/* <div className=" mb-4">
        <p className=" text-2xl font-semibold">Welcome To Dashboard</p>
        <p className=" opacity-80 text-sm">Home / Dashboard</p>
      </div> */}
      <CardDashbord/>
      {/* <Graph/>
      <Table/> */}
    </>
  )
}

export default Dashboard