import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    MdOutlineClose,
    MdOutlineCheck,
    MdOutlineRefresh,
    MdOutlineSearch,
} from "react-icons/md";
import { Input, Button } from "react-daisyui";
// import { useCityStore } from "../store/CityStore";
import { observer } from "mobx-react";
import { useUserStore } from "../store/UserStore"; 

const Filter = observer((id) => {
    const {
        getbrandname,
        getFilterUsersearch,
        SetfilterSearch,
        getFilterUser,
        getBrandList,
        allclear,
        brandname,
    } = useUserStore();
    return (
        <>
            <div className="cardbg-base-100 shadow-md p-6  mb-10">
                <form
                    onSubmit={(e) => {
                        getFilterUsersearch();
                        e.preventDefault();
                    }}
                    className=" flex flex-row justify-between  "
                >
                    <div className="w-6/12 ">
                        <div>
                            {/* <label
                htmlFor="Brandname"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Brand Name
              </label> */}
                            <input
                                name="username"
                                onChange={(e) => SetfilterSearch(e.target.value)}
                                placeholder="Search By user"
                                id="username"
                                type="text"
                                autoComplete="off"
                                className="input focus:outline-0 w-full text-sm font-medium rounded-md capitalize focus:outline-offset-0 input-bordered"
                            />
                        </div>
                        {/* <div>
                        
                        <select
                            id="addedby"
                            name="addedby"
                            defaultValue=""
                            // onChange={(e) => setaddedby(e.target.value)}
                            className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                        >
                            <option value="" className="">
                            Permissions Type
                            </option>
                            <option value="none">None</option>
                            <option value="read">Read only</option>
                            <option value="write">Read and write</option>

                        </select>
                    </div> */}
              
                    </div>
                 

                    <div >
                        <button
                            type="reset"
                            onClick={() => {
                                allclear(id.id);
                                // getBrandList();
                            }}
                            className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                        >
                            <MdOutlineRefresh className="mr-1" />
                            Reset
                        </button>
                        <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
                            <MdOutlineSearch className="mr-1" />
                            Search
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
});
export default Filter;
