import React from "react";
import { MdAdd, MdOutlinedFlag } from "react-icons/md";
import { RxEyeOpen, RxPencil1 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import JoditEditor from 'jodit-react';
import { useCmsStore, CmsStore, CmsStoreContext } from "./Store/CmsStore";
const CmsManagement = () => {
  const store = new CmsStore({
    isloading: true,
    editloading: false,
  });
  return (
    <CmsStoreContext.Provider value={store}>
      <Screen />
    </CmsStoreContext.Provider>
  );
};
export default CmsManagement;

const Screen = observer(() => {
  const { cms, getstatus, getcmsid, statusChange,loadCms,nolist } = useCmsStore();
  return (
    <>
    <div className="flex justify-between mt-[51px] ">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold">Cms Management</p>
          {/* <p className=" opacity-80 text-sm">Home / League</p> */}
        </div>
      </div>

      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <div className="overflow-x-auto w-full">
          <table className="table table-zebra w-full">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                {/* <th>Content</th> */}
                <th>Status</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody className="text-sm relative">
              {loadCms && (
                <>
                  <tr>
                    <td colSpan={4} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={4} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={4} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={4} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={4} className="animate-pulse py-6"></td>
                  </tr>
                </>
              )}

              {cms.map((data, index) => (
                <tr key={index} className="capitalize">
                  <td>{index + 1}</td>
                  <td>{data?.name}</td>
                  {/* <td>{data?.content}</td> */}
                  <th>
                    <label
                      title="Cms Status"
                      htmlFor="cmsstatus"
                      className={`btn capitalize ${
                        data?.isActive
                          ? "bg-color_theme hover:bg-color_theme"
                          : "bg-warning hover:bg-warning"
                      } text-[#000] border-hidden  btn-xs`}
                      onClick={(e) => {
                        getcmsid(data?._id);
                        getstatus(data?.isActive);
                      }}
                    >
                      {data?.isActive ? "Active" : "Inactive"}
                    </label>
                  </th>
                  <td className="text-base">
                    <div className="flex items-center space-x-4">
                      <button title="Edit">
                        <Link to={`/cmsmanagement/${data?._id}`}>
                          <RxPencil1 />
                        </Link>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}

              {nolist && (
                <tr>
                  <td colSpan={4} className="my-10 text-center">
                    <MdOutlinedFlag className=" text-[#dedede] text-8xl mx-auto" />
                    <p className=" font-semibold text-lg">No Cms Found</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <input type="checkbox" id="cmsstatus" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="cmsstatus"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status ?
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor="cmsstatus"
                  className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                  onClick={() => statusChange()}
                >
                  Yes
                </label>
                <label
                  htmlFor="cmsstatus"
                  className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                >
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
