import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { Input } from "react-daisyui";
import {
  useSeasonStore,
  SeasonStore,
  SeasonStoreContext,
} from "./store/SeasonStore";
import { MdOutlineCheck, MdOutlineClose } from "react-icons/md";
import { useAuthStore } from "../Auth/store/Auth";

const EditSeason = observer(() => {
  const { id } = useParams();
  return (
    <SeasonStoreContext.Provider
      value={
        new SeasonStore({
          loadeditcitybol: false,
          editloading: true,
          slectcity: true,
          id: id,
        })
      }
    >
      <Screen />
    </SeasonStoreContext.Provider>
  );
});

export default EditSeason;

const Screen = observer(() => {
  const {
    getupdatedstatus,
    updatedEndDate,
    updatedStartDate,
    editstartdate,
    seasonname,
    citydrop,
    updatedcityname,
    newcityname,
    editname,
    saved,
    edityear,
    edityears,
    updatedseason,
    updatedStatus,
    editenddate,
    editseasonname,
    getToken
  } = useSeasonStore();
  const { adminAuthApproved } = useAuthStore();
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (adminAuthApproved) {
      getToken(localStorage.getItem("Admintoken"));
    }
  
  }, [adminAuthApproved]);
  const [startdatestate, setstartdatestate] = useState(null);
  useEffect(() => {
    if (saved) {
      navigate("../seasonmanagement");
    }
    if (startdatestate === null ? true : false) {
    }
  }, [saved, startdatestate, updatedStartDate]);

  return (
    <>
    <div className="flex justify-between mt-[51px] ">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold">Edit Season</p>
          {/* <p className=" opacity-80 text-sm">Home / Season/ Edit</p> */}
        </div>
        <div>
          <Link
            to="../seasonmanagement"
            className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
          >
            Back
          </Link>
        </div>
      </div>
      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <form
          onSubmit={(e) => {
            updatedseason(id);
            e.preventDefault();
          }}
        >
          <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Season Name<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <select
                id="SeasonName"
                onChange={(e) => editseasonname(e.target.value)}
                value={editname}
                disabled
                name="SeasonName"
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="Spring">Spring</option>
                <option value="Summer">Summer</option>
                <option value="Fall">Fall</option>
                <option value="Winter">Winter</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="Year"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Year<span className="text-[#f00]">&nbsp;*</span>
              </label>
              {/* <Input 
                name="Year" 
                placeholder="year" 
                onChange={(e) => edityears(e.target.value) }
                value={edityear}
                min={2023}
                max={2099}
                id="Year"
                type="number" 
                required
                autoComplete="off" 
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                /> */}
              <select
                value={edityear}
                required
                name="Year"
                disabled
                id="Year"
                onChange={(e) => edityears(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="" disabled className="hidden">
                  Select Year
                </option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="date"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Start Date<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <input
                name="startdate"
                placeholder=""
                disabled
                onChange={(e) => {
                  editstartdate(e.target.value);
                  setstartdatestate(e.target.value);
                }}
                value={updatedStartDate}
                min={new Date().toISOString().split("T")[0]}
                max="2025-01-01"
                id="startdate"
                type="date"
                autoComplete="off"
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
              />
            </div>
            <div>
              <label
                htmlFor="date"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                End Date<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <input
                name="enddate"
                placeholder=""
                disabled
                onChange={(e) => editenddate(e.target.value)}
                // defaultValue={<Moment format="YYYY-MM-DD">{{updatedEndDate}}</Moment>}
                value={updatedEndDate}
                // disabled={updatedStartDate === "" ? true : false}
                min={
                  new Date(updatedStartDate ? updatedStartDate : null)
                    .toISOString()
                    .split("T")[0]
                }
                max="2025-01-01"
                id="enddate"
                type="date"
                autoComplete="off"
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
              />
            </div>
            <div>
              <label
                htmlFor="City"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                City<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <select
                onChange={(e) => updatedcityname(e.target.value)}
                value={newcityname}
                id="city"
                disabled
                name="city"
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option disabled>city name</option>
                {citydrop.map((data, index) => (
                  <option value={data?._id} key={index}>
                    {data?.city_name}
                  </option>
                ))}
              </select>
            </div>

            <div>

              <label
                htmlFor="status"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Status<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <select
                name="Status"
                value={updatedStatus}
                onChange={(e) => getupdatedstatus(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="true">Active</option>
                <option value="false">Inactive </option>
              </select>
            </div>
          </div>
          <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
            <Link
              to="../seasonmanagement"
              className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
            >
              <MdOutlineClose className="mr-1" />
              Cancel
            </Link>
            <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
              <MdOutlineCheck className="mr-1" />
              Update
            </button>
          </div>
        </form>
      </div>
    </>
  );
});
