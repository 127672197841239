import React, { useState } from "react";
import { RxEyeClosed, RxEyeOpen, RxPencil1, RxPerson } from "react-icons/rx";
import { Link } from "react-router-dom";
import { MdAdd, MdLocationCity, MdLockPerson, MdOutlineDelete, MdOutlineLockPerson, MdPassword, MdPerson, MdPerson3 } from "react-icons/md";
import { observer } from "mobx-react";
import ReactPaginate from 'react-paginate';
// import { BrandStore, useBrandStore, BrandStoreContext } from "./store/BrandStore";
import { UserStoreContext, useUserStore, UserStore } from "./store/UserStore";
import { useAuthStore } from "../Auth/store/Auth";
import Filter from "./UserFilter/FIlter";
import { useEffect } from "react";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import Moment from "react-moment";

const Users = () => {
    const store = new UserStore({
        isloading: true,
        editloading: false
    });
    return (
        <UserStoreContext.Provider value={store}>
            <Screen />
        </UserStoreContext.Provider>
    )
}
export default Users;


const Screen = observer(() => {
    const {
        Users,
        LoadUsers,
        nolist,
        Citypopup,
        BulkDelete,
        BulkstatusChange,
        UserLoader,
        // setallUsers,
        // allUsers,
        setPaginationPage,
        setalldeactivate,
        alldeactivate,
        getBrandList,
        getFilterUser,
        TotalPages,
        setUserspopup,
        Userpopup,
        deletenewuser,
        setdeletenewuser,
        setdelateLoader,
        delateLoader,
        statusChange,
        setstatuspopup,
        getid,
        getstatus,
        deletefunction,
        setchecked,
        checkeddd,
        setpasswordpopup,
        passwordpopup,
        passwordChanged,
        Confirmpassw,
        updateUserPass,
        passwordLoading,
        getToken
    } = useUserStore();

    const { adminAuthApproved } = useAuthStore();

    useEffect(() => {
        if (adminAuthApproved) {
            getToken(localStorage.getItem("Admintoken"));
        }

    }, [adminAuthApproved]);

    const [currentPage, setCurrentPage] = useState(0);
    const [userId, setuserId] = useState();
    const [allUsers, setallUsers] = useState([])
    const [showpassword, setshowpassword] = useState(false);
    const [confirmpassword, setconfirmpassword] = useState(false);
    // const [checkeddd, setchecked] = useState(false)

    const [photoIndex, setPhotoIndex] = useState("");
    const ITEMS_PER_PAGE = 10;
    const handlePageChange = ({ selected }) => {
        setPaginationPage(selected + 1);
        getFilterUser();
        setCurrentPage(selected);
    };

    const handleClick = () => {
        const element = document.getElementById("tablescroll");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }
    const startIndex = currentPage * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const AdminUser = Users?.slice(startIndex, endIndex);
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    console.log(AdminUser, Users);

    const checkAllCheckboxes = () => {
        // setallUsers([]);
        // Get all checkboxes with the same name attribute
        var checkboxes = document.getElementsByName('myCheckbox');

        if (checkeddd) {
            for (var i = 0; i < checkboxes.length; i++) {
                checkboxes[i].checked = false;
            }
            setallUsers([]);
        } else {
            var emails = [];
            var commaSeparatedEmails = [];
            // var emailArray = emailString.split(",").map((email) => email.trim());
            // Loop through each checkbox and check it
            for (var i = 0; i < checkboxes.length; i++) {
                checkboxes[i].checked = true;
                var emailValues = checkboxes[i].value.split(',');
                if (emailValues.length > 1) {
                    commaSeparatedEmails.push(...emailValues);
                } else {
                    var emailValue = emailValues[0].trim();
                    if (emailValue !== '' && !emails.includes(emailValue)) {
                        emails.push(emailValue);
                    }

                }
            }
            setallUsers((prevEmails) => [...prevEmails, ...emails, ...commaSeparatedEmails]);
        }

    }
    const isallcheck = (e) => {
        var checkboxes = document.getElementsByName('myCheckbox');
        for (var i = 0; i < checkboxes.length; i++) {
            var updatedEmails = Array.from(checkboxes)
                .filter((checkbox) => checkbox.checked)
                .flatMap((checkbox) => checkbox.value.split(',').map((email) => email.trim()));
            setallUsers(updatedEmails);
            if (!checkboxes[i].checked) {
                setchecked(false)
                return
            }
            else {
                setchecked(true)
            }

        }
    }

    console.log(TotalPages);

    return (
        <>
          <div className="flex justify-between mt-[51px] ">
                <div className=" mb-4">
                    <p className=" text-2xl font-semibold"> Users </p>
                    <p className=" opacity-80 text-sm">Home / Users</p>
                </div>
                {/* <div>
                    <Link
                        to="./adduser"
                        className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                    >
                        <MdAdd />
                        Add Admin Users
                    </Link>
                </div> */}
            </div>
            <Filter />

            <div className="flex justify-between items-center py-2">
                <div className="ml-3">
                    <label className=" font-extrabold">
                        <input className="mr-2" checked={checkeddd} type="checkbox" name="selectall"
                            onClick={() => { checkAllCheckboxes(); setchecked(!checkeddd) }}


                        />
                        Select All
                    </label>
                </div>
                <div className="space-x-3 flex justify-center">
                    {/* { allemail.length !== 0 && */}
                    {/* <label
                        htmlFor="emailpopup"
                        className={`btn btn-md ${allUsers.length == 0 && "opacity-50"}  bg-[#000] text-color_theme text-sm hover:bg-[#000]`}
                    // onClick={() => setPopupemail(true)}
                    >
                        All Deactivate
                    </label > */}
                    {/* <label class={`relative ${allUsers.length == 0 && "opacity-50"}  inline-flex items-center  cursor-pointer`}>
                        <input disabled={allUsers.length == 0 && true} onClick={() => { setalldeactivate(); BulkstatusChange(allUsers) }} type="checkbox" class="sr-only peer" />
                        <div class="w-7 h-3 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300"> {!alldeactivate ? "All Activate" : "All Deactivate"}
                        </span>
                    </label> */}

             
              
                    {/* } */}


                </div>
            </div>
            <div className="card bg-base-100 shadow-md p-6 mt-8">
                <div className="overflow-x-auto w-full">
                    <table id="tablescroll" className="table table-zebra w-full">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Full Name</th>
                                <th>Shop Name</th>
                                <th>Email</th>
                                <th>Reg. Date</th>
                                <th>status</th>
                                <th>Action</th>
                            </tr>
                        </thead>

                        <tbody className="text-sm relative">
                            {LoadUsers &&
                                <>
                                    <tr>
                                        <td colSpan={7} className="animate-pulse py-6"></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={7} className="animate-pulse py-6"></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={7} className="animate-pulse py-6"></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={7} className="animate-pulse py-6"></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={7} className="animate-pulse py-6"></td>
                                    </tr>
                                </>
                            }
                            {
                                (AdminUser.length === 0 ? Users : AdminUser)?.map((data, index) => (
                                    <tr
                                        key={index}
                                        className="capitalize">

                                        <td>
                                            {/* {!data?.is_refunded && */}
                                            <input
                                                // value={`${data?.players?.mate?.email} ? ${data?.players?.user?.email}`} 
                                                value={data?.id}
                                                onChange={(e) => { setchecked(false); isallcheck(e.target.value); }}
                                                type="checkbox"
                                                name="myCheckbox"
                                            />
                                            {/* } */}
                                        </td>
                                        <td>
                                            {data?.full_name}
                                        </td>
                                        <td>
                                            {data?.shop_name}

                                        </td>
                                        <td>
                                            {data?.email}
                                        </td>
                                        <td>
                                            <Moment
                                                format={
                                                    "DD/MM/YYYY"
                                                }
                                            >
                                                {data.created_on}
                                            </Moment>

                                        </td>



                                        <th >
                                            <div className="flex space-x-3">
                                                <label
                                                    title="Change Status"
                                                    htmlFor="my-modal-5"
                                                    className={`btn capitalize Active bg-warning hover:bg-warning text-[#000] border-hidden  btn-xs`}



                                                    onClick={(e) => {
                                                        getid(data?.seller_id);
                                                        setUserspopup(true);
                                                        getstatus(data?.is_active === 1 ? 0 : 1);
                                                    }}
                                                >

                                                    Disapprove
                                                </label>

                                            </div>

                                        </th>
                                        <th >
                                            <div className="flex space-x-3">

                                                <div className="flex items-center space-x-3">
                                                    {/* {admin_permissions.Orders === 1 ?
                                                        <label
                                                            htmlFor="deletecity"
                                                            className=" text-[#d02c2c] cursor-pointer"
                                                            title="Delete"
                                                            onClick={() => { setdeletenewuser(true); getid(data?.id) }}
                                                        >
                                                            <MdOutlineDelete />
                                                        </label>
                                                        :
                                                        <label
                                                            htmlFor="deletecity"
                                                            className=" text-[#d02c2c] cursor-pointer"
                                                            title="Delete"
                                                            onClick={() => { toast.warning("You have no permissions to edit.") }}
                                                        >
                                                            <MdOutlineDelete />
                                                        </label>
                                                    } */}
                                                    <label
                                                        htmlFor="deletenewuser"
                                                        className=" text-[#d02c2c] cursor-pointer"
                                                        title="Delete"
                                                        onClick={() => { setdeletenewuser(true); 
                                                            getid(data?.seller_id);
                                                        
                                                        
                                                        }}
                                                    >
                                                        <MdOutlineDelete />
                                                    </label>
                                                    {/* <button title="Edit">
                                                        <Link to={`./edituser/${data?.id}`}>

                                                            <RxPencil1 />
                                                        </Link>
                                                    </button>
                                                    <label title="change password" className="cursor-pointer" onClick={() => { setpasswordpopup(); setuserId(data?.id) }} htmlFor="my-modal-3">
                                                        <MdPassword />
                                                    </label> */}

                                                </div>
                                            </div>

                                        </th>

                                    </tr>
                                ))
                            }

                            {nolist &&
                                <tr>
                                    <td colSpan={9} className="my-10 text-center">
                                        <RxPerson className=" text-[#dedede] text-8xl mx-auto" />
                                        <p className=" font-semibold text-lg">No Users Found</p>
                                        {/* <Link
                                            to="./addcity"
                                            className="btn btn-sm mt-4 bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                                        >
                                            <MdAdd />
                                            Add Admin Users
                                        </Link> */}
                                    </td>
                                </tr>}
                        </tbody>
                    </table>
                    {/* {!city?.length == 0 && */}

                    <ReactPaginate
                        initialPage={0}
                        pageCount={Math.ceil(TotalPages)}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageChange}
                        onClick={handleClick}
                        containerClassName={'pagination'}
                        activeClassName={'active'}

                    />
                    {/* } */}

                    {/* status change model start */}
                    {/* <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="my-modal-5"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status ?
              </h3>
              <div className="modal-action justify-center">
                      <label
                        htmlFor="my-modal-5"
                        className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                        onClick={() => statusChange()}
                      >
                        Yes
                      </label> 
                <label htmlFor="my-modal-5" className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]">
                  No
                </label>
              </div>
            </div>
          </div> */}
                    {/* status change model end */}
                    {deletenewuser &&
                        <>
                            <input type="checkbox" id="deletenewuser" className="modal-toggle" />
                            <div className="modal">
                                <div className="modal-box relative">
                                    <label

                                        className="btn btn-sm btn-circle absolute right-2 top-2"
                                        onClick={() => { setdelateLoader(false); setdeletenewuser(false) }}
                                    >
                                        ✕
                                    </label>
                                    <h3 className="text-lg font-bold text-center">
                                        Are you sure you want to Delete this User
                                    </h3>
                                    <div className="modal-action justify-center">
                                        {/* <label
                                            htmlFor="deletecity"
                                            className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                            onClick={() => deletefunction()}
                                        >
                                            Yes
                                        </label> */}
                                        {!delateLoader ?
                                            <label

                                                className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                                onClick={() => deletefunction()}
                                            >
                                                Yes
                                            </label> :
                                            <button
                                                // type="submit"
                                                disabled
                                                className="flex items-center bg-color_theme px-3 mx-5 rounded-md"
                                            // onClick={() => {
                                            //   PostMatchscore();
                                            // }}
                                            >
                                                Yes..
                                                <svg
                                                    className="animate-spin mr-1 h-5 w-5 text-white"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle
                                                        className="opacity-25"
                                                        cx="12"
                                                        cy="12"
                                                        r="10"
                                                        stroke="currentColor"
                                                        strokeWidth="4"
                                                    ></circle>
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    ></path>
                                                </svg>
                                            </button>}
                                        <label
                                            onClick={() => setdeletenewuser(false)}
                                            className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </>

                    }
                    {Userpopup &&
                        <>
                            <input type="checkbox" id="my-modal-5" className="modal-toggle" />
                            <div className="modal">
                                <div className="modal-box relative">
                                    <label

                                        className="btn btn-sm btn-circle absolute right-2 top-2"
                                        onClick={() => setUserspopup(false)}
                                    >
                                        ✕
                                    </label>
                                    <h3 className="text-lg font-bold text-center">
                                        Are you sure you want to Approve User ?
                                    </h3>
                                    <div className="modal-action justify-center">
                                        {!UserLoader ? <label

                                            className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                            onClick={() => statusChange()}
                                        >
                                            Yes
                                        </label> :
                                            <button
                                                // type="submit"
                                                disabled
                                                className="flex items-center bg-color_theme px-3 mx-5 rounded-md"
                                            // onClick={() => {
                                            //   PostMatchscore();
                                            // }}
                                            >
                                                Yes..
                                                <svg
                                                    className="animate-spin mr-1 h-5 w-5 text-white"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle
                                                        className="opacity-25"
                                                        cx="12"
                                                        cy="12"
                                                        r="10"
                                                        stroke="currentColor"
                                                        strokeWidth="4"
                                                    ></circle>
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    ></path>
                                                </svg>
                                            </button>}

                                        <label
                                            onClick={() => setUserspopup(false)}
                                            // htmlFor="voidstatus"
                                            className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                                        >
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>

                        </>

                    }


                    <input readOnly checked={passwordpopup} type="checkbox" id="my-modal-3" className="modal-toggle" />
                    <div className="modal">
                        <div className="modal-box relative">
                            <form autoComplete="off" onSubmit={(e) => { updateUserPass(userId); e.preventDefault() }}>
                                <label onClick={() => setpasswordpopup()} htmlFor="my-modal-3" className="btn btn-sm btn-circle absolute right-2 top-2">
                                    <button type="reset">✕</button>
                                </label>
                                <h3 className="text-lg font-bold">Change Password</h3>
                                <div className="pt-4  ">

                                    <label className=" relative ">
                                        <input
                                            autoComplete="new password"
                                            required
                                            onChange={(e) => passwordChanged(e.target.value)}
                                            className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] mb-5 rounded-lg px-6 py-3 focus:outline-none"
                                            placeholder="New Password"
                                            type={showpassword ? "text" : "password"}
                                        />
                                        <span className=" cursor-pointer absolute right-3 top-1" onClick={() => setshowpassword(!showpassword)}>
                                            {showpassword
                                                ?
                                                <RxEyeOpen className=" text-[#837575]" />
                                                :
                                                <RxEyeClosed className=" text-[#837575]" />
                                            }
                                        </span>
                                    </label>



                                    <label className=" relative">
                                        <input
                                            autoComplete="confirm-password"
                                            required
                                            onChange={(e) => Confirmpassw(e.target.value)}
                                            className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                                            placeholder="Confirm password"
                                            type={confirmpassword ? "text" : "password"}
                                        />
                                        <span className=" cursor-pointer absolute right-3 top-1" onClick={() => setconfirmpassword(!confirmpassword)}>
                                            {confirmpassword
                                                ?
                                                <RxEyeOpen className=" text-[#837575]" />
                                                :
                                                <RxEyeClosed className=" text-[#837575]" />
                                            }
                                        </span>
                                    </label>



                                    {!passwordLoading
                                        ?
                                        <button type="submit" className="mt-5 btn w-full bg-color_theme hover:bg-[#000] text-[#000] hover:text-color_theme font-semibold border-none">Update Password</button>
                                        :
                                        <button type="button" className="mt-5 btn w-full bg-[#000] hover:bg-[#000] text-color_theme hover:text-color_theme font-semibold border-none flex justify-center items-center">
                                            <svg
                                                className="animate-spin mr-1 h-5 w-5 text-white"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                            >
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path
                                                    className="opacity-75"
                                                    fill="currentColor"
                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                ></path>
                                            </svg>
                                        </button>
                                    }


                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

