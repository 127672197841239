import React, { useEffect, useState } from 'react'
import { Input } from 'react-daisyui'
import {  MdOutlineRefresh, MdOutlineSearch } from 'react-icons/md'
import { Link, useMatch, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useMatchStore } from '../store/MatchStore';
import { useLeagueStore } from '../../LeagueManagement/store/LeagueStore';
// import { useSeasonStore} from "../store/SeasonStore";

const Filter = observer(() => {
    const { division,getFilterMatches,getlevel,level,cityDropdown,leaguedropdown,getleague,getMatchList,allclear,getcitydrop,getdivision } = useMatchStore();

    const [startdatestate,setstartdatestate] = useState(null)
    
    // const Reset=()=>{
    //     getname("")
    //     getyear("")
    //     updatedcitynameid("")
    //     getstartdate("")
    //     getenddate("")
    //     getstatus("")
    // }
    const {
      leveldropdown,
      getleveldropdown
    } = useLeagueStore();
  
    useEffect(() => {
      getleveldropdown()
    },[])
  return (
    <>
     
        <div className="card bg-base-100 shadow-md p-6  mt-3 mb-10">
            <form onSubmit={(e) => {getFilterMatches();e.preventDefault()}} >
                <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
         
            <div>
                        <label htmlFor="City" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">City</label>
                        <select     
                        defaultValue=""
                        id="city"
                        name="city"
                         onChange={(e) => getcitydrop(e.target.value)}
                        className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                            {/* <option value="" disabled className="hidden">Select city</option> */}
                            <option value="" className="">All</option>
                            {cityDropdown.map((data,index)=> (
                                <option value={data?._id} key={index}>{data?.city_name}</option>
                         
                            ))}
                        </select>  
                    </div>
                    <div>
              <label
                htmlFor="LeagueName"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                League Name
              </label>
              <select
                defaultValue=""
                id="LeagueName"
                name="LeagueName"
                 onChange={(e) => getleague(e.target.value)}
                className={`select capitalize focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                {/* <option value="" disabled className="hidden">Select city</option> */}
                <option value="" className="">
                  All
                </option>
                {leaguedropdown.map((data, index) => (
                  <option value={data?._id} key={index}>
                    {data?.name}
                  </option>
                ))}
              </select>
        
            </div>

            <div>
              <label
                htmlFor="division"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Division
              </label>
              <Input
                name="division"
                 onChange={(e) => getdivision(e.target.value)}
                 value={division}
                 min={0}
                placeholder="Division"
                id="division"
                type="number"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
          
            <div>
            <label
                htmlFor="level"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Level
              </label>
              <select
                   onChange={(e) => getlevel(e.target.value)}
                   value={level}
                  className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                  // aria-label="Default select example"
                >  
                 <option value=""> Select Level</option>
                {leveldropdown?.map((data,index)=> (
                        <option value={data?._id} key={index}>{data?.name.toFixed(1)} </option>
                        ))}
                  
                </select>
            </div>
                
            
                  
                </div>
                <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                <button type='reset'  onClick={()=>{getMatchList(); allclear()}} className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"><MdOutlineRefresh className="mr-1"/>Reset</button>
                        <button  className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"><MdOutlineSearch className="mr-1"/>Search</button>
                </div>
            </form>
        </div>
    </>
  )
});

export default Filter;