import { createContext, useContext, userContext } from "react";
import { action, makeAutoObservable, makeObservable, observable, runInAction } from "mobx";
import { postMethodData, getMethodData, putMethod, delMethod, patchMethodData } from "../../ApiMethods/api";
import { toast } from "react-toastify";
const SEO_URL = process.env.REACT_APP_SEO_URL;





export class UserStore {

    Token = null;
    Users = [];
    filterSearch = "";
    RequestCount  = "";
    PaginationPage = "1";
    alert="";
    TotalPages = "";
    BannerDimiensions = ""
    crop = {};
    errLoadAminUsers = "";
    LoadUsers = false;
    UserLoader = false;
    delateLoader = false;
    nolist = false;
    Userpopup = false;
    deletepopup = false;
    deletenewuser= false;
    name = "";
    status = "1";
    statesid = "";
    user_id = [];
    seoUrl = "";
    statuspopup = false;
    addbrandname = "";
    imageurl = "";
    logoimage = "";
    bannerimage = "";
    Base64logoImage = "";
    Base64BannerImage = "";
    logofile = [];
    allbrand = [];
    alldeactivate = 'false';
    checkeddd = '';
    password = "";
    errPassword = "";
    confirmresetpas = "";
    username = "";
    email = "";
    alert = "false";
    passwordpopup = false;
    passwordLoading = false;
    AdminPermissions=[];
    adminpermissions={};
    UserFormDetails = {
        "full_name": "",
        "username": "",
        "email": "",
        "system_generated_alert": "",
        "is_active": ""
    };
    saved = false;

    constructor(isloading = false) {
        makeAutoObservable(this, {
            addbrandname: observable,
            status: observable,
            errorMessages: observable,
            setaddbrandname: action,
            getstatus: action,
            resetErrors: action,
        });

        this.Token = localStorage.getItem("Admintoken");
        if (isloading.isloading) {
            this.getFilterUser();

            //   this.getStateList();
            return
        }
        if (isloading.addloading) {
            //   this.getStateList();
        }
        if (isloading.Usereditloading) {
            this.getUserform(isloading.id);
            return
        }
        if (isloading.AdminPermissions) {
            this.getAdminPermissions(isloading.id);
            return
        }
    }
    //All Clear 
    allclear = (id) => {
        this.filterSearch = ""
        this.getFilterUser(id);
        // this.getAdminPermissions(id);
        //   this.status="";
        //   this.statesid="";
    }
    setimage = (text, type) => {
        this.imageurl = URL.createObjectURL(text[0]);
    }

    setalldeactivate = (text) => {
        console.log(text);
        this.alldeactivate = !this.alldeactivate;
    };

    getid = (text) => {
        this.user_id.push(text);
    };


    // status popup
    setstatuspopup = () => {
        this.statuspopup = !this.statuspopup;
    };


    setallBrand = (text) => {
        this.allbrand = text;
    }

    // get status 
    getstatus = (text) => {
        this.status = text;
    };

    //get city name
    getbrandname = (text) => {
        this.brandname = text;
    };
    setemail = (text) => {
        this.email = text;
    };
    setname = (text) => {
        this.name = text;
    };
    setCrop = (text) => {
        this.crop = text;
    };

    setBannerDimiensions = (text) => {
        this.BannerDimiensions = text;
    };

    setlogofile = (e) => {
        this.logofile = e;
        console.log(this.logofile);
    };

    setUserspopup = (e) => {
        this.Userpopup = e;
    };
    setdeletepopup = (e) => {
        this.deletepopup = e;
    };
    setdeletenewuser= (e) => {
        this.deletenewuser = e;
    };
    setdelateLoader = (e) => {
        this.delateLoader = e;
    }
    getToken = (e) => {
        this.Token = e;
    }
    getusername = (text) => {
        // console.log(`https://demo.e-kart.com/${text}`);
        this.username = text;
    };
    getRequestCount = (text) => {
        // console.log(`https://demo.e-kart.com/${text}`);
        this.RequestCount  = text;
    };
    getlogoimage = (text) => {
        console.log("checking in the brand", text);
        this.logoimage = text;
    };
    getbanner_image = (text) => {
        console.log(text);
        this.bannerimage = text;
    };
    SetfilterSearch = (text) => {
        this.filterSearch = text;
    };
    setPaginationPage = (text) => {
        this.PaginationPage = text;
    };
    SetTotalPages = (text) => {
        this.TotalPages = text;
    };
    setchecked = (text) => {
        this.checkeddd = text;
    };
    // get password value
    passwordChanged = (text) => {
        this.password = text
    };
   
    Confirmpassw = (text) => {
        this.confirmresetpas = text
    };
    setalert = (text) => {
        this.alert = !this.alert
    };
    setpasswordpopup = () => {
        this.passwordpopup = !this.passwordpopup
        this.errPassword = ""
        // this.currentpassword = ""
        // this.newpassword = ""
        // this.confirmnewpassword = ""
    }

    isBase64 = (str) => {
        try {
            return btoa(atob(str)) === str;
        } catch (err) {
            return false;
        }
    };
    setUserFormDetails = (UserDetail) => {
        this.UserFormDetails = {
            ...this.UserFormDetails,
            full_name: UserDetail.full_name || "", // Use default value if name is undefined
            username: UserDetail.username || "", // Use default value if seo_url is undefined
            // logo_image: this.isBase64( this.UserFormDetails.logo_image) ? this.logofile.length == 0 ? UserDetail.logo_image    : this.UserFormDetails.logo_image  : this.logofile.length !== 0 ? this.UserFormDetails.logo_image :  UserDetail.logo_image   || "",
            // banner_image: this.isBase64(UserDetail.banner_image) ? this.UserFormDetails.banner_image : UserDetail.banner_image || "",
            email: UserDetail.email || "",
            system_generated_alert: UserDetail.system_generated_alert || "",
            is_active: UserDetail.is_active
        };
    };
    setadminpermissions = (permissions) => {
        this.adminpermissions = {
          ...this.adminpermissions,
          ...permissions,
        };
        console.log(JSON.stringify(this.adminpermissions));
      };
    // setUserFormDetails = (text) => {
    //     this.UserFormDetails = text;
    //     console.log("setUserFormDetails", this.UserFormDetails.brandname);
    // };
    base64toFile(base64Data, fileName, mimeType) {
        if (base64Data) {
            const byteCharacters = atob(base64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                const slice = byteCharacters.slice(offset, offset + 512);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const file = new File(byteArrays, fileName, { type: mimeType });

            return file;
        }

    }

    // get brand list
    getUserList = () => {
        runInAction(() => {
            this.UserLoader = true;
            this.errLoadAminUsers = "";
            this.saved = false;
            this.nolist = false;
            this.Users = [];
        });
        getMethodData(`/api/v1/admin/admin-users/get-list?page=${this.PaginationPage + 1}`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.UserLoader = false;
                        this.errLoadAminUsers = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.UserLoader = false;
                        this.errLoadAminUsers = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.Users = resp?.data?.data;
                        this.RequestCount = resp?.data?.data?.length;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadUsers = false;
                    this.errLoadAminUsers = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.UserLoader = false;
                    if (!error.response) {
                        this.errLoadAminUsers = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadAminUsers = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };
    //Filter city
    // filter Admin User

    getFilterUser = () => {
        runInAction(() => {
            this.LoadUsers = true;
            this.errLoadAminUsers = "";
            this.saved = false;
            this.nolist = false;
        });
        postMethodData(`/api/v1/admin/request/seller/search?page=${ this.PaginationPage  }`, {
            keyword: this.filterSearch
        }, this.Token)
            .then((resp) => {

                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.loadleague = false;
                        this.errloadleague = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {

                    runInAction(() => {
                        this.LoadUsers = false;
                        this.errloadleague = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.Users = resp?.data?.data;
                        this.RequestCount = resp?.data?.data?.length
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.loadcity = false;
                    this.errloadcuty = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.LoadUsers = false;
                    if (!error.response) {
                        this.errloadleague = error?.message;
                        toast.error(error?.message);
                    }
                    this.errloadleague = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    // filter User Permissions

    getFilterUsersearch = () => {
        runInAction(() => {
            this.LoadUsers = true;
            this.errLoadAminUsers = "";
            this.saved = false;
            this.nolist = false;
            this.getUserList = [];
        });
        postMethodData(`/api/v1/admin/request/seller/search`, {
            keyword: this.filterSearch,

        }, this.Token)
            .then((resp) => {

                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.loadleague = false;
                        this.errloadleague = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {

                    runInAction(() => {
                        this.loadcity = false;
                        this.errloadleague = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.Users = resp?.data?.data;
                        this.RequestCount = resp?.data?.data?.length;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.loadcity = false;
                    this.errloadcuty = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.loadleague = false;
                    if (!error.response) {
                        this.errloadleague = error?.message;
                        toast.error(error?.message);
                    }
                    this.errloadleague = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    // add new User
    addUser = () => {
        if (this.password != this.confirmresetpas) {
            runInAction(() => {
                toast.error("The password and confirmation password do not match.")
            })
            return;
        }
        runInAction(() => {
            this.addloadUsersLoadUsers = true;
            this.errLoadAminUsers = "";
        });
        const formData = new FormData();

        formData.append('fullname', this.name);



        formData.append('username', this.username);

        formData.append('email', this.email);


        formData.append('password', this.password);

        formData.append('confirmPassword', this.confirmresetpas);
        formData.append('status', this.status);
        formData.append('alert', this.alert ? 0 : 1);




        postMethodData("/api/v1/admin/admin-users/add", formData, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.addloadUsersLoadUsers = false;
                    this.errLoadAminUsers = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.addloadUsersLoadUsers = false;
                    this.errLoadAminUsers = resp?.data?.message;
                    // toast.success(resp.data.message);
                    this.getFilterUser();
                    this.saved = true;
                })
                return
            }
            runInAction(() => {
                this.addloadUsersLoadUsers = false;
                this.errLoadAminUsers = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadUsersLoadUsers = false;
                    if (!error.response) {
                        this.errLoadAminUsers = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadAminUsers = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    //Active and inactive
    statusChange = (id) => {
        runInAction(() => {
            this.UserLoader = true;
            this.Userpopup = true;

            this.errLoadAminUsers = "";
        });
        postMethodData(`/api/v1/admin/request/seller/toggleVerification`, {
            "status": this.status,
            "record_id": this.user_id
        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.UserLoader = false;
                        this.Userpopup = false;
                        this.errLoadAminUsers = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.UserLoader = false;
                        this.Userpopup = false;
                        this.user_id = [];
                        this.errLoadAminUsers = resp?.data?.message;
                        this.getFilterUser();
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.UserLoader = false;
                    this.Userpopup = false;

                    this.errLoadAminUsers = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.UserLoader = false;
                        this.Userpopup = false;

                        this.errLoadAminUsers = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.UserLoader = false;
                    this.Userpopup = false;

                    this.errLoadAminUsers = error?.response?.data?.message;
                })
                return;
            })
    };
    // Bulk Active and inactive
    BulkstatusChange = (allbrand) => {
        runInAction(() => {
            this.UserLoader = true;
            this.Userpopup = true;

            this.errLoadAminUsers = "";
        });

        postMethodData(`/api/v1/admin/admin-users/update-status`, {


            "recordId": JSON.stringify(allbrand),
            "status": JSON.stringify(this.alldeactivate ? 1 : 0)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.UserLoader = false;
                        this.Userpopup = false;
                        this.errLoadAminUsers = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.UserLoader = false;
                        this.Userpopup = false;

                        this.errLoadAminUsers = resp?.data?.message;
                        this.getFilterUser();
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.UserLoader = false;
                    this.Userpopup = false;

                    this.errLoadAminUsers = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.UserLoader = false;
                        this.Userpopup = false;

                        this.errLoadAminUsers = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.UserLoader = false;
                    this.Userpopup = false;

                    this.errLoadAminUsers = error?.response?.data?.message;
                })
                return;
            })
    };

    //Delete method
    deletefunction = () => {
        runInAction(() => {
            this.setdelateLoader(true);
            this.deletenewuser = true;
            this.errLoadAminUsers = "";
        });
        postMethodData(`/api/v1/admin/request/seller/delete`, {

            "record_id": this.user_id,

        }, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.LoadUsers = false;
                    this.setdelateLoader(true);
                    this.deletenewuser = true;
                    this.errLoadAminUsers = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.LoadUsers = false;
                    this.setdelateLoader(false);
                    this.user_id = [];
                    this.deletenewuser = false;
                    this.errLoadAminUsers = resp?.data?.message;
                    this.getFilterUser();
                    // toast.success(resp.data.message);
                })
                return;
            }
            runInAction(() => {
                this.LoadUsers = false;
                this.errLoadAminUsers = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadcity = false;
                    if (!error.response) {
                        this.LoadUsers = false;
                        this.setdelateLoader(false);
                        this.deletenewuser = false;
                        this.errLoadAminUsers = error?.message;
                        toast.error(error?.message);
                    }
                    this.LoadUsers = false;
                    this.errLoadAminUsers = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            })
    }
    // Bulk Delete 
    BulkDelete = (allbrand) => {
        runInAction(() => {
            this.UserLoader = true;
            this.Userpopup = true;

            this.errLoadAminUsers = "";
        });

        postMethodData(`/api/v1/admin/brand/multi-delete`, {

            "user_id": JSON.stringify(allbrand),
            // "status": JSON.stringify(this.alldeactivate ? 1 : 0)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.UserLoader = false;
                        this.Userpopup = false;
                        this.errLoadAminUsers = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.UserLoader = false;
                        this.Userpopup = false;

                        this.errLoadAminUsers = resp?.data?.message;
                        this.getFilterUser();
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.UserLoader = false;
                    this.Userpopup = false;

                    this.errLoadAminUsers = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.UserLoader = false;
                        this.Userpopup = false;

                        this.errLoadAminUsers = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.UserLoader = false;
                    this.Userpopup = false;

                    this.errLoadAminUsers = error?.response?.data?.message;
                })
                return;
            })
    };

    //get Brand by id
    getUserform = (id) => {
        runInAction(() => {
            this.UserLoader = true;
            this.Userpopup = true;

            this.errLoadAminUsers = "";
        });
        postMethodData(`/api/v1/admin/admin-users/form`, {

            "recordId": id,
            // "status": JSON.stringify(this.status)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.UserLoader = false;
                        this.Userpopup = false;
                        this.errLoadAminUsers = resp?.data?.message;
                        // toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        console.log(resp.data.data);
                        this.setUserFormDetails(resp.data.data.userDetail);
                        this.alert = resp.data.data.userDetail.system_generated_alert;
                        this.UserLoader = false;
                        this.Userpopup = false;
                        this.errLoadAminUsers = resp?.data?.message;
                    })
                    return;
                }
                runInAction(() => {
                    this.UserLoader = false;
                    this.Userpopup = false;

                    this.errLoadAminUsers = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.UserLoader = false;
                        this.Userpopup = false;

                        this.errLoadAminUsers = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.UserLoader = false;
                    this.Userpopup = false;

                    this.errLoadAminUsers = error?.response?.data?.message;
                })
                return;
            })
    };



    //update method
    updateEditAdmin = (id) => {
        runInAction(() => {
            this.addloadUsersLoadUsers = true;
            this.errLoadAminUsers = "";
        });

        const formData = new FormData();
        console.log(this.UserFormDetails.system_generated_alert);

        formData.append('fullname', this.UserFormDetails.full_name);
        formData.append('username', this.UserFormDetails.username);

        formData.append('recordId', id);

        formData.append('email', this.UserFormDetails.email);

        formData.append('alert', this.UserFormDetails.system_generated_alert || this.alert);

        formData.append('status', this.UserFormDetails.is_active);




        putMethod(`/api/v1/admin/admin-users/edit`,
            formData,
            this.Token
        )
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.UserLoader = false;
                        this.errLoadAminUsers = resp?.data?.message;
                        toast.error(resp.data.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.UserLoader = false;
                        this.errLoadAminUsers = resp?.data?.message;
                        toast.success(resp.data.message);
                        // this.getCity();
                        this.getFilterUser();
                        this.saved = true;
                    })
                    return
                }
                runInAction(() => {
                    this.UserLoader = false;
                    this.errLoadAminUsers = resp?.data?.message;
                    toast.error(resp.data.message);
                });
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.UserLoader = false;
                        this.errLoadAminUsers = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.UserLoader = false;
                    this.errLoadAminUsers = error?.response?.data?.message;
                })
                return;
            })
    };
    //Admin Permissions List 

    getAdminPermissions = (id) => {
        runInAction(() => {
            this.UserLoader = true;
            this.Userpopup = true;

            this.errLoadAminUsers = "";
        });
        getMethodData(`/api/v1/admin/admin-permissions/list/${id}`,
     this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.UserLoader = false;
                        this.Userpopup = false;
                        this.errLoadAminUsers = resp?.data?.message;
                        // toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.AdminPermissions = resp?.data?.data;
                        this.UserLoader = false;
                        this.Userpopup = false;
                        this.errLoadAminUsers = resp?.data?.message;
                    })
                    return;
                }
                runInAction(() => {
                    this.UserLoader = false;
                    this.Userpopup = false;

                    this.errLoadAminUsers = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.UserLoader = false;
                        this.Userpopup = false;

                        this.errLoadAminUsers = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.UserLoader = false;
                    this.Userpopup = false;

                    this.errLoadAminUsers = error?.response?.data?.message;
                })
                return;
            })
    };
    //update password
    updateUserPass = (id) => {
        if (this.password != this.confirmresetpas) {
            runInAction(() => {
                toast.error("The password and confirmation password do not match.")
            })
            return;
        }
        runInAction(() => {
            this.passwordLoading = true;
            this.errLoadAminUsers = "";
        });

        const formData = new FormData();
        formData.append('password', this.password);
        formData.append('confirmPassword', this.confirmresetpas);
        formData.append('recordId', id);

        postMethodData(`/api/v1/admin/admin-users/update-password`,
            formData,
            this.Token
        )
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.UserLoader = false;
                        this.passwordLoading = false;
                        this.errLoadAminUsers = resp?.data?.message;
                        toast.error(resp.data.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.setpasswordpopup();
                        toast.success(resp.data.message);
                        this.getFilterUser();
                        this.saved = true;
                    })
                    return
                }
                runInAction(() => {
                    this.UserLoader = false;
                    this.errLoadAminUsers = resp?.data?.message;
                    toast.error(resp.data.message);
                });
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.UserLoader = false;
                        this.passwordLoading = false;
                        this.errLoadAminUsers = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.UserLoader = false;
                    this.errLoadAminUsers = error?.response?.data?.message;
                })
                return;
            })
    };
    //Update User Permissions
    updateUserPermissions = async(id , permission_value) => {
       await postMethodData(`/api/v1/admin/admin-permissions/update`,
            {
                permissionList : this.adminpermissions ,
                recordId:id
            },
            this.Token
        )
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.UserLoader = false;
                        this.passwordLoading = false;
                        this.errLoadAminUsers = resp?.data?.message;
                        toast.error(resp.data.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        toast.success(resp.data.message);
                        this.getAdminPermissions(id);
                        this.saved = true;
                    })
                    return
                }
                runInAction(() => {
                    this.UserLoader = false;
                    this.errLoadAminUsers = resp?.data?.message;
                    toast.error(resp.data.message);
                });
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {

                        this.UserLoader = false;
                        this.passwordLoading = false;
                        this.errLoadAminUsers = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                console.log(error);

                    this.UserLoader = false;
                    this.errLoadAminUsers = error?.response?.data?.message;
                })
                return;
            })
    };
}





export const UserStoreContext = createContext(new UserStore());
export const useUserStore = () => useContext(UserStoreContext);
